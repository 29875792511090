import React, {useEffect, useState} from 'react';
import {
    Paper,
    Progress,
    Text,
    Badge,
    Box,
    Grid,
    Divider,
    useMantineTheme, createStyles,
    Tooltip as MantineTooltip, Button,
} from '@mantine/core';
import {getDaysInMonth} from "date-fns";
import {ChevronRight} from "react-feather";
import axios from "axios";
import {useAuth} from "../../../AuthContext";
import {useUsage} from "../usageContext";

const useStyles = createStyles((theme) => ({
    title: {
        fontSize: '12px',
        color: theme.colors.gray[1],
        fontWeight: 500,
    },
    data: {
        fontSize: '16px',
        color: theme.colors.gray[4],
        fontWeight: 300,
    },
    toolTip: {
        transition: 'all 0.3s ease',
        color: theme.colors.gray[1],
        border: `3px solid ${theme.colors.gray[1]}`,
        zIndex: 1000,
        fontSize: '10px',
    },

    inLineStyle: {
         display: 'inline-block'
    },
    toolTipText: {
        borderBottom: `2px dotted ${theme.colors.gray[7]}`,
    },
      balanceButton: {
    backgroundColor: theme.colors.dark[7], // Match background color (adjust as needed)
    '&:hover': {
      backgroundColor: theme.colors.gray[8], // Slightly darker gray on hover
    }
  }
}));



export const Invoices: React.FC = () => {
    const theme = useMantineTheme();
    const { classes } = useStyles();
    const {invoiceData} = useUsage();


return (
    <Box>
        <Paper p="md" radius="md">
            <p style={{
                display: 'inline',
                marginRight: '0px',
                color: theme.colors.gray[1],
                fontWeight: '700',
                marginLeft: '0px',
                fontSize: '14px'
            }}>Invoices</p>
            <Divider my="sm"/>

            <Grid columns={12}>
                <Grid.Col span={3}>
                    <Text size="xs" className={classes.title}>MONTH
                        FROM</Text>
                </Grid.Col>
                <Grid.Col span={3}>
                    <Text size="xs" className={classes.title}>STATE</Text>
                </Grid.Col>
                <Grid.Col span={6}>
                    <Text align={"right"} size="xs" mr={15} className={classes.title}>BALANCE</Text>
                </Grid.Col>
            </Grid>
            <div style={{
                borderBottom: '1px solid',
                borderColor: theme.colors.gray[5],
                width: '100%',
                marginTop: theme.spacing.xs,
                marginBottom: theme.spacing.xs
            }}></div>
            {/*if there are no invoices to map dispally "no invoices"*/}
            {invoiceData.length === 0 && (
                <Text align="center" size="xs" color={theme.colors.gray[4]}>
                    No payments have been made yet on this account.
                </Text>
            )}

            {invoiceData.map((item, index) => {
              return (
                  <Grid columns={12} key={index}>
                      <Grid.Col span={3}>
                          <Text mt={7} className={classes.data} weight={500}>{item.month}</Text>
                      </Grid.Col>
                      <Grid.Col span={3}>
                          <Badge fz={'10px'} p={10} radius={'xs'}
                                 color={item.state === 'Paid' ? 'green' : 'red'}
                                 variant="light"
                          >
                              {item.state}
                          </Badge>
                      </Grid.Col>
                      <Grid.Col span={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button
                          mt={7}
                          mr={-10}
                          className={classes.balanceButton}
                        >
                            <Text>${item.balance}</Text>
                            <ChevronRight size={15} style={{ marginLeft: 5, marginRight: 0}} />
                        </Button>
                      </Grid.Col>

                      <div style={{
                          borderBottom: '1px solid',
                          borderColor: theme.colors.gray[7],
                          width: '100%',
                          marginLeft: 10,
                          marginRight: 10,
                          marginTop: 0,
                          marginBottom: 8
                      }}></div>
                  </Grid>
              );
            })}
        </Paper>
    </Box>
);

};

export default Invoices;
