interface Params {
    [key: string]: any;
}

const getGoCommand = (url: string, parameters: Params, currentParameters: Params): string => {
    let command = `package main\n\nimport (\n\t"bytes"\n\t"net/http"\n\t"io/ioutil"\n\t"log"\n)\n\nfunc main() {`;

    let data = {...parameters};
    for(let param in data) {
        if(data[param] === currentParameters[param]) {
            delete data[param];
        }
    }

    if(Object.keys(data).length > 0) {
        let jsonIndent = '\t\t';
        let dataString = JSON.stringify(data, null, 2).split('\n').map(line => jsonIndent + line).join('\n');
        command += `\n\tdata := []byte(\`${dataString}\`)\n`;
    }

    command += `\n\treq, err := http.NewRequest(`;
    command += `\n\t\t"POST", `;
    command += `\n\t\t"${url}", `;
    command += `\n\t\tbytes.NewBuffer(data)`;
    command += `\n\t)`;
    command += `\n\tif err != nil {\n\t\tlog.Fatal("Error on creating request object: ", err.Error())\n\t\treturn\n\t}\n`;
    command += `\n\treq.Header.Set("Content-Type", "application/json")`;
    command += `\n\treq.Header.Set("api-key", "YOUR_NUTRICLOUD_API_KEY")`;
    command += `\n\n\tclient := &http.Client{}`;
    command += `\n\tresp, err := client.Do(req)\n\tif err != nil {\n\t\tlog.Fatal("Error on dispatching request: ", err.Error())\n\t\treturn\n\t}\n`;
    command += `\n\tbody, _ := ioutil.ReadAll(resp.Body)\n\tlog.Println("Response: ", string(body))\n\tresp.Body.Close()\n}`;

    return command;
};

export default getGoCommand;
