import React, { useState } from 'react';
import {Card, Image, Text, Group, Button, createStyles} from '@mantine/core';

// Define types for the recipe

const useStyles = createStyles((theme) => ({
  glowTitleText: {
    fontSize: '2rem',
    fontWeight: 500,
    maxWidth: 900,
    lineHeight: '1.1',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    height: '100%', // Ensure the container takes full available height

    '@media (min-width: 768px)': {
      flexWrap: 'nowrap',
    },
  },

  leftContent: {
    flex: '0 0 100%',
    display: 'flex',
    justifyContent: 'center', // Horizontally center
    alignItems: 'center', // Vertically center
    padding: 50,
    '@media (min-width: 768px)': {
      flex: '0 0 40%',
    },
  },

  rightContent: {
    flex: '0 0 100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center', // Vertically center
    textAlign: 'left', // Center the text as well
    paddingLeft: 40,
    paddingTop: 40,
    paddingBottom: 40,
    '@media (min-width: 768px)': {
      flex: '0 0 60%',
    },
  },


  // Ensure image fills its container but doesn't exceed its original size
  image: {
    maxWidth: '100%',
    height: 'auto',
  },

    paragraphText: {
        fontSize: 16,
        lineHeight: 1.5,
        marginTop: 20,
        marginRight: 80,
    }
}));

// The RecipeGenerator component
const GeolocationTab = () => {
  const { classes } = useStyles();
  const ServicesColor = "#994fb2";

    const imageUrl = `${process.env.PUBLIC_URL}/landingPage/locationService.png`;


  return (
    <div className={classes.container}>
        <div className={classes.leftContent}>
            <img src={imageUrl} style={{ width: '100%', height: 'auto' }} alt="Your SVG Description"/>
        </div>
        <div className={classes.rightContent}>
            <Text className={classes.glowTitleText}
                  >Personalized</Text>
            <Text className={classes.glowTitleText} style={{color: ServicesColor}}>
                Geolocation APIs</Text>
            <p className={classes.paragraphText}>Transform how users find dining and shopping spots with our Personalized Local Discovery API. Tailored to individual preferences, it locates the best restaurants, grocery stores, and farmers markets, offering a curated experience beyond what general mapping services provide. Offering a new era in food recommendation.</p>
            <Button size={'md'} w={200} bg={ServicesColor} mt={40}>More details</Button>

        </div>
    </div>
  );
};

export default GeolocationTab;
