import React from 'react';
import {Paper, Col, Text, Code, useMantineTheme, Divider} from '@mantine/core';
import {createStyles} from "@mantine/styles";
import {Documentation, RequestBodyItem} from "./BaseDocumentation";
import DocsPage from "../../Documentation/DocsPage";
import ApiReferencePage from "../ApiReferencePage";
import getPythonCode from "../../PlayGround/Components/DynamicCodeGenerators/Python";
import {useNavigate} from "react-router-dom";

const useStyles = createStyles((theme) => ({
    paper: {
        padding: theme.spacing.md,
        margin: theme.spacing.md,
    },
    title: {
        marginBottom: theme.spacing.md,
    },
    code: {
        wordBreak: 'break-all',
    },
}));

const FoodClassification = () => {
    const theme = useMantineTheme();
    const navigate = useNavigate();
    const uri = `/api/classify/food/`;
    const httpMethod = `POST`;
    const endpointName = `Food Classification`;
    const parameters = {
      "query": "Black eyes peas",
      "limit": 5,
      "classification_types": [
         "allergy",
         "diet",
         "branded",
         "texture",
         "flavor_profile",
         "cuisine",
         "seasonality",
         "freshness_score",
         "price_point",
         "caloric_density",
         "health_index",
         "color_profile"
      ]
    }

    const exampleResponse = `{
        "status": 200,
        "data": {
          "allergy": [],
          "diet": [
            "Vegan",
            "Vegetarian",
            "Gluten Free",
            "Dairy Free",
            "Low FODMAP"
          ],
          "branded": {
            "is_branded": false,
            "brand": null
          },
          "texture": [
            "tender",
            "creamy",
            "grainy"
          ],
          "flavor_profile": {
            "sweet": 1,
            "salty": 2,
            "sour": 0,
            "bitter": 1,
            "umami": 3,
            "spicy": 0
          },
          "seasonality": [
            "Late Summer",
            "Early Fall"
          ],
          "freshness_score": 18,
          "price_point": "$",
          "caloric_density": "low",
          "health_index": 70,
          "color_profile": [
            "Black",
            "Brown"
          ]
        }
    }
    `;

    const uriDescription = `Returns classifications for food items, including allergens, diets, cuisines, etc. Multiple classification types can be requested in one query, with customizable result limits per type. Useful for food categorization and filtering. For workloads with only recipes, use the Recipe Classification endpoint for optimized performance.`;

    const requestBody: RequestBodyItem[] = [
        {
            name: "query",
            dataType: "string",
            required: true,
            defaultValue: "",
       description: "The name of the food item to analyze. This can be any food name like 'apple', 'chicken curry', or 'chocolate cake'. The more specific the name, the more accurate the classifications will be.",
        },
        {
            name: "classification_types",
            dataType: "array",
            required: true,
            defaultValue: '["allergy", "flavor_profile", "diet"]',
       description: "Array of classification types to analyze. For example, 'allergy' will check for common allergens like nuts, dairy, eggs, etc. Multiple classification types can be requested in a single query.",
            reactComponent: (
                <div>
                    <Text mt={10} fz={'sm'} fw={400} color={theme.colors.gray[3]}>Accepted Classification types:</Text>
                    <Code>
                        {`[
                        "allergy",
                        "diet",
                        "branded",
                        "cuisine",
                        "texture",
                        "seasonality",
                        "freshness_score",
                        "price_point",
                        "flavor_profile",
                        "caloric_density",
                        "health_index",
                        "color_profile"
                    ]`}
                    </Code>
                    <Text mt={10} fz={'sm'} fw={400} color={theme.colors.gray[3]}>
                      To learn about each classification type{' '}
                      <span
                        onClick={() => navigate('/docs/classify')}
                        style={{ cursor: 'pointer',  color: theme.colors.orange[0] }}
                      >
                        see the docs.
                      </span>
                    </Text>
                </div>
            ),
        },
        {
            name: "limit",
            dataType: "number",
            required: false,
            defaultValue: '10',
       description: "Controls the maximum length of returned classification lists. For example, if limit=3 and the food contains 5 potential allergens, only the top 3 allergens will be returned. Must be between 1 and 10.",
        }
    ];


    return (
        <Documentation
            httpMethod={httpMethod}
            endpointName={endpointName}
            parameters={parameters}
            exampleResponse={exampleResponse}
            uri={uri}
            urlDescription={uriDescription}
            requestBody={requestBody}
        />
    );
}

const RecipeClassification = () => {
    const theme = useMantineTheme();
    const navigate = useNavigate();
    const uri = `/api/classify/recipe/`;
    const httpMethod = `POST`;
    const endpointName = `Recipe Classification`;
    const parameters = {
      "query": "lemon scallop fettuccini Alfredo",
      "limit": 5,
      "classification_types": [
        "allergy",
        "texture",
        "freshness_score",
        "flavor_profile",
        "price_point",
        "health_index"
      ]
    }

    const exampleResponse = `{
        "status": 200,
        "data": {
          "allergy": [
            "Shellfish",
            "Wheat",
            "Milk"
          ],
          "texture": [
            "creamy",
            "tender",
            "smooth",
            "juicy"
          ],
          "freshness_score": 10,
          "flavor_profile": {
            "sweet": 2,
            "salty": 4,
            "sour": 3,
            "bitter": 0,
            "umami": 6,
            "spicy": 0
          },
          "price_point": "$$$",
          "health_index": 45
        }
    }
    `;

    const uriDescription = `Returns classifications for recipes, including allergens, diets, cuisines, etc. Multiple classification types can be requested in one query, with customizable result limits per type. Useful for recipe categorization and filtering. For workloads with general food items, use the Food Classification endpoint instead.`;

    const requestBody: RequestBodyItem[] = [
        {
            name: "query",
            dataType: "string",
            required: true,
            defaultValue: "",
       description: "The name of the food item to analyze. This can be any food name like 'apple', 'chicken curry', or 'chocolate cake'. The more specific the name, the more accurate the classifications will be.",
        },
        {
            name: "classification_types",
            dataType: "array",
            required: true,
            defaultValue: '["allergy", "flavor_profile", "diet"]',
       description: "Array of classification types to analyze. For example, 'allergy' will check for common allergens like nuts, dairy, eggs, etc. Multiple classification types can be requested in a single query.",
                        reactComponent: (
                <div>
                    <Text mt={10} fz={'sm'} fw={400} color={theme.colors.gray[3]}>Accepted Classification types:</Text>
                    <Code>
                        {`[
                        "allergy",
                        "diet",
                        "branded",
                        "cuisine",
                        "texture",
                        "seasonality",
                        "freshness_score",
                        "price_point",
                        "flavor_profile",
                        "caloric_density",
                        "health_index",
                        "color_profile"
                    ]`}
                    </Code>
                    <Text mt={10} fz={'sm'} fw={400} color={theme.colors.gray[3]}>
                      To learn about each classification type{' '}
                      <span
                        onClick={() => navigate('/docs/classify')}
                        style={{ cursor: 'pointer',  color: theme.colors.orange[0] }}
                      >
                        see the docs.
                      </span>
                    </Text>
                </div>
            ),
        },
        {
            name: "limit",
            dataType: "number",
            required: false,
            defaultValue: '10',
       description: "Controls the maximum length of returned classification lists. For example, if limit=3 and the food contains 5 potential allergens, only the top 3 allergens will be returned. Must be between 1 and 10.",
        },
    ];

    return (
        <Documentation
            httpMethod={httpMethod}
            endpointName={endpointName}
            parameters={parameters}
            exampleResponse={exampleResponse}
            uri={uri}
            urlDescription={uriDescription}
            requestBody={requestBody}
        />
    );
}

const ClassifyApiOverview = () => {
    const { classes } = useStyles();
    const theme = useMantineTheme();
        const navigate = useNavigate();


    return (
        <div style={{margin: 40}}>
            <Text mt={80} fz={52} color={theme.colors.gray[2]}>Classify</Text>
            <Text>Learn how to generate inference for foods</Text>
            <Text mt={10} fz={'sm'} fw={400} color={theme.colors.gray[4]}>
              Related docs: {' '}
              <span
                onClick={() => navigate('/docs/classify')}
                style={{ cursor: 'pointer',  color: theme.colors.orange[0] }}
              >
                Classification Docs
              </span>
            </Text>
        </div>
    );
}

export function ClassifyApiReference() {
    const { classes } = useStyles();
     const theme = useMantineTheme();

    return (
        <div>
            <ApiReferencePage children={
                <div>
                    <ClassifyApiOverview/>
                    <Divider ml={40} mr={40} color={theme.colors.gray[6]}></Divider>
                    <FoodClassification/>
                    <RecipeClassification/>
                </div>
            }
                              activeItem="classify"
            />

        </div>
    );
}
