import {
    TextInput,
    PasswordInput,
    Anchor,
    Paper,
    Title,
    Text,
    Container,
    Group,
    Button,
    Divider,
    Grid,
    Col, useMantineTheme,
} from '@mantine/core';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {createStyles} from "@mantine/styles";
import {FormEvent, useEffect, useState} from "react";
import qs from "qs";
import NutricloudLogo from "../../componenets/logo/NutriCloudLogo";
import OtpInput from "./otpInput";
import OtpVerification from "./otpInput";


const useStyles = createStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100vh',
        justifyContent: 'space-between',
        paddingTop: '0%',
    },
    links: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '2rem',
    },
    link: {
        fontSize: '0.9rem',
        color: theme.colorScheme === 'dark' ? theme.colors.orange[0] : theme.colors.gray[7],
    },
    description: {
        fontSize: '.9rem',
        color: theme.colorScheme === 'dark' ? theme.colors.gray[3] : theme.colors.gray[6],
        textAlign: 'center',
    },
    title: {
        fontFamily: `Greycliff CF, ${theme.fontFamily}`,
        fontWeight: 600 ,
        fontSize: theme.fontSizes.xl,
        textAlign: 'center',
        color: theme.colorScheme === 'dark' ? theme.colors.gray[2] : theme.colors.gray[6],
    },
    form: {
        marginTop: -30,
    },

    textInput: {
        width: "100%",
        color: theme.colors.gray[1]
    },

    centeredContent: {
        display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      flexGrow: 1,
      width: "100%",
    },
}));


export function VerifyPhoneNumber() {
    const theme = useMantineTheme();
    const navigate = useNavigate();
        const location = useLocation();
    const { classes } = useStyles();

    const [phone, setPhone] = useState('');
    const [authError, setAuthError] = useState<string | null>(null);


    function formatPhoneNumber(phone: string): string {
    // Check if the phone string contains exactly 10 digits
    const cleanPhone = phone.replace(/\D/g, ''); // Removes anything that's not a digit
    if (cleanPhone.length === 10) {
        // Format and return the phone number
        return `(${cleanPhone.substring(0, 3)}) ${cleanPhone.substring(3, 6)}-${cleanPhone.substring(6)}`;
    } else {
        // Return the input if it doesn't match the expected format
        return phone;
    }
}


    useEffect(() => {
        // Parse the query parameters
        const queryParams = new URLSearchParams(location.search);
        let phoneParam = queryParams.get('phone');

        if (phoneParam) {
            // Attempt to correct encoding issues specifically for the plus sign
            if (phoneParam.startsWith(" ") || !phoneParam.includes('+') && phoneParam.includes('%2B')) {
                phoneParam = phoneParam.replace(" ", "+").replace('%2B', '+');
            }
            const phoneNumber = decodeURIComponent(phoneParam);
            setPhone(formatPhoneNumber(phoneNumber));
        }
    }, [location]);


    useEffect(() => {
        // This effect runs whenever the `token` state changes
        if (phone) {
            console.log(phone); // This will log the updated token value
        }
    }, [phone]); // Dependency array includes `token` to ensure effect runs on token changes


    return (
        <div className={classes.root}>
            <div style={{marginTop: 20}}>
                <NutricloudLogo size={50}/>
            </div>
            <div className={classes.centeredContent}>
            <Container size={620} my={40}>

                {/*title*/}
                <Title
                    align="center"
                    className={classes.title}
                >
                    OTP Verification
                </Title>
                <Text style={{textAlign: "center"}}>Enter the code sent to <span style={{fontWeight: 600}}>{phone}</span></Text>
                <div>
                    <OtpVerification/>
                </div>

            </Container>
                </div>

        </div>
    );
}
