interface Params {
    [key: string]: any;
}

const getTypeScriptCommand = (url: string, parameters: Params, currentParameters: Params): string => {
    let command = `import axios from 'axios';\n\n`;

    let data = {...parameters};
    for(let param in data) {
        if(data[param] === currentParameters[param]) {
            delete data[param];
        }
    }

    if(Object.keys(data).length > 0) {
        let jsonIndent = '    ';
        let dataString = JSON.stringify(data, null, 2).split('\n').map(line => jsonIndent + line).join('\n');
        command += `const data = ${dataString};\n\n`;
    } else {
        command += `const data = {};\n\n`;
    }

    command += `axios.post('${url}', data, {\n`;
    command += `    headers: {\n`;
    command += `        'Content-Type': 'application/json',\n`;
    command += `        'api-key': 'YOUR_NUTRICLOUD_API_KEY',\n`;
    command += `    }\n`;
    command += `})\n`;
    command += `.then((response) => {\n`;
    command += `    console.log(response.data);\n`;
    command += `})\n`;
    command += `.catch((error) => {\n`;
    command += `    console.error(\`Error: \${error}\`);\n`;
    command += `});`;

    return command;
};

export default getTypeScriptCommand;
