import React from 'react';
import { createStyles, Text } from '@mantine/core';
import {ChevronRight} from "react-feather";

const useStyles = createStyles((theme) => ({
  animatedLink: {
    display: 'inline-flex',
    alignItems: 'center',
    cursor: 'pointer',
    position: 'relative',
    textDecoration: 'none',
    color: theme.colorScheme === 'dark' ? theme.white : theme.black,

    // this is where the magic happens for the animated underline
    '&:hover:after': {
      width: '100%',
    },

    // pseudo-element for the animated underline
    '&:after': {
      content: '""',
      position: 'absolute',
      width: 0,
      height: '2px',
      bottom: 0,
      left: 0,
      backgroundColor: theme.colorScheme === 'dark' ? theme.white : theme.black,
      transition: 'width 0.3s ease',
    },
  },
  icon: {
    marginLeft: theme.spacing.sm,
  },
}));

type AnimatedLinkProps = {
  text: string;
  path: string;
};

const AnimatedLink: React.FC<AnimatedLinkProps> = ({ text, path }) => {
  const { classes } = useStyles();
  return (
      <div>
        <a href={path} className={classes.animatedLink}>
          <Text>{text}</Text>
          <ChevronRight className={classes.icon} />
        </a>
      </div>
  );
};

export default AnimatedLink;
