import React from 'react';
import {
    Paper,
    Text,
    Flex,
    Container,
    createStyles,
    Col,
    Grid,
    useMantineTheme, Accordion, Box, Center
} from '@mantine/core';
import { FaHome, FaUser, FaEnvelope, FaPhone, FaSearch, FaHeart, FaComment, FaBars } from 'react-icons/fa';
import {Link, useNavigate} from 'react-router-dom';
import { FaInfoCircle } from 'react-icons/fa';
import {Prism} from "@mantine/prism";
import FeedbackBanner from "../../Documentation/Components/Feedback";
import DocsPage from "../../Documentation/DocsPage";
import ApiReferencePage from "../ApiReferencePage";


const useStyles = createStyles((theme) => ({
    iconBox: {
        display: 'flex',
        justifyContent: 'flex-start', // Align items to the start (left side)
        alignItems: 'center',
        marginBottom: theme.spacing.md,
        '& svg': {
            transition: 'transform 0.3s ease',
        },
        '&:hover svg': {
            transform: 'scale(1.2)',
        },
    },
    contentBox: {
        marginLeft: theme.spacing.md, // Add some space between the icon and the text
    },

    // headers
    pageTitle: {
        textAlign: "left",
        fontSize: theme.fontSizes.xxl,
        fontWeight:600,
        marginTop: "30px",
        marginBottom: "0px",
    },
    mainHeader: {
        fontWeight: 500,
        fontSize: theme.fontSizes.xl,
        marginTop: "0px",
        marginBottom: "10px",
    },
    subHeader: {
        fontWeight: 500,
        color: theme.colors.gray[3],
        fontSize: theme.fontSizes.md,
        marginTop: "0px",
        marginBottom: "0px",
    },

    // bullet list
    link: {
        color: theme.colors.orange[0],
        textDecoration: 'none',
        '&:hover': {
            color: theme.colors.orange[9],  // Change this to the color you want on hover
        },
    },
    bulletTitle: {
        color: theme.colors.gray[0],
        fontWeight: 600,
    },
    bulletList: {
        listStyleType: 'disc',
    },
     numberedList: {
        listStyleType: 'decimal',
    },
    bulletPoint: {
        fontSize: theme.fontSizes.sm,
        margin: '5px 0',
    },
    // text
    paragraphText: {
        fontSize: '16px',
        marginBottom: "10px",
    },

    // info boxes
    infoBox: {
        backgroundColor: theme.colors.gray[8],
        padding: theme.spacing.md,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        width: '100%',
        marginTop: '10px',
        marginBottom: '20px',
    },
    infoText: {
        marginLeft: theme.spacing.sm,
        fontSize: '16px',
    },
    infoIcon: {
        fontSize: '1em',
        flexShrink: 0,
    },

              gradientBorderBox: {
    display: 'inline-flex',
        backgroundImage: theme.fn.gradient({ from: 'pink', to: 'orange' }),
    borderRadius: '5px',
    padding: '2px', // Controls the thickness of the gradient border
    alignItems: 'center',
           // marginLeft: "50px",
    justifyContent: 'center',
      width: '300px',

      '@media (max-width: 800px)': {
        marginLeft: 0,
    },

  },
  innerBox: {
    backgroundColor: theme.colors.dark[7], // Should match the page's background
    borderRadius: '5px',
      width: '300px',
    paddingLeft: "30px",
    paddingRight: "30px",
    paddingTop: "5x",
    paddingBottom: "0px",
      color: theme.colors.gray[1]
  },
            accordionSubHeader: {
        fontWeight: 500,

        color: theme.colors.gray[3],
        fontSize: theme.fontSizes.md,
        marginTop: "0px",
        marginBottom: "15px",
    },
}));

// create gradient boxes
function AuthenticationAPIReference() {
    const { classes } = useStyles();
    const theme = useMantineTheme();
    const navigate = useNavigate();

    return (
        <Container>
            {/*title*/}
            <Text className={classes.pageTitle}>Authentication</Text>
            <Text className={classes.subHeader} style={{color: theme.colors.gray[5]}}>Learn
                how to authenticate with the NutriCloud API</Text>

            {/*api keys*/}
            <Text mt={50} className={classes.mainHeader}>API Keys</Text>
            <Text className={classes.paragraphText}>Our API platform utilizes API keys
                for authentication and authorization. You can create and manage your API
                keys directly from your developer dashboard. Each API key can be
                configured with specific permissions on an endpoint basis, allowing
                fine-grained control over what actions can be performed with that
                key.</Text>

            {/*creating api keys*/}
            <Text mt={30} className={classes.subHeader}>Creating API Keys</Text>
            <Text className={classes.paragraphText}>To create a new API key:</Text>
            <ol className={classes.numberedList}>
                <li className={classes.bulletPoint}>
                    <span
                    onClick={() => navigate('/login')}
                    style={{cursor: 'pointer', color: theme.colors.orange[0]}}
                    >Log in</span> to your developer dashboard.
                </li>
                <li className={classes.bulletPoint}>
                    Navigate to the <span
                    onClick={() => navigate('/api-keys')}
                    style={{cursor: 'pointer', color: theme.colors.orange[0]}}
                >
                API Keys page.
              </span>
                </li>
                <li className={classes.bulletPoint}>
                    Click on Create New API Key.
                </li>
                <li className={classes.bulletPoint}>
                    Assign Permissions: Select the endpoints you wish to allow or deny
                    for this API key (e.g., allow search and classification, deny chat).
                </li>
                <li className={classes.bulletPoint}>
                    Generate Key: Click Generate to create the API key.
                </li>
                <li className={classes.bulletPoint}>
                    Secure Storage: Copy and store your new API key securely.
                </li>
            </ol>

            {/*Permissions managemnet*/}
            <Text mt={30} className={classes.subHeader}>Permissions Management</Text>
            <Text className={classes.paragraphText}>When creating or editing an API key,
                you can set permissions on an endpoint basis:</Text>
            <ul className={classes.bulletList}>
                <li className={classes.bulletPoint}>
                    <strong>Allow</strong>: Grants access to the specified endpoint.
                </li>
                <li className={classes.bulletPoint}>
                    <strong>Deny</strong>: Blocks access to the specified endpoint.
                </li>
            </ul>

            <Text className={classes.paragraphText}>This enables you to:</Text>

            <ul className={classes.bulletList}>
                <li className={classes.bulletPoint}>
                    Restrict certain keys to specific functionalities.
                </li>
                <li className={classes.bulletPoint}>
                    Implement different access levels for development, staging, and
                    production environments.
                </li>
                <li className={classes.bulletPoint}>
                    Enhance security by limiting exposure of sensitive operations.
                </li>
            </ul>

            {/*Best practices*/}
            <Text mt={30} className={classes.subHeader}>Security Best Practices</Text>
            <ul className={classes.bulletList}>
                <li className={classes.bulletPoint}>
                    <strong>Keep It Secret</strong>: Your API key is a secret
                    credential. Do not share it or expose it in client-side code (e.g.,
                    browsers, mobile apps).
                </li>
                <li className={classes.bulletPoint}>
                    <strong>Backend Usage</strong>: Use your API key in server-side
                    applications where it can be securely stored and accessed.
                </li>
                <li className={classes.bulletPoint}>
                    <strong>Environment Variables</strong>: Load your API key from
                    environment variables or a key management service.
                </li>
            </ul>

            {/*using api keys*/}
            <Text mt={50} className={classes.mainHeader}>Using API Keys</Text>
            <Text className={classes.paragraphText}>All API requests must include your
                API key in the Authorization HTTP header as follows:</Text>
            <Prism language={"sql"}>
                {`Authorization: api-key <insert_api_key_here>`}
            </Prism>

            <Text mt={30} className={classes.subHeader}>Example with cURL</Text>
            <Prism language={"bash"}>
                {`curl https://api.yourplatform.com/v1/search \\
  -H "Authorization: api-key YOUR_API_KEY" \\
  -H "Content-Type: application/json" \\
  -d '{"query": "example search term"}'
`}
            </Prism>
            <Text mt={30} className={classes.subHeader}>Example in Python</Text>
            <Prism language={"python"}>
                {`import requests

headers = {
    "Authorization": "api-key YOUR_API_KEY",
    "Content-Type": "application/json"
}

data = {
    "query": "example search term"
}

response = requests.post("https://api.yourplatform.com/v1/search", headers=headers, json=data)
print(response.json())
`}
            </Prism>

            {/*Billing and usage*/}
            <Text mt={50} className={classes.mainHeader}>Billing and Usage</Text>
            <Text className={classes.paragraphText}>API keys are tied to your developer
                account. Any usage associated with an API key will be billed to your
                account according to our pricing plan. You can monitor your <span
                    onClick={() => navigate('/usage')}
                    style={{cursor: 'pointer', color: theme.colors.orange[0]}}
                >usage</span> and
                <span
                    onClick={() => navigate('/account/billing/overview')}
                    style={{cursor: 'pointer', color: theme.colors.orange[0]}}
                > billing</span> details in the developer dashboard under the Usage
                section.</Text>
            <ol className={classes.bulletList}>
                <li className={classes.bulletPoint}>
                    <strong>Real-Time Monitoring</strong>: Track your API usage on
                    the <span
                    onClick={() => navigate('/usage')}
                    style={{cursor: 'pointer', color: theme.colors.orange[0]}}
                >usage dashboard page.</span>
                </li>
                <li className={classes.bulletPoint}>
                    <strong>Usage Alerts</strong>: Set up alerts to notify you when you
                    reach certain usage thresholds.
                </li>
                <li className={classes.bulletPoint}>
                    <strong>Detailed Reports</strong>: Download detailed usage reports
                    for analysis and record-keeping.
                </li>
            </ol>

            <span
                onClick={() => navigate('/account/billing/overview')}
                style={{cursor: 'pointer', color: theme.colors.orange[0]}}
            >
                See the billing page
              </span>


            {/*error handling*/}
            <Text mt={50} className={classes.mainHeader}>Error Handling</Text>
            <Text className={classes.paragraphText}>If an API request is made to an
                endpoint that the API key does not have permission to access, the server
                will respond with an appropriate HTTP status code and error
                message.</Text>
            <Text mt={30} className={classes.subHeader}>Common Error Responses</Text>
            <Text mt={20} className={classes.paragraphText}><strong>401
                Unauthorized:</strong> Missing or invalid API key.</Text>
            <Prism language={"bash"}>
                {`{
  "error": {
    "code": 401,
    "message": "Unauthorized: API key is missing or invalid."
  }
}
`}
            </Prism>

            <Text mt={20} className={classes.paragraphText}><strong>403
                Forbidden:</strong> Insufficient permissions for the requested endpoint.</Text>
            <Prism language={"bash"}>
                {`{
  "error": {
    "code": 403,
    "message": "Forbidden: You do not have permission to access this endpoint."
  }
}
`}
            </Prism>

            <Text mt={20} className={classes.paragraphText}><strong>429 Too Many
                Requests:</strong> Rate limit exceeded.</Text>
            <Prism language={"bash"}>
                {`{
  "error": {
    "code": 429,
    "message": "Too Many Requests: You have exceeded your rate limit."
  }
}
`}
            </Prism>

            <span
                onClick={() => navigate('/docs/api-reference/debugging-requests')}
                style={{cursor: 'pointer', color: theme.colors.orange[0]}}
            >
                See debugging requests
              </span>


            {/*best practices*/}
            <Text mt={50} className={classes.mainHeader}>Best Practices for API Key
                Management</Text>
            <ul className={classes.bulletList}>
                <li className={classes.bulletPoint}>
                    <strong>Least Privilege Principle</strong>: Grant the minimum
                    necessary permissions required for each API key.
                </li>
                <li className={classes.bulletPoint}>
                    <strong>Regular Rotation</strong>: Periodically regenerate your API
                    keys to reduce the risk of unauthorized access.
                </li>
                <li className={classes.bulletPoint}>
                    <strong>Separate Keys for Different Environments</strong>: Use
                    different API keys for development, testing, and production
                    environments.
                </li>
                <li className={classes.bulletPoint}>
                    <strong>Monitor Access</strong>: Regularly review your API logs to
                    detect any unusual activity.
                </li>
            </ul>


            <FeedbackBanner/>
        </Container>
    );
}

export default function AuthenticationAPIReferencePage() {
    return (
        <div>
            <ApiReferencePage children={<AuthenticationAPIReference/>}
                              activeItem={"authentication"}/>
        </div>
    );
}
