import { Group, Button } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { FiThumbsUp, FiThumbsDown } from 'react-icons/fi';
import zIndex from "@mui/material/styles/zIndex";

function FeedbackBanner() {

    const handleFeedback = () => {
        notifications.show({
            autoClose: 4000, // 4 seconds
            title: 'Thanks for your support!',
            message: 'We appreciate your help in making our website more intuitive.',
        });
    };

    return (
        <Group position="left" my={60} style={{zIndex: 2000}}>
            <p style={{fontSize: '16px',}}>Did you find this page helpful?</p>
            <Group position="left">
                <Button onClick={handleFeedback} variant="link"><FiThumbsUp /></Button>
                <Button onClick={handleFeedback} variant="link"><FiThumbsDown /></Button>
            </Group>
        </Group>
    );
}

export default FeedbackBanner;
