import React from 'react';
import { createStyles } from '@mantine/core';

const useStyles = createStyles((theme) => ({
    button: {
        display: 'inline-flex',
        alignItems: 'center',
        border: '1px solid white',
        background: 'none',
        color: 'white',
        padding: `${theme.spacing.xs} ${theme.spacing.md}`,
        cursor: 'pointer',
        textDecoration: 'none',
        transition: 'all 0.2s',
        '&:hover': {
            backgroundColor: theme.colors.orange[0],
            border: '1px solid black',
        }
    },
    arrow: {
        marginLeft: theme.spacing.xs,
        borderTop: '1px solid white',
        borderRight: '1px solid white',
        width: '12px',
        height: '12px',
        transform: 'rotate(45deg)',
    },
    buttonText: {
        fontSize: '18px',
        fontWeight: 200,
        color: theme.colors.gray[0],
    },
}));

interface CustomButtonProps {
    text: string;
    href: string;
}

const WhiteWireFrameButton: React.FC<CustomButtonProps> = ({ text, href }) => {
    const { classes } = useStyles();

    return (
        <a className={classes.button} href={href}>
            <span className={classes.buttonText}>{text}</span>
            <div className={classes.arrow}></div>
        </a>
    );
}

export default WhiteWireFrameButton;
