import React from 'react';
import {
    Paper,
    Text,
    Flex,
    Container,
    createStyles,
    Col,
    Grid,
    Center, useMantineTheme, ScrollArea
} from '@mantine/core';
import { FaHome, FaUser, FaEnvelope, FaPhone, FaSearch, FaHeart, FaComment, FaBars } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { FaInfoCircle } from 'react-icons/fa';
import Demo from "../Components/Feedback";
import DocsPage from "../DocsPage";
import FeedbackBanner from "../Components/Feedback";
import {OverviewContentCard} from "../Components/leftRightCard";
import {Prism} from "@mantine/prism";
import AnimatedCardLink, {
} from "../Components/AnimatedBoxCardSimple";
import {
    Activity,
    ArrowUpRight, BarChart2, Book,
    Clock,
    Code,
    CreditCard,
    Lock,
    Repeat
} from "react-feather";
import AnimatedLink from "../../LandingPage/components/animatedLink";
import {GradientCard} from "../Components/GradientCard";
import {useViewportSize} from "@mantine/hooks";


const useStyles = createStyles((theme) => ({
    iconBox: {
        display: 'flex',
        justifyContent: 'flex-start', // Align items to the start (left side)
        alignItems: 'center',
        marginBottom: theme.spacing.md,
        '& svg': {
            transition: 'transform 0.3s ease',
        },
        '&:hover svg': {
            transform: 'scale(1.2)',
        },
    },
    contentBox: {
        marginLeft: theme.spacing.md, // Add some space between the icon and the text
    },

    // headers
    pageTitle: {
        textAlign: "left",
        fontSize: theme.fontSizes.xxl,
        fontWeight:600,
        marginBottom: "30px",
        marginTop: "100px",
    },
    mainHeader: {
        fontWeight: 500,
        fontSize: theme.fontSizes.xl,
        marginTop: "0px",
        marginBottom: "10px",
    },
    subHeader: {
        fontWeight: 500,
        fontSize: theme.fontSizes.lg,
        color: theme.colors.gray[3],
        // marginTop: "30px",
        marginBottom: "0px",
    },

    // bullet list
    link: {
        color: theme.colors.orange[0],
        textDecoration: 'none',
        '&:hover': {
            color: theme.colors.orange[9],  // Change this to the color you want on hover
        },
    },
    bulletList: {
        listStyleType: 'disc',
    },

    // text
    paragraphText: {
        fontSize: '16px',
        marginBottom: "10px",
        color: theme.colors.gray[3],
    },

    customText: {
        fontSize: '16px',
        color: theme.colors.gray[6]
    },

    // info boxes
    infoBox: {
        backgroundColor: theme.colors.gray[8],
        padding: theme.spacing.md,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        width: '100%',
        marginTop: '10px',
        marginBottom: '20px',
    },
    infoText: {
        marginLeft: theme.spacing.sm,
        fontSize: '16px',
    },
    infoIcon: {
        fontSize: '1em',
        flexShrink: 0,
    },
coloredBox: {
    border: `0.5px solid ${theme.colors.gray[8]}`,
    backgroundColor: theme.colors.gray[9],
        '&:hover': {
      backgroundColor: theme.colors.dark[5],
    },
    borderRadius: '8px',
    padding: 20,
    flex: '1 1 30%',  // Add this line to make boxes take equal space
    // minWidth: '250px', // Add this to prevent boxes from getting too narrow
},

    meetTheAPICardContainer: {
        display: 'flex',
        gap: '20px',
        flexDirection: 'row',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        [`@media (max-width: 1000px)`]: {
            flexDirection: 'column',
        },
    },
}));


function StartBuilding() {
    const { classes } = useStyles();
    const theme = useMantineTheme();
    const { width } = useViewportSize();

    const cards = [
        {
            title: "Playground",
            text: "Test and explore NutriClouds APIs",
            backgroundColor: '#4e3b33',
            icon: Code,
            path: "/playground"
        },
        {
            title: "Authentication",
            text: "Ensure Secure access to our APIs",
            backgroundColor: '#5b6182',
            icon: Lock,
            path: "/docs/api-reference/authentication"
        },
        {
            title: "Usage",
            text: "Monitor API usage and credit balance",
            backgroundColor: '#575966',
            icon: BarChart2,
            path: "/usage"
        },


            {
                title: "Rate limits",
                text: "Understand how rate limits work",
                backgroundColor: '#3a4961',
                icon: Activity,
                path: "/docs/guides/rate-limits"
            },
            {
                title: "Terms and policies",
                text: "Review our terms and policies",
                backgroundColor: '#454e57',
                icon: Book,
                path: "/policies"
            },
        {
            title: "Making requests",
            text: "Learn how to make requests to the API",
            backgroundColor: '#c25c53',
            icon: Repeat,
            path: "/docs/api-reference/making-requests"
        },

    ];

    return (
        <div style={{ marginTop: 70 }}>
            <Text mb={20} className={classes.subHeader}>Start Building</Text>

            {width > 1000 ? (
                <Grid gutter="md">
                    {cards.map((card, index) => (
                        <Grid.Col key={index} span={4}>
                            <GradientCard
                                title={card.title}
                                path={card.path}
                                backgroundColor={card.backgroundColor}
                                width={275}
                                height={250}
                                icon={card.icon}
                            >
                                <div>
                                    <Text className={classes.paragraphText}>{card.text}</Text>
                                </div>
                            </GradientCard>
                        </Grid.Col>
                    ))}
                </Grid>
            ) : (
                <ScrollArea>
                    <div style={{
                        display: 'flex',
                        gap: '1rem',
                        paddingBottom: '1rem',
                        minWidth: 'min-content'
                    }}>
                        {cards.map((card, index) => (
                            <div key={index} style={{ flexShrink: 0 }}>
                                <GradientCard
                                    title={card.title}
                                    path={card.path}
                                    backgroundColor={card.backgroundColor}
                                    width={275}
                                    height={250}
                                    icon={card.icon}
                                >
                                    <div>
                                        <Text className={classes.paragraphText}>{card.text}</Text>
                                    </div>
                                </GradientCard>
                            </div>
                        ))}
                    </div>
                </ScrollArea>
            )}
        </div>
    );
}
function MeetTheAPIs() {
    const {classes} = useStyles();

    return (
        <div style={{marginTop: 70, padding: 10}}>
            {/*title*/}
            <div style={
                {
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginBottom: "20px",
                }
            }>
                <Text className={classes.subHeader}>Meet the APIs</Text>
                <AnimatedLink text={"Pricing"} path={"/pricing"}/>
            </div>
            <div className={classes.meetTheAPICardContainer}>
                <div className={classes.coloredBox}>
                    <AnimatedCardLink title={"Search API"} path={"/docs/search/food"}
                                      reactchildren={
                        <div>
                                          <Text className={classes.paragraphText}>Search for both foods and recipes.</Text>
                                          <Text className={classes.customText}>Nutrient profiles</Text>
                                          <Text className={classes.customText}>Ingredients and directions</Text>
                                          <Text className={classes.customText}>Images</Text>
                        </div>
                        }/>
                </div>
                <div className={classes.coloredBox}>
                    <AnimatedCardLink title={"Classify API"} path={"/docs/classify"}
                                      reactchildren={
                        <div>
                                          <Text className={classes.paragraphText}>Get in-depth understandings of your foods and recipes.</Text>
                            <Text className={classes.customText}>Flavor profiles</Text>
                            <Text className={classes.customText}>Allergies</Text>
                            <Text className={classes.customText}>Textures</Text>
                        </div>
                        }/>
                </div>
                <div className={classes.coloredBox}>
                    <AnimatedCardLink title={"Recognition API"} path={"/docs/recognition"}
                                      reactchildren={
                        <div>
                                          <Text className={classes.paragraphText}>Gain inference on foods with just a photo.</Text>
                            <Text className={classes.customText}>Nutrient profiles</Text>
                            <Text className={classes.customText}>Ingredients</Text>
                            <Text className={classes.customText}>Directions</Text>
                        </div>
                        }/>
                </div>
            </div>
            <Center mt={20}>
                <AnimatedLink text={"Explore all"} path={"/docs/apis"}/>
            </Center>
        </div>
    );
}

function OverviewContentCardCreate() {
    const {classes} = useStyles();
    return (
        <div>
            <OverviewContentCard leftContent={
                <div>

                    <AnimatedCardLink title={"Developer quickstart"} path={"/docs/quickstart"}
                                      reactchildren={
                      <div>
                          <Text className={classes.paragraphText}>
                                Set up your environment and make your first API request in minutes
                            </Text>
                          <div style={{
                              display: 'flex',
                              alignItems: 'center',
                              flexDirection: 'row',
                              gap: '10px', // Adds consistent spacing between items
                              lineHeight: 1, // Ensures consistent line height
                          }}>
                              <Clock size={15}/>
                              <Text
                                  className={classes.paragraphText}
                                  style={{margin: 0}} // Reset any margins if needed
                              >
                                  5 min
                              </Text>
                          </div>
                      </div>
                  }
                />
            </div>
        } rightContent={
            <Prism language={"bash"}>
                {`curl -X POST \\
  'https://api.yourplatform.com/v1/classify/food/' \\
  -H 'Authorization: api-key: <key_here>' \\
  -H 'Content-Type: application/json' \\
  -d '{
    "query": "Chicken Fettuccine Alfredo",
    "classification_types": [
      "texture",
      "flavor_profile"
    ]
  }'`}
                        </Prism>
        }/>
        </div>

    );
}


function OverviewDocs() {
    const {classes} = useStyles();


    return (
        <Container>
            {/*title*/}
            <Text className={classes.pageTitle}>NutriCloud developer platform</Text>

            <OverviewContentCardCreate/>
            <MeetTheAPIs/>
            <StartBuilding/>
            <FeedbackBanner/>
        </Container>
    );
}

export default function OverviewDocsPage() {
    return (
        <div>
            <DocsPage children={<OverviewDocs/>} activeItem={"overview"} />
        </div>
    );
}
