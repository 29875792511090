interface Params {
    [key: string]: any;
}

const getJsCommand = (url: string, parameters: Params, currentParameters: Params): string => {
    let command = `const data = ${JSON.stringify(parameters, null, 2)};\n\n`;

    command += `fetch('${url}', {\n`;
    command += `    method: 'POST',\n`;
    command += `    headers: {\n`;
    command += `        'Content-Type': 'application/json',\n`;
    command += `        'api-key': 'YOUR_NUTRICLOUD_API_KEY',\n`;
    command += `    },\n`;

    let data = {...parameters};
    for(let param in data) {
        if(data[param] === currentParameters[param]) {
            delete data[param];
        }
    }

    if(Object.keys(data).length > 0) {
        let jsonIndent = '        ';
        let dataString = JSON.stringify(data, null, 2).split('\n').map(line => jsonIndent + line).join('\n');
        command += `    body: JSON.stringify(\n${dataString}\n    ),\n`;
    }

    command += `})\n`;
    command += `.then(response => response.json())\n`;
    command += `.then(data => console.log(data))\n`;
    command += `.catch((error) => {\n`;
    command += `  console.error('Error:', error);\n`;
    command += `});`;

    return command;
};

export default getJsCommand;
