import { createStyles, Paper, Text } from '@mantine/core';
import TwoColumn from "../TwoColumns";
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import {rem} from "@mantine/core";
import WhiteWireFrameButton from "../../../componenets/buttons/whiteWireFrameButton";

const useStyles = createStyles((theme) => ({
    pageTitle: {
        textAlign: 'left',
        marginTop: 80,
        marginBottom: 40,
        fontSize: '2.5rem',
        marginLeft: theme.spacing.md,
        color: theme.colors.gray[0],
        '@media (min-width: 600px)': {
            marginTop: 100,
            marginBottom: 60,
            fontSize: '3.5rem',
        },

        '@media (min-width: 1280px)': {
            marginTop: 150,
            marginBottom: 80,
            fontSize: '4.5625rem',
        },
    },

    header: {
        textAlign: 'left',
        fontSize: theme.fontSizes.lg,
        color: theme.colors.gray[3],
        fontWeight: 400,
        '@media (min-width: 600px)': {
            fontSize: theme.fontSizes.xl
        },

        '@media (min-width: 1280px)': {
            fontSize: theme.fontSizes.xxl,
        },
    },

    pageLine: {
        width: '97%',
        height: '2px',
        backgroundColor: theme.colors.gray[7],
        marginBottom: theme.spacing.md,
        marginLeft: 'auto', // Add this line
        marginRight: 'auto', // Add this line
    },

    paragraphText: {
        fontSize: '18px',
        fontWeight: 200,
        color: theme.colors.gray[0],
    },

    rightDiv: {
        marginLeft: '25px',
        '@media (min-width: 1280px)': {
            marginLeft: 0,
        },
    },

    leftDiv: {
        marginBottom: '40px',
    },

    separatingDiv: {
        height: '20px',
        '@media (min-width: 1280px)': {
            height: '30px',
        },
    },
    largeSeparatingDiv: {
        height: '60px',
        '@media (min-width: 1280px)': {
            height: '80px',
        },
    },

    paragraphBoldText: {
        fontWeight: 'bold',
    },

    table: {
        width: '100%',
        borderCollapse: 'collapse',
    },
    cell: {
        padding: theme.spacing.md,
        textAlign: 'left',
        borderBottom: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.gray[0] : theme.colors.gray[0]}`,
    },
    tableHeader: {
        borderBottomWidth: '2px',  // make the header line bolder
        fontWeight: 'bold',
    },
    lastRow: {
        borderBottom: 'none',  // remove the bottom border from the last row
    }
}));

export function CoordinatedVulnerabilityDisclosurePolicy() {
    const { classes } = useStyles();
    return (

        <div>
            <Text size="xxl" className={classes.pageTitle}>Coordinated vulnerability disclosure policy</Text>
            <div className={classes.pageLine}></div>
            {/* info*/}
            <TwoColumn
                left={
                    <div className={classes.leftDiv}>
                        <Text style={{fontWeight: 'bold'}} className={classes.paragraphText}>Updated</Text>
                        <Text className={classes.paragraphText}>August 1, 2023</Text>
                    </div>
                }
                right={
                    <div className={classes.rightDiv}>
                        <div className={classes.separatingDiv}></div>
                        <div className={classes.largeSeparatingDiv}></div>

                        <p className={classes.paragraphText}>Security is essential to OpenAI’s mission. We value the input of hackers acting in good faith to help us maintain a high standard for the security and privacy for our users and technology. This includes encouraging responsible vulnerability research and disclosure.</p>
                        <div className={classes.separatingDiv}></div>
                        <div className={classes.largeSeparatingDiv}></div>


                        <Text className={classes.header}>Bug bounty</Text>
                        <p className={classes.paragraphText}>The OpenAI Bug Bounty Program is a way for us to recognize and reward the valuable insights of security researchers who contribute to keeping our technology and company secure. We invite you to report vulnerabilities, bugs, or security flaws you discover in our systems. By sharing your findings, you will play a crucial role in making our technology safer for everyone.</p>
                        <p className={classes.paragraphText}>Detailed guidelines and rules for participation can be found on our Bug Bounty Program page.</p>
                        <div className={classes.separatingDiv}></div>
                        <div className={classes.largeSeparatingDiv}></div>

                        <Text className={classes.header}>Incident reporting</Text>
                        <p className={classes.paragraphText}>To report a security incident, please let us know immediately by submitting an encrypted report. Information shared with us in this manner must be shared unconditionally.</p>
                        <div className={classes.separatingDiv}></div>
                        <div className={classes.largeSeparatingDiv}></div>
                    </div>
                }
            />
        </div>
    );
}
