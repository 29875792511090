import {
    createStyles,
    Header,
    HoverCard,
    Group,
    Button,
    UnstyledButton,
    Text,
    SimpleGrid,
    ThemeIcon,
    Anchor,
    Divider,
    Center,
    Box,
    Burger,
    Drawer,
    Collapse,
    ScrollArea,
    rem,
} from '@mantine/core';
import {MantineLogo} from "@mantine/ds";
import { useDisclosure } from '@mantine/hooks';
import {
    IconNotification,
    IconCode,
    IconBook,
    IconChartPie3,
    IconFingerprint,
    IconCoin,
    IconChevronDown,
} from '@tabler/icons-react';
import { Link, useNavigate } from 'react-router-dom';
import {FullWidthCompanyLogo} from "../icons/FullWidthCompanyLogo";
import {StandardCompanyLogo} from "../icons/CompanyLogo";
import React from "react";
import {useAuth} from "../../AuthContext";
import axios from "axios";


const useStyles = createStyles((theme) => ({
    logoText: {
        marginTop: '0%',
        fontFamily: 'YourDesiredFont, sans-serif', // Replace 'YourDesiredFont' with the font you want to use
        color: theme.colors.white[1], // Replace '#336699' with the color you want to use (hex, rgb, contactUs.)
        fontSize: 24, // Adjust the font size to your preference
        fontWeight: 'bold', // Adjust the font weight to your preference
    },

    link: {
        display: 'flex',
        alignItems: 'center',
        height: '100%',
        paddingLeft: theme.spacing.md,
        paddingRight: theme.spacing.md,
        textDecoration: 'none',
        color: theme.colorScheme === 'dark' ? theme.colors.white : theme.colors.black,
        fontWeight: 500,
        fontSize: theme.fontSizes.sm,

        [theme.fn.smallerThan('sm')]: {
            height: rem(42),
            display: 'flex',
            alignItems: 'center',
            width: '100%',
        },

        ...theme.fn.hover({
            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
        }),
    },

    subLink: {
        width: '100%',
        padding: `${theme.spacing.xs} ${theme.spacing.md}`,
        borderRadius: theme.radius.md,

        ...theme.fn.hover({
            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[0],
        }),

        '&:active': theme.activeStyles,
    },

    dropdownFooter: {
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[0],
        margin: `calc(${theme.spacing.md} * -1)`,
        marginTop: theme.spacing.sm,
        padding: `${theme.spacing.md} calc(${theme.spacing.md} * 2)`,
        paddingBottom: theme.spacing.xl,
        borderTop: `${rem(1)} solid ${
            theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[1]
        }`,
    },

    hiddenMobile: {
        [`@media (max-width: ${1200}px)`]: {
            display: 'none',
        },
    },

    hiddenDesktop: {
        [`@media (min-width: ${1200}px)`]: {
            display: 'none',
        },
    },

    navbarContainer: {
        position: 'fixed',
        width: '100%',
        top: 0,
        zIndex: 9999 // To ensure the navbar stays above all other content
    },
}));

const APIReferenceData = [
    {
        icon: IconCode,
        title: 'Full Nutrient Profile',
        description: 'Retrieves comprehensive nutritional legacy_data for a specific food item.',
        uri: '/api/v1/full-nutrient-profile',
    },
    {
        icon: IconCoin,
        title: 'Relational Search',
        description: 'Similarity search based on food names',
        uri: '/api/v1/food-relational-search',
    },
    {
        icon: IconBook,
        title: 'AutoComplete',
        description: 'Generates autocomplete suggestions for food names as you type.',
        uri: '/api/v1/auto-complete',
    },
    {
        icon: IconFingerprint,
        title: 'DataBase Search',
        description: 'Searches the food database using specific criteria to return relevant results.',
        uri: '/api/v1/database-search',
    },
];


const DocumentationData= [
    {
        icon: IconCode,
        title: 'Introduction',
        description: 'Quickly get up and running with NutriCloud',
        uri: '/api-reference/introduction',
    },
    {
        icon: IconCoin,
        title: 'Guides',
        description: '',
        uri: '/docs/guides',
    },
];

export function NavBar() {
    const [drawerOpened, { toggle: toggleDrawer, close: closeDrawer }] = useDisclosure(false);
    const [apiReferenceLinksOpened, { toggle: toggleAPIReferenceLinks }] = useDisclosure(false);
    const [documentationLinksOpened, { toggle: toggleDocumentationLinks }] = useDisclosure(false);
    const { isAuthenticated, logout } = useAuth();

    const { classes, theme } = useStyles();

    const navigate = useNavigate();

    const handleLogout = async () => {
        try {
            await logout(); // Use the centralized logout function
        } catch (error) {
            console.error('Logout error', error); // Handle any errors that might occur
        }
    };

    const APIReferenceLinks = APIReferenceData.map((item) => (
        <Link to={item.uri}>
        <UnstyledButton className={classes.subLink} key={item.title}>
            <Group noWrap align="flex-start">
                <ThemeIcon size={34} variant="default" radius="md">
                    <item.icon size={rem(22)} color={theme.fn.primaryColor()} />
                </ThemeIcon>
                <div>
                    <Text size="sm" fw={500}>
                        {item.title}
                    </Text>
                    <Text size="xs" color="dimmed">
                        {item.description}
                    </Text>
                </div>
            </Group>
        </UnstyledButton>
        </Link>
    ));

    const DocumentationLinks = DocumentationData.map((item) => (
        <Link to={item.uri}>
            <UnstyledButton className={classes.subLink} key={item.title}>
                <Group noWrap align="flex-start">
                    <ThemeIcon size={34} variant="default" radius="md">
                        <item.icon size={rem(22)} color={theme.fn.primaryColor()} />
                    </ThemeIcon>
                    <div>
                        <Text size="sm" fw={500}>
                            {item.title}
                        </Text>
                        <Text size="xs" color="dimmed">
                            {item.description}
                        </Text>
                    </div>
                </Group>
            </UnstyledButton>
        </Link>
    ));

    return (
        <div className={classes.navbarContainer}>
            <Box pb={0}>
                <Header height={60} px="md">
                    <Group position="apart" sx={{ height: '100%' }}>
                        <div style={{ display: 'flex', alignItems: 'center', height: '100%'}}>
                            <StandardCompanyLogo size="130px" />
                        </div>
                        <Group sx={{ height: '100%'}} spacing={0} className={classes.hiddenMobile}>
                            <Link to="/account/profile" className={classes.link}>
                                Dashboard
                            </Link>
                            <Link to="/playground" className={classes.link}>
                                Playground
                            </Link>
                            <Link to="/docs/overview" className={classes.link}>
                                Documentation
                            </Link>

                            <Link to="/pricing" className={classes.link}>
                                Pricing
                            </Link>
                            <Link to="/about" className={classes.link}>
                                About
                            </Link>

                            <Link to="/policies" className={classes.link}>
                                Legal
                            </Link>
                        </Group>

                        <Group className={classes.hiddenMobile}>
                            {!isAuthenticated ? (
                                <>
                                    <Button size={'xs'} variant="default" onClick={() => navigate('/login')}>Log in</Button>
                                    <Button size={'xs'} onClick={() => navigate('/signup')}>Sign up</Button>
                                </>
                            ) : (
                                <Button size={'xs'} onClick={handleLogout}>Log out</Button>
                            )}
                        </Group>

                        <Burger opened={drawerOpened} onClick={toggleDrawer} className={classes.hiddenDesktop} />
                    </Group>
                </Header>

                {/*hamburger*/}
                <Drawer
                    opened={drawerOpened}
                    onClose={closeDrawer}
                    size="100%"
                    padding="md"
                    title="NutriCloud"
                    className={classes.hiddenDesktop}
                    zIndex={1000000}
                >
                    <ScrollArea h={`calc(100vh - ${rem(60)})`} mx="-md">
                        <Divider my="sm" color={theme.colorScheme === 'dark' ? 'dark.5' : 'gray.1'} />

                            <Link to="/home" onClick={() => closeDrawer()}  className={classes.link}>
                                Home
                            </Link>
                            <Link to="/docs/overview" onClick={() => closeDrawer()}  className={classes.link}>
                                Documentation
                            </Link>
                            <Link to="/docs/api-reference" onClick={() => closeDrawer} className={classes.link}>
                                API Reference
                            </Link>
                            <Link to="/pricing" onClick={() => closeDrawer()}  className={classes.link}>
                                Pricing
                            </Link>
                            <Link to="/about" onClick={() => closeDrawer()} className={classes.link}>
                                About
                            </Link>
                            <Link to="/playground"  onClick={() => closeDrawer()}  className={classes.link}>
                                Playground
                            </Link>
                            <Link to="/policies" onClick={() => closeDrawer()}  className={classes.link}>
                                Legal
                            </Link>


                        <Divider my="sm" color={theme.colorScheme === 'dark' ? 'dark.5' : 'gray.1'} />

                        <Group position="center" grow pb="xl" px="md">
                            {!isAuthenticated ? (
                                <>
                                    <Button variant="default" onClick={() => navigate('/login')}>
                                        login
                                    </Button>
                                    <Button onClick={() => navigate('/signup')}>
                                        Sign up
                                    </Button>
                                </>
                            ) : (
                                <Button onClick={handleLogout}>Log out</Button>
                            )}
                        </Group>
                    </ScrollArea>
                </Drawer>
            </Box>
        </div>
    );
}
