import {
    createStyles,
    Title,
    Text,
    Card,
    SimpleGrid,
    Container,
    rem, Box, Center, Group, Col, Grid, Flex, Table,
} from '@mantine/core';
import { IconGauge, IconUser, IconCookie } from '@tabler/icons-react';
import React, {useEffect, useState} from "react";

const mockdata = [
    {
        title: 'Extreme performance',
        description:
            'Unlock unparalleled speed and reliability with NutriCloud. We\'ve optimized our systems to handle high-volume requests, ensuring your services operate smoothly even under peak demand.',
        icon: IconGauge,
    },
    {
        title: 'Privacy focused',
        description:
            'We prioritize your users\' privacy. NutriCloud is designed to adhere to the strictest legacy_data protection standards, ensuring user information is handled securely and responsibly.',
        icon: IconUser,
    },
    {
        title: 'No third parties',
        description:
            'With NutriCloud, you have direct control over your legacy_data. We don’t involve third parties, giving you peace of mind about legacy_data integrity and consistent performance.',
        icon: IconCookie,
    },
];

const useStyles = createStyles((theme) => ({
    title: {
        fontSize: rem(34),
        fontWeight: 900,

        [theme.fn.smallerThan('sm')]: {
            fontSize: rem(24),
        },
    },

    description: {
        maxWidth: 600,
        margin: 'auto',

        '&::after': {
            content: '""',
            display: 'block',
            backgroundColor: theme.fn.primaryColor(),
            width: rem(45),
            height: rem(2),
            marginTop: theme.spacing.sm,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },

    card: {
        border: `${rem(1)} solid ${
            theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[1]
        }`,
    },

    cardTitle: {
        '&::after': {
            content: '""',
            display: 'block',
            backgroundColor: theme.fn.primaryColor(),
            width: rem(45),
            height: rem(2),
            marginTop: theme.spacing.sm,
        },
    },
      gradientBorderBox: {
    display: 'inline-flex',
        backgroundImage: theme.fn.gradient({ from: 'pink', to: 'orange' }),
    borderRadius: '5px',
    padding: '2px', // Controls the thickness of the gradient border
    alignItems: 'center',
    justifyContent: 'center',
      marginLeft: '50px',
      width: '150px',
  },
  innerBox: {
    backgroundColor: theme.colors.dark[7], // Should match the page's background
    borderRadius: '5px',
      width: '150px',
    paddingLeft: "30px",
    paddingRight: "30px",
    paddingTop: "5x",
    paddingBottom: "0px",
      color: theme.colors.gray[1]
  },
  apiColumn: {
    width: '50%',
    textAlign: 'left', // Ensure text alignment matches
  },
  spacerColumn: {
    width: '25%', // Adjust the width for the desired gap
  },
  tokensColumn: {
    textAlign: 'center',
  },
  usdColumn: {
        textAlign: 'center',
  },
  cell: {
  },

}));

interface RowData {
  key: string;
  endpoint: string;
  tokens: number;
}

interface MyComponentProps {
  data: RowData[];
}


const PricingTable: React.FC<MyComponentProps> = ({ data }) => {
    const { classes } = useStyles();
    const [isMobile, setIsMobile] = useState(window.innerWidth < 450);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 450);
        };
        window.addEventListener('resize', handleResize);
        // Clean up to avoid memory leaks
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div style={{ maxWidth: 1200 }}>
            <Table highlightOnHover>
                <thead>
                    <tr>
                        <th className={classes.apiColumn}>API endpoint</th>
                        {!isMobile && <th className={classes.spacerColumn}></th>}
                        {!isMobile && <th className={classes.tokensColumn}>tokens/request</th>}
                        <th className={classes.usdColumn}>USD/ 1K requests</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map(({ key, endpoint, tokens }) => (
                        <tr key={key}>
                            <td className={classes.apiColumn}>{endpoint}</td>
                            {!isMobile && <td className={classes.spacerColumn}></td>}
                            {!isMobile && <td className={classes.tokensColumn}>{tokens}</td>}
                            <td className={classes.usdColumn}>${tokens * 0.02}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    );
};


export default PricingTable;
