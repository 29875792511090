import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import CheckoutForm from "./checkoutForm";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe('pk_live_51MUDAdLxrSaYVGQ0b2trZzFaMBAzuDHIH9zWsHcKv3VlFHA8lfoVMIxShCAN8cj4O1gfpyOp82xNowrz6kx1Bxq200vJvG8Ju7');

export default function checkoutPage() {
  const options = {
    // passing the client secret obtained from the server
    clientSecret: '{{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}}',
  };

  return (
    <Elements stripe={stripePromise} options={options}>
      <CheckoutForm />
    </Elements>
  );
};
