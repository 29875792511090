import { createStyles, Paper, Text } from '@mantine/core';
import TwoColumn from "../TwoColumns";
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import {rem} from "@mantine/core";
import WhiteWireFrameButton from "../../../componenets/buttons/whiteWireFrameButton";

const useStyles = createStyles((theme) => ({
    pageTitle: {
        textAlign: 'left',
        marginTop: 80,
        marginBottom: 40,
        fontSize: '2.5rem',
        marginLeft: theme.spacing.md,
        color: theme.colors.gray[0],
        '@media (min-width: 600px)': {
            marginTop: 100,
            marginBottom: 60,
            fontSize: '3.5rem',
        },

        '@media (min-width: 1280px)': {
            marginTop: 150,
            marginBottom: 80,
            fontSize: '4.5625rem',
        },
    },

    header: {
        textAlign: 'left',
        fontSize: theme.fontSizes.lg,
        color: theme.colors.gray[3],
        fontWeight: 400,
        '@media (min-width: 600px)': {
            fontSize: theme.fontSizes.xl
        },

        '@media (min-width: 1280px)': {
            fontSize: theme.fontSizes.xxl,
        },
    },

    pageLine: {
        width: '97%',
        height: '2px',
        backgroundColor: theme.colors.gray[7],
        marginBottom: theme.spacing.md,
        marginLeft: 'auto', // Add this line
        marginRight: 'auto', // Add this line
    },

    paragraphText: {
        fontSize: '18px',
        fontWeight: 200,
        color: theme.colors.gray[0],
    },

    rightDiv: {
        marginLeft: '25px',
        '@media (min-width: 1280px)': {
            marginLeft: 0,
        },
    },

    leftDiv: {
        marginBottom: '40px',
    },

    separatingDiv: {
        height: '20px',
        '@media (min-width: 1280px)': {
            height: '30px',
        },
    },
    largeSeparatingDiv: {
        height: '60px',
        '@media (min-width: 1280px)': {
            height: '80px',
        },
    },

    paragraphBoldText: {
        fontWeight: 'bold',
    },

    table: {
        width: '100%',
        borderCollapse: 'collapse',
    },
    cell: {
        padding: theme.spacing.md,
        textAlign: 'left',
        borderBottom: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.gray[0] : theme.colors.gray[0]}`,
    },
    tableHeader: {
        borderBottomWidth: '2px',  // make the header line bolder
        fontWeight: 'bold',
    },
    lastRow: {
        borderBottom: 'none',  // remove the bottom border from the last row
    }
}));

export function UsagePolicies() {
    const { classes } = useStyles();
    return (

        <div>
            <Text size="xxl" className={classes.pageTitle}>Usage policies</Text>
            <div className={classes.pageLine}></div>
            {/* info*/}
            <TwoColumn
                left={
                    <div className={classes.leftDiv}>
                        <Text style={{fontWeight: 'bold'}} className={classes.paragraphText}>Updated</Text>
                        <Text className={classes.paragraphText}>August 1, 2023</Text>
                    </div>
                }
                right={
                    <div className={classes.rightDiv}>
                        {/*start*/}
                        <p>We’ve recently updated our usage policies to be clearer and more specific.</p>
                        <div className={classes.separatingDiv}></div>
                        <div className={classes.largeSeparatingDiv}></div>
                        <p className={classes.paragraphText}>We want everyone to use our tools safely and responsibly. That’s why we’ve created usage policies that apply to all users of NutriCloud’s API's, tools, and services. By following them, you’ll ensure that our technology is used for good.</p>
                        <p className={classes.paragraphText}>If we discover that your product or usage doesn’t follow these policies, we may ask you to make necessary changes. Repeated or serious violations may result in further action, including suspending or terminating your account.</p>
                        <p className={classes.paragraphText}>Our policies may change as we learn more about use and abuse of our API's.</p>
                        <div className={classes.separatingDiv}></div>
                        <div className={classes.largeSeparatingDiv}></div>

                        {/*disallowed usage*/}
                        <Text className={classes.header}>Disallowed usage of our API's</Text>
                        <p className={classes.paragraphText}>We don’t allow the use of our API's for the following:</p>
                        <ul>
                            <li className={classes.paragraphText}>Illegal</li>
                            <ul>
                                <li>NutriCloud prohibits the use of our API's, tools, and services for illegal activity.</li>
                            </ul>

                            <li className={classes.paragraphText}>Child Sexual Abuse Material or any content that exploits or harms children</li>
                            <ul>
                                <li className={classes.paragraphText}>We report CSAM to the National Center for Missing and Exploited Children.</li>
                            </ul>

                            <li className={classes.paragraphText}>Generation of hateful, harassing, or violent content</li>
                            <ul>
                                <li className={classes.paragraphText}>Content that expresses, incites, or promotes hate based on identity</li>
                                <li className={classes.paragraphText}>Content that intends to harass, threaten, or bully an individual</li>
                                <li className={classes.paragraphText}>Content that promotes or glorifies violence or celebrates the suffering or humiliation of others</li>
                            </ul>

                            <li className={classes.paragraphText}>Generation of malware</li>
                            <ul>
                                <li className={classes.paragraphText}>Content that attempts to generate code that is designed to disrupt, damage, or gain unauthorized access to a computer system.</li>
                            </ul>

                            <li className={classes.paragraphText}>Activity that has high risk of physical harm, including:</li>
                            <ul>
                                <li className={classes.paragraphText}>Weapons development</li>
                                <li className={classes.paragraphText}>Military and warfare</li>
                                <li className={classes.paragraphText}>Management or operation of critical infrastructure in energy, transportation, and water</li>
                                <li className={classes.paragraphText}>Content that promotes, encourages, or depicts acts of self-harm, such as suicide, cutting, and eating disorders</li>
                            </ul>

                            <li className={classes.paragraphText}>Activity that has high risk of economic harm, including:</li>
                            <ul>
                                <li className={classes.paragraphText}>Multi-level marketing
                                </li>
                                <li className={classes.paragraphText}>Gambling
                                </li>
                                <li className={classes.paragraphText}>Payday lending
                                </li>
                                <li className={classes.paragraphText}>Automated determinations of eligibility for credit, employment, educational institutions, or public assistance services
                                </li>
                            </ul>

                            <li className={classes.paragraphText}>Fraudulent or deceptive activity, including:
                            </li>
                            <ul>
                                <li className={classes.paragraphText}>Scams
                                </li>
                                <li className={classes.paragraphText}>Coordinated inauthentic behavior
                                </li>
                                <li className={classes.paragraphText}>Plagiarism
                                </li>
                                <li className={classes.paragraphText}>Academic dishonesty
                                </li>
                                <li className={classes.paragraphText}>Astroturfing, such as fake grassroots support or fake review generation
                                </li>
                                <li className={classes.paragraphText}>Disinformation</li>
                                <li className={classes.paragraphText}>Spam
                                </li>
                                <li className={classes.paragraphText}>Pseudo-pharmaceuticals
                                </li>
                            </ul>

                            <li className={classes.paragraphText}>Activity that violates people’s privacy, including:
                            </li>
                            <ul>
                                <li className={classes.paragraphText}>Tracking or monitoring an individual without their consent
                                </li>
                                <li className={classes.paragraphText}>Classifying individuals based on protected characteristics
                                </li>
                                <li className={classes.paragraphText}>Unlawful collection or disclosure of personal identifiable information or educational, financial, or other protected records
                                </li>
                            </ul>
                        </ul>
                        <div className={classes.separatingDiv}></div>
                        <div className={classes.largeSeparatingDiv}></div>

                        {/*platform policy*/}
                        <Text className={classes.header}>Platform Policy</Text>
                        <p className={classes.paragraphText}>Our API is being used to power businesses across many sectors and technology platforms. From iOS Apps to websites to Slack, the simplicity of our API makes it possible to integrate into a wide array of use cases. Subject to the use case restrictions mentioned above, we allow the integration of our API into products on all major technology platforms, app stores, and beyond.</p>


                        <div className={classes.largeSeparatingDiv}></div>

                    </div>
                }
            />
        </div>
    );
}
