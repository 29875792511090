import React from 'react';
import { Button, Group, TextInput, useMantineTheme } from '@mantine/core';
import { addMonths, format, subMonths } from 'date-fns';
import {useUsage} from "./usageContext";

// Define props type
interface MonthPickerProps {
}

const MonthPicker: React.FC<MonthPickerProps> = () => {
  const theme = useMantineTheme();
  const handleNextMonth = () => setCurrentMonth(addMonths(currentMonth, 1));
  const handlePreviousMonth = () => setCurrentMonth(subMonths(currentMonth, 1));

  const {
    currentMonth,
    setCurrentMonth,
  } = useUsage();

    const formattedMonth = format(currentMonth, 'MMMM');


  const color = theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.dark[7];

  return (
    <Group position="center" spacing="xs">
      <Button size={'xs'} bg={color} onClick={handlePreviousMonth} p={7}>{'<'}</Button>
      <TextInput radius={'xs'} size={'xs'} value={formattedMonth} readOnly style={{width: 120, marginLeft: -3, marginRight: -3}} />
      <Button size={'xs'} bg={color} onClick={handleNextMonth} p={7}>{'>'}</Button>
    </Group>
  );
};

export default MonthPicker;
