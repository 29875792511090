interface Params {
    [key: string]: any;
}

const getJavaCode = (url: string, parameters: Params, currentParameters: Params): string => {
    let code = `import java.io.OutputStream;\nimport java.net.HttpURLConnection;\nimport java.net.URL;\n\npublic class Main {\n    public static void main(String[] args) throws Exception {\n        URL url = new URL("${url}");\n        HttpURLConnection conn = (HttpURLConnection) url.openConnection();\n        conn.setRequestMethod("POST");\n        conn.setRequestProperty("Content-Type", "application/json; utf-8");\n        conn.setRequestProperty("api-key", "YOUR_NUTRICLOUD_API_KEY");\n        conn.setDoOutput(true);\n`;

    let data = {...parameters};
    for(let param in data) {
        if(data[param] === currentParameters[param]) {
            delete data[param];
        }
    }

    if(Object.keys(data).length > 0) {
        let jsonIndent = '    ';
        let dataString = JSON.stringify(data, null, 2).split('\n').map(line => jsonIndent + line).join('\n');
        code += `\n        String jsonInputString = "${dataString}";\n\n        try(OutputStream os = conn.getOutputStream()) {\n            byte[] input = jsonInputString.getBytes("utf-8");\n            os.write(input, 0, input.length);\n        }\n\n        int code = conn.getResponseCode();\n        System.out.println(code);\n        \n        conn.disconnect();\n    }\n}`;
    }
    return code;
};

export default getJavaCode;
