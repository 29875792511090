import React, { useState } from 'react';
import { createStyles, Text } from '@mantine/core';
import { ChevronRight } from 'react-feather';
import {useNavigate} from "react-router-dom";

const useStyles = createStyles((theme, { isHovered }: { isHovered: boolean }) => ({
  container: {
  },
  animatedLink: {
      marginBottom: theme.spacing.sm,
    display: 'inline-flex',
    alignItems: 'center',
    cursor: 'pointer',
    position: 'relative',
    textDecoration: 'none',
    color: theme.colorScheme === 'dark' ? theme.white : theme.black,

    '&::after': {
      content: '""',
      position: 'absolute',
      width: isHovered ? '100%' : 0,
      height: '2px',
      bottom: 0,
      left: 0,
      backgroundColor: theme.colorScheme === 'dark' ? theme.white : theme.black,
      transition: 'width 0.3s ease',
    },
  },
  icon: {
    marginLeft: theme.spacing.sm,
  },
    unstyledLink: {
    textDecoration: "none",
    color: "inherit",

  },
    coloredBox: {
      flex: 1,
              border: `0.5px solid ${theme.colors.gray[8]}`,
      backgroundColor: theme.colors.gray[9],
    }
}));

type AnimatedCardProps = {
  title: string;
  path: string;
  reactchildren?: React.ReactNode;
};

const AnimatedLink: React.FC<AnimatedCardProps> = ({ title, path, reactchildren }) => {
  const [isHovered, setIsHovered] = useState(false);
  const { classes } = useStyles({ isHovered });
    const navigate = useNavigate();

  return (
      <a href={path} className={classes.unstyledLink}>
        <div
          className={classes.container}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}

        >
          <div className={classes.animatedLink}>
            <Text>{title}</Text>
            <ChevronRight className={classes.icon} />
          </div>
          {reactchildren}
        </div>
          </a>
  );
};

export default AnimatedLink;
