import React from 'react';
import {
    Paper,
    Text,
    Flex,
    Container,
    createStyles,
    Col,
    Grid,
    useMantineTheme, Accordion, Box, Center
} from '@mantine/core';
import { FaHome, FaUser, FaEnvelope, FaPhone, FaSearch, FaHeart, FaComment, FaBars } from 'react-icons/fa';
import {Link, useNavigate} from 'react-router-dom';
import { FaInfoCircle } from 'react-icons/fa';
import {Prism} from "@mantine/prism";
import FeedbackBanner from "../../Documentation/Components/Feedback";
import DocsPage from "../../Documentation/DocsPage";
import ApiReferencePage from "../ApiReferencePage";


const useStyles = createStyles((theme) => ({
    iconBox: {
        display: 'flex',
        justifyContent: 'flex-start', // Align items to the start (left side)
        alignItems: 'center',
        marginBottom: theme.spacing.md,
        '& svg': {
            transition: 'transform 0.3s ease',
        },
        '&:hover svg': {
            transform: 'scale(1.2)',
        },
    },
    contentBox: {
        marginLeft: theme.spacing.md, // Add some space between the icon and the text
    },

    // headers
    pageTitle: {
        textAlign: "left",
        fontSize: theme.fontSizes.xxl,
        fontWeight:600,
        marginTop: "30px",
        marginBottom: "0px",
    },
    mainHeader: {
        fontWeight: 500,
        fontSize: theme.fontSizes.xl,
        marginTop: "0px",
        marginBottom: "10px",
    },
    subHeader: {
        fontWeight: 500,
        color: theme.colors.gray[3],
        fontSize: theme.fontSizes.md,
        marginTop: "0px",
        marginBottom: "0px",
    },

    // bullet list
    link: {
        color: theme.colors.orange[0],
        textDecoration: 'none',
        '&:hover': {
            color: theme.colors.orange[9],  // Change this to the color you want on hover
        },
    },
    bulletTitle: {
        color: theme.colors.gray[0],
        fontWeight: 600,
    },
    bulletList: {
        listStyleType: 'disc',
    },
     numberedList: {
        listStyleType: 'decimal',
    },
    bulletPoint: {
        fontSize: theme.fontSizes.sm,
        margin: '5px 0',
    },
    // text
    paragraphText: {
        fontSize: '16px',
        marginBottom: "10px",
    },

    // info boxes
    infoBox: {
        backgroundColor: theme.colors.gray[8],
        padding: theme.spacing.md,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        width: '100%',
        marginTop: '10px',
        marginBottom: '20px',
    },
    infoText: {
        marginLeft: theme.spacing.sm,
        fontSize: '16px',
    },
    infoIcon: {
        fontSize: '1em',
        flexShrink: 0,
    },

              gradientBorderBox: {
    display: 'inline-flex',
        backgroundImage: theme.fn.gradient({ from: 'pink', to: 'orange' }),
    borderRadius: '5px',
    padding: '2px', // Controls the thickness of the gradient border
    alignItems: 'center',
           // marginLeft: "50px",
    justifyContent: 'center',
      width: '300px',

      '@media (max-width: 800px)': {
        marginLeft: 0,
    },

  },
  innerBox: {
    backgroundColor: theme.colors.dark[7], // Should match the page's background
    borderRadius: '5px',
      width: '300px',
    paddingLeft: "30px",
    paddingRight: "30px",
    paddingTop: "5x",
    paddingBottom: "0px",
      color: theme.colors.gray[1]
  },
            accordionSubHeader: {
        fontWeight: 500,

        color: theme.colors.gray[3],
        fontSize: theme.fontSizes.md,
        marginTop: "0px",
        marginBottom: "15px",
    },
}));

// create gradient boxes
function DebuggingRequestsAPIReference() {
    const { classes } = useStyles();
    const theme = useMantineTheme();
    const navigate = useNavigate();

    return (
        <Container>
            {/*title*/}
            <Text className={classes.pageTitle}>Debugging Requests</Text>
            <Text className={classes.subHeader} style={{color: theme.colors.gray[5]}}>When
                working with our API, you might encounter various issues. This guide
                will help you troubleshoot common problems and understand error
                responses.</Text>
            <Text mt={50} className={classes.mainHeader}>Common Error Codes</Text>
            <Text className={classes.paragraphText}></Text>
            <ul className={classes.bulletList}>
                <li className={classes.bulletPoint}>
                    <strong>401 Unauthorized</strong>: Authentication failure due to
                    missing or invalid API key.
                </li>
                <li className={classes.bulletPoint}>
                    <strong>422 Unprocessable Entity</strong>: The request was
                    well-formed but contains semantic errors.
                </li>
                <li className={classes.bulletPoint}>
                    <strong>429 Too Many Requests</strong>: Rate limit exceeded; too
                    many requests in a given time frame.
                </li>
                <li className={classes.bulletPoint}>
                    <strong>403 Forbidden</strong>: Insufficient permissions to access
                    the requested endpoint.
                </li>
                <li className={classes.bulletPoint}>
                    <strong>500 Internal Server Error</strong>: An unexpected error
                    occurred on the server.
                </li>
            </ul>

            {/*401*/}
            <Text mt={30} className={classes.subHeader}>401 Unauthorized</Text>
            <Text mt={10} className={classes.paragraphText}><strong>Description</strong></Text>
            <Text className={classes.paragraphText}>A 401 Unauthorized error indicates
                that the API could not authenticate your request. This usually happens
                when the Authorization header is missing, malformed, or contains an
                invalid API key.</Text>

            <Text className={classes.paragraphText}><strong>Possible
                Causes</strong></Text>
            <ul className={classes.bulletList}>
                <li className={classes.bulletPoint}>
                    <strong>Missing API Key</strong>: The `Authorization` header is not
                    included in the request.
                </li>
                <li className={classes.bulletPoint}>
                    <strong>Invalid API Key Format</strong>: The API key is incorrectly
                    formatted.
                </li>
                <li className={classes.bulletPoint}>
                    <strong>Expired or Revoked API Key</strong>: The API key has been
                    revoked or expired.
                </li>
                <li className={classes.bulletPoint}>
                    <strong>Typographical Errors</strong>: Mistyped API key or header
                    name.
                </li>
            </ul>

            <Text className={classes.paragraphText}><strong>How to
                Resolve</strong></Text>
            <ul className={classes.bulletList}>
                <li className={classes.bulletPoint}>
                    <strong>Check the Authorization Header</strong>: Ensure that the
                    Authorization header is correctly set.
                    <Prism language={"sql"}>
                        {`Authorization: api-key: <your_api_key>`}
                    </Prism>
                </li>
                <li className={classes.bulletPoint}>
                    <strong>Verify the API Key</strong>: Confirm that you are using a
                    valid API key from your developer dashboard.
                </li>
                <li className={classes.bulletPoint}>
                    <strong>Inspect for Typos</strong>: Double-check for any
                    typographical errors in the header name or API key value.
                </li>
                <li className={classes.bulletPoint}>
                    <strong>Test with a New API Key</strong>: If the issue persists, try
                    generating a new API key and update your request accordingly.
                </li>
            </ul>

            <span
                onClick={() => navigate('/docs/api-reference/authentication')}
                style={{cursor: 'pointer', color: theme.colors.orange[0]}}
            >
                See the authentication page
              </span>


            {/*422*/}
            <Text mt={30} className={classes.subHeader}>422 Unprocessable Entity</Text>
            <Text className={classes.paragraphText}><strong>Description</strong></Text>
            <Text className={classes.paragraphText}>A 422 Unprocessable Entity error
                means that the server understands the content type of the request
                entity, but was unable to process the contained instructions. This often
                relates to semantic errors in the request body.</Text>

            <Text className={classes.paragraphText}><strong>Possible
                Causes</strong></Text>
            <ul className={classes.bulletList}>
                <li className={classes.bulletPoint}>
                    <strong>Invalid JSON Format</strong>: Malformed JSON in the request
                    body.
                </li>
                <li className={classes.bulletPoint}>
                    <strong>Missing Required Fields</strong>: Essential parameters are
                    missing from the request.
                </li>
                <li className={classes.bulletPoint}>
                    <strong>Invalid Parameter Values</strong>: Parameters contain values
                    that are out of range or of the wrong type.
                </li>
                <li className={classes.bulletPoint}>
                    <strong>Incorrect Data Types</strong>: Expected data types (e.g.,
                    string, integer) are not respected.
                </li>
            </ul>

            <Text className={classes.paragraphText}><strong>How to
                Resolve</strong></Text>
            <ol className={classes.numberedList}>
                <li className={classes.bulletPoint}>
                    <strong>Validate JSON Syntax</strong>: Use a JSON validator to
                    ensure your request body is well-formed.
                </li>
                <li className={classes.bulletPoint}>
                    <strong>Check Required Fields</strong>: Refer to the <span
                    onClick={() => navigate('/docs/api-reference/search')}
                    style={{cursor: 'pointer', color: theme.colors.orange[0]}}
                >API
                    documentation</span> to verify all required fields are included.
                </li>
                <li className={classes.bulletPoint}>
                    <strong>Verify Parameter Values</strong>: Ensure that parameter
                    values meet the expected formats and constraints.
                </li>
                <li className={classes.bulletPoint}>
                    <strong>Confirm Data Types</strong>: Match the data types of your
                    parameters with those specified in the API documentation.
                </li>
            </ol>

            <span
                onClick={() => navigate('/docs/api-reference/search')}
                style={{cursor: 'pointer', color: theme.colors.orange[0]}}
            >
                See the endpoints API reference
              </span>


            {/*429*/}
            <Text mt={30} className={classes.subHeader}>429 Too Many Requests</Text>
            <Text className={classes.paragraphText}><strong>Description</strong></Text>
            <Text className={classes.paragraphText}>A 429 Too Many Requests error
                indicates that you've exceeded the rate limits set by the API. This is a
                protective measure to prevent abuse and ensure service
                availability.</Text>

            <Text className={classes.paragraphText}><strong>Possible
                Causes</strong></Text>
            <ol className={classes.bulletList}>
                <li className={classes.bulletPoint}>
                    <strong>Exceeding Rate Limits</strong>: Sending more requests than
                    allowed within the time window.
                </li>
                <li className={classes.bulletPoint}>
                    <strong>Burst Traffic</strong>: Sudden spikes in request volume.
                </li>
                <li className={classes.bulletPoint}>
                    <strong>Inefficient Looping</strong>: Unintentionally making
                    multiple requests in a loop without delays.
                </li>
            </ol>

            <Text className={classes.paragraphText}><strong>How to
                Resolve</strong></Text>
            <ol className={classes.numberedList}>
                <li className={classes.bulletPoint}>
                    <strong>Implement Rate Limiting</strong>: Ensure your application
                    respects the rate limits specified in the Rate Limiting section.
                </li>
                <li className={classes.bulletPoint}>
                    <strong>Use Exponential Backoff</strong>: Implement a retry
                    mechanism with exponential backoff to handle rate limit errors
                    gracefully.
                </li>
                <li className={classes.bulletPoint}>
                    <strong>Optimize Requests</strong>: Consolidate multiple requests
                    into a single request if possible.
                </li>
            </ol>

            <span
                onClick={() => navigate('/docs/guides/rate-limits')}
                style={{cursor: 'pointer', color: theme.colors.orange[0]}}
            >
                See the rate limit docs
              </span>


            {/*handling rate limiting*/}
            <Text mt={30} className={classes.mainHeader}>Handling Rate Limits</Text>
            <Text className={classes.paragraphText}><strong>Best
                Practices</strong></Text>
            <ul className={classes.bulletList}>
                <li className={classes.bulletPoint}>
                    <strong>Plan for Throttling</strong>: Design your application to
                    handle `429` errors gracefully.
                </li>
                <li className={classes.bulletPoint}>
                    <strong>Adjust Request Frequency</strong>: Reduce the number of
                    requests you make per minute.
                </li>
                <li className={classes.bulletPoint}>
                    <strong>Batch Requests</strong>: Where applicable, use batch
                    endpoints to minimize the number of API calls.
                </li>
                <li className={classes.bulletPoint}>
                    <strong>Contact Support</strong>: If you consistently hit rate
                    limits, consider reaching out to discuss higher rate limits.
                </li>
            </ul>


            {/*Insufficient credits*/}
            <Text mt={30} className={classes.mainHeader}>Credits</Text>
            <Text className={classes.paragraphText}><strong>Description</strong></Text>
            <Text className={classes.paragraphText}>When you create a NutriCloud account, you'll receive 1,000 complimentary credits to begin using our API platform. Additional credits can be purchased through the <span
                onClick={() => navigate('/account/billing/overview')}
                style={{cursor: 'pointer', color: theme.colors.orange[0]}}
            >billing page</span>, and you can earn free credits by <span
                    onClick={() => navigate('/account/profile')}
                    style={{cursor: 'pointer', color: theme.colors.orange[0]}}
            >verifying your phone number</span>
            </Text>


            <Text className={classes.paragraphText}><strong>Possible
                Issues</strong></Text>
            <ul className={classes.bulletList}>
                <li className={classes.bulletPoint}>
                    <strong>Insufficient Credits:</strong> You have used up your
                    allocated credits.
                </li>
                <li className={classes.bulletPoint}>
                    <strong>Quota Limits Reached:</strong> Monthly or daily quota limits
                    have been exceeded.
                </li>
                <li className={classes.bulletPoint}>
                    <strong>Billing Issues:</strong> Payment failures or overdue
                    invoices.
                </li>
            </ul>

            <Text className={classes.paragraphText}><strong>How to
                Resolve</strong></Text>
            <ul className={classes.bulletList}>
                <li className={classes.bulletPoint}>
                    <strong>Check Usage Dashboard:</strong> Monitor your current usage
                    and remaining credits in the developer dashboard.
                </li>
                <li className={classes.bulletPoint}>
                    <strong>Buy more credits:</strong> You can buy more credits on the
                    billing page.
                </li>
                <li className={classes.bulletPoint}>
                    <strong>Contact Billing Support:</strong> For billing-related
                    issues, reach out to our support team.
                </li>
            </ul>

            <span
                    onClick={() => navigate('/usage')}
                    style={{cursor: 'pointer', color: theme.colors.orange[0]}}
                >
                see the usage page for more information
              </span>

            <FeedbackBanner/>
        </Container>
    );
}

export default function DebuggingRequestsAPIReferencePage() {
    return (
        <div>
            <ApiReferencePage children={<DebuggingRequestsAPIReference/>}
                              activeItem={"debugging-requests"}/>
        </div>
    );
}
