import React, { useState } from 'react';
import { Paper, Col, Text, Grid, Select, Badge } from '@mantine/core';
import { createStyles } from "@mantine/styles";
import { Prism } from '@mantine/prism';
import styles from './BaseDocumentation.module.css'
import getPythonCode from "../../PlayGround/Components/DynamicCodeGenerators/Python";
import getCurlCommand from "../../PlayGround/Components/DynamicCodeGenerators/CURL";
import getNodeCode from "../../PlayGround/Components/DynamicCodeGenerators/node";
import getCppCode from "../../PlayGround/Components/DynamicCodeGenerators/cpp";
import getGoCommand from "../../PlayGround/Components/DynamicCodeGenerators/GoLang";
import getJavaCode from "../../PlayGround/Components/DynamicCodeGenerators/Java";
import getJsCommand from "../../PlayGround/Components/DynamicCodeGenerators/JavaScript";
import getKotlinCode from "../../PlayGround/Components/DynamicCodeGenerators/Kotlin";
import getRubyCommand from "../../PlayGround/Components/DynamicCodeGenerators/Ruby";
import getSwiftCommand from "../../PlayGround/Components/DynamicCodeGenerators/Swift";
import getTypeScriptCommand
    from "../../PlayGround/Components/DynamicCodeGenerators/TypeScript";
import {ProgrammingLanguageSelector} from "../../../componenets/etc/LanguageSelector";

const useStyles = createStyles((theme) => ({
  paper: {
    padding: theme.spacing.md,
    margin: theme.spacing.md,
    height: 'auto',  // Add this
    overflow: 'visible',  // Add this
  },
    httpBadge: {
        marginRight: '10px',
        position: 'relative',
        top: '-3px', // adjust this as necessary
    },
    sectionHeader: {
        fontFamily: 'ColfaxAI,helvetica,sans-serif',
        color: theme.colors.orange[0],
        fontSize: theme.fontSizes.xl,
        marginBottom: theme.spacing.sm,
        fontWeight: 500,
    },
    endpointName: {
        fontSize: theme.fontSizes.xxl,
        fontWeight: 500,
    },
    uri: {
        color: theme.colors.gray[3],
        marginBottom: theme.spacing.md,
        fontSize: theme.fontSizes.md,
        fontWeight: 300,
    },
    uriDescription: {
        color: theme.colors.gray[4],
        fontSize: theme.fontSizes.sm,
        fontWeight: 400,
    },
    bodyRow: {
        marginTop: theme.spacing.sm,
    },

    // Request Body Values
    requestBodyName: {
        fontFamily: 'ColfaxAI,helvetica,sans-serif',
        color: theme.colors.gray[1],
        fontSize: theme.fontSizes.md,
        marginRight: theme.spacing.sm,
    },
    requestBodyOptional: {
        fontFamily: 'ColfaxAI,helvetica,sans-serif',
        color: theme.colors.gray[4],
        fontSize: theme.fontSizes.sm,
        marginRight: theme.spacing.sm,
    },
    requestBodyRequired: {
        fontFamily: 'ColfaxAI,helvetica,sans-serif',
        color: 'red',
        fontSize: theme.fontSizes.xs,
        marginRight: theme.spacing.sm,
    },
    requestBodyValue: {
        fontFamily: 'ColfaxAI,helvetica,sans-serif',
        color: 'rgba(206, 212, 218, 0.8)',
        fontSize: theme.fontSizes.xs,
        marginRight: theme.spacing.sm,
    },
    requestBodyDefualtValue: {
        fontFamily: 'ColfaxAI,helvetica,sans-serif',
        color: theme.colors.gray[4],
        fontSize: theme.fontSizes.xs,
        marginRight: theme.spacing.sm,
    },
    requestBodyDescription: {
        // fontFamily: 'ColfaxAI,helvetica,sans-serif',
        color: theme.colors.gray[4],
        fontSize: theme.fontSizes.sm,
        marginTop: theme.spacing.xs,
    },

    // line to seperate rows of legacy_data
    line: {
        height: '1px',
        width: '100%',
        background: 'rgba(134, 142, 150, 0.3)',
        marginTop: theme.spacing.sm,
    },
}));

interface Params {
    [key: string]: any;
}

interface DocProps {
    httpMethod: 'GET' | 'POST' | 'PUT' | 'DELETE';
    endpointName: string,
    parameters: Params,
    exampleResponse: string,
    uri: string,
    urlDescription: string,
    requestBody: Array<{[key: string]: any}>
    responseBody?: Array<{[key: string]: any}>
}

export type RequestBodyItem = {
    name: string;
    dataType: string;
    required: boolean;
    defaultValue: string;
    description: string;
    reactComponent?: JSX.Element;};

type PrismLanguage = 'jsx' | 'tsx' | 'graphql' | 'yaml' | 'go' | 'cpp' | 'bash' | 'python';

const languageMap: { [key: string]: PrismLanguage } = {
    'bash': 'bash',
    'cpp': 'cpp',
    'golang': 'go',
    'java': 'jsx',
    'javascript': 'jsx',
    'kotlin': 'jsx',
    'nodejs': 'jsx',
    'python': 'python',
    'ruby': 'go',
    'swift': 'go',
    'typescript': 'tsx',
};

const getCode = (language: string, url: string, parameters: Params, defaultParamaters: Params = {}) => {
    switch (language) {
        case 'python':
            return getPythonCode(url, parameters, defaultParamaters);
        case 'bash':
            return getCurlCommand(url, parameters, defaultParamaters);
        case 'nodejs':
            return getNodeCode(url, parameters, defaultParamaters)
        case 'cpp':
            return getCppCode(url, parameters, defaultParamaters)
        case 'golang':
            return getGoCommand(url, parameters, defaultParamaters)
        case 'java':
            return getJavaCode(url, parameters, defaultParamaters)
        case 'javascript':
            return getJsCommand(url, parameters, defaultParamaters)
        case 'kotlin':
            return getKotlinCode(url, parameters, defaultParamaters)
        case 'ruby':
            return getRubyCommand(url, parameters, defaultParamaters)
        case 'swift':
            return getSwiftCommand(url, parameters, defaultParamaters)
        case 'typescript':
            return getTypeScriptCommand(url, parameters, defaultParamaters)
        default:
            return getCurlCommand(url, parameters, defaultParamaters);
    }
}

export function Documentation({
                                  httpMethod,
                                  endpointName,
                                  parameters,
                                  exampleResponse,
                                  uri,
                                  urlDescription,
                                  requestBody,
    responseBody
                              }: DocProps & { requestBody: RequestBodyItem[] , responseBody?: RequestBodyItem[] }) {
    const { classes } = useStyles();

    const baseURL = process.env.REACT_APP_BASE_URL;
    const url = baseURL + uri;


    const [selectedLanguage, setSelectedLanguage] = useState('bash');

    function Line() {
        return <div className={classes.line}></div>;
    }
    function HttpMethodBadge({ method }: { method: 'GET' | 'POST' | 'PUT' | 'DELETE' }) {
        let color: 'cyan' | 'teal' | 'lime' | 'red' = 'cyan';

        switch (method) {
            case 'GET':
                color = 'cyan';
                break;
            case 'POST':
                color = 'teal';
                break;
            case 'PUT':
                color = 'lime';
                break;
            case 'DELETE':
                color = 'red';
                break;
        }

        return (
            <Badge className={classes.httpBadge} color={color} variant="filled">
                {method}
            </Badge>
        );
    }


    const renderRow = (row: RequestBodyItem) => (
        <div key={row.name} className={classes.bodyRow}>
            <div>
                <Text size="md" weight={500}>
                    <span className={classes.requestBodyName}>{row.name}</span>
                    <span className={classes.requestBodyValue}>{row.dataType} </span>
                    {!row.required && <span className={classes.requestBodyValue} >Optional </span>}
                    {row.defaultValue && (
                        <span className={classes.requestBodyValue}>Defaults to {row.defaultValue}</span>
                    )}
                    {row.required && <span className={classes.requestBodyRequired} color={'red'}>Required </span>}
                </Text>
                <Text className={classes.requestBodyDescription}>{row.description}</Text>
                {row.reactComponent}
            </div>
            <Line/>
        </div>
    );

    let selectedRequest;

    selectedRequest = getCode(selectedLanguage, url, parameters, {});
    const language = languageMap[selectedLanguage] || 'bash';

    return (
        <div style={{ overflow: 'auto' }}>
            <Paper className={classes.paper} shadow="xs" >
                <div style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: '16px',
                    width: '100%'
                }}>
                    <div style={{
                        flex: '1 1 calc(50% - 8px)',
                        minWidth: '300px',
                    }}>
                        <div className={styles.container}>
                            <span className={classes.endpointName}>{endpointName}</span>
                        </div>
                        <Text className={classes.uri}>
                            <HttpMethodBadge method={httpMethod}/>
                            {url}
                        </Text>
                        <Text className={classes.uriDescription}>
                            {urlDescription}
                        </Text>
                        <div className={classes.paper}>
                            <Text className={classes.sectionHeader}>
                                Request Body
                            </Text>
                            <Line/>
                            {requestBody.map(renderRow)}
                        </div>
                        {responseBody && (
                            <div className={classes.paper}>
                                <Text className={classes.sectionHeader}>
                                    Response Body
                                </Text>
                                <Line/>
                                {responseBody.map(renderRow)}
                            </div>
                        )}
                    </div>

                    {/* right side */}
                    <div style={{
                        flex: '1 1 calc(50% - 8px)',
                        minWidth: '300px',
                    }}>
                        <Paper className={classes.paper} shadow="xs">
                        <Text size="md" weight={500}>
                                Example Request
                            </Text>
                            <ProgrammingLanguageSelector
                                setSelectedLanguage={setSelectedLanguage}/>
                            <div style={{overflow: 'auto', maxHeight: '100%'}}>
                                <Prism language={language as any}>
                                    {selectedRequest}
                                </Prism>
                            </div>
                        </Paper>

                        <Paper className={classes.paper} shadow="xs">
                            <Text size="md" weight={500}>
                                Response
                            </Text>
                            <Prism language="json">
                                {exampleResponse}
                            </Prism>
                        </Paper>
                    </div>
                </div>
            </Paper>
        </div>
    );
}
