import React, {useState} from 'react';
import {
    Button, Col,
    createStyles, Grid,
    Input,
    Text,
    TextInput,
    useMantineTheme
} from '@mantine/core';
import ThreeDContainer from "../threeDContainer";
import {PythonIcon} from "../../../../componenets/icons/Python";
import {JavaIcon} from "../../../../componenets/icons/Java";
import {TsIcon} from "../../../../componenets/icons/TypeScript";
import {Prism} from "@mantine/prism";
import ScrollRevealGradientLine from "../coloredLine";
import {ChevronRight} from "react-feather";
import AnimatedLink from "../animatedLink";

// Create custom styles
const useStyles = createStyles((theme, { bloomColor }: { bloomColor: string }) => ({
        glowTitleText: {
        fontSize: '2rem',
        fontWeight: 500,
        maxWidth: 800,
        lineHeight: '1.1',
            textAlign: "left",
    },

    description: {
        fontSize: '1rem',
        marginTop: '20px',
        color: theme.colors.gray[5]
    },

  twoRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch', // Align items in a way that they stretch to fill the container
    marginTop: '20px',
    gap: '20px', // Adjust the gap between the cards
  },

    topSection: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "flex-start",
        marginTop: "20px",
        padding: '2vh',
    },
  cardContainer: {
    flex: 1, // Makes each card take equal width
    display: 'flex',
    flexDirection: 'column', // Makes the content of the card stack vertically
    justifyContent: 'flex-start', // Aligns content to the start, adjust this as needed
  },
      imageContainer: {
    display: 'flex',
    justifyContent: 'center', // This aligns the child (your image) to the right
  },

}));

function EmbeddingsService() {
    const bloomColor = '#D32122';
  const { classes } = useStyles({ bloomColor });
      const theme = useMantineTheme();
      const imageUrl = `${process.env.PUBLIC_URL}/landingPage/embeddings-representation.png`;
          const twophonechat = `${process.env.PUBLIC_URL}/landingPage/two-phone-chat.png`;
          const chatbotapidiagram = `${process.env.PUBLIC_URL}/landingPage/chatbot-api-diagram.png`;


      const serviceColor = "#3fb950"
    return (
        <div style={{padding: 20}}>
            {/*top section*/}
            <ThreeDContainer bloomColor={'radial-gradient(circle, rgba(63, 185, 80,0.2) 0%, rgba(63, 185, 80,0) 70%)'}>
                <div className={classes.topSection}>
                    <Grid>
                    <Col span={12} sm={6} >
                        <Text
                            className={classes.glowTitleText}>Supercharge Your Food App with
                            <span
                                style={{color: serviceColor}}> Semantic Understanding</span></Text>
                        <Text
                            className={classes.description}>
                            Unlock deeper food insights and build smarter features. Our
                            fine-tuned food embeddings model delivers nuanced understanding
                            of dishes, ingredients, cuisines, and dietary preferences.
                            Enhance search, recommendations, and user experiences, unlike
                            anything possible with generic embeddings.
                        </Text>
                    </Col>
                    <Col span={12} sm={6}>
                        <div style={{
                            justifyContent: 'center',
                            display: 'flex',
                        }}>
                            <img style={{width: "50%", height: "auto"}} src={imageUrl} alt={"data"}/>
                        </div>
                    </Col>
                        </Grid>

                </div>
            </ThreeDContainer>
            {/*middle section*/}
            <div className={classes.twoRow}>
                <Grid>
                    <Col span={12} xs={12} sm={6} md={6} lg={6} className={classes.cardContainer}>
                        <ThreeDContainer bloomColor={'radial-gradient(circle, rgba(63, 185, 80,0.2) 0%, rgba(63, 185, 80,0) 70%)'}>
                            <div style={{padding: '2vh',}}>
                                <div>
                                    <Text className={classes.glowTitleText}>
                                        <span style={{color: serviceColor}}>Unified</span> Food Intelligence
                                    </Text>
                                    <Text className={classes.description}>
                                        Build a friendly, knowledgeable chatbot that helps users explore recipes, understand ingredients, and discover new culinary adventures.
                                    </Text>
                                </div>
                                <div className={classes.imageContainer}>
                                    <img style={{width: "50%", height: "auto"}} src={chatbotapidiagram} alt={"data"}/>
                                </div>
                            </div>
                        </ThreeDContainer>
                    </Col>
                    <Col span={12} xs={12} sm={6} md={6} lg={6} className={classes.cardContainer}>
                        {/* Content for the second column */}

                        <ThreeDContainer
                            bloomColor={'radial-gradient(circle, rgba(63, 185, 80,0.2) 0%, rgba(63, 185, 80,0) 70%)'}>
                            <div style={{padding: '2vh',}}>
                                <div>
                                    <Text
                                        className={classes.glowTitleText}>Add conversational AI
                                        <span
                                            style={{color: serviceColor}}> in minutes</span></Text>
                                    <Text
                                        className={classes.description}>
                                        Empower your users to interact with your food app as if they were talking to a culinary expert. With access to our complete suite of APIs, your chatbot can deliver detailed recipes, answer complex dietary questions, suggest creative ingredient swaps, and even generate personalized meal plans.</Text>
                                </div>
                                <div className={classes.imageContainer}>
                                    <img style={{width: "50%", height: "auto", marginBottom: "-2vh"}}
                                         src={twophonechat}
                                         alt={"data"}/>
                                </div>
                            </div>
                        </ThreeDContainer>
                    </Col>
                </Grid>
            </div>

        </div>
    );
}

export default EmbeddingsService;
