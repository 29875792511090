import React, {useEffect, useState} from 'react';
import {
    Paper,
    Progress,
    Text,
    Badge,
    Box,
    Grid,
    Divider,
    useMantineTheme, createStyles,
    Tooltip as MantineTooltip, Group, Stack, Button, Modal, Loader, Center,
} from '@mantine/core';
import {getDaysInMonth} from "date-fns";
import ProgressRing from "./progressRing";
import {Link} from "react-router-dom";
import {useUsage} from "../usageContext";

const useStyles = createStyles((theme) => ({
    title: {
        fontSize: '12px',
        color: theme.colors.gray[1],
        fontWeight: 500,
    },
    data: {
        fontSize: '16px',
        color: theme.colors.gray[4],
        fontWeight: 300,
    },
    toolTip: {
        transition: 'all 0.3s ease',
        color: theme.colors.gray[1],
        border: `3px solid ${theme.colors.gray[1]}`,
        zIndex: 1000,
        fontSize: '10px',
    },

    inLineStyle: {
         display: 'inline-block'
    },
    toolTipText: {
        borderBottom: `2px dotted ${theme.colors.gray[7]}`,
    },
        highlightedLink: {
        color: theme.colors.orange[0],
        textDecoration: 'none',
    },
}));

interface MonthlyUsageProps{
}

export const MonthlyUsage: React.FC<MonthlyUsageProps> = () => {
    const theme = useMantineTheme();
    const { classes } = useStyles();

      const [createModalOpened, setCreateModalOpened] = useState(false);

      const {
    currentMonth,
    monthlyUsageLimit,
    spendPercentage,
    totalSpend,
    shortMonth
  } = useUsage();

    return (
        <Box>
                <div>
            {/*  Monthly Usage circle  */}
            <div style={{marginLeft: '20px', fontSize: '14px'}}>
                <p style={{
                    display: 'inline',
                    marginRight: '0px',
                    color: theme.colors.gray[1],
                    fontWeight: '700',
                }}>Monthly Usage </p>
                <p style={{
                    display: 'inline',
                    color: theme.colors.gray[4],
                }}>{shortMonth} 1-{getDaysInMonth(currentMonth)}</p>
            </div>
            <Group position="left" spacing="xs">
                <ProgressRing
                    percent={spendPercentage}></ProgressRing>
                <Stack spacing="xs">
                    <Text size="lg" weight={700}
                          color={theme.colors.gray[1]}>
                        ${totalSpend.toFixed(2)}
                    </Text>
                    <Text mb={10} size="sm">/
                        ${monthlyUsageLimit.toFixed(2)} limit</Text>
                    <Button color={theme.colors.orange[1]}
                            w={110} fz={14} p={0}
                            style={{color: theme.colors.gray[1]}}
                            fw={600}
                            onClick={() => setCreateModalOpened(true)}
                    >
                        Increase limit
                    </Button>
                    <Modal
                        opened={createModalOpened}
                        onClose={() => setCreateModalOpened(false)}
                        title={
                            <Text fw={600} color={theme.colors.gray[2]}>Requesting
                                a
                                Rate Limit Increase</Text>
                        }
                        size={525}
                        centered={true}
                    >
                        <Text>
                            To request a rate limit increase, please contact
                            support
                            at
                            <span
                                style={{color: theme.colors.orange[0]}}> accounts@nutricloud.ai</span>
                        </Text>
                        <div style={{marginTop: 30}}></div>
                        <Text>
                            Please include the following information in your
                            email:
                        </Text>
                        <ul>
                            <li>
                                Link to company website
                            </li>
                            <li>
                                Reason for rate limit increase
                            </li>
                            <li>
                                Desired rate limit
                            </li>
                        </ul>
                        <div style={{marginTop: 30}}></div>
                        <Text>
                            For more information please refer to our <Link
                            to="/docs/guides/rate-limits"
                            className={classes.highlightedLink}>rate limit
                            documentation</Link>
                        </Text>

                    </Modal>
                </Stack>
            </Group>
        </div>


</Box>
)
    ;
};

export default MonthlyUsage;
