import React from 'react';
import {
    Paper,
    Text,
    Col,
    Grid,
    Image,
    Container,
    Center,
    useMantineTheme, Flex, Button, Box
} from '@mantine/core';
import { createStyles } from '@mantine/styles';
import {ChevronRight} from "react-feather";
import AnimatedLink from "../LandingPage/components/animatedLink";
import Sidebar from "../../componenets/sidebar/sidebar";

const useStyles = createStyles((theme) => ({

}));

const DataPage: React.FC = () => {
    const { classes } = useStyles();
    const theme = useMantineTheme();


    return (
        <div>
            <Sidebar defaultExpanded={true} activeItem="data"/>
            <Flex justify={"center"} align={"center"} h={"50vw"} w={"100%"}>
                <Button bg={theme.colors.orange[1]}  size={"xl"} >Coming soon...</Button>
            </Flex>

        </div>
    );
};

export default DataPage;
