import {
    Button,
    createStyles, Input,
    Paper,
    Select,
    Text, Tooltip,
    useMantineTheme
} from '@mantine/core';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {rem} from "@mantine/core";
import Sidebar from "../../componenets/sidebar/sidebar";
import React, {useEffect, useState} from "react";
import { notifications } from '@mantine/notifications';
import { TextInput, Group, Box, Title } from '@mantine/core';
import axios from "axios";
import qs from "qs";

import intlTelInput from 'intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import PhoneInputBox from "../../componenets/input/phoneInput";
import CustomPhoneInput from "../../componenets/input/phoneInput";
import PhoneInput from 'react-phone-number-input';
import {useAuth} from "../../AuthContext";
import axiosInstance from "../../axiosInstance";


const useStyles = createStyles((theme) => ({
      phoneInput: {
          // Styles for the input part of the phone input
          '.PhoneInputInput': {
              width: '100%',
              height: 'auto',
              fontSize: theme.fontSizes.md,
              fontWeight: 100,
              color: theme.colors.gray[4],
              backgroundColor: theme.colors.dark[5],
              border: `1px solid ${theme.colors.gray[7]}`,
              padding: 5,
              paddingLeft: 10,
              borderRadius: theme.radius.sm,

              // Add more styles as needed
          },
      },
    pageTitle: {
        textAlign: 'left',
        marginTop: 15,
        marginBottom: 10,
        fontSize: '1.5rem',
        fontWeight: 700,
        marginLeft: theme.spacing.md,
        color: theme.colors.gray[0],
        borderBottom: `1px dotted ${theme.colors.gray[7]}`,
        lineHeight: 'normal', // Adjust this line
    },

        toolTip: {
        transition: 'all 0.3s ease',
        backgroundColor: theme.colors.gray[0],
        color: theme.colors.gray[9],
        border: `1px solid ${theme.colors.gray[5]}`,
        zIndex: 1000,
        fontSize: '10px',
    },

    inLineStyle: {
         display: 'inline-block'
    },

    header: {
        textAlign: 'left',
        fontSize: "24px",
        color: theme.colors.gray[3],
        fontWeight: 600,
        // marginLeft: '25px',
    },

    finePrint: {
        fontSize: '12px',
        color: theme.colors.gray[3],
        // marginLeft: '25px',
    },

    pageLine: {
        width: '200%',
        height: '1px',
        backgroundColor: theme.colors.gray[7],
        marginBottom: theme.spacing.md,
        marginLeft: 'auto', // Add this line
        marginRight: 'auto', // Add this line
    },

    paragraphText: {
        fontSize: '16px',
        fontWeight: 300,
        color: theme.colors.gray[0],
        // marginLeft: '25px',
    },

    rightDiv: {
        maxWidth: '1000px',
        marginRight: '20px',
        // marginLeft: '25px',
        '@media (min-width: 800px)': {
            marginLeft: 20,
        },
    },

    leftDiv: {
        marginBottom: '40px',
    },

    separatingDiv: {
        height: '10px',
        '@media (min-width: 1280px)': {
            height: '15px',
        },
    },
    largeSeparatingDiv: {
        height: '60px',
        '@media (min-width: 1280px)': {
            height: '80px',
        },
    },

    paragraphBoldText: {
        fontWeight: 'bold',
    },

    highlightedLink: {
        color: theme.colors.orange[0],
        textDecoration: 'none',
    },

    table: {
        width: '100%',
        borderCollapse: 'collapse',
        marginLeft: '20px',
    },

    cell: {
        padding: '0px',
        textAlign: 'left',
        borderBottom: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.gray[0] : theme.colors.gray[0]}`,
        minWidth: '100px',
        marginLeft: '0px',
        marginRight: '0px',

        fontSize: '14px',
        color: theme.colors.gray[3],

    },
    bigCell: {
        padding: '0px',
        textAlign: 'left',
        borderBottom: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.gray[0] : theme.colors.gray[0]}`,
        marginLeft: '0px',
        marginRight: '0px',
        fontSize: '14px',
        color: theme.colors.gray[3],
        minWidth: '100px',
        '@media (min-width: 1000px)': {
            minWidth: '200px',
        },
    },
    tableHeader: {
        borderBottomWidth: '2px',  // make the header line bolder
        fontWeight: 'bold',
    },
    lastRow: {
        borderBottom: 'none',  // remove the bottom border from the last row
    },

    buttonColor: {
        backgroundColor: theme.colors.orange[1],
        fontSize: '14px',
        height: '35px',
        color: theme.colors.gray[9],
    },
    container: {
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
    paddingLeft: theme.spacing.md,
    borderRadius: theme.radius.md,
    boxShadow: theme.shadows.sm,
        maxWidth: '400px',
  },
  title: {
    marginBottom: theme.spacing.md,
  },
  input: {
    marginBottom: theme.spacing.sm,
  },
  button: {
    marginTop: theme.spacing.md,
  },

    sideBarGrow: {
        marginLeft: '60px',
        '@media (min-width: 800px)': {
            marginLeft: '200px',
        },
    },


}));

interface UserProfileFormValues {
  name: string;
  email: string;
  phoneNumber: string;
}


export function Profile() {
    const theme = useMantineTheme();
    const { classes } = useStyles();
    const navigate = useNavigate();
    const location = useLocation();

    // use auth data
    let {email, isPhoneVerified, phone, name, setName, setPhone} = useAuth();

    // data
    const [displayedEmail] = useState(email);
    const [displayedPhoneNumber, setDisplayedPhoneNumber] = useState(sessionStorage.getItem('phone') ?? '');
    const [displayedName, setDisplayedName] = useState(sessionStorage.getItem('name') ?? '');
    const [phoneVerified] = useState(isPhoneVerified);

    useEffect(() => {
        setDisplayedName(name);
        setDisplayedPhoneNumber(phone);
    }, [])

    useEffect(() => {

    }, [isPhoneVerified])

    // updaters
    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDisplayedName(event.target.value);
    };


    // loading and errors
    const [requestError, setRequestError] = useState<string | null>(null);


      // update
      const updateUserData = async () => {
        try {
            // let requestPhone = displayedPhoneNumber.replace("+", "");
            const formData = {
                "name": displayedName,
                "phone": displayedPhoneNumber,
            };

            const baseURL = process.env.REACT_APP_BASE_URL;
            const response = await axiosInstance.post(`${baseURL}/api/user/update-profile-basic`, formData, {
                withCredentials: true
            });
            // update request
            if (response.status === 200) {
                setName(displayedName);
                sessionStorage.setItem('name', displayedName);
                setPhone(displayedPhoneNumber);
                sessionStorage.setItem('phone', displayedPhoneNumber);
            }

        } catch (error) {
            console.error('Login error', error);
            setRequestError('Authentication failed. Please check your password and try again.');
        }
      };

      const verifyPhoneNumber = async () => {
          try {
              const baseURL = process.env.REACT_APP_BASE_URL;

                const response = await axiosInstance.post(
                  `${baseURL}/api/auth/verify/send-phone-verification-code/`,
                  {},
                  {
                    headers: {
                      'Accept': 'application/json',
                    },
                    withCredentials: true
                  }
                );

              console.log(response);

              navigate('/verify/phone-number?phone=' + displayedPhoneNumber);
              setRequestError(null);
          } catch (error) {
                console.error('Login error', error);
                setRequestError('invalid phone number');
          }
          navigate('/verify/phone-number?phone=' + displayedPhoneNumber);
      }

    return (
        <div>
            <Sidebar defaultExpanded={true} activeItem="profile"/>
            <div className={classes.sideBarGrow}>
                {/*title*/}
                {/*title*/}
                <Tooltip
                  label="Manage your profile settings."
                  className={classes.toolTip}
                  position={'bottom'}
                  transitionProps={{ transition: 'scale', duration: 200 }}
                  multiline
                  w={300}
                  radius={"xs"}
                  p={20}
                  fw={500}
                  fz={'14px'}
                  ml={15}
                  offset={-5}
                  bg={theme.colors.gray[9]}
                  color={theme.colors.gray[8]}
                >
                  <div className={classes.inLineStyle}>
                    <Text size="xs" className={classes.pageTitle}>Profile</Text>
                  </div>
                </Tooltip>
                <div className={classes.pageLine}></div>
                {/* info*/}
                <div className={classes.rightDiv}>
                        <Box className={classes.container}>
                            <TextInput
                                value={displayedName}
                                label="Name"
                                description="The name associated with this account"
                                placeholder="Your name"
                                className={classes.input}
                                onChange={handleNameChange}
                            />
                            <TextInput
                                readOnly={true}
                                disabled={true}
                                defaultValue={displayedEmail}
                                label="Email address"
                                description="The email address associated with this account"
                                placeholder="Your email" className={classes.input}
                            />
                              {phoneVerified ? (
                              <TextInput
                                // readOnly
                                disabled={true}
                                defaultValue={displayedPhoneNumber}
                                label="Phone number"
                                description="The phone number associated with this account"
                                placeholder="Your phone number"
                                className={classes.input}
                              />
                            ) : (
                                <div>
                                  <TextInput
                                    value={displayedPhoneNumber}
                                    label="Phone number"
                                    description="The phone number associated with this account"
                                    placeholder="Your phone number"
                                    className={classes.input}
                                    onChange={(e) => setDisplayedPhoneNumber(e.currentTarget.value)}
                                  />
                                </div>
                            )}
                            {/*<Text style={{fontSize: 16, fontWeight: 500}}>Phone number</Text>*/}
                            {/*<Text style={{fontSize: 14, color: theme.colors.gray[6]}}>The phone number associated with this account</Text>*/}
                            {/*/!*<CustomPhoneInput onChange={setDisplayedPhoneNumber} value={displayedPhoneNumber}></CustomPhoneInput>*!/*/}
                            {/*    <Input*/}
                            {/*      value={displayedPhoneNumber}*/}
                            {/*      onChange={handlePhoneChange}*/}
                            {/*      className={classes.phoneInput}*/}
                            {/*    />*/}

                            <Group position="left" className={classes.button}>
                              <Button type="submit" onSubmit={updateUserData} onClick={updateUserData}>Save</Button>
                            </Group>

                            {/*<Group position="left" mt="lg">*/}
                            {/*    <Text fz={24} fw={600}>Multi-factor authentication (MFA)</Text>*/}
                            {/*    <Text fz={14}>Require an extra security challenge when logging in. If you are unable to pass this challenge, you will have the option to recover your account via email.</Text>*/}
                            {/*  <Button variant="default" >Enable MFA</Button>*/}
                            {/*</Group>*/}


                            {!phoneVerified && (
                                <div>
                                    <Text mt="lg"><span style={{color: theme.colors.orange[1]}}>* </span>Verify
                                        your phone number to <span
                                        style={{color: theme.colors.orange[1]}}>receive $20</span> in credit grants.*
                                    </Text>
                                  <Button
                                    bg={theme.colors.orange[0]}
                                    className={classes.button}
                                    variant="default"
                                    mb={40}
                                    onClick={verifyPhoneNumber}
                                    sx={{
                                      '&:active': {
                                        backgroundColor: theme.colors.orange[0],
                                      },
                                      '&:hover': {
                                        backgroundColor: theme.colors.orange[1],
                                      },
                                    }}
                                  >
                                    Verify Phone
                                  </Button>
                                </div>
                            )}
                        </Box>
                </div>
            </div>
        </div>
    );
}

export default Profile;
