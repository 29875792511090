import {
    Burger,
    Button,
    createStyles, Divider, Drawer,
    Paper, ScrollArea,
    Select,
    Text, Tooltip,
    useMantineTheme
} from '@mantine/core';
import {Link, NavLink, useLocation, useNavigate} from 'react-router-dom';
import {rem} from "@mantine/core";
import Sidebar from "../../componenets/sidebar/sidebar";
import React, {ReactNode, useEffect, useState} from "react";
import { notifications } from '@mantine/notifications';
import { TextInput, Group, Box, Title } from '@mantine/core';
import qs from "qs";

import intlTelInput from 'intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import PhoneInput from "../../componenets/input/phoneInput";
import PhoneInputBox from "../../componenets/input/phoneInput";
import CustomPhoneInput from "../../componenets/input/phoneInput";
import DocsTOC from "../Documentation/DocsTableOfContents";
import ApiRefTOC from "./ApiRefTOC";
import {useDisclosure} from "@mantine/hooks";
import ApiRefHamburgerTOC from "./ApiRefHamburgerTOC";


const useStyles = createStyles((theme) => ({
    pageTitle: {
        textAlign: 'left',
        marginTop: 15,
        marginBottom: 10,
        fontSize: '1.5rem',
        fontWeight: 700,
        marginLeft: theme.spacing.md,
        color: theme.colors.gray[0],
        borderBottom: `1px dotted ${theme.colors.gray[7]}`,
        lineHeight: 'normal', // Adjust this line
    },
        pageLine: {
        width: '100%',
        height: '1px',
        backgroundColor: theme.colors.gray[8],
        marginBottom: 0,
        marginLeft: 'auto', // Add this line
        marginRight: 'auto', // Add this line
    },
    hiddenMobile: {
        [`@media (max-width: ${1200}px)`]: {
            display: 'none',
        },
    },

    hiddenDesktop: {
        [`@media (min-width: ${1200}px)`]: {
            display: 'none',
        },
    },
    headerButton: {
      fontSize: 18,
        [`@media (max-width: ${600}px)`]: {
            fontSize: 14
        },
        [`@media (max-width: ${375}px)`]: {
            fontSize: 12
        },

    },
        childPage: {
        marginLeft: "230px",
        marginTop: "54px",
        [`@media (max-width: ${1200}px)`]: {
            fontSize: 14,
            marginLeft: "0px",
        },
    }
}));

interface ApiReferenceProps {
    children?: ReactNode;
    activeItem?: string;
}

export function ApiReferencePage({ children, activeItem="classify"}: ApiReferenceProps) {
    const theme = useMantineTheme();
    const { classes } = useStyles();

        // Burger
    const [drawerOpened, { toggle: toggleDrawer, close: closeDrawer }] = useDisclosure(false);

    return (
        <div>
            <Sidebar defaultExpanded={false} activeItem={activeItem}/>

            <div style={{marginLeft: "60px"}}>

                {/*header*/}
                <Group m={0} p={0} position="apart" spacing="sm" style={{
                    backgroundColor: theme.colors.dark[8],
                    position: 'fixed',
                    width: "calc(100% - 70px)",
                }}>
                    <div>
                        <NavLink to="/docs/overview" style={{textDecoration: 'none'}}>
                            <Button className={classes.headerButton} h={50}
                                    style={{color: theme.colors.gray[1]}}
                                    variant="subtle" radius={0} component="span"
                                    onClick={() => {
                                    }}>Documentation</Button>
                        </NavLink>
                        <NavLink to="/docs/api-reference" style={{textDecoration: 'none'}}>
                            <Button className={classes.headerButton} h={50} style={{
                                color: theme.colors.gray[1],
                                borderBottom: "3px solid #FFF"
                            }} variant="subtle" radius={0} component="span" onClick={() => {
                            }}>API reference</Button>
                        </NavLink>
                    </div>
                    <div onClick={toggleDrawer}
                         style={{
                             display: 'flex',
                             margin: 0,
                             padding: 0,
                         }}>
                        <Burger
                            opened={drawerOpened}
                            className={classes.hiddenDesktop}
                        />
                    </div>
                </Group>



                {/*hamburger*/}
                <Drawer
                    opened={drawerOpened}
                    onClose={closeDrawer}
                    size="100%"
                    padding="md"
                    title="NutriCloud"
                    className={classes.hiddenDesktop}
                    zIndex={1000000}
                >
                    <ScrollArea h={`calc(100vh - ${rem(60)})`} mx="-md">
                        <Divider my="sm"
                                 color={theme.colorScheme === 'dark' ? 'dark.5' : 'gray.1'}/>
                        <ApiRefHamburgerTOC defaultExpanded={true}
                                          activeItem="api-reference"/>
                        <Divider my="sm"
                                 color={theme.colorScheme === 'dark' ? 'dark.5' : 'gray.1'}/>
                    </ScrollArea>
                </Drawer>
                <div className={classes.pageLine}></div>

                {/*TOC*/}
                <div className={classes.hiddenMobile}>
                    <ApiRefTOC defaultExpanded={true} activeItem={activeItem}/>
                </div>

                {/*children components*/}
                <div className={classes.childPage}>
                    {children}
                </div>
            </div>
        </div>
    );
}

export default ApiReferencePage;
