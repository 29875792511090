import axios from 'axios';
import { useAuth } from './AuthContext';

// Create a custom Axios instance
const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    withCredentials: true // If you're using cookies, this is important
});

// Intercept request to add access token
axiosInstance.interceptors.request.use(
    (config) => {
        const accessToken = localStorage.getItem('accessToken');
        if (accessToken) {
            config.headers.Authorization = `Bearer ${accessToken}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// Intercept responses to handle errors like 401 Unauthorized
axiosInstance.interceptors.response.use(
    (response) => response, // If the response is successful, just return it
    async (error) => {
        if (error.response?.status === 401) {
            const { logout } = useAuth(); // Assuming you have a logout function in your auth context

            // Automatically log the user out on 401 Unauthorized response
            logout();
        }
        return Promise.reject(error);
    }
);

export default axiosInstance;
