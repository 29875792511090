import React, {useState} from 'react';
import {
    Paper,
    Text,
    Flex,
    Container,
    createStyles,
    Col,
    Grid,
    useMantineTheme
} from '@mantine/core';
import { FaHome, FaUser, FaEnvelope, FaPhone, FaSearch, FaHeart, FaComment, FaBars } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { FaInfoCircle } from 'react-icons/fa';
import Demo from "../Components/Feedback";
import DocsPage from "../DocsPage";
import RateLimitsAccordionTable from "../../Settings/limitsTable";
import {RateLimitSelector} from "../../Settings/components/rateLimitPicker";
import FeedbackBanner from "../Components/Feedback";
import {Prism} from "@mantine/prism";


const useStyles = createStyles((theme) => ({
    iconBox: {
        display: 'flex',
        justifyContent: 'flex-start', // Align items to the start (left side)
        alignItems: 'center',
        marginBottom: theme.spacing.md,
        '& svg': {
            transition: 'transform 0.3s ease',
        },
        '&:hover svg': {
            transform: 'scale(1.2)',
        },
    },
    contentBox: {
        marginLeft: theme.spacing.md, // Add some space between the icon and the text
    },

    // headers
    pageTitle: {
        textAlign: "left",
        fontSize: theme.fontSizes.xxl,
        fontWeight:600,
        marginTop: "30px",
        marginBottom: "30px",
    },
    mainHeader: {
        fontWeight: 600,
        fontSize: theme.fontSizes.lg,
        color: theme.colors.gray[2],
        marginTop: "60px",
        marginBottom: "10px",
    },
    subHeader: {
        fontWeight: 500,
        fontSize: theme.fontSizes.md,
        color: theme.colors.gray[2],
        marginTop: "30px",
        marginBottom: "0px",
    },

    // bullet list
    link: {
        color: theme.colors.orange[0],
        textDecoration: 'none',
        '&:hover': {
            color: theme.colors.orange[9],  // Change this to the color you want on hover
        },
    },
    bulletList: {
        listStyleType: 'disc',
    },

    // text
    paragraphText: {
        fontSize: '18px',
        marginBottom: "10px",
    },

    boldParagraphText: {
        fontSize: '16px',
        marginBottom: "10px",
        fontWeight: 900,
        color: theme.colors.gray[4]
    },

    // info boxes
    infoBox: {
        backgroundColor: theme.colors.gray[8],
        padding: theme.spacing.md,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        width: '100%',
        marginTop: '10px',
        marginBottom: '20px',
    },
    infoText: {
        marginLeft: theme.spacing.sm,
        fontSize: '16px',
    },
    infoIcon: {
        fontSize: '1em',
        flexShrink: 0,
    },
    table: {
        width: '100%',
        borderCollapse: 'collapse',
    },
    cell: {
        padding: theme.spacing.sm,
        textAlign: 'left',
        borderBottom: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.gray[7] : theme.colors.gray[0]}`,
    },
    tableHeader: {
        borderBottomWidth: '2px',  // make the header line bolder
        fontWeight: 'bold',
    },
    lastRow: {
        borderBottom: 'none',  // remove the bottom border from the last row
    }
}));

// create gradient boxes
const boxData = [
    { colors: ['#FF073A', '#FF7F56'], title: 'Home', description: 'Description 1', logo: <FaHome size="1.5em" />, to: '/examplePage' },
    { colors: ['#0047AB', '#418FDE'], title: 'User', description: 'Description 2', logo: <FaUser size="1.5em" /> , to: '/examplePage'},
    { colors: ['#008000', '#32CD32'], title: 'Mail', description: 'Description 3', logo: <FaEnvelope size="1.5em" /> , to: '/examplePage'},
    { colors: ['#FF00FF', '#FF77FF'], title: 'Phone', description: 'Description 4', logo: <FaPhone size="1.5em" /> , to: '/examplePage'},
    { colors: ['#FF4500', '#FFA07A'], title: 'Search', description: 'Description 5', logo: <FaSearch size="1.5em" /> , to: '/examplePage'},
    { colors: ['#D2691E', '#FFA500'], title: 'Menu', description: 'Description 8', logo: <FaBars size="1.5em" />, to: '/examplePage' },
    { colors: ['#FF073A', '#FF7F56'], title: 'Home', description: 'Description 1', logo: <FaHome size="1.5em" />, to: '/examplePage' },
    { colors: ['#0047AB', '#418FDE'], title: 'User', description: 'Description 2', logo: <FaUser size="1.5em" /> , to: '/examplePage'},
];


function ErrorCodesGuide() {
    const { classes } = useStyles();
    const theme = useMantineTheme();

    const [selectedRateLimitTier, setSelectedRateLimitTier] = useState('Free');

    const gradientBoxes = boxData.map((box, index) => (
        <Link to={box.to} key={index} style={{ textDecoration: 'none', color: 'inherit' }}>
        <Flex className={classes.iconBox} key={index}>
            <Paper
                style={{
                    background: `linear-gradient(to top right, ${box.colors[0]}, ${box.colors[1]})`,
                    padding: '.5em',
                    borderRadius: '4px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '3em',
                    height: '3em',
                    boxSizing: 'border-box',
                }}
            >
                {box.logo}
            </Paper>
            <Flex direction="column" className={classes.contentBox}>
                <Text weight={500}>{box.title}</Text>
                <Text size="sm">{box.description}</Text>
            </Flex>
        </Flex>
        </Link>

    ));

    return (
        <Container>
            {/*title*/}
            <Text className={classes.pageTitle}>Error Codes</Text>
            <Text className={classes.paragraphText}>The following overview covers error
                codes you may encounter when using the API. You'll find detailed
                guidance for each error code in its own section below.</Text>
            {/* api error code table */}
            <Text className={classes.mainHeader}>API errors</Text>
            <table className={classes.table}>
                <thead>
                <tr>
                    <th className={`${classes.cell} ${classes.paragraphText}`}><span
                        className={classes.boldParagraphText}>CODE</span>
                    </th>
                    <th className={`${classes.cell} ${classes.paragraphText}`}><span
                        className={classes.boldParagraphText}>OVERVIEW</span>
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td style={{fontWeight: 800}}
                        className={`${classes.cell} ${classes.paragraphText}`}>
                        401 - Invalid Authentication
                    </td>
                    <td className={`${classes.cell} ${classes.paragraphText}`}>
                        <Text><span style={{fontWeight: 800}}>Cause: </span>
                            Invalid Authentication
                        </Text>
                        <Text><span style={{fontWeight: 800}}>Solution: </span>
                            Ensure the correct API key and requesting organization are
                            being used.
                        </Text>
                    </td>
                </tr>
                <tr>
                    <td style={{fontWeight: 800}}
                        className={`${classes.cell} ${classes.paragraphText}`}>
                        401 - Invalid API Key
                    </td>
                    <td className={`${classes.cell} ${classes.paragraphText}`}>
                        <Text><span style={{fontWeight: 800}}>Cause: </span>
                            Your API credentials aren't valid
                        </Text>
                        <Text><span style={{fontWeight: 800}}>Solution: </span>
                            Double-check your API key, refresh your browser cache, or
                            create a fresh API key
                        </Text>
                    </td>
                </tr>

                <tr>
                    <td style={{fontWeight: 800}}
                        className={`${classes.cell} ${classes.paragraphText}`}>
                        401 - Missing Organization Membership
                    </td>
                    <td className={`${classes.cell} ${classes.paragraphText}`}>
                        <Text><span style={{fontWeight: 800}}>Cause: </span>
                            You haven't been added to any organization in the system
                        </Text>
                        <Text><span style={{fontWeight: 800}}>Solution: </span>
                            Reach out to support for a new organization or ask your org
                            manager to send you an invite
                        </Text>
                    </td>
                </tr>

                <tr>
                    <td style={{fontWeight: 800}}
                        className={`${classes.cell} ${classes.paragraphText}`}>
                        403 - Geographic Restriction
                    </td>
                    <td className={`${classes.cell} ${classes.paragraphText}`}>
                        <Text><span style={{fontWeight: 800}}>Cause: </span>
                            Your location isn't supported for API access
                        </Text>
                        <Text><span style={{fontWeight: 800}}>Solution: </span>
                            Review the supported regions documentation
                        </Text>
                    </td>
                </tr>

                <tr>
                    <td style={{fontWeight: 800}}
                        className={`${classes.cell} ${classes.paragraphText}`}>
                        429 - Too Many Requests
                    </td>
                    <td className={`${classes.cell} ${classes.paragraphText}`}>
                        <Text><span style={{fontWeight: 800}}>Cause: </span>
                            You're exceeding the allowed request frequency
                        </Text>
                        <Text><span style={{fontWeight: 800}}>Solution: </span>
                            Add delays between requests and consult the rate limiting
                            documentation
                        </Text>
                    </td>
                </tr>

                <tr>
                    <td style={{fontWeight: 800}}
                        className={`${classes.cell} ${classes.paragraphText}`}>
                        429 - Usage Limit Exceeded
                    </td>
                    <td className={`${classes.cell} ${classes.paragraphText}`}>
                        <Text><span style={{fontWeight: 800}}>Cause: </span>
                            You've depleted your available credits or hit your spending
                            cap
                        </Text>
                        <Text><span style={{fontWeight: 800}}>Solution: </span>
                            Purchase additional credits or request a limit increase
                        </Text>
                    </td>
                </tr>

                <tr>
                    <td style={{fontWeight: 800}}
                        className={`${classes.cell} ${classes.paragraphText}`}>
                        500 - Server Error
                    </td>
                    <td className={`${classes.cell} ${classes.paragraphText}`}>
                        <Text><span style={{fontWeight: 800}}>Cause: </span>
                            Internal server malfunction
                        </Text>
                        <Text><span style={{fontWeight: 800}}>Solution: </span>
                            Wait briefly and try again; contact support if it continues.
                            Monitor the status page
                        </Text>
                    </td>
                </tr>

                <tr>
                    <td style={{fontWeight: 800}}
                        className={`${classes.cell} ${classes.paragraphText}`}>
                        503 - Server Overload
                    </td>
                    <td className={`${classes.cell} ${classes.paragraphText}`}>
                        <Text><span style={{fontWeight: 800}}>Cause: </span>
                            The system is experiencing heavy load
                        </Text>
                        <Text><span style={{fontWeight: 800}}>Solution: </span>
                            Wait a few moments and retry your request
                        </Text>
                    </td>
                </tr>

                </tbody>
            </table>


            {/*persistant errors*/}
            <Text className={classes.mainHeader}>Persistent errors</Text>
            <Text className={classes.paragraphText}>
                If the issue persists, please email our support team with the following information:
            </Text>
            <ul>
                <li className={classes.paragraphText}>The model you were using</li>
                <li className={classes.paragraphText}>The error message and code you received</li>
                <li className={classes.paragraphText}>The request data and headers you sent</li>
                <li className={classes.paragraphText}>The timestamp and timezone of your request</li>
                <li className={classes.paragraphText}>Any other relevant details that may help us diagnose the issue</li>
            </ul>
            <Text className={classes.paragraphText}>
                Include these details in your email so our team can investigate the issue thoroughly. While we strive to respond promptly, please note that response times may vary depending on current support volume.
            </Text>

            {/*handling errors*/}
            <Text className={classes.mainHeader}>Handling errors</Text>
            <Text className={classes.paragraphText}>When making API calls, it's recommended to implement error handling in your code. Here's a suggested approach you could use:</Text>
            <div style={{marginTop: 50}}>
                <Prism language={"python"}>
                {`import requests
import json
from requests.exceptions import RequestException

def search_nutricloud(query, limit=15, threshold=0.41, data_source="verified"):
    url = "http://api.nutricloud.cloud/v1/search"
    headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + NUTRICLOUD_API_KEY
    }

    data = {
        "query": query,
        "limit": limit,
        "threshold": threshold,
        "data_source": data_source
    }

    try:
        response = requests.post(url, headers=headers, data=json.dumps(data))

        # Check status codes
        if response.status_code == 200:
            return response.json()
        elif response.status_code == 429:
            raise Exception("Too many requests. Please wait before trying again.")
        elif response.status_code == 401:
            raise Exception("Invalid authentication. Please check your API key.")
        elif response.status_code == 503:
            raise Exception("Server is currently overloaded. Please try again later.")
        else:
            raise Exception(f"Unexpected status code: {response.status_code}")

    except RequestException as e:
        raise Exception(f"Network error occurred: {str(e)}")`}
            </Prism>
            </div>

            <FeedbackBanner/>
        </Container>
    );
}

export default function ErrorCodesGuidePage() {
    return (
        <div>
            <DocsPage children={<ErrorCodesGuide/>}/>
        </div>
    );
}
