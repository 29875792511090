import React from 'react';
import Typewriter from 'typewriter-effect';
import styles from './LandingPageTypewriteText.module.css'

function LandingPageTypewriteText() {
    return (
            <div className={styles.type_writer_container}>
                <div style={{paddingBottom: 10,}}>
                    <Typewriter
                        options={{
                            strings: ['product builders', 'developers', 'side projects', 'app designers'],
                            autoStart: true,
                            loop: true,
                            deleteSpeed: 30,
                        }}
                        onInit={(typewriter) => {
                            typewriter
                                .changeDelay(40)
                                .typeString('<span>product builders</span>')
                                .pauseFor(2000)
                                .deleteAll()
                                .typeString('<span>developers</span>')
                                .pauseFor(2000)
                                .deleteAll()
                                .typeString('<span>side projects</span>')
                                .pauseFor(2000)
                                .deleteAll()
                                .typeString('<span>app designers</span>')
                                .pauseFor(2000)
                                .start();
                        }}
                    />
                </div>
            </div>
    );
}

export default LandingPageTypewriteText;
