import { createStyles, Paper, Text } from '@mantine/core';
import TwoColumn from "../TwoColumns";
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import {rem} from "@mantine/core";
import WhiteWireFrameButton from "../../../componenets/buttons/whiteWireFrameButton";

const useStyles = createStyles((theme) => ({
    pageTitle: {
        textAlign: 'left',
        marginTop: 80,
        marginBottom: 40,
        fontSize: '2.5rem',
        marginLeft: theme.spacing.md,
        color: theme.colors.gray[0],
        '@media (min-width: 600px)': {
            marginTop: 100,
            marginBottom: 60,
            fontSize: '3.5rem',
        },

        '@media (min-width: 1280px)': {
            marginTop: 150,
            marginBottom: 80,
            fontSize: '4.5625rem',
        },
    },

    header: {
        textAlign: 'left',
        fontSize: theme.fontSizes.lg,
        color: theme.colors.gray[3],
        fontWeight: 400,
        '@media (min-width: 600px)': {
            fontSize: theme.fontSizes.xl
        },

        '@media (min-width: 1280px)': {
            fontSize: theme.fontSizes.xxl,
        },
    },

    pageLine: {
        width: '97%',
        height: '2px',
        backgroundColor: theme.colors.gray[7],
        marginBottom: theme.spacing.md,
        marginLeft: 'auto', // Add this line
        marginRight: 'auto', // Add this line
    },

    paragraphText: {
        fontSize: '18px',
        fontWeight: 200,
        color: theme.colors.gray[0],
    },

    rightDiv: {
        marginLeft: '25px',
        '@media (min-width: 1280px)': {
            marginLeft: 0,
        },
    },

    leftDiv: {
        marginBottom: '40px',
    },

    separatingDiv: {
        height: '20px',
        '@media (min-width: 1280px)': {
            height: '30px',
        },
    },
    largeSeparatingDiv: {
        height: '60px',
        '@media (min-width: 1280px)': {
            height: '80px',
        },
    },

    paragraphBoldText: {
        fontWeight: 'bold',
    },

    table: {
        width: '100%',
        borderCollapse: 'collapse',
    },
    cell: {
        padding: theme.spacing.md,
        textAlign: 'left',
        borderBottom: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.gray[0] : theme.colors.gray[0]}`,
    },
    tableHeader: {
        borderBottomWidth: '2px',  // make the header line bolder
        fontWeight: 'bold',
    },
    lastRow: {
        borderBottom: 'none',  // remove the bottom border from the last row
    }
}));

export function DataProcessingAddendum() {
    const { classes } = useStyles();
    return (

        <div>
            <Text size="xxl" className={classes.pageTitle}>Data Processing Addendum</Text>
            <div className={classes.pageLine}></div>
            {/* info*/}
            <TwoColumn
                left={
                    <div className={classes.leftDiv}>
                        <Text style={{fontWeight: 'bold'}} className={classes.paragraphText}>Updated</Text>
                        <Text className={classes.paragraphText}>August 1, 2023</Text>
                    </div>
                }
                right={
                    <div className={classes.rightDiv}>
                        <p className={classes.paragraphText}>These Service Terms govern your use of the Services. Capitalized terms not defined here will have the meanings in the Terms of Use, Enterprise Agreement, or other agreement you have with us governing your use of the Services (“Agreement”). If there is a conflict between the Service Terms and your Agreement, the Service Terms will control. For purposes of these Terms, “Content” includes “Customer Content.”</p>
                        <p className={classes.paragraphText}>Note that this agreement is only applicable to our business service offerings (APIs for text completion, images, embeddings, moderations, etc.) and NOT our consumer services.</p>
                        <p className={classes.paragraphText}>Unfortunately, we are unable to review or sign DPAs provided by our customers or customize our DPA on a case by case basis.</p>
                        <p className={classes.paragraphText}>Once you submit this form, you will have the opportunity to review the agreement before accepting or rejecting the terms. A request to review the agreement will also be sent to the signer email address you provided.</p>
                        <p className={classes.paragraphText}>The agreement will only be considered legally binding after you accepted the terms.</p>

                        <WhiteWireFrameButton text="Execute Data Processing Agreement" href="/"/>

                        <div className={classes.largeSeparatingDiv}></div>

                    </div>
                }
            />
        </div>
    );
}
