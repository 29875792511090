import React, {FC, ReactNode} from 'react';
// general components
import {NavBar} from "./header/header";
import {NewFooter} from "./footer/NewFooter";

const footerData = [
    {
        title: 'Company',
        links: [
            { label: 'About Us', link: '/about' },
            { label: 'Careers', link: '/careers' },
            { label: 'Contact', link: '/contact' }
        ]
    },
    {
        title: 'API',
        links: [
            { label: 'API Reference', link: '/api/v1/all-api-reference' },
            { label: 'Relational Search', link: '/api/v1/food-relational-search' },
            { label: 'Full Nutrient Profile', link: '/api/v1/full-nutrient-profile' },
            { label: 'AutoComplete', link: '/api/v1/all-api-reference' },
            { label: 'Database', link: '/api/v1/database-search' }
        ]
    },
    {
        title: 'Getting Started',
        links: [
            { label: 'Playground', link: '/playground' },
            { label: 'Pricing', link: '/pricing' },
            { label: 'Documentation', link: '/all-docs' },
            { label: 'Sign Up', link: '/signup' },
        ]
    },
    {
        title: 'Legal',
        links: [
            { label: 'Terms $ Policies', link: '/policies' },
            { label: 'Terms of Use', link: '/policies/terms-of-use' },
            { label: 'Privacy Policy', link: '/policies/privacy-policy' },
            { label: 'Contact Us', link: '/contact' }
        ]
    }
];

const Page: FC<{ children: ReactNode }> = ({ children }) => (
    <div>
        <NavBar/>
        {children}
        <NewFooter
            data={footerData}
        />
    </div>
);

export default Page;
