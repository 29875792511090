import React, { forwardRef } from 'react';
import { Group, Avatar, Text, Select } from '@mantine/core';
import {PythonIcon} from "../icons/Python";
import {JavaIcon} from "../icons/Java";
import {SwiftIcon} from "../icons/Swift";
import {RubyIcon} from "../icons/Ruby";
import {KotlinIcon} from "../icons/Kotlin";
import {CppIcon} from "../icons/cpp";
import {BashIcon} from "../icons/Bash";
import {GoLangIcon} from "../icons/GoLang";
import {JsIcon} from "../icons/JavaScript";
import {NodeJSIcon} from "../icons/NodeJS";
import {TsIcon} from "../icons/TypeScript";


const data = [
    {
        icon: BashIcon,
        label: 'bash',
        value: 'bash',
    },
    {
        icon: CppIcon,
        label: 'cpp',
        value: 'cpp',
    },
    {
        icon: GoLangIcon,
        label: 'golang',
        value: 'golang',
    },
    {
        icon: JavaIcon,
        label: 'java',
        value: 'java',
    },
    {
        icon: JsIcon,
        label: 'javascript',
        value: 'javascript',
    },
    {
        icon: KotlinIcon,
        label: 'kotlin',
        value: 'kotlin',
    },
    {
        icon: NodeJSIcon,
        label: 'nodejs',
        value: 'nodejs',
    },
    {
        icon: PythonIcon,
        label: 'python',
        value: 'python',
    },
    {
        icon: RubyIcon,
        label: 'ruby',
        value: 'ruby',
    },
    {
        icon: SwiftIcon,
        label: 'swift',
        value: 'swift',
    },
    {
        icon: TsIcon,
        label: 'typescript',
        value: 'typescript',
    },
];

interface ItemProps extends React.ComponentPropsWithoutRef<'div'> {
    icon: React.ElementType; // we specify that the `icon` prop will be a React component
    label: string;
}

interface SelectItem {
    icon: React.ElementType;
    label: string;
    value: string;
}


const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
    ({ icon: Icon, label, ...others }: ItemProps, ref) => (
        <div ref={ref} style={{ height: '50px' /* or any other value */ }} {...others}>
            <Group noWrap>
                <Icon width="2rem" height="2rem" />
                <div>
                    <Text size="sm">{label}</Text>
                </div>
            </Group>
        </div>
    )
);

interface ProgrammingLanguageSelectorProps {
    setSelectedLanguage: (value: string) => void;
}

export const ProgrammingLanguageSelector: React.FC<ProgrammingLanguageSelectorProps> = ({ setSelectedLanguage }) => {

    return (
        <Select
            defaultValue={'Bash'}
            label="Choose language"
            placeholder="Bash"
            itemComponent={SelectItem}
            data={data}
            searchable
            maxDropdownHeight={400}
            nothingFound="Nothing found"
            filter={(value: string, item: SelectItem) =>
                item.label.toLowerCase().includes(value.toLowerCase().trim())
            }
            onChange={(item) => {
                if (item) {
                    setSelectedLanguage(item.valueOf());
                }
            }}        />
    );
}
