import React, {useState} from 'react';
import {
    Button, Col,
    createStyles, Grid,
    Input,
    Text,
    TextInput,
    useMantineTheme
} from '@mantine/core';
import ThreeDContainer from "../threeDContainer";
import {PythonIcon} from "../../../../componenets/icons/Python";
import {JavaIcon} from "../../../../componenets/icons/Java";
import {TsIcon} from "../../../../componenets/icons/TypeScript";
import {Prism} from "@mantine/prism";
import ScrollRevealGradientLine from "../coloredLine";
import {ChevronRight} from "react-feather";
import AnimatedLink from "../animatedLink";

// Create custom styles
const useStyles = createStyles((theme, { bloomColor }: { bloomColor: string }) => ({
    overallSection: {
        display: "flex",
        flexDirection: "column",
    },
    smallSection: {
        display: "flex",
        justifyContent: "left",
        alignItems: "center",
        flexDirection: "row",
        marginTop: 40,
    },
        textInput: {
        width: "100%",
        color: theme.colors.gray[1]

    },
        glowTitleText: {
        fontSize: '2rem',
        fontWeight: 500,
        maxWidth: 800,
        lineHeight: '1.1',
            textAlign: "left",
    },
    bigNumber: {
                fontSize: '4rem',
        fontWeight: 500,
        maxWidth: 800,
        lineHeight: '1.1',
            textAlign: "left",
        marginTop: 40
    },
      textRow: {
    margin: 0, // Removes margin from individual text rows
    padding: 0, // Removes padding from individual text rows
    fontSize: '4.5rem',
      lineHeight: '1', // Adjust line height to manage space between the text rows
      fontWeight: 600,
          marginTop: 180,
          [`@media (max-width: 767px)`]: {
            fontSize: '3rem',
        },
  },

    description: {
        fontSize: '1rem',
        marginTop: '20px',
        color: theme.colors.gray[5]
    },
    pipInstall: {
        marginLeft: "auto",
        // marginTop: "-20vh",
        // marginRight: "-10vw",
        // width: '23vw',
        [`@media (min-width: 1000px)`]: {
            marginTop: "-15vh",
        },
        [`@media (max-width: 767px)`]: {
            paddingLeft: 30,
        },

    },

    callToAction: {
        marginTop: -100,
        [`@media (max-width: 767px)`]: {
            marginTop: -200,
        },
    },

    bottomSection: {
        display: "flex",
        justifyContent: "left",
        flexDirection: "row",
        height: "auto",
        marginLeft: 10,

    },
    bloomEffect: {
      position: 'absolute', // Keep the bloom effect behind the icon
      backgroundColor: bloomColor,
      // borderRadius: '50%',
        marginLeft: "15vw",
        marginTop: "27vh",
      boxShadow: `0 0 190px 200px ${bloomColor}`, // Increase blur radius and spread for a more gradual effect
      opacity: 0.6, // Lower the opacity for a lighter effect
        zIndex: -1,
            [`@media (max-width: 767px)`]: {
      marginLeft: "40vw",
                boxShadow: `0 -50px 190px 200px ${bloomColor}`, // Increase blur radius and spread for a more gradual effect
    },
    },

}));

function DevelopementSection() {
    const bloomColor = '#28AFB0';
  const { classes } = useStyles({ bloomColor });
      const theme = useMantineTheme();

      const serviceColor = "#28AFB0"
    return (
        <div style={{padding: 20}}>
            {/*<ThreeDContainer bloomColor={'radial-gradient(circle, rgba(211,33,34,0.2) 0%, rgba(211,33,34,0) 70%)'}>*/}
            <ThreeDContainer bloomColor={'radial-gradient(circle, rgba(40, 175, 176,0.2) 0%, rgba(40, 175, 176,0) 70%)'} >
                <div style={{
                    padding: "2vh",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center"
                }}>
                    <Grid>
                    {/* Left Section */}
                    <Col span={12} md={6} style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        // marginLeft: 30
                    }}>
                        <Text className={classes.glowTitleText}>
                            <span
                                style={{color: serviceColor, fontSize: "3rem"}}>Build, Launch, Scale</span>
                        </Text>
                        <Text fz={18} mt={40}>
                            Supercharge your food app development journey with our
                            powerful APIs. Eliminate time-consuming data sourcing,
                            streamline complex calculations, and future-proof your app
                            with seamless scaling. Radically reduce development costs
                            while accelerating your time-to-market and positioning your
                            app for long-term success.
                        </Text>
                    </Col>
                    {/* Right Section */}
                    <Col span={12} md={6}>
                        <div style={{
                            justifyContent: 'center',
                            display: 'flex',
                        }}>
                        <img
                            src={`${process.env.PUBLIC_URL}/landingPage/food-app-triage.png`}
                            style={{width: "100%", height: "auto", maxWidth: 500}} alt="Food App"/>
                            </div>
                    </Col>
                    </Grid>
                </div>

            </ThreeDContainer>

            <div className={classes.bottomSection}>
                <ScrollRevealGradientLine colorStart="rgb(211, 33, 34, 0)"
                                          colorMiddle="#28AFB0"
                                          colorEnd="#28AFB0"
                                          leftContent={
                                              <div className={classes.overallSection}>
                                                  <Grid>
                                                      {/*text*/}
                                                      <Col span={12} sm={6} md={7} p={50}>
                                                          <Text><span
                                                              style={{
                                                                  fontWeight: 800,
                                                                  fontSize: "26px"
                                                              }}>NutriClouds APIs</span> cut
                                                              your development time in
                                                              half with our powerful
                                                              APIs. Streamline
                                                              integration, unlock
                                                              advanced features, and
                                                              deliver a superior user
                                                              experience.</Text>
                                                          <div style={{marginTop: 40,}}>
                                                              <AnimatedLink
                                                                  text={"Explore NutriCloud APIs"}
                                                                  path={"/"}/>
                                                          </div>
                                                      </Col>
                                                      {/*  pip install*/}
                                                      <Col span={12} sm={6} md={5} mb={100} mt={20}
                                                          className={classes.pipInstall}>
                                                          <div
                                                              className={classes.bloomEffect}></div>
                                                          <ThreeDContainer>
                                                              <div
                                                                  style={{padding: '2vh',}}>
                                                                  <Text
                                                                      className={classes.glowTitleText}>
                                                                        <span
                                                                        style={{color: serviceColor}}>Slash Your Dev Time</span> with
                                                                      NutriCloud
                                                                      APIs</Text>
                                                                  <Text
                                                                      className={classes.description}>
                                                                      Bypass tedious
                                                                      data wrangling and
                                                                      complex
                                                                      calculations. Our
                                                                      APIs handle the
                                                                      heavy lifting, so
                                                                      you can focus on
                                                                      building amazing
                                                                      features.
                                                                  </Text>

                                                                  {/*icons*/}
                                                                  <div>
                                                                      <Text
                                                                          className={classes.bigNumber}
                                                                          style={{color: serviceColor}}>70%</Text>
                                                                      <Text>Faster
                                                                          development*</Text>
                                                                  </div>
                                                              </div>
                                                          </ThreeDContainer>
                                                      </Col>
                                                      <Col span={12} className={classes.callToAction}>
                                                          <div style={{paddingLeft: 40}}>
                                                              <Text
                                                                  className={classes.textRow}>Build
                                                                  Your Food App
                                                                  Vision</Text>
                                                              <Text style={{
                                                                  maxWidth: 600,
                                                                  marginBottom: 40
                                                              }}>We
                                                                  make
                                                                  food data simple, so
                                                                  you can
                                                                  focus
                                                                  on building amazing
                                                                  things.
                                                                  Join us
                                                                  and turn your concept
                                                                  into a
                                                                  game-changing
                                                                  app.</Text>
                                                          </div>
                                                      </Col>
                                                  </Grid>
                                              </div>
                                          }
                />
            </div>


        </div>
    );
}

export default DevelopementSection;
