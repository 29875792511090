import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { MantineProvider } from '@mantine/core';
import {Notifications} from "@mantine/notifications";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <React.StrictMode>
        <MantineProvider
            withGlobalStyles
            withNormalizeCSS
            theme={{
                primaryColor: 'orange',
                colorScheme: 'dark',
                colors: {
                    blue: ['#E9EDFC', '#C1CCF6', '#99ABF0', '#7090EA', '#4776E4', '#1E5CDE', '#0044C8', '#0032A0', '#002178', '#000F50'],
                    white: ['#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff'],
                    black: ['#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000'],
                    orange: ['#FFA500', '#FF8C00', '#FF7F50', '#FF6347', '#FF4500', '#FFD700', '#FFA07A', '#FF8B00', '#FFA500', '#FF7F00']
                },

                spacing: {
                    xs: '5px',
                    sm: '10px',
                    md: '20px',
                    lg: '40px',
                  xl: '60px',
                  xxl: '80px',
                },

                radius: {
                    xs: '5px',
                    sm: '10px',
                    md: '15px',
                    lg: '20px',
                    xl: '60px',
                    xxl: '120px',  // You can add as many radius sizes as you need
                },

                fontSizes: {
                    xs: '14px',
                    sm: '16px',
                    md: '20px',
                    lg: '24px',
                    xl: '32px',
                    xxl: '42px',
                },
            }}
        >
            <Notifications
                position="top-center"
                limit={1}
                containerWidth={300}
                zIndex={10000}
            />
            <App />
        </MantineProvider>
    </React.StrictMode>
);


reportWebVitals();
