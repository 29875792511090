import React from 'react';
import {Paper, Col, Text, Code, useMantineTheme, Divider} from '@mantine/core';
import {createStyles} from "@mantine/styles";
import {Documentation, RequestBodyItem} from "./BaseDocumentation";
import DocsPage from "../../Documentation/DocsPage";
import ApiReferencePage from "../ApiReferencePage";
import getPythonCode from "../../PlayGround/Components/DynamicCodeGenerators/Python";
import {useNavigate} from "react-router-dom";

const useStyles = createStyles((theme) => ({
    paper: {
        padding: theme.spacing.md,
        margin: theme.spacing.md,
    },
    title: {
        marginBottom: theme.spacing.md,
    },
    code: {
        wordBreak: 'break-all',
    },
}));

const FoodSearch = () => {
    const theme = useMantineTheme();
    const navigate = useNavigate();
    const uri = `/api/search/food/`;
    const httpMethod = `POST`;
    const endpointName = `Food Search`;
    const parameters = {
  "queries": [
    "Salmon Filet",
    "Chicken Breast"
  ],
  "limit": 1,
  "threshold": 0,
  "data_source": "verified",
  "nutrients": "macronutrients"
}

    const exampleResponse = `[
  {
    "Salmon Filet": [
      {
        "food_id": 29354,
        "food_name": "Salmon, fillet, pan-fried",
        "data_source": "norweigan_food_composition",
        "nutrients": [
          {
            "unit": "g",
            "value": 3.2,
            "nutrient_name": "Carbohydrates"
          },
          {
            "unit": "g",
            "value": 19.4,
            "nutrient_name": "Total Fat"
          },
          {
            "unit": "g",
            "value": 21.1,
            "nutrient_name": "Protein"
          },
          {
            "unit": "kcal",
            "value": 271.21,
            "nutrient_name": "Calories (kcal)"
          }
        ],
        "portions": [
          {
            "unit": "g",
            "value": 100
          }
        ],
        "similarity_score": 0.759983659
      }
    ]
  },
  {
    "Chicken Breast": [
      {
        "food_id": 2744682,
        "food_name": "Chicken breast, baked or broiled, skin not eaten, from pre-cooked",
        "data_source": "USDA_FNDDS",
        "nutrients": [
          {
            "unit": "g",
            "value": 0,
            "nutrient_name": "Carbohydrates"
          },
          {
            "unit": "g",
            "value": 3.57,
            "nutrient_name": "Total Fat"
          },
          {
            "unit": "g",
            "value": 28,
            "nutrient_name": "Protein"
          },
          {
            "unit": "kcal",
            "value": 144,
            "nutrient_name": "Calories (kcal)"
          }
        ],
        "portions": [
          {
            "unit": "g",
            "value": 100
          }
        ],
        "similarity_score": 0.717959404
      }
    ]
  }
]`;

    const uriDescription = `The NutriCloud Search API allows you to find foods and retrieve detailed nutrient information, including macronutrients and micronutrients, with customizable filters for accuracy and data source. Batch searching is supported for efficient, high-accuracy dietary analysis. Optimized for single food items.`;

    const requestBody: RequestBodyItem[] = [
        {
            name: "query",
            dataType: "array",
            required: true,
            defaultValue: "",
       description: " An array of search terms, where each term initiates a separate search, enabling batch queries.",
        },
        {
            name: "limit",
            dataType: "number",
            required: false,
            defaultValue: '5',
            description: "Specifies the maximum number of results returned for each search query.",
        },
        {
            name: "Threshold",
            dataType: "number",
            required: false,
            defaultValue: '0',
            description: "Sets the minimum similarity score required for a food item to be included in the results.",
        },
        {
            name: "data_source",
            dataType: "string",
            required: false,
            defaultValue: '0',
            description: "Chooses the source of data quality, with options:for broader but less rigorous data, and \"all_sources\" for the most comprehensive search.",
    reactComponent: (
        <div>
            <Text mt={10} fz={'sm'} fw={400} color={theme.colors.gray[3]}>Accepted
                data_source types:</Text>
            <Code>
                {`[
                   "verified",
                   "unverified",
                   "all"
                    ]`}
            </Code>
            <Text mt={10} fz={'sm'} fw={400} color={theme.colors.gray[3]}>
                To learn about each data source type{' '}
                <span
                    onClick={() => navigate('/docs/search/food')}
                    style={{cursor: 'pointer', color: theme.colors.orange[0]}}
                >
                        see the docs.
                      </span>
            </Text>
        </div>
    )
},
        {
            name: "nutrients",
            dataType: "string",
            required: false,
            defaultValue: '',
            description: "Selects the level of nutrient detail in the results, such as \"macronutrients\" for basic nutrition or \"micronutrients\" for detailed information.",
            reactComponent: (
                <div>
                    <Text mt={10} fz={'sm'} fw={400} color={theme.colors.gray[3]}>Accepted
                        nutrient types:</Text>
                    <Code>
                        {`[
                           "macronutrients",
                           "micronutrients_simplified",
                           "micronutrients_full"
                            ]`}
                    </Code>
                    <Text mt={10} fz={'sm'} fw={400} color={theme.colors.gray[3]}>
                        To learn about each nutrient type{' '}
                        <span
                            onClick={() => navigate('/docs/search/food')}
                            style={{cursor: 'pointer', color: theme.colors.orange[0]}}
                        >
                                see the docs.
                              </span>
                    </Text>
                </div>
            )
        },

    ];


    return (
        <Documentation
            httpMethod={httpMethod}
            endpointName={endpointName}
            parameters={parameters}
            exampleResponse={exampleResponse}
            uri={uri}
            urlDescription={uriDescription}
            requestBody={requestBody}
            // responseBody={requestBody}
        />
    );
}

const RecipeSearch = () => {
    const theme = useMantineTheme();
    const navigate = useNavigate();
    const uri = `/api/search/recipe/`;
    const httpMethod = `POST`;
    const endpointName = `Recipe Search`;
    const parameters = {
           "query": "Chicken tikka massala",
           "limit": 1,
           "threshold": 5,
           "labels": false,
           "nutrients": false,
           "photo": true
         }

    const exampleResponse = `
{
    "status": 200,
    "data": {
      "Chicken Tikka Masala": {
        "similarity": 0.4991712258034667,
        "ingredients": [
          "2 lbs. boneless skinless chicken breasts",
          "1/4 cup masala paste",
          "1/2 cup plain yogurt",
          "2 cups tomato puree",
          "1 cup heavy cream",
          "2 cups rice",
          "1/4 cup cilantro",
          "2 onions",
          "5 cloves garlic",
          "a 2-inch piece of fresh ginger",
          "3 tablespoons garam masala",
          "1 tablespoon each chili powder, turmeric, and cumin",
          "1/2 teaspoon ground cloves",
          "2 teaspoons kosher salt",
          "1/2 teaspoon cayenne pepper",
          "a small pile of cilantro stems",
          "juice of one lemon"
        ],
        "directions": [
          "MASALA PASTE: Pulse all ingredients into a food processor until smooth. Set aside about 1/4 cup of the paste for this recipe – freeze the rest for your next batch! Boom!",
          "MARINATE CHICKEN: Cut the chicken into bite sized pieces. Marinate with 1-2 tablespoons of masala paste and the yogurt for about 30 minutes in the fridge.",
          "COOK RICE AND CHICKEN: Get your rice started cooking according to package directions. Then, heat a large, deep nonstick skillet over medium high heat. Heat 1-2 tablespoons of oil. Cook the chicken in batches, undisturbed, until each piece is nice and browned on the outside. Remove from pan and set aside. (Chicken does not need to be fully cooked at this point.)",
          "SAUCE: Add 2-3 tablespoons of masala paste to the same skillet. Stir fry for a few minutes. Add the tomato puree and browned chicken; simmer for 10-15 minutes. (Chicken should now be fully cooked.)",
          "FINISH: Stir in cream or coconut milk. Season with salt. Let stand for a while so the sauce will thicken up a bit. Serve over rice with cilantro. HEAVEN."
        ],
        "servings": "6",
        "cook_time": "15",
        "prep_time": "15",
        "total_time": "30",
        "image_link": "https://nutricloudrecipesimage-jadsiojerjakllkadueajlmdalk.s3.amazonaws.com/pinchofyum/chicken-tikka-masala?response-content-type=image%2Fjpeg&AWSAccessKeyId=AKIAXKZMLZKAMBX6JEUZ&Signature=Rx4wKTM4cuAWxBsb2d1mR8CbBAI%3D&Expires=1731533002"
      }
}
    `;

    const uriDescription = `The Recipe Search API enables you to find recipes based on your search queries and obtain comprehensive recipe details including ingredients, directions, cooking information, and optional nutritional data`;

    const requestBody: RequestBodyItem[] = [
        {
            name: "query",
            dataType: "string",
            required: true,
            defaultValue: "",
       description: "The search term for finding recipes. This could be an ingredient, dish name, or any keyword related to recipes.",
        },
        {
            name: "limit",
            dataType: "number",
            required: false,
            defaultValue: '2',
       description: "The maximum number of recipes to return.",
        },
        {
            name: "threshold",
            dataType: "number",
            required: false,
            defaultValue: '5',
       description: "The maximum Euclidean distance for similarity in search results. A lower value means more similar results.",
        },
        {
            name: "labels",
            dataType: "boolean",
            required: false,
            defaultValue: 'false',
       description: "Include labels such as meal type, cuisine, flavor profiles (sweet, spicy, umami, sour, etc.), and other generated labels. ",
        },
{
            name: "photo",
            dataType: "boolean",
            required: false,
            defaultValue: 'true',
       description: "Include a link to a photo of the recipe. ",
        },
        {
            name: "nutrients",
            dataType: "boolean",
            required: false,
            defaultValue: 'false',
       description: "Include nutritional information for the recipe. Returns macronutrients and micronutrients to the level of 'simplified_micronutrients' from the food search endpoint.",
        },

    ];

    return (
        <Documentation
            httpMethod={httpMethod}
            endpointName={endpointName}
            parameters={parameters}
            exampleResponse={exampleResponse}
            uri={uri}
            urlDescription={uriDescription}
            requestBody={requestBody}
        />
    );
}

const SearchApiOverview = () => {
    const { classes } = useStyles();
    const theme = useMantineTheme();
        const navigate = useNavigate();


    return (
        <div style={{margin: 40}}>
            <Text mt={80} fz={52} color={theme.colors.gray[2]}>Search</Text>
            <Text>Learn how to generate nutritional insights</Text>
            <Text mt={10} fz={'sm'} fw={400} color={theme.colors.gray[4]}>
              Related docs: {' '}
              <span
                onClick={() => navigate('/docs/search/food')}
                style={{ cursor: 'pointer',  color: theme.colors.orange[0] }}
              >
                Search Docs
              </span>
            </Text>
        </div>
    );
}

export function SearchAPIReference() {
    const { classes } = useStyles();
     const theme = useMantineTheme();

    return (
        <div>
            <ApiReferencePage children={
                <div>
                    <SearchApiOverview/>
                    <Divider ml={40} mr={40} color={theme.colors.gray[6]}></Divider>
                    <FoodSearch/>
                    <RecipeSearch/>
                </div>
            }
                              activeItem="search"
            />

        </div>
    );
}
