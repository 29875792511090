import React, {useState} from 'react';
import {
    Button, Col,
    createStyles, Grid,
    Input,
    Text,
    TextInput,
    useMantineTheme
} from '@mantine/core';
import ThreeDContainer from "../threeDContainer";
import {PythonIcon} from "../../../../componenets/icons/Python";
import {JavaIcon} from "../../../../componenets/icons/Java";
import {TsIcon} from "../../../../componenets/icons/TypeScript";
import {Prism} from "@mantine/prism";
import ScrollRevealGradientLine from "../coloredLine";
import {ChevronRight} from "react-feather";
import AnimatedLink from "../animatedLink";

// Create custom styles
const useStyles = createStyles((theme, { bloomColor }: { bloomColor: string }) => ({
    overallSection: {
        display: "flex",
        flexDirection: "column",
    },
    smallSection: {
        display: "flex",
        justifyContent: "left",
        alignItems: "center",
        flexDirection: "row",

    },
        textInput: {
        width: "100%",
        color: theme.colors.gray[1]

    },
        glowTitleText: {
        fontSize: '2rem',
        fontWeight: 500,
        maxWidth: 800,
        lineHeight: '1.1',
            textAlign: "left",
    },

    description: {
        fontSize: '1rem',
        marginTop: '20px',
        color: theme.colors.gray[5]
    },
    pipInstall: {
        marginLeft: "auto",
        // marginTop: "-20vh",
        // marginRight: "-10vw",
        width: '23vw',
        [`@media (min-width: 1000px)`]: {
            marginTop: "-20vh",
        },

    },

    bottomSection: {
        display: "flex",
        justifyContent: "left",
        flexDirection: "row",
        // height: "60vh",
        marginLeft: 30,
    },
    bloomEffect: {
      position: 'absolute', // Keep the bloom effect behind the icon
      backgroundColor: bloomColor,
      // borderRadius: '50%',
        marginLeft: "15vw",
        marginTop: "27vh",
      boxShadow: `0 0 190px 200px ${bloomColor}`, // Increase blur radius and spread for a more gradual effect
      opacity: 0.6, // Lower the opacity for a lighter effect
        zIndex: -1,
            [`@media (max-width: 767px)`]: {
      marginLeft: "40vw",
                boxShadow: `0 0 190px 230px ${bloomColor}`, // Increase blur radius and spread for a more gradual effect
    },
    },
}));

function SDKSection() {
    const bloomColor = '#D32122';
  const { classes } = useStyles({ bloomColor });
      const theme = useMantineTheme();

      const serviceColor = "#D32122"
    return (
        <div>
            {/*<ThreeDContainer bloomColor={'radial-gradient(circle, rgba(211,33,34,0.2) 0%, rgba(211,33,34,0) 70%)'}>*/}
            <div style={{padding: 20}}>
                <ThreeDContainer>
                    <img style={{width: "auto", height: "70vh"}}
                         src={`${process.env.PUBLIC_URL}/landingPage/sdkVSCodeExample_upscaled.png`}/>

                </ThreeDContainer>
            </div>

            <div className={classes.bottomSection}>
                <ScrollRevealGradientLine colorStart="rgb(211, 33, 34, 0)"
                                          colorMiddle="#D32122"
                                          colorEnd="#3fb950"
                                          leftContent={
                    <Grid p={20}>
                              {/*text*/}
                              <Col span={12} sm={6} md={7} p={50}>
                                  <Text><span style={{fontWeight: 800, fontSize: "26px"}}>NutriClouds SDKs</span> empower developers
                                      to enhance productivity by up
                                      to 50%, providing advanced,
                                      context-aware tools that
                                      simplify integration and
                                      elevate app functionalities
                                      across various
                                      platforms.</Text>
                                  <div style={{marginTop: 40,}}>
                                    <AnimatedLink text={"Explore NutriCloud SDKs"} path={"/"}/>
                                  </div>
                              </Col>
                              {/*  pip install*/}
                              <Col span={12} sm={6} md={5}
                                  className={classes.pipInstall}
                              >
                                  <div
                                      className={classes.bloomEffect}></div>
                                  <ThreeDContainer>
                                      <div style={{ padding: '2vh',}}>
                                          <Text
                                              className={classes.glowTitleText}>
                                        <span
                                            style={{color: serviceColor}}>Develop at light speed</span> with
                                              your favorite
                                              language</Text>
                                          <Text
                                              className={classes.description}>
                                              Build more with less
                                              effort. Our SDK
                                              offers powerful tools
                                              that
                                              save you
                                              hours of development
                                              time.
                                              Start
                                              building smarter
                                              today!
                                          </Text>

                                          {/*icons*/}
                                          <div style={{
                                              display: 'flex',
                                              flexDirection: 'column',
                                              alignItems: 'center',
                                              marginTop: 20,
                                              marginBottom: 20,
                                          }}>
                                              <div style={{
                                                  display: 'flex',
                                                  justifyContent: 'space-between',
                                                  width: '100%',
                                              }}>
                                                  <div style={{
                                                      flex: 1,
                                                      display: 'flex',
                                                      justifyContent: 'center'
                                                  }}> {/* Container for the first icon, centered */}
                                                      <PythonIcon
                                                          style={{height: "10vh"}}/>
                                                  </div>
                                                  <div style={{
                                                      flex: 1,
                                                      display: 'flex',
                                                      justifyContent: 'center'
                                                  }}> {/* Container for the second icon, centered */}
                                                      <JavaIcon
                                                          style={{height: "10vh"}}/>
                                                  </div>
                                              </div>
                                              <div style={{
                                                  display: 'flex',
                                                  justifyContent: 'center',
                                                  width: '100%'
                                              }}> {/* Container for the third icon, centered on its own line */}
                                                  <TsIcon
                                                      style={{height: "10vh"}}/>
                                              </div>
                                          </div>
                                          <Prism
                                              language={"" as any}>
                                              pip install nutricloud
                                          </Prism>
                                      </div>
                                  </ThreeDContainer>
                              </Col>
                    </Grid>
                }
                />
            </div>
        </div>
    );
}

export default SDKSection;
