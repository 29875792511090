import React, {useState} from 'react';
import {
    createStyles,
    rem,
    Slider,
    TextInput,
    Text,
    SegmentedControl,
    Button,
    useMantineTheme
} from '@mantine/core';
import { Select, SelectItem } from '@mantine/core';

const useStyles = createStyles((theme) => ({
    inputMargin: {
        marginBottom: '10px',
    },
    titleSize: {
        fontSize: '24x',
        '@media (max-height: 1000px)': {
            fontSize: '16px'
        },
    }
}));

const selectorStyles = createStyles((theme) => ({
    root: {
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.white,
        boxShadow: theme.shadows.md,
        border: `${rem(1)} solid ${
            theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[1]
        }`,
    },

    indicator: {
        backgroundImage: theme.fn.gradient({ from: 'pink', to: 'orange' }),
    },

    control: {
        border: '0 !important',
    },

    label: {
        '&, &:hover': {
            '&[data-active]': {
                color: theme.white,
            },
        },
    },
}));



interface RelationalSearchParametersProps {
    onParamChange: (param: string, value: any) => void;
}

const RecipeAutoCompleteParameters: React.FC<RelationalSearchParametersProps> = ({ onParamChange }) => {
    const theme = useMantineTheme();
    const { classes } = useStyles();

        const [searchType, setSearchType] = useState('prefix');


    const orange = "rgb(255, 140, 0)";

    return (

        <div>
            {/*search type*/}
            <div>
                <Text className={classes.titleSize}>Search Type</Text>
                <SegmentedControl
                    data={[
                        {label: 'Prefix', value: 'prefix'},
                        {label: 'Full Text', value: 'full_text'},
                        {label: 'Incremental', value: 'incremental'}
                    ]}
                    radius="xl"
                    size="xs"
                    fullWidth
                    onChange={(value) => {
                        setSearchType(value);
                        onParamChange('search_type', value);
                    }}
                    className={classes.inputMargin}
                />
            </div>

            {/*searchTerm*/}

            <Text className={classes.titleSize}>Query</Text>
            <TextInput
                placeholder="Chicken..."
                required
                onChange={(event) => onParamChange('query', event.target.value)}
                className={classes.inputMargin}
            />

            {/*limit*/}
            <div>
                <Text className={classes.titleSize}>Limit</Text>
                <Slider
                    defaultValue={10}
                    min={1}
                    max={20}
                    label={(value) => value.toFixed(0)}
                    onChangeEnd={(value) => onParamChange('limit', value)}
                    className={classes.inputMargin}
                />
            </div>


            {/* Conditionally display this slider */}
            {searchType === 'full_text' && (
                <div>
                    <Text className={classes.titleSize}>Fuzziness</Text>
                    <Slider
                        defaultValue={0}
                        min={0}
                        max={3}
                        label={(value) => value.toFixed(0)}
                        onChangeEnd={(value) => onParamChange('fuzziness', value)}
                        className={classes.inputMargin}
                    />
                </div>
            )}


        </div>
    );
};

export default RecipeAutoCompleteParameters;
