import React from 'react';
import { useNavigate } from 'react-router-dom';

interface LogoProps {
    size: number; // Size in pixels
}

const NutricloudLogo: React.FC<LogoProps> = ({ size }) => {
    const navigate = useNavigate();

    const handleLogoClick = () => {
        navigate('/'); // Navigate to homepage
    };

    // Construct the path to the SVG file in the public directoryfrontend/NutriCloudWebsite/public/logo/square-logo.png
    const svgPath = `${process.env.PUBLIC_URL}/logo/logo-orange-purple-small.svg`;

    return (
        <img
            src={svgPath}
            alt="NutriCloud Logo"
            style={{ width: size, cursor: 'pointer', height: 'auto' }} // Ensure the cursor indicates it's clickable
            onClick={handleLogoClick} // Handle click events
        />
    );
};

export default NutricloudLogo;
