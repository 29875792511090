import React from 'react';
import {
    Paper,
    Text,
    Flex,
    Container,
    createStyles,
    Col,
    Grid,
    Center, useMantineTheme, ScrollArea, Badge, Select, SegmentedControl, Code
} from '@mantine/core';
import { FaHome, FaUser, FaEnvelope, FaPhone, FaSearch, FaHeart, FaComment, FaBars } from 'react-icons/fa';
import {Link, useNavigate} from 'react-router-dom';
import { FaInfoCircle } from 'react-icons/fa';
import Demo from "../Components/Feedback";
import DocsPage from "../DocsPage";
import FeedbackBanner from "../Components/Feedback";
import {OverviewContentCard} from "../Components/leftRightCard";
import {Prism} from "@mantine/prism";
import AnimatedCardLink, {
} from "../Components/AnimatedBoxCardSimple";
import AnimatedLink from "../../LandingPage/components/animatedLink";
import {GradientCard} from "../Components/GradientCard";
import {useViewportSize} from "@mantine/hooks";
import {
    Activity, AlertCircle,
    BarChart2,
    ChevronRight,
    Eye,
    Layers,
    Lock,
    MessageCircle,
    Search, Terminal
} from "react-feather";


const useStyles = createStyles((theme) => ({
    iconBox: {
        display: 'flex',
        justifyContent: 'flex-start', // Align items to the start (left side)
        alignItems: 'center',
        marginBottom: theme.spacing.md,
        '& svg': {
            transition: 'transform 0.3s ease',
        },
        '&:hover svg': {
            transform: 'scale(1.3)',
            color: theme.colors.gray[0],
        },

    },
    contentBox: {
        marginLeft: theme.spacing.md, // Add some space between the icon and the text
    },
      animatedLink: {
          display: 'inline-flex',
          fontSize: '18px',
          alignItems: 'center',
          cursor: 'pointer',
          position: 'relative',
          textDecoration: 'none',
          color: theme.colorScheme === 'dark' ? theme.white : theme.black,
      },

    // headers
    pageTitle: {
        textAlign: "left",
        fontSize: theme.fontSizes.xxl,
        fontWeight:600,
        color: theme.colors.gray[3],
        marginBottom: "0px",
        marginTop: "100px",
    },
    mainHeader: {
        fontWeight: 500,
        fontSize: theme.fontSizes.xl,
        marginTop: "0px",
        marginBottom: "10px",
    },
    subHeader: {
        fontWeight: 500,
        fontSize: theme.fontSizes.lg,
        color: theme.colors.gray[5],
        // marginTop: "30px",
        marginBottom: "0px",
    },

    // bullet list
    link: {
        color: theme.colors.orange[0],
        textDecoration: 'none',
        '&:hover': {
            color: theme.colors.orange[9],  // Change this to the color you want on hover
        },
    },
    bulletList: {
        listStyleType: 'disc',
    },

    // text
    paragraphText: {
        fontSize: '16px',
        marginBottom: "10px",
        color: theme.colors.gray[3],
    },
        table: {
        width: '100%',
        borderCollapse: 'collapse',
    },
    cell: {
        padding: theme.spacing.sm,
        textAlign: 'left',
        borderBottom: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.gray[7] : theme.colors.gray[0]}`,
    },
        boldParagraphText: {
        fontSize: '16px',
        marginBottom: "10px",
        fontWeight: 900,
        color: theme.colors.gray[4]
    },

    customText: {
        fontSize: '16px',
        color: theme.colors.gray[6]
    },

    // info boxes
    infoBox: {
        backgroundColor: theme.colors.gray[8],
        padding: theme.spacing.md,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        width: '100%',
        marginTop: '10px',
        marginBottom: '20px',
    },
    infoText: {
        marginLeft: theme.spacing.sm,
        fontSize: '16px',
    },
    infoIcon: {
        fontSize: '1em',
        flexShrink: 0,
    },
coloredBox: {
    border: `0.5px solid ${theme.colors.gray[8]}`,
    backgroundColor: theme.colors.gray[9],
        '&:hover': {
      backgroundColor: theme.colors.dark[5],
    },
    borderRadius: '8px',
    padding: 20,
    flex: '1 1 30%',  // Add this line to make boxes take equal space
    // minWidth: '250px', // Add this to prevent boxes from getting too narrow
},

    meetTheAPICardContainer: {
        display: 'flex',
        gap: '20px',
        flexDirection: 'row',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        [`@media (max-width: 1000px)`]: {
            flexDirection: 'column',
        },
    },
}));


const unixCodeExamplesCurl: Record<string, string> = {
    Classify: `curl -X 'POST' \\
  'https://api.nutricloud.ai/api/classify/food/' \\
  -H 'accept: application/json' \\
  -H 'Authorization: api-key $NUTRICLOUD_API_KEY' \\
  -H 'Content-Type: application/json' \\
  -d '{
  "query": "Chicken Fettucini alfredo with broccoli and capers",
  "limit": 10,
  "classification_types": [
    "allergy",
             "texture",
             "flavor_profile",
             "cuisine"
  ]
}'`,
    Search: `curl -X 'POST' \\
  'https://api.nutricloud.ai/api/search/food/' \\
  -H 'accept: application/json' \\
  -H "Authorization: api-key $NUTRICLOUD_API_KEY" \\
  -H 'Content-Type: application/json' \\
  -d '{
  "queries": [
    "Salmon Filet",
    "Chicken Breast"
  ],
  "limit": 1,
  "threshold": 0,
  "data_source": "verified",
  "nutrients": "macronutrients"
}'`
};

const windowsCodeExamplesCurl: Record<string, string> = {
    Classify: `curl -X POST ^
  "https://api.nutricloud.ai/api/classify/food/" ^
  -H "accept: application/json" ^
  -H "Authorization: api-key %NUTRICLOUD_API_KEY%" ^
  -H "Content-Type: application/json" ^
  -d "{
  \\"query\\": \\"Chicken Fettucini alfredo with broccoli and capers\\",
  \\"limit\\": 10,
  \\"classification_types\\": [
    \\"allergy\\",
    \\"texture\\",
    \\"flavor_profile\\",
    \\"cuisine\\"
  ]
}"`,
    Search: `curl -X POST ^
  "https://api.nutricloud.ai/api/search/food/" ^
  -H "accept: application/json" ^
  -H "Authorization: api-key %NUTRICLOUD_API_KEY%" ^
  -H "Content-Type: application/json" ^
  -d "{
  \\"queries\\": [
    \\"Salmon Filet\\",
    \\"Chicken Breast\\"
  ],
  \\"limit\\": 1,
  \\"threshold\\": 0,
  \\"data_source\\": \\"verified\\",
  \\"nutrients\\": \\"macronutrients\\"
}"`
};


const codeExamplesPython: Record<string, string> = {
    Classify: `import os
import requests

api_key = os.getenv('NUTRICLOUD_API_KEY')
if not api_key:
    raise ValueError("NUTRICLOUD_API_KEY environment variable is not set")

def classify_food():
    url = 'https://api.nutricloud.ai/api/classify/food/'

    headers = {
        'accept': 'application/json',
        'Authorization': f'api-key {api_key}',
        'Content-Type': 'application/json'
    }

    data = {
        "query": "Chicken Fettucini alfredo with broccoli and capers",
        "limit": 10,
        "classification_types": [
            "allergy",
            "texture",
            "flavor_profile",
            "cuisine"
        ]
    }

    try:
        response = requests.post(url, headers=headers, json=data)
        response.raise_for_status()
        return response.json()
    except requests.exceptions.RequestException as e:
        print(f"Error: {e}")
        return None

if __name__ == "__main__":
    result = classify_food()
    print("Classification results:", result)`,
    Search: `import os
import requests

api_key = os.getenv('NUTRICLOUD_API_KEY')
if not api_key:
    raise ValueError("NUTRICLOUD_API_KEY environment variable is not set")

def search_food():
    url = 'https://api.nutricloud.ai/api/search/food/'

    headers = {
        'accept': 'application/json',
        'Authorization': f'api-key {api_key}',
        'Content-Type': 'application/json'
    }

    data = {
        "queries": [
            "Salmon Filet",
            "Chicken Breast"
        ],
        "limit": 1,
        "threshold": 0,
        "data_source": "verified",
        "nutrients": "macronutrients"
    }

    try:
        response = requests.post(url, headers=headers, json=data)
        response.raise_for_status()
        return response.json()
    except requests.exceptions.RequestException as e:
        print(f"Error: {e}")
        return None

if __name__ == "__main__":
    result = search_food()
    print("Search results:", result)`
};

const codeExamples: Record<string, string> = {
  Classify: `const apiKey = process.env.NUTRICLOUD_API_KEY;

if (!apiKey) {
  console.error('Error: NUTRICLOUD_API_KEY environment variable is not set');
  process.exit(1);
}

const requestData = {
  query: "Chicken Fettucini alfredo with broccoli and capers",
  limit: 10,
  classification_types: [
    "allergy",
    "texture",
    "flavor_profile",
    "cuisine"
  ]
};

async function classifyFood() {
  try {
    const response = await fetch('https://api.nutricloud.ai/api/classify/food/', {
      method: 'POST',
      headers: {
        'accept': 'application/json',
        'Authorization': \`api-key \${apiKey}\`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(requestData)
    });

    if (!response.ok) {
      throw new Error(\`HTTP error! status: \${response.status}\`);
    }

    const data = await response.json();
    console.log('Classification results:', data);
    return data;
  } catch (error) {
    console.error('Error:', error);
  }
}

classifyFood();`,

  Search: `const apiKey = process.env.NUTRICLOUD_API_KEY;

if (!apiKey) {
 console.error('Error: NUTRICLOUD_API_KEY environment variable is not set');
 process.exit(1);
}

const requestData = {
 queries: [
   "Salmon Filet",
   "Chicken Breast"
 ],
 limit: 1,
 threshold: 0,
 data_source: "verified",
 nutrients: "macronutrients"
};

async function searchFood() {
 try {
   const response = await fetch('https://api.nutricloud.ai/api/search/food/', {
     method: 'POST',
     headers: {
       'accept': 'application/json',
       'Authorization': \`api-key \${apiKey}\`,
       'Content-Type': 'application/json'
     },
     body: JSON.stringify(requestData)
   });

   if (!response.ok) {
     throw new Error(\`HTTP error! status: \${response.status}\`);
   }

   const data = await response.json();
   console.log('Search results:', data);
   return data;
 } catch (error) {
   console.error('Error:', error);
 }
}

searchFood();`
};


const nextStepBoxData = [
    { colors: ['#FF073A', '#FF7F56'], title: 'Food search', description: 'Search for foods and generate nutrients', logo: <Search size="1.1em" />, to: '/docs/search/food' },
    { colors: ['#FF073A', '#FF7F56'], title: 'Recipe search', description: 'Search recipes', logo: <Search size="1.1em" />, to: '/docs/search/recipe' },
    { colors: ['#0047AB', '#418FDE'], title: 'Classification', description: 'Get in-depth understandings of foods', logo: <Layers size="1.1em" /> , to: '/docs/classify'},
    { colors: ['#008000', '#32CD32'], title: 'Chat', description: 'Learn about generating text responses to natural language prompts', logo: <MessageCircle size="1.1em" /> , to: '/docs/chat'},
    { colors: ['#FF00FF', '#FF77FF'], title: 'Recognition', description: 'Generate nutrients with just a photo', logo: <Eye size="1.1em" /> , to: '/docs/recognition'},
    { colors: ['#FF4500', '#FFA07A'], title: 'Authentication', description: 'Guarantee secure access and best practices to our APIs', logo: <Lock size="1.1em" /> , to: '/docs/api-reference/authentication'},
    { colors: ['#D2691E', '#FFA500'], title: 'Rate limits', description: 'Learn how rate limiting is handled and find your limits', logo: <Activity size="1.1em" />, to: '/docs/guides/rate-limits' },
    { colors: ['#FF073A', '#FF7F56'], title: 'Error codes', description: 'Examine detailed guidance for error codes on the platform', logo: <AlertCircle size="1.1em" />, to: '/docs/guides/error-codes' },
    { colors: ['#0047AB', '#418FDE'], title: 'Debugging requests', description: 'Troubleshoot common problems and understand error responses', logo: <Terminal size="1.1em" /> , to: '/docs/api-reference/debugging-requests'},
];


function APIsDocs() {
    const {classes} = useStyles();
    const theme = useMantineTheme();
    const navigate = useNavigate();

    const [osType, setOsType] = React.useState<string>('macOS/Linux');
    const [selectedHTTPClient, setSelectedHTTPClient] = React.useState<string>('JavaScript');
    const [selectedExample, setSelectedExample] = React.useState<string>('Classify');

        const gradientBoxes = nextStepBoxData.map((box, index) => (
        <Link to={box.to} key={index} style={{ textDecoration: 'none', color: 'inherit' }}>
        <Flex className={classes.iconBox} key={index}>
            <Paper
                style={{
                    background: `linear-gradient(to right, ${theme.colors.gray[7]}, ${theme.colors.dark[3]})`,
                    padding: '.5em',
                    borderRadius: '6px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '2.4em',
                    height: '2.4em',
                    boxSizing: 'border-box',
                }}
            >
                {box.logo}
            </Paper>
            <Flex direction="column" className={classes.contentBox}>
                <div className={classes.animatedLink}>
                    <Text>{box.title}</Text>
                    <ChevronRight style={{marginLeft: theme.spacing.sm}}/>
                </div>

                <Text size="xs">{box.description}</Text>
            </Flex>
        </Flex>
        </Link>
        ));

    return (
        <Container>
            {/*title*/}
            <Text className={classes.pageTitle}>APIs</Text>
            <Text className={classes.subHeader}>Explore all of our APIs at a
                glance</Text>

            <Text mt={50} className={classes.mainHeader}>APIs overview</Text>
            <Text className={classes.paragraphText}>The NutriCloud API is powered by a
                diverse set of databases, AI, and ML models with different capabilities
                and price points.</Text>

            <table className={classes.table}>
                <thead>
                <tr>
                    <th className={`${classes.cell} ${classes.paragraphText}`}><span
                        className={classes.boldParagraphText}>API</span>
                    </th>
                    <th className={`${classes.cell} ${classes.paragraphText}`}><span
                        className={classes.boldParagraphText}>DESCRIPTION</span>
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td className={`${classes.cell} ${classes.paragraphText}`}>
                        <a href="#search-api"
                           style={{
                               textDecoration: 'none',
                               color: theme.colors.orange[0]
                           }}>
                            Search
                        </a>
                    </td>
                    <td className={`${classes.cell} ${classes.paragraphText}`}>
                        Get recipes and Generate nutritional information for text based
                        queries
                    </td>
                </tr>
                <tr>
                    <td className={`${classes.cell} ${classes.paragraphText}`}>
                        <a href="#classify-api"
                           style={{
                               textDecoration: 'none',
                               color: theme.colors.orange[0]
                           }}>
                            Classify
                        </a>
                    </td>
                    <td className={`${classes.cell} ${classes.paragraphText}`}>
                        Gain a insights into the flavors, textures, and nuanced
                        information of a food item
                    </td>
                </tr>
                <tr>
                    <td className={`${classes.cell} ${classes.paragraphText}`}>
                        <a href="#recognition-api"
                           style={{
                               textDecoration: 'none',
                               color: theme.colors.orange[0]
                           }}>
                            Recognition
                        </a>
                    </td>
                    <td className={`${classes.cell} ${classes.paragraphText}`}>
                        Query for nutritional information, ingredients, and directions
                        with an image
                    </td>
                </tr>
                <tr>
                    <td className={`${classes.cell} ${classes.paragraphText}`}>
                        <a href="#chat-api"
                           style={{
                               textDecoration: 'none',
                               color: theme.colors.orange[0]
                           }}>
                            Chat
                        </a>
                    </td>
                    <td className={`${classes.cell} ${classes.paragraphText}`}>
                        Talk to an LLM with access to call our entire suite of APIs
                    </td>
                </tr>
                <tr>
                    <td className={`${classes.cell} ${classes.paragraphText}`}>
                        <a href="#autocomplete-api"
                           style={{
                               textDecoration: 'none',
                               color: theme.colors.orange[0]
                           }}>
                            Autocomplete
                        </a>
                    </td>
                    <td className={`${classes.cell} ${classes.paragraphText}`}>
                        Give your food application suggestive powers in search bars
                    </td>
                </tr>
                <tr>
                    <td className={`${classes.cell} ${classes.paragraphText}`}>
                        <a href="#embeddings-api"
                           style={{
                               textDecoration: 'none',
                               color: theme.colors.orange[0]
                           }}>
                            Embeddings
                        </a>
                    </td>
                    <td className={`${classes.cell} ${classes.paragraphText}`}>
                        Use our embeddings to power your own models for advanced
                        semantic understanding
                    </td>
                </tr>
                <tr>
                    <td className={`${classes.cell} ${classes.paragraphText}`}>
                        <a href="#recommendation-api"
                           style={{
                               textDecoration: 'none',
                               color: theme.colors.orange[0]
                           }}>
                            Recommendation
                        </a>
                    </td>
                    <td className={`${classes.cell} ${classes.paragraphText}`}>
                        Get recipe recommendations for users based on their preferences
                    </td>
                </tr>
                <tr>
                    <td className={`${classes.cell} ${classes.paragraphText}`}>
                        <a href="#user-api"
                           style={{
                               textDecoration: 'none',
                               color: theme.colors.orange[0]
                           }}>
                            User
                        </a>
                    </td>
                    <td className={`${classes.cell} ${classes.paragraphText}`}>
                        Authentication, verification, and gain preference recommendation
                        for your app users
                    </td>
                </tr>
                <tr>
                    <td className={`${classes.cell} ${classes.paragraphText}`}>
                        <a href="#app-api"
                           style={{
                               textDecoration: 'none',
                               color: theme.colors.orange[0]
                           }}>
                            Apps
                        </a>
                    </td>
                    <td className={`${classes.cell} ${classes.paragraphText}`}>
                        Create groups of users for custom rate limits, permissions, and
                        usage tracking
                    </td>
                </tr>

                </tbody>
            </table>

            <section id="search-api">
                <Text pt={50} className={classes.mainHeader}>Search</Text>
                <Text className={classes.paragraphText}>The Search API offers two
                    distinct search capabilities: Food Search and Recipe Search.</Text>

                <Text mt={10} className={classes.subHeader}>Food Search</Text>
                <Text className={classes.paragraphText}>Find food items and retrieve
                    their nutritional information. Submit single or batch queries to get
                    detailed nutrient data, with flexible options for data quality and
                    detail level. You can request basic macronutrients or dive deep into
                    hundreds of micronutrients, sourced from verified laboratory
                    data.</Text>
                <Text className={classes.paragraphText}>
                <span
                    onClick={() => navigate('/docs/search/food')}
                    style={{cursor: 'pointer', color: theme.colors.orange[0]}}
                >See the food docs</span>
                </Text>
                <Text mt={20} className={classes.subHeader}>Recipe Search</Text>
                <Text className={classes.paragraphText}>Search through a vast database
                    of recipes to retrieve complete cooking instructions, ingredient
                    lists, and high-quality images. Each recipe result includes detailed
                    nutritional information calculated from its ingredients,
                    step-by-step preparation directions, and associated food
                    photography.</Text>
                <Text className={classes.paragraphText}>
                <span
                    onClick={() => navigate('/docs/search/recipe')}
                    style={{cursor: 'pointer', color: theme.colors.orange[0]}}
                >See the recipe docs</span>
                </Text>
            </section>


            <section id="classify-api">
                <Text pt={50} className={classes.mainHeader}>Classify</Text>
                <Text className={classes.paragraphText}>The Classification API analyzes
                    food items and recipes across multiple dimensions. It provides
                    detailed insights into dietary restrictions, allergens, flavor
                    profiles, and more. With support for both individual ingredients and
                    complete recipes, this API can classify foods across numerous
                    categories including diet compatibility, texture, seasonality, and
                    health metrics.</Text>

                <Text className={classes.paragraphText}>Key features include
                    comprehensive classification types ranging from allergies and diets
                    to flavor profiles and health indices, with the flexibility to
                    request only the specific classifications needed for your use
                    case.</Text>

                <Text className={classes.paragraphText}>
                <span
                    onClick={() => navigate('/docs/classify')}
                    style={{cursor: 'pointer', color: theme.colors.orange[0]}}
                >See the classify docs</span>
                </Text>
            </section>


            <section id="recognition-api">
                <Text pt={50} className={classes.mainHeader}>Recognition</Text>
                <Text className={classes.paragraphText}>The Recognition API transforms
                    food images into comprehensive recipe data. Upload an image of any
                    prepared dish, and our advanced AI will identify the meal, generate
                    an accurate name, and provide detailed nutritional information.
                    Optionally, the API can return complete recipe details including
                    ingredient lists and step-by-step cooking instructions. This
                    powerful visual recognition system supports both single-item foods
                    and complex prepared dishes, making it ideal for applications
                    ranging from personal nutrition tracking to recipe discovery.</Text>

                <Text className={classes.paragraphText}>
                <span
                    onClick={() => navigate('/docs/recognition')}
                    style={{cursor: 'pointer', color: theme.colors.orange[0]}}
                >See the recognition docs</span>
                </Text>
            </section>

            <section id="chat-api">
                <Text pt={50} className={classes.mainHeader}>Chat</Text>
                <Text className={classes.paragraphText}>The Chat API provides an
                    intelligent conversational interface powered by a specialized
                    food-focused LLM. What sets this API apart is its deep integration
                    with NutriCloud's entire ecosystem - the model can dynamically
                    access our APIs such as the Search, Classification, and Recognition
                    APIs during conversations. This means it can search nutritional
                    data, analyze recipes, classify foods, and interpret food images in
                    real-time while chatting.</Text>
                <Text className={classes.paragraphText}>Available in both streaming and
                    non-streaming modes, the API is perfect for creating sophisticated
                    food-related chatbots, virtual nutritionists, or interactive cooking
                    assistants that can provide accurate, data-backed responses by
                    leveraging our comprehensive food database and analysis
                    tools.</Text>

                <Text className={classes.paragraphText}>
                <span
                    onClick={() => navigate('/docs/chat')}
                    style={{cursor: 'pointer', color: theme.colors.orange[0]}}
                >See the chat docs</span>
                </Text>
            </section>

            <section id="autocomplete-api">
                <Text pt={50} className={classes.mainHeader}>Autocomplete</Text>
                <Text className={classes.paragraphText}>The Autocomplete API provides
                    real-time text suggestions optimized specifically for food-related
                    searches. As users type, the API returns intelligent completions
                    based on our extensive database of ingredients, recipes, dishes, and
                    cooking terms. The service is fine-tuned to understand common food
                    misspellings, ingredient variations, and regional food naming
                    differences, making it invaluable for improving search experiences
                    in food-related applications. With customizable response limits and
                    ultra-fast response times, this API helps create smooth, intuitive
                    search interfaces that guide users to their desired food content
                    quickly and efficiently.</Text>

                <Text className={classes.paragraphText}>
                <span
                    onClick={() => navigate('/docs/autocomplete')}
                    style={{cursor: 'pointer', color: theme.colors.orange[0]}}
                >See the autocomplete docs</span>
                </Text>
            </section>

            <section id="embeddings-api">
                <Text pt={50} className={classes.mainHeader}>Embeddings</Text>
                <Text className={classes.paragraphText}>Our Embeddings API provides
                    access to embeddings optimized for food-domain semantic
                    understanding. These vectors are designed to capture relationships
                    between ingredients, dishes, cooking methods, and nutritional
                    properties, enabling developers to build sophisticated food-focused
                    applications with semantic search and similarity matching
                    capabilities.</Text>

                <Text className={classes.paragraphText}>
                <span
                    onClick={() => navigate('/docs/embedding')}
                    style={{cursor: 'pointer', color: theme.colors.orange[0]}}
                >See the embeddings docs</span>
                </Text>
            </section>

            <section id="recommendation-api">
                <Text pt={50} className={classes.mainHeader}>Recommendation</Text>
                <Text className={classes.paragraphText}>Our Recommendation API delivers
                    personalized recipe suggestions based on a rich understanding of
                    user food preferences. The system analyzes multiple dimensions of
                    food preference including cuisine types, flavor profiles, ingredient
                    preferences, cooking complexity, nutritional goals, and several
                    other characteristics learned from user interactions. By processing
                    user likes, dislikes, and favorites, the API constructs a
                    comprehensive taste profile to surface highly relevant recipe
                    recommendations that align with individual preferences and dietary
                    patterns. Each recommendation comes complete with recipe details and
                    confidence scores for matching specific user preferences.</Text>

                <Text className={classes.paragraphText}>
                <span
                    onClick={() => navigate('/docs/recommendation')}
                    style={{cursor: 'pointer', color: theme.colors.orange[0]}}
                >See the recommendation docs</span>
                </Text>
            </section>

            <section id="user-api">
                <Text pt={50} className={classes.mainHeader}>User</Text>
                <Text className={classes.paragraphText}>Our User API handles all aspects
                    of user management and authentication while integrating seamlessly
                    with NutriCloud's food intelligence features. Developers can
                    register users, manage authentication, and control permissions and
                    rate limits on a per-user basis. The API handles secure token
                    generation, user verification, and delegated API access, allowing
                    end users to interact with NutriCloud services through your
                    application. What sets this apart from standard user management
                    systems is its tight integration with our food analytics - as users
                    interact with your application, their preferences and patterns
                    automatically enhance the personalization capabilities of our
                    Recommendation API.</Text>

                <Text className={classes.paragraphText}>
                <span
                    onClick={() => navigate('/docs/user')}
                    style={{cursor: 'pointer', color: theme.colors.orange[0]}}
                >See the user docs</span>
                </Text>
            </section>

            <section id="app-api">
                <Text pt={50} className={classes.mainHeader}>App</Text>
                <Text className={classes.paragraphText}>The App API enables developers to organize and manage their NutriCloud integration at scale. Create and manage user groups, define custom rate limits, and set granular permissions for different user segments or features within your application. Track detailed API usage metrics across user groups to optimize your implementation and control costs. The API provides the tools needed to structure complex applications with varying access levels, whether you're building a small cooking app or a large-scale food service platform with multiple user tiers.</Text>

                <Text className={classes.paragraphText}>
                <span
                    onClick={() => navigate('/docs/app')}
                    style={{cursor: 'pointer', color: theme.colors.orange[0]}}
                >See the app docs</span>
                </Text>
            </section>


            <FeedbackBanner/>
        </Container>
    );
}

export default function APIsDocsPage() {
    return (
        <div>
            <DocsPage children={<APIsDocs/>} activeItem={"apis"}/>
        </div>
    );
}
