import React from 'react';
import { TextInput, TextInputProps, ActionIcon, useMantineTheme } from '@mantine/core';
import { IconSearch, IconArrowRight, IconArrowLeft } from '@tabler/icons-react';
import { createStyles } from '@mantine/core';

const useStyles = createStyles((theme) => ({
    inputFullWidth: {
        width: '100%',
    },
}));

interface ExtendedTextInputProps extends TextInputProps {
    onEnter?: () => void;
}

export function InputWithButton(props: ExtendedTextInputProps) {
    const { onEnter, ...rest } = props;
    const theme = useMantineTheme();
    const { classes } = useStyles();

    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter' && onEnter) {
            onEnter();
        }
    };

    return (
        <TextInput
            className={classes.inputFullWidth}  // Apply the style here
            icon={<IconSearch size="1.1rem" stroke={1.5} />}
            radius="xl"
            style={{width: '100%'}}
            size="md"
            rightSection={
                <ActionIcon
                    size={32}
                    radius="xl"
                    color={theme.primaryColor}
                    variant="filled"
                    onClick={onEnter}
                >
                    {theme.dir === 'ltr' ? (
                        <IconArrowRight size="1.1rem" stroke={1.5} />
                    ) : (
                        <IconArrowLeft size="1.1rem" stroke={1.5} />
                    )}
                </ActionIcon>
            }
            placeholder="Search questions"
            rightSectionWidth={42}
            onKeyPress={handleKeyPress}
            {...rest}
        />
    );
}
