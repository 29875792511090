import React from 'react';
import { Paper, Text, Flex, Container, createStyles, Col, Grid } from '@mantine/core';
import { FaHome, FaUser, FaEnvelope, FaPhone, FaSearch, FaHeart, FaComment, FaBars } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { FaInfoCircle } from 'react-icons/fa';
import Demo from "../Components/Feedback";
import DocsPage from "../DocsPage";
import FeedbackBanner from "../Components/Feedback";


const useStyles = createStyles((theme) => ({
    iconBox: {
        display: 'flex',
        justifyContent: 'flex-start', // Align items to the start (left side)
        alignItems: 'center',
        marginBottom: theme.spacing.md,
        '& svg': {
            transition: 'transform 0.3s ease',
        },
        '&:hover svg': {
            transform: 'scale(1.2)',
        },
    },
    contentBox: {
        marginLeft: theme.spacing.md, // Add some space between the icon and the text
    },

    // headers
    pageTitle: {
        textAlign: "left",
        fontSize: theme.fontSizes.xxl,
        fontWeight:600,
        marginTop: "30px",
        marginBottom: "30px",
    },
    mainHeader: {
        fontWeight: 500,
        fontSize: theme.fontSizes.xl,
        marginTop: "0px",
        marginBottom: "10px",
    },
    subHeader: {
        fontWeight: 500,
        fontSize: theme.fontSizes.lg,
        marginTop: "30px",
        marginBottom: "0px",
    },

    // bullet list
    link: {
        color: theme.colors.orange[0],
        textDecoration: 'none',
        '&:hover': {
            color: theme.colors.orange[9],  // Change this to the color you want on hover
        },
    },
    bulletList: {
        listStyleType: 'disc',
    },

    // text
    paragraphText: {
        fontSize: '16px',
        marginBottom: "10px",
    },

    // info boxes
    infoBox: {
        backgroundColor: theme.colors.gray[8],
        padding: theme.spacing.md,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        width: '100%',
        marginTop: '10px',
        marginBottom: '20px',
    },
    infoText: {
        marginLeft: theme.spacing.sm,
        fontSize: '16px',
    },
    infoIcon: {
        fontSize: '1em',
        flexShrink: 0,
    },
}));

// create gradient boxes
const boxData = [
    { colors: ['#FF073A', '#FF7F56'], title: 'Home', description: 'Description 1', logo: <FaHome size="1.5em" />, to: '/examplePage' },
    { colors: ['#0047AB', '#418FDE'], title: 'User', description: 'Description 2', logo: <FaUser size="1.5em" /> , to: '/examplePage'},
    { colors: ['#008000', '#32CD32'], title: 'Mail', description: 'Description 3', logo: <FaEnvelope size="1.5em" /> , to: '/examplePage'},
    { colors: ['#FF00FF', '#FF77FF'], title: 'Phone', description: 'Description 4', logo: <FaPhone size="1.5em" /> , to: '/examplePage'},
    { colors: ['#FF4500', '#FFA07A'], title: 'Search', description: 'Description 5', logo: <FaSearch size="1.5em" /> , to: '/examplePage'},
    { colors: ['#D2691E', '#FFA500'], title: 'Menu', description: 'Description 8', logo: <FaBars size="1.5em" />, to: '/examplePage' },
    { colors: ['#FF073A', '#FF7F56'], title: 'Home', description: 'Description 1', logo: <FaHome size="1.5em" />, to: '/examplePage' },
    { colors: ['#0047AB', '#418FDE'], title: 'User', description: 'Description 2', logo: <FaUser size="1.5em" /> , to: '/examplePage'},
];


function DocsIntroduction() {
    const { classes } = useStyles();

    const gradientBoxes = boxData.map((box, index) => (
        <Link to={box.to} key={index} style={{ textDecoration: 'none', color: 'inherit' }}>
        <Flex className={classes.iconBox} key={index}>
            <Paper
                style={{
                    background: `linear-gradient(to top right, ${box.colors[0]}, ${box.colors[1]})`,
                    padding: '.5em',
                    borderRadius: '4px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '3em',
                    height: '3em',
                    boxSizing: 'border-box',
                }}
            >
                {box.logo}
            </Paper>
            <Flex direction="column" className={classes.contentBox}>
                <Text weight={500}>{box.title}</Text>
                <Text size="sm">{box.description}</Text>
            </Flex>
        </Flex>
        </Link>

    ));

    return (
        <Container>
            <Text className={classes.pageTitle}>Introduction</Text>

            <Flex direction="column" style={{ marginBottom: '20px' }}>
                <div id="Overview">
                    {/*overview.tsx*/}
                    <Text className={classes.mainHeader}>Overview</Text>
                    <Paper p="md" className={classes.infoBox}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <FaInfoCircle className={classes.infoIcon} />
                            <Text className={classes.infoText}>Looking for our Docs? Head to <Link to="/playground" className={classes.link}>NutriCloud.cloud/docs</Link></Text>
                        </div>
                    </Paper>
                    <Text className={classes.paragraphText}>The NutriCloud API can be applied to virtually any task that requires understanding or generating natural language and code. The NutriCloud API can also be used to generate and edit images or convert speech into text. We offer a range of models with different capabilities and price points, as well as the ability to fine-tune custom models.</Text>
                    <Text className={classes.paragraphText}>The NutriCloud API can be applied to virtually any task that requires understanding or generating natural language and code. The NutriCloud API can also be used to generate and edit images or convert speech into text. We offer a range of models with different capabilities and price points, as well as the ability to fine-tune custom models.</Text>
                </div>
            {/*    Resources*/}
                <Text className={classes.subHeader}>Resources</Text>
                <ul className={classes.bulletList}>
                    <li>
                        Experiment in the <Link to="/playground" className={classes.link}>playground</Link>
                    </li>
                    <li>
                        Read the <Link to="/api-reference" className={classes.link}>API reference</Link>
                    </li>
                    <li>
                        Visit the <Link to="/help-center" className={classes.link}>help center</Link>
                    </li>
                    <li>
                        View the current <Link to="/api-status" className={classes.link}>API status</Link>
                    </li>
                    <li>
                        Check out the <Link to="/developer-forum" className={classes.link}>NutriCloud Developer Forum</Link>
                    </li>
                    <li>
                        Learn about our <Link to="/usage-policies" className={classes.link}>usage policies</Link>
                    </li>
                </ul>


            {/*    key concepts*/}
                <div id="key-concepts">
                    <Text className={classes.mainHeader}>Key concepts</Text>
                    <Paper p="md" className={classes.infoBox}>
                        <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                            <FaInfoCircle className={classes.infoIcon} />
                            <Text className={classes.infoText}>At NutriCloud, protecting user data is fundamental to our mission. We do not train our models on inputs and outputs through our API. Learn more on our <Link to="/usage-policies" className={classes.link}>API data privacy page.</Link></Text>
                        </div>
                    </Paper>

                    <Text className={classes.subHeader}>Nutrient Profiles</Text>
                    <Text className={classes.paragraphText}>NutriCloud's GPT (generative pre-trained transformer) models have been trained to understand natural language and code. GPTs provide text outputs in response to their inputs. The inputs to GPTs are also referred to as "prompts". Designing a prompt is essentially how you “program” a GPT model, usually by providing instructions or some examples of how to successfully complete a task. GPTs can be used across a great variety of tasks including content or code generation, summarization, conversation, creative writing, and more. Read more in our introductory GPT guide and in our GPT best practices guide.</Text>

                    <Text className={classes.subHeader}>Relational search</Text>
                    <Text className={classes.paragraphText}>NutriCloud's GPT (generative pre-trained transformer) models have been trained to understand natural language and code. GPTs provide text outputs in response to their inputs. The inputs to GPTs are also referred to as "prompts". Designing a prompt is essentially how you “program” a GPT model, usually by providing instructions or some examples of how to successfully complete a task. GPTs can be used across a great variety of tasks including content or code generation, summarization, conversation, creative writing, and more. Read more in our introductory GPT guide and in our GPT best practices guide.</Text>

                    <Text className={classes.subHeader}>Auto complete</Text>
                    <Text className={classes.paragraphText}>NutriCloud's GPT (generative pre-trained transformer) models have been trained to understand natural language and code. GPTs provide text outputs in response to their inputs. The inputs to GPTs are also referred to as "prompts". Designing a prompt is essentially how you “program” a GPT model, usually by providing instructions or some examples of how to successfully complete a task. GPTs can be used across a great variety of tasks including content or code generation, summarization, conversation, creative writing, and more. Read more in our introductory GPT guide and in our GPT best practices guide.</Text>

                    <Text className={classes.subHeader}>Database search</Text>
                    <Text className={classes.paragraphText}>NutriCloud's GPT (generative pre-trained transformer) models have been trained to understand natural language and code. GPTs provide text outputs in response to their inputs. The inputs to GPTs are also referred to as "prompts". Designing a prompt is essentially how you “program” a GPT model, usually by providing instructions or some examples of how to successfully complete a task. GPTs can be used across a great variety of tasks including content or code generation, summarization, conversation, creative writing, and more. Read more in our introductory GPT guide and in our GPT best practices guide.</Text>

                    <Text className={classes.subHeader}>Tokens</Text>
                    <Text className={classes.paragraphText}>GPT and embeddings models process text in chunks called tokens. Tokens represent commonly occurring sequences of characters. For example, the string " tokenization" is decomposed as " token" and "ization", while a short and common word like " the" is represented as a single token. Note that in a sentence, the first token of each word typically starts with a space character. Check out our tokenizer tool to test specific strings and see how they are translated into tokens. As a rough rule of thumb, 1 token is approximately 4 characters or 0.75 words for English text.</Text>
                    <Text className={classes.paragraphText}>One limitation to keep in mind is that for a GPT model the prompt and the generated output combined must be no more than the model's maximum context length. For embeddings models (which do not output tokens), the input must be shorter than the model's maximum context length. The maximum context lengths for each GPT and embeddings model can be found in the model index.</Text>
                </div>
            </Flex>

            {/*Guides*/}
            <div id="Guides">
                <Flex direction="column" style={{ marginBottom: '20px' }}>
                    <Text className={classes.mainHeader}>Guides</Text>
                    <Text className={classes.paragraphText}>Jump into one of our guides to learn more.</Text>
                </Flex>

                <Flex direction="column">
                    <Flex direction="column">{gradientBoxes}</Flex>
                </Flex>
            </div>
            <FeedbackBanner/>
        </Container>
    );
}

export default function DocsIntroductionPage() {
    return (
        <div>
            <DocsPage children={<DocsIntroduction />} activeItem={"Introduction"} />
        </div>
    );
}
