import React, {useState} from 'react';
import {
    Paper,
    Text,
    Flex,
    Container,
    createStyles,
    Col,
    Grid,
    useMantineTheme
} from '@mantine/core';
import { FaHome, FaUser, FaEnvelope, FaPhone, FaSearch, FaHeart, FaComment, FaBars } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { FaInfoCircle } from 'react-icons/fa';
import Demo from "../Components/Feedback";
import DocsPage from "../DocsPage";
import RateLimitsAccordionTable from "../../Settings/limitsTable";
import {RateLimitSelector} from "../../Settings/components/rateLimitPicker";
import FeedbackBanner from "../Components/Feedback";


const useStyles = createStyles((theme) => ({
    iconBox: {
        display: 'flex',
        justifyContent: 'flex-start', // Align items to the start (left side)
        alignItems: 'center',
        marginBottom: theme.spacing.md,
        '& svg': {
            transition: 'transform 0.3s ease',
        },
        '&:hover svg': {
            transform: 'scale(1.2)',
        },
    },
    contentBox: {
        marginLeft: theme.spacing.md, // Add some space between the icon and the text
    },

    // headers
    pageTitle: {
        textAlign: "left",
        fontSize: theme.fontSizes.xxl,
        fontWeight:600,
        marginTop: "30px",
        marginBottom: "30px",
    },
    mainHeader: {
        fontWeight: 600,
        fontSize: theme.fontSizes.lg,
        color: theme.colors.gray[2],
        marginTop: "0px",
        marginBottom: "10px",
    },
    subHeader: {
        fontWeight: 500,
        fontSize: theme.fontSizes.md,
        color: theme.colors.gray[2],
        marginTop: "30px",
        marginBottom: "0px",
    },

    // bullet list
    link: {
        color: theme.colors.orange[0],
        textDecoration: 'none',
        '&:hover': {
            color: theme.colors.orange[9],  // Change this to the color you want on hover
        },
    },
    bulletList: {
        listStyleType: 'disc',
    },

    // text
    paragraphText: {
        fontSize: '16px',
        marginBottom: "10px",
    },

    boldParagraphText: {
        fontSize: '16px',
        marginBottom: "10px",
        fontWeight: 900,
        color: theme.colors.gray[4]
    },

    // info boxes
    infoBox: {
        backgroundColor: theme.colors.gray[8],
        padding: theme.spacing.md,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        width: '100%',
        marginTop: '10px',
        marginBottom: '20px',
    },
    infoText: {
        marginLeft: theme.spacing.sm,
        fontSize: '16px',
    },
    infoIcon: {
        fontSize: '1em',
        flexShrink: 0,
    },
    table: {
        width: '100%',
        borderCollapse: 'collapse',
    },
    cell: {
        padding: theme.spacing.sm,
        textAlign: 'left',
        borderBottom: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.gray[7] : theme.colors.gray[0]}`,
    },
    tableHeader: {
        borderBottomWidth: '2px',  // make the header line bolder
        fontWeight: 'bold',
    },
    lastRow: {
        borderBottom: 'none',  // remove the bottom border from the last row
    }
}));

// create gradient boxes
const boxData = [
    { colors: ['#FF073A', '#FF7F56'], title: 'Home', description: 'Description 1', logo: <FaHome size="1.5em" />, to: '/examplePage' },
    { colors: ['#0047AB', '#418FDE'], title: 'User', description: 'Description 2', logo: <FaUser size="1.5em" /> , to: '/examplePage'},
    { colors: ['#008000', '#32CD32'], title: 'Mail', description: 'Description 3', logo: <FaEnvelope size="1.5em" /> , to: '/examplePage'},
    { colors: ['#FF00FF', '#FF77FF'], title: 'Phone', description: 'Description 4', logo: <FaPhone size="1.5em" /> , to: '/examplePage'},
    { colors: ['#FF4500', '#FFA07A'], title: 'Search', description: 'Description 5', logo: <FaSearch size="1.5em" /> , to: '/examplePage'},
    { colors: ['#D2691E', '#FFA500'], title: 'Menu', description: 'Description 8', logo: <FaBars size="1.5em" />, to: '/examplePage' },
    { colors: ['#FF073A', '#FF7F56'], title: 'Home', description: 'Description 1', logo: <FaHome size="1.5em" />, to: '/examplePage' },
    { colors: ['#0047AB', '#418FDE'], title: 'User', description: 'Description 2', logo: <FaUser size="1.5em" /> , to: '/examplePage'},
];


function RateLimitsGuide() {
    const { classes } = useStyles();
    const theme = useMantineTheme();

    const [selectedRateLimitTier, setSelectedRateLimitTier] = useState('Free');

    const gradientBoxes = boxData.map((box, index) => (
        <Link to={box.to} key={index} style={{ textDecoration: 'none', color: 'inherit' }}>
        <Flex className={classes.iconBox} key={index}>
            <Paper
                style={{
                    background: `linear-gradient(to top right, ${box.colors[0]}, ${box.colors[1]})`,
                    padding: '.5em',
                    borderRadius: '4px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '3em',
                    height: '3em',
                    boxSizing: 'border-box',
                }}
            >
                {box.logo}
            </Paper>
            <Flex direction="column" className={classes.contentBox}>
                <Text weight={500}>{box.title}</Text>
                <Text size="sm">{box.description}</Text>
            </Flex>
        </Flex>
        </Link>

    ));

    return (
        <Container>
            {/*title*/}
            <Text className={classes.pageTitle}>Rate Limits</Text>
            <Text className={classes.paragraphText}>Rate limits are constraints placed
                on our API, limiting how many times a user or client can access our
                services within a given time frame.</Text>
            {/*    Why do we have rate limits*/}
            <Text className={classes.mainHeader}>Overview</Text>
            <ul className={classes.bulletList}>
                <li>
                    <Text className={classes.paragraphText}>
                        <span className={classes.boldParagraphText}>They help protect against abuse or misuse of the API.</span> For
                        instance, a malicious actor might flood the API with requests to
                        overload it or cause service disruptions. By implementing rate
                        limits, NutriCloud can prevent such activities.
                    </Text>
                </li>
                <li>
                    <Text className={classes.paragraphText}>
                        <span className={classes.boldParagraphText}>Rate limits help ensure that everyone has fair access to the API.</span> If
                        one person or organization makes an excessive number of
                        requests, it could slow down the API for others. By controlling
                        the number of requests a single user can make, NutriCloud
                        ensures that the maximum number of people can use the API
                        without experiencing slowdowns.
                    </Text>
                </li>
                <li>
                    <Text className={classes.paragraphText}>
                        <span className={classes.boldParagraphText}>Rate limits can help NutriCloud manage the aggregate load on its infrastructure.</span> If
                        there is a sudden surge in API requests, it could strain the
                        servers and cause performance issues. By establishing rate
                        limits, NutriCloud can maintain a smooth and consistent
                        experience for all users.
                    </Text>
                </li>
            </ul>
            <Paper p="md" className={classes.infoBox} mt={30}>
                <Grid>
                    <Grid.Col span={1}>
                        <FaInfoCircle className={classes.infoIcon}
                                      color={theme.colors.orange[1]}/>
                    </Grid.Col>
                    <Grid.Col span={11} ml={"-5%"}>
                        <Text className={classes.infoText}>Please review this entire
                            document to gain a comprehensive understanding of how
                            NutriCloud's rate limit system functions. Additionally,
                            information on how your rate limits are automatically
                            increased is provided in the usage tiers section
                            below.</Text>
                    </Grid.Col>
                </Grid>
            </Paper>
            {/*    How do these rate limits work*/}
            <Text className={classes.mainHeader}>How do these rate limits work?</Text>
            <Text className={classes.paragraphText}>API usage is restricted in five
                dimensions: requests per minute (RPM), requests per day (RPD), tokens
                per minute (TPM), tokens per day (TPD), and total requests. You may
                reach any of these limits independently, whichever comes first. For
                instance, if your RPM limit is 20, you could hit this ceiling by sending
                20 requests to the ChatCompletions endpoint, each containing only 100
                tokens. This would occur even if you haven't approached your TPM limit
                of 150,000 tokens within those 20 requests.</Text>
            <Text className={classes.paragraphText}>Other important things worth
                noting:</Text>
            <ul className={classes.bulletList}>
                <li>
                    <Text className={classes.paragraphText}>
                        API restrictions are set individually for developer accounts and
                        app users, rather than applying to entire organizations.
                    </Text>
                </li>
                <li>
                    <Text className={classes.paragraphText}>
                        Different APIs have their own specific rate limits.
                    </Text>
                </li>
                <li>
                    <Text className={classes.paragraphText}>
                        Monthly spending caps, also called "usage limits," are imposed
                        on organizations for their overall API consumption.
                    </Text>
                </li>
                <li>
                    <Text className={classes.paragraphText}>
                        Certain API groups share common rate limits. APIs listed under a
                        "shared limit" on your organization's limit page collectively
                        contribute to that limit. For instance, if the shared TPM is
                        3.5M, every call to any API within that shared group counts
                        towards the 3.5M total.
                    </Text>
                </li>
            </ul>

            {/*    Usage tiers*/}
            <Text className={classes.mainHeader} mt={30}>Usage tiers</Text>
            <Text className={classes.paragraphText}>To check your organization's rate
                and usage restrictions, visit the limits section in your account
                settings. As your NutriCloud API consumption and associated expenses
                increase, we automatically upgrade you to the next usage level. This
                upgrade typically leads to higher rate limits for most models.</Text>
            <table className={classes.table}>
                <thead>
                <tr>
                    <th className={`${classes.cell} ${classes.paragraphText}`}><span
                        className={classes.boldParagraphText}>TIER</span>
                    </th>
                    <th className={`${classes.cell} ${classes.paragraphText}`}><span
                        className={classes.boldParagraphText}>QUALIFICATION</span>
                    </th>
                    <th className={`${classes.cell} ${classes.paragraphText}`}><span
                        className={classes.boldParagraphText}>USAGE LIMITS</span>
                    </th>

                </tr>
                </thead>
                <tbody>
                <tr>
                    <td className={`${classes.cell} ${classes.paragraphText}`}>
                        Free
                    </td>
                    <td className={`${classes.cell} ${classes.paragraphText}`}>
                        User must be have verified the accounts email address
                    </td>
                    <td className={`${classes.cell} ${classes.paragraphText}`}>
                        $100 / month
                    </td>
                </tr>

                <tr>
                    <td className={`${classes.cell} ${classes.paragraphText}`}>
                        Tier 1
                    </td>
                    <td className={`${classes.cell} ${classes.paragraphText}`}>
                        $5 paid
                    </td>
                    <td className={`${classes.cell} ${classes.paragraphText}`}>
                        $100 / month
                    </td>
                </tr>
                <tr>
                    <td className={`${classes.cell} ${classes.paragraphText}`}>
                        Tier 2
                    </td>
                    <td className={`${classes.cell} ${classes.paragraphText}`}>
                        $50 paid and 7+ days since first successful payment
                    </td>
                    <td className={`${classes.cell} ${classes.paragraphText}`}>
                        $500 / month
                    </td>
                </tr>
                <tr>
                    <td className={`${classes.cell} ${classes.paragraphText}`}>
                        Tier 3
                    </td>
                    <td className={`${classes.cell} ${classes.paragraphText}`}>
                        $100 paid and 7+ days since first successful payment
                    </td>
                    <td className={`${classes.cell} ${classes.paragraphText}`}>
                        $1000 / month
                    </td>
                </tr>
                <tr>
                    <td className={`${classes.cell} ${classes.paragraphText}`}>
                        Tier 4
                    </td>
                    <td className={`${classes.cell} ${classes.paragraphText}`}>
                        $250 paid and 7+ days since first successful payment
                    </td>
                    <td className={`${classes.cell} ${classes.paragraphText}`}>
                        $5000 / month
                    </td>
                </tr>
                <tr className={classes.lastRow}>
                    <td className={`${classes.cell} ${classes.paragraphText}`}>
                        Tier 5
                    </td>
                    <td className={`${classes.cell} ${classes.paragraphText}`}>
                        $1000 paid and 7+ days since first successful payment
                    </td>
                    <td className={`${classes.cell} ${classes.paragraphText}`}>
                        $50000 / month
                    </td>
                </tr>

                </tbody>
            </table>

            <Text mt={30} mb={20} className={classes.paragraphText}>Select a tier below
                to view
                a high-level summary of rate limits per API.</Text>
            <RateLimitSelector selectedValue={selectedRateLimitTier}
                               setSelectedValue={setSelectedRateLimitTier}/>
            <Text className={classes.mainHeader} mt={20}>{selectedRateLimitTier} rate
                limits</Text>
            <Text className={classes.paragraphText}>This overview provides a general
                outline, but specific APIs may have different restrictions, such as
                older interfaces or those handling larger data sets. For precise
                API-specific limits applicable to your account, refer to the limits
                section in your account settings.</Text>

            <div>
                <RateLimitsAccordionTable rate_limit_tier={selectedRateLimitTier}/>
            </div>
            {/*    Free tier rate limits*/}

            {/*   error mitigation*/}
            <Text className={classes.mainHeader} mt={20}>Error Mitigation</Text>
            <Text className={classes.subHeader} mt={20}>How can I minimize the risk of
                API misuse?</Text>
            <Text mt={20} className={classes.paragraphText}>Exercise caution with
                advanced features:
                Be selective when granting access to programmatic interfaces, bulk
                processing capabilities, and automated social media posting. Consider
                reserving these functions for verified, trustworthy users only.</Text>
            <Text mt={15} className={classes.paragraphText}>Implement user-specific
                usage limits:
                Establish clear usage thresholds for individual users over defined
                periods (daily, weekly, or monthly). This helps prevent automated or
                high-volume misuse. When users approach or exceed these limits, consider
                enforcing a hard cap or initiating a manual review process.</Text>
            <Text mt={15} className={classes.paragraphText}>Monitor and analyze usage
                patterns:
                Regularly review API usage data to identify unusual patterns or spikes
                in activity. Implement an alert system that notifies you of potential
                misuse, such as sudden increases in request volume or attempts to
                circumvent rate limits. This proactive approach allows you to address
                issues quickly and refine your security measures as needed.</Text>

            {/*exponential backoff*/}
            <Text className={classes.subHeader} mt={20}>Implementing Exponential Backoff
                for Request Retries</Text>
            <Text mt={20} className={classes.paragraphText}>A simple yet effective strategy to
                handle rate limit errors is to employ automatic retries with random
                exponential backoff. This method involves:</Text>
            <ul className={classes.bulletList}>
                <li>
                    <Text className={classes.paragraphText}>
                        Pausing briefly when encountering a rate limit error
                    </Text>
                </li>
                <li>
                    <Text className={classes.paragraphText}>
                        Retrying the failed request
                    </Text>
                </li>
                <li>
                    <Text className={classes.paragraphText}>
                        If still unsuccessful, incrementally increasing the wait time between attempts
                    </Text>
                </li>
                <li>
                    <Text className={classes.paragraphText}>
                        Repeating this process until either success or a predefined retry limit is reached
                    </Text>
                </li>
            </ul>

            <Text className={classes.paragraphText}>Key advantages of this approach:</Text>
            <ul className={classes.bulletList}>
                <li>
                    <Text className={classes.paragraphText}>
                        <span className={classes.boldParagraphText}>Resilience:</span> Automatically recovering from rate limit errors prevents crashes and data loss
                    </Text>
                </li>
                <li>
                    <Text className={classes.paragraphText}>
                        <span className={classes.boldParagraphText}>Efficiency:</span> Quick initial retries with progressively longer delays for persistent failures

                    </Text>
                </li>
                <li>
                    <Text className={classes.paragraphText}>
                        <span className={classes.boldParagraphText}>Load distribution:</span> Adding random variation (jitter) to delay times prevents simultaneous retry attempts
                    </Text>
                </li>

            </ul>

            <Text className={classes.paragraphText}>Important caveat: Failed requests still count towards your per-minute limit, so rapid, continuous retries are counterproductive.</Text>

            <FeedbackBanner/>
        </Container>
    );
}

export default function RateLimitsGuidePage() {
    return (
        <div>
            <DocsPage children={<RateLimitsGuide/>}/>
        </div>
    );
}
