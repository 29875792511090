import React from 'react';
import {
    Paper,
    Text,
    Flex,
    Container,
    createStyles,
    Col,
    Grid,
    useMantineTheme, Accordion, Box, Center
} from '@mantine/core';
import { FaHome, FaUser, FaEnvelope, FaPhone, FaSearch, FaHeart, FaComment, FaBars } from 'react-icons/fa';
import {Link, useNavigate} from 'react-router-dom';
import { FaInfoCircle } from 'react-icons/fa';
import {Prism} from "@mantine/prism";
import FeedbackBanner from "../../Documentation/Components/Feedback";
import DocsPage from "../../Documentation/DocsPage";
import ApiReferencePage from "../ApiReferencePage";


const useStyles = createStyles((theme) => ({
    iconBox: {
        display: 'flex',
        justifyContent: 'flex-start', // Align items to the start (left side)
        alignItems: 'center',
        marginBottom: theme.spacing.md,
        '& svg': {
            transition: 'transform 0.3s ease',
        },
        '&:hover svg': {
            transform: 'scale(1.2)',
        },
    },
    contentBox: {
        marginLeft: theme.spacing.md, // Add some space between the icon and the text
    },

    // headers
    pageTitle: {
        textAlign: "left",
        fontSize: theme.fontSizes.xxl,
        fontWeight:600,
        marginTop: "30px",
        marginBottom: "0px",
    },
    mainHeader: {
        fontWeight: 500,
        fontSize: theme.fontSizes.xl,
        marginTop: "0px",
        marginBottom: "10px",
    },
    subHeader: {
        fontWeight: 500,
        color: theme.colors.gray[3],
        fontSize: theme.fontSizes.md,
        marginTop: "0px",
        marginBottom: "0px",
    },

    // bullet list
    link: {
        color: theme.colors.orange[0],
        textDecoration: 'none',
        '&:hover': {
            color: theme.colors.orange[9],  // Change this to the color you want on hover
        },
    },
    bulletTitle: {
        color: theme.colors.gray[0],
        fontWeight: 600,
    },
    bulletList: {
        listStyleType: 'disc',
    },
     numberedList: {
        listStyleType: 'decimal',
    },
    bulletPoint: {
        fontSize: theme.fontSizes.sm,
        margin: '5px 0',
    },
    // text
    paragraphText: {
        fontSize: '16px',
        marginBottom: "10px",
    },

    // info boxes
    infoBox: {
        backgroundColor: theme.colors.gray[8],
        padding: theme.spacing.md,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        width: '100%',
        marginTop: '10px',
        marginBottom: '20px',
    },
    infoText: {
        marginLeft: theme.spacing.sm,
        fontSize: '16px',
    },
    infoIcon: {
        fontSize: '1em',
        flexShrink: 0,
    },

              gradientBorderBox: {
    display: 'inline-flex',
        backgroundImage: theme.fn.gradient({ from: 'pink', to: 'orange' }),
    borderRadius: '5px',
    padding: '2px', // Controls the thickness of the gradient border
    alignItems: 'center',
           // marginLeft: "50px",
    justifyContent: 'center',
      width: '300px',

      '@media (max-width: 800px)': {
        marginLeft: 0,
    },

  },
  innerBox: {
    backgroundColor: theme.colors.dark[7], // Should match the page's background
    borderRadius: '5px',
      width: '300px',
    paddingLeft: "30px",
    paddingRight: "30px",
    paddingTop: "5x",
    paddingBottom: "0px",
      color: theme.colors.gray[1]
  },
            accordionSubHeader: {
        fontWeight: 500,

        color: theme.colors.gray[3],
        fontSize: theme.fontSizes.md,
        marginTop: "0px",
        marginBottom: "15px",
    },
}));

// create gradient boxes
function MakingRequestsAPIReference() {
    const { classes } = useStyles();
    const theme = useMantineTheme();
    const navigate = useNavigate();

    return (
        <Container>
            {/*title*/}
            <Text className={classes.pageTitle}>Making Requests</Text>

            <Text mt={50} className={classes.mainHeader}>Sending Your First Request</Text>

            <Text mb={30} className={classes.paragraphText}>You can run your
                first API request by pasting the command below into your terminal.
                Remember to replace {'<api_key_here>'} with your actual API key. This
                example demonstrates how to interact with the Classification API to
                classify a food item based on various attributes.</Text>

            <Prism language={"bash"}>
                {'curl -X POST \\\n' +
                    '  \'https://api.yourplatform.com/v1/classify/food/\' \\\n' +
                    '  -H \'Accept: application/json\' \\\n' +
                    '  -H \'Authorization: api-key: <key_here>\' \\\n' +
                    '  -H \'Content-Type: application/json\' \\\n' +
                    '  -d \'{\n' +
                    '    "query": "Chicken Fettuccine Alfredo with broccoli and capers",\n' +
                    '    "limit": 10,\n' +
                    '    "classification_types": [\n' +
                    '      "allergy",\n' +
                    '      "texture",\n' +
                    '      "flavor_profile"\n' +
                    '    ]\n' +
                    '  }\'\n'}
            </Prism>

            <Text mt={30} className={classes.paragraphText}>This request sends a food
                description to the /classify/food/ endpoint, asking the API to classify
                it based on allergies, textures, and flavor profiles.</Text>
            {/*understanding the request*/}
            <Text mt={50} className={classes.mainHeader}>Understanding the
                Request</Text>
            <ul className={classes.bulletList}>
                <li className={classes.bulletPoint}>
                    <strong>Endpoint:</strong> https://api.yourplatform.com/v1/classify/food/
                </li>
                <li className={classes.bulletPoint}>
                    <strong>Method:</strong> POST
                </li>
                <li className={classes.bulletPoint}>
                    <strong>Headers:</strong>
                    <ul className={classes.bulletList}>
                        <li className={classes.bulletPoint}>
                            <strong>Accept:</strong> application/json: Indicates that
                            the client expects a JSON response.
                        </li>
                        <li className={classes.bulletPoint}>
                            <strong>Authorization:</strong> api-key: &lt;key_here&gt;:
                            Includes your API key for authentication.
                        </li>
                        <li className={classes.bulletPoint}>
                            <strong>Content-Type:</strong> application/json: Specifies
                            that the request body is in JSON format.
                        </li>
                    </ul>
                </li>
                <li className={classes.bulletPoint}>
                        <strong>Request Body:</strong> (<span
                    onClick={() => navigate('/docs/classify')}
                    style={{cursor: 'pointer', color: theme.colors.orange[0]}}
                >
                see the docs for more details
              </span>)
                    <ul className={classes.bulletList}>
                        <li className={classes.bulletPoint}>
                            <strong>"query":</strong> The food description you want to
                            classify.
                        </li>
                        <li className={classes.bulletPoint}>
                            <strong>"limit":</strong> The maximum number of results to
                            return per classification type.
                        </li>
                        <li className={classes.bulletPoint}>
                            <strong>"classification_types":</strong> An array specifying
                            which classifications to perform.
                        </li>
                    </ul>
                </li>
            </ul>


            {/*sample response*/}
            <Text mt={50} className={classes.mainHeader}>Sample Response</Text>
            <Text mt={30} className={classes.paragraphText}>You should receive a response similar to the following:</Text>
            <Prism language={"bash"}>
                {'{\n' +
                    '  "allergy": [],\n' +
                    '  "texture": [\n' +
                    '    "creamy",\n' +
                    '    "tender",\n' +
                    '    "smooth",\n' +
                    '    "firm",\n' +
                    '    "crisp-tender"\n' +
                    '  ],\n' +
                    '  "flavor_profile": {\n' +
                    '    "sweet": 2,\n' +
                    '    "salty": 6,\n' +
                    '    "sour": 2,\n' +
                    '    "bitter": 1,\n' +
                    '    "umami": 7,\n' +
                    '    "spicy": 0\n' +
                    '  }\n' +
                    '}\n'}
            </Prism>

            <Text mt={30} className={classes.paragraphText}>Now that you've generated your first classfication, let's break down the response object. The response has three sections "allergy", "flavor_profile", and "flavor_profile" as requested by our example request. First, the allergy field shows whether any common allergens are present - in this case none were found. Next, the texture field includes various words that describe how the food feels when eaten. Finally, the flavor profile breaks down different taste characteristics, rating each one on a numerical scale from 0 to 10.</Text>

            {/*next steps*/}
            <Text mt={50} className={classes.mainHeader}>Next Steps</Text>

            <Text mt={20} className={classes.subHeader}>Modifying the Request</Text>
            <Text mt={10} className={classes.paragraphText}>Feel free to change the "query" parameter to classify different food items. You can also adjust the "classification_types" array to include or exclude certain classifications. For example, to classify only for caloric_density and allergies:</Text>
            <Prism language={"bash"}>
                {'"classification_types": [\n' +
                    '  "allergy",\n' +
                    '  "caloric_density"\n' +
                    ']\n'}
            </Prism>

            <Text mt={30} className={classes.subHeader}>Explore other APIs</Text>
            <Text mt={30} className={classes.paragraphText}>We encourage you to explore our diverse API endpoints to enhance your application. Our powerful Search API lets you retrieve detailed nutritional information for any food item, while our Recognition API can analyze food images to provide nutrient data instantly. These tools can help create richer, more engaging experiences for your users.</Text>
            <Text mt={30} className={classes.paragraphText}><span
                    onClick={() => navigate('/docs/overview')}
                    style={{cursor: 'pointer', color: theme.colors.orange[0]}}
                >
                see the docs to explore more APIs
              </span></Text>

            <FeedbackBanner/>
        </Container>
    );
}

export default function MakingRequestsAPIReferencePage() {
    return (
        <div>
            <ApiReferencePage children={<MakingRequestsAPIReference/>}
                              activeItem={"making-requests"}/>
        </div>
    );
}
