import React, { useState } from 'react';
import { createStyles, SegmentedControl, rem } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import {useUsage} from "./usageContext";

const useStyles = createStyles((theme) => ({
    root: {
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.white,
        boxShadow: theme.shadows.md,
        border: `${rem(1)} solid ${
            theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[1]
        }`,
    },

    indicator: {
        backgroundImage: theme.fn.gradient({ from: 'pink', to: 'orange' }),
    },

    control: {
        border: '0 !important',
    },

    label: {
        '&, &:hover': {
            '&[data-active]': {
                color: theme.white,
            },
        },
    },
}));

interface ApiSelectorProps {
}

export const UsageSelector: React.FC<ApiSelectorProps> = () => {
    const { classes } = useStyles();

        const {
    selectedUsageTab,
            setSelectedUsageTab
  } = useUsage();

    const handleChange = (value: string) => {
        setSelectedUsageTab(value);
    };

    return (
        <SegmentedControl
            radius="sm"
            size="sm"
            data={['Cost', 'Activity']}
            classNames={classes}
            value={selectedUsageTab}
            onChange={handleChange}
            style={{ marginBottom: '20px', marginLeft: '20px', marginTop: '0px'}}
        />
    );
};
