import React from 'react';
import {
    Paper,
    Text,
    Col,
    Grid,
    Image,
    Container,
    Center,
    useMantineTheme, Flex, Button, Box
} from '@mantine/core';
import { createStyles } from '@mantine/styles';
import {ChevronRight} from "react-feather";
import AnimatedLink from "../LandingPage/components/animatedLink";

const useStyles = createStyles((theme) => ({
    customHeader: {
        fontSize: 32,
        fontWeight: 500,
        [`@media (max-width: 500px)`]: {
            fontSize: 24,
        }
    },
    card: {
        borderRadius: 50,
        position: 'relative',
        zIndex: 1,
        backgroundColor: theme.colors.gray[9],
        padding: 40,

    },
    journeyCard: {
                borderRadius: 50,
        position: 'relative',
        zIndex: 1,
        backgroundColor: theme.colors.gray[9],
        padding: 40,
        [`@media (max-width: 767px)`]: {
            marginTop: 40,
        },    },

    pictureCard: {
        borderRadius: 50,
        zIndex: 1,
        backgroundColor: theme.colors.gray[9],
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',

        height: 400,
        [`@media (max-width: 1200px)`]: {
            height: 300,
        },
        [`@media (max-width: 767px)`]: {
            height: 350,
            marginTop: 40,
        },
        // [`@media (max-width: 500px)`]: {
        //     height: 200,
        // },
    },

      imageWithGradient: {
        width: '100vw',
          // marginLeft: "-20vw",
    backgroundSize: 'cover',
    backgroundPosition: 'center',
backgroundImage: `linear-gradient(to bottom, ${theme.colors.dark[7]} 15%, rgba(255,255,255,0) 60%, ${theme.colors.dark[7]} 100%), url(${process.env.PUBLIC_URL}/aboutPage/gradient-background.png)`,
  },


    mainTitle: {
        textAlign: "center",
        fontSize: "7rem",
        [`@media (max-width: 1300px)`]: {
      fontSize: "5rem",
    },
      [`@media (max-width: 750px)`]: {
      fontSize: "2.5rem",
    },
    },

    mainSubTitle: {
        textAlign: "center",
        [`@media (max-width: 675px)`]: {
      fontSize: "1rem",
            marginLeft: 50,
            marginRight: 50
    },
    },

    getStartedButton: {
        fontSize: "2rem",
        width: "fit-content",
        height: "fit-content",
        maxWidth: "90%",
        [`@media (max-width: 1300px)`]: {
      fontSize: "1.5rem",
    },
      [`@media (max-width: 750px)`]: {
      fontSize: "1.3rem",
    },
        [`@media (max-width: 500px)`]: {
      fontSize: "1.3rem",
    },

    },

    paragraphText: {
        fontSize: 20,
        marginLeft: 10,
        marginRight: -10,
        marginTop: 20,
        [`@media (max-width: 500px)`]: {
        fontSize: 16,
        }
    },

    marketFaster: {
        fontSize: 54,
        [`@media (max-width: 500px)`]: {
      fontSize: 44,
    },
    },


}));

const AboutPage: React.FC = () => {
    const { classes } = useStyles();
    const theme = useMantineTheme();

      const bloomStyle = {
        boxShadow: '0 0 60px 30px rgba(255, 255, 255, 0.5)', // Adjust color and spread for the desired effect
        borderRadius: 100,
      };

    return (
        <div style={{
            // margin: 'auto',
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
        }}>
            {/*title*/}
            <Flex mt={'5rem'} direction={"column"} justify={"center"} align={"center"} style={{maxWidth: 1200}} >
                <Text className={classes.mainTitle} fw={500} style={{zIndex: 2}}>About NutriCloud</Text>
                <Text className={classes.mainSubTitle} style={{zIndex: 2}}>The leading AI powered food oriented development platform</Text>
                <Button className={classes.getStartedButton} style={{zIndex: 2 }}  mt={50} radius={'xs'} bg={theme.colors.orange[1]}>
                    <Text >Get started with NutriCloud</Text>
                    <ChevronRight/>
                </Button>
            </Flex>

            {/*big box with gradient circle behind it*/}

            <Flex p={10} justify="center" mt={100} style={{ position: 'relative', maxWidth: 1200 }}>
              <Flex
                bg={theme.colors.gray[9]}
                // w="70%"
                  p={40}
                style={{ borderRadius: 100, position: 'relative', zIndex: 1, boxShadow: '0 0 900px 70px rgba(255, 165, 0, 0.5)' }}
                direction={"column"}
              >
                  <Grid>
                  {/*left section*/}
                      <Col span={12} md={6} >
                        <Text className={classes.customHeader}>Our <span style={{color: theme.colors.orange[1]}}>Vision</span></Text>
                        <Text className={classes.paragraphText}>In a world where technology and culinary arts intersect, there lies an untapped potential to enhance the way we experience and interact with food. NutriCloud emerged from this very idea - a vision to make cutting-edge food technology accessible to developers and businesses of all sizes. We believe that by providing powerful, AI-driven tools and resources, we can foster a community of innovation that contributes to healthier, more sustainable, and enjoyable food experiences for everyone.</Text>
                      </Col>
                      {/*right section*/}
                      <Col span={12} md={6} >
                        <Text className={classes.customHeader}><span style={{color: theme.colors.orange[1]}}>Developer-Centric</span> Approach</Text>
                          <Text className={classes.paragraphText}>At NutriCloud, we understand the challenges developers face when building food-related applications. That's why we have designed our APIs to be intuitive, well-documented, and easy to integrate into your existing projects. Whether you're working on a nutrition tracking app, a recipe recommendation system, or a food delivery service, NutriCloud has the solutions you need to bring your ideas to life.</Text>
                      </Col>
                  </Grid>
                  <div style={{paddingTop: 80}}>
                    <AnimatedLink text={"Explore our APIs"} path={"/docs"}/>
                  </div>
                      {/* Content inside the inner <Flex> */}
              </Flex>
            </Flex>
            {/*Solid dark gray background section with some boxes (describe myself)*/}
            <Flex
                className={classes.imageWithGradient}
                direction={"column"}
                align={"center"}
                justify={"center"}
                mt={100}
            >
                <Grid m={20} mt={100} style={{maxWidth: 1200}}>
                    {/* Our founder text */}
                    <Col  span={12} sm={7} mr={"5vw"} className={classes.card} > {/* Adjusted md to 5 */}
                        <Text className={classes.customHeader}>Our <span style={{color: theme.colors.orange[1]}}>Founder</span></Text>
                        <Text className={classes.paragraphText}>
                        Founded by William Bohm, a software engineer with a vision for transforming the food tech industry, NutriCloud is committed to providing developers with the tools they need to succeed. With our extensive knowledge in both food science and AI technology, we have created a platform that offers unparalleled accuracy, reliability, and performance.
                        </Text>
                    </Col>

                    {/* Our founder photo */}
                    <Col span={12} sm={4} className={classes.pictureCard} style={{
                        // position: 'relative'
                    }}> {/* Adjusted md to 7 and added positioning */}
                        <img style={{
                                padding: 20,
                                // position: 'absolute',
                                // height: "50%",
                            height: "100%",
                                right: '10%', // Adjust for overlap positioning
                                top: '0px' // Adjust for overlap positioning
                            }} src={`${process.env.PUBLIC_URL}/aboutPage/double.png`} alt="Founder Headshot"/>
                    </Col>
                    <Col span={12} sm={12} mb={40} mt={40} className={classes.card} m={0}>
                        <Text className={classes.customHeader}><span style={{color: theme.colors.orange[1]}}>Cutting-Edge</span> Technology</Text>
                        <Text className={classes.paragraphText}>Our APIs leverage the latest advancements in artificial intelligence and machine learning to provide accurate, up-to-date information on a wide range of food-related topics. From nutritional data and ingredient analysis to recipe suggestions and meal planning, NutriCloud offers a comprehensive suite of tools to help you create exceptional user experiences.</Text>
                    </Col>
                    <Col span={12} sm={5} className={classes.card} > {/* Adjusted md to 5 */}
                        <Text className={classes.customHeader}>Continuous <span style={{color: theme.colors.orange[1]}}>Improvement</span></Text>
                        <Text className={classes.paragraphText}>
                            As a developer-first company, we prioritize the needs of our users and are constantly working to improve and expand our offerings. We value your feedback and are always eager to hear your ideas for new features and enhancements.                        </Text>
                    </Col>

                    {/* Our founder photo */}
                    <Col span={12} sm={7} className={classes.journeyCard} style={{ position: 'relative'}}> {/* Adjusted md to 7 and added positioning */}
                        <Text className={classes.customHeader}>Join Us on Our <span style={{color: theme.colors.orange[1]}}>Journey</span></Text>
                        <Text className={classes.paragraphText}>
                        We're just getting started, and we invite you to join us on this exciting journey. Whether you're a developer looking to build the next big food app, a business seeking to innovate your services, or someone passionate about the future of food technology, there's a place for you at NutriCloud.
                        </Text>
                        <Text className={classes.paragraphText}>
                            Together, let's redefine the future of food.
                        </Text>
                    </Col>
                </Grid>

                <Flex justify={"center"} mb={150}>
                    <Flex direction={"column"} align={"center"} justify={"center"} >
                        <Text style={{textAlign: 'center'}} className={classes.marketFaster} fw={600} mt={100} mb={30}>Bringing app's to market <span style={{color: theme.colors.orange[1]}}>faster</span></Text>
                        <Grid m={20} mt={100} style={{maxWidth: 1200}} justify={'center'}>
                            <Col className={classes.card} span={12} md={10} lg={7} xl={6} mb={40} mr={20}>
                                <Text className={classes.customHeader}>Get to Market <span style={{color: theme.colors.orange[1]}}>Faster</span></Text>
                                <Text  className={classes.paragraphText}>In the competitive world of food tech, getting your product to market quickly is essential. NutriCloud's APIs are designed to help you launch your food app in record time. By leveraging our powerful tools and extensive food database, you can streamline your development process and get your product in front of users faster. With NutriCloud, you can focus on what matters most: delivering exceptional value to your customers.</Text>
                                <div style={{marginTop: 50}}>
                                    <AnimatedLink text={"Learn more"} path={"/docs"}/>
                                </div>
                            </Col>
                            <Col className={classes.card} span={12} md={10} lg={7} xl={5} mb={40}>
                                <Text className={classes.customHeader}><span style={{color: theme.colors.orange[1]}}>Accelerate</span> Your Development</Text>
                                <Text  className={classes.paragraphText}>NutriCloud's APIs are designed to accelerate your food app development process. Our well-documented, easy-to-integrate APIs allow you to focus on creating unique features and user experiences, while we handle the complex food data and analytics behind the scenes. With NutriCloud, you can bring your ideas to life faster than ever before.</Text>
                            </Col>
                            <Col className={classes.card} span={12} md={10} lg={7}>
                                <Text className={classes.customHeader}><span style={{color: theme.colors.orange[1]}}>Simplify</span> Complex Food Data</Text>
                                <Text  className={classes.paragraphText}>Integrating comprehensive food data into your applications can be a daunting task. NutriCloud simplifies this process by providing a single, unified API that covers a wide range of food-related information. From nutritional data to ingredient analysis and recipe suggestions, our API makes it easy to incorporate complex food data into your apps without the hassle of managing multiple data sources.</Text>
                            </Col>

                        </Grid>
                    </Flex>
                </Flex>
            </Flex>
        </div>
    );
};

export default AboutPage;
