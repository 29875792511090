interface Params {
    [key: string]: any;
}

const getRubyCommand = (url: string, parameters: Params, currentParameters: Params): string => {
    let command = `require 'net/http'\nrequire 'uri'\nrequire 'json'\n\nuri = URI.parse("${url}")\n\nheader = {'Content-Type': 'application/json', 'api-key': 'YOUR_NUTRICLOUD_API_KEY'}\n\n`;

    let data = {...parameters};
    for(let param in data) {
        if(data[param] === currentParameters[param]) {
            delete data[param];
        }
    }

    if(Object.keys(data).length > 0) {
        let dataString = JSON.stringify(data, null, 2)
            .replace(/"/g, ":")
            .replace(/,/g, ",");
        command += `data = {\n${dataString}\n}\n\n`;
    }

    command += `http = Net::HTTP.new(uri.host, uri.port)\nrequest = Net::HTTP::Post.new(uri.request_uri, header)\nrequest.body = data.to_json\n\nresponse = http.request(request)\n\nputs response.body`;

    return command;
};

export default getRubyCommand;
