import React, { createContext, useState, useContext, ReactNode, useEffect } from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
// import {useApiClient} from "./apiClient";
import axios from "axios";
import axiosInstance from "./axiosInstance";

interface AuthContextType {
    isAuthenticated: boolean;
    setIsAuthenticated: (isAuthenticated: boolean) => void;
    isEmailVerified: boolean;
    email: string;
    isPhoneVerified: boolean;
    setIsPhoneVerified: (isPhoneVerified: boolean) => void;
    phone: string;
    name: string;
    isLoading: boolean;
    accessToken: string;
    checkAuthentication: () => Promise<string>;
    getUpdatedUserData: () => Promise<string>;
    login: (email: string, password: string) => Promise<string>;
    logout: () => Promise<void>;
    register: (email: string, password: string) => Promise<string>;
    sendEmailVerification: () => Promise<string>;
    verifyEmailLink: (token: string) => Promise<string>;
    setName: (name: string) => void;
    setPhone: (phone: string) => void;
    apiClient: typeof axiosInstance;
}

const AuthContext = createContext<AuthContextType | null>(null);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(true);
    const [isEmailVerified, setIsEmailVerified] = useState<boolean>(true);
    const [isPhoneVerified, setIsPhoneVerified] = useState<boolean>(true);
    const [phone, setPhone] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [name, setName] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const tempToken = localStorage.getItem('accessToken');
    const [accessToken, setAccessToken] = useState<string>(tempToken ? tempToken : '');
    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || '/';

    // const apiClient = useApiClient();

    useEffect(() => {
        const storedToken = localStorage.getItem('accessToken');
        if (storedToken) {
            setAccessToken(storedToken);
            checkAuthentication(storedToken);  // Pass the token to check authentication
        } else {
            setIsAuthenticated(false);
            setIsEmailVerified(false);
            setIsPhoneVerified(false);
        }
        const name = sessionStorage.getItem('name');
        if (name) {setName(name);}
        const phone = sessionStorage.getItem('phone');
        if (phone) {setPhone(phone);}
        const email = sessionStorage.getItem('email');
        if (email) {setEmail(email);}
        const isEmailVerified = sessionStorage.getItem('isEmailVerified');
        if (isEmailVerified) {setIsEmailVerified(isEmailVerified === 'true');}
        const isPhoneVerified = sessionStorage.getItem('isPhoneVerified');
        if (isPhoneVerified) {setIsPhoneVerified(isPhoneVerified === 'true');}
    }, []);

    const register = async (email: string, password: string): Promise<string> => {
        const formData = {
            email: email,
            password: password,
        };

        try {
            const baseURL = process.env.REACT_APP_BASE_URL;
            const response = await apiClient.post(`${baseURL}/api/auth/register/dev`, formData, {
                headers: {
                    'accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            });

            if (response.status === 201) {
                // let loginResponse = await login(email, password);
                // if (loginResponse !== "success") {
                //     return loginResponse;
                // }
                // setIsAuthenticated(true);
                // setEmail(email);
                return "success";
            } else {
                return response.data.message || "Registration failed.";
            }
        } catch (error) {
            console.error('Registration error', error);
            return 'Registration failed. Please try again.';
        }
    };

    const logout = async () => {
        try {
            setIsAuthenticated(false);
            setAccessToken('');
            localStorage.removeItem('accessToken');
            setIsEmailVerified(false);
            setEmail('');
            sessionStorage.removeItem('email');
            setIsPhoneVerified(false);
            sessionStorage.removeItem('isPhoneVerified');
            setIsEmailVerified(false);
            sessionStorage.removeItem('isEmailVerified');
            setPhone('');
            sessionStorage.removeItem('phone');
            setName('');
            sessionStorage.removeItem('name');

            navigate('/');
        } catch (error) {
            console.error('Logout failed', error);
        }
    };

    const login = async (email: string, password: string): Promise<string> => {
        const restrictedPaths = [
                  '/login',
                  '/signup',
                  '/',
                  '/forgot-password',
                  '/verify/email',
                  ''
                ];

        const defaultPath = '/account/profile';

        // if (isAuthenticated) {
        //     console.log('Already authenticated');
        //     if (typeof from === 'string' || !from) {
        //           navigate(defaultPath, { replace: true });
        //         } else if (restrictedPaths.includes(from.pathname)) {
        //           navigate(defaultPath, { replace: true });
        //         } else {
        //           navigate(from, { replace: true });
        //         }
        //     return 'success';
        // }

        const jsonData = {
            email: email,
            password: password,
        };

        try {
            setIsLoading(true);
            const baseURL = process.env.REACT_APP_BASE_URL;
            const response = await apiClient.post(`${baseURL}/api/auth/login`, jsonData, {
                headers: {
                    'accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                withCredentials: true
            });

            if (response.status === 200) {
                setAccessToken(response.data.access_token);
                localStorage.setItem('accessToken', response.data.access_token);
                setIsAuthenticated(true);
                const checkAuthResponse = await checkAuthentication(response.data.access_token);
                if (checkAuthResponse !== "success") {
                    return 'Authentication failed. Please check your password and try again.'
                }


                if (typeof from === 'string' || !from) {
                  navigate(defaultPath, { replace: true });
                } else if (restrictedPaths.includes(from.pathname)) {
                  navigate(defaultPath, { replace: true });
                } else {
                  navigate(from, { replace: true });
                }
                return "success";
            } else {
                return "Login failed. Please check your credentials.";
            }
        } catch (error) {
            return 'Authentication failed. Please check your password and try again.';
        } finally {
            setIsLoading(false);
        }
    };

    const sendEmailVerification = async () => {
        const baseURL = process.env.REACT_APP_BASE_URL;
            const response = await apiClient.post(`${baseURL}/api/verify/email`, {}, {
                headers: {
                    'accept': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                },
                withCredentials: true
            });
            if (response.status === 200) {
                return "success";
            } else {
                return "Failed to send email verification.";
            }
    };

    const verifyEmailLink = async (token: string): Promise<string> => {
        if (isEmailVerified) {
            return 'success';
        }
        try {
            const baseURL = process.env.REACT_APP_BASE_URL;
            const response = await apiClient.get(`${baseURL}/api/auth/verify/email?token=` + token, {
                headers: {
                    'accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                },
                withCredentials: true
            });

            if (response.status === 404) {
                return 'Login link Expired'; // Set the error message
            }

            if (response.status === 200) {
                setIsEmailVerified(true);

                navigate('/login');
                return 'success';
            } else {
                return 'Failed to verify email.';
            }
        } catch (error) {
            console.error('Login error', error);
            return 'Login link has expired.'; // Set the error message
        }
    };

    const getUpdatedUserData = async (token: string = accessToken || localStorage.getItem('accessToken') || '') => {
        if (isAuthenticated) {
            return 'success';
        }

        try {
            const baseURL = process.env.REACT_APP_BASE_URL;
            const response = await apiClient.post(`${baseURL}/api/auth/validate-user`, {}, {
                headers: {
                    'accept': 'application/json',
                    // 'Authorization': `Bearer ${token}`,
                },
                withCredentials: true
            });

            // setIsAuthenticated(response.data.isAuthenticated);
            setIsEmailVerified(response.data.isEmailVerified);
            sessionStorage.setItem('isEmailVerified', response.data.isEmailVerified);
            setEmail(response.data.email);
            sessionStorage.setItem('email', response.data.email);
            setIsPhoneVerified(response.data.isPhoneVerified);
            sessionStorage.setItem('isPhoneVerified', response.data.isPhoneVerified);
            setPhone(response.data.phone);
            if (response.data.phone) {sessionStorage.setItem('phone', response.data.phone);}
            setName(response.data.name);
            if (response.data.name) {sessionStorage.setItem('name', response.data.name);}

            return 'success';
        } catch (error) {
            // setIsAuthenticated(false);
            setIsEmailVerified(false);
            sessionStorage.removeItem('isEmailVerified');
            setEmail('');
            sessionStorage.removeItem('email');
            setIsPhoneVerified(false);
            sessionStorage.removeItem('isPhoneVerified');
            setPhone('');
            sessionStorage.removeItem('phone');
            setName('');
            sessionStorage.removeItem('name');
            return 'Authentication failed';
        } finally {
            setIsLoading(false);
        }
    };

    const checkAuthentication = async (token: string = accessToken || localStorage.getItem('accessToken') || '') => {
        // if (isAuthenticated) {
        //     return 'success';
        // }

        try {
            const baseURL = process.env.REACT_APP_BASE_URL;
            const response = await axios.post(`${baseURL}/api/auth/validate-user`, {}, {
                headers: {
                    'accept': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                withCredentials: true
            });

            setIsAuthenticated(true);
            setIsEmailVerified(response.data.isEmailVerified);
            sessionStorage.setItem('isEmailVerified', response.data.isEmailVerified);
            setEmail(response.data.email);
            sessionStorage.setItem('email', response.data.email);
            setIsPhoneVerified(response.data.isPhoneVerified);
            sessionStorage.setItem('isPhoneVerified', response.data.isPhoneVerified);
            setPhone(response.data.phone);
            if (response.data.phone) {sessionStorage.setItem('phone', response.data.phone);}
            setName(response.data.name);
            if (response.data.name) {sessionStorage.setItem('name', response.data.name);}

            return 'success';
        } catch (error) {
            setIsAuthenticated(false);
            setIsEmailVerified(false);
            sessionStorage.removeItem('isEmailVerified');
            setEmail('');
            sessionStorage.removeItem('email');
            setIsPhoneVerified(false);
            sessionStorage.removeItem('isPhoneVerified');
            setPhone('');
            sessionStorage.removeItem('phone');
            setName('');
            sessionStorage.removeItem('name');
            return 'Authentication failed';
        } finally {
            setIsLoading(false);
        }
    };

    const apiClient = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    withCredentials: true,
  });

  // Request interceptor
  apiClient.interceptors.request.use(
    (config) => {
      if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
      }
      return config;
    },
    (error) => {
      console.error('Request error:', error);
      return Promise.reject(error);
    }
  );

  // Response interceptor
  apiClient.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response?.status === 401) {
        logout();
      }
      return Promise.reject(error);
    }
  );

    return (
        <AuthContext.Provider value={{
            isAuthenticated,
            setIsAuthenticated,
            isEmailVerified,
            email,
            isPhoneVerified,
            setIsPhoneVerified,
            phone,
            name,
            isLoading,
            accessToken,
            checkAuthentication,
            getUpdatedUserData,
            logout,
            login,
            register,
            sendEmailVerification,
            verifyEmailLink,
            setName,
            setPhone,
            apiClient
        }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};
