interface Params {
    [key: string]: any;
}

const getCppCode = (url: string, parameters: Params, currentParameters: Params): string => {
    let code = `#include <iostream>\n#include <string>\n#include <curl/curl.h>\n\n`;
    let data = {...parameters};
    for(let param in data) {
        if(data[param] === currentParameters[param]) {
            delete data[param];
        }
    }

    if(Object.keys(data).length > 0) {
        let jsonIndent = '    ';
        let dataString = JSON.stringify(data, null, 2).split('\n').map(line => jsonIndent + line).join('\n');
        code += `std::string data = R"(\n${dataString}\n)";\n\n`;
    }

    code += `std::string auth_header = "api-key: " + std::string("YOUR_NUTRICLOUD_API_KEY");\n\nstruct curl_slist *headers = NULL;\n\nint main() {\n    CURL *curl;\n    CURLcode res;\n\n    curl_global_init(CURL_GLOBAL_DEFAULT);\n\n    curl = curl_easy_init();\n    if(curl) {\n        headers = curl_slist_append(headers, "Content-Type: application/json");\n        headers = curl_slist_append(headers, auth_header.c_str());\n        curl_easy_setopt(curl, CURLOPT_HTTPHEADER, headers);\n\n        curl_easy_setopt(curl, CURLOPT_URL, "${url}");\n        curl_easy_setopt(curl, CURLOPT_POST, 1L);\n        curl_easy_setopt(curl, CURLOPT_POSTFIELDS, data.c_str());\n\n        res = curl_easy_perform(curl);\n\n        if(res != CURLE_OK)\n            fprintf(stderr, "curl_easy_perform() failed: %s\\n", curl_easy_strerror(res));\n      \n        curl_easy_cleanup(curl);\n    }\n\n    curl_global_cleanup();\n\n    return 0;\n}`;

    return code;
};

export default getCppCode;
