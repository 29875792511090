interface Params {
    [key: string]: any;
}

const getPythonCode = (url: string, parameters: Params, currentParameters: Params): string => {
    let headers = "{\n    'Content-Type': 'application/json',\n    'api-key': NUTRICLOUD_API_KEY\n}";

    let data = {...parameters};
    for(let param in data) {
        if(data[param] === currentParameters[param]) {
            delete data[param];
        }
    }

    let jsonIndent = '    ';
    let dataString = JSON.stringify(data, null, 2).split('\n').map(line => jsonIndent + line).join('\n');
    let pythonCode = `import requests\nimport json\n\nurl = ${url}\nheaders = ${headers}\ndata = {\n${dataString}\n}\n\nresponse = requests.post(url, headers=headers, data=json.dumps(data))`;

    return pythonCode;
};

export default getPythonCode;
