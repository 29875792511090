interface Params {
    [key: string]: any;
}

const getSwiftCommand = (url: string, parameters: Params, currentParameters: Params): string => {
    let command = `import Foundation\n\nlet url = URL(string: "${url}")!\nvar request = URLRequest(url: url)\nrequest.httpMethod = "POST"\nrequest.setValue("application/json", forHTTPHeaderField: "Content-Type")\nrequest.setValue("YOUR_NUTRICLOUD_API_KEY", forHTTPHeaderField: "api-key")\n\n`;

    let data = {...parameters};
    for(let param in data) {
        if(data[param] === currentParameters[param]) {
            delete data[param];
        }
    }

    if(Object.keys(data).length > 0) {
        let jsonIndent = '   ';
        let dataString = JSON.stringify(data, null, 2).split('\n').map(line => jsonIndent + line).join(',\n');
        command += `let json: [String: Any] = [\n${dataString}\n]\n\nlet jsonData = try? JSONSerialization.data(withJSONObject: json)\n\nrequest.httpBody = jsonData\n\n`;
    }

    command += `let task = URLSession.shared.dataTask(with: request) { data, response, error in\n    if let error = error {\n        print("Error: \\(error)")\n    } else if let data = data {\n        let str = String(data: data, encoding: .utf8)\n        print("Received data:\\n\\(str ?? "")")\n    }\n}\n\ntask.resume()`;

    return command;
};

export default getSwiftCommand;
