import React from 'react';
import { createStyles, useMantineTheme } from '@mantine/core';

interface TwoColumnProps {
    left: React.ReactNode;
    right: React.ReactNode;
}

const useStyles = createStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'row',
        [`@media (max-width: 1280px)`]: {
            flexDirection: 'column',
        },
    },
    leftColumn: {
        marginLeft: '25px',
        width: '200px',
        [`@media (max-width: 1280px)`]: {
            width: '100%',
        },
    },
    rightColumn: {
        width: '700px',
        [`@media (max-width: 1280px) and (min-width: 746px)`]: {
            width: '80vw',
        },
        [`@media (max-width: 745px)`]: {
            width: '95vw',
        },
    },
}));

const TwoColumn: React.FC<TwoColumnProps> = ({ left, right }) => {
    const { classes } = useStyles();

    return (
        <div className={classes.container}>
            <div className={classes.leftColumn}>{left}</div>
            <div className={classes.rightColumn}>{right}</div>
        </div>
    );
}

export default TwoColumn;
