import { createStyles } from '@mantine/core';
import React from 'react';

const useStyles = createStyles((theme) => ({
  logoContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
    padding: 0,
    margin: 0,
  },
  logoImage: {
    // Use clamp to adjust the marginTop value between a min and max value based on the viewport width
    marginTop: `clamp(${theme.spacing.xs}, 2vw, ${theme.spacing.lg})`, // Adjust the '2vw' as needed
    padding: 0,
  },
  companyName: {
    flexGrow: 1,
    fontWeight: 500,
    fontSize: '14vw', // This might be very large on desktop screens, consider using clamp here too
    color: theme.colors.gray[3],
    lineHeight: 1,
  },
}));





export const FullWidthCompanyLogo = () => {
  const { classes } = useStyles();

  return (
    <div className={classes.logoContainer}>
      <div className={classes.logoImage} aria-label="Company Logo" >
        {MySVG} {/* SVG goes here */}
      </div>
      <span className={classes.companyName}>NutriCloud</span>
    </div>
  );
};


// Usage example, embed the SVG directly as JSX
const MySVG = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="450 100 300 300" style={{width: 'auto', height: '20vw'}}>
    <path d="m640.333 317.588 92.526-149.216H576.284l8.184 13.2h124.673l-68.808 110.967zM490.859 298.464l68.808-110.967v-25.049l-92.526 149.216h156.575l-8.184-13.2z" fill="#ff8b00"/>
    <path d="M559.667 162.448v25.049l80.666 130.092v-25.05z" fill="#8c034e"/>
  </svg>
);
