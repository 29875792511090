import { createStyles, Paper } from '@mantine/core';

// Define the styles using Mantine's createStyles
const useStyles = createStyles((theme) => ({
  card: {
    display: 'flex',
      padding: 20,
      alignItems: 'center',
    border: `0.5px solid ${theme.colors.gray[8]}`,
      backgroundColor: theme.colors.gray[9],
    transition: 'background-color 200ms ease',

    '&:hover': {
      backgroundColor: theme.colors.dark[5],
    },

    // Switch to column layout on smaller screens
    [`@media (max-width: ${850}px)`]: {
      flexDirection: 'column',
    },
  },

  leftSection: {
    width: '35%',
    padding: theme.spacing.md,

    [`@media (max-width: ${850}px)`]: {
      width: '100%',
    },
  },

  rightSection: {
    width: '65%',
    padding: theme.spacing.md,

    [`@media (max-width: ${850}px)`]: {
      width: '100%',
    },
  },
}));

// Define props interface
interface ContentCardProps {
  leftContent: React.ReactNode;
  rightContent: React.ReactNode;
}

export const OverviewContentCard = ({ leftContent, rightContent }: ContentCardProps) => {
  const { classes } = useStyles();

  return (
    <Paper className={classes.card}>
      <div className={classes.leftSection}>
        {leftContent}
      </div>
      <div className={classes.rightSection}>
        {rightContent}
      </div>
    </Paper>
  );
};
