import React, { useState } from 'react';
import {
    Box,
    Text,
    useMantineTheme,
    Stack,
createStyles
} from '@mantine/core';
import {Link} from 'react-router-dom';
import {useDisclosure} from "@mantine/hooks";

const useStyles = createStyles((theme) => ({
    title: {
        textAlign: 'left',
        marginTop: 25,
        fontSize: '1.2rem',
        fontWeight: 700,
        marginBottom: 0,
        marginLeft: theme.spacing.md,
        color: theme.colors.gray[0],
        lineHeight: 'normal', // Adjust this line
    },


}));


// Add a new prop to SidebarItemProps
interface SidebarItemProps {
  label: string;
  to: string;
  isExpanded: boolean;
  isHighlighted?: boolean; // new prop to indicate permanent highlight
}

const hexToRGBA = (hex: string, alpha = 1) => {
  const [r, g, b] = hex.match(/\w\w/g)!.map((x) => parseInt(x, 16));
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};


const SidebarItem: React.FC<SidebarItemProps> = ({label, to, isExpanded, isHighlighted }) => {
    const [hover, setHover] = useState(false);
    const theme = useMantineTheme();

    // Use theme color for highlighting. Adjust the color index as needed.
    const highlightColor = hexToRGBA(theme.colors.orange[1], 0.2);

    // Determine the background color based on isHighlighted and hover state
    const backgroundColor = isHighlighted ? highlightColor :
                            hover ? hexToRGBA(theme.colors.blue[1], 0.1) : 'transparent';


    return (
        <Link
            to={to}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            style={{
                textDecoration: 'none',
                display: 'flex',
                alignItems: 'center',
                marginLeft: '10px',
                marginRight: '10px',
                marginTop: '0px',
                height: '28px',
                backgroundColor: backgroundColor, // Apply the determined background color
                borderRadius: '8px',
                transition: 'background-color 0.2s',
                padding: '0 10px',
            }}
        >
            <div style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: isExpanded ? '200px' : '0',
                transition: 'max-width 0.3s ease',
            }}>
                <Text fz={15} fw={400} color={isHighlighted ? 'white' : theme.colors.gray[1]} style={{ lineHeight: '1em' }}>
                    {label}
                </Text>
            </div>
        </Link>
    );
};


interface SidebarProps {
    defaultExpanded?: boolean;
    activeItem: string;
}

const ApiRefTOC: React.FC<SidebarProps> = ({ defaultExpanded = false, activeItem  }) => {
    const [isExpanded, setIsExpanded] = useState(defaultExpanded);
    const theme = useMantineTheme();
        const { classes } = useStyles();

  return (
    <Box
      // onMouseEnter={() => setIsExpanded(true)}
      //       onMouseLeave={handleMouseLeave} // Updated to use the handler function
      style={{
        position: 'fixed',
        left: 60,
        top: 54,
        width: isExpanded ? 230 : 60,
        height: 'calc(100vh - 54px)',
        backgroundColor: theme.colors.dark[8],
        transition: 'width 0.3s',
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column',
        zIndex: 1, borderRight: '1px solid rgba(134, 142, 150, 0.3)',
          paddingBottom: 50,
      }}
    >
      {/*  get started*/}
      <Stack spacing="xs">
          <Text className={classes.title}>Get Started</Text>
        {/* Top-aligned items */}
          <SidebarItem label="Authentication" to="/docs/api-reference/authentication" isExpanded={isExpanded} isHighlighted={activeItem === "authentication"} />
          <SidebarItem label="Making requests" to="/docs/api-reference/making-requests" isExpanded={isExpanded} isHighlighted={activeItem === "making-requests"} />
          <SidebarItem label="Debugging requests" to="/docs/api-reference/debugging-requests" isExpanded={isExpanded} isHighlighted={activeItem === "debugging-requests"} />
      </Stack>

              {/* endpoints*/}
      <Stack spacing="xs">
          <Text className={classes.title}>Endpoints</Text>
        {/* Top-aligned items */}
          <SidebarItem label="Search" to="/docs/api-reference/search" isExpanded={isExpanded} isHighlighted={activeItem === "search"}/>
          <SidebarItem label="Classify" to="/docs/api-reference/classify" isExpanded={isExpanded} isHighlighted={activeItem === "classify"}  />
          <SidebarItem label="Information" to="/docs/api-reference/introduction" isExpanded={isExpanded} isHighlighted={activeItem === "introduction"} />
          <SidebarItem label="Chat" to="/docs/api-reference/quickstart" isExpanded={isExpanded} isHighlighted={activeItem === "api-keys"} />
          <SidebarItem label="Suggest" to="/docs/api-reference/tutorials" isExpanded={isExpanded} isHighlighted={activeItem === "suggest"} />
            <SidebarItem label="Image" to="/docs/api-reference/profile" isExpanded={isExpanded} isHighlighted={activeItem === "image"}/>
            <SidebarItem label="Generate" to="/docs/api-reference/profile" isExpanded={isExpanded} isHighlighted={activeItem === "generate"}/>
      </Stack>
    </Box>
  );
};

export default ApiRefTOC;
