import {
    Button,
    createStyles, Flex,
    Paper,
    Select,
    Text, Tooltip,
    useMantineTheme
} from '@mantine/core';
import {Link, NavLink} from 'react-router-dom';
import {rem} from "@mantine/core";
import Sidebar from "../../../componenets/sidebar/sidebar";
import React from "react";
import { notifications } from '@mantine/notifications';
import { TextInput, Group, Box, Title } from '@mantine/core';
import {FaBars, FaEnvelope, FaHome, FaPhone, FaSearch, FaUser} from "react-icons/fa";
import {
    Briefcase,
    CreditCard,
    DollarSign,
    Info,
    Settings,
    Sliders
} from "react-feather";


const useStyles = createStyles((theme) => ({
    pageTitle: {
        textAlign: 'left',
        marginTop: 15,
        marginBottom: 10,
        fontSize: '1.5rem',
        fontWeight: 700,
        marginLeft: theme.spacing.md,
        color: theme.colors.gray[0],
        borderBottom: `1px dotted ${theme.colors.gray[7]}`,
        lineHeight: 'normal', // Adjust this line
    },
    toolTipTitle: {
        textAlign: 'left',
        marginTop: 15,
        marginBottom: 10,
        fontSize: '1.2rem',
        fontWeight: 300,
        color: theme.colors.gray[0],
        borderBottom: `1px dotted ${theme.colors.gray[7]}`,
        lineHeight: 'normal', // Adjust this line
    },

        toolTip: {
        transition: 'all 0.3s ease',
        backgroundColor: theme.colors.gray[0],
        color: theme.colors.gray[9],
        border: `1px solid ${theme.colors.gray[5]}`,
        zIndex: 1000,
        fontSize: '10px',
    },

    inLineStyle: {
         display: 'inline-block'
    },

    header: {
        textAlign: 'left',
        fontSize: "24px",
        color: theme.colors.gray[3],
        fontWeight: 600,
        marginLeft: '25px',
    },

    finePrint: {
        fontSize: '12px',
        color: theme.colors.gray[3],
        marginLeft: '25px',
    },

    pageLine: {
        width: '200%',
        height: '1px',
        backgroundColor: theme.colors.gray[7],
        marginBottom: theme.spacing.md,
        marginLeft: 'auto', // Add this line
        marginRight: 'auto', // Add this line
    },

    paragraphText: {
        fontSize: '16px',
        fontWeight: 300,
        color: theme.colors.gray[0],
        marginLeft: '25px',
    },

    rightDiv: {
        maxWidth: '1000px',
        '@media (min-width: 1280px)': {
            marginLeft: 0,
        },
    },

    leftDiv: {
        marginBottom: '40px',
    },

    separatingDiv: {
        height: '10px',
        '@media (min-width: 1280px)': {
            height: '15px',
        },
    },
    largeSeparatingDiv: {
        height: '60px',
        '@media (min-width: 1280px)': {
            height: '80px',
        },
    },

    paragraphBoldText: {
        fontWeight: 'bold',
    },

    highlightedLink: {
        color: theme.colors.orange[0],
        textDecoration: 'none',
    },

    table: {
        width: '100%',
        borderCollapse: 'collapse',
        marginLeft: '20px',
    },

    cell: {
        padding: '0px',
        textAlign: 'left',
        borderBottom: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.gray[0] : theme.colors.gray[0]}`,
        minWidth: '100px',
        marginLeft: '0px',
        marginRight: '0px',

        fontSize: '14px',
        color: theme.colors.gray[3],

    },
    bigCell: {
        padding: '0px',
        textAlign: 'left',
        borderBottom: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.gray[0] : theme.colors.gray[0]}`,
        marginLeft: '0px',
        marginRight: '0px',
        fontSize: '14px',
        color: theme.colors.gray[3],
        minWidth: '100px',
        '@media (min-width: 1000px)': {
            minWidth: '200px',
        },
    },
    tableHeader: {
        borderBottomWidth: '2px',  // make the header line bolder
        fontWeight: 'bold',
    },
    lastRow: {
        borderBottom: 'none',  // remove the bottom border from the last row
    },

    buttonColor: {
        backgroundColor: theme.colors.orange[1],
        fontSize: '14px',
        height: '35px',
        color: theme.colors.gray[9],
    },
    container: {
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
    paddingLeft: theme.spacing.md,
    borderRadius: theme.radius.md,
    boxShadow: theme.shadows.sm,
        maxWidth: '400px',
  },
  title: {
    marginBottom: theme.spacing.md,
  },
  input: {
    marginBottom: theme.spacing.sm,
  },
  button: {
    marginTop: theme.spacing.md,
  },
        iconBox: {
        width: '300px',
            marginRight: theme.spacing.lg,
        display: 'flex',
        justifyContent: 'flex-start', // Align items to the start (left side)
        alignItems: 'center',
        marginBottom: theme.spacing.md,
        '& svg': {
            transition: 'transform 0.3s ease',
        },
        '&:hover svg': {
            transform: 'scale(1.2)',
        },
    },
    contentBox: {
        marginLeft: theme.spacing.md, // Add some space between the icon and the text
    },
    price: {
        fontSize: '2.5rem',
        fontWeight: 500,
        marginBottom: theme.spacing.md,
    },
}));

interface UserProfileFormValues {
  name: string;
  email: string;
  phoneNumber: string;
}

  const handleSubmit = (values: UserProfileFormValues) => {
    console.log(values);
  };

  const handleEnableMFA = () => {
    // Logic to enable MFA
  };

  const boxData = [
    { colors: ['#5BB080', '#428a61'], title: 'Billing history', description: 'View past and current invoices', logo: <CreditCard size="1.5rem"/>, to: '/account/billing/history' },
    { colors: ['#28AFB0', '#2286aa'], title: 'Usage limits', description: 'Set monthly spend limits', logo: <Sliders size="1.3em" /> , to: '/account/limits.'},
    { colors: ['#C916DA', '#810099'], title: 'Preferences', description: 'Manage billing information', logo: <Settings size="1.2em" /> , to: '/account/billing/preferences'},
    { colors: ['#ef4146', '#F10B15'], title: 'pricing', description: 'View pricing FAQs', logo: <DollarSign size="1.5rem"/> , to: '/account/billing/'},
    { colors: ['#E2B73F', '#b1891b'], title: 'Legal', description: 'View our policies', logo: <Briefcase size="1.5rem"/> , to: '/legal'},
];



export function BillingPreferences() {
    const theme = useMantineTheme();
    const { classes } = useStyles();
    const gradientBoxes = boxData.map((box, index) => (
        <Link to={box.to} key={index} style={{ textDecoration: 'none', color: 'inherit' }}>
        <Flex className={classes.iconBox} key={index}>
            <Paper
                style={{
                    background: `linear-gradient(to top right, ${box.colors[0]}, ${box.colors[1]})`,
                    padding: '.5em',
                    borderRadius: '10px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '3em',
                    height: '3em',
                    boxSizing: 'border-box',
                }}
            >
                {box.logo}
            </Paper>
            <Flex direction="column" className={classes.contentBox}>
                <Text fz={18} weight={700}>{box.title}</Text>
                <Text fz={14} size="sm">{box.description}</Text>
            </Flex>
        </Flex>
        </Link>
    ));
    return (
        <div style={{maxWidth: 1400}}>
            <Sidebar defaultExpanded={true} activeItem="billing"/>
            <div style={{marginLeft: "200px"}}>
                {/*title*/}
                {/*title*/}
                <Tooltip
                  label="Manage your billing and API credits"
                  className={classes.toolTip}
                  position={'bottom'}
                  transitionProps={{ transition: 'scale', duration: 200 }}
                  multiline
                  w={300}
                  radius={"xs"}
                  p={20}
                  fw={500}
                  fz={'14px'}
                  ml={15}
                  offset={-5}

                  bg={theme.colors.gray[9]}
                  color={theme.colors.gray[8]}
                >
                  <div className={classes.inLineStyle}>
                    <Text size="xs" className={classes.pageTitle}>Billing</Text>
                  </div>
                </Tooltip>
                {/*header*/}
                    <Group m={0} p={0} position="left" spacing="sm">
                      <NavLink to="/account/billing/overview" style={{ textDecoration: 'none'}}>
                        <Button style={{color: theme.colors.gray[1]}} variant="subtle" radius={0} component="span" onClick={() => {}}>Overview</Button>
                      </NavLink>
                      <NavLink to="/account/billing/history" style={{ textDecoration: 'none' }}>
                        <Button style={{color: theme.colors.gray[1]}} variant="subtle" radius={0} component="span" onClick={() => {}}>Billing History</Button>
                      </NavLink>
                      <NavLink to="/account/billing/preferences" style={{ textDecoration: 'none' }}>
                        <Button style={{color: theme.colors.gray[1], borderBottom: "3px solid #FFF"}} variant="subtle" radius={0} component="span" onClick={() => {}}>Preferences</Button>
                      </NavLink>
                    </Group>


                <div className={classes.pageLine}></div>
                {/* info*/}
                <div className={classes.rightDiv} style={{marginLeft: 25}}>
                </div>
            </div>
        </div>
    );
}

export default BillingPreferences;
