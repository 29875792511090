import React, {useEffect, useState} from 'react';
import {
    Table,
    Button,
    createStyles,
    useMantineTheme,
    Flex,
    Tooltip, Badge, Text, Loader, Center
} from '@mantine/core';
import {ChevronDown} from "react-feather";
import axios from "axios";
import axiosInstance from "../../axiosInstance";
import {useAuth} from "../../AuthContext";

// Use Mantine's createStyles to generate class names with attached styles
const useStyles = createStyles((theme) => ({
  cell: {
    // You can adjust the padding to increase cell spacing
    padding: theme.spacing.lg,
    textAlign: 'center', // This centers the content in the cell
  },
  header: {
    backgroundColor: theme.colors.gray[0],
    // You can adjust the padding to increase header cell spacing
    padding: theme.spacing.lg,
    textAlign: 'center', // This centers the content in the header cell
  },
  button: {
    marginTop: theme.spacing.md,
    display: 'block', // Centers the button
    marginLeft: 'auto',
    marginRight: 'auto',
  },
    toolTip: {
        transition: 'all 0.3s ease',
        backgroundColor: theme.colors.gray[0],
        color: theme.colors.gray[9],
        border: `1px solid ${theme.colors.gray[5]}`,
        zIndex: 1000,
        fontSize: '10px',
    },
    tableContainer: {
        overflowX: 'auto', // Enables horizontal scrolling
        maxWidth: '100%', // Ensures the container does not exceed the viewport width
        marginRight: 20,
    },
}));

// Example row type
type RateLimitItem = {
  name: string;
  tokens_per_day: number;
  requests_per_day: number;
  tokens_per_minute: number;
  requests_per_minute: number;
};

type RateLimitTiers = {
  [key: string]: {
    [key: string]: number;
  };
};
const rate_limit_tiers: RateLimitTiers = {
    "Free": {
    "requests_per_minute_limit": 60,
    "tokens_per_minute_limit": 100,
    "requests_per_day_limit": 1440,
    "tokens_per_day_limit": 2400,
    "user_defined_tokens_per_month_limit": 10000,
    "user_defined_email_threshold": -1,
    "tokens_per_month_limit": 10000,
    "food_search_requests_minute": 5,
    "food_search_tokens_minute": 5,
    "food_search_requests_day": 10,
    "food_search_tokens_day": 10,
    "food_info_requests_minute": 3,
    "food_info_tokens_minute": 3,
    "food_info_requests_day": 10,
    "food_info_tokens_day": 10,
    "food_chat_requests_minute": 2,
    "food_chat_tokens_minute": 2,
    "food_chat_requests_day": 10,
    "food_chat_tokens_day": 10,
    "food_suggest_requests_minute": 10,
    "food_suggest_tokens_minute": 10,
    "food_suggest_requests_day": 4,
    "food_suggest_tokens_day": 4,
    "food_image_requests_minute": 5,
    "food_image_tokens_minute": 5,
    "food_image_requests_day": 10,
    "food_image_tokens_day": 10,
    "food_classification_requests_minute": 5,
    "food_classification_tokens_minute": 5,
    "food_classification_requests_day": 10,
    "food_classification_tokens_day": 10,
    "food_recognition_requests_minute": 5,
    "food_recognition_tokens_minute": 5,
    "food_recognition_requests_day": 10,
    "food_recognition_tokens_day": 10,
    "food_generate_requests_minute": 5,
    "food_generate_tokens_minute": 5,
    "food_generate_requests_day": 10,
    "food_generate_tokens_day": 10,
    "recipe_search_requests_minute": 5,
    "recipe_search_tokens_minute": 5,
    "recipe_search_requests_day": 10,
    "recipe_search_tokens_day": 10,
    "recipe_classification_requests_minute": 5,
    "recipe_classification_tokens_minute": 5,
    "recipe_classification_requests_day": 10,
    "recipe_classification_tokens_day": 10,
    "recipe_generate_requests_minute": 5,
    "recipe_generate_tokens_minute": 5,
    "recipe_generate_requests_day": 10,
    "recipe_generate_tokens_day": 10,
    "recipe_image_requests_minute": 5,
    "recipe_image_tokens_minute": 5,
    "recipe_image_requests_day": 10,
    "recipe_image_tokens_day": 10,
    "recipe_info_requests_minute": 5,
    "recipe_info_tokens_minute": 5,
    "recipe_info_requests_day": 10,
    "recipe_info_tokens_day": 10,
    "recipe_chat_requests_minute": 5,
    "recipe_chat_tokens_minute": 5,
    "recipe_chat_requests_day": 10,
    "recipe_chat_tokens_day": 10,
    "recipe_recognition_requests_minute": 5,
    "recipe_recognition_tokens_minute": 5,
    "recipe_recognition_requests_day": 10,
    "recipe_recognition_tokens_day": 10,
    "recipe_suggest_requests_minute": 5,
    "recipe_suggest_tokens_minute": 5,
    "recipe_suggest_requests_day": 10,
    "recipe_suggest_tokens_day": 10,
    "apps_requests_minute": 5,
    "apps_tokens_minute": 5,
    "apps_requests_day": 10,
    "apps_tokens_day": 10,
    "users_requests_minute": 5,
    "users_tokens_minute": 5,
    "users_requests_day": 10,
    "users_tokens_day": 10,
    "payments_requests_minute": 5,
    "payments_tokens_minute": 5,
    "payments_requests_day": 10,
    "payments_tokens_day": 10,
    "usage_requests_minute": 5,
    "usage_tokens_minute": 5,
    "usage_requests_day": 10,
    "usage_tokens_day": 10
},
"Tier 1":
{
    'users_requests_minute': 60,
    'users_tokens_minute': 300,
    'users_requests_day': 1440,
    'users_tokens_day': 2400,

    'apps_requests_minute': 60,
    'apps_tokens_minute': 300,
    'apps_requests_day': 1440,
    'apps_tokens_day': 2400,

    'payments_requests_minute': 60,
    'payments_tokens_minute': 300,
    'payments_requests_day': 1440,
    'payments_tokens_day': 2400,

    'usage_requests_minute': 60,
    'usage_tokens_minute': 300,
    'usage_requests_day': 1440,
    'usage_tokens_day': 2400,

    'food_chat_requests_day': 14400,
 'food_chat_requests_minute': 120,
 'food_chat_tokens_day': 72000,
 'food_chat_tokens_minute': 600,
 'food_classification_requests_day': 21600,
 'food_classification_requests_minute': 180,
 'food_classification_tokens_day': 108000,
 'food_classification_tokens_minute': 900,
 'food_generate_requests_day': 7200,
 'food_generate_requests_minute': 60,
 'food_generate_tokens_day': 36000,
 'food_generate_tokens_minute': 300,
 'food_image_requests_day': 7200,
 'food_image_requests_minute': 60,
 'food_image_tokens_day': 36000,
 'food_image_tokens_minute': 300,
 'food_info_requests_day': 28800,
 'food_info_requests_minute': 240,
 'food_info_tokens_day': 144000,
 'food_info_tokens_minute': 1200,
 'food_recognition_requests_day': 7200,
 'food_recognition_requests_minute': 60,
 'food_recognition_tokens_day': 36000,
 'food_recognition_tokens_minute': 300,
 'food_search_requests_day': 36000,
 'food_search_requests_minute': 300,
 'food_search_tokens_day': 180000,
 'food_search_tokens_minute': 1500,
 'food_suggest_requests_day': 21600,
 'food_suggest_requests_minute': 180,
 'food_suggest_tokens_day': 108000,
 'food_suggest_tokens_minute': 900,
 'recipe_chat_requests_day': 14400,
 'recipe_chat_requests_minute': 120,
 'recipe_chat_tokens_day': 72000,
 'recipe_chat_tokens_minute': 600,
 'recipe_classification_requests_day': 21600,
 'recipe_classification_requests_minute': 180,
 'recipe_classification_tokens_day': 108000,
 'recipe_classification_tokens_minute': 900,
 'recipe_generate_requests_day': 7200,
 'recipe_generate_requests_minute': 60,
 'recipe_generate_tokens_day': 36000,
 'recipe_generate_tokens_minute': 300,
 'recipe_image_requests_day': 7200,
 'recipe_image_requests_minute': 60,
 'recipe_image_tokens_day': 36000,
 'recipe_image_tokens_minute': 300,
 'recipe_info_requests_day': 28800,
 'recipe_info_requests_minute': 240,
 'recipe_info_tokens_day': 144000,
 'recipe_info_tokens_minute': 1200,
 'recipe_recognition_requests_day': 7200,
 'recipe_recognition_requests_minute': 60,
 'recipe_recognition_tokens_day': 36000,
 'recipe_recognition_tokens_minute': 300,
 'recipe_search_requests_day': 36000,
 'recipe_search_requests_minute': 300,
 'recipe_search_tokens_day': 180000,
 'recipe_search_tokens_minute': 1500,
 'recipe_suggest_requests_day': 21600,
 'recipe_suggest_requests_minute': 180,
 'recipe_suggest_tokens_day': 108000,
 'recipe_suggest_tokens_minute': 900,
 'requests_per_day_limit': 86400,
 'requests_per_minute_limit': 600,
 'tokens_per_day_limit': 432000,
 'tokens_per_minute_limit': 3000,
 'tokens_per_month_limit': 12960000
 },

"Tier 2":
{
    'users_requests_minute': 500,
    'users_tokens_minute': 2500,
    'users_requests_day': 12000,
    'users_tokens_day': 20000,

    'apps_requests_minute': 500,
    'apps_tokens_minute': 2500,
    'apps_requests_day': 12000,
    'apps_tokens_day': 20000,

    'payments_requests_minute': 500,
    'payments_tokens_minute': 2500,
    'payments_requests_day': 12000,
    'payments_tokens_day': 20000,

    'usage_requests_minute': 500,
    'usage_tokens_minute': 2500,
    'usage_requests_day': 12000,
    'usage_tokens_day': 20000,


    'food_chat_requests_day': 120000,
 'food_chat_requests_minute': 1000,
 'food_chat_tokens_day': 600000,
 'food_chat_tokens_minute': 5000,
 'food_classification_requests_day': 180000,
 'food_classification_requests_minute': 1500,
 'food_classification_tokens_day': 900000,
 'food_classification_tokens_minute': 7500,
 'food_generate_requests_day': 60000,
 'food_generate_requests_minute': 500,
 'food_generate_tokens_day': 300000,
 'food_generate_tokens_minute': 2500,
 'food_image_requests_day': 60000,
 'food_image_requests_minute': 500,
 'food_image_tokens_day': 300000,
 'food_image_tokens_minute': 2500,
 'food_info_requests_day': 240000,
 'food_info_requests_minute': 2000,
 'food_info_tokens_day': 1200000,
 'food_info_tokens_minute': 10000,
 'food_recognition_requests_day': 60000,
 'food_recognition_requests_minute': 500,
 'food_recognition_tokens_day': 300000,
 'food_recognition_tokens_minute': 2500,
 'food_search_requests_day': 300000,
 'food_search_requests_minute': 2500,
 'food_search_tokens_day': 1500000,
 'food_search_tokens_minute': 12500,
 'food_suggest_requests_day': 180000,
 'food_suggest_requests_minute': 1500,
 'food_suggest_tokens_day': 900000,
 'food_suggest_tokens_minute': 7500,
 'recipe_chat_requests_day': 120000,
 'recipe_chat_requests_minute': 1000,
 'recipe_chat_tokens_day': 600000,
 'recipe_chat_tokens_minute': 5000,
 'recipe_classification_requests_day': 180000,
 'recipe_classification_requests_minute': 1500,
 'recipe_classification_tokens_day': 900000,
 'recipe_classification_tokens_minute': 7500,
 'recipe_generate_requests_day': 60000,
 'recipe_generate_requests_minute': 500,
 'recipe_generate_tokens_day': 300000,
 'recipe_generate_tokens_minute': 2500,
 'recipe_image_requests_day': 60000,
 'recipe_image_requests_minute': 500,
 'recipe_image_tokens_day': 300000,
 'recipe_image_tokens_minute': 2500,
 'recipe_info_requests_day': 240000,
 'recipe_info_requests_minute': 2000,
 'recipe_info_tokens_day': 1200000,
 'recipe_info_tokens_minute': 10000,
 'recipe_recognition_requests_day': 60000,
 'recipe_recognition_requests_minute': 500,
 'recipe_recognition_tokens_day': 300000,
 'recipe_recognition_tokens_minute': 2500,
 'recipe_search_requests_day': 300000,
 'recipe_search_requests_minute': 2500,
 'recipe_search_tokens_day': 1500000,
 'recipe_search_tokens_minute': 12500,
 'recipe_suggest_requests_day': 180000,
 'recipe_suggest_requests_minute': 1500,
 'recipe_suggest_tokens_day': 900000,
 'recipe_suggest_tokens_minute': 7500,
 'requests_per_day_limit': 720000,
 'requests_per_minute_limit': 5000,
 'tokens_per_day_limit': 3600000,
 'tokens_per_minute_limit': 25000,
 'tokens_per_month_limit': 108000000},

"Tier 3":
{
    'users_requests_minute': 2000,
    'users_tokens_minute': 10000,
    'users_requests_day': 12000,
    'users_tokens_day': 20000,

    'apps_requests_minute': 500,
    'apps_tokens_minute': 2500,
    'apps_requests_day': 12000,
    'apps_tokens_day': 20000,

    'payments_requests_minute': 500,
    'payments_tokens_minute': 2500,
    'payments_requests_day': 12000,
    'payments_tokens_day': 20000,

    'usage_requests_minute': 500,
    'usage_tokens_minute': 2500,
    'usage_requests_day': 12000,
    'usage_tokens_day': 20000,

    'food_chat_requests_day': 480000,
 'food_chat_requests_minute': 4000,
 'food_chat_tokens_day': 2400000,
 'food_chat_tokens_minute': 20000,
 'food_classification_requests_day': 720000,
 'food_classification_requests_minute': 6000,
 'food_classification_tokens_day': 3600000,
 'food_classification_tokens_minute': 30000,
 'food_generate_requests_day': 240000,
 'food_generate_requests_minute': 2000,
 'food_generate_tokens_day': 1200000,
 'food_generate_tokens_minute': 10000,
 'food_image_requests_day': 240000,
 'food_image_requests_minute': 2000,
 'food_image_tokens_day': 1200000,
 'food_image_tokens_minute': 10000,
 'food_info_requests_day': 960000,
 'food_info_requests_minute': 8000,
 'food_info_tokens_day': 4800000,
 'food_info_tokens_minute': 40000,
 'food_recognition_requests_day': 240000,
 'food_recognition_requests_minute': 2000,
 'food_recognition_tokens_day': 1200000,
 'food_recognition_tokens_minute': 10000,
 'food_search_requests_day': 1200000,
 'food_search_requests_minute': 10000,
 'food_search_tokens_day': 6000000,
 'food_search_tokens_minute': 50000,
 'food_suggest_requests_day': 720000,
 'food_suggest_requests_minute': 6000,
 'food_suggest_tokens_day': 3600000,
 'food_suggest_tokens_minute': 30000,
 'recipe_chat_requests_day': 480000,
 'recipe_chat_requests_minute': 4000,
 'recipe_chat_tokens_day': 2400000,
 'recipe_chat_tokens_minute': 20000,
 'recipe_classification_requests_day': 720000,
 'recipe_classification_requests_minute': 6000,
 'recipe_classification_tokens_day': 3600000,
 'recipe_classification_tokens_minute': 30000,
 'recipe_generate_requests_day': 240000,
 'recipe_generate_requests_minute': 2000,
 'recipe_generate_tokens_day': 1200000,
 'recipe_generate_tokens_minute': 10000,
 'recipe_image_requests_day': 240000,
 'recipe_image_requests_minute': 2000,
 'recipe_image_tokens_day': 1200000,
 'recipe_image_tokens_minute': 10000,
 'recipe_info_requests_day': 960000,
 'recipe_info_requests_minute': 8000,
 'recipe_info_tokens_day': 4800000,
 'recipe_info_tokens_minute': 40000,
 'recipe_recognition_requests_day': 240000,
 'recipe_recognition_requests_minute': 2000,
 'recipe_recognition_tokens_day': 1200000,
 'recipe_recognition_tokens_minute': 10000,
 'recipe_search_requests_day': 1200000,
 'recipe_search_requests_minute': 10000,
 'recipe_search_tokens_day': 6000000,
 'recipe_search_tokens_minute': 50000,
 'recipe_suggest_requests_day': 720000,
 'recipe_suggest_requests_minute': 6000,
 'recipe_suggest_tokens_day': 3600000,
 'recipe_suggest_tokens_minute': 30000,
 'requests_per_day_limit': 2880000,
 'requests_per_minute_limit': 20000,
 'tokens_per_day_limit': 14400000,
 'tokens_per_minute_limit': 100000,
 'tokens_per_month_limit': 432000000},

"Tier 4":
{
    'users_requests_minute': 5000,
    'users_tokens_minute': 25000,
    'users_requests_day': 12000,
    'users_tokens_day': 20000,

    'apps_requests_minute': 500,
    'apps_tokens_minute': 2500,
    'apps_requests_day': 12000,
    'apps_tokens_day': 20000,

    'payments_requests_minute': 500,
    'payments_tokens_minute': 2500,
    'payments_requests_day': 12000,
    'payments_tokens_day': 20000,

    'usage_requests_minute': 500,
    'usage_tokens_minute': 2500,
    'usage_requests_day': 12000,
    'usage_tokens_day': 20000,

    'food_chat_requests_day': 1200000,
 'food_chat_requests_minute': 10000,
 'food_chat_tokens_day': 6000000,
 'food_chat_tokens_minute': 50000,
 'food_classification_requests_day': 1800000,
 'food_classification_requests_minute': 15000,
 'food_classification_tokens_day': 9000000,
 'food_classification_tokens_minute': 75000,
 'food_generate_requests_day': 600000,
 'food_generate_requests_minute': 5000,
 'food_generate_tokens_day': 3000000,
 'food_generate_tokens_minute': 25000,
 'food_image_requests_day': 600000,
 'food_image_requests_minute': 5000,
 'food_image_tokens_day': 3000000,
 'food_image_tokens_minute': 25000,
 'food_info_requests_day': 2400000,
 'food_info_requests_minute': 20000,
 'food_info_tokens_day': 12000000,
 'food_info_tokens_minute': 100000,
 'food_recognition_requests_day': 600000,
 'food_recognition_requests_minute': 5000,
 'food_recognition_tokens_day': 3000000,
 'food_recognition_tokens_minute': 25000,
 'food_search_requests_day': 3000000,
 'food_search_requests_minute': 25000,
 'food_search_tokens_day': 15000000,
 'food_search_tokens_minute': 125000,
 'food_suggest_requests_day': 1800000,
 'food_suggest_requests_minute': 15000,
 'food_suggest_tokens_day': 9000000,
 'food_suggest_tokens_minute': 75000,
 'recipe_chat_requests_day': 1200000,
 'recipe_chat_requests_minute': 10000,
 'recipe_chat_tokens_day': 6000000,
 'recipe_chat_tokens_minute': 50000,
 'recipe_classification_requests_day': 1800000,
 'recipe_classification_requests_minute': 15000,
 'recipe_classification_tokens_day': 9000000,
 'recipe_classification_tokens_minute': 75000,
 'recipe_generate_requests_day': 600000,
 'recipe_generate_requests_minute': 5000,
 'recipe_generate_tokens_day': 3000000,
 'recipe_generate_tokens_minute': 25000,
 'recipe_image_requests_day': 600000,
 'recipe_image_requests_minute': 5000,
 'recipe_image_tokens_day': 3000000,
 'recipe_image_tokens_minute': 25000,
 'recipe_info_requests_day': 2400000,
 'recipe_info_requests_minute': 20000,
 'recipe_info_tokens_day': 12000000,
 'recipe_info_tokens_minute': 100000,
 'recipe_recognition_requests_day': 600000,
 'recipe_recognition_requests_minute': 5000,
 'recipe_recognition_tokens_day': 3000000,
 'recipe_recognition_tokens_minute': 25000,
 'recipe_search_requests_day': 3000000,
 'recipe_search_requests_minute': 25000,
 'recipe_search_tokens_day': 15000000,
 'recipe_search_tokens_minute': 125000,
 'recipe_suggest_requests_day': 1800000,
 'recipe_suggest_requests_minute': 15000,
 'recipe_suggest_tokens_day': 9000000,
 'recipe_suggest_tokens_minute': 75000,
 'requests_per_day_limit': 7200000,
 'requests_per_minute_limit': 50000,
 'tokens_per_day_limit': 36000000,
 'tokens_per_minute_limit': 250000,
 'tokens_per_month_limit': 1080000000},

"Tier 5":
{
    'users_requests_minute': 10000,
    'users_tokens_minute': 50000,
    'users_requests_day': 12000,
    'users_tokens_day': 20000,

    'apps_requests_minute': 500,
    'apps_tokens_minute': 2500,
    'apps_requests_day': 12000,
    'apps_tokens_day': 20000,

    'payments_requests_minute': 500,
    'payments_tokens_minute': 2500,
    'payments_requests_day': 12000,
    'payments_tokens_day': 20000,

    'usage_requests_minute': 500,
    'usage_tokens_minute': 2500,
    'usage_requests_day': 12000,
    'usage_tokens_day': 20000,

    'food_chat_requests_day': 2400000,
 'food_chat_requests_minute': 20000,
 'food_chat_tokens_day': 12000000,
 'food_chat_tokens_minute': 100000,
 'food_classification_requests_day': 3600000,
 'food_classification_requests_minute': 30000,
 'food_classification_tokens_day': 18000000,
 'food_classification_tokens_minute': 150000,
 'food_generate_requests_day': 1200000,
 'food_generate_requests_minute': 10000,
 'food_generate_tokens_day': 6000000,
 'food_generate_tokens_minute': 50000,
 'food_image_requests_day': 1200000,
 'food_image_requests_minute': 10000,
 'food_image_tokens_day': 6000000,
 'food_image_tokens_minute': 50000,
 'food_info_requests_day': 4800000,
 'food_info_requests_minute': 40000,
 'food_info_tokens_day': 24000000,
 'food_info_tokens_minute': 200000,
 'food_recognition_requests_day': 1200000,
 'food_recognition_requests_minute': 10000,
 'food_recognition_tokens_day': 6000000,
 'food_recognition_tokens_minute': 50000,
 'food_search_requests_day': 6000000,
 'food_search_requests_minute': 50000,
 'food_search_tokens_day': 30000000,
 'food_search_tokens_minute': 250000,
 'food_suggest_requests_day': 3600000,
 'food_suggest_requests_minute': 30000,
 'food_suggest_tokens_day': 18000000,
 'food_suggest_tokens_minute': 150000,
 'recipe_chat_requests_day': 2400000,
 'recipe_chat_requests_minute': 20000,
 'recipe_chat_tokens_day': 12000000,
 'recipe_chat_tokens_minute': 100000,
 'recipe_classification_requests_day': 3600000,
 'recipe_classification_requests_minute': 30000,
 'recipe_classification_tokens_day': 18000000,
 'recipe_classification_tokens_minute': 150000,
 'recipe_generate_requests_day': 1200000,
 'recipe_generate_requests_minute': 10000,
 'recipe_generate_tokens_day': 6000000,
 'recipe_generate_tokens_minute': 50000,
 'recipe_image_requests_day': 1200000,
 'recipe_image_requests_minute': 10000,
 'recipe_image_tokens_day': 6000000,
 'recipe_image_tokens_minute': 50000,
 'recipe_info_requests_day': 4800000,
 'recipe_info_requests_minute': 40000,
 'recipe_info_tokens_day': 24000000,
 'recipe_info_tokens_minute': 200000,
 'recipe_recognition_requests_day': 1200000,
 'recipe_recognition_requests_minute': 10000,
 'recipe_recognition_tokens_day': 6000000,
 'recipe_recognition_tokens_minute': 50000,
 'recipe_search_requests_day': 6000000,
 'recipe_search_requests_minute': 50000,
 'recipe_search_tokens_day': 30000000,
 'recipe_search_tokens_minute': 250000,
 'recipe_suggest_requests_day': 3600000,
 'recipe_suggest_requests_minute': 30000,
 'recipe_suggest_tokens_day': 18000000,
 'recipe_suggest_tokens_minute': 150000,
 'requests_per_day_limit': 14400000,
 'requests_per_minute_limit': 100000,
 'tokens_per_day_limit': 72000000,
 'tokens_per_minute_limit': 500000,
 'tokens_per_month_limit': 2000000000}
}

interface RateLimitObj {
  [key: string]: number;
}

const getRateLimitData = (rateLimitObj: RateLimitObj): RateLimitItem[] => {
    return [
        {
            name: "/food/search",
            tokens_per_day: rateLimitObj.food_search_tokens_day,
            requests_per_day: rateLimitObj.food_search_requests_day,
            tokens_per_minute: rateLimitObj.food_search_tokens_minute,
            requests_per_minute: rateLimitObj.food_search_requests_minute,
        },
        {
            name: "/food/info",
            tokens_per_day: rateLimitObj.food_info_tokens_day,
            requests_per_day: rateLimitObj.food_info_requests_day,
            tokens_per_minute: rateLimitObj.food_info_tokens_minute,
            requests_per_minute: rateLimitObj.food_info_requests_minute,
        },
        {
            name: "/food/chat",
            tokens_per_day: rateLimitObj.food_chat_tokens_day,
            requests_per_day: rateLimitObj.food_chat_requests_day,
            tokens_per_minute: rateLimitObj.food_chat_tokens_minute,
            requests_per_minute: rateLimitObj.food_chat_requests_minute,
        },
        {
            name: "/food/suggest",
            tokens_per_day: rateLimitObj.food_suggest_tokens_day,
            requests_per_day: rateLimitObj.food_suggest_requests_day,
            tokens_per_minute: rateLimitObj.food_suggest_tokens_minute,
            requests_per_minute: rateLimitObj.food_suggest_requests_minute,
        },
        {
            name: "/food/image",
            tokens_per_day: rateLimitObj.food_image_tokens_day,
            requests_per_day: rateLimitObj.food_image_requests_day,
            tokens_per_minute: rateLimitObj.food_image_tokens_minute,
            requests_per_minute: rateLimitObj.food_image_requests_minute,
        },
        {
            name: "/food/classification",
            tokens_per_day: rateLimitObj.food_classification_tokens_day,
            requests_per_day: rateLimitObj.food_classification_requests_day,
            tokens_per_minute: rateLimitObj.food_classification_tokens_minute,
            requests_per_minute: rateLimitObj.food_classification_requests_minute,
        },
        {
            name: "/food/recognition",
            tokens_per_day: rateLimitObj.food_recognition_tokens_day,
            requests_per_day: rateLimitObj.food_recognition_requests_day,
            tokens_per_minute: rateLimitObj.food_recognition_tokens_minute,
            requests_per_minute: rateLimitObj.food_recognition_requests_minute,
        },
        {
            name: "/food/generate",
            tokens_per_day: rateLimitObj.food_generate_tokens_day,
            requests_per_day: rateLimitObj.food_generate_requests_day,
            tokens_per_minute: rateLimitObj.food_generate_tokens_minute,
            requests_per_minute: rateLimitObj.food_generate_requests_minute,
        },
        {
            name: "/recipe/search",
            tokens_per_day: rateLimitObj.recipe_search_tokens_day,
            requests_per_day: rateLimitObj.recipe_search_requests_day,
            tokens_per_minute: rateLimitObj.recipe_search_tokens_minute,
            requests_per_minute: rateLimitObj.recipe_search_requests_minute,
        },
        {
            name: "/recipe/classification",
            tokens_per_day: rateLimitObj.recipe_classification_tokens_day,
            requests_per_day: rateLimitObj.recipe_classification_requests_day,
            tokens_per_minute: rateLimitObj.recipe_classification_tokens_minute,
            requests_per_minute: rateLimitObj.recipe_classification_requests_minute,
        },
        {
            name: "/recipe/generate",
            tokens_per_day: rateLimitObj.recipe_generate_tokens_day,
            requests_per_day: rateLimitObj.recipe_generate_requests_day,
            tokens_per_minute: rateLimitObj.recipe_generate_tokens_minute,
            requests_per_minute: rateLimitObj.recipe_generate_requests_minute,
        },
        {
            name: "/recipe/image",
            tokens_per_day: rateLimitObj.recipe_image_tokens_day,
            requests_per_day: rateLimitObj.recipe_image_requests_day,
            tokens_per_minute: rateLimitObj.recipe_image_tokens_minute,
            requests_per_minute: rateLimitObj.recipe_image_requests_minute,
        },
        {
            name: "/recipe/info",
            tokens_per_day: rateLimitObj.recipe_info_tokens_day,
            requests_per_day: rateLimitObj.recipe_info_requests_day,
            tokens_per_minute: rateLimitObj.recipe_info_tokens_minute,
            requests_per_minute: rateLimitObj.recipe_info_requests_minute,
        },
        {
            name: "/recipe/chat",
            tokens_per_day: rateLimitObj.recipe_chat_tokens_day,
            requests_per_day: rateLimitObj.recipe_chat_requests_day,
            tokens_per_minute: rateLimitObj.recipe_chat_tokens_minute,
            requests_per_minute: rateLimitObj.recipe_chat_requests_minute,
        },
        {
            name: "/recipe/recognition",
            tokens_per_day: rateLimitObj.recipe_recognition_tokens_day,
            requests_per_day: rateLimitObj.recipe_recognition_requests_day,
            tokens_per_minute: rateLimitObj.recipe_recognition_tokens_minute,
            requests_per_minute: rateLimitObj.recipe_recognition_requests_minute,
        },
        {
            name: "/recipe/suggest",
            tokens_per_day: rateLimitObj.recipe_suggest_tokens_day,
            requests_per_day: rateLimitObj.recipe_suggest_requests_day,
            tokens_per_minute: rateLimitObj.recipe_suggest_tokens_minute,
            requests_per_minute: rateLimitObj.recipe_suggest_requests_minute,
        },
    ];
};


const RateLimitsAccordionTable = ({rate_limit_tier = "null"}) => {
    const theme = useMantineTheme();
  const { classes } = useStyles();
  const [areExtraRowsVisible, setAreExtraRowsVisible] = useState(false);
  const alwaysVisibleRows = 5;

  const {apiClient} = useAuth();

  // get table row
    const [tableRows, setTableRows] = useState<RateLimitItem[]>([]);
    const [loadingRateLimits, setLoadingRateLimits] = useState(true);
    const fetchRateLimits = async () => {
      try {
          setLoadingRateLimits(true);
        const baseURL = process.env.REACT_APP_BASE_URL;
        const response = await apiClient.get(`${baseURL}/api/rate-limit/current`, {
          withCredentials: true,
        });
        const usageTier: number = Number(response.data.name.match(/\d+/)?.[0]);


        switch (usageTier) {
            case 0:
                rate_limit_tier = "Free";
                break;
            case 1:
                rate_limit_tier = "Tier 1";
                break;
            case 2:
                rate_limit_tier = "Tier 2";
                break;
            case 3:
                rate_limit_tier = "Tier 3";
                break;
            case 4:
                rate_limit_tier = "Tier 4";
                break;
            case 5:
                rate_limit_tier = "Tier 5";
                break;
            default:
                rate_limit_tier = "Unknown";
                break;
        }
        let rateLimit_data = rate_limit_tiers[rate_limit_tier];
        let rateLimitItems = getRateLimitData(rateLimit_data);
        setTableRows(rateLimitItems);
      } catch (error) {
        console.error('Failed to fetch rate limits: ', error);
      } finally {
          setLoadingRateLimits(false);
      }
    };

    useEffect(() => {
        if (rate_limit_tier !== "null") {
            let rateLimit_data = rate_limit_tiers[rate_limit_tier];
            let rateLimitItems = getRateLimitData(rateLimit_data);
            setTableRows(rateLimitItems);
        }
        else {
            fetchRateLimits();
        }
      }, [rate_limit_tier]);


  return (
      <div>
          {loadingRateLimits ? (
            <Center mt={170} mb={170}>
                <Loader size={"lg"}/>
            </Center>
        ) : (

              <div className={classes.tableContainer}>

                  <Table striped highlightOnHover>
                      <thead>
                      <tr>
                          <th style={{padding: '10px', textAlign: 'left'}}>API</th>
                          <th style={{padding: '10px', textAlign: 'right'}}>TPM</th>
                          <th style={{padding: '10px', textAlign: 'right'}}>RPM</th>
                          <th style={{padding: '10px', textAlign: 'right'}}>TPD</th>
                          <th style={{padding: '10px', textAlign: 'right'}}>RPD</th>
                      </tr>
                      </thead>
                      <tbody>
                      {tableRows.slice(0, areExtraRowsVisible ? tableRows.length : alwaysVisibleRows).map((row) => (
                          <tr key={row.name}>
                              <td style={{
                                  padding: '10px',
                                  textAlign: 'left'
                              }}>{row.name}</td>
                              <td style={{
                                  padding: '10px',
                                  textAlign: 'right'
                              }}>{row.tokens_per_minute}</td>
                              <td style={{
                                  padding: '10px',
                                  textAlign: 'right'
                              }}>{row.requests_per_minute}</td>
                              <td style={{
                                  padding: '10px',
                                  textAlign: 'right'
                              }}>{row.requests_per_day}</td>
                              <td style={{
                                  padding: '10px',
                                  textAlign: 'right'
                              }}>{row.tokens_per_day}</td>
                          </tr>
                      ))}
                      </tbody>
                  </Table>

                  <Button
                      className={classes.button}
                      onClick={() => setAreExtraRowsVisible(!areExtraRowsVisible)}
                      w={"100%"}
                      bg={theme.colors.dark[7]}
                  >
                      <ChevronDown/> {areExtraRowsVisible ? 'Show Less APIs' : 'Show all APIs'}
                  </Button>
              </div>
          )}

      </div>
  );
};

export default RateLimitsAccordionTable;
