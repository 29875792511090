import {
    Burger,
    Button,
    createStyles, Divider, Drawer, rem, ScrollArea,
    useMantineTheme
} from '@mantine/core';
import {Link, NavLink} from 'react-router-dom';
import Sidebar from "../../componenets/sidebar/sidebar";
import React, {ReactNode} from "react";
import { Group} from '@mantine/core';


import 'react-intl-tel-input/dist/main.css';
import DocsTOC, {DocsHamburgerTOC} from "../Documentation/DocsTableOfContents";
import {useDisclosure} from "@mantine/hooks";


const useStyles = createStyles((theme) => ({
    pageLine: {
        width: '100%',      // Maintain full width
        borderBottom: `1px solid ${theme.colors.gray[8]}`, // Add a bottom border
        margin: 0,
        padding: 0,
    },
        link: {
        display: 'flex',
        alignItems: 'center',
        height: '100%',
        paddingLeft: theme.spacing.md,
        paddingRight: theme.spacing.md,
        textDecoration: 'none',
        color: theme.colorScheme === 'dark' ? theme.colors.white : theme.colors.black,
        fontWeight: 500,
        fontSize: theme.fontSizes.sm,

        [theme.fn.smallerThan('sm')]: {
            height: rem(42),
            display: 'flex',
            alignItems: 'center',
            width: '100%',
        },

        ...theme.fn.hover({
            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
        }),
    },
        hiddenMobile: {
        [`@media (max-width: ${1200}px)`]: {
            display: 'none',
        },
    },

    hiddenDesktop: {
        [`@media (min-width: ${1200}px)`]: {
            display: 'none',
        },
    },
    headerButton: {
      fontSize: 18,
        [`@media (max-width: ${600}px)`]: {
            fontSize: 14
        },
        [`@media (max-width: ${375}px)`]: {
            fontSize: 12
        },

    },

    childPage: {
        marginLeft: "230px",
        marginTop: "54px",
        [`@media (max-width: ${1200}px)`]: {
            fontSize: 14,
            marginLeft: "0px",
        },
    }
}));


interface DocsPageProps {
    children?: ReactNode;
    activeItem?: string;
}

export function DocsPage({ children, activeItem = "Overview"}: DocsPageProps) {
    const theme = useMantineTheme();
    const { classes } = useStyles();

    // Burger
    const [drawerOpened, { toggle: toggleDrawer, close: closeDrawer }] = useDisclosure(false);

    return (
        <div>
            <Sidebar defaultExpanded={false} activeItem="documentation"/>

            <div style={{marginLeft: "60px"}}>

                {/*header*/}
                <Group  m={0} p={0} position="apart" spacing="sm" style={{
                    backgroundColor: theme.colors.dark[8],
                    position: 'fixed',
                    width: "calc(100% - 70px)",
                    zIndex: 1000,
                }}>
                    {/*nav links*/}
                    <div>
                        <NavLink to="/docs/overview" style={{
                            textDecoration: 'none',
                            // paddingBottom: 14,
                            margin: 0,
                            padding: 0
                        }}>
                            <Button className={classes.headerButton} h={50}
                                    style={{
                                        color: theme.colors.gray[1],
                                        borderBottom: "3px solid #FFF",
                            }}
                                    variant="subtle" radius={0} component="span"
                                    onClick={() => {
                                    }}>Documentation</Button>
                        </NavLink>
                        <NavLink to="/docs/api-reference" style={{textDecoration: 'none'}}>
                            <Button className={classes.headerButton} h={50}
                                    style={{color: theme.colors.gray[1]}}
                                    variant="subtle" radius={0} component="span"
                                    onClick={() => {
                                    }}>API reference</Button>
                        </NavLink>
                    </div>
                        {/* Enhance clickability by wrapping Burger in a div with adequate padding */}
                    <div onClick={toggleDrawer}
                         style={{
                             display: 'flex',
                             margin: 0,
                             padding: 0,
                    }}>
                        <Burger
                            opened={drawerOpened}
                            className={classes.hiddenDesktop}
                        />
                    </div>

                </Group>
                <Drawer
                    opened={drawerOpened}
                    onClose={closeDrawer}
                    size="100%"
                    padding="md"
                    title="NutriCloud"
                    className={classes.hiddenDesktop}
                    zIndex={1000000}
                >
                    <ScrollArea h={`calc(100vh - ${rem(60)})`} mx="-md">
                        <Divider my="sm"
                                 color={theme.colorScheme === 'dark' ? 'dark.5' : 'gray.1'}/>
                        <DocsHamburgerTOC defaultExpanded={true}
                                          activeItem="documentation"/>
                        <Divider my="sm"
                                 color={theme.colorScheme === 'dark' ? 'dark.5' : 'gray.1'}/>
                    </ScrollArea>
                </Drawer>

                <div className={classes.pageLine}></div>

                {/*TOC*/}
                <div className={classes.hiddenMobile}>
                    <DocsTOC defaultExpanded={true} activeItem={activeItem}/>
                </div>

                {/*children components*/}
                <div className={classes.childPage}>
                    {children}
                </div>
            </div>
        </div>
    );
}

export default DocsPage;
