import {
    createStyles,
    Box,
    Flex,
    Text,
    Divider,
    SegmentedControl,
    Tooltip, useMantineTheme
} from '@mantine/core';
import React, {useEffect, useState} from "react";
import {Clipboard, Info} from "react-feather";
import { Modal, TextInput, Group, Button } from '@mantine/core';
import axios from "axios";
import {useAuth} from "../../AuthContext";
import {ApiKey} from "./ApiKeysTable";
import {notifications, showNotification} from "@mantine/notifications";
import {PermissionKey, Permissions} from "./SelectPermissions";

const useStyles = createStyles((theme) => ({
  container: {
    width: '100%',
    borderRadius: theme.radius.sm,
  },
  row: {
    display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    '&:not(:last-child)': {
      marginBottom: theme.spacing.xs,
    },
  },
  leftContent: {
    flex: 1,
    marginRight: theme.spacing.xs,
  },
  rightContent: {
    flex: 1,
    marginLeft: theme.spacing.md,
      textAlign: 'right',
  },
        selectableButton: {
            backgroundColor: theme.colors.dark[7],
            ':hover': {
            cursor: 'pointer',
            backgroundColor: hexToRGBA(theme.colors.orange[0], 0.5)
        }
    },
}));

const hexToRGBA = (hex: string, alpha = 1) => {
  const [r, g, b] = hex.match(/\w\w/g)!.map((x) => parseInt(x, 16));
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};
interface endpointDataType {
    apiPath: string;
    official_name: string;
    tooltip: string;
    endpoints: string[];
}

const endpointData: endpointDataType[] = [
    {
        apiPath: 'Classify',
        official_name: 'classify',
        tooltip: 'Analyzes and categorizes food-related data.',
        endpoints: [
            '/classify'
        ]
    },
    {
        apiPath: 'Info',
        official_name: 'info',
        tooltip: 'Provides comprehensive culinary information.',
        endpoints: [
            '/seasonality',
            '/locality',
            '/history',
            '/equipment',
            '/required-ingredients',
            '/nutrition',
            '/base-ingredient',
        ]
    },
    {
        apiPath: 'Chat',
        official_name: 'chat',
        tooltip: 'Enables intelligent food-related conversations.',
        endpoints: [
            '/chat'
        ]
    },
    {
        apiPath: 'Suggest',
        official_name: 'suggest',
        tooltip: 'Offers personalized culinary recommendations.',
        endpoints: [
            '/recipe',
            '/substitute',
            '/pairing',
            '/from-ingredients',
        ]
    },
    {
        apiPath: 'Image',
        official_name: 'image',
        tooltip: 'Processes and generates food imagery and recipes.',
        endpoints: [
            '/recognition',
            '/generate',
            '/ingredients-in-image',
            '/recipe',
        ]
    },
    {
        apiPath: 'Search',
        official_name: 'search',
        tooltip: 'Facilitates efficient food and recipe discovery.',
        endpoints: [
            '/autocomplete',
            '/search',
            '/name-standardization',
        ]
    },
    {
        apiPath: 'Generate',
        official_name: 'generate',
        tooltip: 'Creates customized culinary content.\n',
        endpoints: [
            '/image',
            '/ingredients',
            '/instructions',
            '/tips',
            '/cutting-instructions'
        ]
    },
    {
        apiPath: 'Users',
        official_name: 'user',
        tooltip: 'Authenticate, validate, and build profiles to manage users and their access to the API.',
        endpoints: [
            '/login',
            '/signup',
            '/verify-email',
            '/verify-phone',
            '/like-recipe',
            '/dislike-recipe',
            '/favorite-recipe',
        ]
    },
];


type ApiKeyPermissionsSelectorProps = {
    selectedPermissions: Permissions;
};


// Usage in your component
const ApiKeyPermissionsSelector: React.FC<ApiKeyPermissionsSelectorProps> = ({selectedPermissions}) => {
      const { classes } = useStyles();
      const theme = useMantineTheme();

      if (!selectedPermissions) {
          return null;
      }

  return (
    <div>
        <Box className={classes.container}>
            <Flex className={classes.row}>
                <Text fz={12} fw={600}>Resources</Text>
                <Text fz={12} fw={600}>Permissions</Text>
            </Flex>
            <Divider my="xs" />

          {endpointData.map((path, index) => (
            <div key={index}>
              <Flex className={classes.row}>
                  {/*left*/}
                  <div>
                      {/*title*/}
                      <div style={{display: "flex"}}>
                        <Text mr={5} fz={16} fw={600}>{path.apiPath}</Text>
                        <Tooltip
                          label={path.tooltip}
                          // className={classes.toolTip}
                          position={'top'}
                          transitionProps={{ transition: 'scale', duration: 200 }}
                          multiline
                          w={300}
                          radius={"xs"}
                          p={20}
                          fw={500}
                          fz={'14px'}
                          ml={15}

                          offset={-5}
                          bg={theme.colors.gray[9]}
                          color={theme.colors.gray[8]}
                        >
                          <div style={{display: 'inline-block'}}>
                            <Info size={16} color={'#666666'}/>
                          </div>
                        </Tooltip>
                      </div>
                        {/*endpoints*/}
                      {path.endpoints.map((endpoint, i) => (
                        <Text key={i} fz={12} color={theme.colors.gray[6]}>{endpoint}</Text>
                      ))}
                  </div>
                  {/*right*/}
                  <div>
                      <div className={classes.rightContent}>
                        <SegmentedControl
                            disabled={true}
                          size={'xs'}
                          value={selectedPermissions[path.official_name as PermissionKey] ? 'allowed' : 'denied'}
                          data={[
                            { value: 'denied', label: 'Denied' },
                            { value: 'allowed', label: 'Allowed' },
                          ]}
                          styles={(theme) => ({
                            label: {
                              paddingLeft: 13,
                              paddingRight: 13,
                              paddingTop: 5,
                              paddingBottom: 5,
                            },

                          })}
                        />
                    </div>
                  </div>
              </Flex>
              {index < endpointData.length - 1 && <Divider my="xs" />}
            </div>
          ))}
        </Box>
    </div>
  );
};



interface EditApiKeyModalProps {
    createModalOpened: boolean;
    setCreateModalOpened: (value: boolean) => void;
    apiKey: ApiKey | undefined;
}

export const ViewApiKey: React.FC<EditApiKeyModalProps> = ({
    createModalOpened,
    setCreateModalOpened,
    apiKey,
}) => {
  const theme = useMantineTheme();
    const { classes } = useStyles();

    const [apiKeyName, setApiKeyName] = useState('');
    const [selectedPermissionLevel, setSelectedPermissionLevel] = useState('Restricted');
    const permissionLevelOptions = ["All", "Restricted"];
    const {accessToken} = useAuth();

    if (!apiKey) {
        return null;
    }

    const copyToClipboard = (text: string | undefined) => {
        if (!text) {
            return;
        }
      navigator.clipboard.writeText(text).then(() => {
        // Handle success feedback here
        showNotification({
          title: 'Copied!',
          message: 'API key copied to clipboard.',
          autoClose: 3000, // Adjust timing as needed
        });
        notifications.cleanQueue()
        console.log('API key copied to clipboard');
      }, (err) => {
        // Handle errors here
        console.error('Failed to copy API key', err);
      });
    };



  return (
        <Modal
          opened={createModalOpened}
          onClose={() => setCreateModalOpened(false)}
          title={<div style={{fontWeight: 600, color: theme.colors.gray[1], fontSize: '24px'}}>API Key Info</div>}
          size={525}
          centered={true}
         >
            <div style={{padding: '20px'}}>
                <Text size="sm" style={{
                    marginBottom: '10px',
                    color: theme.colors.gray[3],
                    fontWeight: 500
                }}>Name</Text>
                <TextInput
                    placeholder=""
                    value={apiKey?.name}
                    onChange={(event) => setApiKeyName(event.currentTarget.value)}
                    style={{marginBottom: '20px'}}
                    radius="sm" // Rounded corners
                    disabled={true}
                />
                <Text size="sm" style={{
                    marginBottom: '10px',
                    color: theme.colors.gray[3],
                    fontWeight: 500
                }}>ApiKey</Text>

                <div style={{display: 'flex', width: '100%', marginBottom: '20px'}}>
                    <TextInput
                        placeholder=""
                        value={apiKey?.key}
                        radius="sm"
                        disabled={true}
                        style={{flex: 1, marginRight: '10px'}}
                    />
                    <button
                        onClick={() => copyToClipboard(apiKey?.key)}
                        className={classes.selectableButton}
                        style={{
                            border: 'none',
                            cursor: 'pointer',
                            padding: '5px',
                            borderRadius: '4px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <Clipboard size={16}/>
                    </button>
                </div>

                <div style={{marginBottom: '20px'}}>
                    <Text size="sm" style={{marginBottom: '5px'}}>Permissions</Text>
                    {/*<SegmentedControl*/}
                    {/*    value={selectedPermissionLevel}*/}
                    {/*    data={permissionLevelOptions}*/}
                    {/*    style={{marginBottom: 20}}*/}
                    {/*    disabled={true}*/}
                    {/*/>*/}

                    {selectedPermissionLevel === 'All' && (
                        <div></div>
                    )}
                    {selectedPermissionLevel === 'Restricted' && (
                        <ApiKeyPermissionsSelector
                            selectedPermissions={apiKey?.permissions}/>
                    )}

                </div>
            </div>
        </Modal>
  );
};

export default ViewApiKey;
