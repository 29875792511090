import React, { useState } from 'react';
import { useSpring, animated } from 'react-spring';
import { createStyles } from '@mantine/core';

const useStyles = createStyles((theme) => ({
  container: {
    width: '100%',
    height: '100%',
    position: 'relative', // Add position relative to the container
  },
  card: {
    width: '100%',
    height: '100%',
    borderRadius: '20px',
    backgroundSize: 'cover',
            overflow: 'hidden',

    backgroundPosition: 'center center',
    boxShadow: '0px 10px 30px -5px rgba(0, 0, 0, 0.3)',
    transition: 'box-shadow 0.5s',
    backgroundColor: theme.colors.dark[6],
    willChange: 'transform',
    border: `1px solid ${theme.colors.gray[7]}`,
    '&:hover': {
      boxShadow: '0px 30px 100px -10px rgba(0, 0, 0, 0.4)',
    },
    position: 'relative',
  },
  bloomEffect: {
    position: 'absolute',
    borderRadius: '50%',
    width: '1200px',
    height: '1200px',
    pointerEvents: 'none',
    transform: 'translate(-50%, -50%)',
    zIndex: 100,
  },
}));

const trans = (x: number, y: number, s: number) => `perspective(600px) rotateX(${x}deg) rotateY(${y}deg) scale(${s})`;

const calc = (x: number, y: number, rect: DOMRect) => {
  const xRel = x - (rect.left + rect.width / 2);
  const yRel = y - (rect.top + rect.height / 2);
  const yRotate = xRel / rect.width * 2;
  const xRotate = -yRel / rect.height * 2;

  return [xRotate, yRotate, 1];
};

interface ThreeDContainerProps {
    bloomColor?: string;
  children: React.ReactNode;
}

const ThreeDContainer: React.FC<ThreeDContainerProps> = ({ children, bloomColor }) => {
  const { classes } = useStyles();
  const [props, set] = useSpring(() => ({
    xys: [0, 0, 1],
    config: { mass: 5, tension: 350, friction: 40 },
  }));
  const [bloomPosition, setBloomPosition] = useState({ x: 0, y: 0 });
  const [isHovering, setIsHovering] = useState(false);

  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const rect = e.currentTarget.getBoundingClientRect();
    setBloomPosition({
      x: e.clientX - rect.left,
      y: e.clientY - rect.top,
    });
  };

  return (
    <div className={classes.container}>
      <animated.div
        className={classes.card}
        onMouseMove={(e) => {
          const rect = e.currentTarget.getBoundingClientRect();
          const { clientX: x, clientY: y } = e;
          setBloomPosition({ x: x - rect.left, y: y - rect.top });
          setIsHovering(true);
          return set({ xys: calc(x, y, rect) });
        }}
        onMouseLeave={() => {
          setIsHovering(false);
          set({ xys: [0, 0, 1] });
        }}
        style={{
          transform: props.xys.to(trans),
        }}
      >
        {children}
        {isHovering && (
          <div
            className={classes.bloomEffect}
            style={{
                background: bloomColor,
              left: `${bloomPosition.x}px`,
              top: `${bloomPosition.y}px`,
            }}
          />
        )}
      </animated.div>
    </div>
  );
};

export default ThreeDContainer;
