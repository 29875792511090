import React, {useEffect, useState} from 'react';
import {
    Box,
    ThemeIcon,
    Text,
    useMantineTheme,
    Stack,
    Tooltip,
    Badge, createStyles, Popover, Col, Grid
} from '@mantine/core';
import {Link, useNavigate} from 'react-router-dom';
import { FaHome, FaCog, FaSignOutAlt /* other icons */ } from 'react-icons/fa';
import NutricloudLogo from "../logo/NutriCloudLogo";
import {
    BarChart2,
    Archive,
    Briefcase,
    Code,
    Database,
    User,
    Key,
    LogOut,
    Settings, Book, HelpCircle, CreditCard, ChevronsUp, Layers, LogIn
} from 'react-feather';
import {useAuth} from "../../AuthContext";
import {useMediaQuery} from "@mantine/hooks";
import {IconPuzzle} from "@tabler/icons-react";

// Add a new prop to SidebarItemProps
interface SidebarItemProps {
  icon: React.ReactNode;
  label: string;
  to: string;
  isExpanded: boolean;
  isHighlighted?: boolean; // new prop to indicate permanent highlight
}

const hexToRGBA = (hex: string, alpha = 1) => {
  const [r, g, b] = hex.match(/\w\w/g)!.map((x) => parseInt(x, 16));
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

const SidebarItem: React.FC<SidebarItemProps> = ({ icon, label, to, isExpanded, isHighlighted }) => {
    const [hover, setHover] = useState(false);
    const theme = useMantineTheme();
    // Use theme color for highlighting. Adjust the color index as needed.
    const highlightColor = hexToRGBA(theme.colors.orange[1], 0.2);
    const hoverColor = hexToRGBA(theme.colors.gray[1], 0.2); // Adjust if you have a specific hover color

    // Determine the background color based on isHighlighted and hover state
    const backgroundColor = isHighlighted ? highlightColor :
                            hover ? hexToRGBA(theme.colors.blue[1], 0.1) : 'transparent';

    return (
        <Link
            to={to}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            style={{
                textDecoration: 'none',
                display: 'flex',
                alignItems: 'center',
                marginLeft: '10px',
                marginRight: '10px',
                marginTop: '0px',
                height: '40px',
                backgroundColor: backgroundColor, // Apply the determined background color
                borderRadius: '8px',
                transition: 'background-color 0.2s',
                padding: '0 10px',
            }}
        >
            <ThemeIcon variant="white" size={20} mr="xs">
                {icon}
            </ThemeIcon>
            <div style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: isExpanded ? '140px' : '0',
                transition: 'max-width 0.3s ease',
            }}>
                <Text size="sm" color={isHighlighted ? 'white' : theme.colors.gray[1]} style={{ lineHeight: '1em' }}>
                    {label}
                </Text>
            </div>
        </Link>
    );
};


const ExpandedSidebarItem: React.FC<SidebarItemProps> = ({ icon, label, to, isExpanded, isHighlighted }) => {
    const [hover, setHover] = useState(false);
    const theme = useMantineTheme();
    // Use theme color for highlighting. Adjust the color index as needed.
    const highlightColor = hexToRGBA(theme.colors.orange[1], 0.2);
    const hoverColor = hexToRGBA(theme.colors.gray[1], 0.2); // Adjust if you have a specific hover color

    // Determine the background color based on isHighlighted and hover state
    const backgroundColor = isHighlighted ? highlightColor :
                            hover ? hexToRGBA(theme.colors.blue[1], 0.1) : 'transparent';

    return (
        <Link
            to={to}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            style={{
                textDecoration: 'none',
                display: 'flex',
                alignItems: 'center',
                height: '40px',
                backgroundColor: backgroundColor, // Apply the determined background color
                borderRadius: '8px',
                transition: 'background-color 0.2s',
                padding: '0 10px',
            }}
        >
            <ThemeIcon variant="white" size={20} mr="xs">
                {icon}
            </ThemeIcon>
            <div style={{
                // whiteSpace: 'nowrap',
                // overflow: 'hidden',
                // textOverflow: 'ellipsis',
                maxWidth: isExpanded ? '400px' : '0',
                transition: 'max-width 0.3s ease',
            }}>
                <Text size="sm" color={isHighlighted ? 'white' : theme.colors.gray[1]} style={{ lineHeight: '1em' }}>
                    {label}
                </Text>
            </div>
        </Link>
    );
};


const DisabledSidebarItem: React.FC<SidebarItemProps> = ({ icon, label, to, isExpanded, isHighlighted }) => {
    const [hover, setHover] = useState(false);
    const theme = useMantineTheme();
    // Use theme color for highlighting. Adjust the color index as needed.
    const highlightColor = hexToRGBA(theme.colors.orange[1], 0.2);
    const hoverColor = hexToRGBA(theme.colors.gray[1], 0.2); // Adjust if you have a specific hover color

    // Determine the background color based on isHighlighted and hover state
    const backgroundColor = isHighlighted ? highlightColor :
                            hover ? hexToRGBA(theme.colors.blue[1], 0.1) : 'transparent';

    return (
        <div
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            style={{
                textDecoration: 'none',
                display: 'flex',
                alignItems: 'center',
                height: '40px',
                backgroundColor: backgroundColor, // Apply the determined background color
                borderRadius: '8px',
                transition: 'background-color 0.2s',
                padding: '0 10px',
            }}
        >
            <ThemeIcon variant="white" size={20} mr="xs">
                {icon}
            </ThemeIcon>
            <div style={{
                // whiteSpace: 'nowrap',
                // overflow: 'hidden',
                // textOverflow: 'ellipsis',
                maxWidth: isExpanded ? '400px' : '0',
                transition: 'max-width 0.3s ease',
            }}>
                <Text size="sm" color={isHighlighted ? 'white' : theme.colors.gray[1]} style={{ lineHeight: '1em' }}>
                    {label}
                </Text>
            </div>
        </div>
    );
};

interface SidebarProps {
    defaultExpanded?: boolean;
    activeItem: string;
    shrinkSize?: number;
}

const Sidebar: React.FC<SidebarProps> = ({defaultExpanded = false, activeItem, shrinkSize = 800}) => {
    const isMobile = useMediaQuery(`(max-width: ${shrinkSize}px)`);
    const [isExpanded, setIsExpanded] = useState(defaultExpanded);
        const { logout, name, email, isAuthenticated } = useAuth();

    useEffect(() => {
        // Update isExpanded based on isMobile and defaultExpanded
        if (isMobile) {
            setIsExpanded(false);
        } else {
            setIsExpanded(defaultExpanded);
        }
    }, [isMobile, defaultExpanded]);

    const theme = useMantineTheme();
    const iconColor = theme.colors.orange[1];

    const handleMouseLeave = () => {
        // Check if the screen is mobile before collapsing the sidebar
        if (isMobile || !defaultExpanded) {
            setIsExpanded(false);
        }
    };

        const AccountSidebarItem: React.FC<SidebarItemProps> = ({ icon, label, isExpanded, isHighlighted }) => {
    const [hover, setHover] = useState(false);
    const [opened, setOpened] = useState(false);
    const theme = useMantineTheme();
            const { isAuthenticated, logout, login } = useAuth();


    const highlightColor = theme.fn.rgba(theme.colors.orange[1], 0.2);
    const hoverColor = theme.fn.rgba(theme.colors.gray[1], 0.2);
    const backgroundColor = isHighlighted ? highlightColor :
                            hover ? theme.fn.rgba(theme.colors.blue[1], 0.1) : 'transparent';
    const navigate = useNavigate();
    return (
        <div>

            {isAuthenticated ? (
                <Popover
                opened={opened}
                onClose={() => setOpened(false)}
                position="bottom"
                withArrow
                radius="xs"
                width={220}

            >
                <Popover.Target>
                    <div
                        onClick={() => setOpened(o => !o)}
                        onMouseEnter={() => setHover(true)}
                        onMouseLeave={() => setHover(false)}
                        style={{
                            textDecoration: 'none',
                            display: 'flex',
                            alignItems: 'center',
                            marginLeft: '10px',
                            marginRight: '10px',
                            marginTop: '0px',
                            height: '40px',
                            backgroundColor: backgroundColor,
                            borderRadius: '8px',
                            transition: 'background-color 0.2s',
                            padding: '0 10px',
                            cursor: 'pointer',
                        }}
                    >
                        <ThemeIcon variant="white" size={20} mr="xs">
                            {icon}
                        </ThemeIcon>
                        <div style={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            maxWidth: isExpanded ? '140px' : '0',
                            transition: 'max-width 0.3s ease',
                        }}>
                            <Text size="sm"
                                  color={isHighlighted ? 'white' : theme.colors.gray[1]}
                              style={{lineHeight: '1em'}}>
                            {label}
                        </Text>
                    </div>
                </div>
            </Popover.Target>
            <Popover.Dropdown>
                {isAuthenticated ? (
                    <Grid>
                    {/*email*/}
                    <Col>
                        <Text size="sm" color={theme.colors.gray[1]} style={{lineHeight: '1em'}}>
                            {name}
                        </Text>
                        <Text size="xs" color={theme.colors.gray[1]} style={{lineHeight: '1em'}}>
                            {email}
                        </Text>
                        <div style={{
                            borderBottom: `1px solid ${theme.colors.gray[7]}`,
                            marginTop: 10,
                            marginLeft: -10,
                            marginRight: -10,
                        }}></div>
                    </Col>
                    <div style={{
                        margin: 0,
                        padding: 0,
                        marginLeft: -10,
                        marginRight: -10,
                        width: '200%',
                    }}>
                        <ExpandedSidebarItem icon={<User style={{ color: iconColor }}/>} label="Profile" to="/account/profile" isExpanded={true}
                                     isHighlighted={isHighlighted}/>
                        <ExpandedSidebarItem icon={<Briefcase style={{ color: iconColor }}/>} label="Terms & policies" to="/policies" isExpanded={true}
                                     isHighlighted={isHighlighted}/>
                        <div onClick={() => logout()}>
                            <DisabledSidebarItem icon={<LogOut style={{ color: iconColor }}/>} label="Logout" to="/logout" isExpanded={true}
                                         isHighlighted={isHighlighted}/>
                        </div>
                    </div>
                </Grid>
                ) : null}
            </Popover.Dropdown>
        </Popover>
            ) : (
                <div
                    onClick={() => setOpened(o => !o)}
                    onMouseEnter={() => setHover(true)}
                    onMouseLeave={() => setHover(false)}
                    style={{
                        textDecoration: 'none',
                        display: 'flex',
                        alignItems: 'center',
                        marginLeft: '10px',
                        marginRight: '10px',
                        marginTop: '0px',
                        height: '40px',
                        backgroundColor: backgroundColor,
                        borderRadius: '8px',
                        transition: 'background-color 0.2s',
                        padding: '0 10px',
                        cursor: 'pointer',
                    }}
                >
                    <ThemeIcon variant="white" size={20} mr="xs">
                        {icon}
                    </ThemeIcon>
                    <div style={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        maxWidth: isExpanded ? '140px' : '0',
                        transition: 'max-width 0.3s ease',
                    }}>
                        <Text size="sm"
                              color={isHighlighted ? 'white' : theme.colors.gray[1]}
                              style={{lineHeight: '1em'}}
                              onClick={() => navigate('/login')}
                        >
                            Login
                        </Text>
                    </div>
                </div>
            )}
        </div>
    );
};

    return (
        <Box
            onMouseEnter={() => setIsExpanded(true)}
            onMouseLeave={handleMouseLeave} // Updated to use the handler function
            style={{
                position: 'fixed',
                left: 0,
                top: 0,
                width: isExpanded ? 200 : 60,
                height: '100vh',
                backgroundColor: theme.colors.dark[8],
                transition: 'width 0.3s',
                // overflow: 'hidden',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                zIndex: 100, borderRight: '1px solid rgba(134, 142, 150, 0.3)',
            }}
        >
            <Stack spacing="xs">
                {/* Top-aligned items */}
                <div
                    style={{marginLeft: '5px', marginTop: '15px', marginBottom: '5px'}}>
                    <NutricloudLogo size={50}/>
                </div>
                <SidebarItem icon={<Code style={{color: iconColor}}/>}
                             label="Playground" to="/playground" isExpanded={isExpanded} isHighlighted={activeItem === "playground"}  />
          <SidebarItem icon={<Layers style={{ color: iconColor }}/>} label="Apps" to="/apps" isExpanded={isExpanded} isHighlighted={activeItem === "data"} />
          <SidebarItem icon={<Key style={{ color: iconColor }}/>} label="Api Keys" to="/api-keys" isExpanded={isExpanded} isHighlighted={activeItem === "api-keys"} />
          <SidebarItem icon={<BarChart2 style={{ color: iconColor }}/>} label="Usage" to="/usage" isExpanded={isExpanded} isHighlighted={activeItem === "usage"} />
            <SidebarItem icon={<Settings style={{ color: iconColor }} />} label="Settings" to="/account/profile" isExpanded={isExpanded} isHighlighted={activeItem === "setting"}/>

            {/* Conditional Settings items */}
              {/* Conditional rendering for settings-related items */}
            {["settings", "profile", "billing", "limits"].includes(activeItem) && (
                <>
                    <SidebarItem icon={<User style={{ color: theme.colors.gray[5] }} />} label="Profile" to="/account/profile" isExpanded={isExpanded} isHighlighted={activeItem === "profile"} />
                    <SidebarItem  icon={<CreditCard style={{ color: theme.colors.gray[5] }} />} label="Billing" to="/account/billing/overview" isExpanded={isExpanded} isHighlighted={activeItem === "billing"} />
                    <SidebarItem  icon={<ChevronsUp style={{ color: theme.colors.gray[5] }} />} label="Limits" to="/account/limits" isExpanded={isExpanded} isHighlighted={activeItem === "limits"} />
                </>
            )}
      </Stack>

        <Stack spacing="xs">
            {/* Bottom-aligned items */}
            <SidebarItem icon={<Book style={{color: iconColor}}/>} label="Documentation"
                         to="/docs/overview" isExpanded={isExpanded} isHighlighted={activeItem === "documentation"} />
            <SidebarItem icon={<IconPuzzle style={{color: iconColor}}/>} label="API Reference"
                         to="/docs/api-reference" isExpanded={isExpanded} isHighlighted={activeItem === "api-reference"} />
            <SidebarItem icon={<HelpCircle style={{color: iconColor}}/>} label="Help"
                         to="/help" isExpanded={isExpanded} isHighlighted={activeItem === "help"} />
            <div style={{
                height: '1px',
                background: 'rgba(134, 142, 150, 0.3)',
                marginTop: theme.spacing.sm,
                marginLeft: '15px',
                marginRight: '15px',
            }}></div>
            <AccountSidebarItem icon={<User style={{color: iconColor}}/>} label="Account"
                         to="/account" isExpanded={isExpanded} isHighlighted={activeItem === "account"} />
            <div style={{marginBottom: "10px"}}></div>
        </Stack>
    </Box>

  );
};

export default Sidebar;
