import React, {useEffect, useState} from 'react';
import {createStyles, SegmentedControl, rem, Select, MantineSize} from '@mantine/core';
import { useNavigate } from 'react-router-dom';

const useStyles = createStyles((theme) => ({
    root: {
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.white,
        boxShadow: theme.shadows.md,
        border: `${rem(1)} solid ${
            theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[1]
        }`,
    },

    indicator: {
        backgroundImage: theme.fn.gradient({ from: 'pink', to: 'orange' }),
    },

    header: {
        zIndex: -1,
        position: 'relative',
    },

    label: {
        '&, &:hover': {
            '&[data-active]': {
                color: theme.white,
            },
        },
    },
}));

interface RateLimitSelectorProps {
    selectedValue: string;
    setSelectedValue: any;
}

export const RateLimitSelector: React.FC<RateLimitSelectorProps> = ({ selectedValue, setSelectedValue }) => {
    const { classes } = useStyles();

    const [isNarrow, setIsNarrow] = useState(window.innerWidth < 475);

    useEffect(() => {
        const handleResize = () => {
            setIsNarrow(window.innerWidth < 475);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const data = ["Free", "Tier 1", "Tier 2", "Tier 3", "Tier 4", "Tier 5"];
    const size: MantineSize = isNarrow ? "sm" : "md";

    const handleChange = (value: string) => {
        setSelectedValue(value);
    };

    const getSegementedControl = () => {
        if (isNarrow) {
            return (
               <Select
                    label="Choose an API"
                    value={selectedValue}
                    onChange={handleChange}
                    data={data}
                    w="75vw"

               />
            );
        }

        return (
                <SegmentedControl
                    radius="sm"
                    size={"sm"}
                    data={data}
                    classNames={classes}
                    value={selectedValue}
                    onChange={handleChange}
                />
        );
    }

    return (
        getSegementedControl()
    );
};
