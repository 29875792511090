import React, { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from './AuthContext';
import {Loader} from "@mantine/core";

interface ProtectedElementProps {
    component: React.ComponentType;
}

const ProtectedElement: React.FC<ProtectedElementProps> = ({ component: Component }) => {
    const {
        isAuthenticated,
        isEmailVerified,
        email, isLoading,
        checkAuthentication
    } = useAuth();
    const location = useLocation();
    // const [hasChecked, setHasChecked] = useState(false);

    // useEffect(() => {
    //     const initiateCheck = async () => {
    //         await checkAuthentication();
    //         setHasChecked(true);
    //     };
    //     initiateCheck();
    // }, [checkAuthentication]);

    if (isLoading) {
        return (
            <div style={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
                <Loader size="lg" />
            </div>
        );
    }

    if (!isAuthenticated) {
        console.log('Redirecting to login because user is not authenticated.');
        return <Navigate to="/login" replace state={{ from: location }}/>;
    } else if (!isEmailVerified) {
        console.log('Redirecting to verify email because user is not verified.');
        const url = `/onboarding?email=${encodeURIComponent(email)}`;
        return <Navigate to={url} replace state={{ from: location }}/>;
    }

    return <Component />;
};

export default ProtectedElement;
