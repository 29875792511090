interface Params {
    [key: string]: any;
}

const getKotlinCode = (url: string, parameters: Params, currentParameters: Params): string => {
    let code = `import java.net.HttpURLConnection\nimport java.net.URL\n\nfun main() {\n    val url = URL("${url}")\n\n    with(url.openConnection() as HttpURLConnection) {\n        requestMethod = "POST"\n        setRequestProperty("Content-Type", "application/json; utf-8")\n        setRequestProperty("api-key", "YOUR_NUTRICLOUD_API_KEY")\n        doOutput = true`;

    let data = {...parameters};
    for(let param in data) {
        if(data[param] === currentParameters[param]) {
            delete data[param];
        }
    }

    if(Object.keys(data).length > 0) {
        let kotlinIndent = '    ';
        let dataString = JSON.stringify(data, null, 4).split('\n').map(line => kotlinIndent + line).join('\n');
        code += `\n\n        val jsonInputString = """\n${dataString}\n        """.trimIndent()\n\n        outputStream.write(jsonInputString.toByteArray(Charsets.UTF_8))\n        \n        println("Response Code: $responseCode")\n        \n        disconnect()\n    }\n}`;
    }
    return code;
};

export default getKotlinCode;
