import React from 'react';
import {
    createStyles,
    rem,
    Slider,
    TextInput,
    Text,
    SegmentedControl,
    Button,
    useMantineTheme
} from '@mantine/core';
import { Select, SelectItem } from '@mantine/core';

const useStyles = createStyles((theme) => ({
    inputMargin: {
        marginBottom: '10px',
    },
    titleSize: {
        fontSize: '24x',
        '@media (max-height: 1000px)': {
            fontSize: '16px'
        },
    }
}));

const selectorStyles = createStyles((theme) => ({
    root: {
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.white,
        boxShadow: theme.shadows.md,
        border: `${rem(1)} solid ${
            theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[1]
        }`,
    },

    indicator: {
        backgroundImage: theme.fn.gradient({ from: 'pink', to: 'orange' }),
    },

    control: {
        border: '0 !important',
    },

    label: {
        '&, &:hover': {
            '&[data-active]': {
                color: theme.white,
            },
        },
    },
}));



interface RelationalSearchParametersProps {
    onParamChange: (param: string, value: any) => void;
}

const ImageGenerationParameters: React.FC<RelationalSearchParametersProps> = ({ onParamChange }) => {
    const theme = useMantineTheme();
    const { classes } = useStyles();

    const sizes: SelectItem[] = [
      { value: 'true', label: 'True' },
      { value: 'false', label: 'False' },
    ];

    const searchTypes: SelectItem[] = [
      { value: 'true', label: 'True' },
      { value: 'false', label: 'False' },
    ];

    const orange = "rgb(255, 140, 0)";

    return (
        <div>
            {/*searchTerm*/}
            <Text className={classes.titleSize}>Query</Text>
            <TextInput
                placeholder="Strwaberry poppyseed salad..."
                required
                onChange={(event) => onParamChange('query', event.target.value)}
                className={classes.inputMargin}
            />

            {/* meal type */}
            <div>
                <Text className={classes.titleSize}>Generate Photo</Text>
                <Select
                    data={searchTypes}
                    placeholder="false"
                    radius="sm"
                    size="sm"
                    onChange={(value) => onParamChange('generate_photo', value)}
                    className={classes.inputMargin}
                />
            </div>
            {/*    cuisine*/}
            <div>
                <Text className={classes.titleSize}>Generate Nutrients</Text>
                <Select
                    data={sizes}
                    placeholder="false"
                    radius="sm"
                    size="sm"
                    onChange={(value) => onParamChange('generate_nutrients', value)}
                    className={classes.inputMargin}
                />
            </div>


        </div>
    );
};

export default ImageGenerationParameters;
