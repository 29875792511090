import React from 'react';
import {
    createStyles,
    rem,
    Slider,
    TextInput,
    Text,
    SegmentedControl,
    Button,
    useMantineTheme
} from '@mantine/core';
import { Select, SelectItem } from '@mantine/core';

const useStyles = createStyles((theme) => ({
    inputMargin: {
        marginBottom: '10px',
    },
    titleSize: {
        fontSize: '24x',
        '@media (max-height: 1000px)': {
            fontSize: '16px'
        },
    }
}));

const selectorStyles = createStyles((theme) => ({
    root: {
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.white,
        boxShadow: theme.shadows.md,
        border: `${rem(1)} solid ${
            theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[1]
        }`,
    },

    indicator: {
        backgroundImage: theme.fn.gradient({ from: 'pink', to: 'orange' }),
    },

    control: {
        border: '0 !important',
    },

    label: {
        '&, &:hover': {
            '&[data-active]': {
                color: theme.white,
            },
        },
    },
}));



interface RelationalSearchParametersProps {
    onParamChange: (param: string, value: any) => void;
}

const ImageSearchGeneration: React.FC<RelationalSearchParametersProps> = ({ onParamChange }) => {
    const theme = useMantineTheme();
    const { classes } = useStyles();

      const data_quality_variables: SelectItem[] = [
        { value: 'verified', label: 'Verified' },
        { value: 'unverified', label: 'Unverified' },
        { value: 'all', label: 'All Sources' },
      ];

      const nutrient_variables: SelectItem[] = [
        { value: 'macronutrients', label: 'Macronutrients' },
        { value: 'micronutrients_simplified', label: 'Simplified micronutrients' },
        { value: 'micronutrients_full', label: 'Full micronutrients' },
      ];



    const orange = "rgb(255, 140, 0)";

    return (
        <div>
            {/*limit*/}
            <div>
                <Text className={classes.titleSize}>Limit</Text>
                <Slider
                    defaultValue={10}
                    min={1}
                    max={100}
                    label={(value) => value.toFixed(0)}
                    onChangeEnd={(value) => onParamChange('limit', value)}
                    className={classes.inputMargin}
                />
            </div>

            {/*threshold*/}
            <div>
                <Text className={classes.titleSize}>Threshold</Text>
                <Slider
                    defaultValue={0.5}
                    min={0}
                    max={1}
                    step={0.01}
                    label={(value) => value.toFixed(2)}
                    onChangeEnd={(value) => onParamChange('threshold', value)}
                    className={classes.inputMargin}
                />
            </div>

            {/*include flags*/}
            <div>
                <Text className={classes.titleSize}>Include Flags</Text>
                <SegmentedControl
                    data={[
                        {label: 'False', value: 'false'},
                        {label: 'True', value: 'true'},
                    ]}
                    radius="xl"
                    size="xs"
                    fullWidth
                    onChange={(value) => onParamChange('include_flags', value)}
                    className={classes.inputMargin}
                />
            </div>
            {/*include flags*/}
            <div>
                <Text className={classes.titleSize}>Calculate Nutrients</Text>
                <SegmentedControl
                    data={[
                        {label: 'False', value: 'false'},
                        {label: 'True', value: 'true'},
                    ]}
                    radius="xl"
                    size="xs"
                    fullWidth
                    onChange={(value) => onParamChange('calculate_nutrients', value)}
                    className={classes.inputMargin}
                />
            </div>


            {/*    data quality*/}
            <div>
                <Text className={classes.titleSize}>Data Source</Text>
                <Select
                    data={data_quality_variables}
                    placeholder="Verified"
                    radius="sm"
                    size="sm"
                    onChange={(value) => onParamChange('data_source', value)}
                    className={classes.inputMargin}
                />
            </div>

            {/* nutrients */}
            <div>
                <Text className={classes.titleSize}>Nutrients</Text>
                <Select
                    data={nutrient_variables}
                    placeholder="Simplified micronutrients"
                    radius="sm"
                    size="sm"
                    onChange={(value) => onParamChange('nutrients', value)}
                    className={classes.inputMargin}
                />
            </div>


        </div>
    );
};

export default ImageSearchGeneration;
