import {
    Title,
    Text,
    Container, useMantineTheme, Loader,
} from '@mantine/core';
import {useLocation, useNavigate} from 'react-router-dom';
import {createStyles} from "@mantine/styles";
import { useEffect, useState} from "react";
import NutricloudLogo from "../../componenets/logo/NutriCloudLogo";
import {useAuth} from "../../AuthContext";

const useStyles = createStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100vh',
        justifyContent: 'space-between',
        paddingTop: '0%',
    },
    title: {
        fontFamily: `Greycliff CF, ${theme.fontFamily}`,
        fontWeight: 600,
        fontSize: theme.fontSizes.md,
        textAlign: 'center',
        color: theme.colorScheme === 'dark' ? theme.colors.gray[2] : theme.colors.gray[6],
        marginBottom: theme.spacing.md,
    },
    centeredContent: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        flexGrow: 1,
        width: "100%",
    },
    loaderContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    loader: {
        marginTop: theme.spacing.md,
    },
}));


export function VerifyEmail() {
    const navigate = useNavigate();
        const location = useLocation();
    const { classes } = useStyles();

    const [token, setToken] = useState('');
    const [authError, setAuthError] = useState<string | null>(null);
    const { isEmailVerified, verifyEmailLink } = useAuth();


    // dynamic form
    useEffect(() => {
        // Parse the query parameters
        const queryParams = new URLSearchParams(location.search);
        const tokenParam = queryParams.get('token');
        if (tokenParam) setToken(decodeURIComponent(tokenParam));
    }, [location]);

    useEffect(() => {
        // This effect runs whenever the `token` state changes
        if (token) {
            console.log(token); // This will log the updated token value
            handleToken();
        }
    }, [token]); // Dependency array includes `token` to ensure effect runs on token changes


    const handleToken = async () => {
        if (isEmailVerified) {
            navigate('/');
        } else {
            try {
                const response = await verifyEmailLink(token);

                if (response === "success") {
                    navigate('/login');
                    setAuthError(null);
                } else {
                    setAuthError(response);
                }

            } catch (error) {
                console.error('Login error', error);
                setAuthError('Authentication failed. Please check your password and try again.'); // Set the error message
            }
        }
    };

return (
    <div className={classes.root}>
        <div style={{marginTop: 20}}>
            <NutricloudLogo size={50}/>
        </div>
        <div className={classes.centeredContent}>
            <Container size={420} my={40}>
                {authError ? (
                    <Text color="red" align="center">{authError}</Text>
                ) : (
                    <div className={classes.loaderContainer}>
                        <Text className={classes.title}>
                            We are verifying your email...
                        </Text>
                        <Loader size="md" className={classes.loader} />
                    </div>
                )}
            </Container>
        </div>
    </div>
);
}
