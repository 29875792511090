import {
    Button, Center,
    createStyles,
    Grid,
    Group, Loader,
    Stack,
    Text,
    Tooltip as MantineTooltip,
    useMantineTheme
} from '@mantine/core';
import Sidebar from "../../componenets/sidebar/sidebar";
import React, {useEffect, useState} from "react";
import {UsageSelector} from "./usageSelector";
import MonthPicker from "./monthSelector";
import CreditGrants from "./graphs/creditGrants";
import Invoices from "./graphs/invoices";
import axios from "axios";
import MonthlyUsage from "./graphs/MonthlyUsage";
import MonthlySpendGraphs from "./graphs/MonthlySpendGraphs";
import ActivityTabGraphs from "./graphs/ActivityTab";
import {useAuth} from "../../AuthContext";
import axiosInstance from "../../axiosInstance";
import {monthlySpendLimitMap} from "../Settings/limits";
import {UsageProvider, useUsage} from "./usageContext";


const useStyles = createStyles((theme) => ({
    pageTitle: {
        textAlign: 'left',
        marginTop: 15,
        marginBottom: 10,
        fontSize: '1.5rem',
        fontWeight: 700,
        marginLeft: theme.spacing.md,
        color: theme.colors.gray[0],
        borderBottom: `2px dotted ${theme.colors.gray[7]}`,
        lineHeight: 'normal', // Adjust this line
    },

  gradientBorderBox: {
    display: 'inline-flex',
        backgroundImage: theme.fn.gradient({ from: 'pink', to: 'orange' }),
    borderRadius: '5px',
    padding: '2px', // Controls the thickness of the gradient border
    alignItems: 'center',
    justifyContent: 'center',
      marginLeft: '50px',
      width: '150px',
  },
  innerBox: {
    backgroundColor: theme.colors.dark[7], // Should match the page's background
    borderRadius: '5px',
      width: '150px',
    paddingLeft: "30px",
    paddingRight: "30px",
    paddingTop: "5x",
    paddingBottom: "0px",
      color: theme.colors.gray[1]
  },

  apiRouteTitleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center', // This ensures vertical alignment is centered if your texts have different heights
    width: '100%', // Adjust the width as necessary
  },

    routeTitle: {
        textAlign: 'left',
        marginTop: 15,
        marginBottom: 10,
        fontSize: '1rem',
        fontWeight: 700,
        marginLeft: 60,
    // background: 'linear-gradient(to bottom, orange, purple)',
    // '-webkit-background-clip': 'text',
    // 'background-clip': 'text',
    // color: 'transparent',
    display: 'inline',
    },

    apiActivityDescriptor: {
        color: theme.colors.gray[6],
        fontWeight: 400,
    },



    header: {
        textAlign: 'left',
        fontSize: "24px",
        color: theme.colors.gray[3],
        fontWeight: 600,
        marginLeft: '25px',
    },

    finePrint: {
        fontSize: '12px',
        color: theme.colors.gray[3],
        marginLeft: '25px',
    },

    pageLine: {
        width: '100%',
        height: '1px',
        backgroundColor: theme.colors.gray[7],
        marginBottom: 0,
        marginLeft: 'auto', // Add this line
        marginRight: 'auto', // Add this line
    },

    dottedPageLine: {
        width: '95%',
        height: '1px',
        borderBottom: `2px dotted ${theme.colors.gray[7]}`,
        marginBottom: theme.spacing.md,
        marginLeft: 'auto', // Add this line
        marginRight: 'auto', // Add this line
    },

    paragraphText: {
        fontSize: '16px',
        fontWeight: 300,
        color: theme.colors.gray[0],
        marginLeft: '25px',
    },

    rightDiv: {
        marginLeft: '25px',
        '@media (min-width: 1280px)': {
            marginLeft: 0,
        },
    },

    leftDiv: {
        marginBottom: '40px',
    },

    separatingDiv: {
        height: '10px',
        '@media (min-width: 1280px)': {
            height: '15px',
        },
    },
    largeSeparatingDiv: {
        height: '60px',
        '@media (min-width: 1280px)': {
            height: '80px',
        },
    },

    paragraphBoldText: {
        fontWeight: 'bold',
    },

    highlightedLink: {
        color: theme.colors.orange[0],
        textDecoration: 'none',
    },

    table: {
        width: '100%',
        borderCollapse: 'collapse',
        marginLeft: '20px',
    },

    cell: {
        padding: '0px',
        textAlign: 'left',
        borderBottom: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.gray[0] : theme.colors.gray[0]}`,
        minWidth: '100px',
        marginLeft: '0px',
        marginRight: '0px',

        fontSize: '14px',
        color: theme.colors.gray[3],

    },
    bigCell: {
        padding: '0px',
        textAlign: 'left',
        borderBottom: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.gray[0] : theme.colors.gray[0]}`,
        marginLeft: '0px',
        marginRight: '0px',
        fontSize: '14px',
        color: theme.colors.gray[3],
        minWidth: '100px',
        '@media (min-width: 1000px)': {
            minWidth: '200px',
        },
    },
    tableHeader: {
        borderBottomWidth: '2px',  // make the header line bolder
        fontWeight: 'bold',
    },
    lastRow: {
        borderBottom: 'none',  // remove the bottom border from the last row
    },

    buttonColor: {
        backgroundColor: theme.colors.orange[1],
        fontSize: '14px',
        height: '35px',
        color: theme.colors.gray[9],
    },

    toolTip: {
        transition: 'all 0.3s ease',
        color: theme.colors.gray[9],
        border: `1px solid ${theme.colors.gray[5]}`,
        zIndex: 1000,
        fontSize: '10px',
    },

    inLineStyle: {
         display: 'inline-block'
    },

    graphToolTip: {
        backgroundColor: theme.colors.gray[9],
        color: theme.colors.gray[0],
        padding: '20px',
        borderRadius: '10px',
        width: '225px',
        // border: `1px solid ${theme.colors.gray[5]}`,
        fontSize: '12px',
        lineHeight: '.5',
        // marginTop: '0px',
        // marginBottom: '0px',
    },

        sideBarGrow: {
        marginLeft: '60px',
        '@media (min-width: 1000px)': {
            marginLeft: '200px',
        },
    }
}));

const hexToRGBA = (hex: string, alpha = 1) => {
  const [r, g, b] = hex.match(/\w\w/g)!.map((x) => parseInt(x, 16));
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

const getShortMonth = (date: any) => {
  return date.toLocaleString('default', { month: 'short' });
};

const getTotalSpend = (data: any) => {
  const totalSpend = data.reduce((acc: number, cur: any) => acc + cur["total-cost"], 0);
    return Math.floor(totalSpend * 100) / 100;
};


export function UsagePage() {
    const theme = useMantineTheme();
    const { classes } = useStyles();

  //   // tab
  //   const [selectedUsageTab, setSelectedUsageTab] = useState('Cost'); // Initialize with the API key's current permission
  //   // month
  //   const [currentMonth, setCurrentMonth] = useState(new Date());
  //   const shortMonth = getShortMonth(currentMonth);
  //   const getDaysInMonth = (date: Date) => {
  //         return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
  //     };
  //
  //   //   rate limit
  //   const [monthlyUsageLimit, setMonthlyUsageLimit] = useState(5000);
  //
  //   // legacy_data
  //   interface MonthlyUsageDataItem {
  //     day: number;
  //     [key: string]: number;
  //   }
  //   type MonthlySumData = { [key: string]: number };
  //   const [monthlyUsageData, setMonthlyUsageData] = useState<MonthlyUsageDataItem[]>([]);
  //   const [monthlySumData, setMonthlySumData] = useState<[string, number][]>([]);
  //   const totalSpend = getTotalSpend(monthlyUsageData);
  //   const generateCompleteData = (data: any[]) => {
  //       const daysInMonth = getDaysInMonth(currentMonth);
  //         return Array.from({length: daysInMonth}, (_, i) => {
  //           const day: number = i + 1;
  //           const existingData = data.find(d => d.day === day);
  //
  //           if (existingData) {
  //               return existingData;
  //           }
  //           return {
  //             "day": day,
  //             "total-req": 0,
  //             "total-tok": 0,
  //             "total-cost": 0,
  //             "food-info-req": 0,
  //             "food-info-tok": 0,
  //             "food-info-cost": 0,
  //             "food-chat-req": 0,
  //             "food-chat-tok": 0,
  //             "food-chat-cost": 0,
  //             "food-suggest-req": 0,
  //             "food-suggest-tok": 0,
  //             "food-suggest-cost": 0,
  //             "food-image-req": 0,
  //             "food-image-tok": 0,
  //             "food-image-cost": 0,
  //             "foods-search-req": 0,
  //             "foods-search-tok": 0,
  //             "foods-search-cost": 0,
  //             "food-classify-req": 0,
  //             "food-classify-tok": 0,
  //             "food-classify-cost": 0,
  //             "recipes-chat-req": 0,
  //             "recipes-chat-tok": 0,
  //             "recipes-chat-cost": 0,
  //             "recipes-classify-req": 0,
  //             "recipes-classify-tok": 0,
  //             "recipes-classify-cost": 0,
  //             "recipes-generate-req": 0,
  //             "recipes-generate-tok": 0,
  //             "recipes-generate-cost": 0,
  //             "recipes-image-req": 0,
  //             "recipes-image-tok": 0,
  //             "recipes-image-cost": 0,
  //             "recipes-info-req": 0,
  //             "recipes-info-tok": 0,
  //             "recipes-info-cost": 0,
  //             "recipes-req": 0,
  //             "recipes-tok": 0,
  //             "recipes-cost": 0,
  //             "recipes-suggest-req": 0,
  //             "recipes-suggest-tok": 0,
  //             "recipes-suggest-cost": 0
  //           };
  //       });
  //     };
  //   const getMonthlyUsageData = async () => {
  //     try {
  //       const year = currentMonth.getFullYear();
  //       const month = currentMonth.getMonth() + 1; // Months are zero-indexed (0 = January)
  //       const baseURL = process.env.REACT_APP_BASE_URL;
  //       const response = await axios.get(`${baseURL}/api/usage/new-get_monthly_usage/${year}/${month}`, {
  //           headers: {
  //               'accept': 'application/json',
  //               'Authorization': `Bearer ${accessToken}`
  //           },
  //           withCredentials: true
  //       });
  //       const updated_data = generateCompleteData(response.data);
  //       setMonthlyUsageData(updated_data); // This updates the state for future renders
  //       const newMonthlySumData = calculateMonthlySum(updated_data); // Use updated_data directly
  //       setMonthlySumData(newMonthlySumData);
  //     } catch (error) {
  //       console.error('Failed to fetch monthly usage legacy_data:', error);
  //     }
  //   };
  //   const [costDataKeys, setCostDataKeys] = useState<[string, number][]>([]);
  //   const [activityDataKeys, setActivityDataKeys] = useState<[string, number][]>([]);
  //   const calculateMonthlySum = (data: MonthlyUsageDataItem[]): [string, number][] => {
  //     const monthlySum: MonthlySumData = {};
  //
  //     // Sum up values
  //     data.forEach(item => {
  //       Object.keys(item).forEach(key => {
  //         if (key !== 'day') {
  //           monthlySum[key] = (monthlySum[key] || 0) + item[key];
  //         }
  //       });
  //     });
  //
  //     // Convert to array, sort by value, and return
  //       return Object.entries(monthlySum).sort((a, b) => b[1] - a[1]);
  //   };
  //
  //   // spend progress circle
  //   const spendPercentage = Math.floor(((totalSpend / monthlyUsageLimit) * 100) * 100) / 100;
  //
  //
  //   // usage tier and monthly limit
  //   const [, setUsageTier] = useState(0);
  //   const fetchUsageTierData = async () => {
  //     try {
  //       const baseURL = process.env.REACT_APP_BASE_URL;
  //       const response = await axiosInstance.get(`${baseURL}/api/rate-limit/current`, {
  //         withCredentials: true,
  //       });
  //       console.log(response);
  //       const usageTier: string = response.data.name.match(/\d+/)?.[0];
  //
  //
  //       const monthlySpendLimit = monthlySpendLimitMap[usageTier];
  //       setMonthlyUsageLimit(monthlySpendLimit);
  //
  //       setUsageTier(Number(usageTier));
  //     } catch (error) {
  //       console.error('Failed to fetch API keys:', error);
  //     }
  //   };
  //
  //     //     use effects
  // useEffect(() => {
  //     getMonthlyUsageData();
  //   getDaysInMonth(currentMonth);
  //   fetchUsageTierData();
  // }, [currentMonth]);
  //
  //
  // useEffect(() => {
  //   const newCostDataKeys = monthlySumData.filter(([key, value]) =>
  //     key.endsWith("cost") && value > 0 && key !== "total-cost"
  //   );
  //   setCostDataKeys(newCostDataKeys);
  //   console.log("newCostDataKeys", newCostDataKeys);
  //
  //   const newActivityDataKeys = monthlySumData.filter(([key, value]) =>
  //     !key.endsWith("cost")
  //   );
  //   setActivityDataKeys(newActivityDataKeys);
  //
  // }, [monthlySumData]);

    const {
    selectedUsageTab,
        isloading
  } = useUsage();

    return (
        <div>
            <Sidebar defaultExpanded={true} activeItem="usage" shrinkSize={1000}/>
            <div className={classes.sideBarGrow}>
                {/*title*/}
                <MantineTooltip
                  label="Below you'll find a summary of API usage for your organization. All dates and times are UTC-based, and data may be delayed up to 5 minutes."
                  className={classes.toolTip}
                  position={'bottom'}
                  transitionProps={{ transition: 'scale', duration: 200 }}
                  multiline
                  w={300}
                  radius={"xs"}
                  p={20}
                  fw={500}
                  fz={'14px'}
                  ml={15}
                  offset={-5}
                  bg={theme.colors.gray[9]}
                  color={theme.colors.gray[8]}
                >
                  <div className={classes.inLineStyle}>
                    <Text size="xs" className={classes.pageTitle}>Usage</Text>
                  </div>
                </MantineTooltip>
                <div></div>

                {/* top selectors*/}
                <Group position="apart" spacing="xs" style={{ width: '100%', alignItems: 'center' }}>
                  <UsageSelector />

                  <Group spacing="xs" mb={15} ml={10}>
                    <MonthPicker />
                    {/*<Button bg={theme.colors.orange[1]} size={'xs'} mr={20} ml={10}>Export</Button>*/}
                  </Group>
                </Group>
                <div className={classes.pageLine}></div>

                {/* Cost Tab*/}
                  {selectedUsageTab === 'Cost' && (
                    <Grid>
                      <Grid.Col span={12} lg={8}>
                          {isloading ? (
                              <Center mt={100} mb={50}>
                                <Loader size={"lg"}/>
                            </Center>
                          ) : (
                            <MonthlySpendGraphs />
                          )}

                      </Grid.Col>
                        <Grid.Col span={12} lg={4}>
                            {isloading ? (
                               <Center mt={100} mr={50}>
                                <Loader size={"lg"}/>
                            </Center>
                          ) : (
                              <Stack spacing="xs">
                                <div style={{marginTop: '10px'}}></div>
                                <MonthlyUsage/>
                                <CreditGrants/>
                                <Invoices/>
                            </Stack>
                          )}

                        </Grid.Col>
                    </Grid>
                  )}
                {/* Activity Tab */}
                {selectedUsageTab === 'Activity' && (
                    <div>
                        <ActivityTabGraphs />
                    </div>
                )}
            </div>
        </div>
    );
}

export default UsagePage;
