import React, {useEffect, useState} from 'react';
import {createStyles, SegmentedControl, rem, Select} from '@mantine/core';
import { useNavigate } from 'react-router-dom';

const useStyles = createStyles((theme) => ({
    root: {
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.white,
        boxShadow: theme.shadows.md,
        border: `${rem(1)} solid ${
            theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[1]
        }`,
    },

    indicator: {
        backgroundImage: theme.fn.gradient({ from: 'pink', to: 'orange' }),
    },

    control: {
        border: '0 !important',
    },

    label: {
        '&, &:hover': {
            '&[data-active]': {
                color: theme.white,
            },
        },
    },
}));

interface ApiSelectorProps {
    selectedValue: string;
    setSelectedValue: any;
}

export const ApiSelector: React.FC<ApiSelectorProps> = ({ selectedValue, setSelectedValue }) => {
    const { classes } = useStyles();

    const [isNarrow, setIsNarrow] = useState(window.innerWidth < 475);

    useEffect(() => {
        const handleResize = () => {
            setIsNarrow(window.innerWidth < 475);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const data = [
        'Search',
        'Autocomplete',
        'Classify',
        'Chat',
        'Recognition',
        'Recommendation',
        'Embedding', 'Users',
        'App',
    ];


    const handleChange = (value: string) => {
        setSelectedValue(value);
    };

    const getSegementedControl = () => {
        if (isNarrow) {
            return (
               <Select
                    label="Choose an API"
                    value={selectedValue}
                    onChange={handleChange}
                    data={data}
                    w="75vw"

               />
            );
        }
        return (
                <SegmentedControl
                    radius="xl"
                    size="xs"
                    data={data}
                    classNames={classes}
                    value={selectedValue}
                    onChange={handleChange}
                />
        );
    }

    return (
        getSegementedControl()
    );
};
