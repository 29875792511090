import React, { useState } from 'react';
import {TextInput, Button, List, Group, Grid, Text} from '@mantine/core';
import {Trash} from "react-feather";

interface ListInputComponentProps {
    onParamChange: (param: string, value: string[]) => void;
}

const ListInputComponent: React.FC<ListInputComponentProps> = ({ onParamChange }) => {
    const [inputValue, setInputValue] = useState('');
    const [items, setItems] = useState<string[]>([]);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(event.target.value);
    };

    const updateItems = (newItems: string[]) => {
        setItems(newItems);
        onParamChange('ingredients', newItems); // Invoke the callback with the new list
    };

    const handleAddItem = () => {
        if (inputValue) {  // Prevent adding empty strings
            const newItems = [...items, inputValue];
            updateItems(newItems);
            setInputValue('');  // Clear input after adding
        }
    };

    const handleRemoveItem = (index: number) => {
        const newItems = items.filter((_, i) => i !== index);
        updateItems(newItems);
    };

    const handleClearItems = () => {
        updateItems([]);
    };

    return (
        <div>
            <Group spacing="xs">
                <Text>Ingredients</Text>
                <TextInput
                    mb={20}
                    value={inputValue}
                    onChange={handleInputChange}
                    placeholder="Add an ingredient..."
                    rightSection={
                        <Button onClick={handleAddItem}>
                            Add
                        </Button>
                    }
                />
                {items.length > 0 && (
                    <div style={{width: "100%"}}>
                        {items.map((item, index) => (
                                <Grid w={"100%"} key={index}>
                                    <Grid.Col  span={10}>
                                        <Text size={'sm'}>
                                            {item}
                                        </Text>
                                    </Grid.Col>
                                    <Grid.Col span={2}>
                                        <Button variant={"subtle"} onClick={() => handleRemoveItem(index)} size="xs">
                                            <Trash color={"orange"} size={12} />
                                        </Button>
                                    </Grid.Col>
                                </Grid>
                        ))}
                    </div>
                )}


            </Group>
        </div>
    );
};

export default ListInputComponent;
