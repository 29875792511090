import {
    TextInput,
    PasswordInput,
    Anchor,
    Paper,
    Title,
    Text,
    Container,
    Group,
    Button,
    Divider,
    Grid,
    Col, useMantineTheme, Loader,
} from '@mantine/core';
import {FacebookButton, GithubButton, GoogleButton} from "../SocialButtons/SocialButtons"
import {Link, useNavigate} from 'react-router-dom';
import {createStyles} from "@mantine/styles";
import React, {FormEvent, useEffect, useState} from "react";
import styles from "../auth.module.css"
import {PropIcon} from "../SocialButtons/SocialButtons";
import axios from "axios";
import qs from 'qs';
import {useAuth} from "../../../AuthContext";
import { useLocation } from 'react-router-dom';
import NutricloudLogo from "../../../componenets/logo/NutriCloudLogo";




const useStyles = createStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100vh',
        justifyContent: 'space-between',
        paddingTop: '0%',
    },
    links: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '2rem',
    },
    link: {
        fontSize: '0.9rem',
        color: theme.colorScheme === 'dark' ? theme.colors.orange[0] : theme.colors.gray[7],
    },
    description: {
        fontSize: '.9rem',
        color: theme.colorScheme === 'dark' ? theme.colors.gray[3] : theme.colors.gray[6],
        textAlign: 'center',
    },
    title: {
        fontFamily: `Greycliff CF, ${theme.fontFamily}`,
        fontWeight: 600 ,
        fontSize: theme.fontSizes.xl,
        textAlign: 'center',
        color: theme.colorScheme === 'dark' ? theme.colors.gray[2] : theme.colors.gray[6],
    },
    form: {
        marginTop: -30,
        width: '100%',
    },
    textInput: {
        width: "100%",
        color: theme.colors.gray[1]
    }
}));


export function ForgotPassword() {
    //
    // setting
    //
    const theme = useMantineTheme();
    const { classes } = useStyles();
    const navigate = useNavigate();


    // dynamic form

    const [showPassword, setShowPassword] = useState(false);
    const [email, setEmail] = useState('');
    const [authError, setAuthError] = useState<string | null>(null);

    const [authLoading, setAuthLoading] = useState(false);


    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
    };


    //
    // auth
    //

    const handleEditClick = () => {
        setShowPassword(false);
      };

    const handleSubmit = (event: FormEvent) => {
        event.preventDefault();
    };

    return (
        <div className={classes.root}>
            <NutricloudLogo  size={100}/>
            <Container size={420} my={40}>

                {/*title*/}
                <Title
                    align="center"
                    className={classes.title}
                    mb={10}
                >
                    Reset your password
                </Title>
                <Text color="dimmed" size="sm" align="center">
                    Enter your Email address and we will send you instructions to reset your password.
                </Text>

                                {/*form*/}
                <Paper p={0} pt={30} my={0} radius="md">
                    <form onSubmit={handleSubmit} className={classes.form}>
                        <Grid>
                            <Col style={{ position: 'relative', width: "100%"}}>
                                <TextInput
                                    className={`${classes.textInput}`} // Add dynamic class
                                    type="email"
                                    label={showPassword ? '' : 'Email'}
                                    placeholder="Email Address"
                                    value={email}
                                    onChange={handleEmailChange}
                                    required
                                    radius="xs"
                                    size="lg"
                                    readOnly={showPassword}
                                    rightSection={
                                    showPassword && (
                                      <Button
                                        variant="subtle"
                                        style={{ color: theme.colors.orange[1], background: 'none', border: 'none' }}
                                        onClick={handleEditClick}
                                        size="xs"
                                      >
                                        Edit
                                      </Button>
                                    )
                              }
                                />
                            </Col>
                        </Grid>

                        {authLoading ? (
                            <div style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                padding: '16px 0',
                                margin: 7,
                            }}>
                                <Loader size="lg" />
                            </div>
                        ) : (
                            <Button className={styles.Box} fullWidth my="md" type="submit">
                                {showPassword ? 'Login' : 'Continue'}
                            </Button>
                        )}
                    </form>

                    <Text color="dimmed" size="sm" align="center" weight={700}>
                        <Anchor color={theme.colors.orange[0]} size="sm" component={Link} to="/login">
                            Back to NutriCloud
                        </Anchor>
                    </Text>

                </Paper>
            </Container>
            <div></div>
        </div>
    );
}
