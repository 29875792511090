import React, {useEffect, useState} from 'react';
import {
    createStyles,
    rem,
    Slider,
    TextInput,
    Text,
    SegmentedControl,
    Button,
    useMantineTheme, Checkbox
} from '@mantine/core';
import { Select, SelectItem } from '@mantine/core';
import {CheckboxGroup} from "@mantine/core/lib/Checkbox/CheckboxGroup/CheckboxGroup";

const useStyles = createStyles((theme) => ({
    inputMargin: {
        marginBottom: '10px',
    },
    titleSize: {
        fontSize: '24x',
        '@media (max-height: 1000px)': {
            fontSize: '16px'
        },
    },

    checkBox: {
        marginBottom: 12,
    }
}));



interface RelationalSearchParametersProps {
    onParamChange: (param: string, value: any) => void;
}

const FoodClassifyParameters: React.FC<RelationalSearchParametersProps> = ({ onParamChange }) => {
    const theme = useMantineTheme();
    const { classes } = useStyles();


    const [searchType, setSearchType] = useState('prefix');

    const orange = "rgb(255, 140, 0)";

        const defaultTypes = ['allergy', 'diet', 'branded', 'texture', 'flavor_profile'];

    const [selectedTypes, setSelectedTypes] = useState<string[]>(defaultTypes);  // Changed this line
    const handleTypeChange = (type: string, checked: boolean) => {
        const newTypes = checked
            ? [...selectedTypes, type]
            : selectedTypes.filter((t) => t !== type);

        setSelectedTypes(newTypes);
        onParamChange('classification_types', newTypes);
    };
    // At the top of your component:

    // Add this useEffect hook
    useEffect(() => {
        // Trigger initial parameter updates
        onParamChange('query', ''); // Since this is your text input's default value
        onParamChange('limit', 5); // Since this is your slider's default value
        onParamChange('classification_types', defaultTypes);
    }, []); // Empty dependency array means this runs once when component mounts

    // Rest of your component remains the same
    return (
        <div>
            {/*searchTerm*/}
            <Text className={classes.titleSize}>Query</Text>
            <TextInput
                placeholder="Chicken..."
                required
                onChange={(event) => onParamChange('query', event.target.value)}
                className={classes.inputMargin}
            />

            {/*limit*/}
            <div>
                <Text className={classes.titleSize}>Limit</Text>
                <Slider
                    defaultValue={10}
                    min={1}
                    max={20}
                    label={(value) => value.toFixed(0)}
                    onChangeEnd={(value) => onParamChange('limit', value)}
                    className={classes.inputMargin}
                />

                {/*classification types*/}
                <Text className={classes.titleSize} mb={10}>Classification Types</Text>
                <div className={classes.inputMargin}>
                <Checkbox
                   className={classes.checkBox}
                   label="Allergy"
                   defaultChecked
                   checked={selectedTypes.includes('allergy')}
                   onChange={(event) => handleTypeChange('allergy', event.currentTarget.checked)}
                   radius='xs'
                   size={'sm'}
                />
                    <Checkbox
                        className={classes.checkBox}
                        label="Diet"
                        defaultChecked
                        checked={selectedTypes.includes('diet')}
                        onChange={(event) => handleTypeChange('diet', event.currentTarget.checked)}
                        radius='xs'
                        size={'sm'}
                    />
                    <Checkbox
                        className={classes.checkBox}
                        label="Branded"
                        defaultChecked
                        checked={selectedTypes.includes('branded')}
                        onChange={(event) => handleTypeChange('branded', event.currentTarget.checked)}
                        radius='xs'
                        size={'sm'}
                    />
                    <Checkbox
                        className={classes.checkBox}
                        label="Flavor Profile"
                        defaultChecked
                        checked={selectedTypes.includes('flavor_profile')}
                        onChange={(event) => handleTypeChange('flavor_profile', event.currentTarget.checked)}
                        radius='xs'
                        size={'sm'}
                    />
                    <Checkbox
                        className={classes.checkBox}
                        label="Texture"
                        defaultChecked
                        checked={selectedTypes.includes('texture')}
                        onChange={(event) => handleTypeChange('texture', event.currentTarget.checked)}
                        radius='xs'
                        size={'sm'}
                    />
                    <Checkbox
                        className={classes.checkBox}
                        label="Cuisine"
                        checked={selectedTypes.includes('cuisine')}
                        onChange={(event) => handleTypeChange('cuisine', event.currentTarget.checked)}
                        radius='xs'
                        size={'sm'}
                    />
                    <Checkbox
                        className={classes.checkBox}
                        label="Seasonality"
                        checked={selectedTypes.includes('seasonality')}
                        onChange={(event) => handleTypeChange('seasonality', event.currentTarget.checked)}
                        radius='xs'
                        size={'sm'}
                    />
                    <Checkbox
                        className={classes.checkBox}
                        label="Freshness Score"
                        checked={selectedTypes.includes('freshness_score')}
                        onChange={(event) => handleTypeChange('freshness_score', event.currentTarget.checked)}
                        radius='xs'
                        size={'sm'}
                    />
                    <Checkbox
                        className={classes.checkBox}
                        label="Price Point"
                        checked={selectedTypes.includes('price_point')}
                        onChange={(event) => handleTypeChange('price_point', event.currentTarget.checked)}
                        radius='xs'
                        size={'sm'}
                    />
                    <Checkbox
                        className={classes.checkBox}
                        label="Caloric Density"
                        checked={selectedTypes.includes('caloric_density')}
                        onChange={(event) => handleTypeChange('caloric_density', event.currentTarget.checked)}
                        radius='xs'
                        size={'sm'}
                    />
                    <Checkbox
                        className={classes.checkBox}
                        label="Health Index"
                        checked={selectedTypes.includes('health_index')}
                        onChange={(event) => handleTypeChange('health_index', event.currentTarget.checked)}
                        radius='xs'
                        size={'sm'}
                    />
                    <Checkbox
                        className={classes.checkBox}
                        label="Color Profile"
                        checked={selectedTypes.includes('color_profile')}
                        onChange={(event) => handleTypeChange('color_profile', event.currentTarget.checked)}
                        radius='xs'
                        size={'sm'}
                    />

                </div>
            </div>
        </div>
    );
};

export default FoodClassifyParameters;
