import React from 'react';
import {
    Text,
    Container,
    createStyles,
    useMantineTheme
} from '@mantine/core';
import {useNavigate} from 'react-router-dom';
import DocsPage from "../DocsPage";
import FeedbackBanner from "../Components/Feedback";
import {Prism} from "@mantine/prism";



const useStyles = createStyles((theme) => ({
    iconBox: {
        display: 'flex',
        justifyContent: 'flex-start', // Align items to the start (left side)
        alignItems: 'center',
        marginBottom: theme.spacing.md,
        '& svg': {
            transition: 'transform 0.3s ease',
        },
        '&:hover svg': {
            transform: 'scale(1.3)',
            color: theme.colors.gray[0],
        },

    },
    contentBox: {
        marginLeft: theme.spacing.md, // Add some space between the icon and the text
    },
      animatedLink: {
          display: 'inline-flex',
          fontSize: '18px',
          alignItems: 'center',
          cursor: 'pointer',
          position: 'relative',
          textDecoration: 'none',
          color: theme.colorScheme === 'dark' ? theme.white : theme.black,
      },

    // headers
    pageTitle: {
        textAlign: "left",
        fontSize: theme.fontSizes.xxl,
        fontWeight:600,
        color: theme.colors.gray[3],
        marginBottom: "0px",
        marginTop: "50px",
    },
    mainHeader: {
        fontWeight: 500,
        fontSize: theme.fontSizes.xl,
        marginTop: "0px",
        marginBottom: "10px",
    },
    subHeader: {
        fontWeight: 500,
        fontSize: theme.fontSizes.lg,
        color: theme.colors.gray[5],
        // marginTop: "30px",
        marginBottom: "0px",
    },

    // bullet list
    link: {
        color: theme.colors.orange[0],
        textDecoration: 'none',
        '&:hover': {
            color: theme.colors.orange[9],  // Change this to the color you want on hover
        },
    },
    bulletTitle: {
        color: theme.colors.gray[0],
        fontWeight: 600,
    },
    bulletList: {
        listStyleType: 'disc',
    },
    bulletPoint: {
        fontSize: theme.fontSizes.sm,
        margin: '5px 0',
    },
    // text
    paragraphText: {
        fontSize: '16px',
        marginBottom: "10px",
        color: theme.colors.gray[3],
    },

    customText: {
        fontSize: '16px',
        color: theme.colors.gray[6]
    },

    // info boxes
    infoBox: {
        backgroundColor: theme.colors.gray[8],
        padding: theme.spacing.md,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        width: '100%',
        marginTop: '10px',
        marginBottom: '20px',
    },
    infoText: {
        marginLeft: theme.spacing.sm,
        fontSize: '16px',
    },
    infoIcon: {
        fontSize: '1em',
        flexShrink: 0,
    },
coloredBox: {
    border: `0.5px solid ${theme.colors.gray[8]}`,
    backgroundColor: theme.colors.gray[9],
        '&:hover': {
      backgroundColor: theme.colors.dark[5],
    },
    borderRadius: '8px',
    padding: 20,
    flex: '1 1 30%',  // Add this line to make boxes take equal space
    // minWidth: '250px', // Add this to prevent boxes from getting too narrow
},

    meetTheAPICardContainer: {
        display: 'flex',
        gap: '20px',
        flexDirection: 'row',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        [`@media (max-width: 1000px)`]: {
            flexDirection: 'column',
        },
    },
}));



function RecipeSearchDocs() {
    const {classes} = useStyles();
    const theme = useMantineTheme();
    const navigate = useNavigate();

    return (
        <Container>
            {/*title*/}
            <Text className={classes.pageTitle}>Recipe Search</Text>
            <Text className={classes.subHeader}>Learn how to search for recipes and
                retrieve detailed information with NutriCloud.</Text>
            <Text className={classes.paragraphText}></Text>

            <Text mt={50} className={classes.mainHeader}>Introduction</Text>
            <Text className={classes.paragraphText}>The Recipe Search API allows you to
                find recipes based on your search queries and customize the information
                you receive about each recipe. By inputting a query and setting optional
                parameters, you can:</Text>
            <ul className={classes.bulletList}>
                <li className={classes.bulletPoint}>
                    Discover new recipes based on ingredients or dish names.
                </li>
                <li className={classes.bulletPoint}>
                    Retrieve cooking instructions and ingredient lists.
                </li>
                <li className={classes.bulletPoint}>
                    Access additional details like nutritional information, labels, and
                    photos.
                </li>
                <li className={classes.bulletPoint}>
                    Filter results based on similarity and limit the number of returned
                    recipes.
                </li>
            </ul>


            <Text mt={50} className={classes.mainHeader}>Quickstart</Text>
            <Text className={classes.subHeader}>Recipe Search Endpoint</Text>
            <Text mb={30} className={classes.paragraphText}>Search for recipes with
                customizable detail levels. Submit your search query and configure
                optional parameters to control the number of results, similarity
                threshold, and included data fields.</Text>
            <Prism language={"bash"}>
                {'curl -X POST \\\n' +
                    '  \'https://api.nutricloud.com/v1/search/recipe/\' \\\n' +
                    '  -H \'Content-Type: application/json\' \\\n' +
                    '  -H \'Authorization: api-key YOUR_API_KEY\' \\\n' +
                    '  -d \'{\n' +
                    '    "query": "chicken schnitzel",\n' +
                    '    "limit": 2,\n' +
                    '    "threshold": 5,\n' +
                    '    "labels": true,\n' +
                    '    "nutrients": false,\n' +
                    '    "photo": true\n' +
                    '  }\'\n'}
            </Prism>

            <Text mt={50} className={classes.subHeader}>Response Structure</Text>
            <Text className={classes.paragraphText}>The API response will be a JSON
                object containing the recipes that match your query. Each recipe
                includes:</Text>
            <ul className={classes.bulletList}>
                <li className={classes.bulletPoint}><strong>Recipe Name:</strong> The
                    name of the recipe.
                </li>
                <li className={classes.bulletPoint}><strong>Similarity:</strong> A score
                    indicating how closely the recipe matches your query.
                </li>
                <li className={classes.bulletPoint}><strong>Ingredients:</strong> A list
                    of ingredients required for the recipe.
                </li>
                <li className={classes.bulletPoint}>
                    <strong>Directions:</strong> Step-by-step cooking instructions.
                </li>
                <li className={classes.bulletPoint}><strong>Servings:</strong> Number of
                    servings the recipe makes.
                </li>
                <li className={classes.bulletPoint}><strong>Cook/Prep
                    Times:</strong> Time in minutes for cooking, preparation, and total
                    time.
                </li>
                <li className={classes.bulletPoint}>
                    <strong>Optional Fields</strong> (based on your parameters):
                    <ul className={classes.bulletList}>
                        <li className={classes.bulletPoint}>
                            <strong>Labels:</strong> Additional information like
                            cuisine, meal type, flavor profiles, dietary restrictions,
                            etc.
                        </li>
                        <li className={classes.bulletPoint}>
                            <strong>Nutrients:</strong> Nutritional information if
                            nutrients is set to true.
                        </li>
                        <li className={classes.bulletPoint}><strong>Image
                            Link:</strong> URL to the recipe's photo if photo is true.
                        </li>
                    </ul>
                </li>
            </ul>

            <Text mt={50} className={classes.mainHeader}>Key Features</Text>
            <Text className={classes.subHeader}>Flexible Search Queries</Text>
            <Text className={classes.paragraphText}></Text>
            <ul className={classes.bulletList}>
                <li className={classes.bulletPoint}>
                    <strong>Ingredient-Based Search:</strong> Find recipes that include
                    specific ingredients.
                </li>
                <li className={classes.bulletPoint}>
                    <strong>Dish Name Search:</strong> Look up recipes by their names or
                    variations.
                </li>
                <li className={classes.bulletPoint}>
                    <strong>Keyword Search:</strong> Use any relevant keywords to
                    discover new recipes.
                </li>
            </ul>


            <Text className={classes.subHeader}>Customizable Results</Text>
            <Text className={classes.paragraphText}></Text>
            <ul className={classes.bulletList}>
                <li className={classes.bulletPoint}>
                    <strong>Limit Results:</strong> Control the number of recipes
                    returned using the limit parameter.
                </li>
                <li className={classes.bulletPoint}>
                    <strong>Similarity Threshold:</strong> Adjust the threshold to
                    filter results based on how closely they match your query.
                </li>
                <li className={classes.bulletPoint}>
                    <strong>Include Photos:</strong> Set photo to true to receive images
                    of the recipes.
                </li>
                <li className={classes.bulletPoint}>
                    <strong>Include Labels:</strong> Get additional context like cuisine
                    type, meal type, flavor profiles, and more by setting labels to
                    true.
                </li>
                <li className={classes.bulletPoint}>
                    <strong>Nutritional Information:</strong> Access detailed nutrient
                    data by setting nutrients to true.
                </li>
            </ul>

            <Text className={classes.subHeader}>Labels and Metadata</Text>
            <Text className={classes.paragraphText}>When labels is set to true, you
                receive enriched data about each recipe, such as:</Text>
            <ul className={classes.bulletList}>
                <li className={classes.bulletPoint}>
                    <strong>Cuisine:</strong> e.g., Italian, Chinese, Mexican.
                </li>
                <li className={classes.bulletPoint}>
                    <strong>Meal Type:</strong> e.g., Breakfast, Main Course, Dessert.
                </li>
                <li className={classes.bulletPoint}>
                    <strong>Flavor Profiles:</strong> Levels of sweetness, spiciness,
                    umami, sourness, etc.
                </li>
                <li className={classes.bulletPoint}>
                    <strong>Dietary Information:</strong> e.g., Gluten-Free, Vegan,
                    Allergens.
                </li>
                <li className={classes.bulletPoint}>
                    <strong>Cooking Equipment:</strong> Required tools like skillet,
                    oven, blender.
                </li>
                <li className={classes.bulletPoint}>
                    <strong>Cost and Skill Level:</strong> Estimated cost and difficulty
                    level.
                </li>
            </ul>
            <Text className={classes.paragraphText}>For a full list of labels see the
                <span
                    onClick={() => navigate('/docs/api-reference/search')}
                    style={{cursor: 'pointer', color: theme.colors.orange[0]}}
                > API reference page</span>
            </Text>

            <Text mt={50} className={classes.mainHeader}>Optimization Tips</Text>
            <Text className={classes.paragraphText}></Text>
            <ul className={classes.bulletList}>
                <li className={classes.bulletPoint}>
                    <strong>Use Specific Queries:</strong> More precise queries yield
                    more relevant results (e.g., "vegan lasagna" instead of "lasagna").
                </li>
                <li className={classes.bulletPoint}>
                    <strong>Adjust Threshold:</strong> Lowering the threshold value will
                    return recipes more closely matching your query.
                </li>
                <li className={classes.bulletPoint}>
                    <strong>Limit Results Appropriately:</strong> Use the limit
                    parameter to manage the size of the response and reduce unnecessary
                    data transfer.
                </li>
                <li className={classes.bulletPoint}>
                    <strong>Select Necessary Fields:</strong> Only set labels, photo,
                    and nutrients to true if you need that information to optimize
                    performance.
                </li>
            </ul>

            <FeedbackBanner/>
        </Container>
    );
}

export default function RecipeSearchDocsPage() {
    return (
        <div>
            <DocsPage children={<RecipeSearchDocs/>} activeItem={"recipe-search"}/>
        </div>
    );
}
