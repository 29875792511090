import React, { createContext, useContext, useState, useEffect } from 'react';
import {useAuth} from "../../AuthContext";
import axios from "axios";

interface UsageContextType {
  // States
    isloading: boolean;
  selectedUsageTab: string;
  setSelectedUsageTab: (tab: string) => void;
  currentMonth: Date;
  setCurrentMonth: (date: Date) => void;
  monthlyUsageLimit: number;
  monthlyUsageData: MonthlyUsageDataItem[];
  monthlySumData: [string, number][];
  costDataKeys: [string, number][];
  activityDataKeys: [string, number][];
  usageTier: number;
  spendPercentage: number;
  totalSpend: number;
  shortMonth: string;
  creditGrants: CreditGrantItem[];
    invoiceData: InvoiceItem[];
}

interface MonthlyUsageDataItem {
  day: number;
  [key: string]: number;
}

export interface CreditGrantItem {
  availableFrom: string;
  state: string;
  balance: number;
  total: number;
  expirationDate: string;
}

type monthlySpendLimitMapType = {
    [key: string]: number;
}
export const monthlySpendLimitMap: monthlySpendLimitMapType = {
    "1": 100,
    "2": 500,
    "3": 1000,
    "4": 5000,
    "5": 50000,
}

interface InvoiceItem {
  month: string;
  state: 'Paid' | 'Due';
  balance: number;
  link: string;
}

type MonthlySumData = { [key: string]: number };

const UsageContext = createContext<UsageContextType | undefined>(undefined);

export const UsageProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const {
        apiClient
    } = useAuth();
    const [isloading, setIsLoading] = useState(true);
  const [selectedUsageTab, setSelectedUsageTab] = useState('Cost');
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [monthlyUsageLimit, setMonthlyUsageLimit] = useState(5000);
  const [monthlyUsageData, setMonthlyUsageData] = useState<MonthlyUsageDataItem[]>([]);
  const [monthlySumData, setMonthlySumData] = useState<[string, number][]>([]);
  const [costDataKeys, setCostDataKeys] = useState<[string, number][]>([]);
  const [activityDataKeys, setActivityDataKeys] = useState<[string, number][]>([]);
  const [usageTier, setUsageTier] = useState(0);
  const [creditGrants, setCreditGrants] = useState<CreditGrantItem[]>([]);
  const [invoiceData, setInvoiceData] = useState<InvoiceItem[]>([]);  // Correct the type for useState


  const getDaysInMonth = (date: Date) => {
    return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
  };

  const getShortMonth = (date: Date) => {
    return date.toLocaleString('default', { month: 'short' });
  };

  const generateCompleteData = (data: any[]) => {
    const daysInMonth = getDaysInMonth(currentMonth);
    return Array.from({length: daysInMonth}, (_, i) => {
      const day: number = i + 1;
      const existingData = data.find(d => d.day === day);

      if (existingData) {
        return existingData;
      }
      return {
              "day": day,
              "total-req": 0,
              "total-tok": 0,
              "total-cost": 0,
              "food-info-req": 0,
              "food-info-tok": 0,
              "food-info-cost": 0,
              "food-chat-req": 0,
              "food-chat-tok": 0,
              "food-chat-cost": 0,
              "food-suggest-req": 0,
              "food-suggest-tok": 0,
              "food-suggest-cost": 0,
              "food-image-req": 0,
              "food-image-tok": 0,
              "food-image-cost": 0,
              "foods-search-req": 0,
              "foods-search-tok": 0,
              "foods-search-cost": 0,
              "food-classify-req": 0,
              "food-classify-tok": 0,
              "food-classify-cost": 0,
              "recipes-chat-req": 0,
              "recipes-chat-tok": 0,
              "recipes-chat-cost": 0,
              "recipes-classify-req": 0,
              "recipes-classify-tok": 0,
              "recipes-classify-cost": 0,
              "recipes-generate-req": 0,
              "recipes-generate-tok": 0,
              "recipes-generate-cost": 0,
              "recipes-image-req": 0,
              "recipes-image-tok": 0,
              "recipes-image-cost": 0,
              "recipes-info-req": 0,
              "recipes-info-tok": 0,
              "recipes-info-cost": 0,
              "recipes-req": 0,
              "recipes-tok": 0,
              "recipes-cost": 0,
              "recipes-suggest-req": 0,
              "recipes-suggest-tok": 0,
              "recipes-suggest-cost": 0
      };
    });
  };

  const calculateMonthlySum = (data: MonthlyUsageDataItem[]): [string, number][] => {
    const monthlySum: MonthlySumData = {};
    data.forEach(item => {
      Object.keys(item).forEach(key => {
        if (key !== 'day') {
          monthlySum[key] = (monthlySum[key] || 0) + item[key];
        }
      });
    });
    return Object.entries(monthlySum).sort((a, b) => b[1] - a[1]);
  };

  const getTotalSpend = (data: MonthlyUsageDataItem[]): number => {
    // Implement your total spend calculation logic here
    return 0; // Replace with actual calculation
  };

  const getMonthlyUsageData = async () => {
    try {
      const year = currentMonth.getFullYear();
      const month = currentMonth.getMonth() + 1;
      const baseURL = process.env.REACT_APP_BASE_URL;
      const response = await apiClient.get(
        `${baseURL}/api/usage/new-get_monthly_usage/${year}/${month}`,
        {
          headers: {
            'accept': 'application/json',
          },
          withCredentials: true
        }
      );
      const updated_data = generateCompleteData(response.data);
      setMonthlyUsageData(updated_data);
      const newMonthlySumData = calculateMonthlySum(updated_data);
      setMonthlySumData(newMonthlySumData);
    } catch (error) {
      console.error('Failed to fetch monthly usage data:', error);
    }
  };

  const fetchUsageTierData = async () => {
    try {
      const baseURL = process.env.REACT_APP_BASE_URL;
      const response = await apiClient.get(`${baseURL}/api/rate-limit/current`, {
        withCredentials: true,
      });
      const usageTier: string = response.data.name.match(/\d+/)?.[0];
      const monthlySpendLimit = monthlySpendLimitMap[usageTier];
      setMonthlyUsageLimit(monthlySpendLimit);
      setUsageTier(Number(usageTier));
    } catch (error) {
      console.error('Failed to fetch usage tier:', error);
    }
  };

  const fetchCreditGrants = async () => {
    try {
        const baseURL = process.env.REACT_APP_BASE_URL;
        const response = await apiClient.get(`${baseURL}/api/payment/credit-grants?page=1`, {
            headers: {
            'accept': 'application/json',
        },
            withCredentials: true
        });
        console.log(response.data);

        const creditGrants: CreditGrantItem[] = response.data.legacy_data.map((item: any) => ({
            availableFrom: item.date_issued,
            state: item.status !== 'Expired' ? 'Active' : 'Expired', // You need to adjust this condition based on your actual criteria.
            balance: item.usd_balance,
            total: item.usd_amount,
            expirationDate: item.expiration_date
        }));

        setCreditGrants(creditGrants);
    } catch (error) {
        console.error('Failed to fetch credit grants', error);
    }
};

      const getInvoiceData = async () => {
        try {
          const baseURL = process.env.REACT_APP_BASE_URL;
          const response = await apiClient.get(`${baseURL}/api/payment/invoices?page=1`, {
            headers: {
                'accept': 'application/json',
            },
              withCredentials: true
          });

          const invoices: InvoiceItem[] = response.data.legacy_data.map((invoice: any) => ({
            month: new Date(invoice.date_issued).toLocaleString('default', { month: 'long', year: 'numeric' }),  // Format the date as "Month Year"
            state: invoice.status === "ACTIVE" ? 'Due' : 'Paid',  // Assuming 'ACTIVE' means due and others mean paid
            balance: invoice.usd_balance,  // Assuming you want to use the USD balance
            link: `https://example.com/invoice/${invoice.token_amount}`  // Example link, adjust based on actual data
          }));

          setInvoiceData(invoices);
        } catch (error) {
          console.error('Failed to fetch invoices', error);
        }
    };

    useEffect(() => {
      const fetchAllData = async () => {
        setIsLoading(true);
        try {
          await Promise.all([
            getMonthlyUsageData(),
            getDaysInMonth(currentMonth),
            getInvoiceData(),
            fetchUsageTierData(),
            fetchCreditGrants()
          ]);
        } catch (error) {
          console.error('Error fetching data:', error);
        } finally {
          setIsLoading(false);
        }
      };

      fetchAllData();
    }, [currentMonth]);

  useEffect(() => {
    const newCostDataKeys = monthlySumData.filter(([key, value]) =>
      key.endsWith("cost") && value > 0 && key !== "total-cost"
    );
    setCostDataKeys(newCostDataKeys);

    const newActivityDataKeys = monthlySumData.filter(([key]) =>
      !key.endsWith("cost")
    );
    setActivityDataKeys(newActivityDataKeys);
  }, [monthlySumData]);

  const totalSpend = getTotalSpend(monthlyUsageData);
  const spendPercentage = Math.floor(((totalSpend / monthlyUsageLimit) * 100) * 100) / 100;
  const shortMonth = getShortMonth(currentMonth);

  const value = {
      isloading,
    selectedUsageTab,
    setSelectedUsageTab,
    currentMonth,
    setCurrentMonth,
    monthlyUsageLimit,
    monthlyUsageData,
    monthlySumData,
    costDataKeys,
    activityDataKeys,
    usageTier,
    spendPercentage,
    totalSpend,
    shortMonth,
      creditGrants,
      invoiceData,

  };

  return (
    <UsageContext.Provider value={value}>
      {children}
    </UsageContext.Provider>
  );
};

// Custom hook to use the context
export const useUsage = () => {
  const context = useContext(UsageContext);
  if (context === undefined) {
    throw new Error('useUsage must be used within a UsageProvider');
  }
  return context;
};
