import {
    TextInput,
    PasswordInput,
    Anchor,
    Paper,
    Title,
    Text,
    Container,
    Group,
    Button,
    Divider,
    Grid,
    Col, useMantineTheme, Loader,
} from '@mantine/core';
import {FacebookButton, GithubButton, GoogleButton} from "../SocialButtons/SocialButtons"
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {createStyles} from "@mantine/styles";
import React, {FormEvent, useEffect, useState} from "react";
import {PasswordInputWithValidation} from "../PasswordWithValidation";
import styles from "../auth.module.css"
import NutricloudLogo from "../../../componenets/logo/NutriCloudLogo";
import axios from "axios";
import qs from "qs";
import {StandardCompanyLogo} from "../../../componenets/icons/CompanyLogo";
import AppleLogin from 'react-apple-login';
import { GoogleLogin, GoogleLoginResponse, GoogleLoginResponseOffline } from 'react-google-login';
import {useAuth} from "../../../AuthContext";

const useStyles = createStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100vh',
        justifyContent: 'space-between',
        paddingTop: '0%',
    },
    links: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '2rem',
    },
    link: {
        fontSize: '0.9rem',
        color: theme.colorScheme === 'dark' ? theme.colors.orange[0] : theme.colors.gray[7],
    },
    description: {
        fontSize: '.9rem',
        color: theme.colorScheme === 'dark' ? theme.colors.gray[3] : theme.colors.gray[6],
        textAlign: 'center',
    },
    title: {
        fontFamily: `Greycliff CF, ${theme.fontFamily}`,
        fontWeight: 600 ,
        fontSize: theme.fontSizes.xl,
        textAlign: 'center',
        color: theme.colorScheme === 'dark' ? theme.colors.gray[2] : theme.colors.gray[6],
    },
    form: {
        marginTop: -30,
    },

    textInput: {
        width: "100%",
        color: theme.colors.gray[1]
    }
}));


export function Signup() {
    const theme = useMantineTheme();
    const navigate = useNavigate();
    const location = useLocation();
    const { classes } = useStyles();


    // dynamic form
    const [showPassword, setShowPassword] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordValid, setPasswordValid] = useState(false);
    const [authError, setAuthError] = useState<string | null>(null);
    const [registerLoading, setRegisterLoading] = useState(false);


    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
    };


    useEffect(() => {
        // Parse the query parameters
        const queryParams = new URLSearchParams(location.search);
        const emailParam = queryParams.get('email');
        if (emailParam) setEmail(decodeURIComponent(emailParam));
    }, [location]);

    // sign-up
    const { register } = useAuth();
    const handleSignUp = async () => {
        setRegisterLoading(true);
        const result = await register(email, password);
        if (result === "success") {
            navigate(`/onboarding?email=${encodeURIComponent(email)}`);
        } else {
            setAuthError(result);
        }
        setRegisterLoading(false);
    };

    const handleSubmit = (event: FormEvent) => {
        event.preventDefault();
        if (!showPassword) {
            setShowPassword(true);
        } else if (passwordValid) {
            setAuthError(null);
            handleSignUp();
        }
    };

      const handleEditClick = () => {
        setShowPassword(false);
        setAuthError(null);
      };



    return (
        <div className={classes.root}>
        <NutricloudLogo  size={100}/>
            <Container size={420} my={40}>

                {/*title*/}
                <Title
                    align="center"
                    className={classes.title}
                >
                    Create your account
                </Title>
                {/*if auth error show it*/}


                {/*form*/}
                <Paper p={0} pt={30} my={0} radius="md">
                    <form onSubmit={handleSubmit} className={classes.form}>
                        <Grid w={340}>
                            <Col>
                                <TextInput
                                    className={`${classes.textInput}`} // Add dynamic class
                                    type="email"
                                    label={!showPassword ? 'Email' : ''}
                                    placeholder="Email Address"
                                    value={email}
                                    onChange={handleEmailChange}
                                    required
                                    radius="xs"
                                    size="lg"
                                    readOnly={showPassword}
                                    rightSection={
                                        showPassword && (
                                            <Button
                                                variant="subtle"
                                                style={{
                                                    color: theme.colors.orange[1],
                                                    background: 'none',
                                                    border: 'none'
                                                }}
                                                onClick={handleEditClick}
                                                size="xs"
                                            >
                                                Edit
                                            </Button>
                                        )
                                    }
                                />
                                {showPassword && (
                                    <PasswordInputWithValidation
                                        password={password}
                                        setPassword={setPassword}
                                        onValidChange={setPasswordValid}
                                    />
                                )}
                            </Col>
                        </Grid>

                        {authError && (
                            <Text style={{maxWidth: 300, marginTop: 10}} color="red" align="center" size="sm">
                                {authError}
                            </Text>
                        )}

                        {registerLoading ? (
                            <div style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                padding: '16px 0',
                                margin: 7,
                            }}>
                                <Loader size="lg" />
                            </div>
                        ) : (
                        <Button className={styles.Box} fullWidth my="md" type="submit"
                                disabled={!passwordValid && showPassword}
                                color={theme.colors.gray[1]}
                                style={{backgroundColor: theme.colors.orange[0]}}>
                            {showPassword ? 'Sign up' : 'Continue'}
                        </Button>
                        )}

                        <Text color="dimmed" size="sm" align="center">
                            Already have an account?{' '}
                            <Anchor size="sm" component={Link} to="/login">
                                Log in
                            </Anchor>
                        </Text>
                    </form>


                    {/*continue with third party*/}
                    {!showPassword && (
                        <>
                            {/*<Divider label="OR" labelPosition="center" my="sm"/>*/}
                            {/*<Group position="center" spacing="sm">*/}
                            {/*    <AppleLogin*/}
                            {/*      clientId="your-client-id"*/}
                            {/*      redirectURI="your-redirect-uri"*/}
                            {/*      responseType="code"*/}
                            {/*      responseMode="query"*/}
                            {/*      scope="email name"*/}
                            {/*    />*/}
                            {/*    <FacebookButton>Continue with Facebook</FacebookButton>*/}
                            {/*    <GithubButton>Continue with Github</GithubButton>*/}
                            {/*    <GoogleLogin*/}
                            {/*      clientId="444718345124-9i985t2atsr45iemstq1dslrd3r99b6c.apps.googleusercontent.com"*/}
                            {/*      buttonText="Sign in with Google"*/}
                            {/*      onSuccess={handleGoogleLoginSuccess}*/}
                            {/*      onFailure={handleGoogleLoginFailure}*/}
                            {/*      cookiePolicy={'single_host_origin'}*/}
                            {/*    />*/}
                            {/*</Group>*/}
                        </>
                    )}
                </Paper>
            </Container>

            {/*privacy policy*/}
            <div className={classes.links}>
                <Link to="/policies/terms-of-use" className={classes.link}>Terms of
                    use</Link>
                <Text size="sm" mx="xs">|</Text>
                <Link to="/policies/privacy-policy" className={classes.link}>Privacy
                    policy</Link>
            </div>
        </div>
    );
}
