import {
    createStyles,
    Box,
    Flex,
    Text,
    Divider,
    SegmentedControl,
    Tooltip, useMantineTheme, Loader
} from '@mantine/core';
import React, {useState} from "react";
import {Info} from "react-feather";
import { Modal, TextInput, Group, Button } from '@mantine/core';
import axios from "axios";
import {useAuth} from "../../AuthContext";

const useStyles = createStyles((theme) => ({
  container: {
    width: '100%',
    borderRadius: theme.radius.sm,
  },
  row: {
    display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    '&:not(:last-child)': {
      marginBottom: theme.spacing.xs,
    },
  },
  leftContent: {
    flex: 1,
    marginRight: theme.spacing.xs,
  },
  rightContent: {
    flex: 1,
    marginLeft: theme.spacing.md,
      textAlign: 'right',
  },
}));


export type PermissionKey =
  | "app"
  | "auth"
  | "chat"
  | "classify"
  | "generate"
  | "info"
  | "search"
  | "suggest"
  | "payment"
  | "usage"
  | "user"
  | "verify"
  | "image"
  | "recognition"
  | "api_key";

export type Permissions = {
  [key in PermissionKey]: boolean;
};

export const defaultPermissions: Permissions = {
  app: true,
  auth: true,
  chat: true,
  classify: true,
  generate: true,
  info: true,
  search: true,
  suggest: true,
  payment: true,
  usage: true,
  user: true,
  verify: true,
  image: true,
  recognition: true,
  api_key: true
};

interface endpointDataType {
    apiPath: string;
    official_name: string;
    tooltip: string;
    endpoints: string[];
}

const endpointData: endpointDataType[] = [
    {
        apiPath: 'Classify',
        official_name: 'classify',
        tooltip: 'Analyzes and categorizes food-related data.',
        endpoints: [
            '/classify'
        ]
    },
    {
        apiPath: 'Info',
        official_name: 'info',
        tooltip: 'Provides comprehensive culinary information.',
        endpoints: [
            '/seasonality',
            '/locality',
            '/history',
            '/equipment',
            '/required-ingredients',
            '/nutrition',
            '/base-ingredient',
        ]
    },
    {
        apiPath: 'Chat',
        official_name: 'chat',
        tooltip: 'Enables intelligent food-related conversations.',
        endpoints: [
            '/chat'
        ]
    },
    {
        apiPath: 'Suggest',
        official_name: 'suggest',
        tooltip: 'Offers personalized culinary recommendations.',
        endpoints: [
            '/recipe',
            '/substitute',
            '/pairing',
            '/from-ingredients',
        ]
    },
    {
        apiPath: 'Image',
        official_name: 'image',
        tooltip: 'Processes and generates food imagery and recipes.',
        endpoints: [
            '/recognition',
            '/generate',
            '/ingredients-in-image',
            '/recipe',
        ]
    },
    {
        apiPath: 'Search',
        official_name: 'search',
        tooltip: 'Facilitates efficient food and recipe discovery.',
        endpoints: [
            '/autocomplete',
            '/search',
            '/name-standardization',
        ]
    },
    {
        apiPath: 'Generate',
        official_name: 'generate',
        tooltip: 'Creates customized culinary content.\n',
        endpoints: [
            '/image',
            '/ingredients',
            '/instructions',
            '/tips',
            '/cutting-instructions'
        ]
    },
    {
        apiPath: 'Users',
        official_name: 'user',
        tooltip: 'Authenticate, validate, and build profiles to manage users and their access to the API.',
        endpoints: [
            '/login',
            '/signup',
            '/verify-email',
            '/verify-phone',
            '/like-recipe',
            '/dislike-recipe',
            '/favorite-recipe',
        ]
    },
];


type ApiKeyPermissionsSelectorProps = {
    selectedPermissions: Permissions;
    setSelectedPermissions: (permissions: (currentPermissions: Permissions) => {
        app: boolean;
        classify: boolean;
        image: boolean;
        auth: boolean;
        usage: boolean;
        recognition: boolean;
        suggest: boolean;
        search: boolean;
        api_key: boolean;
        chat: boolean;
        verify: boolean;
        payment: boolean;
        generate: boolean;
        user: boolean;
        info: boolean
    }) => void;
};


// Usage in your component
const ApiKeyPermissionsSelector: React.FC<ApiKeyPermissionsSelectorProps> = ({setSelectedPermissions, selectedPermissions}) => {
      const { classes } = useStyles();
      const theme = useMantineTheme();
      // const [selectedPermissions, setSelectedPermissions] = React.useState<Permissions>(defaultPermissions);

  return (
    <div>
        <Box className={classes.container}>
            <Flex className={classes.row}>
                <Text fz={12} fw={600}>Resources</Text>
                <Text fz={12} fw={600}>Permissions</Text>
            </Flex>
            <Divider my="xs" />

          {endpointData.map((path, index) => (
            <div key={index}>
              <Flex className={classes.row}>
                  {/*left*/}
                  <div>
                      {/*title*/}
                      <div style={{display: "flex"}}>
                        <Text mr={5} fz={16} fw={600}>{path.apiPath}</Text>
                        <Tooltip
                          label={path.tooltip}
                          // className={classes.toolTip}
                          position={'top'}
                          transitionProps={{ transition: 'scale', duration: 200 }}
                          multiline
                          w={300}
                          radius={"xs"}
                          p={20}
                          fw={500}
                          fz={'14px'}
                          ml={15}

                          offset={-5}
                          bg={theme.colors.gray[9]}
                          color={theme.colors.gray[8]}
                        >
                          <div style={{display: 'inline-block'}}>
                            <Info size={16} color={'#666666'}/>
                          </div>
                        </Tooltip>
                      </div>
                        {/*endpoints*/}
                      {path.endpoints.map((endpoint, i) => (
                        <Text key={i} fz={12} color={theme.colors.gray[6]}>{endpoint}</Text>
                      ))}
                  </div>
                  {/*right*/}
                  <div>
                      <div className={classes.rightContent}>
                        <SegmentedControl
                          size={'xs'}
                          value={selectedPermissions[path.official_name as PermissionKey] ? 'allowed' : 'denied'}
                          onChange={(value: string) => {
                            setSelectedPermissions((currentPermissions: Permissions) => ({
                              ...currentPermissions,
                              [path.official_name as PermissionKey]: value === 'allowed'
                            }));
                          }}
                          data={[
                            { value: 'denied', label: 'Denied' },
                            { value: 'allowed', label: 'Allowed' },
                          ]}
                          styles={(theme) => ({
                            label: {
                              paddingLeft: 13,
                              paddingRight: 13,
                              paddingTop: 5,
                              paddingBottom: 5,
                            },
                          })}
                        />
                    </div>
                  </div>
              </Flex>
              {index < endpointData.length - 1 && <Divider my="xs" />}
            </div>
          ))}
        </Box>
    </div>
  );
};



interface EditApiKeyModalProps {
    createModalOpened: boolean;
    setCreateModalOpened: (value: boolean) => void;
    fetchApiKeys: () => void;
}

export const EditApiKeyModal: React.FC<EditApiKeyModalProps> = ({
    createModalOpened,
    setCreateModalOpened,
    fetchApiKeys
}) => {
  const theme = useMantineTheme();
    const [apiKeyName, setApiKeyName] = useState('');
    const [selectedPermissionLevel, setSelectedPermissionLevel] = useState('All');
    const [selectedPermissions, setSelectedPermissions] = React.useState<Permissions>(defaultPermissions);
    const [loadingCreateKey, setLoadingCreateKey] = useState(false);
    const permissionLevelOptions = ["All", "Restricted"];
    const {accessToken} = useAuth();


    const createApiKey = async (apiKeyName: string) => {
    try {
        setLoadingCreateKey(true);
        const baseURL = process.env.REACT_APP_BASE_URL;
        // console.log(selectedPermissions);
      const response = await axios.post(`${baseURL}/api/auth/api-key/`,
          {
              "name": apiKeyName,
              "permissions": selectedPermissions
          },
          {
                headers: {
                    'accept': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                },
              withCredentials: true
          }
      );
      setApiKeyName('');
        setSelectedPermissions(defaultPermissions);
        setSelectedPermissionLevel('All');
        console.log('API key created:', response.data);
        console.log('API key created:', response.data);
      // Refresh the table legacy_data after deletion by re-calling fetchApiKeys
      await fetchApiKeys(); // Make sure fetchApiKeys is accessible here
        setCreateModalOpened(false); // Close modal after save
    } catch (error) {
      console.error('Failed to create API key:', error);
    }
    setLoadingCreateKey(false);
  };

  return (
        <Modal
          opened={createModalOpened}
          onClose={() => setCreateModalOpened(false)}
          title={<div style={{fontWeight: 600, color: theme.colors.gray[1], fontSize: '24px'}}>Create new secret key</div>}
          size={525}
          centered={true}
         >
          <div style={{ padding: '20px' }}>
            <Text size="sm" style={{ marginBottom: '10px', color: theme.colors.gray[3], fontWeight: 500}}>Name<span style={{color: theme.colors.gray[6], marginLeft: '10px', fontWeight: 300, fontSize: '14px'}}>Optional</span></Text>
            <TextInput
              placeholder="my-api-key"
              value={apiKeyName}
              onChange={(event) => setApiKeyName(event.currentTarget.value)}
              // rightSection={<Text color="dimmed" size="sm" style={{ marginRight: '5px' }}>Optional</Text>}
              style={{ marginBottom: '20px' }}
              radius="sm" // Rounded corners
            />
            <div style={{ marginBottom: '20px' }}>
              <Text size="sm" style={{ marginBottom: '5px' }}>Permissions</Text>
            <SegmentedControl
              value={selectedPermissionLevel}
              onChange={setSelectedPermissionLevel}
              data={permissionLevelOptions}
              style={{ marginBottom: 20 }}
            />

            {selectedPermissionLevel === 'All' && (
              <div></div>
            )}
            {selectedPermissionLevel === 'Restricted' && (
              <ApiKeyPermissionsSelector selectedPermissions={selectedPermissions} setSelectedPermissions={setSelectedPermissions}/>
            )}
            </div>
            <Group position="right" spacing="md">
              <Button variant="default" onClick={() => setCreateModalOpened(false)}>Cancel</Button>
                {loadingCreateKey ? (
                    <Loader size={"lg"} />
                ) : (
                    <Button onClick={() => createApiKey(apiKeyName)}>Create secret key</Button>
                )}
            </Group>
          </div>
        </Modal>
  );
};

export default EditApiKeyModal;
