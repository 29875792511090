import React from 'react';
import {
    Paper,
    Text,
    Flex,
    Container,
    createStyles,
    Col,
    Grid,
    useMantineTheme, Accordion, Box, Center
} from '@mantine/core';
import { FaHome, FaUser, FaEnvelope, FaPhone, FaSearch, FaHeart, FaComment, FaBars } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { FaInfoCircle } from 'react-icons/fa';
import Demo from "../Components/Feedback";
import DocsPage from "../DocsPage";
import FeedbackBanner from "../Components/Feedback";
import {Prism} from "@mantine/prism";


const useStyles = createStyles((theme) => ({
    iconBox: {
        display: 'flex',
        justifyContent: 'flex-start', // Align items to the start (left side)
        alignItems: 'center',
        marginBottom: theme.spacing.md,
        '& svg': {
            transition: 'transform 0.3s ease',
        },
        '&:hover svg': {
            transform: 'scale(1.2)',
        },
    },
    contentBox: {
        marginLeft: theme.spacing.md, // Add some space between the icon and the text
    },

    // headers
    pageTitle: {
        textAlign: "left",
        fontSize: theme.fontSizes.xxl,
        fontWeight:600,
        marginTop: "30px",
        marginBottom: "0px",
    },
    mainHeader: {
        fontWeight: 500,
        fontSize: theme.fontSizes.xl,
        marginTop: "0px",
        marginBottom: "10px",
    },
    subHeader: {
        fontWeight: 500,
        color: theme.colors.gray[3],
        fontSize: theme.fontSizes.md,
        marginTop: "0px",
        marginBottom: "0px",
    },

    // bullet list
    link: {
        color: theme.colors.orange[0],
        textDecoration: 'none',
        '&:hover': {
            color: theme.colors.orange[9],  // Change this to the color you want on hover
        },
    },
    bulletTitle: {
        color: theme.colors.gray[0],
        fontWeight: 600,
    },
    bulletList: {
        listStyleType: 'disc',
    },
    bulletPoint: {
        fontSize: theme.fontSizes.sm,
        margin: '5px 0',
    },
    // text
    paragraphText: {
        fontSize: '16px',
        marginBottom: "10px",
    },

    // info boxes
    infoBox: {
        backgroundColor: theme.colors.gray[8],
        padding: theme.spacing.md,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        width: '100%',
        marginTop: '10px',
        marginBottom: '20px',
    },
    infoText: {
        marginLeft: theme.spacing.sm,
        fontSize: '16px',
    },
    infoIcon: {
        fontSize: '1em',
        flexShrink: 0,
    },

              gradientBorderBox: {
    display: 'inline-flex',
        backgroundImage: theme.fn.gradient({ from: 'pink', to: 'orange' }),
    borderRadius: '5px',
    padding: '2px', // Controls the thickness of the gradient border
    alignItems: 'center',
           // marginLeft: "50px",
    justifyContent: 'center',
      width: '300px',

      '@media (max-width: 800px)': {
        marginLeft: 0,
    },

  },
  innerBox: {
    backgroundColor: theme.colors.dark[7], // Should match the page's background
    borderRadius: '5px',
      width: '300px',
    paddingLeft: "30px",
    paddingRight: "30px",
    paddingTop: "5x",
    paddingBottom: "0px",
      color: theme.colors.gray[1]
  },
            accordionSubHeader: {
        fontWeight: 500,

        color: theme.colors.gray[3],
        fontSize: theme.fontSizes.md,
        marginTop: "0px",
        marginBottom: "15px",
    },
}));


// create gradient boxes
function DocsSearch() {
    const { classes } = useStyles();
    const theme = useMantineTheme();

    return (
        <Container>
            {/*title*/}
            <Text className={classes.pageTitle}>Food Search</Text>
            <Text className={classes.subHeader} style={{color: theme.colors.gray[5]}}>Learn
                how to search for food items and retrieve nutritional information with
                NutriCloud.</Text>
            <div style={{marginBottom: 30}}></div>

            {/*Introduction*/}
            <Text className={classes.mainHeader}>Introduction</Text>
            <Text className={classes.paragraphText}>The Search API allows you to find
                closely related food items based on your search queries and obtain
                detailed nutritional information for each item. By inputting one or
                multiple queries, you can receive a list of matching foods along with
                their nutrients. This can be used to:</Text>
            <ul className={classes.bulletList}>
                <li className={classes.bulletPoint}>
                    Retrieve nutritional data for specific foods.
                </li>
                <li className={classes.bulletPoint}>
                    Find alternative food options based on similarity.
                </li>
                <li className={classes.bulletPoint}>
                    Analyze macronutrients and micronutrients of various foods.
                </li>
            </ul>


            {/*quickstart*/}
            <Text className={classes.mainHeader}>Quickstart</Text>

            <Text className={classes.subHeader}>Search Food Endpoint</Text>
            <div style={{marginBottom: 15}}></div>
            <Text className={classes.paragraphText}>The Food Search Endpoint enables you
                to search for food items and get their nutritional information.</Text>
            <Text className={classes.paragraphText}>Get nutrient data for foods with
                tailored detail. Submit food queries and set optional filters: limit for
                result count, threshold for relevance, data_source for data accuracy,
                and nutrients to specify macronutrients or micronutrients. Flexible,
                accurate, and easy-to-use.</Text>

            <div style={{padding: 30}}>
                <Prism language={"bash"}>
                    {'curl -X POST \\\n' +
                        '  \'https://api.nutricloud.com/v1/search/food/\' \\\n' +
                        '  -H \'accept: application/json\' \\\n' +
                        '  -H \'Authorization: Bearer YOUR_API_KEY\' \\\n' +
                        '  -H \'Content-Type: application/json\' \\\n' +
                        '  -d \'{\n' +
                        '    "queries": [\n' +
                        '      "Salmon Filet"\n' +
                        '    ],\n' +
                        '    "limit": 1,\n' +
                        '    "threshold": 0,\n' +
                        '    "data_source": "verified",\n' +
                        '    "nutrients": "macronutrients"\n' +
                        '  }\'\n'}
                </Prism>
            </div>

            <Text className={classes.paragraphText}>The API will return a JSON response
                with the search results:</Text>
            <div style={{padding: 30}}>
                <Prism language={"bash"}>
                    {'[\n' +
                        '  {\n' +
                        '    "Salmon Filet": [\n' +
                        '      {\n' +
                        '        "food_name": "Salmon, fillet, pan-fried",\n' +
                        '        "nutrients": [\n' +
                        '          {\n' +
                        '            "unit": "g",\n' +
                        '            "value": 3.2,\n' +
                        '            "nutrient_name": "Carbohydrates"\n' +
                        '          },\n' +
                        '          {\n' +
                        '            "unit": "g",\n' +
                        '            "value": 19.4,\n' +
                        '            "nutrient_name": "Total Fat"\n' +
                        '          },\n' +
                        '          {\n' +
                        '            "unit": "g",\n' +
                        '            "value": 21.1,\n' +
                        '            "nutrient_name": "Protein"\n' +
                        '          },\n' +
                        '          {\n' +
                        '            "unit": "kcal",\n' +
                        '            "value": 271.21,\n' +
                        '            "nutrient_name": "Calories (kcal)"\n' +
                        '          }\n' +
                        '        ],\n' +
                        '        "portions": [\n' +
                        '          {\n' +
                        '            "unit": "g",\n' +
                        '            "value": 100\n' +
                        '          }\n' +
                        '        ],\n' +
                        '        "similarity_score": 0.759983659\n' +
                        '      }\n' +
                        '    ]\n' +
                        '  }\n' +
                        ']'}
                </Prism>
            </div>

            {/*    Understanding the nutrients*/}
            <Text mt={50} className={classes.mainHeader}>Key Features</Text>

            <Text className={classes.subHeader}>Nutrients</Text>
            <Text className={classes.paragraphText}>
                Our Search API allows you to query food items and retrieve their
                nutrient data with varying levels of detail. Choose from basic
                macronutrient information to a comprehensive list of micronutrients,
                including lab-sourced data with hundreds of nutrient details.
            </Text>

            <Accordion m={20} multiple defaultValue={["macronutrients"]}>
                <Accordion.Item value="macronutrients">
                    <Accordion.Control>
                        <Box className={classes.gradientBorderBox}>
                            <Box className={classes.innerBox}>
                                <Center>
                                    <Text size="md">Macronutrients</Text>
                                </Center>
                            </Box>
                        </Box>
                    </Accordion.Control>
                    <Accordion.Panel>
                        <Text className={classes.paragraphText}>The macronutrient level
                            provides essential nutrition data, focusing on calories,
                            protein, fat, and carbohydrates. This is ideal for general
                            diet tracking and offers a simple overview.</Text>
                        <ul className={classes.bulletList}>
                            <li className={classes.bulletPoint}>Calories</li>
                            <li className={classes.bulletPoint}>Protein</li>
                            <li className={classes.bulletPoint}>Fat</li>
                            <li className={classes.bulletPoint}>Carbohydrates</li>
                        </ul>
                    </Accordion.Panel>
                </Accordion.Item>

                <Accordion.Item value="micronutrients_simplified">
                    <Accordion.Control>
                        <Box className={classes.gradientBorderBox}>
                            <Box className={classes.innerBox}>
                                <Center>
                                    <Text size="md">Micronutrients Simplified</Text>
                                </Center>
                            </Box>
                        </Box>
                    </Accordion.Control>
                    <Accordion.Panel>
                        <Text className={classes.subHeader}>Nutrients available in
                            micronutrients simplified:</Text>
                        <ul className={classes.bulletList}>
                            <li className={classes.bulletPoint}>Vitamin K</li>
                            <li className={classes.bulletPoint}>Saturated Fat</li>
                            <li className={classes.bulletPoint}>Sorbitol</li>
                            <li className={classes.bulletPoint}>Sulfur</li>
                            <li className={classes.bulletPoint}>Fluoride</li>
                            <li className={classes.bulletPoint}>Maltitol</li>
                            <li className={classes.bulletPoint}>Linoleic Acid</li>
                            <li className={classes.bulletPoint}>Eicosapentaenoic Acid
                                (EPA)
                            </li>
                            <li className={classes.bulletPoint}>Copper</li>
                            <li className={classes.bulletPoint}>Chloride</li>
                            <li className={classes.bulletPoint}>Polydextrose</li>
                            <li className={classes.bulletPoint}>Xylitol</li>
                            <li className={classes.bulletPoint}>Manganese</li>
                            <li className={classes.bulletPoint}>Lycopene</li>
                            <li className={classes.bulletPoint}>Aspartame</li>
                            <li className={classes.bulletPoint}>Docosahexaenoic Acid
                                (DHA)
                            </li>
                            <li className={classes.bulletPoint}>Inulin</li>
                            <li className={classes.bulletPoint}>Erythritol</li>
                            <li className={classes.bulletPoint}>Sterols</li>
                            <li className={classes.bulletPoint}>Tryptophan</li>
                            <li className={classes.bulletPoint}>Maltodextrins</li>
                            <li className={classes.bulletPoint}>Branched Chain Amino
                                Acids (BCAA)
                            </li>
                            <li className={classes.bulletPoint}>Anhydrous Caffeine</li>
                            <li className={classes.bulletPoint}>Mannitol</li>
                            <li className={classes.bulletPoint}>Cellulose</li>
                            <li className={classes.bulletPoint}>Mono-Unsaturated Trans
                                Fat
                            </li>
                            <li className={classes.bulletPoint}>Choline</li>
                            <li className={classes.bulletPoint}>Total Monosaccharides
                            </li>
                            <li className={classes.bulletPoint}>Total Disaccharides</li>
                            <li className={classes.bulletPoint}>Total Sugar Alcohols
                            </li>
                            <li className={classes.bulletPoint}>Bromine</li>
                            <li className={classes.bulletPoint}>Nitrate</li>
                            <li className={classes.bulletPoint}>Vitamin D3
                                (cholecalciferol)
                            </li>
                            <li className={classes.bulletPoint}>Magnesium</li>
                            <li className={classes.bulletPoint}>Iron</li>
                            <li className={classes.bulletPoint}>Vitamin D</li>
                            <li className={classes.bulletPoint}>Added Sugars</li>
                            <li className={classes.bulletPoint}>Sodium</li>
                            <li className={classes.bulletPoint}>Vitamin B2
                                (Riboflavin)
                            </li>
                            <li className={classes.bulletPoint}>Vitamin D2
                                (ergocalciferol)
                            </li>
                            <li className={classes.bulletPoint}>Cholesterol</li>
                            <li className={classes.bulletPoint}>Vitamin B12
                                (Cobalamin)
                            </li>
                            <li className={classes.bulletPoint}>Vitamin B</li>
                            <li className={classes.bulletPoint}>Beta-Carotene</li>
                            <li className={classes.bulletPoint}>Vitamin K3 (menadione)
                            </li>
                            <li className={classes.bulletPoint}>Vitamin B5 (Pantothenic
                                Acid)
                            </li>
                            <li className={classes.bulletPoint}>Glucose</li>
                            <li className={classes.bulletPoint}>Vitamin B3 (Niacin)</li>
                            <li className={classes.bulletPoint}>Nitrogen</li>
                            <li className={classes.bulletPoint}>Natural Sugars</li>
                            <li className={classes.bulletPoint}>Iodine</li>
                            <li className={classes.bulletPoint}>Alpha-Carotene</li>
                            <li className={classes.bulletPoint}>Calcium</li>
                            <li className={classes.bulletPoint}>Vitamin B7 (Biotin)</li>
                            <li className={classes.bulletPoint}>Mono-Saturated Fat</li>
                            <li className={classes.bulletPoint}>Galactose</li>
                            <li className={classes.bulletPoint}>Fructose</li>
                            <li className={classes.bulletPoint}>Lactose</li>
                            <li className={classes.bulletPoint}>Sucrose</li>
                            <li className={classes.bulletPoint}>Citric Acid</li>
                            <li className={classes.bulletPoint}>Dietary Fiber</li>
                            <li className={classes.bulletPoint}>Added Sodium</li>
                            <li className={classes.bulletPoint}>Caffeine</li>
                            <li className={classes.bulletPoint}>Phosphorus</li>
                            <li className={classes.bulletPoint}>Poly-Saturated Fat</li>
                            <li className={classes.bulletPoint}>Omega-6 Fatty Acids</li>
                            <li className={classes.bulletPoint}>Essential Fatty Acids
                            </li>
                            <li className={classes.bulletPoint}>Vitamin E</li>
                            <li className={classes.bulletPoint}>Unsaturated Fat</li>
                            <li className={classes.bulletPoint}>Vitamin A</li>
                            <li className={classes.bulletPoint}>Vitamin K2
                                (menaquinone)
                            </li>
                            <li className={classes.bulletPoint}>Omega-9 Fatty Acids</li>
                            <li className={classes.bulletPoint}>Potassium</li>
                            <li className={classes.bulletPoint}>Vitamin B6
                                (Pyridoxine)
                            </li>
                            <li className={classes.bulletPoint}>Retinol</li>
                            <li className={classes.bulletPoint}>Vitamin B9 (Folate or
                                Folic Acid)
                            </li>
                            <li className={classes.bulletPoint}>Trans Fat</li>
                            <li className={classes.bulletPoint}>Mono-Unsaturated Fat
                            </li>
                            <li className={classes.bulletPoint}>Vitamin K1
                                (phylloquinone)
                            </li>
                            <li className={classes.bulletPoint}>Zinc</li>
                            <li className={classes.bulletPoint}>Pro-Vitamin A</li>
                            <li className={classes.bulletPoint}>Total Sugars</li>
                            <li className={classes.bulletPoint}>Poly-Unsaturated Fat
                            </li>
                            <li className={classes.bulletPoint}>Omega-3 Fatty Acids</li>
                            <li className={classes.bulletPoint}>Vitamin B1 (Thiamine)
                            </li>
                            <li className={classes.bulletPoint}>Vitamin C (Ascorbic
                                Acid)
                            </li>
                            <li className={classes.bulletPoint}>Soluble Fiber</li>
                        </ul>
                    </Accordion.Panel>
                </Accordion.Item>

                <Accordion.Item value="micronutrients_full">
                    <Accordion.Control>
                        <Box className={classes.gradientBorderBox}>
                            <Box className={classes.innerBox}>
                                <Center>
                                    <Text size="md">Micronutrients Full</Text>
                                </Center>
                            </Box>
                        </Box>
                    </Accordion.Control>
                    <Accordion.Panel>
                        <Text className={classes.paragraphText}>The full micronutrients option offers an extensive nutrient
                        profile,
                        including trace elements and lab-sourced data with hundreds of
                        unique nutrients, suitable for comprehensive dietary analysis</Text>

                    </Accordion.Panel>
                </Accordion.Item>
            </Accordion>


            <Text mt={50} className={classes.subHeader}>Batch Queries</Text>
            <Text className={classes.paragraphText}>The API supports batch searching
                through the queries parameter, allowing you to search for multiple foods
                in a single request:</Text>
            <div style={{margin: 30}}>
                <Prism language={"json"}>
                    {`{
  "queries": [
    "Salmon Filet",
    "Chicken Breast",
    "Sweet Potato"
  ]
}`}
                </Prism>
            </div>

            <Text mt={50} className={classes.subHeader}>Data Sources</Text>
            <Text className={classes.paragraphText}>Choose from three different data
                qualities options which define the ensured quality and truth of the
                nutritional data. Verified data, while comprehensive, does not have data
                for the extreme queries, while our unverified data covers every search
                query possible:</Text>
            <ul className={classes.bulletList}>
                <li className={classes.bulletPoint}>
                    <span
                        className={classes.bulletTitle}>verified:</span> Laboratory-verified
                    nutritional data with highest accuracy
                </li>
                <li className={classes.bulletPoint}>
                    <span className={classes.bulletTitle}>unverified:</span> Larger
                    dataset with good accuracy but less rigorous verification
                </li>
                <li className={classes.bulletPoint}>
                    <span
                        className={classes.bulletTitle}>all_sources:</span> Comprehensive
                    search across all available data sources
                </li>
            </ul>

            {/*optimize*/}
            <Text mt={50} className={classes.mainHeader}>Optimizations Tips</Text>
            <ul className={classes.bulletList}>
                <li className={classes.bulletPoint}>
                    <span
                        className={classes.bulletTitle}>Use Specific Queries:</span> More
                    specific queries (e.g., "grilled chicken breast" vs "chicken") yield
                    more accurate results
                </li>
                <li className={classes.bulletPoint}>
                    <span className={classes.bulletTitle}>Adjust Threshold:</span> Lower
                    thresholds return more results but may be less accurate
                </li>
                <li className={classes.bulletPoint}>
                    <span className={classes.bulletTitle}>Choose Appropriate Data Source:</span> Use
                    "verified" for highest accuracy or "all_sources" for broader
                    coverage
                </li>
                <li className={classes.bulletPoint}>
                    <span
                        className={classes.bulletTitle}>Select Nutrient Detail:</span> Only
                    request the nutrient detail level needed for your use case
                </li>
                <li className={classes.bulletPoint}>
                    <span className={classes.bulletTitle}>Batch Queries:</span> Combine
                    multiple searches in one request for better performance
                </li>
            </ul>


            {/*additional info*/}
            <Text mt={50} className={classes.mainHeader}>Additional Information</Text>
            <ul className={classes.bulletList}>
                <li className={classes.bulletPoint}>
                    <span
                        className={classes.bulletTitle}>Response Size:</span> Requesting
                    micronutrients_full may result in large responses, especially when
                    combined with a high limit.
                </li>
                <li className={classes.bulletPoint}>
                    <span className={classes.bulletTitle}>Accuracy:</span> While
                    "verified" data sources are lab-verified, "unverified" data may have
                    variances.
                </li>
                <li className={classes.bulletPoint}>
                    <span className={classes.bulletTitle}>Performance:</span> Large
                    numbers of queries or high limits may affect response times.
                </li>
            </ul>


            <FeedbackBanner/>
        </Container>
    );
}

export default function DocsSearchPage() {
    return (
        <div>
            <DocsPage children={<DocsSearch/>} activeItem={"food-search"}/>
        </div>
    );
}
