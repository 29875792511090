import React from 'react';
import {
    Text,
    Container,
    createStyles,
    useMantineTheme
} from '@mantine/core';
import {useNavigate} from 'react-router-dom';
import DocsPage from "../DocsPage";
import FeedbackBanner from "../Components/Feedback";



const useStyles = createStyles((theme) => ({
    iconBox: {
        display: 'flex',
        justifyContent: 'flex-start', // Align items to the start (left side)
        alignItems: 'center',
        marginBottom: theme.spacing.md,
        '& svg': {
            transition: 'transform 0.3s ease',
        },
        '&:hover svg': {
            transform: 'scale(1.3)',
            color: theme.colors.gray[0],
        },

    },
    contentBox: {
        marginLeft: theme.spacing.md, // Add some space between the icon and the text
    },
      animatedLink: {
          display: 'inline-flex',
          fontSize: '18px',
          alignItems: 'center',
          cursor: 'pointer',
          position: 'relative',
          textDecoration: 'none',
          color: theme.colorScheme === 'dark' ? theme.white : theme.black,
      },

    // headers
    pageTitle: {
        textAlign: "left",
        fontSize: theme.fontSizes.xxl,
        fontWeight:600,
        color: theme.colors.gray[3],
        marginBottom: "0px",
        marginTop: "100px",
    },
    mainHeader: {
        fontWeight: 500,
        fontSize: theme.fontSizes.xl,
        marginTop: "0px",
        marginBottom: "10px",
    },
    subHeader: {
        fontWeight: 500,
        fontSize: theme.fontSizes.lg,
        color: theme.colors.gray[5],
        // marginTop: "30px",
        marginBottom: "0px",
    },

    // bullet list
    link: {
        color: theme.colors.orange[0],
        textDecoration: 'none',
        '&:hover': {
            color: theme.colors.orange[9],  // Change this to the color you want on hover
        },
    },
    bulletList: {
        listStyleType: 'disc',
    },

    // text
    paragraphText: {
        fontSize: '16px',
        marginBottom: "10px",
        color: theme.colors.gray[3],
    },

    customText: {
        fontSize: '16px',
        color: theme.colors.gray[6]
    },

    // info boxes
    infoBox: {
        backgroundColor: theme.colors.gray[8],
        padding: theme.spacing.md,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        width: '100%',
        marginTop: '10px',
        marginBottom: '20px',
    },
    infoText: {
        marginLeft: theme.spacing.sm,
        fontSize: '16px',
    },
    infoIcon: {
        fontSize: '1em',
        flexShrink: 0,
    },
coloredBox: {
    border: `0.5px solid ${theme.colors.gray[8]}`,
    backgroundColor: theme.colors.gray[9],
        '&:hover': {
      backgroundColor: theme.colors.dark[5],
    },
    borderRadius: '8px',
    padding: 20,
    flex: '1 1 30%',  // Add this line to make boxes take equal space
    // minWidth: '250px', // Add this to prevent boxes from getting too narrow
},

    meetTheAPICardContainer: {
        display: 'flex',
        gap: '20px',
        flexDirection: 'row',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        [`@media (max-width: 1000px)`]: {
            flexDirection: 'column',
        },
    },
}));



function AppsDocs() {
    const {classes} = useStyles();
    const theme = useMantineTheme();
    const navigate = useNavigate();

    return (
        <Container>
            {/*title*/}
            <Text className={classes.pageTitle}>App</Text>
            <Text className={classes.subHeader}></Text>
            <Text className={classes.paragraphText}></Text>

            <Text className={classes.mainHeader}></Text>
            <Text className={classes.paragraphText}></Text>

            <FeedbackBanner/>
        </Container>
    );
}

export default function AppsDocsPage() {
    return (
        <div>
            <DocsPage children={<AppsDocs/>} activeItem={"app"}/>
        </div>
    );
}
