import React, {useEffect, useState} from 'react';
import {
    Paper,
    Progress,
    Text,
    Badge,
    Box,
    Grid,
    Divider,
    useMantineTheme, createStyles,
    Tooltip as MantineTooltip,
} from '@mantine/core';
import {getDaysInMonth} from "date-fns";
import {useAuth} from "../../../AuthContext";
import axiosInstance from "../../../axiosInstance";
import {useUsage} from "../usageContext";

const useStyles = createStyles((theme) => ({
    title: {
        fontSize: '12px',
        color: theme.colors.gray[1],
        fontWeight: 500,
    },
    data: {
        fontSize: '16px',
        color: theme.colors.gray[4],
        fontWeight: 300,
    },
    toolTip: {
        transition: 'all 0.3s ease',
        color: theme.colors.gray[1],
        border: `3px solid ${theme.colors.gray[1]}`,
        zIndex: 1000,
        fontSize: '10px',
    },

    inLineStyle: {
         display: 'inline-block'
    },
    toolTipText: {
        borderBottom: `2px dotted ${theme.colors.gray[7]}`,
    }
}));




export const CreditGrants: React.FC = () => {
    const theme = useMantineTheme();
    const { classes } = useStyles();
    const [error, setError] = useState<string | null>(null);
    const [creditsRemaining, setCreditsRemaining] = useState<number>(0);
    const [creditsGranted, setCreditsGranted] = useState<number>(0);
    const [creditsUsed, setCreditsUsed] = useState<number>(0);
    const [percentageUsed, setPercentageUsed] = useState<number>(0);
    const [percentageRemaining, setPercentageRemaining] = useState<number>(0);
    const {
    creditGrants,
  } = useUsage();

  const calculateCredits = () => {
    let totalGranted = 0;
    let totalRemaining = 0;

    creditGrants.forEach(grant => {
      if (grant.state === 'Active') { // Assuming 'Active' is the state that means the credits are still usable
        totalGranted += grant.total;
        totalRemaining += grant.balance;
      }
    });

    setCreditsGranted(parseFloat(totalGranted.toFixed(2)));
    setCreditsRemaining(parseFloat(totalRemaining.toFixed(2)));
    let tempCreditsUsed = totalGranted - totalRemaining;
    setCreditsUsed(parseFloat(tempCreditsUsed.toFixed(2)));

    if (totalGranted > 0) { // Prevent division by zero
      const used = totalGranted - totalRemaining;
      const usedPercentage = (used / totalGranted) * 100;
      const remainingPercentage = (totalRemaining / totalGranted) * 100;

      setPercentageUsed(parseFloat(usedPercentage.toFixed(2)));
      setPercentageRemaining(parseFloat(remainingPercentage.toFixed(2)));
    } else {
      setPercentageUsed(0);
      setPercentageRemaining(0);
    }
  };


  useEffect(() => {
    calculateCredits();
  }, [creditGrants]);


  return (
      <Box>
          <div style={{marginLeft: '20px', fontSize: '14px'}}>
              <p style={{
                  display: 'inline',
                  marginRight: '0px',
                  color: theme.colors.gray[1],
                  fontWeight: '700',
              }}>Credit Grants </p>
              <p style={{
                  display: 'inline',
                  color: theme.colors.gray[4],
              }}>USD</p>
          </div>
          <Grid pl={"md"} pr={"md"} columns={12}>
              <Grid.Col span={9}>
                  <Progress
                      sections={[
                          {value: percentageRemaining, color: 'orange'},
                          {value: percentageUsed, color: 'gray'},
                      ]}
                      size="xl"
                      radius="xs"
                  />
              </Grid.Col>
              <Grid.Col span={3} mt={-10}>
                <MantineTooltip
                    label={
                    <div>
                        You've used
                        <span style={{fontWeight: 800}}> ${creditsUsed}</span> out of the <span style={{fontWeight: 800}}>${creditsGranted} </span>
                        total credits granted to you.
                    </div>
                }
                  className={classes.toolTip}
                  position={'top'}
                  transitionProps={{ transition: 'scale', duration: 200 }}
                  multiline
                  w={300}
                  radius={"xs"}
                  p={20}
                  fw={500}
                  fz={'13px'}
                  mr={100}
                    offset={10}
                    bg={theme.colors.gray[9]}
                  color={theme.colors.gray[8]}
                >
                  <div className={classes.inLineStyle}>
                    <Text className={classes.toolTipText} size="xs" >${creditsRemaining} / ${creditsGranted}</Text>
                  </div>
                </MantineTooltip>
                </Grid.Col>
          </Grid>

          <Divider ml={20} mr={20} my="sm"/>


        <Paper pl={'md'} pr={'md'} radius="md" mt={0}>
          <Grid columns={12}>
              <Grid.Col span={3}>
                  <Text size="xs" className={classes.title}>AVAILABLE
                      FROM</Text>
              </Grid.Col>
              <Grid.Col span={3}>
                  <Text size="xs" className={classes.title}>STATUS</Text>
              </Grid.Col>
              <Grid.Col span={3}>
                  <Text size="xs" className={classes.title}>BALANCE</Text>
              </Grid.Col>
              <Grid.Col span={3}>
                  <Text size="xs" className={classes.title}>EXPIRATION
                      DATE</Text>
              </Grid.Col>
          </Grid>
        </Paper>

          {error && <Text color="red">{error}</Text>}
          {creditGrants.map((item, index) => {

              return (
                  <Paper pl={'md'} pr={'md'} radius="md" key={index} mt={index > 0 ? 'md' : 0}>

                      <div style={{
                          borderBottom: '1px solid',
                          borderColor: theme.colors.gray[5],
                          width: '100%',
                          marginTop: theme.spacing.xs,
                          marginBottom: theme.spacing.xs
                      }}></div>
                      <Grid columns={12}>
                          <Grid.Col span={3}>
                              <Text className={classes.data}
                                    weight={500}>{item.availableFrom}</Text>
                          </Grid.Col>
                          <Grid.Col span={3}>
                              <Badge fz={'10px'} p={10} radius={'xs'}
                                     color={item.state === 'Expired' ? 'red' : 'green'}
                                     variant="light">
                                  {item.state}
                              </Badge>
                          </Grid.Col>
                          <Grid.Col span={3}>
                              <Text
                                  className={classes.data}
                                  weight={500}>{`$${item.balance.toFixed(2)} / $${item.total.toFixed(2)}`}</Text>
                          </Grid.Col>
                          <Grid.Col span={3}>
                              <Text className={classes.data}
                                    weight={500}>{item.expirationDate}</Text>
                          </Grid.Col>
                      </Grid>

                  </Paper>
              );
          })}
      </Box>
  );
};

export default CreditGrants;
