import {
    TextInput,
    PasswordInput,
    Anchor,
    Paper,
    Title,
    Text,
    Container,
    Group,
    Button,
    Divider,
    Grid,
    Col, useMantineTheme, Loader,
} from '@mantine/core';
import {FacebookButton, GithubButton, GoogleButton} from "../SocialButtons/SocialButtons"
import {Link, useNavigate} from 'react-router-dom';
import {createStyles} from "@mantine/styles";
import React, {FormEvent, useEffect, useState} from "react";
import styles from "../auth.module.css"
import {PropIcon} from "../SocialButtons/SocialButtons";
import axios from "axios";
import qs from 'qs';
import {useAuth} from "../../../AuthContext";
import { useLocation } from 'react-router-dom';
import NutricloudLogo from "../../../componenets/logo/NutriCloudLogo";




const useStyles = createStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100vh',
        justifyContent: 'space-between',
        paddingTop: '0%',
    },
    links: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '2rem',
    },
    link: {
        fontSize: '0.9rem',
        color: theme.colorScheme === 'dark' ? theme.colors.orange[0] : theme.colors.gray[7],
    },
    description: {
        fontSize: '.9rem',
        color: theme.colorScheme === 'dark' ? theme.colors.gray[3] : theme.colors.gray[6],
        textAlign: 'center',
    },
    title: {
        fontFamily: `Greycliff CF, ${theme.fontFamily}`,
        fontWeight: 600 ,
        fontSize: theme.fontSizes.xl,
        textAlign: 'center',
        color: theme.colorScheme === 'dark' ? theme.colors.gray[2] : theme.colors.gray[6],
    },
    form: {
        marginTop: -30,
        width: '100%',
    },
    textInput: {
        width: "100%",
        color: theme.colors.gray[1]
    }
}));


export function Login() {
    //
    // setting
    //
    const theme = useMantineTheme();
    const { classes } = useStyles();
    const navigate = useNavigate();


    // dynamic form

    const [showPassword, setShowPassword] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [authError, setAuthError] = useState<string | null>(null);

    const [authLoading, setAuthLoading] = useState(false);


    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
    };
    const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
    };

    const handleEdit = () => {
        setShowPassword(false);
    };

    //
    // auth
    //
    const { login, isAuthenticated } = useAuth();


    const handleLogin = async () => {
        setAuthLoading(true);
        const result = await login(email, password);
        if (result !== "success") {
            setAuthError(result); // Set error message from login function
        } else {
            setAuthError(''); // Clear any previous errors on successful login
        }
        setAuthLoading(false);
    };

    const handleEditClick = () => {
        setShowPassword(false);
      };

    const handleSubmit = (event: FormEvent) => {
        event.preventDefault();
        if (!showPassword) {
            setShowPassword(true);
        } else {
            handleLogin()
        }
    };

    return (
        <div className={classes.root}>
        <NutricloudLogo  size={100}/>
            <Container size={420} my={40}>

                {/*title*/}
                <Title
                    align="center"
                    className={classes.title}
                    mb={10}
                >
                    {showPassword ? 'Enter your password' : 'Welcome back'}
                </Title>


                {/*form*/}
                <Paper p={0} pt={30} my={0} radius="md">
                    <form onSubmit={handleSubmit} className={classes.form}>
                        <Grid>
                            <Col style={{ position: 'relative', width: "100%"}}>
                                <TextInput
                                    className={`${classes.textInput}`} // Add dynamic class
                                    type="email"
                                    label={showPassword ? '' : 'Email'}
                                    placeholder="Email Address"
                                    value={email}
                                    onChange={handleEmailChange}
                                    required
                                    radius="xs"
                                    size="lg"
                                    readOnly={showPassword}
                                    rightSection={
                                    showPassword && (
                                      <Button
                                        variant="subtle"
                                        style={{ color: theme.colors.orange[1], background: 'none', border: 'none' }}
                                        onClick={handleEditClick}
                                        size="xs"
                                      >
                                        Edit
                                      </Button>
                                    )
                              }
                                />
                                {showPassword && (
                                    <div>
                                        <PasswordInput
                                            placeholder="Password"
                                            value={password}
                                            onChange={handlePasswordChange}
                                            required mt="md"
                                            radius="xs"
                                            size="lg"
                                        />
                                        {showPassword && (
                                            <div style={{marginTop: 10, color: 'red', maxWidth: 300, marginLeft: 5}}>
                                                <Text inline
                                                  style={{fontSize: '12px'}}>{authError}</Text>
                                            </div>
                                        )}
                                        <Group position="apart" mt="xs" ml="xs">
                                            <Anchor component="button" size="sm">
                                            Forgot password?
                                            </Anchor>
                                        </Group>
                                    </div>
                                )}

                            </Col>
                        </Grid>

                        {authLoading ? (
                            <div style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                padding: '16px 0',
                                margin: 7,
                            }}>
                                <Loader size="lg" />
                            </div>
                        ) : (
                            <Button className={styles.Box} fullWidth my="md" type="submit">
                                {showPassword ? 'Login' : 'Continue'}
                            </Button>
                        )}


                    </form>

                    <Text color="dimmed" size="sm" align="center">
                        Don't have an account?{' '}
                        <Anchor size="sm" component={Link} to="/signup">
                            Sign up
                        </Anchor>
                    </Text>

                    {/*continue with third party*/}
                    {!showPassword &&(
                        <>
                            {/*<Divider label="OR" labelPosition="center" my="sm"/>*/}
                            {/*<Group position="center" spacing="sm">*/}
                            {/*    <FacebookButton>Continue with Facebook</FacebookButton>*/}
                            {/*    <GithubButton>Continue with Github</GithubButton>*/}
                            {/*    <GoogleButton>Continue with Google</GoogleButton>*/}
                            {/*</Group>*/}
                        </>
                    )}
                </Paper>
            </Container>

            {/*privacy policy*/}
            <div className={classes.links}>
                <Link to="/policies/terms-of-use" className={classes.link}>Terms of use</Link>
                <Text size="sm" mx="xs">|</Text>
                <Link to="/policies/privacy-policy" className={classes.link}>Privacy policy</Link>
            </div>
        </div>
    );
}
