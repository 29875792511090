interface Params {
    [key: string]: any;
}

const getCurlCommand = (url: string, parameters: Params, currentParameters: Params): string => {
    let command = `curl -X POST "${url}" \\\n     -H "Content-Type: application/json" \\\n     -H "api-key: $NUTRICLOUD_API_KEY"`;

    let data = {...parameters};
    for(let param in data) {
        if(data[param] === currentParameters[param]) {
            delete data[param];
        }
    }

    if(Object.keys(data).length > 0) {
        let jsonIndent = '         ';
        let dataString = JSON.stringify(data, null, 2).split('\n').map(line => jsonIndent + line).join('\n');
        command += ` \\\n     -d '\n${dataString}\n        '`;
    }
    return command;
};

export default getCurlCommand;
