import {
    Button,
    createStyles,
    Paper,
    Select,
    Text, Tooltip,
    useMantineTheme
} from '@mantine/core';
import { Link } from 'react-router-dom';
import {rem} from "@mantine/core";
import Sidebar from "../../componenets/sidebar/sidebar";
import React from "react";
import { notifications } from '@mantine/notifications';
import { TextInput, Group, Box, Title } from '@mantine/core';


const useStyles = createStyles((theme) => ({
    pageTitle: {
        textAlign: 'left',
        marginTop: 15,
        marginBottom: 10,
        fontSize: '1.5rem',
        fontWeight: 700,
        marginLeft: theme.spacing.md,
        color: theme.colors.gray[0],
        borderBottom: `1px dotted ${theme.colors.gray[7]}`,
        lineHeight: 'normal', // Adjust this line
    },

        toolTip: {
        transition: 'all 0.3s ease',
        backgroundColor: theme.colors.gray[0],
        color: theme.colors.gray[9],
        border: `1px solid ${theme.colors.gray[5]}`,
        zIndex: 1000,
        fontSize: '10px',
    },

    inLineStyle: {
         display: 'inline-block'
    },

    header: {
        textAlign: 'left',
        fontSize: "24px",
        color: theme.colors.gray[3],
        fontWeight: 600,
        marginLeft: '25px',
    },

    finePrint: {
        fontSize: '12px',
        color: theme.colors.gray[3],
        marginLeft: '25px',
    },

    pageLine: {
        width: '200%',
        height: '1px',
        backgroundColor: theme.colors.gray[7],
        marginBottom: theme.spacing.md,
        marginLeft: 'auto', // Add this line
        marginRight: 'auto', // Add this line
    },

    paragraphText: {
        fontSize: '16px',
        fontWeight: 300,
        color: theme.colors.gray[0],
        marginLeft: '25px',
    },

    rightDiv: {
        maxWidth: '1000px',
        marginLeft: '25px',
        '@media (min-width: 1280px)': {
            marginLeft: 0,
        },
    },

    leftDiv: {
        marginBottom: '40px',
    },

    separatingDiv: {
        height: '10px',
        '@media (min-width: 1280px)': {
            height: '15px',
        },
    },
    largeSeparatingDiv: {
        height: '60px',
        '@media (min-width: 1280px)': {
            height: '80px',
        },
    },

    paragraphBoldText: {
        fontWeight: 'bold',
    },

    highlightedLink: {
        color: theme.colors.orange[0],
        textDecoration: 'none',
    },

    table: {
        width: '100%',
        borderCollapse: 'collapse',
        marginLeft: '20px',
    },

    cell: {
        padding: '0px',
        textAlign: 'left',
        borderBottom: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.gray[0] : theme.colors.gray[0]}`,
        minWidth: '100px',
        marginLeft: '0px',
        marginRight: '0px',

        fontSize: '14px',
        color: theme.colors.gray[3],

    },
    bigCell: {
        padding: '0px',
        textAlign: 'left',
        borderBottom: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.gray[0] : theme.colors.gray[0]}`,
        marginLeft: '0px',
        marginRight: '0px',
        fontSize: '14px',
        color: theme.colors.gray[3],
        minWidth: '100px',
        '@media (min-width: 1000px)': {
            minWidth: '200px',
        },
    },
    tableHeader: {
        borderBottomWidth: '2px',  // make the header line bolder
        fontWeight: 'bold',
    },
    lastRow: {
        borderBottom: 'none',  // remove the bottom border from the last row
    },

    buttonColor: {
        backgroundColor: theme.colors.orange[1],
        fontSize: '14px',
        height: '35px',
        color: theme.colors.gray[9],
    },
    container: {
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
    paddingLeft: theme.spacing.md,
    borderRadius: theme.radius.md,
    boxShadow: theme.shadows.sm,
        maxWidth: '400px',
  },
  title: {
    marginBottom: theme.spacing.md,
  },
  input: {
    marginBottom: theme.spacing.sm,
  },
  button: {
    marginTop: theme.spacing.md,
  },
}));

interface UserProfileFormValues {
  name: string;
  email: string;
  phoneNumber: string;
}

  const handleSubmit = (values: UserProfileFormValues) => {
    console.log(values);
  };

  const handleEnableMFA = () => {
    // Logic to enable MFA
  };

export function Organization() {
    const theme = useMantineTheme();
    const { classes } = useStyles();
    return (
        <div>
            <Sidebar defaultExpanded={true} activeItem="organization"/>
            <div style={{marginLeft: "200px"}}>
                {/*title*/}
                {/*title*/}
                <Tooltip
                  label="Manage your profile settings."
                  className={classes.toolTip}
                  position={'bottom'}
                  transitionProps={{ transition: 'scale', duration: 200 }}
                  multiline
                  w={300}
                  radius={"xs"}
                  p={20}
                  fw={500}
                  fz={'14px'}
                  ml={15}
                  offset={-5}
                  bg={theme.colors.gray[9]}
                  color={theme.colors.gray[8]}
                >
                  <div className={classes.inLineStyle}>
                    <Text size="xs" className={classes.pageTitle}>Profile</Text>
                  </div>
                </Tooltip>
                <div className={classes.pageLine}></div>
                {/* info*/}
                <div className={classes.rightDiv}>
                        <Box className={classes.container}>
                          <form onSubmit={(event) => {
                            event.preventDefault();
                            handleSubmit({
                              name: 'William Bohm',
                              email: 'wsbohm@gmail.com',
                              phoneNumber: '+19195923639',
                            });
                          }}>
                            <TextInput label="Name" description="The name associated with this account" placeholder="Your name" className={classes.input} />
                            <TextInput label="Email address" description="The email address associated with this account" placeholder="Your email" className={classes.input} />
                            <TextInput label="Phone number" description="The phone number associated with this account" placeholder="Your phone number" className={classes.input} />

                            <Group position="left" className={classes.button}>
                              <Button type="submit">Save</Button>
                            </Group>

                            <Group position="left" mt="md">
                                <Text fz={24} fw={600}>Multi-factor authentication (MFA)</Text>
                                <Text fz={14}>Require an extra security challenge when logging in. If you are unable to pass this challenge, you will have the option to recover your account via email.</Text>
                              <Button variant="default" onClick={handleEnableMFA}>Enable MFA</Button>
                            </Group>
                          </form>
                        </Box>
                </div>
            </div>
        </div>
    );
}

export default Organization;
