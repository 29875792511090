import React, { FC, useState } from 'react';
import styles from '../Home/LandingPage.module.css'
import {InputWithButton} from "../../componenets/Search/searchBar";
import LandingPageTypewriteText from "../Home/typewriterText";
import ApiOne from "../Home/api1";
import ApiTwo from "../Home/api2";
import ApiThree from "../Home/api3";
import {
    Box, Button, Center,
    Col,
    createStyles,
    Flex, Grid,
    Group, List,
    Text,
    useMantineTheme
} from "@mantine/core";
import {FeaturesCards} from "./oldPricing/CallToAction";
import ThreeDContainer from "../LandingPage/components/threeDContainer";
import SDKSection from "../LandingPage/components/sdk/sdkSection";
import ScrollRevealGradientLine from "../LandingPage/components/coloredLine";
import AnimatedLink from "../LandingPage/components/animatedLink";
import {PythonIcon} from "../../componenets/icons/Python";
import {JavaIcon} from "../../componenets/icons/Java";
import {TsIcon} from "../../componenets/icons/TypeScript";
import {Prism} from "@mantine/prism";
import {
    Activity,
    ArrowRightCircle, ChevronDown,
    Circle,
    Codesandbox, DollarSign,
    HelpCircle
} from "react-feather";
import UsageDashboard from "./UsageDashboard";
import ScrollRevealIcon from "../LandingPage/components/bloomIcon";
import PricingTable from "./pricingTable";
import {
    chatApiPricingData,
    classifyApiPricingData, generateApiPricingData, imageApiPricingData,
    infoApiPricingData, searchApiPricingData, suggestApiPricingData
} from "./apiPricingData";
import PricingTables from "./pricingTables";
import {FaqSimple} from "./oldPricing/PricingFaq";
import {Link} from "react-router-dom";


const useStyles = createStyles((theme, { bloomColor }: { bloomColor: string }) => ({
    apiComponent: {
        marginBottom: theme.spacing.xxl,
    },

    // page: {
    //     background: `url('http://www.script-tutorials.com/demos/360/images/stars.png')`,
    //     backgroundSize: 'cover',
    //     backgroundAttachment: 'fixed',
    //     backgroundRepeat: 'no-repeat',
    // },
    page: {
        maxWidth: 1400,
        paddingLeft: 20,
        width: "100%",
        marginTop: "10vh",
            display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center', // Centers the title block vertically
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    glowingBox: {
        backgroundColor: theme.colors.dark[8],
        // maxWidth: 1000,
        borderRadius: theme.radius.lg,
        padding: theme.spacing.sm ,
        boxShadow: `0 0 300px ${theme.colors.orange[0]}`,
        textAlign: 'center',
        marginTop: theme.spacing.xl,
      },
      numberText: {
        fontSize: 42,
        fontWeight: 'bold',
        color: theme.colors.gray[3],
      },
        topSection: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "20px",
        padding: '2vh',
    },
    glowTitleText: {
        fontSize: '3rem',
        fontWeight: 500,
        maxWidth: "60vw",
        lineHeight: '1.1',
        marginLeft: 65,
            [`@media (max-width: 1300px)`]: {
      fontSize: "2.5rem",
                marginLeft: 40,
    },
        [`@media (max-width: 750px)`]: {
      fontSize: "2rem",
            maxWidth: "80vw",
            marginLeft: 20,
    },
    },


        description: {
        fontSize: '1rem',
        marginTop: '20px',
        color: theme.colors.gray[5]
    },
        overallSection: {
        display: "flex",
        flexDirection: "column",
    },
        smallSection: {
        display: "flex",
        // justifyContent: "left",
        alignItems: "center",
        flexDirection: "row",

    },
    pipInstall: {
        // marginLeft: "auto",
        // marginTop: "-20vh",
        // marginRight: "-10vw",
        // width: '23vw',
        // maxWidth: 200,
        [`@media (min-width: 1200px)`]: {
            marginTop: "-8vh",
        },

    },
    bloomEffect: {
      position: 'absolute', // Keep the bloom effect behind the icon
      backgroundColor: bloomColor,
      // borderRadius: '50%',
        marginLeft: "0vw",
        marginTop: "27vh",
      boxShadow: `0 0 190px 200px ${bloomColor}`, // Increase blur radius and spread for a more gradual effect
      opacity: 0.6, // Lower the opacity for a lighter effect
        zIndex: -1,
            [`@media (max-width: 830px)`]: {
          opacity: 0.2,
      marginLeft: "0vw",
                boxShadow: `0 0 190px 230px ${bloomColor}`, // Increase blur radius and spread for a more gradual effect
    },
    },
        titleContainer: {
          display: 'flex', // Enables flexbox
    flexDirection: 'row', // Aligns children in a row
        //align at the top
        // height: '40vh',
    alignItems: 'flex-start', // Centers items vertically in the container
    // justifyContent: 'left', // Centers items horizontally in the container
    gap: theme.spacing.lg, // Adds some space between items
        marginTop: theme.spacing.md,
        // marginLeft: 40,
    },

    glowHeader: {
    display: 'flex', // Enables flexbox
    flexDirection: 'row', // Aligns children in a row
        //align at the top
    alignItems: 'center', // Centers items vertically in the container
    // justifyContent: 'left', // Centers items horizontally in the container
    gap: theme.spacing.lg, // Adds some space between items
        marginTop: theme.spacing.md,
        // marginLeft: 30,

  },

//     special box
          gradientBorderBox: {
    display: 'inline-flex',
        backgroundImage: theme.fn.gradient({ from: 'pink', to: 'orange' }),
    borderRadius: '5px',
    padding: '2px', // Controls the thickness of the gradient border
    alignItems: 'center',
    justifyContent: 'center',
      // width: '500px',
  },
  innerBox: {
    backgroundColor: theme.colors.dark[7], // Should match the page's background
    borderRadius: '5px',
      // width: '500px',
    // paddingTop: "5x",
    // paddingBottom: "0px",
      color: theme.colors.gray[1],

  },
    grandientBoxText: {
        fontSize: "3rem",
        fontWeight: 500,
        padding: 10,
        [`@media (max-width: 1270px)`]: {
      fontSize: "2.5rem",
    },
      [`@media (max-width: 750px)`]: {
      fontSize: "2rem",
    },
      [`@media (max-width: 480px)`]: {
      fontSize: "1.5rem",
    },

    },
        pageLine: {
        width: '100%',
        height: '1px',
        backgroundColor: theme.colors.gray[6],
        marginBottom: theme.spacing.md,
        marginLeft: 'auto', // Add this line
        marginRight: 'auto', // Add this line
    },

    titleText: {
        fontSize: 120,
        [`@media (max-width: 1300px)`]: {
            fontSize: 80,
        },
        [`@media (max-width: 750px)`]: {
            fontSize: 60,
        },
    },
    subTitleText: {
        fontSize: 20,
        marginLeft: 10,
        [`@media (max-width: 1300px)`]: {
            fontSize: 18,
        },
        [`@media (max-width: 750px)`]: {
            fontSize: 16,
            marginLeft: 5,
        },
    },
    shortLinearGradient: {
      height: 200,
        [`@media (max-width: 767px)`]: {
          height: 200,
      width:  "100%",
    }},


    bottomCallToAction: {
        fontSize: "4rem",
        [`@media (max-width: 1300px)`]: {
            fontSize: "3rem",
        },
        [`@media (max-width: 1000px)`]: {
            fontSize: "2.4rem",
        },
        [`@media (max-width: 750px)`]: {
            fontSize: "2rem",
        },
        [`@media (max-width: 500px)`]: {
            fontSize: "1.5rem",
        },
    },

    bigParagraphTitle: {
        fontWeight: 500,
        fontSize: theme.fontSizes.lg,

        [theme.fn.largerThan('sm')]: {
          fontSize: theme.fontSizes.xl,
        },

        [theme.fn.largerThan('md')]: {
          fontSize: theme.fontSizes.xxl,
        },
    },

      paragraphTitle: {
        fontWeight: 800,
        fontSize: theme.fontSizes.md,

        [theme.fn.largerThan('sm')]: {
          fontSize: theme.fontSizes.xl,
        },

        [theme.fn.largerThan('md')]: {
          fontSize: theme.fontSizes.xl,
        },
      },

      paragraphText: {
        fontSize: theme.fontSizes.sm,

        [theme.fn.largerThan('sm')]: {
          fontSize: theme.fontSizes.sm,
        },

        [theme.fn.largerThan('md')]: {
          fontSize: theme.fontSizes.md,
        },
      },

    getStartedButton: {
        fontSize: "2rem",
        [`@media (max-width: 300px)`]: {
            fontSize: "1.5rem",
        },
    },

    adjustableMarginTitle: {
        marginTop: 100,
        [`@media (max-width: 1100px)`]: {
            marginTop: 50
        },
    }
}));

// API pricing legacy_data

const NewPricing: FC = () => {
        const theme = useMantineTheme();

    const bloomColor = '#fbb149';
  const { classes } = useStyles({ bloomColor });
  const imageUrl = `${process.env.PUBLIC_URL}/landingPage/embeddings-representation.png`;


    const serviceColor = "#FFA500"


    // scroll logic
    const scrollToAPIPrices = () => {
        const targetElement = document.getElementById('api-prices');
        if (targetElement) {
          const offset = 100; // Adjust as needed
          const elementPosition = targetElement.getBoundingClientRect().top + window.pageYOffset;
          const offsetPosition = elementPosition - offset;

          window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth',
          });
        }
    };



    return (
        <div className={classes.page} >
            {/*header*/}
            <div>
                <Text className={classes.titleText} color={theme.colors.gray[0]} mb={0}
                      pb={0}>Pricing</Text>
                <Text className={classes.subTitleText} color={theme.colors.gray[0]}>Intuitive
                    pricing
                    for intuitive apis</Text>
            </div>
            {/*giant glowing box with numbers in the middle*/}
            <div style={{
                // maxWidth: 1400,
                marginRight: 20,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                }}
            className={classes.adjustableMarginTitle}
            >
                <ThreeDContainer
                    bloomColor={'radial-gradient(circle, rgba(255, 165, 0,0.2) 0%, rgba(63, 185, 80,0) 70%)'}>
                    <Grid p={30} align={"center"} justify={"center"}
                          className={classes.topSection}>
                        <Col span={12} lg={6}>
                            <Text ml={0}
                                className={classes.bigParagraphTitle}>One price for
                                <span
                                    style={{color: serviceColor}}> all developers</span></Text>
                            <Text
                                className={classes.description}>
                                We believe in making our API accessible to everyone,
                                from indie developers to large enterprises. That's why
                                we offer a single, straightforward pricing model that
                                works for all.
                            </Text>
                            <Text mt={40} ml={0}
                                  className={classes.bigParagraphTitle}>
                                <span
                                    style={{color: serviceColor}}> Focus on Development</span>,
                                Not Accounting</Text>
                            <Text
                                className={classes.description}>
                                Our token-based pricing system is designed to be simple
                                and intuitive, so you can spend more time building
                                amazing applications and less time worrying about
                                complex pricing structures. </Text>
                        </Col>
                        <Col span={12} lg={6} style={{
                            justifyContent: 'center',
                            alignItems: 'center',
                            display: 'flex',
                        }}>
                            <Box className={classes.gradientBorderBox}>
                                <Box className={classes.innerBox}>
                                    <Center>
                                        <Text className={classes.grandientBoxText}
                                              size="md">1000 tokens = $0.01</Text>
                                    </Center>
                                </Box>
                            </Box>
                        </Col>

                    </Grid>
                </ThreeDContainer>
            </div>
            {/*first card*/}
            <div style={{marginLeft: 10}}>
                <ScrollRevealGradientLine colorStart="rgba(251, 177, 73, 0)"
                                          colorMiddle="#fbb149"
                                          colorEnd="#fbb149"
                                          leftContent={
                                              <div className={classes.overallSection}>
                                                  <Grid pt={20} pr={20} pb={20} ml={10} justify={"flex-end"}>
                                                      {/*text*/}
                                                      <Col span={12} sm={12} md={7}>
                                                          <Text className={classes.paragraphText}><span
                                                              className={classes.paragraphTitle}>Pricing Made Easy.</span> No
                                                              more complicated tiers,
                                                              hidden fees, or long-term
                                                              contracts. With our API,
                                                              you only pay for what you
                                                              use, when you use
                                                              it.</Text>
                                                          <div>
                                                              <AnimatedLink
                                                                  text={"Explore our APIs"}
                                                                  path={"/"}/>
                                                          </div>
                                                      </Col>
                                                      {/*  pip install*/}
                                                      <Col span={12} sm={12} md={5}
                                                           className={classes.pipInstall}
                                                           style={{
                                                               justifyContent: 'center',
                                                               // alignItems: 'center',
                                                               display: 'flex',
                                                           }}>
                                                          <div
                                                              className={classes.bloomEffect}>
                                                          </div>
                                                          <div style={{
                                                              maxWidth: 450,
                                                          }}>
                                                              <ThreeDContainer>
                                                                  <div
                                                                      style={{padding: 30}}>
                                                                      <Text
                                                                          className={classes.glowTitleText}>
                                                                        <span
                                                                            style={{color: theme.colors.gray[4]}}>How it works</span>
                                                                      </Text>
                                                                      <Text
                                                                          className={classes.description}>Our
                                                                          token-based
                                                                          pricing system
                                                                          is
                                                                          designed to be
                                                                          simple,
                                                                          transparent,
                                                                          and
                                                                          predictable.
                                                                          Here's a more
                                                                          detailed look
                                                                          at
                                                                          how it
                                                                          works:</Text>
                                                                      <List mt={20}
                                                                            mr={20}
                                                                            spacing={'md'}
                                                                            size={"md"}
                                                                      >
                                                                          <List.Item className={classes.paragraphText}>Each
                                                                              endpoint
                                                                              consumes a
                                                                              specific
                                                                              number of
                                                                              tokens</List.Item>
                                                                          <List.Item className={classes.paragraphText}>1,000
                                                                              tokens
                                                                              cost just
                                                                              $0.01</List.Item>
                                                                          <List.Item className={classes.paragraphText}>Purchase
                                                                              tokens as
                                                                              needed and
                                                                              use them
                                                                              across all
                                                                              endpoints</List.Item>
                                                                      </List>
                                                                        <Button onClick={scrollToAPIPrices} mt={20} fullWidth>
                                                                          <Flex
                                                                            direction="row"
                                                                            justify="center"
                                                                            align="center"
                                                                            wrap="wrap"
                                                                          >
                                                                            <Text mr={10} style={{ textAlign: 'center', flexGrow: 1, whiteSpace: 'normal' }}>
                                                                              Explore below
                                                                            </Text>
                                                                            <ChevronDown size={24} />
                                                                          </Flex>
                                                                        </Button>

                                                                  </div>
                                                              </ThreeDContainer>
                                                          </div>
                                                      </Col>
                                                  </Grid>
                                              </div>
                                          }
                />
            </div>

            {/*usage dashboard*/}
            <div>
                <div className={classes.glowHeader}>
                    <ScrollRevealIcon
                        bloomColor="#D32122"
                        IconComponent={Activity}
                        string={"" +
                            "Dashboard"}
                    />
                </div>
                <div className={classes.titleContainer} style={{marginLeft: 10}}>
                    <ScrollRevealGradientLine colorStart="#D32122"
                                              colorMiddle="#D32122"
                                              colorEnd="rgb(211, 33, 34, 0)"
                                              leftContent={
                                                  <div>
                                                      <Text
                                                          className={classes.glowTitleText}
                                                          style={{color: "#D32122"}}>Pay
                                                          confidently</Text>
                                                      <Text
                                                          className={classes.glowTitleText}
                                                          mb={100}>with
                                                          NutriClouds extensive usage
                                                          dashboard</Text>
                                                  </div>
                                              }
                    />
                </div>
                <div style={{height: "auto"}}>
                    <UsageDashboard></UsageDashboard>
                </div>
            </div>
            {/*tokens per api*/}
            <div id={"api-prices"}>
                <div className={classes.glowHeader}>
                    <ScrollRevealIcon
                        bloomColor="#994fb2"
                        IconComponent={DollarSign}
                        string={"API pricing"}
                    />
                </div>
                <div className={classes.titleContainer} style={{marginLeft: 10}}>
                    <ScrollRevealGradientLine colorStart="#994fb2"
                                              colorMiddle="#994fb2"
                                              colorEnd="#994fb2"
                                              leftContent={
                                                  <div>
                                                      <Text
                                                          className={classes.glowTitleText}
                                                          mb={100}
                                                      >Below,
                                                          you'll find a detailed
                                                          breakdown of the
                                                          <span
                                                              className={classes.glowTitleText}
                                                              style={{
                                                                  color: "#994fb2",
                                                                  marginBottom: 100,
                                                                  marginLeft: 0
                                                              }}> token
                                                          costs for each endpoint
                                                          </span>
                                                      </Text>
                                                  </div>
                                              }
                    />
                </div>
                <div style={{height: "auto"}}>
                </div>
            </div>

            {/*feature cards*/}
            <div style={{marginBottom: "-8vh", marginLeft: 10}}>
                <PricingTables/>
            </div>
            <div className={classes.titleContainer} style={{marginLeft: 10}}>
                <ScrollRevealGradientLine colorStart="#994fb2"
                                          colorMiddle="#994fb2"
                                          colorEnd="#28AFB0"
                                          leftContent={
                                              <div className={classes.shortLinearGradient}>
                                                  &nbsp;
                                                  &nbsp;
                                              </div>
                                          }
                />
            </div>

            {/*Faq*/}
            <div style={{width: "100%"}}>
                <div className={classes.glowHeader}>
                    <ScrollRevealIcon
                        bloomColor="#28AFB0"
                        IconComponent={HelpCircle}
                        string={"FAQ"}
                    />
                </div>
                <div className={classes.titleContainer}
                     style={{marginBottom: 0, marginLeft: 10}}>
                    <ScrollRevealGradientLine colorStart="#28AFB0"
                                              colorMiddle="#28AFB0"
                                              colorEnd="#28AFB0"
                                              leftContent={
                                                <Grid >
                                                  <Col span={12} md={12}>
                                                      <Text
                                                          className={classes.glowTitleText}
                                                          style={{color: "#28AFB0"}}>Frequently
                                                          Asked Questions</Text>
                                                      <Text
                                                          className={classes.glowTitleText}>about
                                                          NutriClouds
                                                          pricing</Text>
                                                  </Col>
                                                    <Col span={12} md={12} mt={50} mb={50}>
                                                        <FaqSimple/>
                                                    </Col>
                                                </Grid>
                                              }
                    />
                </div>
                <div style={{height: "auto"}}>
                </div>
            </div>

            {/*end*/}

            <div style={{marginTop: 100, marginBottom: 100}}>
                <div style={{marginRight: 20}}>
                <div className={classes.pageLine}></div>
                    </div>
                <Flex direction={"column"} justify={"center"} mr={20}>
                    <Text className={classes.bottomCallToAction} fw={500}>Start building with NutriClouds powerful
                        APIs</Text>
                    <Button size={'xl'}
                    >
                        <Text className={classes.getStartedButton}>Get Started</Text>
                        <ArrowRightCircle
                            size={40} color={theme.colors.gray[0]}
                            style={{marginLeft: 10}}/>
                    </Button>
                </Flex>
            </div>
        </div>

    );
}

export default NewPricing;
