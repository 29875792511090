import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { format } from 'date-fns'; // Assuming you're using date-fns for date formatting
import {
    createStyles,
    Group,
    Modal,
    Select,
    TextInput,
    useMantineTheme,
    Text, SegmentedControl, rem, Loader, Center
} from "@mantine/core";
import { Button } from '@mantine/core';
import {Clipboard, Edit, Info, Trash2} from 'react-feather'; // Assuming you're using react-feather for icons
import {notifications, showNotification} from '@mantine/notifications';
import ApiKeyPermissionsSelector from "./SelectPermissions";
import EditApiKeyModal from "./SelectPermissions";
import {useAuth} from "../../AuthContext";
import ViewApiKey from "./ViewAPIKey";
import {Permissions} from "./SelectPermissions";


const useStyles = createStyles((theme) => ({
        tableContainer: {
        overflowX: 'auto', // Enables horizontal scrolling
        maxWidth: '100%', // Ensures the container does not exceed the viewport width
        marginLeft: '20px', // Optional: Maintains left margin if needed
            marginRight: '40px'
    },
    table: {
        width: '100%',
        borderCollapse: 'collapse',
    },

    cell: {
        padding: '0px',
        textAlign: 'left',
        minWidth: '100px',
        marginLeft: '0px',
        marginRight: '0px',

        fontSize: '14px',
        color: theme.colors.gray[3],

    },
    smallCell: {
        padding: '0px',
        textAlign: 'left',
        minWidth: '50px',
        marginLeft: '0px',
        marginRight: '0px',

        fontSize: '14px',
        color: theme.colors.gray[3],

    },
    clipboardCell: {
        padding: '0px',
        textAlign: 'left',
        maxWidth: '10px',
        marginLeft: '0px',
        marginRight: '0px',

        fontSize: '14px',
        color: theme.colors.gray[3],

    },


    bigCell: {
        padding: '0px',
        textAlign: 'left',
        marginLeft: '0px',
        marginRight: '0px',
        fontSize: '14px',
        color: theme.colors.gray[3],
        minWidth: '100px',
        '@media (min-width: 1000px)': {
            minWidth: '200px',
        },
    },

    row: {
            borderTop: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.gray[3] : theme.colors.gray[3]}`,
    },

    selectableButton: {
            backgroundColor: theme.colors.dark[7],
            ':hover': {
            cursor: 'pointer',
            backgroundColor: hexToRGBA(theme.colors.orange[0], 0.5)
        }
    },

    buttonColor: {
        backgroundColor: theme.colors.orange[1],
        fontSize: '14px',
        height: '35px',
        color: theme.colors.gray[9],
    },

    clipboardNotificaiton: {
        backgroundColor: theme.colors.orange[1],
        fontSize: '14px',
        height: '35px',
        maxWidth: '50px',
        color: theme.colors.orange[9],
    }

}));



// Define the TypeScript type for your API keys
export type ApiKey = {
    id: number;
  key: string;
  created_at: string;
  name: string;
  permissions: Permissions;
};





const formatApiKey = (key: string) => `${key.substring(0, 3)}...${key.substring(key.length - 4)}`;




const hexToRGBA = (hex: string, alpha = 1) => {
  const [r, g, b] = hex.match(/\w\w/g)!.map((x) => parseInt(x, 16));
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};


const ApiKeysTable: React.FC = () => {
  const [apiKeys, setApiKeys] = useState<ApiKey[]>([]);
  const [loadingKeys, setLoadingKeys] = useState(true);
  const [selectedApiKey, setSelectedApiKey] = useState<ApiKey>();
  const theme = useMantineTheme();
  const [editModalOpened, setEditModalOpened] = useState(false);
  const [createModalOpened, setCreateModalOpened] = useState(false);
  const [apiKeyName, setApiKeyName] = useState('');
  const { classes } = useStyles();
  const {accessToken, apiClient} = useAuth();

    // copy to clipboard
    const copyToClipboard = (text: string) => {
      navigator.clipboard.writeText(text).then(() => {
        // Handle success feedback here
        showNotification({
          title: 'Copied!',
          message: 'API key copied to clipboard.',
          autoClose: 3000, // Adjust timing as needed
        });
        notifications.cleanQueue()
        console.log('API key copied to clipboard');
      }, (err) => {
        // Handle errors here
        console.error('Failed to copy API key', err);
      });
    };

    // API Key permissions
    const [selectedPermission, setSelectedPermission] = useState('All'); // Initialize with the API key's current permission


    useEffect(() => {
        fetchApiKeys();
    }, []);


    const fetchApiKeys = async () => {
      try {
          setLoadingKeys(true);
          const baseURL = process.env.REACT_APP_BASE_URL;
        const response = await apiClient.get(`${baseURL}/api/auth/api-key/`, {
            headers: {
                'accept': 'application/json',
                // 'Authorization': `Bearer ${accessToken}`
            },
          withCredentials: true
        });
        console.log(response.data);
        setApiKeys(response.data);
      } catch (error) {
        console.error('Failed to fetch API keys:', error);
      }
      setLoadingKeys(false);
    };

    function allPermissionsGranted(permissions: Permissions): boolean {
  return Object.values(permissions).every(value => value === true);
}

  const deleteApiKey = async (apiKey: number) => {
    try {
        const baseURL = process.env.REACT_APP_BASE_URL;
      await axios.delete(`${baseURL}/api/auth/api-key/?api_key_id=${apiKey}`, {
          headers: {
                'accept': 'application/json',
              'Authorization': `Bearer ${accessToken}`
          },
        withCredentials: true
      });
      // Refresh the table legacy_data after deletion by re-calling fetchApiKeys
      await fetchApiKeys(); // Make sure fetchApiKeys is accessible here
    } catch (error) {
      console.error('Failed to delete API key:', error);
    }
  };


  return (
      <>
        {/*  Edit api key modal  */}
          <ViewApiKey apiKey={selectedApiKey} createModalOpened={editModalOpened} setCreateModalOpened={setEditModalOpened} />
        {/*<Modal*/}
        {/*  opened={editModalOpened}*/}
        {/*  onClose={() => setEditModalOpened(false)}*/}
        {/*  title={<div style={{fontWeight: 600, color: theme.colors.gray[1], fontSize: '24px'}}>API Key</div>}*/}
        {/*  size={525}*/}
        {/*  centered={true}*/}
        {/*    >*/}
        {/*  <div style={{ padding: '20px' }}>*/}
        {/*    <Text size="sm" style={{ marginBottom: '10px', color: theme.colors.gray[3], fontWeight: 500}}>Name<span style={{color: theme.colors.gray[6], marginLeft: '10px', fontWeight: 300, fontSize: '14px'}}>Optional</span></Text>*/}
        {/*    <TextInput*/}
        {/*      placeholder="API Key Name"*/}
        {/*      value={apiKeyName}*/}
        {/*      onChange={(event) => setApiKeyName(event.currentTarget.value)}*/}
        {/*      // rightSection={<Text color="dimmed" size="sm" style={{ marginRight: '5px' }}>Optional</Text>}*/}
        {/*      style={{ marginBottom: '20px' }}*/}
        {/*      radius="sm" // Rounded corners*/}
        {/*    />*/}
        {/*    <div style={{ marginBottom: '20px' }}>*/}
        {/*      <Text size="sm" style={{ marginBottom: '5px' }}>Permissions</Text>*/}
        {/*    <SegmentedControl*/}
        {/*      value={selectedPermission}*/}
        {/*      onChange={setSelectedPermission}*/}
        {/*      data={permissionOptions}*/}
        {/*      style={{ marginBottom: 20 }}*/}
        {/*      // defaultValue={selectedPermission}*/}
        {/*    />*/}

        {/*    {selectedPermission === 'All' && (*/}
        {/*      <div>Content for All</div>*/}
        {/*    )}*/}
        {/*    {selectedPermission === 'Restricted' && (*/}
        {/*      <div>Content for Restricted</div>*/}
        {/*    )}*/}


        {/*    </div>*/}
        {/*    <Group position="right" spacing="md">*/}
        {/*      <Button variant="default" onClick={() => setEditModalOpened(false)}>Cancel</Button>*/}
        {/*      <Button onClick={handleSave}>Save</Button>*/}
        {/*    </Group>*/}
        {/*  </div>*/}
        {/*</Modal>*/}

      {/*  create api key modal  */}
      <EditApiKeyModal fetchApiKeys={fetchApiKeys} createModalOpened={createModalOpened} setCreateModalOpened={setCreateModalOpened} />

        {/*  table*/}
          <div className={classes.tableContainer}>
              {loadingKeys ? (
                  <Center>
                    <Loader size="lg" />
                  </Center>
              ) : (
                  <table className={classes.table}>
                  <thead>
                  <tr>
                      <th className={`${classes.bigCell}`}>NAME</th>
                      <th className={`${classes.cell}`}>SECRET KEY</th>
                      <th className={`${classes.cell}`}>CREATED</th>
                      <th className={`${classes.cell}`}>PERMISSIONS</th>
                      <th className={`${classes.smallCell}`}></th>
                  </tr>
                  </thead>
                    <tbody>
                    {apiKeys.map((apiKey) => (
                        <tr className={classes.row} key={apiKey.key}>
                            <td className={`${classes.bigCell}`}>{apiKey.name}</td>
                            <td className={`${classes.cell}`} style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                maxWidth: '80px',
                            }}>
                                <span>{formatApiKey(apiKey.key)}</span>
                                <button
                                    onClick={() => copyToClipboard(apiKey.key)}
                                    className={classes.selectableButton}
                                    style={{
                                        border: 'none',
                                        cursor: 'pointer',
                                        padding: '5px',
                                        borderRadius: '4px',
                                        marginLeft: 'auto',
                                    }}
                                >
                                    <Clipboard size={16}/>
                                </button>
                            </td>

                            <td className={`${classes.cell}`}>{format(new Date(apiKey.created_at), 'MMM dd, yyyy')}</td>
                            <td className={`${classes.cell}`}>
                                {allPermissionsGranted(apiKey.permissions) ? 'All' : 'Restricted'}
                            </td>
                            <td className={`${classes.smallCell}`}>
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-around',
                                }}>
                                    <button
                                        className={classes.selectableButton}
                                        onClick={() => {
                                            setSelectedApiKey(apiKey);
                                            // setSelectedPermission(apiKey.permissions);
                                            setApiKeyName(apiKey.name);
                                            setEditModalOpened(true);
                                        }}
                                        style={{
                                            border: 'none',
                                            cursor: 'pointer',
                                            padding: '5px',
                                            borderRadius: '4px',
                                        }}
                                    >
                                        <Info size={16}/>
                                    </button>
                                    <button
                                        className={classes.selectableButton}
                                        onClick={() => deleteApiKey(apiKey.id)}
                                        style={{
                                            border: 'none',
                                            cursor: 'pointer',
                                            padding: '5px',
                                            borderRadius: '4px',
                                        }}
                                    >
                                        <Trash2 size={16}/>
                                    </button>

                                </div>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
              )}


          </div>

          {/*create new secret button*/}
          <Button
              radius="sm"
              size="sm"
              my={rem(20)}
              mx={rem(20)}
              className={classes.buttonColor}
              onClick={() => {
                  setApiKeyName('');
                  setSelectedPermission('All');
                  setCreateModalOpened(true);
              }}>
              + Create new secret key
          </Button>
      </>
  );
};

export default ApiKeysTable;
