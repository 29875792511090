import { createStyles, MantineTheme } from '@mantine/core';
import React from 'react';
import {useNavigate} from "react-router-dom";

// Define a type for the size prop
interface StandardCompanyLogoStylesProps {
  size: string;
}

const useStyles = createStyles(
  (theme: MantineTheme, { size }: StandardCompanyLogoStylesProps) => ({
    logoContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: size, // Controlled by the size prop
      width: size, // Controlled by the size prop
      padding: 0,
      margin: 0,
    },
    logoImage: {
        marginTop: 80,
        marginLeft: 60,
        marginRight: -5,
      height: '100%',
      width: '100%',
      padding: 0,
    },
    companyName: {
      fontWeight: 500,
      fontSize: theme.fontSizes.xl,
      color: theme.colors.gray[1],
      lineHeight: 1,
      marginLeft: theme.spacing.sm,
    },
  })
);

interface StandardCompanyLogoProps {
  size?: string; // Make size optional with a default value
}

export const StandardCompanyLogo: React.FC<StandardCompanyLogoProps> = ({
  size = '3rem', // Default size if not provided
}) => {
  const { classes } = useStyles({ size });
  const navigate = useNavigate();

  return (

    <div className={classes.logoContainer}
         onClick={() => navigate('/')}
         style={{cursor: 'pointer'}}
    >
      <div className={classes.logoImage} aria-label="Company Logo">
        {MySVG} {/* SVG goes here */}
      </div>
      <span className={classes.companyName}>NutriCloud</span>
    </div>
  );
};

// Usage example, embed the SVG directly as JSX
const MySVG = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="450 40 300 300" style={{ width: 'auto', height: '30%' }}>
    <path d="m640.333 317.588 92.526-149.216H576.284l8.184 13.2h124.673l-68.808 110.967zM490.859 298.464l68.808-110.967v-25.049l-92.526 149.216h156.575l-8.184-13.2z" fill="#ff8b00"/>
    <path d="M559.667 162.448v25.049l80.666 130.092v-25.05z" fill="#ff8b00"/>
  </svg>
);
