import React from 'react';
import {
    Text,
    Container,
    createStyles,
    useMantineTheme, Center, Group, Stack, Table
} from '@mantine/core';
import {useNavigate} from 'react-router-dom';
import DocsPage from "../DocsPage";
import FeedbackBanner from "../Components/Feedback";
import {ArrowDown} from "react-feather";



const useStyles = createStyles((theme) => ({
    iconBox: {
        display: 'flex',
        justifyContent: 'flex-start', // Align items to the start (left side)
        alignItems: 'center',
        marginBottom: theme.spacing.md,
        '& svg': {
            transition: 'transform 0.3s ease',
        },
        '&:hover svg': {
            transform: 'scale(1.3)',
            color: theme.colors.gray[0],
        },

    },
    contentBox: {
        marginLeft: theme.spacing.md, // Add some space between the icon and the text
    },
      animatedLink: {
          display: 'inline-flex',
          fontSize: '18px',
          alignItems: 'center',
          cursor: 'pointer',
          position: 'relative',
          textDecoration: 'none',
          color: theme.colorScheme === 'dark' ? theme.white : theme.black,
      },

    // headers
    pageTitle: {
        textAlign: "left",
        fontSize: theme.fontSizes.xxl,
        fontWeight:600,
        color: theme.colors.gray[3],
        marginBottom: "0px",
        marginTop: "100px",
    },
    mainHeader: {
        fontWeight: 500,
        fontSize: theme.fontSizes.xl,
        marginTop: "0px",
        marginBottom: "10px",
    },
    subHeader: {
        fontWeight: 500,
        fontSize: theme.fontSizes.lg,
        color: theme.colors.gray[5],
        // marginTop: "30px",
        marginBottom: "0px",
    },

    // bullet list
    link: {
        color: theme.colors.orange[0],
        textDecoration: 'none',
        '&:hover': {
            color: theme.colors.orange[9],  // Change this to the color you want on hover
        },
    },
    bulletList: {
        listStyleType: 'disc',
    },

    // text
    paragraphText: {
        fontSize: '16px',
        marginBottom: "10px",
        color: theme.colors.gray[3],
    },
        bulletTitle: {
        color: theme.colors.gray[0],
        fontWeight: 600,
    },

    bulletPoint: {
        fontSize: theme.fontSizes.sm,
        margin: '5px 0',
    },

    customText: {
        fontSize: '16px',
        color: theme.colors.gray[6]
    },

    rightTable: {
         textAlign: 'left',
        width: '60%'
    },

    leftTable: {
         textAlign: 'right',
        width: '40%',
        paddingRight: 20,
    },

    // info boxes
    infoBox: {
        backgroundColor: theme.colors.gray[8],
        padding: theme.spacing.md,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        width: '100%',
        marginTop: '10px',
        marginBottom: '20px',
    },
    infoText: {
        marginLeft: theme.spacing.sm,
        fontSize: '16px',
    },
    infoIcon: {
        fontSize: '1em',
        flexShrink: 0,
    },
coloredBox: {
    border: `0.5px solid ${theme.colors.gray[8]}`,
    backgroundColor: theme.colors.gray[9],
        '&:hover': {
      backgroundColor: theme.colors.dark[5],
    },
    borderRadius: '8px',
    padding: 20,
    flex: '1 1 30%',  // Add this line to make boxes take equal space
    // minWidth: '250px', // Add this to prevent boxes from getting too narrow
},

    meetTheAPICardContainer: {
        display: 'flex',
        gap: '20px',
        flexDirection: 'row',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        [`@media (max-width: 1000px)`]: {
            flexDirection: 'column',
        },
    },
}));



function ProductionBestPracticesDocs() {
    const {classes} = useStyles();
    const theme = useMantineTheme();
    const navigate = useNavigate();

    return (
        <Container>
            {/*title*/}
            <Text className={classes.pageTitle}>Production best practices</Text>
            <Text className={classes.subHeader}>Transition projects to production with best practices</Text>
            <Text mt={30} className={classes.paragraphText}>From proof of concept to production deployment, this guide walks you through essential practices for success. We cover everything from API security to scalable architecture design, helping both experienced developers and newcomers build robust, high-volume applications. Whether you're just starting out or ready to deploy, you'll find practical steps to ensure a smooth transition to production.</Text>

            {/*Billing management*/}
            <Text mt={50} className={classes.mainHeader}>Billing management</Text>
            <Text mt={20} className={classes.paragraphText}>Get started with NutriClouds API using our token-based billing system. New accounts receive 1,000 complimentary tokens to explore our API features. Want more? <span
                    onClick={() => navigate('/account/profile')}
                    style={{cursor: 'pointer', color: theme.colors.orange[0]}}
                >Verify your phone number to receive $20 in free
                credits.</span></Text>
            <Text mt={20} className={classes.paragraphText}>NutriClouds operates on a
                prepaid model - simply visit our billing page to purchase additional credits whenever you need them.</Text>
            <Text mt={20} className={classes.paragraphText}>Once you’ve created your account, you will have an approved usage limit of $100 per month, which is set by NutriCloud. Your quota limit will automatically increase as your usage on your platform increases and you move from one usage tier to another. You can review your current usage limit in the limits page in your account settings.</Text>
            <Text mt={20} className={classes.paragraphText}>We strongly recommend monitoring your credit balance regularly to ensure uninterrupted service for your clients, see the
                <span
                    onClick={() => navigate('/usage')}
                    style={{cursor: 'pointer', color: theme.colors.orange[0]}}
                > usage page to monitor your API usage and balance</span>. Additionally, implementing proper error handling in your API integrations will help manage cases where credits are depleted. Remember that once your credit balance reaches zero, API requests will be declined until you replenish your account, which could impact your applications and end-users.</Text>


            {/*API Keys*/}
            <Text mt={50} className={classes.mainHeader}>API keys</Text>
            <Text mt={20} className={classes.paragraphText}>The NutriCloud API uses API keys for authentication. Visit your
                <span
                    onClick={() => navigate('/api-keys')}
                    style={{cursor: 'pointer', color: theme.colors.orange[0]}}
                > API keys page</span> to retrieve the API key you'll use in your requests.</Text>
            <Text mt={20} className={classes.paragraphText}>Controlling access with API keys is simple, but requires careful handling. Never include these keys directly in your code or store them in public repositories. Instead, keep them in a secure location and access them through environment variables or a secret management system. This approach keeps your sensitive credentials separate from your actual code.</Text>
            <Text mt={20} className={classes.paragraphText}>Creating separate API keys for different purposes helps maintain security and control. You can generate distinct keys for development, staging, and production environments, or create specific keys for different servers or microservices. By assigning unique permissions to each key, you can limit what actions they can perform - for instance, giving read-only access to monitoring services while reserving write permissions for production systems. This granular approach makes it easier to track usage, revoke access when needed, and minimize security risks. If a key is compromised, you can quickly disable it without affecting other parts of your system, and your logs will clearly show which key was responsible for specific actions.</Text>





            {/*scaling architecture*/}
            <Text mt={50} className={classes.mainHeader}>Scaling Your Technical Infrastructure</Text>
            <Text mt={20} className={classes.paragraphText}>Planning for growth is essential when launching a production system that integrates with our API. Here are the critical scaling considerations to address, regardless of your chosen cloud platform:</Text>
            <ul className={classes.bulletList}>
                <li className={classes.bulletPoint}>
                    <span
                        className={classes.bulletTitle}>Caching: </span>
                    Implement caching to minimize repeated API calls by storing
                    frequently-used data. Design your system to prioritize cached
                    information and update it when changes occur. Options include
                    database storage, file system caching, or memory-based solutions,
                    depending on your needs.
                </li>
                <li className={classes.bulletPoint}>
                    <span
                        className={classes.bulletTitle}>Load balancing: </span>
                    Implement strategies to evenly distribute incoming requests across
                    your infrastructure. This might involve dedicated load balancers or
                    DNS-based distribution to optimize performance and prevent system
                    overload.
                </li>
                <li className={classes.bulletPoint}>
                    <span className={classes.bulletTitle}>Horizontal scaling: </span>
                    Deploy multiple servers or containers to handle concurrent requests
                    from various sources. This distributed approach requires an
                    architecture that supports multiple nodes working in harmony, along
                    with proper load distribution systems.
                </li>
                <li className={classes.bulletPoint}>
                    <span
                        className={classes.bulletTitle}>Vertical scaling: </span>
                    Increase the power of individual servers by upgrading their
                    resources. This approach focuses on enhancing single-node
                    performance. Your application should be optimized to leverage these
                    expanded capabilities effectively.
                </li>
            </ul>

            {/*Managing rate limits*/}

            <Text mt={50} className={classes.mainHeader}>Managing rate limits</Text>
            <Text mt={20} className={classes.paragraphText}>when using the NutriCloud
                API, it's important to understand and plan for rate limits. Visit our
                <span
                    onClick={() => navigate('/docs/guides/rate-limits')}
                    style={{cursor: 'pointer', color: theme.colors.orange[0]}}
                > rate limit page</span> to learn to how adjust your code to handle
                these limits.</Text>


            {/*improving latency*/}
            <Text mt={50} className={classes.mainHeader}>Improving latencies</Text>
            <Text mt={20} className={classes.paragraphText}>Latency refers to the duration required to process a request and deliver a response. In this section, we will examine various factors impacting the latency of our text generation models and offer recommendations for optimization. The primary influences on latency in a completion request are the API utilized and the query size for that API. The lifecycle of an API request is as follows:</Text>

            <table style={{borderCollapse: 'collapse', width: '100%'}}>
                <tbody>
                {/*network*/}
                <tr>
                    <td className={classes.leftTable}>
                        <Text className={classes.paragraphText}>
                            <strong>Network</strong>
                        </Text>
                    </td>
                    <td className={classes.rightTable}>
                        <Text className={classes.paragraphText}>
                            End user to API latency
                        </Text>
                    </td>
                </tr>
                <tr>
                    <td className={classes.leftTable}></td>
                    <td className={classes.rightTable}>
                        <div>
                            <ArrowDown/>
                        </div>
                    </td>
                </tr>

                {/*Server*/}
                <tr>
                    <td className={classes.leftTable}>
                        <Text className={classes.paragraphText}>
                            <strong>Server</strong>
                        </Text>
                    </td>
                    <td className={classes.rightTable}>
                        <Text className={classes.paragraphText}>
                            Time to process query text or image
                        </Text>
                    </td>
                </tr>
                <tr>
                    <td className={classes.leftTable}></td>
                    <td className={classes.rightTable}>
                        <div>
                            <ArrowDown/>
                        </div>
                    </td>
                </tr>

                {/*server*/}
                <tr>
                    <td className={classes.leftTable}>
                        <Text className={classes.paragraphText}>
                            <strong>Server</strong>
                        </Text>
                    </td>
                    <td className={classes.rightTable}>
                        <Text className={classes.paragraphText}>
                            Time to run ML models and database queries
                        </Text>
                    </td>
                </tr>
                <tr>
                    <td className={classes.leftTable}></td>
                    <td className={classes.rightTable}>
                        <div>
                            <ArrowDown/>
                        </div>
                    </td>
                </tr>

                {/*network*/}
                <tr>
                    <td className={classes.leftTable}>
                        <Text className={classes.paragraphText}>
                            <strong>Network</strong>
                        </Text>
                    </td>
                    <td className={classes.rightTable}>
                        <Text className={classes.paragraphText}>
                            API to end user latency
                        </Text>
                    </td>
                </tr>
                <tr>
                    <td className={classes.leftTable}></td>
                    <td className={classes.rightTable}>
                        <div>
                            <ArrowDown/>
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>

            <Text className={classes.paragraphText}>The bulk of the latency typically arises from ML model steps.</Text>

            {/*commong factors*/}
            <Text mt={50} className={classes.mainHeader}>Latency mitigation techniques</Text>

            <Text mt={30} className={classes.subHeader}>Infrastructure</Text>
            <Text mt={10} className={classes.paragraphText}>For optimal performance, we recommend placing your infrastructure in the United States, as our servers are US-based. This will reduce network latency between your systems and NutriCloud. While we plan to expand our server locations globally, a US-based setup currently offers the fastest connection speeds.</Text>

            <Text mt={30} className={classes.subHeader}>Batching</Text>
            <Text mt={10} className={classes.paragraphText}>Depending on your use case, batch querying may be beneficial. Some of our APIs support batch queries, enabling us to leverage same data, different request acceleration (SDRA) on our hardware. If you’re sending multiple queries to the same API, you can batch them within a single request to reduce the overall number of requests. We recommend testing this method to see if it enhances your performance. However, be aware that in some cases, it might increase the number of generated tokens, which could impact response time.</Text>


            <Text mt={50} className={classes.mainHeader}>Security and compliance</Text>
            <Text mt={30} className={classes.paragraphText}>
As you transition your prototype to production, it's essential to evaluate and address any security and compliance requirements relevant to your application. This process involves reviewing the data you handle, understanding how our API manages data, and identifying the regulations that apply. For guidance, please refer to our
                <span
                    onClick={() => navigate('/policies')}
                    style={{cursor: 'pointer', color: theme.colors.orange[0]}}
                > Privacy Policy and Terms of Use</span>.</Text>
            <Text mt={20} className={classes.paragraphText}>Key areas to consider include data storage, transmission, and retention. You may also need to implement data privacy safeguards, such as encryption or anonymization where appropriate. Additionally, follow secure coding best practices, like input sanitization and robust error handling.</Text>

            <FeedbackBanner/>
        </Container>
    );
}

export default function ProductionBestPracticesDocsPage() {
    return (
        <div>
            <DocsPage children={<ProductionBestPracticesDocs/>}
                      activeItem={"production-best-practices"}/>
        </div>
    );
}
