import {
    Accordion,
    Badge,
    Button, CheckIcon, Col,
    createStyles, Flex, Grid, Loader,
    Paper,
    Select, Table,
    Text, Tooltip,
    useMantineTheme
} from '@mantine/core';
import { Link } from 'react-router-dom';
import {rem} from "@mantine/core";
import Sidebar from "../../componenets/sidebar/sidebar";
import React, {useEffect, useState} from "react";
import { Notification } from '@mantine/core';
import { TextInput, Group, Box, Title } from '@mantine/core';
import {Circle} from "react-feather";
import { IconCircle } from '@tabler/icons-react';
import RateLimitsAccordionTable from "./limitsTable";
import axiosInstance from "../../axiosInstance";
import {useAuth} from "../../AuthContext";


const useStyles = createStyles((theme) => ({
    pageTitle: {
        textAlign: 'left',
        marginBottom: 10,
        fontSize: '1.5rem',
        fontWeight: 700,
        color: theme.colors.gray[0],
        lineHeight: 'normal', // Adjust this line
    },

        toolTip: {
        transition: 'all 0.3s ease',
        backgroundColor: theme.colors.gray[0],
        color: theme.colors.gray[9],
        border: `1px solid ${theme.colors.gray[5]}`,
        zIndex: 1000,
        fontSize: '10px',
    },
        httpBadge: {
        marginRight: '10px',
        position: 'relative',
        top: '-3px', // adjust this as necessary
    },

    customBadge: {
                marginRight: '10px',
        position: 'relative',
        top: '-3px', // adjust this as necessary
            backgroundColor: theme.colors.dark[7],
            color: theme.colors.orange[0]
    },

    inLineStyle: {
         display: 'inline-block'
    },

    header: {
        textAlign: 'left',
        fontSize: "24px",
        color: theme.colors.gray[3],
        fontWeight: 600,
        marginLeft: '25px',
    },

    finePrint: {
        fontSize: '12px',
        color: theme.colors.gray[3],
        marginLeft: '25px',
    },

    pageLine: {
        width: '200%',
        height: '1px',
        backgroundColor: theme.colors.gray[7],
        marginBottom: theme.spacing.md,
        marginLeft: 'auto', // Add this line
        marginRight: 'auto', // Add this line
    },

    paragraphText: {
        fontSize: '16px',
        fontWeight: 300,
        color: theme.colors.gray[0],
    },

    smallText: {
        fontSize: '12px',
        color: theme.colors.gray[0],
    },
    mediumText: {
        fontSize: '14px',
        color: theme.colors.gray[0],
        marginTop: 3,
    },

    rightDiv: {
        maxWidth: '1000px',
        marginLeft: '25px',
        '@media (min-width: 1280px)': {
            marginLeft: 0,
        },
    },

    leftDiv: {
        marginBottom: '40px',
    },

    separatingDiv: {
        height: '10px',
        '@media (min-width: 1280px)': {
            height: '15px',
        },
    },
    largeSeparatingDiv: {
        height: '60px',
        '@media (min-width: 1280px)': {
            height: '80px',
        },
    },

    paragraphBoldText: {
        fontWeight: 'bold',
    },

    highlightedLink: {
        color: theme.colors.orange[0],
        textDecoration: 'none',
    },

    table: {
        width: '100%',
        borderCollapse: 'collapse',
        marginLeft: '20px',
    },

    cell: {
        padding: '0px',
        textAlign: 'left',
        borderBottom: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.gray[0] : theme.colors.gray[0]}`,
        minWidth: '100px',
        marginLeft: '0px',
        marginRight: '0px',

        fontSize: '14px',
        color: theme.colors.gray[3],

    },
    bigCell: {
        padding: '0px',
        textAlign: 'left',
        borderBottom: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.gray[0] : theme.colors.gray[0]}`,
        marginLeft: '0px',
        marginRight: '0px',
        fontSize: '14px',
        color: theme.colors.gray[3],
        minWidth: '100px',
        '@media (min-width: 1000px)': {
            minWidth: '200px',
        },
    },
    tableHeader: {
        borderBottomWidth: '2px',  // make the header line bolder
        fontWeight: 'bold',
    },
    lastRow: {
        borderBottom: 'none',  // remove the bottom border from the last row
    },

    buttonColor: {
        backgroundColor: theme.colors.orange[1],
        fontSize: '14px',
        height: '35px',
        color: theme.colors.gray[9],
    },
    container: {
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
    paddingLeft: theme.spacing.md,
    borderRadius: theme.radius.md,
    boxShadow: theme.shadows.sm,
        maxWidth: '400px',
  },
  title: {
    marginBottom: theme.spacing.md,
  },
  input: {
    marginBottom: theme.spacing.sm,
  },
  button: {
    marginTop: theme.spacing.md,
  },
        price: {
        fontSize: '2.5rem',
        fontWeight: 500,
        marginBottom: theme.spacing.md,
    },
    smallTitle: {
        fontWeight: 700,
        fontSize: '18px',
        color: theme.colors.gray[3]
    },
  leftLine: {
    backgroundImage: `linear-gradient(to bottom,
      ${theme.fn.rgba(theme.colors.gray[7], 0)},
      ${theme.fn.rgba(theme.colors.orange[1], 1)} 20%,
      ${theme.fn.rgba(theme.colors.orange[1], 1)} 80%,
      ${theme.fn.rgba(theme.colors.gray[7], 0)}
    )`,
    backgroundSize: '1px 100%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'left',
    marginTop: theme.spacing.md,
  },
  hiddenRows: {
    display: 'none',
  },

    sideBarGrow: {
        marginLeft: '60px',
        marginRight: '10px',
        '@media (min-width: 800px)': {
            marginLeft: '200px',
        },
    },
}));
// get usage tier rate limit legacy_data

type monthlySpendLimitMapType = {
    [key: string]: number;
}
export const monthlySpendLimitMap: monthlySpendLimitMapType = {
    "1": 100,
    "2": 500,
    "3": 1000,
    "4": 5000,
    "5": 50000,
}

export function Limits() {
    const theme = useMantineTheme();
    const { classes } = useStyles();
    const {apiClient} = useAuth();

    // Usage tier
    const [usageTier, setUsageTier] = React.useState(3);
    const [loadingUsageTier, setLoadingUsageTier] = React.useState(true);
    const fetchUsageTierData = async () => {
      try {
          setLoadingUsageTier(true);
        const baseURL = process.env.REACT_APP_BASE_URL;
        const response = await apiClient.get(`${baseURL}/api/rate-limit/current`, {
          withCredentials: true,
        });
        console.log(response);
        const usageTier: string = response.data.name.match(/\d+/)?.[0];


        const monthlySpendLimit = monthlySpendLimitMap[usageTier];
        setMonthlySpendLimit(monthlySpendLimit);

        setUsageTier(Number(usageTier));
      } catch (error) {
        console.error('Failed to fetch API keys:', error);
      } finally {
          setLoadingUsageTier(false);
      }
    };

    // monthly spend limit
    const [monthlySpendLimit, setMonthlySpendLimit] = React.useState(0);



    // upgrade requirements
    const [required_time_since_account_creation, setRequiredTimeSinceAccountCreation] = React.useState(14);
    const [requires_total_spend, setRequiresTotalSpend] = React.useState(250);
    const fetchUpgradeRequirements = async () => {
      try {
        const baseURL = process.env.REACT_APP_BASE_URL;
        const response = await apiClient.get(`${baseURL}/api/rate-limit/user/usage-tier/upgrade_requirements`, {
          withCredentials: true,
        });
        console.log(response);
        setRequiredTimeSinceAccountCreation(response.data.time_since_account_creation);
        setRequiresTotalSpend(response.data.total_spend);
      } catch (error) {
        console.error('Failed to fetch API keys:', error);
      }
    };


    // // custom user monthly budget
    // const [customMonthlyBudget, setCustomMonthlyBudget] = React.useState("");
    //
    // const updateMonthlyBudget = (event: React.ChangeEvent<HTMLInputElement>) => {
    //   setCustomMonthlyBudget(String(event.target.value));
    // };
    //
    // const fetchCustomMonthlyBudget = async () => {
    //   try {
    //     const baseURL = process.env.REACT_APP_BASE_URL;
    //     const response = await axiosInstance.get(`${baseURL}/api/rate-limit/user/monthly-budget`, {
    //       withCredentials: true,
    //     });
    //     console.log(response);
    //     setCustomMonthlyBudget(response.data.usd);
    //   } catch (error) {
    //     console.error('Failed to fetch API keys:', error);
    //   }
    // };
    //
    // const saveCustomMonthlyBudget = async () => {
    //     try {
    //         const baseURL = process.env.REACT_APP_BASE_URL;
    //         const response = await axiosInstance.post(`${baseURL}/api/rate-limit/user/set-monthly-budget?budget=2000`,{}, {
    //           withCredentials: true,
    //         });
    //         console.log(response);
    //       } catch (error) {
    //         console.error('Failed to fetch API keys:', error);
    //       }
    // };
    //
    //
    // // email threshold
    // const [emailThreshold, setEmailThreshold] = React.useState("");
    //
    // const updateEmailThreshold = (event: React.ChangeEvent<HTMLInputElement>) => {
    //   setEmailThreshold(String(event.target.value));
    // };
    //
    // const fetchEmailThreshold = async () => {
    //   try {
    //     const baseURL = process.env.REACT_APP_BASE_URL;
    //     const response = await axiosInstance.get(`${baseURL}/api/rate-limit/user/budget-email-threshold`, {
    //       withCredentials: true,
    //     });
    //     console.log(response);
    //     setEmailThreshold(response.data.usd);
    //   } catch (error) {
    //     console.error('Failed to fetch API keys:', error);
    //   }
    // };
    //
    // const saveEmailThreshold = async () => {
    //     try {
    //         const baseURL = process.env.REACT_APP_BASE_URL;
    //         const response = await axiosInstance.post(`${baseURL}/api/rate-limit/user/budget-email-threshold?threshold=${emailThreshold}`, {}, {
    //           withCredentials: true,
    //         });
    //         console.log(response);
    //       } catch (error) {
    //         console.error('Failed to fetch API keys:', error);
    //       }
    // };

    // save button
//     const [saveStatus, setSaveStatus] = useState('');
//
//
//     const handleSave = async () => {
//     try {
//         // Call both save functions
//         await saveEmailThreshold();
//         await saveCustomMonthlyBudget();
//
//         // If both calls succeed
//         setSaveStatus('success');
//     } catch (error) {
//         // Handle any errors that occur during the save operations
//         console.error('Error saving legacy_data:', error);
//         setSaveStatus('error');
//     }
// };

    useEffect(() => {
        fetchUsageTierData();
        fetchUpgradeRequirements();
        // fetchCustomMonthlyBudget();
        // fetchEmailThreshold();
      }, []);

    return (
        <div style={{maxWidth: 1000}}>
            <Sidebar defaultExpanded={true} activeItem="limits"/>
            <div className={classes.sideBarGrow}>
                {/*title*/}
                <Flex direction="row" gap={'sm'} align="center" mt={15} ml={20}>
                <Text size="xs" className={classes.pageTitle}>Limits</Text>
                    {loadingUsageTier ? null : (
                                            <Tooltip
                      label="Your usage tier impacts your API limits. See the 'increasing your limits' section below to learn more."
                      className={classes.toolTip}
                      position={'bottom'}
                      transitionProps={{ transition: 'scale', duration: 200 }}
                      multiline
                      w={300}
                      radius={"xs"}
                      p={20}
                      fw={500}
                      fz={'14px'}
                      ml={15}
                      offset={10}
                      bg={theme.colors.gray[9]}
                      color={theme.colors.gray[8]}
                    >
                        <Badge mb={5} className={classes.httpBadge} color={theme.colors.orange[1]} variant="filled">
                            usage tier {usageTier}
                        </Badge>
                    </Tooltip>
                    )}


                </Flex>
                <div className={classes.pageLine}></div>
                {/* info*/}
                <div className={classes.rightDiv} style={{marginLeft: 20}}>
                    {/*Usage Limits section*/}
                    <Text className={classes.pageTitle}>Usage limits</Text>
                    <Text className={classes.paragraphText}>To control your API expenditure, you have the ability to set up monthly spend limits. When these limits are approaching or exceeded, notification emails will be sent to you at request. Please be aware that there may be a slight delay in the enforcement of these limits, and you remain responsible for any overage charges incurred during this time.</Text>

                    <Text className={classes.smallTitle} mt={20}>Usage limit</Text>
                    <Text className={classes.smallText}>the maximum usage NutriCloud allows for you each month.
                        <Link to="/usage" className={classes.highlightedLink}> View current usage.</Link>
                    </Text>
                    {loadingUsageTier ? (
                        <Loader mt={20} ml={20} size={"lg"}/>
                    ) : (
                        <Text className={classes.price}>${monthlySpendLimit}</Text>
                    )}

                    {/*<Grid>*/}
                        {/*left*/}
                        {/*<Col span={12} sm={6} p={20} pl={10} pr={20}>*/}
                        {/*    <Flex direction={'column'}>*/}
                        {/*        <Text mb={5} fz={14} className={classes.smallTitle}>Set a monthly budget</Text>*/}
                        {/*        <Text className={classes.smallText}>If you exceed this budget in a given calendar month (UTC), subsequent API requests will be rejected.</Text>*/}
                        {/*        <TextInput mt={10} value={customMonthlyBudget} onChange={updateMonthlyBudget}></TextInput>*/}
                        {/*    </Flex>*/}
                        {/*</Col>*/}
                        {/*right*/}
                    {/*    <Col span={12} sm={12} p={20} pl={10}>*/}
                    {/*        <Flex direction={'column'}>*/}
                    {/*            <Text mb={5} fz={14} className={classes.smallTitle}>Set an email notification threshold</Text>*/}
                    {/*            <Text className={classes.smallText}>If you exceed this threshold in a given calendar month (UTC), an email notification will be sent.</Text>*/}
                    {/*            <TextInput mt={10} value={emailThreshold} onChange={updateEmailThreshold}></TextInput>*/}
                    {/*        </Flex>*/}
                    {/*    </Col>*/}

                    {/*    /!*save button*!/*/}
                    {/*    <Button mt={20} ml={10} onClick={handleSave}>Save</Button>*/}
                    {/*      {saveStatus === 'success' && (*/}
                    {/*        <Notification style={{position: "fixed", left: "45vw", top: 0}} title="Success" color="green" onClose={() => setSaveStatus('')}>*/}
                    {/*          Data saved successfully!*/}
                    {/*        </Notification>*/}
                    {/*      )}*/}
                    {/*      {saveStatus === 'error' && (*/}
                    {/*        <Notification style={{position: "fixed", left: "45vw", top: 0}} title="Error" color="red" onClose={() => setSaveStatus('')}>*/}
                    {/*          Failed to save data. Please try again.*/}
                    {/*        </Notification>*/}
                    {/*      )}*/}

                    {/*</Grid>*/}


                    {/*rate limits section*/}
                    <Text mt={80} className={classes.pageTitle}>Rate limits</Text>
                    <Text className={classes.paragraphText}>Your use of the API is restricted by various rate limits. These limits are based on tokens per minute (TPM), requests per minute (RPM), requests per day (RPD), and other constraints specific to each API. The rate limits applicable to you are below.</Text>
                    <Text className={classes.paragraphText} mt={10} mb={10}>Visit our <Link to="/docs/guides/rate-limits" className={classes.highlightedLink}>rate limits guide</Link> to learn more about how rate limits work.</Text>
                    <div>
                        <RateLimitsAccordionTable/>
                    </div>

                    {/*Increasing Limits*/}
                    <Text mt={60} className={classes.pageTitle}>Increasing your limits</Text>
                    <Text className={classes.paragraphText}>You currently are in <span style={{fontWeight: 500}}>Usage tier {usageTier}</span>.
                        Your limits will automatically be increased once you move to the next usage tier based on the criteria outlined below. Visit our <Link to="/docs/guides/rate-limits" className={classes.highlightedLink}> usage tier documentation</Link> to learn more about the limits associated with each tier.</Text>

                    <div className={classes.leftLine} style={{paddingLeft: 10}}>
                        {/*clients usage tier*/}
                        <Flex pt={20} ml={-16} direction={"row"} align={"center"} gap={'6px'}>
                            <IconCircle size={12} strokeWidth={1} color={theme.colors.gray[6]} fill={theme.colors.orange[1]} />
                            <Text mt={2} className={classes.smallText}>Current Tier</Text>
                        </Flex>
                        <Text mt={-5}>Usage tier {usageTier}</Text>
                        <Text mt={10} className={classes.mediumText}>Once the following criteria are met, you'll automatically move to the next tier:</Text>

                        <ul style={{marginTop: 0, paddingTop: 0, marginBottom: 0}}>
                            <li className={classes.mediumText}>A minimum expenditure of <span style={{fontWeight: 800}}>${requires_total_spend}</span> on API usage is required, calculated from the date of account creation.</li>
                            <li className={classes.mediumText}>A period of no less than <span style={{fontWeight: 800}}>{required_time_since_account_creation} days</span> days must have elapsed since account creation.</li>
                        </ul>
                        <Flex mt={10} mb={20} direction={"row"}>
                            <Text mr={10} fz={14} color={theme.colors.orange[1]}><Link to="/account/billing/history" className={classes.highlightedLink}>View payment history</Link></Text>
                            <Text fz={14} color={theme.colors.orange[1]}><Link to="/account/billing/overview" className={classes.highlightedLink}>Buy credits</Link></Text>
                        </Flex>

                        {/*next tier*/}
                        <Flex ml={-16} direction={"row"} align={"center"} gap={'6px'}>
                            <IconCircle size={12} color={theme.colors.gray[6]} strokeWidth={1} fill={theme.colors.gray[7]} />
                            <Text mt={2} className={classes.smallText}>Next Tier</Text>
                        </Flex>
                        <Text mt={-5} pb={20}>Usage tier {usageTier + 1}</Text>
                    </div>

                    <Tooltip
                      label="Please email us at accounts@nutricloud.ai to request an exception."
                      className={classes.toolTip}
                      position={'bottom'}
                      transitionProps={{ transition: 'scale', duration: 200 }}
                      multiline
                      w={300}
                      radius={"xs"}
                      p={20}
                      fw={500}
                      fz={'14px'}
                      ml={15}
                      offset={10}
                      bg={theme.colors.gray[9]}
                      color={theme.colors.gray[8]}
                    >
                          <Badge mb={5} className={classes.customBadge} variant="filled">
                                request an exception
                            </Badge>
                    </Tooltip>
                </div>
            </div>
        </div>
    );
}

export default Limits;
