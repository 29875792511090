import React, {useEffect, useState} from 'react';
import {
    Button,
    createStyles,
    Text,
    Card,
    useMantineTheme,
    SegmentedControl,
    rem,
    Select,
    SelectItem,
    Loader,
    Accordion,
    Group,
    Box,
    Center,
    Grid, List, FileInput
} from '@mantine/core';
import { Link, useNavigate } from 'react-router-dom';
import {ApiSelector} from "./Components/ApiSelector";
import { Prism } from '@mantine/prism';

// code generators
import getCurlCommand from "./Components/DynamicCodeGenerators/CURL";
import getNodeCode from "./Components/DynamicCodeGenerators/node";
import getPythonCode from "./Components/DynamicCodeGenerators/Python";
import getCppCode from "./Components/DynamicCodeGenerators/cpp";
import getGoCommand from "./Components/DynamicCodeGenerators/GoLang";
import getJavaCode from "./Components/DynamicCodeGenerators/Java";
import getJsCommand from "./Components/DynamicCodeGenerators/JavaScript";
import getKotlinCode from "./Components/DynamicCodeGenerators/Kotlin";
import getRubyCommand from "./Components/DynamicCodeGenerators/Ruby";
import getSwiftCommand from "./Components/DynamicCodeGenerators/Swift";
import getTypeScriptCommand from "./Components/DynamicCodeGenerators/TypeScript";

// icons
import {TsIcon} from "../../componenets/icons/TypeScript";
import {JavaIcon} from "../../componenets/icons/Java";
import {BashIcon} from "../../componenets/icons/Bash";
import {CppIcon} from "../../componenets/icons/cpp";
import {GoLangIcon} from "../../componenets/icons/GoLang";
import {JsIcon} from "../../componenets/icons/JavaScript";
import {KotlinIcon} from "../../componenets/icons/Kotlin";
import {NodeJSIcon} from "../../componenets/icons/NodeJS";
import {PythonIcon} from "../../componenets/icons/Python";
import {RubyIcon} from "../../componenets/icons/Ruby";
import {SwiftIcon} from "../../componenets/icons/Swift";

// parameter selectors
import SearchParameters from "./Components/Parameters/SearchParameters";
import NutrientProfileParameters from "./Components/Parameters/NutrientProfileParameters";
import DatabaseSearchParameters from "./Components/Parameters/DatabaseParameters";
import AutoCompleteParameters from "./Components/Parameters/AutoCompleteParameters";
import {ProgrammingLanguageSelector} from "../../componenets/etc/LanguageSelector";
import Sidebar from "../../componenets/sidebar/sidebar";
import axios from "axios";
import RecipeAutoCompleteParameters
    from "./Components/Parameters/AutoCompleteParameters";
import {FilePlus, MoreHorizontal} from "react-feather";
import RecipeSearchParameters from "./Components/Parameters/RecipeSearchParameters";
import FoodClassifyParameters from "./Components/Parameters/ClassifyParamters";
import InformationParameters from "./Components/Parameters/InformationRequest";
import ImageSearchGeneration from "./Components/Parameters/ImageSearchParameters";
import ImageGenerationParameters from "./Components/Parameters/ImageGeneration";
import RecipeImageGenerationParameters
    from "./Components/Parameters/RecipeImageGeneration";
import ListInputComponent from "./Components/Parameters/NutrientGenerationInput";
import RecipeGenerationParameters
    from "./Components/Parameters/RecipeGenerationParameters";
import {useAuth} from "../../AuthContext";
import PrettyJsonDisplay, {
    MultiplePrettyJsonDisplay
} from "./Components/prettyPrintJson";


const useStyles = createStyles((theme) => ({
    inputMargin: {
        marginBottom: '10px',
    },
    titleSize: {
        fontSize: '24x',
        '@media (max-height: 1000px)': {
            fontSize: '16px'
        },
    },
    header: {
        width: '100vw - 60px',
        // height: '60px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingLeft: theme.spacing.md,
        paddingRight: theme.spacing.md,
        paddingBottom: 0,
        paddingTop: 0,
        boxSizing: 'border-box',
        marginTop: -20,
        marginBottom: -30,

        '@media (max-width: 850px)': {
            flexDirection: 'column-reverse',
            alignItems: 'flex-start',
            marginBottom: 0,
        },
    },
    api_selector: {
        '@media (max-width: 850px)': {
            marginTop: -20
        },
        '@media (max-width: 475px)': {
            marginTop: -30
        },

    },
    getDocsButton: {
        '@media (max-width: 619px)': {
            marginTop: 10,
            marginLeft: 3
        },
    },
    container: {
        display: 'flex',
        height: 'calc(100vh - 60px)', // Ensure the container has a defined height

        '@media (max-width: 850px)': {
            flexDirection: 'column',
        },
    },
    column: {
        display: 'flex', // Add this
        flexDirection: 'column', // Add this
        minHeight: 'calc(100vh - 60px)',
        maxHeight: 'calc(100vh - 60px)',
        '@media (max-width: 850px)': {
            width: '100%',
            minHeight: 730
        },
    },
    leftColumn: {
        minWidth: '300px',
        width: '25vw',

        '@media (max-width: 850px)': {
            width: '100%',
        },
    },
    rightColumn: {
        flex: 1,
        width: "50%",
        '@media (max-width: 850px)': {
            width: "100%",
            minHeight: 500,
            maxHeight: 'calc(100vh - 60px)',
            height: "100%"
        },
    },
    parameterBox: {
        margin: '10px',
        flexGrow: 1, // Change height to flexGrow
        overflow: 'auto',
    },
    outputBox: {
        margin: '10px',
        flexGrow: 1, // Change height to flexGrow
        height: '100%', // Ensure it fills its parent
        overflow: 'hidden'
    },
    submitButtonContainer: {
        display: 'flex', // Use flex layout
        justifyContent: 'center', // Center button horizontally
        padding: '10px', // Give it some space
    },
    parametersTitle: {
        textAlign: 'center',
        fontSize: '25px',
        fontWeight: 500,
        marginTop: 0,
        '@media (max-width: 850px)': {
            fontSize: '18px'
        },
    },
    rightColumnColumns: {
        // display: 'flex',
        // flexDirection: 'row',
        height: '100%', // ensures it fills its parent
        overflowY: 'auto', // make it scrollable
    },
    rightColumnLeftSide: {
        // flex: 6,
        // display: 'flex', // Add this
        // flexDirection: 'column', // Add this
        // height: '100%', // Add this
        // width: '40%',
    },
    rightColumnRightSide: {
        // flex: 4,
        overflowY: 'hidden',
        display: 'flex', // Add this
        flexDirection: 'column', // Add this
        height: '100%', // Add this
        // width: '40%'

    },
    scrollablePrism: {
        maxHeight: '100%', // ensure it fills its parent
        overflowY: 'auto', // make it scrollable
        // width: '100%',
        borderRadius: '10px',
        whiteSpace: 'pre-wrap',
    },

          gradientBorderBox: {
    display: 'inline-flex',
        backgroundImage: theme.fn.gradient({ from: 'pink', to: 'orange' }),
    borderRadius: '5px',
    padding: '2px', // Controls the thickness of the gradient border
    alignItems: 'center',
    justifyContent: 'center',
      // marginLeft: '50px',
      width: '65px',
              marginRight: '15px',
  },
  innerBox: {
    backgroundColor: theme.colors.dark[7], // Should match the page's background
    borderRadius: '5px',
      width: '60px',
    paddingLeft: "10px",
    paddingRight: "10px",
    paddingTop: "5x",
    paddingBottom: "0px",
      color: theme.colors.gray[1]
  },
    prettyDropDownData: {
        fontSize: '16px',
        lineHeight: '10px',
    },
    prettyDropDownDataMultiLine: {
        fontSize: '16px',
    }
}));

const selectorStyles = createStyles((theme) => ({
    root: {
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.white,
        boxShadow: theme.shadows.md,
        border: `${rem(1)} solid ${
            theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[1]
        }`,
    },

    indicator: {
        backgroundImage: theme.fn.gradient({ from: 'pink', to: 'orange' }),
    },

    control: {
        border: '0 !important',
    },

    label: {
        '&, &:hover': {
            '&[data-active]': {
                color: theme.white,
            },
        },
    },


}));


interface url_dict {
    [key: string]: {
        [key: string]: {
            [key: string]: string;
        };
    };
}

const urls: url_dict = {
    "Search": {
        "Food": {
            "Search": "/api/search/food/",
            "Auto-complete": "/api/food/search/autocomplete",
        },
        "Recipe": {
            "Search": "/api/search/recipe/",
            "Auto-complete": "/api/recipe/search/autocomplete",
        }
    },
    "Classify": {
        "Food": {
            "Classify": "/api/classify/food/"
        },
        "Recipe": {
            "Classify": "/api/classify/recipe/"
        },
    },
    "Info": {
        "Food": {
            "Seasonality": "/api/food/info/seasonality",
            "Locality": "/api/food/info/locality",
            "History": "/api/food/info/history",
            "Tips": "/api/food/info/tips",
            "Cutting Instructions": "/api/food/info/cutting-instructions",
        },
        "Recipe": {
            "Seasonality": "/api/recipe/info/seasonality",
            "Locality": "/api/recipe/info/locality",
            "History": "/api/recipe/info/history",
            "Tips": "/api/recipe/info/tips",
            "Equipment": "/api/recipe/info/eqiupment",
            "Required Ingredients": "/api/recipe/info/required-ingredients",
        }
    },
    "Image": {
        "Food": {
            "Recognition": "/api/food/image/recognition",
            "Generate": "/api/food/image/generate",
        },
        "Recipe": {
            "Recognition": "/api/recipe/image/recognition",
            "Generate": "/api/recipe/image/generate",
            "Generate Recipe": "/api/recipe/image/generate-recipe",
        }
    },
    "Generate": {
        "Food": {
            "Image": "/api/food/image/generate",
        },
        "Recipe": {
            "Recipe": "/api/recipe/generate/",
            "Image": "/api/recipe/image/generate",
            "Nutrients": "/api/recipe/generate/nutrients",
        }
    }
}


const App: React.FC = () => {
    const theme = useMantineTheme();
    const { classes } = useStyles();
     const {apiClient} = useAuth();
    const { classes: selectorClasses } = selectorStyles();
    const [selectedLanguage, setSelectedLanguage] = useState('bash');

    const [prettyResponse, setPrettyResponse] = useState(false);
    const [response, setResponse] = useState<any>(null);
    const [requestError, setRequestError] = useState<any>(null);
    const [loading, setLoading] = useState(false);

    // image
    const [imageFile, setImageFile] = useState<File | null>(null);
    const handleImageFileChange = (file: File | null) => {
        setImageFile(file);
    };

    const verifyRequestBody = () => {
        if (currentApi === 'Search') {
            if (foodOrRecipe === 'Food') {
                if (parameters.queries.length === 0) {
                    throw new Error('Please add a query in the search parameters to search for.');
                }
            } else if (foodOrRecipe === 'Recipe') {
                if (parameters.query === '') {
                    throw new Error('Please add a query in the search parameters to search for.');
                }
            }
        }
    };

    const sendRequest = async () => {
        try {
            setRequestError(null);
            setResponse(null);
            setLoading(true);

            // get url
            const baseURL = process.env.REACT_APP_BASE_URL;
            let body;
            let headers;

            // console.log(currentApi);
            const path = urls[selectedEndpoint][foodOrRecipe][currentApi];

            const url = new URL(baseURL + path);

            if (currentApi === "Recognition" || currentApi === "Recipe") {

                // Add search parameters to the URL
                // console.log(parameters)
                Object.keys(parameters).forEach(key => {
                    if (parameters[key] !== null && parameters[key] !== undefined) {
                        url.searchParams.append(key, parameters[key].toString());
                    }
                });

                // Prepare the form data
                const formData = new FormData();
                if (imageFile) {
                    formData.append('image', imageFile);  // 'image' is the key expected by the backend
                }
                else {
                     formData.append('image', '');
                }
                body = formData;
                headers = {
                    'Content-Type': 'multipart/form-data',
                };
            } else {
                // Handle standard JSON request

                body = JSON.stringify(parameters);
                // console.log(body);
                // console.log(body);
                headers = {
                    'Content-Type': 'application/json',
                };
            }

            verifyRequestBody();

            // make the request
            const response = await apiClient.post(url.toString(), body, {
                headers: headers,
                withCredentials: true
            });

            setResponse(response.data);
        } catch (error: any) {
            if (axios.isAxiosError(error)) {
                // Handle API errors
                const errorMessage = error.response?.data?.detail ||
                                   error.message ||
                                   'An unexpected error occurred';
                setRequestError(errorMessage);
            } else {
                // Handle custom validation errors
                setRequestError(error.message);
            }
        }
        setLoading(false);
    };


    // take in image link and render it
    const renderImage = (imageLink: string) => {
        if (imageLink) {
            return (
                <img src={imageLink} alt="food"
                     style={{width: '100%', height: 'auto', marginBottom: 50, marginTop: 20}}/>
            );
        }
        else {
            return <div></div>;
        }
    }

        const renderResults = () => {

            if (loading) {
                return (
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: '30vh',
                    }}
                    >
                        <Loader color="orange" size="xl" variant="bars"/>
                    </div>
                )
            }
            else if (requestError) {
                return (
                    <div>
                        <Text color="red">
                            {requestError}
                        </Text>
                    </div>
                )
            }
            else if (response) {
                // console.log(response);
                // console.log("currentApi: ", currentApi);
                // console.log("foodOrRecipe: ", foodOrRecipe);
                // console.log("prettyResponse: ", prettyResponse);
                if (prettyResponse) {
                    // if food
                    if (foodOrRecipe === 'Food') {
                        if (currentApi === "Auto-complete") {
                          return (
                                <Accordion m={0} mr={10} chevron={<MoreHorizontal/>}>
                                    {response.map((item: any, index: any) => (
                                        <Accordion.Item
                                            key={index}
                                            value={`${item.title}-${index}`}
                                        >
                                            <Accordion.Control>
                                                <Grid>
                                                    <Grid.Col span={10}>
                                                        <Text fz={16}>
                                                            {item.title}
                                                        </Text>
                                                    </Grid.Col>
                                                    <Grid.Col span={2}>
                                                    </Grid.Col>
                                                </Grid>
                                            </Accordion.Control>
                                        </Accordion.Item>
                                    ))}
                                </Accordion>
                          );
                    }
                        else if (currentApi === "Search") {
                              return (
                                <Accordion m={0} mr={10}>
                                  {response.map((queryResult: any, queryIndex: any) => {
                                    // Get the search term (key) and results array
                                    const searchTerm = Object.keys(queryResult)[0];
                                    const results = queryResult[searchTerm];

                                    return (
                                      <Accordion.Item
                                        key={`query-${queryIndex}`}
                                        value={`query-${searchTerm}-${queryIndex}`}
                                      >
                                        <Accordion.Control>
                                          <Text fz={20} fw={600}>{searchTerm}</Text>
                                        </Accordion.Control>
                                        <Accordion.Panel>
                                          <Accordion>
                                            {results.map((item: any, itemIndex: number) => (
                                              <Accordion.Item
                                                key={`result-${itemIndex}`}
                                                value={`${item.food_name}-${itemIndex}`}
                                              >
                                                <Accordion.Control>
                                                  <Grid>
                                                    <Grid.Col span={10}>
                                                      <Text fz={18} fw={600}>{item.food_name}</Text>
                                                    </Grid.Col>
                                                    <Grid.Col span={2}></Grid.Col>
                                                  </Grid>
                                                </Accordion.Control>
                                                <Accordion.Panel>
                                                  <Grid>
                                                    <Grid.Col span={12}>
                                                      {/* Similarity score display */}
                                                      <div style={{
                                                        display: 'flex',
                                                        justifyContent: 'space-between',
                                                        alignItems: 'center',
                                                        marginBottom: 10
                                                      }}>
                                                        <Text fz={18} fw={600} className={classes.prettyDropDownData}>
                                                          Similarity Score
                                                        </Text>
                                                        <Box className={classes.gradientBorderBox}>
                                                          <Box className={classes.innerBox}>
                                                            <Center>
                                                              <Text size="sm">
                                                                {item.similarity_score.toFixed(2)}
                                                              </Text>
                                                            </Center>
                                                          </Box>
                                                        </Box>
                                                      </div>

                                                      {/* Nutrients display */}
                                                        <Text mb={20} mt={10} fz={18} fw={600}>Nutrients</Text>
                                                      {item.nutrients.map((nutrient: any, nutrientIndex: number) => (
                                                        <Grid key={nutrientIndex} style={{
                                                          width: '100%',
                                                          alignItems: 'center'
                                                        }}>
                                                          <Grid.Col span={12} style={{
                                                            display: 'flex',
                                                            justifyContent: 'space-between',
                                                            marginBottom: 10
                                                          }}>
                                                            <Text
                                                              className={classes.prettyDropDownData}
                                                              align="left"
                                                            >
                                                              {nutrient.nutrient_name}:
                                                            </Text>
                                                            <Text
                                                              className={classes.prettyDropDownData}
                                                              align="right"
                                                            >
                                                              {nutrient.value} {nutrient.unit}
                                                            </Text>
                                                          </Grid.Col>
                                                        </Grid>
                                                      ))}
                                                    </Grid.Col>
                                                  </Grid>

                                                  {/* Portions display */}
                                                    <Text mb={20} mt={10} fz={22} fw={600}>Portion Size</Text>
                                                  {item.portions.map((portion: any, portionIndex: number) => (
                                                    <Grid key={portionIndex} style={{
                                                      width: '100%',
                                                      alignItems: 'center'
                                                    }}>
                                                      <Grid.Col span={12} style={{
                                                        display: 'flex',
                                                        justifyContent: 'space-between',
                                                        marginBottom: 10
                                                      }}>
                                                        <Text
                                                          className={classes.prettyDropDownData}
                                                          align="left"
                                                        >
                                                          Portion:
                                                        </Text>
                                                        <Text
                                                          className={classes.prettyDropDownData}
                                                          align="right"
                                                        >
                                                          {portion.value} {portion.unit}
                                                        </Text>
                                                      </Grid.Col>
                                                    </Grid>
                                                  ))}
                                                </Accordion.Panel>
                                              </Accordion.Item>
                                            ))}
                                          </Accordion>
                                        </Accordion.Panel>
                                      </Accordion.Item>
                                    );
                                  })}
                                </Accordion>
                              );
                        }
                        else if (["Allergy", "Diet"].includes(currentApi)) {
                            return (
                                <Accordion m={0} mr={10} chevron={<MoreHorizontal/>}>
                                    {response.tags.map((item: any, index: any) => (
                                        <Accordion.Item
                                            key={index}
                                            value={`${item}-${index}`}
                                        >
                                            <Accordion.Control>
                                                <Grid>
                                                    <Grid.Col span={10}>
                                                        <Text fz={16}>
                                                            {item}
                                                        </Text>
                                                    </Grid.Col>
                                                    <Grid.Col span={2}>
                                                    </Grid.Col>
                                                </Grid>
                                            </Accordion.Control>
                                        </Accordion.Item>
                                    ))}
                                </Accordion>
                          );
                        }
                        else if (currentApi === "Branded") {
                            return (
                                <div>
                                    <Grid>
                                        <Grid.Col span={6}>
                                            <Text mb={10} fz={24} fw={600}>
                                                Is Branded:
                                            </Text>
                                            <Text mb={10} fz={24} fw={600}>
                                                Brand:
                                            </Text>

                                        </Grid.Col>
                                        <Grid.Col span={6}>
                                            <Text>{response.is_branded ? "Yes" : "No"}</Text>
                                            <Text>
                                                {response.brand}
                                            </Text>
                                        </Grid.Col>
                                    </Grid>

                                </div>
                          );
                        }
                        else if (currentApi === "Seasonality") {
                            return (
                                <Accordion m={0} mr={10}>
                                    {response.seasons.map((item: any, index: any) => (
                                        <Accordion.Item
                                            key={index}
                                            value={`${item.name}-${index}`}
                                        >
                                            <Accordion.Control>
                                                <Grid>
                                                    <Grid.Col span={10}>
                                                        <Text fz={16}>
                                                            {item.name}
                                                        </Text>
                                                    </Grid.Col>
                                                    <Grid.Col span={2}>
                                                    </Grid.Col>
                                                </Grid>
                                            </Accordion.Control>
                                            <Accordion.Panel>
                                                <Grid>
                                                    <Grid.Col span={12}>

                                                        <Text mb={10} fz={20} fw={600}>
                                                            Season:
                                                        </Text>

                                                        {/*start month*/}
                                                        <Text mt={10} className={classes.prettyDropDownData}>
                                                            {item.start_month} - {item.end_month}
                                                        </Text>

                                                        <Text mt={30} mb={10} fz={20} fw={600}>
                                                            Peak Months:
                                                        </Text>
                                                        { item.peak_months ? ( item.peak_months.map((peak_month: any, peak_monthIndex: any) => (
                                                            <Grid key={peak_monthIndex}
                                                                  style={{
                                                                      width: '100%',
                                                                      alignItems: 'center'
                                                                  }}>
                                                                <Grid.Col span={12}
                                                                          style={{
                                                                              display: 'flex',
                                                                              justifyContent: 'space-between',
                                                                              marginBottom: 10
                                                                          }}>
                                                                    <Text
                                                                        className={classes.prettyDropDownData}
                                                                        align="right">
                                                                        {peak_month}
                                                                    </Text>
                                                                </Grid.Col>
                                                            </Grid>
                                                        ))): () => <div>not displayed</div>}
                                                    </Grid.Col>
                                                </Grid>
                                            </Accordion.Panel>
                                        </Accordion.Item>
                                    ))}
                                </Accordion>
                            );
                        }
                        else if (currentApi === "Locality") {
                            return (
                                <Accordion m={0} mr={10} chevron={<MoreHorizontal/>}>
                                    {response.localities.map((item: any, index: any) => (
                                        <Accordion.Item
                                            key={index}
                                            value={`${item}-${index}`}
                                        >
                                            <Accordion.Control>
                                                <Grid>
                                                    <Grid.Col span={10}>
                                                        <Text fz={16}>
                                                            {item.region_name}, {item.country}
                                                        </Text>
                                                    </Grid.Col>
                                                    <Grid.Col span={2}>
                                                    </Grid.Col>
                                                </Grid>
                                            </Accordion.Control>
                                        </Accordion.Item>
                                    ))}
                                </Accordion>
                          );
                        }
                        else if (currentApi === "History") {
                            return (
                                <div>
                                    <Accordion m={0} mr={10}>
                                        { response.key_events ? (response.key_events.map((item: any, index: any) => (
                                            <Accordion.Item
                                                key={index}
                                                value={`${item.event}-${index}`}
                                            >
                                                <Accordion.Control>
                                                    <Grid>
                                                        <Grid.Col span={10}>
                                                            <Text fz={16}>
                                                                {item.event}
                                                            </Text>
                                                        </Grid.Col>
                                                        <Grid.Col span={2}>
                                                        </Grid.Col>
                                                    </Grid>
                                                </Accordion.Control>
                                                <Accordion.Panel>
                                                    <Grid>

                                                        <Grid.Col span={12}>
                                                            {/*date*/}
                                                            {item.date ? (
                                                                <div>
                                                                    <Text mt={30} mb={10} fz={20} fw={600}>Date: </Text>
                                                                    <Text fz={16}>{item.date}</Text>
                                                                </div>
                                                            ) : (
                                                                <div>null</div>
                                                            )}
                                                        {/*    description*/}
                                                            {item.description ? (
                                                                <div>
                                                                    <Text mt={30} mb={10} fz={20} fw={600}>Description:</Text>
                                                                    <Text fz={16}>{item.description}</Text>
                                                                </div>
                                                            ) : (
                                                                <div>Null</div>
                                                            )}
                                                        </Grid.Col>
                                                    </Grid>
                                                </Accordion.Panel>
                                            </Accordion.Item>
                                        ))) : () => <div>not displayed</div>}
                                    </Accordion>
                                </div>
                            );
                        }
                        else if (currentApi === "Tips") {
                            return (
                              <Accordion m={0} mr={10} chevron={<MoreHorizontal />}>
                                {response.tips.map((item: any, index:any) => (
                                  <Accordion.Item key={index} value={`${item}-${index}`}>
                                    <Accordion.Control>
                                      <Text fz={16}>{item}</Text>
                                    </Accordion.Control>
                                  </Accordion.Item>
                                ))}
                              </Accordion>
                            );
                          }
                        else if (currentApi === "Recognition") {
                            return (
                                <Accordion m={0} mr={10}>
                                    {response.results.map((item: any, index: any) => (
                                        <Accordion.Item
                                            key={index}
                                            value={`${item.food_name}-${index}`}
                                        >
                                            <Accordion.Control>
                                                <Grid>
                                                    <Grid.Col span={10}>
                                                        <Text fz={16}>
                                                            {item.food_name}
                                                        </Text>
                                                    </Grid.Col>
                                                    <Grid.Col span={2}>
                                                    </Grid.Col>
                                                </Grid>
                                            </Accordion.Control>
                                            <Accordion.Panel>
                                                <Grid>

                                                    <Grid.Col span={12}>
                                                        {/* Similarity score display area */}
                                                        <div style={{
                                                            display: 'flex',
                                                            justifyContent: 'space-between',
                                                            alignItems: 'center',
                                                            marginBottom: 10
                                                        }}>

                                                            <Text
                                                                className={classes.prettyDropDownData}>Similarity
                                                                score:</Text>
                                                            <Box
                                                                className={classes.gradientBorderBox}>
                                                                <Box
                                                                    className={classes.innerBox}>
                                                                    <Center>
                                                                        <Text size="sm">
                                                                            {item.similarity_score.toFixed(2)}
                                                                        </Text>
                                                                    </Center>
                                                                </Box>
                                                            </Box>
                                                        </div>


                                                        {/* Map over nutrients to display names */}
                                                        {item.nutrients.map((nutrient: any, nutrientIndex: any) => (
                                                            <Grid key={nutrientIndex}
                                                                  style={{
                                                                      width: '100%',
                                                                      alignItems: 'center'
                                                                  }}>
                                                                <Grid.Col span={12} style={{
                                                                    display: 'flex',
                                                                    justifyContent: 'space-between',
                                                                    marginBottom: 10
                                                                }}>
                                                                    <Text
                                                                        className={classes.prettyDropDownData}
                                                                        align="left">
                                                                        {nutrient.nutrient_name}:
                                                                    </Text>
                                                                    <Text
                                                                        className={classes.prettyDropDownData}
                                                                        align="right">
                                                                        {nutrient.value} {nutrient.unit}
                                                                    </Text>
                                                                </Grid.Col>
                                                            </Grid>
                                                        ))}
                                                    </Grid.Col>

                                                </Grid>


                                                {item.portions.map((portion: any, portionIndex: any) => (
                                                    <Grid key={portionIndex}
                                                          style={{
                                                              width: '100%',
                                                              alignItems: 'center'
                                                          }}>
                                                        <Grid.Col span={12} style={{
                                                            display: 'flex',
                                                            justifyContent: 'space-between',
                                                            marginBottom: 10
                                                        }}>
                                                            <Text
                                                                className={classes.prettyDropDownData}
                                                                align="left">
                                                                Portion:
                                                            </Text>
                                                            <Text
                                                                className={classes.prettyDropDownData}
                                                                align="right">
                                                                {portion.value} {portion.unit}
                                                            </Text>
                                                        </Grid.Col>
                                                    </Grid>
                                                ))}
                                            </Accordion.Panel>
                                        </Accordion.Item>
                                    ))}
                                </Accordion>
                            );
                        }
                        else if (currentApi === "Generate") {
                            return (
                                <div>
                                    {renderImage(response)}
                                </div>
                            );
                        }
                        else if (currentApi === "Image") {
                            return (
                                <div>
                                    {renderImage(response)}
                                </div>
                            );
                        }
                        else if (currentApi === "Classify"){
                            return (
                                <div>
                                    {PrettyJsonDisplay({data: response})}
                                </div>
                            );
                        }

                    }
                    // if recipe
                    else if (foodOrRecipe === 'Recipe') {
                        if (currentApi === "Auto-complete") {
                          return (
                                <Accordion m={0} mr={10} chevron={<MoreHorizontal/>}>
                                    {response.map((item: any, index: any) => (
                                        <Accordion.Item
                                            key={index}
                                            value={`${item.title}-${index}`}
                                        >
                                            <Accordion.Control>
                                                <Grid>
                                                    <Grid.Col span={10}>
                                                        <Text fz={16}>
                                                            {item.title}
                                                        </Text>
                                                    </Grid.Col>
                                                    <Grid.Col span={2}>
                                                    </Grid.Col>
                                                </Grid>
                                            </Accordion.Control>
                                        </Accordion.Item>
                                    ))}
                                </Accordion>
                          );
                    }
                        else if (currentApi === "Search") {
                            console.log("we hitting it son")
                            console.log("we hitting it son")
                            console.log("we hitting it son")
                            console.log("we hitting it son")
                            return (
                                <div>
                                    {MultiplePrettyJsonDisplay({data: response})}
                                </div>
                                // <Accordion m={0} mr={10}>
                                //     {response.results.map((item: any, index: any) => (
                                //         <Accordion.Item
                                //             key={index}
                                //             value={`${item.title}-${index}`}
                                //         >
                                //             <Accordion.Control>
                                //                 <Grid>
                                //                     <Grid.Col span={10}>
                                //                         <Text fz={16}>
                                //                             {item.title}
                                //                         </Text>
                                //                     </Grid.Col>
                                //                     <Grid.Col span={2}>
                                //                     </Grid.Col>
                                //                 </Grid>
                                //             </Accordion.Control>
                                //             <Accordion.Panel>
                                //                 <Grid>
                                //
                                //                     <Grid.Col span={12}>
                                //                         {/* Similarity score display area */}
                                //                         <div style={{
                                //                             display: 'flex',
                                //                             justifyContent: 'space-between',
                                //                             alignItems: 'center',
                                //                             marginBottom: 10
                                //                         }}>
                                //
                                //                             <Text
                                //                                 className={classes.prettyDropDownData}>Similarity
                                //                                 score:</Text>
                                //                             <Box
                                //                                 className={classes.gradientBorderBox}>
                                //                                 <Box
                                //                                     className={classes.innerBox}>
                                //                                     <Center>
                                //                                         <Text size="sm">
                                //                                             {item.similarity_score.toFixed(2)}
                                //                                         </Text>
                                //                                     </Center>
                                //                                 </Box>
                                //                             </Box>
                                //                         </div>
                                //
                                //
                                //                         {/*        image*/}
                                //                         {renderImage(item.image_link)}
                                //
                                //
                                //                         <Text mb={10} fz={24} fw={600}>
                                //                             Ingredients:
                                //                         </Text>
                                //                         {item.ingredients.map((ingredient: any, ingredientIndex: any) => (
                                //                             <Grid key={ingredientIndex}
                                //                                   style={{
                                //                                       width: '100%',
                                //                                       alignItems: 'center'
                                //                                   }}>
                                //                                 <Grid.Col span={12}
                                //                                           style={{
                                //                                               display: 'flex',
                                //                                               justifyContent: 'space-between',
                                //                                               marginBottom: 10
                                //                                           }}>
                                //                                     {/*<Text*/}
                                //                                     {/*    className={classes.prettyDropDownData}*/}
                                //                                     {/*    align="left">*/}
                                //                                     {/*</Text>*/}
                                //                                     <Text
                                //                                         className={classes.prettyDropDownData}
                                //                                         align="right">
                                //                                         {ingredient}
                                //                                     </Text>
                                //                                 </Grid.Col>
                                //                             </Grid>
                                //                         ))}
                                //                         <div style={{height: 50}}></div>
                                //                         <Text mb={10} fz={24} fw={600}>
                                //                             Directions:
                                //                         </Text>
                                //                         {item.directions.map((direction: any, directionIndex: any) => (
                                //                             <Grid key={directionIndex}
                                //                                   style={{
                                //                                       width: '100%',
                                //                                       alignItems: 'center'
                                //                                   }}>
                                //                                 <Grid.Col span={12}
                                //                                           style={{
                                //                                               display: 'flex',
                                //                                               justifyContent: 'space-between',
                                //                                               marginBottom: 10
                                //                                           }}>
                                //                                     <Text
                                //                                         className={classes.prettyDropDownDataMultiLine}
                                //                                         align="left">
                                //                                         {direction}
                                //                                     </Text>
                                //                                 </Grid.Col>
                                //                             </Grid>
                                //                         ))}
                                //
                                //                         <div style={{height: 50}}></div>
                                //                         <Text mb={10} fz={24} fw={600}>
                                //                             Nutrients:
                                //                         </Text> {/* Map over nutrients to display names */}
                                //
                                //                         {item.nutrients ? (
                                //                             item.nutrients.map((nutrient: any, nutrientIndex: any) => (
                                //                             <Grid key={nutrientIndex}
                                //                                   style={{
                                //                                       width: '100%',
                                //                                       alignItems: 'center'
                                //                                   }}>
                                //                                 <Grid.Col span={12}
                                //                                           style={{
                                //                                               display: 'flex',
                                //                                               justifyContent: 'space-between',
                                //                                               marginBottom: 10
                                //                                           }}>
                                //                                     <Text
                                //                                         className={classes.prettyDropDownData}
                                //                                         align="left">
                                //                                         {nutrient.nutrient_name}:
                                //                                     </Text>
                                //                                     <Text
                                //                                         className={classes.prettyDropDownData}
                                //                                         align="right">
                                //                                         {nutrient.value} {nutrient.unit}
                                //                                     </Text>
                                //                                 </Grid.Col>
                                //                             </Grid>
                                //                         ))): (
                                //                             <div>not dipslayed</div>
                                //                         )}
                                //                     </Grid.Col>
                                //                 </Grid>
                                //             </Accordion.Panel>
                                //         </Accordion.Item>
                                //     ))}
                                // </Accordion>
                            );
                        } else if (["Allergy", "Diet", "Meal"].includes(currentApi)) {
                            return (
                                <Accordion m={0} mr={10} chevron={<MoreHorizontal/>}>
                                    {response.tags.map((item: any, index: any) => (
                                        <Accordion.Item
                                            key={index}
                                            value={`${item}-${index}`}
                                        >
                                            <Accordion.Control>
                                                <Grid>
                                                    <Grid.Col span={10}>
                                                        <Text fz={16}>
                                                            {item}
                                                        </Text>
                                                    </Grid.Col>
                                                    <Grid.Col span={2}>
                                                    </Grid.Col>
                                                </Grid>
                                            </Accordion.Control>
                                        </Accordion.Item>
                                    ))}
                                </Accordion>
                          );
                        }
                        else if (currentApi === "Branded") {
                            return (
                                <div>
                                    <Grid>
                                        <Grid.Col span={6}>
                                            <Text mb={10} fz={24} fw={600}>
                                                Is Branded:
                                            </Text>
                                            <Text mb={10} fz={24} fw={600}>
                                                Brand:
                                            </Text>

                                        </Grid.Col>
                                        <Grid.Col span={6}>
                                            <Text>{response.is_branded ? "Yes" : "No"}</Text>
                                            <Text>
                                                {response.brand}
                                            </Text>
                                        </Grid.Col>
                                    </Grid>

                                </div>
                          );
                        }
                        else if (currentApi === "Course") {
                            return(
                            <Accordion m={0} mr={10} chevron={<MoreHorizontal/>}>
                                    {response.courses.map((item: any, index: any) => (
                                        <Accordion.Item
                                            key={index}
                                            value={`${item}-${index}`}
                                        >
                                            <Accordion.Control>
                                                <Grid>
                                                    <Grid.Col span={10}>
                                                        <Text fz={16}>
                                                            {item}
                                                        </Text>
                                                    </Grid.Col>
                                                    <Grid.Col span={2}>
                                                    </Grid.Col>
                                                </Grid>
                                            </Accordion.Control>
                                        </Accordion.Item>
                                    ))}
                                </Accordion>
                            );
                        }
                        else if (currentApi === "Cuisine") {
                            return(
                            <Accordion m={0} mr={10} chevron={<MoreHorizontal/>}>
                                    {response.cuisines.map((item: any, index: any) => (
                                        <Accordion.Item
                                            key={index}
                                            value={`${item}-${index}`}
                                        >
                                            <Accordion.Control>
                                                <Grid>
                                                    <Grid.Col span={10}>
                                                        <Text fz={16}>
                                                            {item}
                                                        </Text>
                                                    </Grid.Col>
                                                    <Grid.Col span={2}>
                                                    </Grid.Col>
                                                </Grid>
                                            </Accordion.Control>
                                        </Accordion.Item>
                                    ))}
                                </Accordion>
                            );
                        }
                        else if (currentApi === "Seasonality") {
                            return (
                                <Accordion m={0} mr={10}>
                                    {response.seasons.map((item: any, index: any) => (
                                        <Accordion.Item
                                            key={index}
                                            value={`${item.name}-${index}`}
                                        >
                                            <Accordion.Control>
                                                <Grid>
                                                    <Grid.Col span={10}>
                                                        <Text fz={16}>
                                                            {item.name}
                                                        </Text>
                                                    </Grid.Col>
                                                    <Grid.Col span={2}>
                                                    </Grid.Col>
                                                </Grid>
                                            </Accordion.Control>
                                            <Accordion.Panel>
                                                <Grid>
                                                    <Grid.Col span={12}>

                                                        <Text mb={10} fz={20} fw={600}>
                                                            Season:
                                                        </Text>

                                                        {/*start month*/}
                                                        <Text mt={10} className={classes.prettyDropDownData}>
                                                            {item.start_month} - {item.end_month}
                                                        </Text>

                                                        <Text mt={30} mb={10} fz={20} fw={600}>
                                                            Peak Months:
                                                        </Text>
                                                        { item.peak_months ? ( item.peak_months.map((peak_month: any, peak_monthIndex: any) => (
                                                            <Grid key={peak_monthIndex}
                                                                  style={{
                                                                      width: '100%',
                                                                      alignItems: 'center'
                                                                  }}>
                                                                <Grid.Col span={12}
                                                                          style={{
                                                                              display: 'flex',
                                                                              justifyContent: 'space-between',
                                                                              marginBottom: 10
                                                                          }}>
                                                                    <Text
                                                                        className={classes.prettyDropDownData}
                                                                        align="right">
                                                                        {peak_month}
                                                                    </Text>
                                                                </Grid.Col>
                                                            </Grid>
                                                        ))): () => <div>not displayed</div>}
                                                    </Grid.Col>
                                                </Grid>
                                            </Accordion.Panel>
                                        </Accordion.Item>
                                    ))}
                                </Accordion>
                            );
                        }
                        else if (currentApi === "Locality") {
                            return (
                                <Accordion m={0} mr={10} chevron={<MoreHorizontal/>}>
                                    {response.localities.map((item: any, index: any) => (
                                        <Accordion.Item
                                            key={index}
                                            value={`${item}-${index}`}
                                        >
                                            <Accordion.Control>
                                                <Grid>
                                                    <Grid.Col span={10}>
                                                        <Text fz={16}>
                                                            {item.region_name}, {item.country}
                                                        </Text>
                                                    </Grid.Col>
                                                    <Grid.Col span={2}>
                                                    </Grid.Col>
                                                </Grid>
                                            </Accordion.Control>
                                        </Accordion.Item>
                                    ))}
                                </Accordion>
                          );
                        }
                        else if (currentApi === "History") {
                            return (
                                <div>
                                    <Accordion m={0} mr={10}>
                                        { response.key_events ? (response.key_events.map((item: any, index: any) => (
                                            <Accordion.Item
                                                key={index}
                                                value={`${item.event}-${index}`}
                                            >
                                                <Accordion.Control>
                                                    <Grid>
                                                        <Grid.Col span={10}>
                                                            <Text fz={16}>
                                                                {item.event}
                                                            </Text>
                                                        </Grid.Col>
                                                        <Grid.Col span={2}>
                                                        </Grid.Col>
                                                    </Grid>
                                                </Accordion.Control>
                                                <Accordion.Panel>
                                                    <Grid>

                                                        <Grid.Col span={12}>
                                                            {/*date*/}
                                                            {item.date ? (
                                                                <div>
                                                                    <Text mt={30} mb={10} fz={20} fw={600}>Date: </Text>
                                                                    <Text fz={16}>{item.date}</Text>
                                                                </div>
                                                            ) : (
                                                                <div>null</div>
                                                            )}
                                                        {/*    description*/}
                                                            {item.description ? (
                                                                <div>
                                                                    <Text mt={30} mb={10} fz={20} fw={600}>Description:</Text>
                                                                    <Text fz={16}>{item.description}</Text>
                                                                </div>
                                                            ) : (
                                                                <div>Null</div>
                                                            )}
                                                        </Grid.Col>
                                                    </Grid>
                                                </Accordion.Panel>
                                            </Accordion.Item>
                                        ))) : () => <div>not displayed</div>}
                                    </Accordion>
                                </div>
                            );
                        }
                        else if (currentApi === "Tips") {
                            return (
                              <Accordion m={0} mr={10} chevron={<MoreHorizontal />}>
                                {response.tips.map((item: any, index:any) => (
                                  <Accordion.Item key={index} value={`${item}-${index}`}>
                                    <Accordion.Control>
                                      <Text fz={16}>{item}</Text>
                                    </Accordion.Control>
                                  </Accordion.Item>
                                ))}
                              </Accordion>
                            );
                          }
                        else if (currentApi === "Equipment") {
                            return (
                              <Accordion m={0} mr={10} chevron={<MoreHorizontal />}>
                                {response.required_equipment.map((item: any, index:any) => (
                                  <Accordion.Item key={index} value={`${item}-${index}`}>
                                    <Accordion.Control>
                                      <Text fz={16}>{item}</Text>
                                    </Accordion.Control>
                                  </Accordion.Item>
                                ))}
                              </Accordion>
                            );
                          }
                        else if (currentApi === "Required Ingredients") {
                            return (
                              <Accordion m={0} mr={10} chevron={<MoreHorizontal />}>
                                {response.essential_ingredients.map((item: any, index:any) => (
                                  <Accordion.Item key={index} value={`${item}-${index}`}>
                                    <Accordion.Control>
                                      <Text fz={16}>{item}</Text>
                                    </Accordion.Control>
                                  </Accordion.Item>
                                ))}
                              </Accordion>
                            );
                          }
                        else if (currentApi === "Recognition") {
                            return (
                                <Accordion m={0} mr={10}>
                                    {response.results.map((item: any, index: any) => (
                                        <Accordion.Item
                                            key={index}
                                            value={`${item.title}-${index}`}
                                        >
                                            <Accordion.Control>
                                                <Grid>
                                                    <Grid.Col span={10}>
                                                        <Text fz={16}>
                                                            {item.title}
                                                        </Text>
                                                    </Grid.Col>
                                                    <Grid.Col span={2}>
                                                    </Grid.Col>
                                                </Grid>
                                            </Accordion.Control>
                                            <Accordion.Panel>
                                                <Grid>

                                                    <Grid.Col span={12}>
                                                        {/* Similarity score display area */}
                                                        <div style={{
                                                            display: 'flex',
                                                            justifyContent: 'space-between',
                                                            alignItems: 'center',
                                                            marginBottom: 10
                                                        }}>

                                                            <Text
                                                                className={classes.prettyDropDownData}>Similarity
                                                                score:</Text>
                                                            <Box
                                                                className={classes.gradientBorderBox}>
                                                                <Box
                                                                    className={classes.innerBox}>
                                                                    <Center>
                                                                        <Text size="sm">
                                                                            {item.similarity_score.toFixed(2)}
                                                                        </Text>
                                                                    </Center>
                                                                </Box>
                                                            </Box>
                                                        </div>


                                                        {/*        image*/}
                                                        {renderImage(item.image_link)}


                                                        <Text mb={10} fz={24} fw={600}>
                                                            Ingredients:
                                                        </Text>
                                                        {item.ingredients.map((ingredient: any, ingredientIndex: any) => (
                                                            <Grid key={ingredientIndex}
                                                                  style={{
                                                                      width: '100%',
                                                                      alignItems: 'center'
                                                                  }}>
                                                                <Grid.Col span={12}
                                                                          style={{
                                                                              display: 'flex',
                                                                              justifyContent: 'space-between',
                                                                              marginBottom: 10
                                                                          }}>
                                                                    {/*<Text*/}
                                                                    {/*    className={classes.prettyDropDownData}*/}
                                                                    {/*    align="left">*/}
                                                                    {/*</Text>*/}
                                                                    <Text
                                                                        className={classes.prettyDropDownData}
                                                                        align="right">
                                                                        {ingredient}
                                                                    </Text>
                                                                </Grid.Col>
                                                            </Grid>
                                                        ))}
                                                        <div style={{height: 50}}></div>
                                                        <Text mb={10} fz={24} fw={600}>
                                                            Directions:
                                                        </Text>
                                                        {item.directions.map((direction: any, directionIndex: any) => (
                                                            <Grid key={directionIndex}
                                                                  style={{
                                                                      width: '100%',
                                                                      alignItems: 'center'
                                                                  }}>
                                                                <Grid.Col span={12}
                                                                          style={{
                                                                              display: 'flex',
                                                                              justifyContent: 'space-between',
                                                                              marginBottom: 10
                                                                          }}>
                                                                    <Text
                                                                        className={classes.prettyDropDownDataMultiLine}
                                                                        align="left">
                                                                        {direction}
                                                                    </Text>
                                                                </Grid.Col>
                                                            </Grid>
                                                        ))}

                                                        <div style={{height: 50}}></div>
                                                        <Text mb={10} fz={24} fw={600}>
                                                            Nutrients:
                                                        </Text> {/* Map over nutrients to display names */}

                                                        {item.nutrients ? (
                                                            item.nutrients.map((nutrient: any, nutrientIndex: any) => (
                                                            <Grid key={nutrientIndex}
                                                                  style={{
                                                                      width: '100%',
                                                                      alignItems: 'center'
                                                                  }}>
                                                                <Grid.Col span={12}
                                                                          style={{
                                                                              display: 'flex',
                                                                              justifyContent: 'space-between',
                                                                              marginBottom: 10
                                                                          }}>
                                                                    <Text
                                                                        className={classes.prettyDropDownData}
                                                                        align="left">
                                                                        {nutrient.nutrient_name}:
                                                                    </Text>
                                                                    <Text
                                                                        className={classes.prettyDropDownData}
                                                                        align="right">
                                                                        {nutrient.value} {nutrient.unit}
                                                                    </Text>
                                                                </Grid.Col>
                                                            </Grid>
                                                        ))): (
                                                            <div>not dipslayed</div>
                                                        )}
                                                    </Grid.Col>
                                                </Grid>
                                            </Accordion.Panel>
                                        </Accordion.Item>
                                    ))}
                                </Accordion>
                            );
                        }
                        else if (currentApi === "Image") {
                            return (
                                <div>
                                    {renderImage(response)}
                                </div>
                            );
                        }
                        else if (currentApi === "Nutrients") {
                            return (
                                <Grid>
                                    <Grid.Col span={12}>
                                        <div style={{height: 50}}></div>
                                        <Text mb={10} fz={24} fw={600}>
                                            Nutrients:
                                        </Text> {/* Map over nutrients to display names */}

                                        {response ? (
                                            response.map((nutrient: any, nutrientIndex: any) => (
                                            <Grid key={nutrientIndex}
                                                  style={{
                                                      width: '100%',
                                                      alignItems: 'center'
                                                  }}>
                                                <Grid.Col span={12}
                                                          style={{
                                                              display: 'flex',
                                                              justifyContent: 'space-between',
                                                              marginBottom: 10
                                                          }}>
                                                    <Text
                                                        className={classes.prettyDropDownData}
                                                        align="left">
                                                        {nutrient.nutrient_name}:
                                                    </Text>
                                                    <Text
                                                        className={classes.prettyDropDownData}
                                                        align="right">
                                                        {nutrient.value} {nutrient.unit}
                                                    </Text>
                                                </Grid.Col>
                                            </Grid>
                                        ))): (
                                            <div>not dipslayed</div>
                                        )}
                                    </Grid.Col>
                                </Grid>
                            );
                        }
                        else if (currentApi === "Recipe") {
                            return (
                                <div>
                                    <Grid>
                                        <Grid.Col span={12}>
                                            <Text fz={30} fw={600}>
                                                {response.recipe.food_title}
                                            </Text>
                                            {/* Similarity score display area */}
                                            {/*        image*/}
                                            {renderImage(response.recipe.image_url)}


                                            <Text mb={10} fz={24} fw={600}>
                                                Ingredients:
                                            </Text>
                                            {response.recipe.ingredients.map((ingredient: any, ingredientIndex: any) => (
                                                <Grid key={ingredientIndex}
                                                      style={{
                                                          width: '100%',
                                                          alignItems: 'center'
                                                      }}>
                                                    <Grid.Col span={12}
                                                              style={{
                                                                  display: 'flex',
                                                                  justifyContent: 'space-between',
                                                                  marginBottom: 10
                                                              }}>
                                                        {/*<Text*/}
                                                        {/*    className={classes.prettyDropDownData}*/}
                                                        {/*    align="left">*/}
                                                        {/*</Text>*/}
                                                        <Text
                                                            className={classes.prettyDropDownData}
                                                            align="right">
                                                            {ingredient}
                                                        </Text>
                                                    </Grid.Col>
                                                </Grid>
                                            ))}
                                            <div style={{height: 50}}></div>
                                            <Text mb={10} fz={24} fw={600}>
                                                Directions:
                                            </Text>
                                            {response.recipe.directions.map((direction: any, directionIndex: any) => (
                                                <Grid key={directionIndex}
                                                      style={{
                                                          width: '100%',
                                                          alignItems: 'center'
                                                      }}>
                                                    <Grid.Col span={12}
                                                              style={{
                                                                  display: 'flex',
                                                                  justifyContent: 'space-between',
                                                                  marginBottom: 10
                                                              }}>
                                                        <Text
                                                            className={classes.prettyDropDownDataMultiLine}
                                                            align="left">
                                                            {direction}
                                                        </Text>
                                                    </Grid.Col>
                                                </Grid>
                                            ))}

                                            <div style={{height: 50}}></div>
                                            <Text mb={10} fz={24} fw={600}>
                                                Nutrients:
                                            </Text>

                                            {response.recipe.nutrients ? (
                                                response.recipe.nutrients.map((nutrient: any, nutrientIndex: any) => (
                                                <Grid key={nutrientIndex}
                                                      style={{
                                                          width: '100%',
                                                          alignItems: 'center'
                                                      }}>
                                                    <Grid.Col span={12}
                                                              style={{
                                                                  display: 'flex',
                                                                  justifyContent: 'space-between',
                                                                  marginBottom: 10
                                                              }}>
                                                        <Text
                                                            className={classes.prettyDropDownData}
                                                            align="left">
                                                            {nutrient.nutrient_name}:
                                                        </Text>
                                                        <Text
                                                            className={classes.prettyDropDownData}
                                                            align="right">
                                                            {nutrient.value} {nutrient.unit}
                                                        </Text>
                                                    </Grid.Col>
                                                </Grid>
                                            ))): (
                                                <div>not returned</div>
                                            )}
                                            {/*tips*/}
                                            {response.recipe.tips ? ( () => (
                                                <div>
                                                    <div
                                                        style={{height: 50}}></div>
                                                    <Text mb={10} fz={24}
                                                          fw={600}>
                                                        Tips:
                                                    </Text>

                                                    <Text
                                                        className={classes.prettyDropDownData}
                                                        align="right">
                                                    {response.recipe.tips}
                                                    </Text>
                                                </div>
                                            )): (
                                                <div></div>
                                            )}

                                        {/*    cook times*/}
                                            {response.recipe.total_time ? ( () => (
                                                <div>
                                                    <div
                                                        style={{height: 50}}></div>
                                                    <Text mb={10} fz={24}
                                                          fw={600}>
                                                        Tips:
                                                    </Text>

                                                    <Text
                                                        className={classes.prettyDropDownData}
                                                        align="right">
                                                    {response.recipe.total_time}
                                                    </Text>
                                                </div>
                                            )): (
                                                <div></div>
                                            )}

                                        {/*    cook times*/}
                                            {response.recipe.total_cook_time ? ( () => (
                                                <div>
                                                    <div
                                                        style={{height: 50}}></div>
                                                    <Text mb={10} fz={24}
                                                          fw={600}>
                                                        Tips:
                                                    </Text>

                                                    <Text
                                                        className={classes.prettyDropDownData}
                                                        align="right">
                                                    {response.recipe.total_cook_time}
                                                    </Text>
                                                </div>
                                            )): (
                                                <div></div>
                                            )}

                                        {/*    cook times*/}
                                            {response.recipe.total_prep_time ? ( () => (
                                                <div>
                                                    <div
                                                        style={{height: 50}}></div>
                                                    <Text mb={10} fz={24}
                                                          fw={600}>
                                                        Tips:
                                                    </Text>

                                                    <Text
                                                        className={classes.prettyDropDownData}
                                                        align="right">
                                                    {response.recipe.total_prep_time}
                                                    </Text>
                                                </div>
                                            )): (
                                                <div></div>
                                            )}
                                        </Grid.Col>
                                    </Grid>
                                </div>
                            );
                        }
                        else if (currentApi === "Classify"){
                            return (
                                <div>
                                    {PrettyJsonDisplay({data: response})}
                                </div>
                            );
                        }
                    }
                }

                // json response
                else {
                    return (
                        <div>
                            <Prism className={classes.scrollablePrism} language={"json"}>
                                {JSON.stringify(response, null, 2)}
                            </Prism>
                        </div>
                    );
                }

            }
            else {
                return (
                    <div>

                    </div>
                )
            }
        }


    // overall endpoint category
    const [selectedEndpoint, setSelectedEndpoint] = useState('Search');

    // the current parameters
    const [defaultParamaters, setDefaultParamaters] = useState<Record<string, any>>({});

    // the current parameters values set in the form
    const [parameters, setParameters] = useState<Record<string, any>>({});

    // food or recipe selector
    const [foodOrRecipe, setFoodOrRecipe] = React.useState('Food');

    // list of all apis under the endpiont and food/recipe
    const [currentApis, setCurrentApis] = useState<string[]>([]);

    // selected specific api
    const [currentApi, setCurrentApi] = useState<string>("Search");

    const handleChangeCurrentApi = (value: any) => {
        setResponse(null);
        setCurrentApi(value);
        setParameters({});
  };

    const handleEndpointChange = (value: string) => {
        setResponse(null);
        setSelectedEndpoint(value);
        setParameters({});
    }
    const handleChange = (value: string) => {
        setResponse(null);
        setFoodOrRecipe(value);
        setParameters({});
    };

    interface ApiOption{
      [key: string]: {
        [key: string]: string[];
      };
    }

    const apiOptions: ApiOption = {
      Food: {
        Search: ["Search", "Auto-complete"],
          Classify: ["Classify"],
          Info: ["Seasonality", "Locality", "History", "Tips"],
          Image: ["Recognition", "Generate"],
          Generate: ["Image"]
      },
      Recipe: {
        Search: ["Search", "Auto-complete"],
          Classify: ["Classify"],
          Info: ["Seasonality", "Locality", "History", "Tips", "Equipment", "Required Ingredients"],
          Image: ["Recognition", "Generate", "Generate Recipe"],
          Generate: ["Image", "Recipe", "Nutrients"]
      }
    };

      useEffect(() => {
        // Update the endpoints when foodOrRecipe or selectedEndpoint changes
        const endpoints = apiOptions[foodOrRecipe]?.[selectedEndpoint] || [];
        // console.log(endpoints)
        setResponse(null)
        setCurrentApis(endpoints);
        setCurrentApi(endpoints[0]);
      }, [foodOrRecipe]);

      useEffect(() => {
      //     update params that are loaded
          const endpoints = apiOptions[foodOrRecipe]?.[selectedEndpoint] || [];
          setResponse(null)
        setCurrentApis(endpoints);
        setCurrentApi(endpoints[0]);
      }, [selectedEndpoint]);



    const API_ENDPOINTS: Record<string, string> = {
        'Classify': 'classify',
        'Info': 'info',
        'Chat': 'chat',
        'Suggest': 'suggest',
        'Search': 'search',
        'Image': 'image',
        'Generate': 'generate',
    };



    useEffect(() => {
        const getDefaultParameters = (endpoint: string): Record<string, any> => {
            switch(endpoint) {
                case 'Relational Search':
                    return {
                        searchTerm: 'default1',
                        limit: 10,
                        minSimilarity: 0.5,
                        sortOrder: 'desc',
                        normalizeVectors: 'false',
                        microNutrients: 'false',
                        macroNutrients: 'false'
                    };
                case 'AutoComplete':
                    return { param3: 'default3', param4: 'default4' };
                // Add more cases here...
                default:
                    return {};
            }
        }

        setDefaultParamaters(getDefaultParameters(selectedEndpoint));
    }, [selectedEndpoint]);


    // documentation links
    const navigate = useNavigate();
    const handleGoToDocsPage = () => {
        let path;
        switch (selectedEndpoint) {
            case 'Classify':
                path = '/docs/classify';
                break;
            case 'Search':
                path = '/docs/search/food';
                break;
            case 'Info':
                path = '/docs/info';
                break;
            case 'Image':
                path = '/docs/image';
                break;
            case 'Generate':
                path = '/docs/generate';
                break;
            case 'Chat':
                path = '/docs/chat';
                break;
            case 'Suggest':
                path = '/docs/suggest';
                break;
            default:
                path = '/signup';
        }
        navigate(path);
    };

    //
    // parameter state
    //
    const handleParamChange = (param: string, value: any) => {
        setParameters(prevState => ({ ...prevState, [param]: value }));
    };

    const renderParameters = () => {
        if (foodOrRecipe === 'Food') {
            switch (selectedEndpoint) {
                case 'Search':
                    if (currentApi === 'Search') {
                        return <SearchParameters onParamChange={handleParamChange}/>;
                    }
                    else if (currentApi === 'Auto-complete') {
                        return <RecipeAutoCompleteParameters onParamChange={handleParamChange}/>;
                    }
                    else if (currentApi === 'name-standardization') {
                        return <RecipeAutoCompleteParameters onParamChange={handleParamChange}/>;
                    }
                    else {
                        return null;
                    }
                case 'Classify':
                    return <FoodClassifyParameters onParamChange={handleParamChange}/>;
                case 'Info':
                    return <InformationParameters onParamChange={handleParamChange}/>;
                case 'Image':
                    if (currentApi === 'Recognition') {
                        return (
                            <div>
                                <ImageSearchGeneration
                                    onParamChange={handleParamChange}/>
                                <FileInput
                                    label="Upload Image"
                                    placeholder="Click to upload image"
                                    value={imageFile}
                                    onChange={handleImageFileChange}
                                    icon={<FilePlus/>}
                                  />
                            </div>
                        );
                    }
                    else if (currentApi === 'Generate') {
                        return <ImageGenerationParameters onParamChange={handleParamChange}/>;
                    }
                    else {
                        return null;
                    }
                case 'Generate':
                    if (currentApi === 'Image') {
                        return (
                            <div>
                                <ImageGenerationParameters
                                    onParamChange={handleParamChange}/>
                            </div>
                        );
                    }
                    else {
                        return null;
                    }
                default:
                    return null;
            }
        }
        else if (foodOrRecipe === 'Recipe') {
            switch (selectedEndpoint) {
                case 'Search':
                    if (currentApi === 'Search') {
                        return <RecipeSearchParameters onParamChange={handleParamChange}/>;
                    }
                    else if (currentApi === 'Auto-complete') {
                        return <AutoCompleteParameters onParamChange={handleParamChange}/>;
                    }
                    else {
                        return null;
                    }
                case 'Classify':
                    return <FoodClassifyParameters onParamChange={handleParamChange}/>;
                case 'Info':
                    return <InformationParameters onParamChange={handleParamChange}/>;
                case 'Image':
                    if (currentApi === 'Recognition') {
                        return (
                            <div>
                                <ImageSearchGeneration
                                    onParamChange={handleParamChange}/>
                                <FileInput
                                    label="Upload Image"
                                    placeholder="Click to upload image"
                                    value={imageFile}
                                    onChange={handleImageFileChange}
                                    icon={<FilePlus/>}
                                  />
                            </div>
                        );
                    }
                    else if (currentApi === 'Generate') {
                        return <RecipeImageGenerationParameters onParamChange={handleParamChange}/>;
                    }
                    else {
                        return null;
                    }
                case 'Generate':
                    if (currentApi === 'Recipe') {
                        return (
                            <div>
                                <RecipeGenerationParameters
                                    onParamChange={handleParamChange}/>
                                <FileInput
                                    label="Upload Image"
                                    placeholder="Click to upload image"
                                    value={imageFile}
                                    onChange={handleImageFileChange}
                                    icon={<FilePlus/>}
                                  />
                            </div>
                        );
                    }
                    else if (currentApi === 'Image') {
                        return <RecipeImageGenerationParameters onParamChange={handleParamChange}/>;
                    }
                    else if (currentApi === 'Nutrients') {
                        return <ListInputComponent onParamChange={handleParamChange}/>;
                    }

                    else {
                        return null;
                    }
                default:
                    return null;
            }
        }
    }


    // language picker
    type PrismLanguage = 'jsx' | 'tsx' | 'graphql' | 'yaml' | 'go' | 'cpp' | 'bash' | 'python';

    const getCode = () => {
        const baseURL = process.env.REACT_APP_BASE_URL;
            const path = urls[selectedEndpoint][foodOrRecipe][currentApi];
            const url = baseURL + path;
        switch (selectedLanguage) {
            case 'python':
                return getPythonCode(url, parameters, defaultParamaters);
            case 'bash':
                return getCurlCommand(url, parameters, defaultParamaters);
            case 'nodejs':
                return getNodeCode(url, parameters, defaultParamaters)
            case 'cpp':
                return getCppCode(url, parameters, defaultParamaters)
            case 'golang':
                return getGoCommand(url, parameters, defaultParamaters)
            case 'java':
                return getJavaCode(url, parameters, defaultParamaters)
            case 'javascript':
                return getJsCommand(url, parameters, defaultParamaters)
            case 'kotlin':
                return getKotlinCode(url, parameters, defaultParamaters)
            case 'ruby':
                return getRubyCommand(url, parameters, defaultParamaters)
            case 'swift':
                return getSwiftCommand(url, parameters, defaultParamaters)
            case 'typescript':
                return getTypeScriptCommand(url, parameters, defaultParamaters)
            default:
                return getCurlCommand(url, parameters, defaultParamaters);
        }
    }

    const languageMap: { [key: string]: PrismLanguage } = {
        'bash': 'bash',
        'cpp': 'cpp',
        'golang': 'go',
        'java': 'jsx',
        'javascript': 'jsx',
        'kotlin': 'jsx',
        'nodejs': 'jsx',
        'python': 'python',
        'ruby': 'go',
        'swift': 'go',
        'typescript': 'tsx',
    };
    const language = languageMap[selectedLanguage] || 'bash';

    return (
        <div>
            <Sidebar defaultExpanded={false} activeItem="playground"/>
            <div style={{marginLeft: "60px"}}>
                {/*header*/}
                <div className={classes.header}>
                    <div className={classes.api_selector}>
                        <ApiSelector selectedValue={selectedEndpoint} setSelectedValue={handleEndpointChange} />
                        <Button className={classes.getDocsButton} bg={theme.colors.orange[1]} radius="xl" ml={10} onClick={handleGoToDocsPage}>See the docs</Button>
                    </div>
                    <h1>Playground</h1>
                </div>
                {/*bottom portion*/}
                <div className={classes.container}>
                    {/*left column*/}
                    <div className={`${classes.column} ${classes.leftColumn}`} >
                        <Card className={classes.parameterBox}>
                            <Text className={classes.parametersTitle}>API Selector</Text>
                            <SegmentedControl
                                radius="sm"
                                size="xs"
                                fullWidth
                                data={[
                                    'Food',
                                    'Recipe',
                                ]}
                                value={foodOrRecipe}
                                classNames={selectorClasses}
                                onChange={handleChange}
                            />
                            {/*    data quality*/}
                            {!["Classify"].includes(selectedEndpoint) ? (
                                <div>
                                    <Select
                                        mt={10}
                                        data={currentApis}
                                        value={currentApi}
                                        radius="sm"
                                        size="sm"
                                        onChange={(value) => handleChangeCurrentApi(value)}
                                        className={classes.inputMargin}
                                    />
                                </div>
                            ) : (
                                <div></div>
                            )}


                            <Text mt={10} className={classes.parametersTitle}>
                                Parameters
                            </Text>
                            {renderParameters()}
                        </Card>
                        <div className={classes.submitButtonContainer}>
                            {/*    Submit button*/}
                            <Button
                                fullWidth
                                radius="xl"
                                size="md"
                                bg={theme.colors.orange[1]}
                                onClick={() => sendRequest()}
                            >
                                Send Request
                            </Button>
                        </div>
                    </div>
                    {/*right column*/}
                    <div className={`${classes.column} ${classes.rightColumn}`}>
                        <Card className={classes.outputBox}>
                        <div className={classes.rightColumnColumns}>
                            <Grid>
                                <Grid.Col xs={12} sm={12}  md={6} lg={6} xl={6}>
                                    {/*left side*/}
                                    <div style={{

                                    }} className={classes.rightColumnLeftSide}>

                                        <Text className={classes.parametersTitle}>API Response</Text>
                                        <SegmentedControl
                                            data={[
                                                {label: 'Json', value: 'Json'},
                                                {label: 'Pretty', value: 'Pretty'},
                                            ]}
                                            radius="xl"
                                            size="xs"
                                            fullWidth
                                            onChange={(value) => setPrettyResponse(value === 'Pretty')}
                                            className={classes.inputMargin}
                                        />
                                        <div
                                            style={{
                                            maxHeight: "calc(100vh - 215px)",
                                            overflowY: 'auto',
                                            padding: 10,
                                            }}
                                        >
                                            {/*if not loading render results*/}
                                            {renderResults()}
                                        </div>
                                    </div>
                                </Grid.Col>
                                <Grid.Col xs={12} sm={12}  md={6} lg={6} xl={6}>
                                    {/*right side*/}
                                    <div className={classes.rightColumnRightSide}>
                                        <Text className={classes.parametersTitle}>API
                                            Request Code</Text>
                                        <ProgrammingLanguageSelector
                                            setSelectedLanguage={setSelectedLanguage}/>

                                            <div
                                                style={{
                                                    maxHeight: "calc(100vh - 247px)",
                                                    overflowY: 'auto',
                                                    padding: 10,
                                                    marginTop: 10,
                                                }}
                                            >
                                                <Prism className={classes.scrollablePrism}
                                                       language={language}>
                                                    {getCode()}
                                                </Prism>
                                            </div>
                                        </div>
                                </Grid.Col>
                            </Grid>

                        </div>
                        </Card>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default App;
