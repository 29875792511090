import {useState} from "react";
import {
    ActionIcon,
    Badge,
    CloseButton,
    Group,
    TextInput,
    Text,
    useMantineTheme
} from "@mantine/core";
import {IconPlus} from "@tabler/icons-react";

interface QueryBuilderProps {
  onQueriesChange: (queries: string[]) => void;
}

const QueryBuilder: React.FC<QueryBuilderProps> = ({ onQueriesChange }) => {
    const theme = useMantineTheme();
  const [queries, setQueries] = useState<string[]>([]);
  const [inputValue, setInputValue] = useState('');

  const addQuery = (query: string) => {
    const trimmedQuery = query.trim();
    if (trimmedQuery && !queries.includes(trimmedQuery)) {
      const newQueries = [...queries, trimmedQuery];
      setQueries(newQueries);
      onQueriesChange(newQueries);
      setInputValue('');
    }
  };

  const removeQuery = (indexToRemove: number) => {
    const newQueries = queries.filter((_, index) => index !== indexToRemove);
    setQueries(newQueries);
    onQueriesChange(newQueries);
  };

  return (
    <div>
      <TextInput
          mb={10}
        placeholder="Enter a food query... ex. 'Broccoli'"
        value={inputValue}
        onChange={(event) => setInputValue(event.currentTarget.value)}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            event.preventDefault();
            addQuery(inputValue);
          }
        }}
        onBlur={() => addQuery(inputValue)}
        rightSection={
          <ActionIcon onClick={() => addQuery(inputValue)}>
            <IconPlus size="1rem" />
          </ActionIcon>
        }
      />

      {queries.length > 0 && (
          <div style={{marginTop: '1rem'}}>
              <Text size="sm" weight={500} mb={8}>
            Active Queries ({queries.length}):
          </Text>
              <Group mb={20} spacing="xs">
                  {queries.map((query, index) => (
                      <Badge
                          key={index}
                          size="lg"
                          variant="outline"

                          sx={{
        color: theme.colors.orange[0],
        borderColor: theme.colors.orange[0]
    }}
                          rightSection={
                              <CloseButton
                                  size="xs"
                                  onClick={() => removeQuery(index)}
                                  aria-label="Remove query"
                              />
                          }
                      >
                          {query}
                      </Badge>
                  ))}
              </Group>
          </div>
      )}
    </div>
  );
};


export default QueryBuilder;

