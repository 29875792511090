import React, {FC, ReactNode} from 'react';
// general components
import {NavBar} from "./header/header";

const PageJustHeader: FC<{ children: ReactNode }> = ({ children }) => (
    <div>
        <NavBar/>
        {children}
    </div>
);

export default PageJustHeader;
