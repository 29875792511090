import { createStyles, Paper, Text } from '@mantine/core';
import TwoColumn from "../TwoColumns";
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import {rem} from "@mantine/core";

const useStyles = createStyles((theme) => ({
    pageTitle: {
        textAlign: 'left',
        marginTop: 80,
        marginBottom: 40,
        fontSize: '2.5rem',
        marginLeft: theme.spacing.md,
        color: theme.colors.gray[0],
        '@media (min-width: 600px)': {
            marginTop: 100,
            marginBottom: 60,
            fontSize: '3.5rem',
        },

        '@media (min-width: 1280px)': {
            marginTop: 150,
            marginBottom: 80,
            fontSize: '4.5625rem',
        },
    },

    header: {
        textAlign: 'left',
        fontSize: theme.fontSizes.lg,
        color: theme.colors.gray[3],
        fontWeight: 400,
        '@media (min-width: 600px)': {
            fontSize: theme.fontSizes.xl
        },

        '@media (min-width: 1280px)': {
            fontSize: theme.fontSizes.xxl,
        },
    },

    pageLine: {
        width: '97%',
        height: '2px',
        backgroundColor: theme.colors.gray[7],
        marginBottom: theme.spacing.md,
        marginLeft: 'auto', // Add this line
        marginRight: 'auto', // Add this line
    },

    paragraphText: {
        fontSize: '18px',
        fontWeight: 200,
        color: theme.colors.gray[0],
    },

    rightDiv: {
        marginLeft: '25px',
        '@media (min-width: 1280px)': {
            marginLeft: 0,
        },
    },

    leftDiv: {
        marginBottom: '40px',
    },

    separatingDiv: {
        height: '20px',
        '@media (min-width: 1280px)': {
            height: '30px',
        },
    },
    largeSeparatingDiv: {
        height: '60px',
        '@media (min-width: 1280px)': {
            height: '80px',
        },
    },

    paragraphBoldText: {
        fontWeight: 'bold',
    },

    table: {
        width: '100%',
        borderCollapse: 'collapse',
    },
    cell: {
        padding: theme.spacing.md,
        textAlign: 'left',
        borderBottom: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.gray[0] : theme.colors.gray[0]}`,
    },
    tableHeader: {
        borderBottomWidth: '2px',  // make the header line bolder
        fontWeight: 'bold',
    },
    lastRow: {
        borderBottom: 'none',  // remove the bottom border from the last row
    }
}));

export function PrivacyPolicy() {
    const { classes } = useStyles();
    return (
        <div>
            <Text size="xxl" className={classes.pageTitle}>Privacy Policy</Text>
            <div className={classes.pageLine}></div>
            {/* info*/}
            <TwoColumn
                left={
                    <div className={classes.leftDiv}>
                        <Text style={{fontWeight: 'bold'}} className={classes.paragraphText}>Updated</Text>
                        <Text className={classes.paragraphText}>August 1, 2023</Text>
                    </div>
                }
                right={
                    <div className={classes.rightDiv}>
                        {/*Welcome*/}
                        <Text className={classes.paragraphText}>We at NutriCloud, LLC (together with our affiliates, “NutriCloud”, “we”, “our” or “us”) respect your privacy and are strongly committed to keeping secure any information we obtain from you or about you. This Privacy Policy describes our practices with respect to Personal Information we collect from or about you when you use our website, applications, and services (collectively, “Services”). This Privacy Policy does not apply to content that we process on behalf of customers of our business offerings, such as our API. Our use of that data is governed by our customer agreements covering access to and use of those offerings.</Text>
                        <div className={classes.separatingDiv}></div>
                        <div className={classes.largeSeparatingDiv}></div>

                        {/*personal information*/}
                        <Text className={classes.header}>1. Personal information we collect</Text>
                        <p className={classes.paragraphText}>We collect personal information relating to you (“Personal Information”) as follows:</p>
                        <p className={classes.paragraphText}><span className={classes.paragraphBoldText}>Personal Information You Provide:</span> We collect Personal Information if you create an account to use our Services or communicate with us as follows:</p>
                        <ul>
                            <li className={classes.paragraphText}>Account Information: When you create an account with us, we will collect information associated with your account, including your name, contact information, account credentials, payment card information, and transaction history, (collectively, “Account Information”).
                            </li>
                            <li className={classes.paragraphText}>User Content: When you use our Services, we collect Personal Information that is included in the input, file uploads, or feedback that you provide to our Services (“Content”).
                            </li>
                            <li className={classes.paragraphText}>Communication Information: If you communicate with us, we collect your name, contact information, and the contents of any messages you send (“Communication Information”).
                            </li>
                            <li className={classes.paragraphText}>Social Media Information: We have pages on social media sites like Instagram, Facebook, Medium, Twitter, YouTube and LinkedIn. When you interact with our social media pages, we will collect Personal Information that you elect to provide to us, such as your contact details (collectively, “Social Information”). In addition, the companies that host our social media pages may provide us with aggregate information and analytics about our social media activity.
                            </li>
                        </ul>
                        <p className={classes.paragraphText}><span className={classes.paragraphBoldText}>Personal Information We Receive Automatically From Your Use of the Services:</span> When you visit, use, or interact with the Services, we receive the following information about your visit, use, or interactions (“Technical Information”):</p>
                        <ul>
                            <li className={classes.paragraphText}>Log Data: Information that your browser automatically sends when you use our Services. Log data includes your Internet Protocol address, browser type and settings, the date and time of your request, and how you interact with our website.
                            </li>
                            <li className={classes.paragraphText}>Usage Data: We may automatically collect information about your use of the Services, such as the types of content that you view or engage with, the features you use and the actions you take, as well as your time zone, country, the dates and times of access, user agent and version, type of computer or mobile device, and your computer connection.
                            </li>
                            <li className={classes.paragraphText}>Device Information: Includes name of the device, operating system, device identifiers,  and browser you are using. Information collected may depend on the type of device you use and its settings.
                            </li>
                            <li className={classes.paragraphText}>Cookies: We use cookies to operate and administer our Services, and improve your experience. A “cookie” is a piece of information sent to your browser by a website you visit. You can set your browser to accept all cookies, to reject all cookies, or to notify you whenever a cookie is offered so that you can decide each time whether to accept it. However, refusing a cookie may in some cases preclude you from using, or negatively affect the display or function of, a website or certain areas or features of a website. For more details on cookies, please visit All About Cookies.
                            </li>
                            <li className={classes.paragraphText}>Analytics: We may use a variety of online analytics products that use cookies to help us analyze how users use our Services and enhance your experience when you use the Services.</li>
                        </ul>
                        <div className={classes.separatingDiv}></div>
                        <div className={classes.largeSeparatingDiv}></div>

                        {/*How we use personal info*/}
                        <Text className={classes.header}>2. How we use personal information</Text>
                        <p className={classes.paragraphText}>We may use Personal Information for the following purposes:</p>
                        <ul>
                            <li className={classes.paragraphText}>To provide, administer, maintain and/or analyze the Services;</li>
                            <li className={classes.paragraphText}>To improve our Services and conduct research;</li>
                            <li className={classes.paragraphText}>To communicate with you;</li>
                            <li className={classes.paragraphText}>To develop new programs and services;</li>
                            <li className={classes.paragraphText}>To prevent fraud, criminal activity, or misuses of our Services, and to protect the security of our IT systems, architecture, and networks;</li>
                            <li className={classes.paragraphText}>To carry out business transfers; and</li>
                            <li className={classes.paragraphText}>To comply with legal obligations and legal process and to protect our rights, privacy, safety, or property, and/or that of our affiliates, you, or other third parties.</li>
                        </ul>
                        <p className={classes.paragraphText}><span className={classes.paragraphBoldText}>Aggregated or De-Identified Information.</span> We may aggregate or de-identify Personal Information so that it may no longer be used to identify you and use such information to analyze the effectiveness of our Services, to improve and add features to our Services, to conduct research and for other similar purposes. In addition, from time to time, we may analyze the general behavior and characteristics of users of our Services and share aggregated information like general user statistics with third parties, publish such aggregated information or make such aggregated information generally available. We may collect aggregated information through the Services, through cookies, and through other means described in this Privacy Policy. We will maintain and use de-identified information in anonymous or de-identified form and we will not attempt to reidentify the information, unless required by law.</p>
                        <p className={classes.paragraphText}>As noted above, we may use Content you provide us to improve our Services, for example to train the models that power ChatGPT. See here for instructions on how you can opt out of our use of your Content to train our models.</p>
                        <div className={classes.separatingDiv}></div>
                        <div className={classes.largeSeparatingDiv}></div>

                        {/*discolsure of personal info*/}
                        <Text className={classes.header}>3. Disclosure of personal information</Text>
                        <p className={classes.paragraphText}>In certain circumstances we may provide your Personal Information to third parties without further notice to you, unless required by the law:</p>
                        <ul>
                            <li className={classes.paragraphText}>Vendors and Service Providers: To assist us in meeting business operations needs and to perform certain services and functions, we may provide Personal Information to vendors and service providers, including providers of hosting services, cloud services, and other information technology services providers, email communication software, and web analytics services, among others. Pursuant to our instructions, these parties will access, process, or store Personal Information only in the course of performing their duties to us.</li>
                            <li className={classes.paragraphText}>Business Transfers: If we are involved in strategic transactions, reorganization, bankruptcy, receivership, or transition of service to another provider (collectively, a “Transaction”), your Personal Information and other information may be disclosed in the diligence process with counterparties and others assisting with the Transaction and transferred to a successor or affiliate as part of that Transaction along with other assets.</li>
                            <li className={classes.paragraphText}>Legal Requirements: We may share your Personal Information, including information about your interaction with our Services, with government authorities, industry peers, or other third parties (i) if required to do so by law or in the good faith belief that such action is necessary to comply with a legal obligation, (ii) to protect and defend our rights or property, (iii) if we determine, in our sole discretion, that there is a violation of our terms, policies, or the law; (iv) to detect or prevent fraud or other illegal activity; (v) to protect the safety, security, and integrity of our products, employees, or users, or the public, or (vi) to protect against legal liability.</li>
                            <li className={classes.paragraphText}>Affiliates: We may disclose Personal Information to our affiliates, meaning an entity that controls, is controlled by, or is under common control with NutriCloud. Our affiliates may use the Personal Information we share in a manner consistent with this Privacy Policy.</li>
                        </ul>
                        <div className={classes.separatingDiv}></div>
                        <div className={classes.largeSeparatingDiv}></div>

                        {/*Your rights*/}
                        <Text className={classes.header}>4. Your rights</Text>
                        <p className={classes.paragraphText}>Depending on location, individuals in the EEA, the UK, and across the globe may have certain statutory rights in relation to their Personal Information. For example, you may have the right to:</p>
                        <ul>
                            <li className={classes.paragraphText}>Access your Personal Information and information relating to how it is processed.</li>
                            <li className={classes.paragraphText}>Delete your Personal Information from our records.</li>
                            <li className={classes.paragraphText}>Rectify or update your Personal Information.</li>
                            <li className={classes.paragraphText}>Transfer your Personal Information to a third party (right to data portability).</li>
                            <li className={classes.paragraphText}>Restrict how we process your Personal Information.</li>
                            <li className={classes.paragraphText}>Withdraw your consent—where we rely on consent as the legal basis for processing at any time.</li>
                            <li className={classes.paragraphText}>Object to how we process your Personal Information.</li>
                            <li className={classes.paragraphText}>Lodge a complaint with your local data protection authority.</li>
                        </ul>
                        <p className={classes.paragraphText}>You can exercise some of these rights through your NutriCloud account. If you are unable to exercise your rights through your account, please send your request to dsar@NutriCloud.com.</p>
                        <p className={classes.paragraphText}>A note about accuracy: Services like ChatGPT generate responses by reading a user’s request and, in response, predicting the words most likely to appear next. In some cases, the words most likely to appear next may not be the most factually accurate. For this reason, you should not rely on the factual accuracy of output from our models. If you notice that ChatGPT output contains factually inaccurate information about you and you would like us to correct the inaccuracy, you may submit a correction request to dsar@NutriCloud.com. Given the technical complexity of how our models work, we may not be able to correct the inaccuracy in every instance. In that case, you may request that we remove your Personal Information from ChatGPT’s output by filling out this form.</p>
                        <p className={classes.paragraphText}>For information on how to exercise your rights with respect to data we have collected from the internet to train our models, please see this help center article.</p>
                        <div className={classes.separatingDiv}></div>
                        <div className={classes.largeSeparatingDiv}></div>

                        {/*additiona u.s. state disclosures*/}
                        <Text className={classes.header}>5. Additional U.S. State Disclosures</Text>
                        <p className={classes.paragraphText}>The following table provides additional information about the categories of Personal Information we collect and how we disclose that information. You can read more about the Personal Information we collect in “Personal information we collect” above, how we use Personal Information in “How we use personal information” above, and how we retain Personal Information in “Security and Retention” below.</p>

                        <table className={classes.table}>
                            <thead>
                            <tr>
                                <th className={`${classes.cell} ${classes.paragraphText}`}><span className={classes.paragraphBoldText}>Category of Personal Information</span></th>
                                <th className={`${classes.cell} ${classes.paragraphText}`}><span className={classes.paragraphBoldText}>Disclosure of Personal Information</span></th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td className={`${classes.cell} ${classes.paragraphText}`}>Identifiers, such as your name, contact details, IP address, and other device identifiers</td>
                                <td className={`${classes.cell} ${classes.paragraphText}`}>We disclose this information to our affiliates, vendors and service providers, law enforcement, and parties involved in Transactions.</td>
                            </tr>
                            <tr className={classes.lastRow}>
                                <td className={`${classes.cell} ${classes.paragraphText}`}>Commercial Information, such as your transaction history	</td>
                                <td className={`${classes.cell} ${classes.paragraphText}`}>We disclose this information to our affiliates, vendors and service providers, law enforcement, and parties involved in Transactions.</td>
                            </tr>
                            <tr className={classes.lastRow}>
                                <td className={`${classes.cell} ${classes.paragraphText}`}>Network Activity Information, such as Content and how you interact with our Services</td>
                                <td className={`${classes.cell} ${classes.paragraphText}`}>We disclose this information to our affiliates, vendors and service providers, law enforcement, and parties involved in Transactions.</td>
                            </tr>
                            <tr className={classes.lastRow}>
                                <td className={`${classes.cell} ${classes.paragraphText}`}>Geolocation Data</td>
                                <td className={`${classes.cell} ${classes.paragraphText}`}>We disclose this information to our affiliates, vendors and service providers, law enforcement, and parties involved in Transactions.</td>
                            </tr>
                            <tr className={classes.lastRow}>
                                <td className={`${classes.cell} ${classes.paragraphText}`}>Your account login credentials and payment card information (Sensitive Personal Information)	</td>
                                <td className={`${classes.cell} ${classes.paragraphText}`}>We disclose this information to our affiliates, vendors and service providers, law enforcement, and parties involved in Transactions.
                                </td>
                            </tr>

                            </tbody>
                        </table>

                        <p className={classes.paragraphText}>To the extent provided for by local law and subject to applicable exceptions, individuals may have the following privacy rights in relation to their Personal Information:</p>
                        <ul>
                            <li className={classes.paragraphText}>The right to know information about our processing of your Personal Information, including the specific pieces of Personal Information that we have collected from you;</li>
                            <li className={classes.paragraphText}>The right to request deletion of your Personal Information;</li>
                            <li className={classes.paragraphText}>The right to correct your Personal Information; and</li>
                            <li className={classes.paragraphText}>The right to be free from discrimination relating to the exercise of any of your privacy rights.</li>
                        </ul>
                        <p className={classes.paragraphText}>We don’t “sell” Personal Information or “share” Personal Information for cross-contextual behavioral advertising (as those terms are defined under applicable local law). We also don’t process sensitive Personal Information for the purposes of inferring characteristics about a consumer.</p>
                        <p className={classes.paragraphText}><span className={classes.paragraphBoldText}>Exercising Your Rights.</span> To the extent applicable under local law, you can exercise privacy rights described in this section by submitting a request to dsar@NutriCloud.com.</p>
                        <p className={classes.paragraphText}><span className={classes.paragraphBoldText}>Verification.</span> In order to protect your Personal Information from unauthorized access, change, or deletion, we may require you to verify your credentials before you can submit a request to know, correct, or delete Personal Information. If you do not have an account with us, or if we suspect fraudulent or malicious activity, we may ask you to provide additional Personal Information and proof of residency for verification. If we cannot verify your identity, we will not be able to honor your request.</p>
                        <p className={classes.paragraphText}><span className={classes.paragraphBoldText}>Authorized Agents.</span> You may also submit a rights request through an authorized agent. If you do so, the agent must present signed written permission to act on your behalf and you may also be required to independently verify your identity and submit proof of your residency with us. Authorized agent requests can be submitted to dsar@NutriCloud.com.</p>
                        <p className={classes.paragraphText}><span className={classes.paragraphBoldText}>Appeals.</span> Depending on where you live, you may have the right to appeal a decision we make relating to requests to exercise your rights under applicable local law. To appeal a decision, please send your request to dsar@NutriCloud.com.</p>
                        <div className={classes.separatingDiv}></div>
                        <div className={classes.largeSeparatingDiv}></div>

                        {/*children*/}
                        <Text className={classes.header}>6. Children</Text>
                        <p className={classes.paragraphText}>Our Service is not directed to children under the age of 13. NutriCloud does not knowingly collect Personal Information from children under the age of 13. If you have reason to believe that a child under the age of 13 has provided Personal Information to NutriCloud through the Service, please email us at legal@NutriCloud.com. We will investigate any notification and if appropriate, delete the Personal Information from our systems. If you are 13 or older, but under 18, you must have consent from your parent or guardian to use our Services.</p>
                        <div className={classes.separatingDiv}></div>
                        <div className={classes.largeSeparatingDiv}></div>

                        {/*links to other websites*/}
                        <Text className={classes.header}>7. Links to other websites</Text>
                        <p className={classes.paragraphText}>The Service may contain links to other websites not operated or controlled by NutriCloud, including social media services (“Third Party Sites”). The information that you share with Third Party Sites will be governed by the specific privacy policies and terms of service of the Third Party Sites and not by this Privacy Policy. By providing these links we do not imply that we endorse or have reviewed these sites. Please contact the Third Party Sites directly for information on their privacy practices and policies.</p>
                        <div className={classes.separatingDiv}></div>
                        <div className={classes.largeSeparatingDiv}></div>

                        {/*security retention*/}
                        <Text className={classes.header}>8. Security and Retention</Text>
                        <p className={classes.paragraphText}>We implement commercially reasonable technical, administrative, and organizational measures to protect Personal Information both online and offline from loss, misuse, and unauthorized access, disclosure, alteration, or destruction. However, no Internet or email transmission is ever fully secure or error free. In particular, email sent to or from us may not be secure. Therefore, you should take special care in deciding what information you send to us via the Service or email. In addition, we are not responsible for circumvention of any privacy settings or security measures contained on the Service, or third-party websites.</p>
                        <p className={classes.paragraphText}>We’ll retain your Personal Information for only as long as we need in order to provide our Service to you, or for other legitimate business purposes such as resolving disputes, safety and security reasons, or complying with our legal obligations. How long we retain Personal Information will depend on a number of factors, such as the amount, nature, and sensitivity of the information, the potential risk of harm from unauthorized use or disclosure, our purpose for processing the information, and any legal requirements.</p>
                        <div className={classes.separatingDiv}></div>
                        <div className={classes.largeSeparatingDiv}></div>

                        {/*international users*/}
                        <Text className={classes.header}>9. International users</Text>
                        <p className={classes.paragraphText}>By using our Service, you understand and acknowledge that your Personal Information will be processed and stored in our facilities and servers in the United States and may be disclosed to our service providers and affiliates in other jurisdictions.</p>
                        <p className={classes.paragraphText}>For EEA, UK or Swiss users:</p>
                        <p className={classes.paragraphText}><span className={classes.paragraphBoldText}>Legal Basis for Processing.</span> Our legal bases for processing your Personal Information include:</p>
                        <ul>
                            <li className={classes.paragraphText}>Performance of a contract with you when we provide and maintain our Services. When we process Account Information, Content, and Technical Information solely to provide our Services to you, this information is necessary to be able to provide our Services. If you do not provide this information, we may not be able to provide our Services to you.</li>
                            <li className={classes.paragraphText}>Our legitimate interests in protecting our Services from abuse, fraud, or security risks, or in developing, improving, or promoting our Services, including when we train our models. This may include the processing of Account Information, Content, Social Information, and Technical Information. See here for instructions on how you can opt out of our use of your information to train our models.</li>
                            <li className={classes.paragraphText}>Your consent when we ask for your consent to process your Personal Information for a specific purpose that we communicate to you. You have the right to withdraw your consent at any time.</li>
                            <li className={classes.paragraphText}>Compliance with our legal obligations when we use your Personal Information to comply with applicable law or when we protect our or our affiliates’, users’, or third parties’ rights, safety, and property.</li>
                        </ul>
                        <p className={classes.paragraphText}><span className={classes.paragraphBoldText}>EEA and UK Representative.</span> We’ve appointed the following representatives in the EEA and UK for data protection matters. You can contact our representatives at privacy@NutriCloud.com. Alternatively:</p>
                        <ul>
                            <li className={classes.paragraphText}>For users in the EEA: NutriCloud Ireland Limited, at 1st Floor, The Liffey Trust Centre, 117-126 Sheriff Street Upper, Dublin 1, D01 YC43, Ireland.</li>
                            <li className={classes.paragraphText}>For users in the UK: NutriCloud UK Ltd,  Suite 1, 3rd Floor, 11-12 St. James’s Square, London SW1Y 4LB, United Kingdom.</li>
                        </ul>
                        <p className={classes.paragraphText}><span className={classes.paragraphBoldText}>Data Transfers.</span> Where required, we will use appropriate safeguards for transferring Personal Information outside of the EEA, Switzerland, and the UK. We will only transfer Personal Information pursuant to a legally valid transfer mechanism. For more information on the appropriate safeguards in place and to obtain a copy of these  safeguards, please contact us at the details below.</p>
                        <p className={classes.paragraphText}><span className={classes.paragraphBoldText}>Data Controller.</span> For the purposes of the UK and EU General Data Protection Regulation 2018, our data controller is NutriCloud OpCo, LLC at 3180 18th Street, San Francisco, CA, United States.</p>
                        <p className={classes.paragraphText}><span className={classes.paragraphBoldText}>Data Protection Officer.</span> You can contact our data protection officer at privacy@NutriCloud.com in matters related to Personal Information processing.</p>
                        <div className={classes.separatingDiv}></div>
                        <div className={classes.largeSeparatingDiv}></div>

                        {/*changes to policy*/}
                        <Text className={classes.header}>10. Changes to the privacy policy</Text>
                        <p className={classes.paragraphText}>We may update this Privacy Policy from time to time. When we do, we will post an updated version on this page, unless another type of notice is required by applicable law.</p>
                        <div className={classes.separatingDiv}></div>
                        <div className={classes.largeSeparatingDiv}></div>

                        {/*how to contact us*/}
                        <Text className={classes.header}>11. How to contact us</Text>
                        <p className={classes.paragraphText}>Please contact support if you have any questions or concerns not already addressed in this Privacy Policy.</p>
                        <div className={classes.separatingDiv}></div>
                        <div className={classes.largeSeparatingDiv}></div>
                    </div>
                }
            />
        </div>
    );
}
