import {
    createStyles,
    Title,
    Text,
    Card,
    SimpleGrid,
    Container,
    rem, Box, Center, Group, Col, Grid, Flex, Table,
} from '@mantine/core';
import { IconGauge, IconUser, IconCookie } from '@tabler/icons-react';
import React, {FC} from "react";
import PricingTable from "./pricingTable";
import {
    appApiPricingData, authApiPricingData,
    chatApiPricingData,
    classifyApiPricingData,
    generateApiPricingData,
    imageApiPricingData,
    infoApiPricingData,
    searchApiPricingData,
    suggestApiPricingData,
    userApiPricingData,
    verifyApiPricingData
} from "./apiPricingData";
import AnimatedLink from "../LandingPage/components/animatedLink";


const useStyles = createStyles((theme) => ({
      gradientBorderBox: {
    display: 'inline-flex',
        backgroundImage: theme.fn.gradient({ from: 'pink', to: 'orange' }),
    borderRadius: '5px',
    padding: '2px', // Controls the thickness of the gradient border
    alignItems: 'center',
    justifyContent: 'center',
      width: '250px',
  },
  innerBox: {
    backgroundColor: theme.colors.dark[7], // Should match the page's background
    borderRadius: '5px',
      width: '300px',
    paddingLeft: "30px",
    paddingRight: "30px",
    paddingTop: "5x",
    paddingBottom: "0px",
      color: theme.colors.gray[1]
  },

    pageLine: {
        width: '100%',
        height: '1px',
        backgroundColor: theme.colors.gray[6],
        marginBottom: theme.spacing.md,
        marginLeft: 'auto', // Add this line
        marginRight: 'auto', // Add this line
    },
    sectionHolder: {
          // padding: 20,
          // paddingLeft: 50,
    },
    gradientText: {
        color: theme.colors.gray[1],
        fontSize: '40px',
        fontWeight: 500,
        padding: 0,
        margin: 0,
    },

    descriptionText: {
        color: theme.colors.gray[1],
        fontSize: '18px',
        fontWeight: 400,
        margin: 0,
        [`@media (max-width: 750px)`]: {
            fontWeight: 300,
            fontSize: '16px',
    },
    },
}));


const PricingTables: FC = () => {
    const { classes, theme } = useStyles();
    const data = [
        {
            title: 'Classify',
            description: 'Our classify endpoints provide powerful and flexible solutions for analyzing and categorizing food items and recipes. Whether you need to identify allergens, determine dietary suitability, or classify recipes by cuisine, meal type, or course, our APIs have you covered.',
            tableData: classifyApiPricingData,
            docsLinkText: 'Explore the classify API',
            docsLinkPath: '/docs/classify',
        },
        {
            title: 'Info',
            description: 'Get insights into the required equipment and techniques to successfully execute a recipe. And discover the essential ingredients that form the backbone of your favorite dishes.' +
            ' Our food info endpoints offer a range of useful details. Explore the seasonality of ingredients to know when they are at their peak freshness and flavor.',
            tableData: infoApiPricingData,
            docsLinkText: 'Explore the info API',
            docsLinkPath: '/docs/info',
        },
        {
            title: 'Chat',
            description: 'Our chat endpoints empower your application with a state-of-the-art language model that has full access to our entire suite of food and recipe APIs. This enables users to engage in rich, context-aware conversations about all aspects of the culinary world. Leveraging the advanced natural language capabilities of leading providers like OpenAI and Anthropic, our chat endpoints provide intelligent and informed responses to user queries, seamlessly drawing upon the wealth of information available through our APIs.',
            tableData: chatApiPricingData,
            docsLinkText: 'Explore the chat API',
            docsLinkPath: '/docs/chat',
        },
        {
            title: 'Suggest',
            description: 'Unlock the full potential of your food and recipe applications with our suggest endpoints. These powerful APIs provide intelligent recommendations and suggestions to enhance your users\' culinary experiences. From suggesting ingredient substitutions and recommending recipes based on available items, to identifying key components in a dish and proposing complementary recipes, our suggest endpoints offer a range of features to inspire and assist users in their cooking endeavors. Integrate our suggest APIs to add a new level of interactivity and personalization to your application.',
            tableData: suggestApiPricingData,
            docsLinkText: 'Explore the suggest API',
            docsLinkPath: '/docs/suggest',
        },
        {
            title: 'Image',
            description: 'Our image APIs offer a range of powerful features to enhance your food and recipe applications. With our image recognition endpoints, you can easily identify foods and dishes from photos, unlocking detailed nutritional information and enabling users to make informed choices about what they eat. Beyond recognition, our image APIs also allow you to generate realistic or stylized images of food items and recipes, providing engaging visuals to accompany your content. For recipe applications, our endpoints can create entire recipes based on a single image.',
            tableData: imageApiPricingData,
            docsLinkText: 'Explore the image API',
            docsLinkPath: '/docs/image',
        },
        {
            title: 'Search',
            description: 'Empower your users to quickly find the food items and recipes they\'re looking for with our search endpoints. Our high-performance search APIs provide lightning-fast results for both general queries and specific criteria like ingredients, cuisines, and dietary restrictions. In addition, our intelligent autocomplete endpoints offer real-time suggestions as users type, helping them find what they need with minimal effort.',
            tableData: searchApiPricingData,
            docsLinkText: 'Explore the search API',
            docsLinkPath: '/docs/search/food',
        },
        {
            title: 'Generate',
            description: 'Revolutionize your food and recipe content creation with our powerful generation endpoints. Our state-of-the-art APIs can create full, detailed recipes from a variety of inputs, including ingredient lists, cuisine styles, dietary preferences, and more. Each generated recipe includes enticing images, helpful preparation tips, step-by-step instructions, providing everything your users need to cook with confidence.',
            tableData: generateApiPricingData,
            docsLinkText: 'Explore the generate API',
            docsLinkPath: '/docs/generate',
        },
        {
            title: 'User',
            description: 'Enhance user experience with our Users endpoint, allowing individual users to like, dislike, and interact with recipes to capture personal preferences. This data fuels tailored recipe recommendations, ensuring a more personalized and engaging experience for every user.',
            tableData: userApiPricingData,
            docsLinkText: 'Explore the user API',
            docsLinkPath: '/docs/user',
        },
        {
            title: 'Verify',
            description: 'Ensure the authenticity of your users with our Verify endpoints. Developers can easily verify phone numbers and emails, enhancing security and user trust while streamlining the account setup process.',
            tableData: verifyApiPricingData,
            docsLinkText: 'Explore the verification API',
            docsLinkPath: '/docs/verify',
        },
        {
            title: 'Apps',
            description: "Manage your application's structure with our App endpoints, enabling developers to create custom rate limits, permissions, usage tiers, and subscriptions for users. Tailor the experience to fit your needs while maintaining control over resource allocation and access.",
            tableData: appApiPricingData,
            docsLinkText: 'Explore the app API',
            docsLinkPath: '/docs/app',
        },
        {
            title: 'Auth',
            description: 'Simplify user authentication with our Auth endpoints. Developers can easily implement user registration and login for their apps, ensuring secure and seamless access for all users.',
            tableData: authApiPricingData,
            docsLinkText: 'Explore the auth API',
            docsLinkPath: '/docs/auth',
        },

    ]

    return (
        <div style={{maxWidth: 1400, borderLeft: "3px solid #994fb2"}}>
            {/*classify*/}
            {/*<div style={{marginTop: 40,}} className={classes.pageLine}></div>*/}
            {data.map((item, index) => (
                <div>
                    <div key={index} className={classes.sectionHolder} style={{paddingLeft: 20, paddingRight:20}}>
                        {/*title*/}
                        <Box className={classes.gradientBorderBox}>
                            <Box className={classes.innerBox}>
                                <Center>
                                    <Text
                                        className={classes.gradientText}>{item.title}</Text>
                                </Center>
                            </Box>
                        </Box>
                        {/*contnet*/}
                        <Grid>
                            <Col span={12} md={6} mt={20}>
                                <div style={{padding: 10,}}>
                                    <Text mb={30} className={classes.descriptionText}>
                                        {item.description}
                                    </Text>
                                    <AnimatedLink text={item.docsLinkText}
                                                  path={item.docsLinkPath}/>
                                </div>
                            </Col>
                            <Col span={12} md={6}>
                                <PricingTable data={item.tableData}/>
                            </Col>
                        </Grid>
                    </div>
                    <div style={{marginTop: 40, marginLeft: 20, marginRight: 20}} >
                        <div className={classes.pageLine}></div>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default PricingTables;
