import React from 'react';
import {useMantineTheme} from "@mantine/core";

interface ProgressRingProps {
  percent: number; // The percentage of the progress
}

const ProgressRing: React.FC<ProgressRingProps> = ({ percent }) => {
        const theme = useMantineTheme();
  const radius = 95; // Increase the radius of the ring for a bigger size
  const stroke = 17; // Increase the thickness of the ring for better visibility
  const normalizedRadius = radius - stroke * 2;
  const circumference = normalizedRadius * 2 * Math.PI;

  // Adjusted to fill clockwise
  const strokeDashoffset = (1 - percent / 100) * circumference;

  return (
    <svg
      height={radius * 2}
      width={radius * 2}
      viewBox={`0 0 ${radius * 2} ${radius * 2}`}
    >
      {/* Background circle */}
      <circle
        stroke={theme.colors.gray[7]}
        fill="transparent"
        strokeWidth={stroke}
        strokeDasharray={circumference + ' ' + circumference}
        r={normalizedRadius}
        cx={radius}
        cy={radius}
      />
      {/* Foreground circle for progress */}
      <circle
        stroke="url(#gradient)"
        fill="transparent"
        strokeWidth={stroke}
        strokeDasharray={circumference + ' ' + circumference}
        style={{ strokeDashoffset, transform: `rotate(-90deg)`, transformOrigin: '50% 50%' }}
        r={normalizedRadius}
        cx={radius}
        cy={radius}
      />
      {/* Gradient definition */}
      <defs>
          <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
              <stop offset="0%" style={{stopColor: 'purple', stopOpacity: 1}}/>
              <stop offset="100%" style={{stopColor: 'orange', stopOpacity: 1}}/>
          </linearGradient>
      </defs>
        {/* Percentage text */}
        <text x="50%" y="50%" dy=".3em" textAnchor="middle" fill={theme.colors.gray[5]}>{`${percent}%`}</text>
    </svg>
  );
};

export default ProgressRing;
