import React, {ReactNode, useEffect, useRef, useState} from 'react';
import {Col, createStyles, Grid} from '@mantine/core';

// Define the types for the component props
interface ScrollRevealGradientLineProps {
  colorStart: string;
  colorMiddle: string;
  colorEnd: string;
    leftContent?: ReactNode; // Optional prop to pass custom content

}

const ScrollRevealGradientLine: React.FC<ScrollRevealGradientLineProps> = ({ colorStart, colorMiddle, colorEnd, leftContent }) => {
  const lineRef = useRef<HTMLDivElement>(null);
  const [isVisible, setIsVisible] = useState(false);

useEffect(() => {
  const onScroll = () => {
    if (!lineRef.current) return;
    const lineRect = lineRef.current.getBoundingClientRect();
    const triggerHeight = window.innerHeight * 0.8;

    const isPartiallyInView =
      (lineRect.bottom > 0 && lineRect.bottom <= triggerHeight) ||
      (lineRect.top >= 0 && lineRect.top < triggerHeight);

    // Check if the element spans across the viewport
    const spansAcrossViewport = lineRect.top < 0 && lineRect.bottom > triggerHeight;

    setIsVisible(isPartiallyInView || spansAcrossViewport);
  };

  window.addEventListener('scroll', onScroll);
  onScroll();  // Call onScroll to check initial state

  return () => window.removeEventListener('scroll', onScroll);
}, []);


  const gradientLineStyles = {
    width: '3px',
    background: `linear-gradient(${colorStart}, ${colorMiddle}, ${colorEnd})`,
    transition: 'height 0.5s ease',
    height: isVisible ? '100%' : '0%',
    opacity: 1,
      zIndex: 100000,
  };

  const contentStyles = {
    opacity: isVisible ? 1 : 0, // Control visibility through opacity for fade-in/out effect
    transform: isVisible ? 'translateX(0px)' : 'translateX(-20px)', // Move in from the left
    transition: 'opacity 0.5s ease, transform 0.5s ease', // Smooth transition for both opacity and transform
    // marginLeft: '55px', // Space between the content and the line
    //   maxWidth: '900px'
  };
return (
  <div ref={lineRef}>
    <Grid gutter={0} grow>
      <Col span={1}>
        <div style={gradientLineStyles}></div>
      </Col>
      <Col span={11} ml={'-17%'}>
        {leftContent && <div style={contentStyles}>{leftContent}</div>}
      </Col>
    </Grid>
  </div>
);

};

export default ScrollRevealGradientLine;
