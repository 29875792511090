import React from 'react';
import {
    Paper,
    Text,
    Flex,
    Container,
    createStyles,
    Col,
    Grid,
    useMantineTheme, Accordion, Box, Center
} from '@mantine/core';
import { FaHome, FaUser, FaEnvelope, FaPhone, FaSearch, FaHeart, FaComment, FaBars } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { FaInfoCircle } from 'react-icons/fa';
import Demo from "../Components/Feedback";
import DocsPage from "../DocsPage";
import FeedbackBanner from "../Components/Feedback";
import {Prism} from "@mantine/prism";


const useStyles = createStyles((theme) => ({
    iconBox: {
        display: 'flex',
        justifyContent: 'flex-start', // Align items to the start (left side)
        alignItems: 'center',
        marginBottom: theme.spacing.md,
        '& svg': {
            transition: 'transform 0.3s ease',
        },
        '&:hover svg': {
            transform: 'scale(1.2)',
        },
    },
    contentBox: {
        marginLeft: theme.spacing.md, // Add some space between the icon and the text
    },

    // headers
    pageTitle: {
        textAlign: "left",
        color: theme.colors.gray[3],
        fontSize: theme.fontSizes.xxl,
        fontWeight:600,
        marginTop: "30px",
    },
    mainHeader: {
        fontWeight: 500,
        color: theme.colors.gray[3],
        fontSize: theme.fontSizes.xl,
        marginTop: "0px",
        marginBottom: "10px",
    },
    subHeader: {
        fontWeight: 500,
        color: theme.colors.gray[3],
        fontSize: theme.fontSizes.md,
        marginTop: "0px",
        marginBottom: "0px",
    },

        accordionSubHeader: {
        fontWeight: 500,

        color: theme.colors.gray[3],
        fontSize: theme.fontSizes.md,
        marginTop: "0px",
        marginBottom: "15px",
    },


    // bullet list
    link: {
        color: theme.colors.orange[0],
        textDecoration: 'none',
        '&:hover': {
            color: theme.colors.orange[9],  // Change this to the color you want on hover
        },
    },
    bulletTitle: {
        color: theme.colors.gray[0],
        fontWeight: 600,
    },
    bulletList: {
        listStyleType: 'disc',
    },
    bulletPoint: {
        fontSize: theme.fontSizes.sm,
        margin: '5px 0',
    },

    // text
    paragraphText: {
        fontSize: '16px',
        marginBottom: "10px",
    },

    // info boxes
    infoBox: {
        backgroundColor: theme.colors.gray[8],
        padding: theme.spacing.md,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        width: '100%',
        marginTop: '10px',
        marginBottom: '20px',
    },
    infoText: {
        marginLeft: theme.spacing.sm,
        fontSize: '16px',
    },
    infoIcon: {
        fontSize: '1em',
        flexShrink: 0,
    },
          gradientBorderBox: {
    display: 'inline-flex',
        backgroundImage: theme.fn.gradient({ from: 'pink', to: 'orange' }),
    borderRadius: '5px',
    padding: '2px', // Controls the thickness of the gradient border
    alignItems: 'center',
           // marginLeft: "50px",
    justifyContent: 'center',
      width: '230px',

      '@media (max-width: 800px)': {
        marginLeft: 0,
    },

  },
  innerBox: {
    backgroundColor: theme.colors.dark[7], // Should match the page's background
    borderRadius: '5px',
      width: '230px',
    paddingLeft: "30px",
    paddingRight: "30px",
    paddingTop: "5x",
    paddingBottom: "0px",
      color: theme.colors.gray[1]
  },
}));

const basicRequestExample = 'curl -X \'POST\' \\\n' +
    '  \'https://api.nutricloud.com/api/classify/food/\' \\\n' +
    '  -H \'accept: application/json\' \\\n' +
    '  -H \'Authorization: Bearer YOUR_API_KEY\' \\\n' +
    '  -H \'Content-Type: application/json\' \\\n' +
    '  -d \'{\n' +
    '  "query": "Grilled salmon with asparagus",\n' +
    '  "classification_types": ["allergy", "diet", "health_index"]\n' +
    '}\''

const basicResponseExample = '{\n' +
    '  "allergy": ["Fish"],\n' +
    '  "diet": ["Pescatarian", "Paleo", "Keto", "Low Carb"],\n' +
    '  "health_index": 85\n' +
    '}'

// create gradient boxes
function DocsClassification() {
    const { classes } = useStyles();
    const theme = useMantineTheme();

    return (
        <Container>
            <Text className={classes.pageTitle}>Classify</Text>
            <Text className={classes.subHeader} style={{color: theme.colors.gray[5]}}>Learn
                how to analyze and classify foods
                and recipes</Text>
            <div style={{marginBottom: 30}}></div>

            {/*Introduction*/}
            <Text className={classes.mainHeader}>Introduction</Text>

            <Text className={classes.paragraphText}>The Classification API allows you to
                extract detailed classifications from food and recipe queries. By
                inputting a search query, you can receive classifications across various
                fields such as diet preferences, allergies, flavor profiles, and more.
                This can be used to:</Text>
            <ul className={classes.bulletList}>
                <li className={classes.bulletPoint}>
                    Dietary restrictions and allergies
                </li>
                <li className={classes.bulletPoint}>
                    Texture and sensory properties
                </li>
                <li className={classes.bulletPoint}>
                    Flavor profiles, texture, and taste characteristics
                </li>
                <li className={classes.bulletPoint}>
                    Extract other valuable insights like seasonality and price point.
                </li>

            </ul>

            {/*quickstart*/}
            <Text className={classes.mainHeader}>Quickstart</Text>

            <Text className={classes.subHeader}>Classification Endpoints</Text>
            <div style={{marginBottom: 15}}></div>
            <Text className={classes.paragraphText}>NutriCloud provides two endpoints
                optimized for different use cases:</Text>

            <ul className={classes.bulletList}>
                <li className={classes.bulletPoint}>
                    <span style={{fontWeight: 700, color: theme.colors.gray[3]}}>Food Classification Endpoint:</span> Best
                    suited for singular food ingredients.
                </li>
                <li className={classes.bulletPoint}>
                    <span style={{fontWeight: 700, color: theme.colors.gray[3]}}>Recipe Classification Endpoint:</span> Optimized
                    for recipe inputs but can handle food ingredients as well.
                </li>
            </ul>

            {/*    classify a food*/}
            <Text className={classes.subHeader}>Classify a Food Query</Text>
            <Text className={classes.paragraphText}>The classification API takes a text
                query describing a food item or recipe and returns comprehensive
                classification data across multiple categories. Here's how to classify a
                food query using the Food Classification Endpoint:</Text>

            {/*<Text className={classes.subHeader}>Request example</Text>*/}
            <div style={{padding: 30}}>
                <Prism language={"bash"}>
                    {basicRequestExample}
                </Prism>
            </div>

            <Text className={classes.paragraphText}>The API will return a JSON response
                with the requested classifications:</Text>
            <div style={{padding: 30}}>
                <Prism language={"json"}>
                    {basicResponseExample}
                </Prism>
            </div>

            {/*    Classificaiton types*/}
            <Text className={classes.mainHeader}>Classification Types</Text>
            <Text className={classes.paragraphText}>The <span
                style={{color: theme.colors.orange[0]}}>classification_types</span> parameter
                allows you to specify which classifications you want to receive. Below
                are the available classification types:</Text>

            <ul className={classes.bulletList}>
                <li className={classes.bulletPoint}
                    style={{color: theme.colors.gray[0]}}>allergy
                </li>
                <li className={classes.bulletPoint}
                    style={{color: theme.colors.gray[0]}}>diet
                </li>
                <li className={classes.bulletPoint}
                    style={{color: theme.colors.gray[0]}}>branded
                </li>
                <li className={classes.bulletPoint}
                    style={{color: theme.colors.gray[0]}}>texture
                </li>
                <li className={classes.bulletPoint}
                    style={{color: theme.colors.gray[0]}}>flavor_profile
                </li>
                <li className={classes.bulletPoint}
                    style={{color: theme.colors.gray[0]}}>cuisine
                </li>
                <li className={classes.bulletPoint}
                    style={{color: theme.colors.gray[0]}}>seasonality
                </li>
                <li className={classes.bulletPoint}
                    style={{color: theme.colors.gray[0]}}>freshness_score
                </li>
                <li className={classes.bulletPoint}
                    style={{color: theme.colors.gray[0]}}>price_point
                </li>
                <li className={classes.bulletPoint}
                    style={{color: theme.colors.gray[0]}}>caloric_density
                </li>
                <li className={classes.bulletPoint}
                    style={{color: theme.colors.gray[0]}}>health_index
                </li>
                <li className={classes.bulletPoint}
                    style={{color: theme.colors.gray[0]}}>color_profile
                </li>
            </ul>

            {/*    understanding the classifications*/}
            <Text className={classes.mainHeader}>Understanding the
                Classifications</Text>
            <Accordion multiple defaultValue={['flavor_profile']}>


                {/*flavor_profile*/}
                <Accordion.Item value="flavor_profile">
                    <Accordion.Control>
                        <Box className={classes.gradientBorderBox}>
                            <Box className={classes.innerBox}>
                                <Center>
                                    <Text size="md">Flavor Profile</Text>
                                </Center>
                            </Box>
                        </Box>
                    </Accordion.Control>
                    <Accordion.Panel>
                        <Text className={classes.accordionSubHeader}>Provides a
                            numerical scale (0-10) for each flavor component</Text>
                        <Text className={classes.subHeader}>Flavor components:</Text>
                        <ul className={classes.bulletList}>
                            <li className={classes.bulletPoint}
                                style={{color: theme.colors.gray[0]}}>Sweet
                            </li>
                            <li className={classes.bulletPoint}
                                style={{color: theme.colors.gray[0]}}>Salty
                            </li>
                            <li className={classes.bulletPoint}
                                style={{color: theme.colors.gray[0]}}>Sour
                            </li>
                            <li className={classes.bulletPoint}
                                style={{color: theme.colors.gray[0]}}>Bitter
                            </li>
                            <li className={classes.bulletPoint}
                                style={{color: theme.colors.gray[0]}}>Umami
                            </li>
                            <li className={classes.bulletPoint}
                                style={{color: theme.colors.gray[0]}}>Spicy
                            </li>
                        </ul>
                        <Text className={classes.paragraphText}>A sophisticated rating
                            system that quantifies the intensity of fundamental taste
                            components on a scale from 0-10. This detailed breakdown
                            helps users understand the complete taste experience of a
                            food item, covering sweet, salty, sour, bitter, umami, and
                            spicy elements. This classification is valuable for recipe
                            development, food pairing, and helping users find foods that
                            match their taste preferences.</Text>
                        <Text className={classes.subHeader}>Flavor Scales:</Text>
                        <Accordion m={20} defaultValue={'sweet'}>
                            <Accordion.Item value="sweet">
                                <Accordion.Control>Sweet</Accordion.Control>
                                <Accordion.Panel>
                                    <ul>
                                        <li className={classes.bulletPoint}
                                            style={{color: theme.colors.gray[0]}}>0:
                                            No sweetness (e.g., plain water)
                                        </li>
                                        <li className={classes.bulletPoint}
                                            style={{color: theme.colors.gray[0]}}>1-3:
                                            Very low sweetness (e.g., unsweetened
                                            bread)
                                        </li>
                                        <li className={classes.bulletPoint}
                                            style={{color: theme.colors.gray[0]}}>4-6:
                                            Moderate sweetness (e.g., fruits like
                                            apples)
                                        </li>
                                        <li className={classes.bulletPoint}
                                            style={{color: theme.colors.gray[0]}}>7-9:
                                            High sweetness (e.g., desserts like cake)
                                        </li>
                                        <li className={classes.bulletPoint}
                                            style={{color: theme.colors.gray[0]}}>10:
                                            Extremely sweet (e.g., pure sugar syrup)
                                        </li>
                                    </ul>
                                </Accordion.Panel>
                            </Accordion.Item>

                            <Accordion.Item value="salty">
                                <Accordion.Control>Salty</Accordion.Control>
                                <Accordion.Panel>
                                    <ul>
                                        <li className={classes.bulletPoint}
                                            style={{color: theme.colors.gray[0]}}>0: No
                                            saltiness
                                        </li>
                                        <li className={classes.bulletPoint}
                                            style={{color: theme.colors.gray[0]}}>1-3:
                                            Very low saltiness
                                        </li>
                                        <li className={classes.bulletPoint}
                                            style={{color: theme.colors.gray[0]}}>4-6:
                                            Moderate saltiness (e.g., salted nuts)
                                        </li>
                                        <li className={classes.bulletPoint}
                                            style={{color: theme.colors.gray[0]}}>7-9:
                                            High saltiness (e.g., pretzels)
                                        </li>
                                        <li className={classes.bulletPoint}
                                            style={{color: theme.colors.gray[0]}}>10:
                                            Extremely salty (e.g., salt-cured foods)
                                        </li>
                                    </ul>
                                </Accordion.Panel>
                            </Accordion.Item>

                            <Accordion.Item value="Sour">
                                <Accordion.Control>Sour</Accordion.Control>
                                <Accordion.Panel>
                                    <ul>
                                        <li className={classes.bulletPoint}
                                            style={{color: theme.colors.gray[0]}}>0: Not
                                            sour
                                        </li>
                                        <li className={classes.bulletPoint}
                                            style={{color: theme.colors.gray[0]}}>1-3:
                                            Very low sourness
                                        </li>
                                        <li className={classes.bulletPoint}
                                            style={{color: theme.colors.gray[0]}}>4-6:
                                            Moderate sourness (e.g., yogurt)
                                        </li>
                                        <li className={classes.bulletPoint}
                                            style={{color: theme.colors.gray[0]}}>7-9:
                                            High sourness (e.g., lemon juice)
                                        </li>
                                        <li className={classes.bulletPoint}
                                            style={{color: theme.colors.gray[0]}}>10:
                                            Extremely sour (e.g., pure vinegar)
                                        </li>
                                    </ul>
                                </Accordion.Panel>
                            </Accordion.Item>
                            <Accordion.Item value="Bitter">
                                <Accordion.Control>Bitter</Accordion.Control>
                                <Accordion.Panel>
                                    <ul>
                                        <li className={classes.bulletPoint}
                                            style={{color: theme.colors.gray[0]}}>0:
                                            Not bitter
                                        </li>
                                        <li className={classes.bulletPoint}
                                            style={{color: theme.colors.gray[0]}}>1-3:
                                            Very low bitterness
                                        </li>
                                        <li className={classes.bulletPoint}
                                            style={{color: theme.colors.gray[0]}}>4-6:
                                            Moderate bitterness (e.g., dark
                                            chocolate)
                                        </li>
                                        <li className={classes.bulletPoint}
                                            style={{color: theme.colors.gray[0]}}>7-9:
                                            High bitterness (e.g., black coffee)
                                        </li>
                                        <li className={classes.bulletPoint}
                                            style={{color: theme.colors.gray[0]}}>10:
                                            Extremely bitter (e.g., bitter melon)
                                        </li>
                                    </ul>
                                </Accordion.Panel>
                            </Accordion.Item>
                            <Accordion.Item value="Umami">
                                <Accordion.Control>Umami</Accordion.Control>
                                <Accordion.Panel>
                                    <ul>
                                        <li className={classes.bulletPoint}
                                            style={{color: theme.colors.gray[0]}}>0:
                                            No umami taste
                                        </li>
                                        <li className={classes.bulletPoint}
                                            style={{color: theme.colors.gray[0]}}>1-3:
                                            Very low umami
                                        </li>
                                        <li className={classes.bulletPoint}
                                            style={{color: theme.colors.gray[0]}}>4-6:
                                            Moderate umami (e.g., mushrooms)
                                        </li>
                                        <li className={classes.bulletPoint}
                                            style={{color: theme.colors.gray[0]}}>7-9:
                                            High umami (e.g., aged cheese)
                                        </li>
                                        <li className={classes.bulletPoint}
                                            style={{color: theme.colors.gray[0]}}>10:
                                            Extremely rich in umami (e.g., MSG
                                            seasoning)
                                        </li>
                                    </ul>
                                </Accordion.Panel>
                            </Accordion.Item>
                            <Accordion.Item value="Spicy">
                                <Accordion.Control>Spicy</Accordion.Control>
                                <Accordion.Panel>
                                    <ul>
                                        <li className={classes.bulletPoint}
                                            style={{color: theme.colors.gray[0]}}>0:
                                            Not spicy
                                        </li>
                                        <li className={classes.bulletPoint}
                                            style={{color: theme.colors.gray[0]}}>1-3:
                                            Very low spiciness
                                        </li>
                                        <li className={classes.bulletPoint}
                                            style={{color: theme.colors.gray[0]}}>4-6:
                                            Moderate spiciness (e.g., mild salsa)
                                        </li>
                                        <li className={classes.bulletPoint}
                                            style={{color: theme.colors.gray[0]}}>7-9:
                                            High spiciness (e.g., hot chili peppers)
                                        </li>
                                        <li className={classes.bulletPoint}
                                            style={{color: theme.colors.gray[0]}}>10:
                                            Extremely spicy (e.g., ghost pepper)
                                        </li>
                                    </ul>
                                </Accordion.Panel>
                            </Accordion.Item>
                        </Accordion>
                    </Accordion.Panel>
                </Accordion.Item>

                {/*health index*/}
                <Accordion.Item value="health_index">
                    <Accordion.Control>
                        <Box className={classes.gradientBorderBox}>
                            <Box className={classes.innerBox}>
                                <Center>
                                    <Text size="md">Health Index</Text>
                                </Center>
                            </Box>
                        </Box>
                    </Accordion.Control>
                    <Accordion.Panel>
                        <Text className={classes.accordionSubHeader}>Rates the food's
                            healthiness on a scale from 0 to 100.</Text>
                        <Text className={classes.paragraphText}>A comprehensive scoring
                            system that evaluates the overall nutritional value of foods
                            on a 0-100 scale. This classification considers multiple
                            factors including nutrient density, fiber content, protein
                            quality, healthy fat content, and the presence of beneficial
                            compounds, while also accounting for less desirable elements
                            like added sugars, sodium, and processed ingredients.</Text>
                        <Text className={classes.subHeader}>The health index
                            scale:</Text>
                        <ul className={classes.bulletList}>
                            <li className={classes.bulletPoint}><span
                                className={classes.bulletTitle}>0-19 (Very Unhealthy)</span>
                                <ul className={classes.bulletList}>
                                    <li className={classes.bulletPoint}>Highly processed
                                        foods with little to no nutritional value.
                                    </li>
                                    <li className={classes.bulletPoint}>High in added
                                        sugars, unhealthy fats, and sodium.
                                    </li>
                                    <li className={classes.bulletPoint}>Examples: Candy
                                        bars, Sugary sodas, Deep-fried snacks, Processed
                                        meats like hot dogs, Packaged pastries.
                                    </li>
                                </ul>
                            </li>
                            <li className={classes.bulletPoint}><span
                                className={classes.bulletTitle}>20-39 (Unhealthy)</span>
                                <ul className={classes.bulletList}>
                                    <li className={classes.bulletPoint}>Processed foods
                                        with low nutritional value.
                                    </li>
                                    <li className={classes.bulletPoint}>Contains added
                                        sugars, unhealthy fats, and artificial
                                        ingredients.
                                    </li>
                                    <li className={classes.bulletPoint}>Examples: White
                                        bread, Instant noodles, Sweetened cereals,
                                        Canned soups high in sodium, Flavored chips.
                                    </li>
                                </ul>
                            </li>
                            <li className={classes.bulletPoint}><span
                                className={classes.bulletTitle}>40-59 (Moderately
                                Healthy)</span>
                                <ul className={classes.bulletList}>
                                    <li className={classes.bulletPoint}>Foods with some
                                        nutritional benefits but may contain added
                                        sugars or fats.
                                    </li>
                                    <li className={classes.bulletPoint}>Examples:
                                        Granola bars, Fruit juices with added sugar,
                                        Flavored yogurts, Cheese, Pasta, Rice.
                                    </li>
                                </ul>
                            </li>
                            <li className={classes.bulletPoint}><span
                                className={classes.bulletTitle}>60-79 (Healthy)</span>
                                <ul className={classes.bulletList}>
                                    <li className={classes.bulletPoint}>Nutrient-dense
                                        foods with minimal processing.
                                    </li>
                                    <li className={classes.bulletPoint}>High in
                                        essential nutrients like vitamins, minerals,
                                        fiber, and healthy fats.
                                    </li>
                                    <li className={classes.bulletPoint}>Examples: Whole
                                        grains, Lean meats (chicken breast), Fish, Eggs,
                                        Legumes, Nuts and seeds, Plain yogurt.
                                    </li>
                                </ul>
                            </li>
                            <li className={classes.bulletPoint}><span
                                className={classes.bulletTitle}>80-100 (Very Healthy)</span>
                                <ul className={classes.bulletList}>
                                    <li className={classes.bulletPoint}>Whole,
                                        unprocessed foods rich in nutrients.
                                    </li>
                                    <li className={classes.bulletPoint}>Low in added
                                        sugars and unhealthy fats.
                                    </li>
                                    <li className={classes.bulletPoint}>Examples: Fresh
                                        fruits and vegetables, Leafy greens, Berries,
                                        Quinoa, Avocado, Broccoli, Almonds, Salmon.
                                    </li>
                                </ul>
                            </li>
                        </ul>

                    </Accordion.Panel>
                </Accordion.Item>

                {/*allergy*/}
                <Accordion.Item value="allergy">
                    <Accordion.Control>
                        <Box className={classes.gradientBorderBox}>
                            <Box className={classes.innerBox}>
                                <Center>
                                    <Text size="md">allergy</Text>
                                </Center>
                            </Box>
                        </Box>
                    </Accordion.Control>

                    <Accordion.Panel>
                        <Text className={classes.accordionSubHeader}>Identifies
                            potential allergens present in the food or recipe.</Text>
                        <Text className={classes.paragraphText}>A comprehensive
                            identification system that flags common allergens present in
                            foods and recipes, helping users with dietary restrictions
                            make safe choices. This classification is crucial for food
                            safety and compliance with labeling regulations, covering
                            the major allergens like nuts, dairy, eggs, soy, wheat,
                            fish, shellfish, and less common allergens. This information
                            is particularly vital for food service operations,
                            manufacturers, and individuals managing food
                            allergies.</Text>
                        <Text className={classes.subHeader}>Available allergen
                            tags:</Text>
                        <ul className={classes.bulletList}>
                            <li className={classes.bulletPoint}
                                style={{color: theme.colors.gray[0]}}>Peanuts
                            </li>
                            <li className={classes.bulletPoint}
                                style={{color: theme.colors.gray[0]}}>Tree Nuts
                            </li>
                            <li className={classes.bulletPoint}
                                style={{color: theme.colors.gray[0]}}>Milk
                            </li>
                            <li className={classes.bulletPoint}
                                style={{color: theme.colors.gray[0]}}>Eggs
                            </li>
                            <li className={classes.bulletPoint}
                                style={{color: theme.colors.gray[0]}}>Wheat
                            </li>
                            <li className={classes.bulletPoint}
                                style={{color: theme.colors.gray[0]}}>Soy
                            </li>
                            <li className={classes.bulletPoint}
                                style={{color: theme.colors.gray[0]}}>Fish
                            </li>
                            <li className={classes.bulletPoint}
                                style={{color: theme.colors.gray[0]}}>Shellfish
                            </li>
                            <li className={classes.bulletPoint}
                                style={{color: theme.colors.gray[0]}}>Sesame
                            </li>
                            <li className={classes.bulletPoint}
                                style={{color: theme.colors.gray[0]}}>Mustard
                            </li>
                            <li className={classes.bulletPoint}
                                style={{color: theme.colors.gray[0]}}>Sulphites
                            </li>
                            <li className={classes.bulletPoint}
                                style={{color: theme.colors.gray[0]}}>Lupin
                            </li>
                            <li className={classes.bulletPoint}
                                style={{color: theme.colors.gray[0]}}>Celery
                            </li>
                        </ul>
                    </Accordion.Panel>
                </Accordion.Item>

                {/*  diet*/}
                <Accordion.Item value="diet">
                    <Accordion.Control>
                        <Box className={classes.gradientBorderBox}>
                            <Box className={classes.innerBox}>
                                <Center>
                                    <Text size="md">diet</Text>
                                </Center>
                            </Box>
                        </Box>
                    </Accordion.Control>
                    <Accordion.Panel>
                        <Text className={classes.accordionSubHeader}>Determines which
                            common dietary plans the food or recipe aligns with.</Text>
                        <Text className={classes.paragraphText}>A detailed
                            categorization system that maps foods and recipes to various
                            dietary preferences and restrictions, including vegetarian,
                            vegan, pescatarian, ketogenic, paleo, halal, kosher, and
                            other specialized diets. This classification helps users
                            quickly identify foods that align with their dietary
                            choices, whether for health, religious, ethical, or personal
                            reasons. It considers both primary ingredients and
                            preparation methods to ensure accuracy.</Text>
                        <Text className={classes.subHeader}>Available diet
                            tags:</Text>
                        <ul className={classes.bulletList}>
                            <li className={classes.bulletPoint}
                                style={{color: theme.colors.gray[0]}}>Vegan
                            </li>
                            <li className={classes.bulletPoint}
                                style={{color: theme.colors.gray[0]}}>Vegetarian
                            </li>
                            <li className={classes.bulletPoint}
                                style={{color: theme.colors.gray[0]}}>Pescatarian
                            </li>
                            <li className={classes.bulletPoint}
                                style={{color: theme.colors.gray[0]}}>Keto
                            </li>
                            <li className={classes.bulletPoint}
                                style={{color: theme.colors.gray[0]}}>Paleo
                            </li>
                            <li className={classes.bulletPoint}
                                style={{color: theme.colors.gray[0]}}>Mediteranean
                            </li>
                            <li className={classes.bulletPoint}
                                style={{color: theme.colors.gray[0]}}>DASH
                            </li>
                            <li className={classes.bulletPoint}
                                style={{color: theme.colors.gray[0]}}>Whole30
                            </li>
                            <li className={classes.bulletPoint}
                                style={{color: theme.colors.gray[0]}}>Low FODMAP
                            </li>
                            <li className={classes.bulletPoint}
                                style={{color: theme.colors.gray[0]}}>Gluten Free
                            </li>
                            <li className={classes.bulletPoint}
                                style={{color: theme.colors.gray[0]}}>Dairy Free
                            </li>
                            <li className={classes.bulletPoint}
                                style={{color: theme.colors.gray[0]}}>Low Carb
                            </li>
                        </ul>
                    </Accordion.Panel>
                </Accordion.Item>

                {/*branded*/}
                <Accordion.Item value="branded">
                    <Accordion.Control>
                        <Box className={classes.gradientBorderBox}>
                            <Box className={classes.innerBox}>
                                <Center>
                                    <Text size="md">Branded</Text>
                                </Center>
                            </Box>
                        </Box>
                    </Accordion.Control>
                    <Accordion.Panel>
                        <Text className={classes.accordionSubHeader}>Indicates if the
                            food is a branded product and identifies the brand if
                            applicable.</Text>
                        <Text className={classes.paragraphText}>
                            Identifies whether a food item is a commercially branded
                            product, including manufacturer information, brand name, and
                            product line details. This classification helps distinguish
                            between generic and branded items, which is valuable for
                            inventory management, price comparison, and consumer choice.
                            It also includes sub-brand information and product variants
                            within the same brand family.
                        </Text>
                        <Text mb={10} className={classes.subHeader}>Example
                            response:</Text>
                        <Prism language={"json"}>
                            {`"branded": {
            "is_branded": false,
            "brand": null
          },`}
                        </Prism>
                    </Accordion.Panel>
                </Accordion.Item>

                {/*  texture*/}
                <Accordion.Item value="texture">
                    <Accordion.Control>
                        <Box className={classes.gradientBorderBox}>
                            <Box className={classes.innerBox}>
                                <Center>
                                    <Text size="md">Texture</Text>
                                </Center>
                            </Box>
                        </Box>
                    </Accordion.Control>
                    <Accordion.Panel>
                        <Text className={classes.accordionSubHeader}>Identifies primary
                            textural characteristics.</Text>
                        <Text className={classes.paragraphText}>Describes the physical
                            and sensory characteristics of food items, including
                            attributes like crunchiness, smoothness, crispiness,
                            chewiness, and mouthfeel. This classification is important
                            for understanding food quality, cooking properties, and
                            sensory experience. It helps users anticipate the eating
                            experience and is particularly valuable for those with
                            texture sensitivities or preferences.</Text>
                        <Text className={classes.subHeader}>Available Texture
                            tags:</Text>
                        <ul className={classes.bulletList}>
                            <li className={classes.bulletPoint}
                                style={{color: theme.colors.gray[0]}}>Crispy
                            </li>
                            <li className={classes.bulletPoint}
                                style={{color: theme.colors.gray[0]}}>Crunchy
                            </li>
                            <li className={classes.bulletPoint}
                                style={{color: theme.colors.gray[0]}}>Tender
                            </li>
                            <li className={classes.bulletPoint}
                                style={{color: theme.colors.gray[0]}}>Chewy
                            </li>
                            <li className={classes.bulletPoint}
                                style={{color: theme.colors.gray[0]}}>Creamy
                            </li>
                            <li className={classes.bulletPoint}
                                style={{color: theme.colors.gray[0]}}>Smooth
                            </li>
                            <li className={classes.bulletPoint}
                                style={{color: theme.colors.gray[0]}}>Silky
                            </li>
                            <li className={classes.bulletPoint}
                                style={{color: theme.colors.gray[0]}}>Flaky
                            </li>
                            <li className={classes.bulletPoint}
                                style={{color: theme.colors.gray[0]}}>Crumbly
                            </li>
                            <li className={classes.bulletPoint}
                                style={{color: theme.colors.gray[0]}}>Soft
                            </li>
                            <li className={classes.bulletPoint}
                                style={{color: theme.colors.gray[0]}}>Firm
                            </li>
                            <li className={classes.bulletPoint}
                                style={{color: theme.colors.gray[0]}}>Juicy
                            </li>
                            <li className={classes.bulletPoint}
                                style={{color: theme.colors.gray[0]}}>Grainy
                            </li>
                            <li className={classes.bulletPoint}
                                style={{color: theme.colors.gray[0]}}>Sticky
                            </li>
                            <li className={classes.bulletPoint}
                                style={{color: theme.colors.gray[0]}}>Gummy
                            </li>
                            <li className={classes.bulletPoint}
                                style={{color: theme.colors.gray[0]}}>Fluffy
                            </li>
                            <li className={classes.bulletPoint}
                                style={{color: theme.colors.gray[0]}}>Spongy
                            </li>
                            <li className={classes.bulletPoint}
                                style={{color: theme.colors.gray[0]}}>Velvety
                            </li>
                            <li className={classes.bulletPoint}
                                style={{color: theme.colors.gray[0]}}>Brittle
                            </li>
                            <li className={classes.bulletPoint}
                                style={{color: theme.colors.gray[0]}}>Mushy
                            </li>
                            <li className={classes.bulletPoint}
                                style={{color: theme.colors.gray[0]}}>Stringy
                            </li>
                            <li className={classes.bulletPoint}
                                style={{color: theme.colors.gray[0]}}>Gelatinous
                            </li>
                            <li className={classes.bulletPoint}
                                style={{color: theme.colors.gray[0]}}>Crisp-tender
                            </li>
                            <li className={classes.bulletPoint}
                                style={{color: theme.colors.gray[0]}}>Al dente
                            </li>
                            <li className={classes.bulletPoint}
                                style={{color: theme.colors.gray[0]}}>Melting
                            </li>
                        </ul>
                    </Accordion.Panel>
                </Accordion.Item>


                {/*    Cuisine*/}
                <Accordion.Item value="cuisine">
                    <Accordion.Control>
                        <Box className={classes.gradientBorderBox}>
                            <Box className={classes.innerBox}>
                                <Center>
                                    <Text size="md">Cuisine</Text>
                                </Center>
                            </Box>
                        </Box>
                    </Accordion.Control>
                    <Accordion.Panel>
                        <Text className={classes.accordionSubHeader}>Identifies from
                            what cuisines the food or recipe originates from.</Text>
                        <Text className={classes.paragraphText}>A comprehensive
                            classification system that identifies the cultural and
                            geographical origins of foods and recipes, encompassing both
                            traditional and fusion elements. This categorization goes
                            beyond simple regional labels to include specific
                            sub-regions, historical influences, and modern
                            interpretations. It helps users understand cooking
                            techniques, ingredient combinations, and flavor principles
                            characteristic of different culinary traditions, from broad
                            categories like East Asian or Mediterranean to specific
                            regional styles like Sicilian or Sichuan. This
                            classification also considers the authenticity level of
                            dishes, noting traditional preparations versus modern
                            adaptations, and can help users explore the cultural context
                            of their food choices. Understanding cuisine classification
                            is valuable for menu planning, cultural education, and
                            exploring the rich diversity of global food
                            traditions.</Text>
                        <Text className={classes.subHeader}>Available cuisine
                            tags:</Text>
                        <ul className={classes.bulletList}>
                            <li className={classes.bulletPoint}
                                style={{color: theme.colors.gray[0]}}>American
                            </li>
                            <li className={classes.bulletPoint}
                                style={{color: theme.colors.gray[0]}}>British
                            </li>
                            <li className={classes.bulletPoint}
                                style={{color: theme.colors.gray[0]}}>Caribbean
                            </li>
                            <li className={classes.bulletPoint}
                                style={{color: theme.colors.gray[0]}}>Chinese
                            </li>
                            <li className={classes.bulletPoint}
                                style={{color: theme.colors.gray[0]}}>French
                            </li>
                            <li className={classes.bulletPoint}
                                style={{color: theme.colors.gray[0]}}>German
                            </li>
                            <li className={classes.bulletPoint}
                                style={{color: theme.colors.gray[0]}}>Greek
                            </li>
                            <li className={classes.bulletPoint}
                                style={{color: theme.colors.gray[0]}}>Indian
                            </li>
                            <li className={classes.bulletPoint}
                                style={{color: theme.colors.gray[0]}}>Italian
                            </li>
                            <li className={classes.bulletPoint}
                                style={{color: theme.colors.gray[0]}}>Japanese
                            </li>
                            <li className={classes.bulletPoint}
                                style={{color: theme.colors.gray[0]}}>Korean
                            </li>
                            <li className={classes.bulletPoint}
                                style={{color: theme.colors.gray[0]}}>Mexican
                            </li>
                            <li className={classes.bulletPoint}
                                style={{color: theme.colors.gray[0]}}>Middle Eastern
                            </li>
                            <li className={classes.bulletPoint}
                                style={{color: theme.colors.gray[0]}}>Nordic
                            </li>
                            <li className={classes.bulletPoint}
                                style={{color: theme.colors.gray[0]}}>South American
                            </li>
                            <li className={classes.bulletPoint}
                                style={{color: theme.colors.gray[0]}}>Southeast Asian
                            </li>
                            <li className={classes.bulletPoint}
                                style={{color: theme.colors.gray[0]}}>Spanish
                            </li>
                            <li className={classes.bulletPoint}
                                style={{color: theme.colors.gray[0]}}>Thai
                            </li>
                            <li className={classes.bulletPoint}
                                style={{color: theme.colors.gray[0]}}>Vietnamese
                            </li>
                        </ul>
                    </Accordion.Panel>
                </Accordion.Item>

                {/*    seasonality*/}
                <Accordion.Item value="seasonality">
                    <Accordion.Control>
                        <Box className={classes.gradientBorderBox}>
                            <Box className={classes.innerBox}>
                                <Center>
                                    <Text size="md">Seasonality</Text>
                                </Center>
                            </Box>
                        </Box>
                    </Accordion.Control>
                    <Accordion.Panel>
                        <Text className={classes.accordionSubHeader}>Suggests the best
                            seasons to enjoy the food.</Text>
                        <Text className={classes.paragraphText}>A guide indicating
                            optimal times throughout the year for specific foods,
                            particularly relevant for produce and seasonal ingredients.
                            This classification considers factors like natural growing
                            seasons, peak harvest times, and traditional consumption
                            periods. It helps users make environmentally conscious
                            choices, find ingredients at their peak quality, and often
                            at better prices.</Text>
                        <Text className={classes.subHeader}>Available seasonality
                            tags:</Text>
                        <ul className={classes.bulletList}>
                            <li className={classes.bulletPoint}
                                style={{color: theme.colors.gray[0]}}>Year-round
                            </li>
                            <li className={classes.bulletPoint}
                                style={{color: theme.colors.gray[0]}}>Early Spring
                            </li>
                            <li className={classes.bulletPoint}
                                style={{color: theme.colors.gray[0]}}>Spring
                            </li>
                            <li className={classes.bulletPoint}
                                style={{color: theme.colors.gray[0]}}>Late Spring
                            </li>
                            <li className={classes.bulletPoint}
                                style={{color: theme.colors.gray[0]}}>Early Summer
                            </li>
                            <li className={classes.bulletPoint}
                                style={{color: theme.colors.gray[0]}}>Summer
                            </li>
                            <li className={classes.bulletPoint}
                                style={{color: theme.colors.gray[0]}}>Late Summer
                            </li>
                            <li className={classes.bulletPoint}
                                style={{color: theme.colors.gray[0]}}>Early Fall
                            </li>
                            <li className={classes.bulletPoint}
                                style={{color: theme.colors.gray[0]}}>Fall
                            </li>
                            <li className={classes.bulletPoint}
                                style={{color: theme.colors.gray[0]}}>Late Fall
                            </li>
                            <li className={classes.bulletPoint}
                                style={{color: theme.colors.gray[0]}}>Early Winter
                            </li>
                            <li className={classes.bulletPoint}
                                style={{color: theme.colors.gray[0]}}>Winter
                            </li>
                            <li className={classes.bulletPoint}
                                style={{color: theme.colors.gray[0]}}>Late Winter
                            </li>
                        </ul>
                    </Accordion.Panel>
                </Accordion.Item>

                {/*    Freshness_score*/}
                <Accordion.Item value="Freshness_score">
                    <Accordion.Control>
                        <Box className={classes.gradientBorderBox}>
                            <Box className={classes.innerBox}>
                                <Center>
                                    <Text size="md">Freshness Score</Text>
                                </Center>
                            </Box>
                        </Box>
                    </Accordion.Control>
                    <Accordion.Panel>
                        <Text className={classes.accordionSubHeader}>Scores the food
                            based on freshness and processing level on a scale from 0 to
                            20.</Text>
                        <Text className={classes.paragraphText}>A comprehensive
                            evaluation system that assesses food items on a 0-20 scale
                            based on their processing level, preservation methods, and
                            overall freshness. This score considers factors like
                            processing techniques, additives, shelf stability, and
                            proximity to natural state. Higher scores typically indicate
                            minimally processed, fresh foods, while lower scores suggest
                            more heavily processed items.</Text>
                        <Text className={classes.subHeader}>The scale used to calculate
                            freshness:</Text>
                        <ul className={classes.bulletList}>
                            <li className={classes.bulletPoint}
                                style={{color: theme.colors.gray[0]}}><span
                                style={{fontWeight: 700}}>0:</span> Canned soda - High
                                sugar drink with additives
                            </li>
                            <li className={classes.bulletPoint}
                                style={{color: theme.colors.gray[0]}}><span
                                style={{fontWeight: 700}}>1:</span> Candy bar -
                                Processed sugar and preservatives
                            </li>
                            <li className={classes.bulletPoint}
                                style={{color: theme.colors.gray[0]}}><span
                                style={{fontWeight: 700}}>2:</span> Packaged potato
                                chips - Processed snack with preservatives
                            </li>
                            <li className={classes.bulletPoint}
                                style={{color: theme.colors.gray[0]}}><span
                                style={{fontWeight: 700}}>3:</span> Instant ramen
                                noodles - Preserved noodles with artificial flavoring
                            </li>
                            <li className={classes.bulletPoint}
                                style={{color: theme.colors.gray[0]}}><span
                                style={{fontWeight: 700}}>4:</span> Frozen chicken
                                nuggets - Processed chicken with fillers
                            </li>
                            <li className={classes.bulletPoint}
                                style={{color: theme.colors.gray[0]}}><span
                                style={{fontWeight: 700}}>5:</span> Boxed macaroni and
                                cheese - Dried pasta with powdered cheese sauce
                            </li>
                            <li className={classes.bulletPoint}
                                style={{color: theme.colors.gray[0]}}><span
                                style={{fontWeight: 700}}>6:</span> Microwaveable frozen
                                dinner - Pre-made meal with preservatives
                            </li>
                            <li className={classes.bulletPoint}
                                style={{color: theme.colors.gray[0]}}><span
                                style={{fontWeight: 700}}>7:</span> Frozen pizza -
                                Pre-made with processed ingredients
                            </li>
                            <li className={classes.bulletPoint}
                                style={{color: theme.colors.gray[0]}}><span
                                style={{fontWeight: 700}}>8:</span> Canned soup -
                                Contains vegetables but preserved
                            </li>
                            <li className={classes.bulletPoint}
                                style={{color: theme.colors.gray[0]}}><span
                                style={{fontWeight: 700}}>9:</span> Deli meat sandwich -
                                Processed meats on bread
                            </li>
                            <li className={classes.bulletPoint}
                                style={{color: theme.colors.gray[0]}}><span
                                style={{fontWeight: 700}}>10:</span> Pasta with jarred
                                sauce - Mix of fresh and preserved ingredients
                            </li>
                            <li className={classes.bulletPoint}
                                style={{color: theme.colors.gray[0]}}><span
                                style={{fontWeight: 700}}>11:</span> Beef stew -
                                Combination of fresh and preserved items
                            </li>
                            <li className={classes.bulletPoint}
                                style={{color: theme.colors.gray[0]}}><span
                                style={{fontWeight: 700}}>12:</span> Chicken stir-fry -
                                Mostly fresh meats and vegetables
                            </li>
                            <li className={classes.bulletPoint}
                                style={{color: theme.colors.gray[0]}}><span
                                style={{fontWeight: 700}}>13:</span> Grilled vegetable
                                kebabs - Fresh vegetables, minimally processed
                            </li>
                            <li className={classes.bulletPoint}
                                style={{color: theme.colors.gray[0]}}><span
                                style={{fontWeight: 700}}>14:</span> Homemade vegetable
                                soup - Made with fresh ingredients
                            </li>
                            <li className={classes.bulletPoint}
                                style={{color: theme.colors.gray[0]}}><span
                                style={{fontWeight: 700}}>15:</span> Freshly made
                                guacamole with tortilla chips - Fresh avocado and
                                vegetables
                            </li>
                            <li className={classes.bulletPoint}
                                style={{color: theme.colors.gray[0]}}><span
                                style={{fontWeight: 700}}>16:</span> Avocado toast -
                                Fresh avocado on bread
                            </li>
                            <li className={classes.bulletPoint}
                                style={{color: theme.colors.gray[0]}}><span
                                style={{fontWeight: 700}}>17:</span> Fresh fruit salad -
                                Raw, cut fruit mixture
                            </li>
                            <li className={classes.bulletPoint}
                                style={{color: theme.colors.gray[0]}}><span
                                style={{fontWeight: 700}}>18:</span> Organic apples -
                                Fresh, unprocessed fruit
                            </li>
                            <li className={classes.bulletPoint}
                                style={{color: theme.colors.gray[0]}}><span
                                style={{fontWeight: 700}}>19:</span> Organic heirloom
                                tomatoes - Fresh, naturally grown produce
                            </li>
                            <li className={classes.bulletPoint}
                                style={{color: theme.colors.gray[0]}}><span
                                style={{fontWeight: 700}}>20:</span> Foraged wild
                                berries - Freshly picked wild produce
                            </li>
                        </ul>
                    </Accordion.Panel>
                </Accordion.Item>

                {/*price_point*/}
                <Accordion.Item value="price_point">
                    <Accordion.Control>
                        <Box className={classes.gradientBorderBox}>
                            <Box className={classes.innerBox}>
                                <Center>
                                    <Text size="md">Price Point</Text>
                                </Center>
                            </Box>
                        </Box>
                    </Accordion.Control>
                    <Accordion.Panel>
                        <Text className={classes.accordionSubHeader}>Estimates relative
                            cost on a four-point scale.</Text>
                        <Text className={classes.paragraphText}>A standardized
                            classification system that categorizes foods into price
                            ranges, helping users make budget-conscious decisions. This
                            system considers market averages, ingredient costs, and
                            preparation complexity. It helps users understand relative
                            value and plan purchases according to their budget
                            constraints, whether for personal shopping or menu
                            planning.</Text>
                        <Text className={classes.subHeader}>The price point
                            scale:</Text>
                        <ul className={classes.bulletList}>
                            <li className={classes.bulletPoint}
                                style={{color: theme.colors.gray[0]}}>$: Very
                                inexpensive foods
                                <ul>
                                    <li className={classes.bulletPoint}
                                        style={{color: theme.colors.gray[0]}}>Examples:
                                        Instant ramen noodles, Canned beans, White
                                        bread, Bananas, Rice, Basic hamburger, Street
                                        tacos, Plain bagel, Hot dog, French fries
                                    </li>
                                </ul>
                            </li>

                            <li className={classes.bulletPoint}
                                style={{color: theme.colors.gray[0]}}>$$: Moderately
                                priced foods
                                <ul>
                                    <li className={classes.bulletPoint}
                                        style={{color: theme.colors.gray[0]}}>Examples:
                                        Grilled chicken sandwich, Cheddar cheese, Olive
                                        oil, Fresh vegetables, Ground beef, Pasta with
                                        marinara sauce, Caesar salad, Pepperoni pizza,
                                        Sushi rolls (basic), Burrito bowl
                                    </li>
                                </ul>
                            </li>

                            <li className={classes.bulletPoint}
                                style={{color: theme.colors.gray[0]}}>$$$: Expensive
                                foods
                                <ul>
                                    <li className={classes.bulletPoint}
                                        style={{color: theme.colors.gray[0]}}>Examples:
                                        Salmon fillet, Organic produce, Artisan bread,
                                        Ribeye steak, Shrimp cocktail, Gourmet burger,
                                        Lamb chops, Pad Thai with prawns, Gourmet sushi
                                        platter, Gourmet pasta with truffles
                                    </li>
                                </ul>
                            </li>

                            <li className={classes.bulletPoint}
                                style={{color: theme.colors.gray[0]}}>$$$$: Very
                                expensive foods
                                <ul>
                                    <li className={classes.bulletPoint}
                                        style={{color: theme.colors.gray[0]}}>Examples:
                                        Truffle mushrooms, Caviar, Kobe beef,
                                        Saffron-infused dishes, Bluefin tuna sashimi,
                                        Lobster Thermidor, Foie gras, Wagyu steak,
                                        Omakase tasting menu, Alaskan king crab legs
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </Accordion.Panel>
                </Accordion.Item>

                {/*caloric density*/}
                <Accordion.Item value="caloric_density">
                    <Accordion.Control>
                        <Box className={classes.gradientBorderBox}>
                            <Box className={classes.innerBox}>
                                <Center>
                                    <Text size="md">Caloric Density</Text>
                                </Center>
                            </Box>
                        </Box>
                    </Accordion.Control>
                    <Accordion.Panel>
                        <Text className={classes.accordionSubHeader}>Categorizes caloric
                            concentration level.</Text>
                        <Text className={classes.paragraphText}>An important nutritional
                            classification that categorizes foods based on their caloric
                            content relative to portion size. This system helps users
                            understand energy density and make informed choices for
                            weight management and dietary planning. It considers both
                            the caloric content and the typical serving size to provide
                            practical guidance for meal planning.</Text>
                        <Text className={classes.subHeader}>The caloric density
                            scale:</Text>
                        <ul className={classes.bulletList}>
                            <li className={classes.bulletPoint}
                                style={{color: theme.colors.gray[0]}}>Very Low:
                                <ul>
                                    <li className={classes.bulletPoint}
                                        style={{color: theme.colors.gray[0]}}>Foods with
                                        minimal calories per serving.
                                    </li>
                                    <li className={classes.bulletPoint}
                                        style={{color: theme.colors.gray[0]}}>Examples:
                                        Water, Lettuce, Cucumber, Celery, Spinach,
                                        Broccoli, Tomatoes, Mushrooms, Zucchini,
                                        Radishes.
                                    </li>
                                </ul>
                            </li>

                            <li className={classes.bulletPoint}
                                style={{color: theme.colors.gray[0]}}>Low:
                                <ul>
                                    <li className={classes.bulletPoint}
                                        style={{color: theme.colors.gray[0]}}>Foods with
                                        relatively few calories per serving.
                                    </li>
                                    <li className={classes.bulletPoint}
                                        style={{color: theme.colors.gray[0]}}>Examples:
                                        Apples, Oranges, Berries, Skim Milk, Oats,
                                        Quinoa, Legumes, Grilled Chicken Breast, White
                                        Fish, Tofu.
                                    </li>
                                </ul>
                            </li>

                            <li className={classes.bulletPoint}
                                style={{color: theme.colors.gray[0]}}>Moderate:
                                <ul>
                                    <li className={classes.bulletPoint}
                                        style={{color: theme.colors.gray[0]}}>Foods with
                                        a moderate amount of calories per serving.
                                    </li>
                                    <li className={classes.bulletPoint}
                                        style={{color: theme.colors.gray[0]}}>Examples:
                                        Whole Grain Bread, Brown Rice, Pasta, Lean Beef,
                                        Pork Tenderloin, Eggs, Avocado, Hummus, Yogurt,
                                        Nuts.
                                    </li>
                                </ul>
                            </li>

                            <li className={classes.bulletPoint}
                                style={{color: theme.colors.gray[0]}}>High:
                                <ul>
                                    <li className={classes.bulletPoint}
                                        style={{color: theme.colors.gray[0]}}>Foods with
                                        a high amount of calories per serving.
                                    </li>
                                    <li className={classes.bulletPoint}
                                        style={{color: theme.colors.gray[0]}}>Examples:
                                        Cheese, Dark Chocolate, Peanut Butter, Olive
                                        Oil, Bacon, Sausages, Granola Bars, Ice Cream,
                                        Fried Foods, Croissants.
                                    </li>
                                </ul>
                            </li>

                            <li className={classes.bulletPoint}
                                style={{color: theme.colors.gray[0]}}>Very High:
                                <ul>
                                    <li className={classes.bulletPoint}
                                        style={{color: theme.colors.gray[0]}}>Foods with
                                        very high calories per serving.
                                    </li>
                                    <li className={classes.bulletPoint}
                                        style={{color: theme.colors.gray[0]}}>Examples:
                                        Butter, Vegetable Oils, Lard, Cake, Pastries,
                                        Milkshakes, Fast Food Burgers, Deep-Dish Pizza,
                                        French Fries, Sugary Sodas.
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </Accordion.Panel>
                </Accordion.Item>


                {/*color profile*/}
                <Accordion.Item value="color_profile">
                    <Accordion.Control>
                        <Box className={classes.gradientBorderBox}>
                            <Box className={classes.innerBox}>
                                <Center>
                                    <Text size="md">Color Profile</Text>
                                </Center>
                            </Box>
                        </Box>
                    </Accordion.Control>
                    <Accordion.Panel>
                        <Text className={classes.accordionSubHeader}>Identifies the
                            primary colors associated with the food.</Text>
                        <Text className={classes.paragraphText}>A visual classification
                            system that identifies and catalogs the primary and
                            secondary colors present in food items. This information is
                            valuable for presentation planning, food photography, and
                            understanding visual appeal. It also helps indicate the
                            presence of different phytonutrients and can be used to
                            ensure varied nutrient intake through eating foods of
                            different colors.</Text>
                        <Text className={classes.subHeader}>Available color tags:</Text>
                        <ul className={classes.bulletList}>
                            <li className={classes.bulletPoint}>Red</li>
                            <li className={classes.bulletPoint}>Orange</li>
                            <li className={classes.bulletPoint}>Yellow</li>
                            <li className={classes.bulletPoint}>Green</li>
                            <li className={classes.bulletPoint}>Blue</li>
                            <li className={classes.bulletPoint}>Purple</li>
                            <li className={classes.bulletPoint}>Brown</li>
                            <li className={classes.bulletPoint}>White</li>
                            <li className={classes.bulletPoint}>Black</li>
                            <li className={classes.bulletPoint}>Pink</li>
                            <li className={classes.bulletPoint}>Beige</li>
                            <li className={classes.bulletPoint}>Gray</li>
                        </ul>
                    </Accordion.Panel>
                </Accordion.Item>

            </Accordion>


            {/*tips*/}
            <Text mt={50} className={classes.mainHeader}>Tips for Using the API</Text>
            <ul className={classes.bulletList}>
                <li className={classes.bulletPoint}>
                    <span className={classes.bulletTitle}>Select Relevant Classification Types:</span> For
                    efficiency, specify only the classification types you need.
                </li>
                <li className={classes.bulletPoint}>
                    <span
                        className={classes.bulletTitle}>Use Appropriate Endpoint:</span> Choose
                    between the food or recipe endpoint based on your input for
                    optimized results.
                </li>
                <li className={classes.bulletPoint}>
                    <span
                        className={classes.bulletTitle}>Handle Empty Responses:</span> Some
                    classifications may return empty if no data is applicable.
                </li>
            </ul>


            {/*addition info*/}
            <Text mt={50} className={classes.mainHeader}>Additional Information</Text>
            <ul className={classes.bulletList}>
                <li className={classes.bulletPoint}>
                    <span className={classes.bulletTitle}>Scalability:</span> Large
                    queries may affect response time; consider optimizing your requests.
                </li>
                <li className={classes.bulletPoint}>
                    <span
                        className={classes.bulletTitle}>Accuracy:</span> Classifications
                    are based on predictive models and may not always be 100% accurate.
                </li>
            </ul>
            <FeedbackBanner/>

        </Container>
    );
}

export default function DocsClassificationPage() {
    return (
        <div>
            <DocsPage children={<DocsClassification/>} activeItem={"Classification"}/>
        </div>
    );
}
