interface Params {
    [key: string]: any;
}

const getNodeCode = (url: string, parameters: Params, currentParameters: Params): string => {

    let data = {...parameters};
    for(let param in data) {
        if(data[param] === currentParameters[param]) {
            delete data[param];
        }
    }

    let jsonIndent = '  ';
    let dataString = JSON.stringify(data, null, 2).split('\n').map(line => jsonIndent + line).join('\n');
    let nodeCode = `const axios = require('axios');\n\naxios.post(${url}, {\n${dataString}\n}, {\n  headers: {\n    api-key: \` \${NUTRICLOUD_API_KEY}\`\n  }\n})\n.then((response) => {\n  console.log(response.data);\n})\n.catch((error) => {\n  console.error(error);\n});`;

    return nodeCode;
};

export default getNodeCode;
