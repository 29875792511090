import {
    TextInput,
    PasswordInput,
    Anchor,
    Paper,
    Title,
    Text,
    Container,
    Group,
    Button,
    Divider,
    Grid,
    Col, useMantineTheme,
    Loader, // Add Loader from Mantine
} from '@mantine/core';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {createStyles} from "@mantine/styles";
import {FormEvent, useEffect, useState} from "react";
import axios from "axios";
import qs from "qs";
import NutricloudLogo from "../../componenets/logo/NutriCloudLogo";
import {useAuth} from "../../AuthContext";
import {notifications, showNotification} from "@mantine/notifications";


const useStyles = createStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100vh',
        justifyContent: 'space-between',
        paddingTop: '0%',
    },
    links: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '2rem',
    },
    link: {
        fontSize: '0.9rem',
        color: theme.colorScheme === 'dark' ? theme.colors.orange[0] : theme.colors.gray[7],
    },
    description: {
        fontSize: '.9rem',
        color: theme.colorScheme === 'dark' ? theme.colors.gray[3] : theme.colors.gray[6],
        textAlign: 'center',
    },
    title: {
        fontFamily: `Greycliff CF, ${theme.fontFamily}`,
        fontWeight: 600 ,
        fontSize: theme.fontSizes.xl,
        textAlign: 'center',
        color: theme.colorScheme === 'dark' ? theme.colors.gray[2] : theme.colors.gray[6],
    },
    form: {
        marginTop: -30,
    },

    textInput: {
        width: "100%",
        color: theme.colors.gray[1]
    },

    centeredContent: {
        display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      flexGrow: 1,
      width: "100%",
    },
}));


export function Onboarding() {
    const theme = useMantineTheme();
    const navigate = useNavigate();
    const location = useLocation();
    const { classes } = useStyles();
    const [resendResponse, setResendResponse] = useState('');
    const [loading, setLoading] = useState(false); // Loading state
    const [sent, setSent] = useState(false);

    const { isEmailVerified, accessToken } = useAuth();

    const resendEmail = async () => {
        setLoading(true); // Set loading to true when the request starts
        try {
            const baseURL = process.env.REACT_APP_BASE_URL;
            const response = await axios.post(`${baseURL}/api/auth/verify/email`, {}, {
                headers: {
                    'accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                },
                withCredentials: true
            });
            console.log(response.data);
            sendNotification();
            if (response.data.success) {
                setResendResponse('Email sent.');
            } else {
                setResendResponse('Failed to send email.');
            }
        } catch (error) {
            setResendResponse('Failed to send email.');
        } finally {
            setLoading(false); // Set loading to false after the request completes
        }
    };

    const sendNotification = () => {
        // Handle success feedback here
        showNotification({
          title: 'Sent!',
          message: 'A new verification email is on its way. Emails may take a few minutes to arrive.',
          autoClose: 8000, // Adjust timing as needed
        });
        notifications.cleanQueue()
    };


    // dynamic form
    const [email, setEmail] = useState('');

    useEffect(() => {
        // Parse the query parameters
        const queryParams = new URLSearchParams(location.search);
        const emailParam = queryParams.get('email');
        if (emailParam) setEmail(decodeURIComponent(emailParam));
    }, [location]);

    useEffect(() => {
        if (isEmailVerified ) {
            navigate('/playground');
        }
    }, [isEmailVerified, navigate]);

    return (
        <div className={classes.root}>
            <div style={{marginTop: 20}}>
                <NutricloudLogo size={50}/>
            </div>
            <div className={classes.centeredContent}>
            <Container size={420} my={40}>

                {/*title*/}
                <Title
                    align="center"
                    className={classes.title}
                >
                    Verify your email
                </Title>

                <Paper p={30} my={0} radius="md">
                    <Text color="dimmed" size="sm" align="center">
                        We sent an email to {email}.
                        Click the link inside to get started.
                    </Text>
                <Text mt={20} color="dimmed" size="sm" align="center" onClick={loading || resendResponse === 'Email sent.' ? undefined : resendEmail}>
                    <Anchor size="sm" style={{ pointerEvents: loading || resendResponse === 'Email sent.' ? 'none' : 'auto' }}>
                        {loading ? <Loader size="xs" /> : resendResponse === 'Email sent.' ? 'Email sent.' : 'Resend email'}
                    </Anchor>
                </Text>
                </Paper>

            </Container>
                </div>
        </div>
    );
}
