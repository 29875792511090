import {
    Badge,
    Button, Center,
    createStyles,
    Loader,
    Table, Text,
    useMantineTheme
} from '@mantine/core';
import React, {useEffect, useState} from 'react';
import axios from "axios";
import axiosInstance from "../../../axiosInstance";
import {useAuth} from "../../../AuthContext";

const useStyles = createStyles((theme) => ({
    tableContainer: {
        overflowX: 'auto', // Enables horizontal scrolling
        maxWidth: '100%', // Ensures the container does not exceed the viewport width
        paddingRight: 50,
        // height: '100%',
    },
    table: {
        width: '100%',
        borderCollapse: 'collapse',
    },
        paragraphText: {
        fontSize: '16px',
        fontWeight: 300,
        color: theme.colors.gray[0],
        marginLeft: '5px',
    },

}));


interface InvoiceItem {
    id: number;
  month: string;
  state: 'Paid' | 'Due';
  balance: number;
  link: string;
}


const InvoiceTable: React.FC<{}> = () => {
      const { classes } = useStyles();
      const {apiClient} = useAuth();

  const [invoiceData, setInvoiceData] = useState<InvoiceItem[]>([]);
  const [loadingInvoices, setLoadingInvoices] = useState<boolean>(true);
    const theme = useMantineTheme();

  // status badge
    const getStatusBadgeColor = (status: string) => {
      return status === 'Paid' ? 'green' : 'red';
    };

    const getInvoiceData = async () => {
    try {
        setLoadingInvoices(true);
      const baseURL = process.env.REACT_APP_BASE_URL;
      const response = await apiClient.get(`${baseURL}/api/payment/invoices?page=1`, {
            headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            },
          withCredentials: true
      });
      console.log(response.data);

      const invoices: InvoiceItem[] = response.data.legacy_data.map((invoice: any) => ({
          id: invoice.invoice_id,
        month: invoice.date_issued,  // Format the date as "Month Year"
        state: invoice.status === "ACTIVE" ? 'Due' : 'Paid',  // Assuming 'ACTIVE' means due and others mean paid
        balance: invoice.usd_balance,  // Assuming you want to use the USD balance
        link: `https://example.com/invoice/${invoice.token_amount}`  // Example link, adjust based on actual data
      }));

      setInvoiceData(invoices);
    } catch (error) {
      console.error('Failed to fetch invoices', error);
    } finally {
        setLoadingInvoices(false);
    }
};

    useEffect(() => {
        getInvoiceData();
    }, []);

  return (
      <div className={classes.tableContainer}>
          {loadingInvoices ? (
                  <div style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '100%'
                  }}>
                      <Loader size="lg"/>
                  </div>
          ) : (
              <div>
                  <Text className={classes.paragraphText}>Showing invoices within the
                      past 12 months</Text>
                  <Table striped highlightOnHover>
                      <thead>
                      <tr>
                          <th>Invoice</th>
                          <th>Status</th>
                          <th>Amount</th>
                          <th>Created</th>
                          <th></th>
                      </tr>
                      </thead>
                      <tbody>

                      {invoiceData.map((data, index) => (
                          <tr key={index}>
                              <td>{data.id}</td>
                              <td>
                                  <Badge
                                      color={getStatusBadgeColor(data.state)}
                                      variant={"light"}>{data.state}</Badge>
                              </td>
                              <td>{data.balance}</td>
                              <td>{data.month}</td>
                              <td>
                                  <Button variant="transparent"
                                          style={{color: theme.colors.orange[0]}}>View</Button>
                              </td>
                          </tr>
                      ))}
                      </tbody>
                  </Table>
              {/*    if lenght of invoiceData is zero display text*/}
                    {invoiceData.length === 0 && (
                        <Center>
                            <Text align="center" size="xs" color={theme.colors.gray[4]}>
                                No payments have been made yet on this account.
                            </Text>
                        </Center>
                    )}
              </div>
          )}
      </div>
  );
};

export default InvoiceTable;
