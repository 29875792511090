import React, { useState, useRef, useEffect } from 'react';
import {createStyles, TextInput, Group, Button} from '@mantine/core';
import axios from "axios";
import {useNavigate} from "react-router-dom";
import {useAuth} from "../../AuthContext";
import axiosInstance from "../../axiosInstance";

const useStyles = createStyles((theme) => ({
  otpInput: {
    width: '80px',
    height: '80px',
    fontSize: '48px',
    textAlign: 'center',
    borderRadius: theme.radius.sm,
    border: `2px solid ${theme.colors.gray[4]}`,
    '&:focus-within': {
      borderColor: theme.colors.orange[1],
    },
    '& input': {
      width: '100%',
      height: '100%',
      textAlign: 'center',
      fontSize: 'inherit',
      padding: '0',
      border: 'none',
      borderRadius: 'inherit',
        marginTop: 9,
      backgroundColor: 'transparent',
      '&:focus': {
        outline: 'none',
      },
    },
  },
}));

const OtpVerification: React.FC = () => {
  const { classes } = useStyles();
  const { setIsPhoneVerified } = useAuth();
  const [otp, setOtp] = useState(['', '', '', '', '', '']);
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
  // navigation
        const navigate = useNavigate();

  //       error
    const [authError, setAuthError] = useState<string | null>(null);


  useEffect(() => {
    inputRefs.current[0]?.focus();
  }, []);

  const handleChange = (index: number, value: string) => {
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    if (value.length === 1 && index < 5) {
      inputRefs.current[index + 1]?.focus();
    }
  };

  const handleVerification = async () => {
      const code = otp.join('');
    try {
        const baseURL = process.env.REACT_APP_BASE_URL;
        const response = await axiosInstance.post(`${baseURL}/api/auth/verify/verify-phone-number`, '"' + code + '"', {
          withCredentials: true,
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
        });
        console.log(response);

        if (response.status === 200) {
            setIsPhoneVerified(true);
            navigate('/account/profile');
            setAuthError(null);
        }

        else {
            setAuthError('Authentication failed.' + response.data.message); // Set the error message
        }
    }catch (error) {
  if (axios.isAxiosError(error) && error.response) {
      console.log(error)
    // Access the response legacy_data from error.response
    setAuthError(error.response.data.detail);
  } else {
    // Handle unexpected errors
    setAuthError('An unexpected error occurred.');
  }
}
};

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    if (event.key === 'Backspace' && otp[index].length === 0 && index > 0) {
      inputRefs.current[index - 1]?.focus();
    }
  };

  return (
    <Group spacing="md" mt={20} position={"center"}>
      {otp.map((_, index) => (
            <TextInput
              key={index}
              ref={(el) => (inputRefs.current[index] = el)}
              className={classes.otpInput}
              maxLength={1}
              value={otp[index]}
              onChange={(event) => handleChange(index, event.target.value)}
              onKeyDown={(event) => {
                handleKeyDown(event, index);
                if (event.key === 'Enter' && index === otp.length - 1) {
                  handleVerification();
                }
              }}
            />
          ))}

        <Button color="orange" size="lg" fullWidth onClick={handleVerification}>
            Verify
        </Button>
        {authError && <div style={{color: 'red'}}>{authError}</div>}
    </Group>
  );
};

export default OtpVerification;
