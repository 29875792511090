import { Box, PasswordInput, Group, Text, Center, useMantineTheme } from '@mantine/core';
import { useInputState } from '@mantine/hooks';
import { useState } from 'react';
import { IconCheck } from '@tabler/icons-react';

function PasswordRequirement({ meets, label }: { meets: boolean; label: string }) {
    return (
        <Text color={meets ? 'teal' : '#dee2e6'} mt={5} size="sm">
            <Center inline>
                {meets ? <IconCheck size="0.9rem" stroke={1.5} /> : '•'}
                <Box ml={7}>{label}</Box>
            </Center>
        </Text>
    );
}

const requirements = [
    { re: /[0-9]/, label: 'Includes number' },
    { re: /[A-Z]/, label: 'Includes uppercase letter' },
];

interface PasswordInputWithValidationProps {
    password: string;
    setPassword: React.Dispatch<React.SetStateAction<string>>;
    onValidChange: (isValid: boolean) => void;
}

export function PasswordInputWithValidation({
    password,
    setPassword,
    onValidChange,
}: PasswordInputWithValidationProps) {
    const theme = useMantineTheme();
    const [startedTyping, setStartedTyping] = useState(false);
    const [spaceTyped, setSpaceTyped] = useState(false);

    // Remove the useInputState hook usage since value and setValue are now props

    const checks = requirements.map((requirement, index) => (
        <PasswordRequirement key={index} label={requirement.label} meets={requirement.re.test(password)} />
    ));

    function allRequirementsMet(password: string) {
        return requirements.every((requirement) => requirement.re.test(password));
    }

    const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.value;
        setPassword(newValue); // Use setPassword from props
        if (newValue.length > 0) {
            setStartedTyping(true);
        }
        if (newValue.includes(' ')) {
            setSpaceTyped(true);
        }

        const valid = newValue.length > 9 && allRequirementsMet(newValue) && !newValue.includes(' ');
        onValidChange(valid);
    }


    return (
        <div>
            <PasswordInput
                value={password}
                onChange={handleValueChange}
                placeholder="Your password"
                radius="xs"
                size="lg"
                mt={15}

            />

            {startedTyping && (
                <div style={{ border: '1px solid ' + theme.colors.gray[8], borderRadius: '5px', padding: '10px', marginTop: '10px', backgroundColor: theme.colors.gray[9] }}>
                    <Text color="#dee2e6" size="sm" weight={500}>Your password must contain:</Text>
                    <PasswordRequirement label="At least 10 characters" meets={password.length > 9} />
                    {checks}
                    {spaceTyped && <PasswordRequirement label="No spaces" meets={!password.includes(' ')} />}
                </div>
            )}
        </div>
    );
}
