import { Container, Title, Accordion, createStyles, rem } from '@mantine/core';

const useStyles = createStyles((theme) => ({
    wrapper: {
        width: '100%', // Ensures the wrapper is always full-width
        boxSizing: 'border-box', // Includes padding and border in the element's dimensions
    },

    title: {
        marginBottom: `calc(${theme.spacing.lg} * 1.5)`,
    },

    item: {
        borderRadius: theme.radius.md,
        marginBottom: theme.spacing.md,
        border: `${rem(1)} solid ${
            theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]
        }`,
        boxSizing: 'border-box', // Ensures padding and borders are within the width and height
    },
}));


const apiCallsDetails = 'The free tier of our service is limited to 50,000 API calls per month. However, once you upgrade to a paid plan, this limit is substantially increased or even removed, depending on the plan you select. This allows for more flexibility and scalability, enabling your applications to handle higher loads as they grow.';

const SDKDetails = 'While we don\'t provide SDKs, we offer comprehensive documentation for different programming languages such as TypeScript, Python, and NodeJS.tsx. These guides help developers understand how to effectively integrate our API into their tech stack, regardless of their preferred coding language.';

const upgradePlandetails = 'Yes, we\'ve made our pricing plans as flexible as possible. You can upgrade your plan at any moment to unlock additional features and capacities. Plus, if you reach high volumes of legacy_data or require more resources, we are open to negotiate a better price. Our ultimate goal is to ensure NutriCloud meets your needs at the best possible cost.';

const customerSupportDetails = 'Yes, we do. Our commitment to our customers extends beyond providing an excellent API. We offer robust customer support to ensure that every user has a smooth and enjoyable experience. Our dedicated support team is readily available to assist you with any challenges you may face or questions you may have. We\'re here to help you maximize the power of NutriCloud.';

const documentationDetails = 'Absolutely, we believe that comprehensive documentation is crucial to the efficient use of our API. We provide a detailed guide for every endpoint, helping you understand their function and usage. Each tutorial includes step-by-step instructions, examples, and advice on how to get the most out of NutriCloud\'s features.';


export function FaqSimple() {
    const { classes } = useStyles();
    return (
        <Container size="md" className={classes.wrapper}>
            <Accordion variant="separated" style={{width: "100%"}}>
                <Accordion.Item className={classes.item} value="reset-password">
                    <Accordion.Control>Is NutriCloud's API documented?</Accordion.Control>
                    <Accordion.Panel>{documentationDetails}</Accordion.Panel>
                </Accordion.Item>

                <Accordion.Item className={classes.item} value="another-account">
                    <Accordion.Control>Do you offer customer support?</Accordion.Control>
                    <Accordion.Panel>{customerSupportDetails}</Accordion.Panel>
                </Accordion.Item>

                <Accordion.Item className={classes.item} value="newsletter">
                    <Accordion.Control>Can I upgrade my plan at any time?</Accordion.Control>
                    <Accordion.Panel>{upgradePlandetails}</Accordion.Panel>
                </Accordion.Item>

                <Accordion.Item className={classes.item} value="credit-card">
                    <Accordion.Control>Do you provide SDKs?</Accordion.Control>
                    <Accordion.Panel>{SDKDetails}</Accordion.Panel>
                </Accordion.Item>

                <Accordion.Item className={classes.item} value="payment">
                    <Accordion.Control>Is there a limit to API calls?</Accordion.Control>
                    <Accordion.Panel>{apiCallsDetails}</Accordion.Panel>
                </Accordion.Item>
            </Accordion>
        </Container>
    );
}
