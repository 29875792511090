import {createStyles, Text, Container, ActionIcon, Group, Grid, Col, useMantineTheme} from '@mantine/core';
import { IconBrandTwitter, IconBrandYoutube, IconBrandInstagram } from '@tabler/icons-react';
import { Avatar } from '@mantine/core';
import React, { useState, useEffect } from 'react';
import {FullWidthCompanyLogo} from "../icons/FullWidthCompanyLogo";


const useStyles = createStyles((theme) => ({
    footer: {
        width: '90vw',
        margin: '0 auto',
        padding: theme.spacing.md,
        marginTop: '-20px',
    },
    footerTop: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        width: '90%',
        marginLeft: '10%',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        paddingBottom: '0px',
    },
    logoContainer: {
        margin: 0,
        padding: 0,
        textAlign: 'left',
        width: '100%',
    },
    column: {
        minWidth: '200px',
        flexGrow: 1,
        flexBasis: '0',
        margin: `0 ${theme.spacing.sm}`,

        '@media (max-width: 768px)': {
            flexBasis: 'calc(50% - ${2 * theme.spacing.sm}px)',  // Accounting for the margin on both sides
            marginBottom: theme.spacing.md,
        },

        '@media (max-width: 480px)': {
            flexBasis: '100%',
            margin: `0 0 ${theme.spacing.md} 0`, // Reset horizontal margin, maintain bottom margin
        },
    },
    title: {
        fontWeight: 'bold',
        color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.gray[7],
    },
    logo: {
        width: '40px',
        marginRight: theme.spacing.sm,
    },
    link: {
        display: 'block',
        textDecoration: 'none',
        color: theme.colors.gray[6],
        marginBottom: theme.spacing.xs,
    },
    linkColumns: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: theme.spacing.xl,
    },
    afterFooter: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderTop: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[2]}`,
        paddingTop: theme.spacing.lg,
        paddingBottom: '100px'
    },
    logoAndName: {
        display: 'flex',
        alignItems: 'center',
        marginRight: theme.spacing.xl,
    },
    backToTopButton: {
        padding: '10px 20px',
        background: 'none',
        border: 'none',
        color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.gray[7],
        cursor: 'pointer',
        fontSize: '1rem', // adjust as needed
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: 'auto',  // this will push the button to the right side of its container
        marginTop: theme.spacing.lg,
        transition: 'color 0.2s',

        '&:hover': {
            color: '#ffffff',
            textDecoration: 'underline',
        }
    },




}));

interface FooterLinksProps {
    data: {
        title: string;
        links: { label: string; link: string }[];
    }[];
}

export function NewFooter({ data }: FooterLinksProps) {
    const { classes } = useStyles();
    let accumulatedTop = 0;
    const theme = useMantineTheme();

    const scrollTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <div>
            <div className={classes.footer}>
                <div className={classes.footerTop}>

                    <div className={classes.column}>
                        <h4 className={classes.title}>Company</h4>
                        <a href="/about" className={classes.link}>About Us</a>
                        <a href="/careers" className={classes.link}>Careers</a>
                        <a href="/contact" className={classes.link}>Contact</a>
                    </div>

                    <div className={classes.column}>
                        <h4 className={classes.title}>API</h4>
                        <a href="/api/v1/all-api-reference" className={classes.link}>API
                            Reference</a>
                        <a href="/api/v1/food-relational-search"
                           className={classes.link}>Relational Search</a>
                        <a href="/api/v1/full-nutrient-profile"
                           className={classes.link}>Full Nutrient Profile</a>
                        <a href="/api/v1/all-api-reference"
                           className={classes.link}>AutoComplete</a>
                        <a href="/api/v1/database-search"
                           className={classes.link}>Database</a>
                    </div>

                    <div className={classes.column}>
                        <h4 className={classes.title}>Getting Started</h4>
                        <a href="/playground" className={classes.link}>Playground</a>
                        <a href="/pricing" className={classes.link}>Pricing</a>
                        <a href="/all-docs" className={classes.link}>Documentation</a>
                        <a href="/signup" className={classes.link}>Sign Up</a>
                    </div>

                    <div className={classes.column}>
                        <h4 className={classes.title}>Legal</h4>
                        <a href="/policies" className={classes.link}>Terms &
                            Policies</a>
                        <a href="/policies/terms-of-use" className={classes.link}>Terms
                            of Use</a>
                        <a href="/policies/privacy-policy" className={classes.link}>Privacy
                            Policy</a>
                        <a href="/contact" className={classes.link}>Contact Us</a>
                    </div>
                </div>

                <div className={classes.logoContainer}>
                    <FullWidthCompanyLogo/>
                </div>

                <div className={classes.afterFooter}>
                    <Text color="dimmed" size="sm">© 2023 NutriCloud.com. All rights
                        reserved.</Text>
                    <Group spacing={0}>
                        <ActionIcon size="lg"><IconBrandTwitter size="1.05rem"
                                                                stroke={1.5}/></ActionIcon>
                        <ActionIcon size="lg"><IconBrandYoutube size="1.05rem"
                                                                stroke={1.5}/></ActionIcon>
                        <ActionIcon size="lg"><IconBrandInstagram size="1.05rem"
                                                                  stroke={1.5}/></ActionIcon>
                    </Group>
                </div>
                <button onClick={scrollTop} className={classes.backToTopButton}>
                    Back to Top ↑
                </button>
            </div>
            <div style={{
                position: 'relative',
                height: '650px',
                width: '100vw',
                overflow: 'hidden'
            }}>
                {Array.from({length: 35}).map((_, idx) => {
                    const height = idx * 0.8;  // slower height increase
                    const gap = 20 - idx * 0.8; // starting gap is 20 and it decreases by 0.8 with each line
                    accumulatedTop += height + gap;

                    return (
                        <div
                            key={idx}
                            style={{
                                content: '""',
                                position: 'absolute',
                                left: 0,
                                right: 0,
                                height: `${height}px`,
                                backgroundColor: theme.colors.orange[0],
                                top: `${accumulatedTop}px`,
                            }}
                        />
                    );
                })}
            </div>
        </div>
    );
}
