import React from 'react';
import { Tabs, createStyles } from '@mantine/core';
import GenerativeTab from "./generativeTab";
import ImageTab from "./ImageTab";
import ChatTab from "./chatTab";
import RecipeTab from "./RecipeTab";
import FoodTab from "./foodTab";
import ClassificationTab from "./classificationTab";
import GeolocationTab from "./geolocationTab";

// Create custom styles
const useStyles = createStyles((theme) => ({
    component: {
        borderRadius: theme.radius.md,
        borderColor: theme.colorScheme === 'dark' ? theme.colors.gray[7] : theme.colors.gray[3],
        borderWidth: '1px',
        borderStyle: 'solid',
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
        padding: 10,
        maxWidth: "90%",
        // marginLeft: "50px",
        marginLeft: -80,
        [`@media (max-width: 1400px)`]: {
            marginLeft: "-50px",
        },
        [`@media (max-width: 767px)`]: {
            marginLeft: 10,
        }
    },
  tabControl: {
      fontSize: 18,
      padding: 20,
    // Adjust styles for the active tab
    '&[data-active]': {
      position: 'relative',
      boxShadow: 'none', // Hide the default underline

      '&::after': {
        content: '""',
        position: 'absolute',
        bottom: -2,
        left: 0,
        right: 0,
        height: '2px',
background: `linear-gradient(90deg, #994fb2 35%, #D32122)`, // Customize gradient here
        borderRadius: '2px',
      },
    },
  },
  // Additional style to target the root of Tabs to ensure no default indicators are visible
  tabsList: {
    '& .mantine-Tabs-tabControl': {
      borderBottom: 'none !important', // Remove default border/underline
    },
  },



}));

function ServiceTabs() {
  const { classes } = useStyles();
    return (
        <div className={classes.component}>
            <Tabs radius="xs" defaultValue="Chat">
                <Tabs.List grow position="center" className={classes.tabsList}>
                    <Tabs.Tab value="Chat" className={classes.tabControl}>
                        Chat
                    </Tabs.Tab>
                    <Tabs.Tab value="Image" className={classes.tabControl}>
                        Image
                    </Tabs.Tab>
                    <Tabs.Tab value="Recipe" className={classes.tabControl}>
                        Recipe
                    </Tabs.Tab>
                    <Tabs.Tab value="Classification" className={classes.tabControl}>
                        Classification
                    </Tabs.Tab>
                    <Tabs.Tab value="Generative AI" className={classes.tabControl}>
                        Generative AI
                    </Tabs.Tab>
                    <Tabs.Tab value="Geolocation" className={classes.tabControl}>
                        Geolocation
                    </Tabs.Tab>
                    <Tabs.Tab value="Food" className={classes.tabControl}>
                        Food
                    </Tabs.Tab>
                </Tabs.List>

                <Tabs.Panel value="Image" pt="xs">
                    <ImageTab></ImageTab>
                </Tabs.Panel>

                <Tabs.Panel value="Generative AI" pt="xs">
                    <GenerativeTab></GenerativeTab>
                </Tabs.Panel>

                <Tabs.Panel value="Food" pt="xs">
                    <FoodTab></FoodTab>
                </Tabs.Panel>

                <Tabs.Panel value="Chat" pt="xs">
                    <ChatTab></ChatTab>
                </Tabs.Panel>

                <Tabs.Panel value="Recipe" pt="xs">
                    <RecipeTab></RecipeTab>
                </Tabs.Panel>

                <Tabs.Panel value="Classification" pt="xs">
                    <ClassificationTab></ClassificationTab>
                </Tabs.Panel>

                <Tabs.Panel value="Geolocation" pt="xs">
                    <GeolocationTab></GeolocationTab>
                </Tabs.Panel>
            </Tabs>
        </div>
    );
}

export default ServiceTabs;
