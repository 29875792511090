import React, {useEffect, useState} from 'react';
import {
    Paper,
    Progress,
    Text,
    Badge,
    Box,
    Grid,
    Divider,
    useMantineTheme, createStyles,
    Tooltip as MantineTooltip, Group, Stack, Button, Accordion, Center,
} from '@mantine/core';
import {
    Bar,
    BarChart,
    CartesianGrid, Line, LineChart,
    ResponsiveContainer,
    Tooltip, TooltipProps,
    XAxis,
    YAxis
} from "recharts";
import {useUsage} from "../usageContext";

const useStyles = createStyles((theme) => ({
    title: {
        fontSize: '12px',
        color: theme.colors.gray[1],
        fontWeight: 500,
    },
    data: {
        fontSize: '16px',
        color: theme.colors.gray[4],
        fontWeight: 300,
    },
    toolTip: {
        transition: 'all 0.3s ease',
        color: theme.colors.gray[1],
        border: `3px solid ${theme.colors.gray[1]}`,
        zIndex: 1000,
        fontSize: '10px',
    },

    inLineStyle: {
         display: 'inline-block'
    },
    toolTipText: {
        borderBottom: `2px dotted ${theme.colors.gray[7]}`,
    },
        graphToolTip: {
        backgroundColor: theme.colors.gray[9],
        color: theme.colors.gray[0],
        padding: '20px',
        borderRadius: '10px',
        width: '225px',
        // border: `1px solid ${theme.colors.gray[5]}`,
        fontSize: '12px',
        lineHeight: '.5',
        // marginTop: '0px',
        // marginBottom: '0px',
    },
      gradientBorderBox: {
    display: 'inline-flex',
        backgroundImage: theme.fn.gradient({ from: 'pink', to: 'orange' }),
    borderRadius: '5px',
    padding: '2px', // Controls the thickness of the gradient border
    alignItems: 'center',
           marginLeft: "50px",
    justifyContent: 'center',
      width: '150px',

      '@media (max-width: 800px)': {
        marginLeft: 0,
    },

  },
  innerBox: {
    backgroundColor: theme.colors.dark[7], // Should match the page's background
    borderRadius: '5px',
      width: '150px',
    paddingLeft: "30px",
    paddingRight: "30px",
    paddingTop: "5x",
    paddingBottom: "0px",
      color: theme.colors.gray[1]
  },

    apiActivityDescriptor: {
        color: theme.colors.gray[6],
        fontWeight: 400,
    },
  apiRouteTitleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center', // This ensures vertical alignment is centered if your texts have different heights
    width: '100%', // Adjust the width as necessary
  },

    routeTitle: {
        textAlign: 'left',
        marginTop: 15,
        marginBottom: 10,
        fontSize: '1rem',
        fontWeight: 700,
        marginLeft: 60,
    display: 'inline',
        '@media (max-width: 800px)': {
        marginLeft: 0,
    },
    },
    barChart: {
        padding: 0,
        '@media (max-width: 800px)': {
            marginLeft: -30,
        },
    },
}));

interface MonthlyUsageDataItem {
  day: number;
  [key: string]: number;
}
interface ActivityTabGraphProps{
}

    const hexToRGBA = (hex: string, alpha = 1) => {
  const [r, g, b] = hex.match(/\w\w/g)!.map((x) => parseInt(x, 16));
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};


export const ActivityTabGraphs: React.FC<ActivityTabGraphProps> = () => {
    const theme = useMantineTheme();
    const { classes } = useStyles();

    const {
    monthlyUsageData,
    activityDataKeys,
    shortMonth,
  } = useUsage();


        interface TokenRequestDataKey {
      name: string;
      food: {
        token: string;
        request: string;
      };
      recipe: {
        token: string;
        request: string;
      };
    }
    const tokenRequestDataKeys: TokenRequestDataKey[] = [
        {
            "name": "Classify",
            "food": {
                "token": "food-classify-tok",
                "request": "food-classify-req"
            },
            "recipe": {
                "token": "recipes-classify-tok",
                "request": "recipes-classify-req"
            }
        },
        {
            "name": "Generate",
            "food": {
                "token": "food-generate-tok",
                "request": "food-generate-req"
            },
            "recipe": {
                "token": "recipes-generate-tok",
                "request": "recipes-generate-req"
            }
        },
        {
            "name": "Suggest",
            "food": {
                "token": "food-suggest-tok",
                "request": "food-suggest-req"
            },
            "recipe": {
                "token": "recipes-suggest-tok",
                "request": "recipes-suggest-req"
            }
        },
        {
            "name": "Chat",
            "food": {
                "token": "food-chat-tok",
                "request": "food-chat-req"
            },
            "recipe": {
                "token": "recipes-chat-tok",
                "request": "recipes-chat-req"
            }
        },
        {
            "name": "Info",
            "food": {
                "token": "food-info-tok",
                "request": "food-info-req"
            },
            "recipe": {
                "token": "recipes-info-tok",
                "request": "recipes-info-req"
            }
        },
        {
            "name": "Image",
            "food": {
                "token": "food-image-tok",
                "request": "food-image-req"
            },
            "recipe": {
                "token": "recipes-image-tok",
                "request": "recipes-image-req"
            }
        },
        {
            "name": "Search",
            "food": {
                "token": "food-search-tok",
                "request": "food-search-req"
            },
            "recipe": {
                "token": "recipes-search-tok",
                "request": "recipes-search-req"
            }
        },
    ]
        const CustomXAxisTick = (props: any) => {
      const { x, y, stroke, payload } = props;

      return (
        <g transform={`translate(${x},${y})`}>
          <text x={20} y={0} dy={10} textAnchor="end" fill={chartLineColor} fontSize={'12px'}>
            {payload.value} {shortMonth}
          </text>
        </g>
      );
    };
            const chartLineColor = theme.colorScheme === 'dark' ? theme.colors.gray[5] : theme.colors.gray[7];
    const formatKey = (key: string): string => {
      // Remove '-cost' and then split by '-' to deal with remaining parts
      const parts = key.replace('-cost', '').split('-');

      // Capitalize the first letter of each part and join them back with spaces
      const capitalizedParts = parts.map(part => part.charAt(0).toUpperCase() + part.slice(1));

      return capitalizedParts.join(' ');
    };

    const SimpleToolTip: React.FC<TooltipProps<number, string>> = ({ active, payload, label }) => {
      if (active && payload && payload.length) {
        return (
            <div className={classes.graphToolTip} style={{ width: 200}}>
                <p style={{margin: 0, marginBottom: 15}}>{`${shortMonth} ${label}`}</p>

                {payload.map((entry: any, index: number) => (
                    <div key={index} style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <div style={{
                                width: '10px',
                                height: '10px',
                                backgroundColor: entry.color,
                                marginRight: '5px',
                            }}></div>
                            <span>{formatKey(entry.name)}</span>
                        </div>
                        <span>${entry.value}</span>
                    </div>
                ))}
            </div>
        );
      }

        return null;
    };
    const toolTipHighlightColor = hexToRGBA(theme.colors.orange[0], 0.2);
    const keyColors: Record<string, string> =   {
        "food-info-cost": "#ff6f4b",
        "food-info-req": "#ff6f4b",
        "food-info-tok": "#ff6f4b",

        "food-chat-cost": "#fd4c55",
        "food-chat-req": "#fd4c55",
        "food-chat-tok": "#fd4c55",

        "food-suggest-cost": "#e13661",
        "food-suggest-req": "#e13661",
        "food-suggest-tok": "#e13661",

        "food-image-cost": "#c1246b",
        "food-image-req": "#c1246b",
        "food-image-tok": "#c1246b",

        "foods-search-cost": "#a11477",
        "foods-search-req": "#a11477",
        "foods-search-tok": "#a11477",

        "foods-generate-cost": "#624185",
        "foods-generate-req": "#624185",
        "foods-generate-tok": "#624185",

        "food-classify-cost": "#ed5784",
        "food-classify-req": "#ed5784",
        "food-classify-tok": "#ed5784",

        "recipes-chat-cost": "#fd4c55",
        "recipes-chat-req": "#fd4c55",
        "recipes-chat-tok": "#fd4c55",

        "recipes-search-cost": "#a11477",
        "recipes-search-req": "#a11477",
        "recipes-search-tok": "#a11477",

        "recipes-classify-cost": "#ed5784",
        "recipes-classify-req": "#ed5784",
        "recipes-classify-tok": "#ed5784",

        "recipes-generate-cost": "#624185",
        "recipes-generate-req": "#624185",
        "recipes-generate-tok": "#624185",

        "recipes-image-cost": "#c1246b",
        "recipes-image-req": "#c1246b",
        "recipes-image-tok": "#c1246b",

        "recipes-info-cost": "#ff6f4b",
        "recipes-info-req": "#ff6f4b",
        "recipes-info-tok": "#ff6f4b",

        "recipes-suggest-cost": "#e13661",
        "recipes-suggest-req": "#e13661",
        "recipes-suggest-tok": "#e13661"

      };


    function renderLineChart(dataKey: string) {
      return (
        <ResponsiveContainer height={200} className={classes.barChart}>
          <LineChart data={monthlyUsageData}>
            <CartesianGrid strokeDasharray="" vertical={false} stroke={chartLineColor} />
            <XAxis
              fontSize={'12px'}
              dataKey="day"
              interval={Math.floor(monthlyUsageData.length / 5) - 1}
              tick={<CustomXAxisTick />}
              stroke={chartLineColor}
            />
            <YAxis
              tickFormatter={(value) => `${value}`}
              tick={{
                fontSize: '12px',
                fill: chartLineColor
              }}
              axisLine={false}
              stroke={chartLineColor}
              tickCount={3}
            />
            <Tooltip content={<SimpleToolTip />} cursor={{ fill: toolTipHighlightColor }} />
            <Line
              type="monotone"
              dataKey={dataKey}
              stroke={keyColors[dataKey] || '#8884d8'}
              dot={false} // This removes the dots from each legacy_data point
            />
          </LineChart>
        </ResponsiveContainer>
      );
    }

  return (
      <Box>
          {tokenRequestDataKeys.map((entry, index) => (
          <Accordion key={index} defaultValue="0">
            <Accordion.Item value={`${index}`}>
              <Accordion.Control>
                <Box className={classes.gradientBorderBox}>
                  <Box className={classes.innerBox}>
                      <Center>
                        <Text size="md">{entry.name}</Text>
                      </Center>
                  </Box>
                </Box>
              </Accordion.Control>
              <Accordion.Panel>
                <Grid>
                    <Grid.Col span={12} md={6}>
                        <div className={classes.apiRouteTitleContainer}>
                            <Text size="xs" className={classes.routeTitle}>
                                Food <span
                                className={classes.apiActivityDescriptor}>API requests</span>
                            </Text>
                            <Text>
                                {activityDataKeys.find(([key, _]) => key === entry.food.request)?.[1] ?? "0"}
                            </Text>
                        </div>
                        {renderLineChart(entry.food.request)}
                        <div className={classes.apiRouteTitleContainer}>
                            <Text size="xs" className={classes.routeTitle}>
                                Food <span
                                className={classes.apiActivityDescriptor}>tokens</span>
                            </Text>
                            <Text>
                                {activityDataKeys.find(([key, _]) => key === entry.food.token)?.[1] ?? "0"}
                            </Text>
                        </div>
                        {renderLineChart(entry.food.token)}
                    </Grid.Col>
                    <Grid.Col span={12} md={6}>
                        <div className={classes.apiRouteTitleContainer}>
                            <Text size="xs" className={classes.routeTitle}>
                                Recipe <span
                                className={classes.apiActivityDescriptor}>API requests</span>
                            </Text>
                            <Text>
                                {activityDataKeys.find(([key, _]) => key === entry.recipe.request)?.[1] ?? "0"}
                            </Text>
                        </div>
                        {renderLineChart(entry.recipe.request)}

                        <div className={classes.apiRouteTitleContainer}>
                            <Text size="xs" className={classes.routeTitle}>
                                Recipe <span
                                className={classes.apiActivityDescriptor}>tokens</span>
                            </Text>
                            <Text>
                                {activityDataKeys.find(([key, _]) => key === entry.recipe.token)?.[1] ?? "0"}
                            </Text>
                        </div>
                        {renderLineChart(entry.recipe.token)}
                    </Grid.Col>
                </Grid>
              </Accordion.Panel>
            </Accordion.Item>
          </Accordion>
        ))}
      </Box>
  );
};

export default ActivityTabGraphs;
