import {Col, createStyles, Grid, Paper, Text} from '@mantine/core';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import {rem} from "@mantine/core";

const useStyles = createStyles((theme) => ({
    card: {
        position: 'relative',
        cursor: 'pointer',
        overflow: 'hidden',
        transition: 'transform 150ms ease, box-shadow 100ms ease',
        padding: theme.spacing.md,
        height: "100%",
        // height: '200px',
        // width: '90vw', // Default width for small screens
        // marginBottom: theme.spacing.md,

        '&:hover': {
            boxShadow: theme.shadows.md,
            transform: 'scale(1.02)',
        },

        '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            width: '6px',
            backgroundImage: theme.fn.linearGradient(0, theme.colors.pink[6], theme.colors.orange[6]),
        },

        '@media (min-width: 600px)': {
            // height: '500px',
            padding: theme.spacing.lg,
            // width: '45vw', // Two cards per row
        },

        '@media (min-width: 1280px)': {
            // height: '500px',
            // width: '22vw', // Four cards per row
        },
    },

    pageTitle: {
        textAlign: 'left',
        marginTop: 80,
        marginBottom: 40,
        fontSize: '2.5rem',
        marginLeft: theme.spacing.md,
        color: theme.colors.gray[2],
        '@media (min-width: 600px)': {
            marginTop: 100,
            marginBottom: 60,
            fontSize: '3.5rem',
        },

        '@media (min-width: 1280px)': {
            marginTop: 150,
            marginBottom: 80,
            fontSize: '4.5625rem',
        },
    },

    pageSubTitle: {
        textAlign: 'left',
        marginBottom: theme.spacing.md,
        fontSize: theme.fontSizes.xl,
        marginLeft: theme.spacing.md,
        color: theme.colors.gray[3],
        fontWeight: 300,
        '@media (min-width: 600px)': {
            fontSize: theme.fontSizes.xxl,
        },

        '@media (min-width: 1280px)': {
            fontSize: theme.fontSizes.xxl,
        },
    },

    pageLine: {
        width: '97%',
        height: '2px',
        backgroundColor: theme.colors.gray[7],
        marginBottom: theme.spacing.md,
        marginLeft: 'auto', // Add this line
        marginRight: 'auto', // Add this line
    },

    cardTitle: {
        textAlign: 'left',
        marginTop: theme.spacing.md,
    },

    cardDescription: {
        marginTop: "20vh",

        '@media (max-width: 991px)': {
            marginTop: "10vh"
        },
    },

    cardContainer: {
        // display: 'flex',
        // flexWrap: 'wrap',
        // justifyContent: 'space-between', // Equal space between the cards
        // alignItems: 'start',
        // maxWidth: '1800px', // Set a maximum width
        // margin: '0 auto', // Center the container
        // padding: theme.spacing.md, // Add padding to prevent cards from touching screen ;
    },
}));

interface CardGradientProps {
    title: string;
    link: string;
    description: string;
}

function CardGradient({ title, description, link }: CardGradientProps) { // Add a link prop to CardGradientProps
    const { classes } = useStyles();
    return (
        <Link to={link} style={{ textDecoration: 'none', color: 'inherit' }}> {/* Use the Link component here */}
            <Paper withBorder radius="md" className={classes.card}>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
                    <Text size="xl" weight={500} className={classes.cardTitle}>
                        {title}
                    </Text>
                    <Text size="sm" color="dimmed" className={classes.cardDescription}>
                        {description}
                    </Text>
                </div>
            </Paper>
        </Link>
    );
}

export function TermsAndPoliciesPage() {
    // assuming some legacy_data for cards
    const legalData = [
        {title: "Terms of use", link: '/policies/terms-of-use', description: "Terms when you use the products and services of NutriCloud, L.L.C. or our affiliates."},
        {title: "Privacy policy", link: '/policies/privacy-policy', description: "Practices with respect to personal information we collect from or about you."},
        {title: "Service terms", link: '/policies/service-terms', description: "Additional terms that govern your use of specific services."},
        {title: "Data processing addendum", link: '/policies/legacy_data-processing-addendum', description: "Ensuring that personal legacy_data is handled appropriately and securely."}
    ];

    const policyData = [
        {title: "Usage policies", link: '/policies/usage-policies', description: "Ensuring our technology is used for good."},
        {title: "API legacy_data usage policies", link: '/policies/api-legacy_data-usage-policies', description: "Usage and retention of legacy_data submitted by users via our API."},
        {title: "Sharing & publication policy", link: '/policies/sharing-&-publication-policy', description: "On permitted sharing, publication, and research access."},
        {title: "Coordinated vulnerability disclosure policy", link: '/policies/coordinated-vulnerability-disclosure-policy', description: "Definition of good faith in the context of finding and reporting vulnerabilities."}
    ];

    const { classes } = useStyles();
    return (
        <div style={{ paddingLeft: '40px', paddingRight: '40px', marginBottom: 100 }}>
            <Text size="xxl" className={classes.pageTitle}>Terms & Policies</Text>
            {/*legal*/}
            <div>
                <div className={classes.pageLine}></div>
                <Text size="xl" className={classes.pageSubTitle}>Legal</Text>
                <Grid className={classes.cardContainer}>
                    {legalData.map((data, index) => (
                        <Col span={12} sm={12} md={6} lg={6} xl={3}>
                            <CardGradient key={index} title={data.title} description={data.description} link={data.link} />
                        </Col>
                    ))}
                </Grid>
            </div>

        {/*  Policies  */}
            <div style={{marginTop: 80,}}>
                <div className={classes.pageLine}></div>
                <Text size="xl" className={classes.pageSubTitle}>Policies</Text>
                <Grid className={classes.cardContainer}>

                    {policyData.map((data, index) => (
                        <Col span={12} sm={12} md={6} lg={6} xl={3}>
                            <CardGradient key={index} title={data.title} description={data.description} link={data.link} />
                        </Col>
                    ))}
                </Grid>
            </div>
        </div>
    );
}
