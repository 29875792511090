import React, {useEffect, useState} from 'react';
import {
    Box,
    Text,
    useMantineTheme,
    Stack,
createStyles
} from '@mantine/core';
import {Link} from 'react-router-dom';
import {useDisclosure} from "@mantine/hooks";

const useStyles = createStyles((theme) => ({
    title: {
        textAlign: 'left',
        marginTop: 25,
        fontSize: '1.2rem',
        fontWeight: 700,
        marginBottom: 0,
        marginLeft: theme.spacing.md,
        color: theme.colors.gray[0],
        lineHeight: 'normal', // Adjust this line
    },



}));


// Add a new prop to SidebarItemProps
interface SidebarItemProps {
  label: string;
  to: string;
  isExpanded: boolean;
  isHighlighted?: boolean; // new prop to indicate permanent highlight
}

const hexToRGBA = (hex: string, alpha = 1) => {
  const [r, g, b] = hex.match(/\w\w/g)!.map((x) => parseInt(x, 16));
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};



const SidebarItem: React.FC<SidebarItemProps> = ({label, to, isExpanded, isHighlighted }) => {
    const [hover, setHover] = useState(false);
    const theme = useMantineTheme();

    // Use theme color for highlighting. Adjust the color index as needed.
    const highlightColor = hexToRGBA(theme.colors.orange[1], 0.2);

    // Determine the background color based on isHighlighted and hover state
    const backgroundColor = isHighlighted ? highlightColor :
                            hover ? hexToRGBA(theme.colors.blue[1], 0.1) : 'transparent';


    return (
        <Link
            to={to}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            style={{
                textDecoration: 'none',
                display: 'flex',
                alignItems: 'center',
                marginLeft: '10px',
                marginRight: '10px',
                marginTop: '0px',
                height: '28px',
                backgroundColor: backgroundColor, // Apply the determined background color
                borderRadius: '8px',
                transition: 'background-color 0.2s',
                padding: '0 10px',
            }}
        >
            <div style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: isExpanded ? '200px' : '0',
                transition: 'max-width 0.3s ease',
            }}>
                <Text fz={15} fw={400} color={isHighlighted ? 'white' : theme.colors.gray[1]} style={{ lineHeight: '1em' }}>
                    {label}
                </Text>
            </div>
        </Link>
    );
};


interface LinkStacksProps {
  isExpanded: boolean;
  activeItem: string;
}

function DocsLinkStacks({ isExpanded, activeItem='Overview' }: LinkStacksProps) {
  const theme = useMantineTheme();
  const { classes } = useStyles();


  return (
    <div>
        <Stack spacing="xs">
            <Text className={classes.title}>Getting Started</Text>
            {/* Top-aligned items */}
            <SidebarItem label="Overview" to="/docs/overview" isExpanded={isExpanded}
                         isHighlighted={activeItem === "overview"}/>
            {/*<SidebarItem label="Introduction" to="/docs/introduction" isExpanded={isExpanded}*/}
            {/*             isHighlighted={activeItem === "Introduction"}/>*/}
            <SidebarItem label="Quickstart" to="/docs/quickstart"
                         isExpanded={isExpanded} isHighlighted={activeItem === "quickstart"}/>
            <SidebarItem label="APIs" to="/docs/apis" isExpanded={isExpanded}
                         isHighlighted={activeItem === "apis"}/>
            {/*<SidebarItem label="Changelog" to="/docs/apis.tsx" isExpanded={isExpanded}*/}
            {/*             isHighlighted={activeItem === "usage"}/>*/}
            <SidebarItem label="Terms and policies" to="/policies" isExpanded={isExpanded}
                         isHighlighted={activeItem === "setting"}/>
        </Stack>

        {/*    Capabilities*/}
        <Stack spacing="xs">
            <Text className={classes.title}>Capabilities</Text>
            {/* Top-aligned items */}
            <SidebarItem label="Food search" to="/docs/search/food" isExpanded={isExpanded}
                         isHighlighted={activeItem === "food-search"}/>
            <SidebarItem label="Recipe search" to="/docs/search/recipe" isExpanded={isExpanded}
                         isHighlighted={activeItem === "recipe-search"}/>
            <SidebarItem label="Classification" to="/docs/classify" isExpanded={isExpanded}
                         isHighlighted={activeItem === "Classification"}/>
            <SidebarItem label="Recognition" to="/docs/recognition" isExpanded={isExpanded}
                         isHighlighted={activeItem === "recognition"}/>
            <SidebarItem label="Autocomplete" to="/docs/autocomplete" isExpanded={isExpanded}
                         isHighlighted={activeItem === "autocomplete"}/>
            <SidebarItem label="Chat" to="/docs/chat" isExpanded={isExpanded}
                         isHighlighted={activeItem === "chat"}/>
            {/*<SidebarItem label="Information" to="/docs/info" isExpanded={isExpanded}*/}
            {/*             isHighlighted={activeItem === "setting"}/>*/}
            {/*<SidebarItem label="Suggest" to="/docs/suggest" isExpanded={isExpanded}*/}
            {/*             isHighlighted={activeItem === "setting"}/>*/}
            <SidebarItem label="Embeddings" to="/docs/embeddings" isExpanded={isExpanded}
                         isHighlighted={activeItem === "embeddings"}/>
            <SidebarItem label="Recommendation" to="/docs/recommendation" isExpanded={isExpanded}
                         isHighlighted={activeItem === "recommendation"}/>
            <SidebarItem label="Users" to="/docs/users" isExpanded={isExpanded}
                         isHighlighted={activeItem === "user"}/>
            <SidebarItem label="Apps" to="/docs/apps" isExpanded={isExpanded}
                         isHighlighted={activeItem === "app"}/>

        </Stack>

        {/*    guides*/}
        <Stack spacing="xs">
            <Text className={classes.title}>Guides</Text>
            {/* Top-aligned items */}
            <SidebarItem label="Rate limits" to="/docs/guides/rate-limits" isExpanded={isExpanded}
                         isHighlighted={activeItem === "playground"}/>
            <SidebarItem label="Error codes" to="/docs/guides/error-codes" isExpanded={isExpanded}
                         isHighlighted={activeItem === "playground"}/>
            {/*<SidebarItem label="Libraries" to="/docs/guides/libraries" isExpanded={isExpanded}*/}
            {/*             isHighlighted={activeItem === "playground"}/>*/}
            {/*<SidebarItem label="Latency optimization" to="/docs/guides/latency-opti"*/}
            {/*             isExpanded={isExpanded}*/}
            {/*             isHighlighted={activeItem === "playground"}/>*/}
            <SidebarItem label="Production best practices" to="/docs/guides/production-best-practices"
                         isExpanded={isExpanded}
                         isHighlighted={activeItem === "production-best-practices"}/>
        </Stack>

        {/*    SDK*/}
        {/*<Stack spacing="xs">*/}
        {/*    <Text className={classes.title}>SDKs</Text>*/}
        {/*    /!* Top-aligned items *!/*/}
        {/*    <SidebarItem label="Python" to="/docs/overview" isExpanded={isExpanded}*/}
        {/*                 isHighlighted={activeItem === "playground"}/>*/}
        {/*    <SidebarItem label="Java" to="/docs/overview" isExpanded={isExpanded}*/}
        {/*                 isHighlighted={activeItem === "playground"}/>*/}
        {/*    <SidebarItem label="Javascript" to="/docs/overview" isExpanded={isExpanded}*/}
        {/*                 isHighlighted={activeItem === "playground"}/>*/}
        {/*</Stack>*/}
    </div>
  );
}

interface SidebarProps {
    defaultExpanded?: boolean;
    activeItem: string;
}


const DocsTOC: React.FC<SidebarProps> = ({ defaultExpanded = false, activeItem  }) => {
    const [isExpanded, setIsExpanded] = useState(defaultExpanded);
    const theme = useMantineTheme();
        const { classes } = useStyles();

  return (
    <Box
      // onMouseEnter={() => setIsExpanded(true)}
      //       onMouseLeave={handleMouseLeave} // Updated to use the handler function
      style={{
        position: 'fixed',
        left: 60,
        top: 54,
        width: isExpanded ? 230 : 60,
        height: 'calc(100vh - 54px)',
        backgroundColor: theme.colors.dark[8],
        transition: 'width 0.3s',
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column',
        zIndex: 1, borderRight: '1px solid rgba(134, 142, 150, 0.3)',
          paddingBottom: 50,
      }}
    >
        <DocsLinkStacks isExpanded={isExpanded} activeItem={activeItem}/>
    </Box>
  );
};


const DocsHamburgerTOC: React.FC<SidebarProps> = ({ defaultExpanded = false, activeItem  }) => {
    const [isExpanded, setIsExpanded] = useState(defaultExpanded);
    const theme = useMantineTheme();
        const { classes } = useStyles();

  return (
    <Box
      // onMouseEnter={() => setIsExpanded(true)}
      //       onMouseLeave={handleMouseLeave} // Updated to use the handler function
      style={{
        // position: 'fixed',
        // left: 60,
        // top: 54,
        width: isExpanded ? 230 : 60,
        // height: 'calc(100vh - 54px)',
        // backgroundColor: theme.colors.dark[8],
        transition: 'width 0.3s',
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column',
        zIndex: 1,
          // borderRight: '1px solid rgba(134, 142, 150, 0.3)',
          paddingBottom: 50,
      }}
    >
        <DocsLinkStacks isExpanded={isExpanded} activeItem={activeItem}/>
    </Box>
  );
};

export default DocsTOC;
export { DocsHamburgerTOC };
