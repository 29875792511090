import {
    Box,
    Text,
    List,
    Group,
    Paper,
    Stack,
    Title,
    useMantineTheme, Divider, createStyles
} from '@mantine/core';
import { ReactNode } from 'react';
import {useNavigate} from "react-router-dom";

interface JsonDisplayProps {
  data: Record<string, any>;
}


export const PrettyJsonDisplay = ({ data }: JsonDisplayProps) => {
    const theme = useMantineTheme();

  const renderValue = (value: any): ReactNode => {
    if (Array.isArray(value)) {
      return value.length === 0 ? (
        <Text c="gray.4">None</Text>
      ) : (
        <List spacing="xs" size="sm">
          {value.map((item, index) => (
            <List.Item key={index}>
              <Text fz={16} fw={500} c="gray.3">
                {item.toString()}
              </Text>
            </List.Item>
          ))}
        </List>
      );
    }

    if (typeof value === 'object' && value !== null) {
      return (
        <Stack spacing="xs">
          {Object.entries(value).map(([subKey, subValue]) => (
            <Group key={subKey}>
              <Text  fz={16} color={theme.colors.gray[5]} fw={700}>{subKey}:</Text>
              <Text fz={16}  color={theme.colors.gray[3]}>{subValue?.toString() ?? 'N/A'}</Text>
            </Group>
          ))}
        </Stack>
      );
    }

    return <Text fz={16}  color={theme.colors.gray[4]}>{value?.toString() ?? 'N/A'}</Text>;
  };

  return (
    <Paper p="md" withBorder>
      <Stack spacing="md">
        {Object.entries(data).map(([key, value], index) => (
          <Box key={key}>
            <Title
                color={theme.colors.gray[4]}
              order={2}
              mb="md"
              tt="capitalize"
              style={{
                borderLeft: `4px solid ${theme.colors.orange[1]}`,
                paddingLeft: '12px'
              }}
            >
              {key.replace(/_/g, ' ')}
            </Title>
            {renderValue(value)}
            {index < Object.entries(data).length - 1 && (
              <Divider my="md" />
            )}
          </Box>
        ))}
      </Stack>
    </Paper>
  );
};


export const MultiplePrettyJsonDisplay = ({ data }: JsonDisplayProps) => {
    const theme = useMantineTheme();


  const renderValue = (value: any): ReactNode => {
    if (Array.isArray(value)) {
      return value.length === 0 ? (
        <Text c="gray.4">None</Text>
      ) : (
        <List spacing="xs" size="sm">
          {value.map((item, index) => (
            <List.Item key={index}>
              <Text fz={16} fw={500} c="gray.3">
                {item.toString()}
              </Text>
            </List.Item>
          ))}
        </List>
      );
    }

    if (typeof value === 'object' && value !== null) {
      return (
        <Stack spacing="xs">
          {Object.entries(value).map(([subKey, subValue]) => (
            <Group key={subKey}>
              <Text  fz={16} color={theme.colors.gray[5]} fw={700}>{subKey}:</Text>
              <Text fz={16}  color={theme.colors.gray[3]}>{subValue?.toString() ?? 'N/A'}</Text>
            </Group>
          ))}
        </Stack>
      );
    }

    return <Text fz={16}  color={theme.colors.gray[4]}>{value?.toString() ?? 'N/A'}</Text>;
  };

  return (
    <Paper p="md" withBorder>
      <Stack spacing="md">
        {Object.entries(data).map(([title, title_values], index) => (
          <Box key={title}>
            <Title
                color={theme.colors.gray[4]}
              order={2}
              mb="md"
              tt="capitalize"
              style={{
                borderLeft: `4px solid ${theme.colors.orange[1]}`,
                paddingLeft: '12px'
              }}
            >
              {title.replace(/_/g, ' ')}
            </Title>
              {Object.entries(title_values).map(([key, value], index) => (
                  <div key={key}>
                      <Text mt={20}>{key}</Text>
                      {renderValue(value)}
                  </div>
              ))}

            {index < Object.entries(data).length - 1 && (
              <Divider my="md" />
            )}
          </Box>
        ))}
      </Stack>
    </Paper>
  );
};

export default PrettyJsonDisplay;

