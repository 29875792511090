import React from 'react';
import {
    createStyles,
    rem,
    Slider,
    TextInput,
    Text,
    SegmentedControl,
    Button,
    useMantineTheme
} from '@mantine/core';
import { Select, SelectItem } from '@mantine/core';

const useStyles = createStyles((theme) => ({
    inputMargin: {
        marginBottom: '10px',
    },
    titleSize: {
        fontSize: '24x',
        '@media (max-height: 1000px)': {
            fontSize: '16px'
        },
    }
}));

const selectorStyles = createStyles((theme) => ({
    root: {
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.white,
        boxShadow: theme.shadows.md,
        border: `${rem(1)} solid ${
            theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[1]
        }`,
    },

    indicator: {
        backgroundImage: theme.fn.gradient({ from: 'pink', to: 'orange' }),
    },

    control: {
        border: '0 !important',
    },

    label: {
        '&, &:hover': {
            '&[data-active]': {
                color: theme.white,
            },
        },
    },
}));



interface RelationalSearchParametersProps {
    onParamChange: (param: string, value: any) => void;
}

const RecipeSearchParameters: React.FC<RelationalSearchParametersProps> = ({ onParamChange }) => {
    const theme = useMantineTheme();
    const { classes } = useStyles();


        React.useEffect(() => {
        // Set default values for all parameters
        onParamChange('query', "");
        onParamChange('limit', 2);
        onParamChange('threshold', 5.0);
        onParamChange('labels', false);
        onParamChange('nutrients', false);
        onParamChange('photo', true);
    }, []);

    return (
        <div>
            {/*searchTerm*/}

            <Text className={classes.titleSize}>Query</Text>
            <TextInput
                placeholder="Oven baked asparagus"
                required
                onChange={(event) => onParamChange('query', event.target.value)}
                className={classes.inputMargin}
            />

            {/*limit*/}
            <div>
                <Text className={classes.titleSize}>Limit</Text>
                <Slider
                    defaultValue={2}
                    min={1}
                    max={10}
                    label={(value) => value.toFixed(0)}
                    onChangeEnd={(value) => onParamChange('limit', value)}
                    className={classes.inputMargin}
                />
            </div>

            {/*threshold*/}
            <div>
                <Text className={classes.titleSize}>Threshold</Text>
                <Slider
                    defaultValue={1.5}
                    min={0}
                    max={5}
                    step={0.01}
                    label={(value) => value.toFixed(2)}
                    onChangeEnd={(value) => onParamChange('threshold', value)}
                    className={classes.inputMargin}
                />
            </div>

            {/*include flags*/}
            <div>
                <Text className={classes.titleSize}>Include labels</Text>
                <SegmentedControl
                    data={[
                        {label: 'False', value: 'false'},
                        {label: 'True', value: 'true'},
                    ]}
                    radius="xl"
                    size="xs"
                    fullWidth
                    onChange={(value) => onParamChange('labels', value)}
                    className={classes.inputMargin}
                />
            </div>
            {/*include flags*/}
            <div>
                <Text className={classes.titleSize}>Include nutrients</Text>
                <SegmentedControl
                    data={[
                        {label: 'False', value: 'false'},
                        {label: 'True', value: 'true'},
                    ]}
                    radius="xl"
                    size="xs"
                    fullWidth
                    onChange={(value) => onParamChange('nutrients', value)}
                    className={classes.inputMargin}
                />
            </div>

            {/* photo*/}
            <div>
                <Text className={classes.titleSize}>Include photo</Text>
                <SegmentedControl
                    data={[
                        {label: 'False', value: 'false'},
                        {label: 'True', value: 'true'},
                    ]}
                    radius="xl"
                    defaultValue='true'
                    size="xs"
                    fullWidth
                    onChange={(value) => onParamChange('photo', value)}
                    className={classes.inputMargin}
                />
            </div>
        </div>
    );
};

export default RecipeSearchParameters;
