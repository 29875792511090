import React, { useState } from 'react';
import {createStyles, Paper, RingProgress, useMantineTheme} from '@mantine/core';
import {InputWithButton} from "../../componenets/Search/searchBar";
import {Link} from 'react-router-dom';



// Define the styles using Mantine's createStyles
const useStyles = createStyles((theme) => ({
    linkReset: {
        textDecoration: 'none',
        color: 'inherit',
        width: '100%',  // Set the width here to 80% to match your previous `resultItem` style
    },

    pageContainer: {
        width: '60%',
        maxWidth: 1800,
        margin: '0 auto',
    },
    searchContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: theme.spacing.md,
        marginBottom: theme.spacing.md,
    },
    resultsContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    resultItem: {
        position: 'relative',
        margin: theme.spacing.sm,
        padding: theme.spacing.sm,
        width: '100%',  // This might need to be 100% since it's a child of the Link component
        border: 'none',
        borderRadius: '10px',
        '&:hover': {
            '&::before': {
                content: '""',
                position: 'absolute',
                top: '-5px',
                right: '-5px',
                bottom: '-5px',
                left: '-5px',
                zIndex: -1,
                backgroundImage: 'linear-gradient(to bottom right, #FF8C00, #800080)',
                filter: 'blur(5px)',
                borderRadius: '15px',
            }
        }
    },


    inputButton: {
        width: '80%',
    },
    calorieRing: {
        display: 'flex',       // Use flexbox for centering
        justifyContent: 'center', // Horizontal centering
        alignItems: 'center',     // Vertical centering
        height: '100%',          // Take full height of parent
        fontSize: '20px',
        fontWeight: 700,
        color: theme.colors.gray[1],
        pointerEvents: 'none'
    },
    mainName: {
        fontSize: '24px',  // Adjust as needed
        fontWeight: 'bold',
        marginBottom: '0px', // Adjust space below main name
    },
    subDetails: {
        display: 'flex',
        alignItems: 'center',
        gap: '10px', // Add space between subname and serving size
        margin: '0 0 4px 0', // Bottom margin reduced to reduce space
    },
    macroSeparator: {
        height: '8px',
        width: '8px',
        borderRadius: '50%',
        backgroundColor: theme.colors.gray[5], // Change to desired color
        display: 'inline-block',
        margin: '0 8px', // Increased space around separator
        verticalAlign: 'middle', // Align to the center of the adjacent text
    },
    macroDetails: {
        margin: '4px 0 0 0', // Top margin reduced to reduce space
    }
}));

interface SearchResult {
    id: number;
    mainName: string;
    subName: string;
    serviceSize: string;
    calories: number;
    protein: number;
    fat: number;
    carbs: number;
}

export const SearchPage: React.FC = () => {
    const [query, setQuery] = useState<string>('');
    const [results, setResults] = useState<SearchResult[]>([]);
    const { classes } = useStyles();

    const fetchResults = async (query: string): Promise<SearchResult[]> => {
        // Generate some mock results based on the query
        return Array.from({ length: 50 }, (_, idx) => ({
            id: idx,
            mainName: `Main Name for ${query} - ${idx + 1}`,
            subName: `Sub Name ${idx + 1}`,
            serviceSize: '100g',
            calories: Math.floor(Math.random() * 500),
            protein: Math.floor(Math.random() * 100),
            fat: Math.floor(Math.random() * 100),
            carbs: Math.floor(Math.random() * 100),
        }));
    };


    const handleSearch = async (value: string) => {
        const newResults = await fetchResults(value);
        setResults(newResults.slice(0, 30));
    };


    const renderRingProgress = (carbs: number, protein: number, fat: number, calories: number) => {
        const totalMacros = carbs + protein + fat;

        return (
            <RingProgress
                size={120}
                thickness={11}
                label={
                    <div className={classes.calorieRing}>
                        {calories}
                    </div>
                }
                sections={[
                    {
                        value: (carbs / totalMacros) * 100,
                        color: 'orange', // Lighter Orange for Carbs
                        tooltip: `Carbs: ${carbs}g`
                    },
                    {
                        value: (protein / totalMacros) * 100,
                        color: '#ff0051', // Magenta for Protein
                        tooltip: `Protein: ${protein}g`
                    },
                    {
                        value: (fat / totalMacros) * 100,
                        color: 'purple', // Light Purple for Fat
                        tooltip: `Fat: ${fat}g`
                    },
                ]}
            />
        );
    };


    const SearchCallToAction = () => (
        <div>
            Please enter a search term to find a food item.
        </div>
    );






    return (
        <div className={classes.pageContainer}>
            <div className={classes.searchContainer}>
                <InputWithButton
                    className={classes.inputButton}
                    value={query}
                    onChange={(event) => setQuery(event.currentTarget.value)}
                    onEnter={() => handleSearch(query)}
                    placeholder="Search for a food item"
                />
            </div>

            <div className={classes.resultsContainer}>
                {results.length === 0 && !query && <SearchCallToAction />}

                {results.map((result, index) => (
                    <Link
                        to={`/yourTargetPath/${result.id}`}
                        className={classes.linkReset}
                        key={result.id}
                    >                    <Paper
                        className={classes.resultItem}
                        key={result.id}
                        radius='md'
                    >
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div>
                                <div className={classes.mainName}>{result.mainName}</div>
                                <div className={classes.subDetails}>
                                    <span>{result.subName}</span>
                                    <span>Service Size: {result.serviceSize}</span>
                                </div>
                                <p className={classes.macroDetails}>
                                    Calories: {result.calories}
                                    <span className={classes.macroSeparator}></span>
                                    Protein: {result.protein}g
                                    <span className={classes.macroSeparator}></span>
                                    Fat: {result.fat}g
                                    <span className={classes.macroSeparator}></span>
                                    Carbs: {result.carbs}g
                                </p>
                            </div>
                            <div>
                                {renderRingProgress(result.carbs, result.protein, result.fat, result.calories)}
                            </div>
                        </div>
                    </Paper>
                        </Link>
                ))}
            </div>
        </div>
    );
};
