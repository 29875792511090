export const classifyApiPricingData = [
  //   food
  { key: 'food_classify', endpoint: '/food/classify/allergy', tokens: 15},
  { key: 'food_classify', endpoint: '/food/classify/diet', tokens: 15},
  { key: 'food_classify', endpoint: '/food/classify/branded', tokens: 15},
  { key: 'food_classify', endpoint: '/food/classify/', tokens: 15},

  //   recipe
  { key: 'recipe_classify', endpoint: '/recipe/classify/allergy', tokens: 15},
  { key: 'recipe_classify', endpoint: '/recipe/classify/diet', tokens: 15},
  { key: 'recipe_classify', endpoint: '/recipe/classify/cuisine', tokens: 15},
  { key: 'recipe_classify', endpoint: '/recipe/classify/meal', tokens: 15},
  { key: 'recipe_classify', endpoint: '/recipe/classify/course', tokens: 15},
];

export const infoApiPricingData = [
      // food
      { key: 'food-info', endpoint: '/food/info/seasonality', tokens: 10},
      { key: 'food-info', endpoint: '/food/info/locality', tokens: 10},
      { key: 'food-info', endpoint: '/food/info/history', tokens: 25},
//     recipe
    { key: 'recipe-info', endpoint: '/recipe/info/locality', tokens: 10},
    { key: 'recipe-info', endpoint: '/recipe/info/seasonality', tokens: 10},
    { key: 'recipe-info', endpoint: '/recipe/info/history', tokens: 25},
    { key: 'recipe-info', endpoint: '/recipe/info/equipment', tokens: 25},
    { key: 'recipe-info', endpoint: '/recipe/info/required-ingredients', tokens: 25},
]

export const chatApiPricingData = [
    // food
    { key: 'food-chat', endpoint: '/food/chat', tokens: 25},
//     recipe
    { key: 'recipe-chat', endpoint: '/recipe/chat', tokens: 25},
//     all api's
    { key: 'chat', endpoint: '/chat', tokens: 25},
]

export const suggestApiPricingData = [
    // food
    { key: 'food-suggest', endpoint: '/food/suggest/substitute', tokens: 20},
    { key: 'food-suggest', endpoint: '/food/suggest/recipe', tokens: 20},
    { key: 'food-suggest', endpoint: '/food/suggest/base-ingredient', tokens: 20},
//     recipe
    { key: 'recipe-suggest', endpoint: '/recipe/suggest/from-ingredients', tokens: 20},
    { key: 'recipe-suggest', endpoint: '/recipe/suggest/substitutions', tokens: 20},
    { key: 'recipe-suggest', endpoint: '/recipe/suggest/recipe', tokens: 20},
]


export const imageApiPricingData = [
    // food
    { key: 'food-image', endpoint: '/food/image/recognition', tokens: 50},
    { key: 'food-image', endpoint: '/food/image/real', tokens: 50},
    { key: 'food-image', endpoint: '/food/image/cartoon', tokens: 50},
//     recipe
        { key: 'recipe-image', endpoint: '/recipe/image/', tokens: 50},
        { key: 'recipe-image', endpoint: '/recipe/image/recognition', tokens: 50},
        { key: 'recipe-image', endpoint: '/recipe/image/create-recipe', tokens: 50},
        { key: 'recipe-image', endpoint: '/recipe/image/generate', tokens: 50},
        { key: 'recipe-image', endpoint: '/recipe/image/ingredients-in-fridge', tokens: 50},
]

export const searchApiPricingData = [
    // food
    { key: 'food-search', endpoint: '/food/search/', tokens: 15},
    { key: 'food-search', endpoint: '/food/search/autocomplete', tokens: 12},
    { key: 'food-search', endpoint: '/food/search/nutrition', tokens: 20},
//     recipe
    { key: 'recipe-search', endpoint: '/recipe/search/', tokens: 15},
    { key: 'recipe-search', endpoint: '/recipe/search/autocomplete', tokens: 12},
    { key: 'recipe-search', endpoint: '/recipe/search/nutrition', tokens: 20},
]

export const generateApiPricingData = [
//     recipe
    { key: 'recipe-generate', endpoint: '/recipe/generate/', tokens: 65},
    { key: 'recipe-generate', endpoint: '/recipe/generate/ingredients', tokens: 30},
    { key: 'recipe-generate', endpoint: '/recipe/generate/instructions', tokens: 30},
    { key: 'recipe-generate', endpoint: '/recipe/generate/tips', tokens: 25},
//     food
    { key: 'food-generate', endpoint: '/food/generate/cutting-instructions', tokens: 25},
]

export const userApiPricingData = [
    { key: 'user', endpoint: '/user/like-recipe', tokens: 8 },
    { key: 'user', endpoint: '/user/dislike-recipe', tokens: 8 },
    { key: 'user', endpoint: '/user/liked-recipes', tokens: 10 },
    { key: 'user', endpoint: '/user/disliked-recipes', tokens: 10 },
    { key: 'user', endpoint: '/user/recipe-interactions', tokens: 12 },
];

export const verifyApiPricingData = [
    { key: 'verify', endpoint: '/verify/send-phone-verification-code', tokens: 12 },
    { key: 'verify', endpoint: '/verify/verify-phone-number', tokens: 15 },
    { key: 'verify', endpoint: '/verify/email', tokens: 12 },
];

export const appApiPricingData = [
    { key: 'apps', endpoint: '/apps', tokens: 10 },
    { key: 'apps-rate-limit-tier', endpoint: '/apps/rate-limit-tier', tokens: 12 },
];

export const authApiPricingData = [
    { key: 'auth', endpoint: '/auth/dev/register', tokens: 20 },
    { key: 'auth', endpoint: '/auth/app/register', tokens: 20 },
    { key: 'auth', endpoint: '/auth/app/user', tokens: 10 },
    { key: 'auth', endpoint: '/auth/app/login', tokens: 20 },
];



