import React, {FC, useState} from 'react';
import {
    Button,
    Container,
    Text,
    Title,
    createStyles,
    TextInput,
    useMantineTheme, Grid, Col,

} from '@mantine/core';



import LandingPageTypewriteText from './typewriterText';
import {
    Header,
    HoverCard,
    Group,
    UnstyledButton,
    SimpleGrid,
    ThemeIcon,
    Anchor,
    Divider,
    Center,
    Box,
    Burger,
    Drawer,
    Collapse,
    ScrollArea,
    rem,
} from '@mantine/core';
import {useNavigate} from "react-router-dom";

import {
    ChevronsRight,
    Circle,
    Cloud,
    Code,
    Codesandbox,
    Cpu,
    Package,
    Star
} from 'react-feather';
import ScrollRevealIcon from "./components/bloomIcon";
import ScrollRevealGradientLine from "./components/coloredLine";
import ServiceTabs from "./components/serviceTabs/serviceTabs";
import ThreeDContainer from "./components/threeDContainer";
import ServicePickerTop from "./components/servicePicker/servicePickerUpper";
import ServicePickerMiddle from "./components/servicePicker/servicePickerLower";
import SDKSection from "./components/sdk/sdkSection";
import ModelSection from "./components/fine-tune/modelSection";
import EmbeddingsService from "./components/fine-tune/modelSection";
import DevelopementSection from "./components/developement/developement";
import ScrollRevealIconChildren from "./components/bloomIconChildren";
import {useMediaQuery} from "@mantine/hooks";


const useStyles = createStyles((theme) => ({
  heroSection: {
    paddingTop: theme.spacing.xl,
    paddingBottom: theme.spacing.xl,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center', // Centers the title block vertically
    textAlign: 'left',
      maxWidth: "1400px",
      [`@media (max-width: 750px)`]: {
      maxWidth: "100vw",
    },
  },

  title: {
    lineHeight: '1', // Reduces the line height to be closer to the text size
      marginLeft: 40,
      width: "100%",
  },

    titleContainer: {
    //       display: 'flex', // Enables flexbox
    // flexDirection: 'row', // Aligns children in a row
    //     //align at the top
    // alignItems: 'flex-start', // Centers items vertically in the container
    // justifyContent: 'left', // Centers items horizontally in the container
    // gap: theme.spacing.lg, // Adds some space between items
    //     marginTop: theme.spacing.md,
        marginLeft: 30,
    },

  textRow: {
    margin: 0,
    padding: 0,
      lineHeight: '1',
      fontWeight: 600,
      fontSize: "5rem",
    [`@media (max-width: 1300px)`]: {
      fontSize: "3.5rem",
    },
      [`@media (max-width: 750px)`]: {
      fontSize: "2.5rem",
    },
  },

  smallText: {
    fontSize: '24px',
      [`@media (max-width: 750px)`]: {
      fontSize: "18px",
    },
    color: theme.colors.gray[3],
      marginTop: theme.spacing.md,
    lineHeight: '1.1', // Adjust line height to manage space between the text rows
  },

  buttonsGroup: {
    marginTop: theme.spacing.lg,
    display: 'flex',
    justifyContent: 'center',
    gap: theme.spacing.md,
  },

//     glow
    glowHeader: {
    display: 'flex', // Enables flexbox
    flexDirection: 'row', // Aligns children in a row
        //align at the top
    alignItems: 'center', // Centers items vertically in the container
    justifyContent: 'left', // Centers items horizontally in the container
    gap: theme.spacing.lg, // Adds some space between items
        marginTop: theme.spacing.md,
        marginBottom: theme.spacing.md,
        marginLeft: 20,

  },
    glowTitle: {
      color: theme.colors.gray[1],
        fontSize: '1.75rem',
        fontWeight: 500,
    },
    glowTitleText: {
        fontSize: '3rem',
        fontWeight: 500,
        maxWidth: "60vw",
        lineHeight: '1.1',
        marginLeft: 55,
            [`@media (max-width: 1300px)`]: {
      fontSize: "2.5rem",
    },
        [`@media (max-width: 750px)`]: {
      fontSize: "2rem",
            maxWidth: "80vw"
    },
    },
//     line
    scrollRevealGradientLine: {
      // marginLeft: 40
    },
//     image
    heroImage: {
  maxWidth: '100%', // Ensures the image is never larger than its container
  maxHeight: '80vh', // Keeps the image aspect ratio intact
        marginLeft: "auto",
},
heroImageSection: {
  position: 'relative', // Keeps the positioning context for the div
  height: '100%', // Adjust as needed to fit your layout
  width: '100%', // Adjust as needed
  backgroundRepeat: 'no-repeat', // Applies to the image
  backgroundSize: '550px, cover', // First for the image, second for the gradient

  backgroundPosition: 'right 0vw top -17vh, center center', // First for the image, second for the gradient

  backgroundImage: `url('${process.env.PUBLIC_URL}/landingPage/heroImage.png')`,
    [`@media (max-width: 1600px)`]: {
      backgroundSize: '500px, cover',
        backgroundPosition: 'right 0vw top -10vh, center center',
    },
    [`@media (max-width: 750px)`]: {
      // backgroundImage: 'none',
        backgroundPosition: 'right 0vw top -10vh, center center', // First for the image, second for the gradient
    },
        [`@media (max-width: 550px)`]: {
      // backgroundImage: 'none',
        backgroundPosition: 'right -30vw top -10vh, center center', // First for the image, second for the gradient
    },
    [`@media (max-width: 400px)`]: {
      // backgroundImage: 'none',
        backgroundPosition: 'right -50vw top -10vh, center center', // First for the image, second for the gradient
    },
},
    bottomGradient: {
  position: 'absolute',
  top: 290,
  right: 0,
  width: '90%',
  height: '50%', // Adjust the height to control the size of the fading effect
  backgroundImage: `linear-gradient(to top, ${theme.colors.dark[7]} 20%, rgba(0, 0, 0, 0) 100%)`,
        [`@media (max-width: 1000px)`]: {
    },
        [`@media (max-width: 750px)`]: {
     top: 255,
    },
  pointerEvents: 'none', // This ensures that the gradient doesn't interfere with mouse events on elements below it.
},

topGradient: {
  position: 'absolute',
  top: 0,
  right: 0,
  width: '90%',
  height: '20%', // Adjust the height to control the size of the fading effect
  backgroundImage: `linear-gradient(to bottom, ${theme.colors.dark[7]} 0%, rgba(0, 0, 0, 0) 100%)`,
        [`@media (max-width: 1100px)`]: {
      height: '100%',
    },
    [`@media (max-width: 750px)`]: {
      // backgroundImage: 'none'
    },
  pointerEvents: 'none', // This ensures that the gradient doesn't interfere with mouse events on elements below it.
},

    leftToRightGradient: {
  position: 'absolute',
  top: 0,
  right: 0,
  width: '100%',
  height: '100%', // This covers the full height of the container
  backgroundImage: `linear-gradient(to right, ${theme.colors.dark[7]} 0%, rgba(0, 0, 0, 0) 40%)`,
  pointerEvents: 'none', // Ensures that the gradient doesn't interfere with mouse events on elements below it.
        zIndex: -1,

},
    emailInput: {
      [`@media (min-width: 767px)`]: {
          minWidth: 350,
    },
        [`@media (max-width: 767px)`]: {
          marginTop: 70
    },
      },
    emailSubmit: {
      marginLeft: -30,
      [`@media (max-width: 767px)`]: {
          marginLeft: 0,
      width:  "100%",
    },
    },

    shortLinearGradient: {
      height: 200,
        [`@media (max-width: 767px)`]: {
          height: 100,
      width:  "100%",
    }}


}));

const LandingPageNew: FC = () => {
    const theme = useMantineTheme();
    const navigate = useNavigate();

  //   large width
    const largeScreen = useMediaQuery('(min-width: 1200px)');

  //   email
    const [email, setEmail] = useState('');
    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
    };
    const handleButtonClick = () => {
        // Redirect to the signup page with email as a query parameter
        navigate(`/signup?email=${encodeURIComponent(email)}`);
    };

  //   colors
    const ServicesColor = "#994fb2";

  //   colored line guide
    const MarginLeft = 40;

  const { classes } = useStyles();

    return (
        <Container size={"100vw"} p={0} className={classes.heroSection} mb={100}>
            {/*title*/}
            <div className={`${classes.heroImageSection}`}>
                <div className={classes.topGradient}></div>
                <div className={classes.bottomGradient}></div>
                {/*<div className={classes.leftToRightGradient}></div>*/}

                <div className={classes.titleContainer} style={{zIndex: 1000}}>
                    <div style={{height: "100%", paddingBottom: 0, paddingTop: 80}}>
                        <Circle style={{marginLeft: -4, marginBottom: -15}}
                                color={theme.colors.gray[5]} size={12}></Circle>
                        <ScrollRevealGradientLine colorStart="rgba(251, 177, 73, 0)"
                                                  colorMiddle="#fbb149"
                                                  colorEnd="#fbb149"
                                                  leftContent={
                                                      <div className={classes.title}>
                                                          <Text
                                                              className={classes.textRow}>Food
                                                              APIs made easy for</Text>
                                                          <div
                                                              className={classes.textRow}>
                                                              <LandingPageTypewriteText/>
                                                          </div>
                                                          <Text mb={20}
                                                                className={classes.smallText}>The
                                                              world’s leading AI-powered
                                                              food
                                                              developer platform.</Text>
                                                      </div>
                                                  }
                        />
                    </div>
                </div>

                {/*email input for sign up*/}
                <div className={classes.glowHeader} style={{maxHeight: 50}}>
                    <ScrollRevealIcon
                        bloomColor="#FFFFFF"
                        IconComponent={Code}
                        string={""}
                    />
                    <Grid ml={-50} pr={20}>
                        <Col span={12} sm={8}>
                            <TextInput
                                type="email"
                                placeholder="Email Address"
                                value={email}
                                onChange={handleEmailChange}
                                className={classes.emailInput}
                                required
                                radius="xs"
                                size="lg"
                                onKeyDown={(event) => {
                                    if (event.key === 'Enter') {
                                        handleButtonClick(); // Call the same function as the button click
                                    }
                                }}
                            />
                        </Col>
                        <Col span={12} sm={4}>
                            <Button
                                variant="filled"
                                style={{
                                    color: theme.colors.gray[1],
                                    border: 'none',
                                }}
                                className={classes.emailSubmit}
                                size="lg"
                                onClick={handleButtonClick}
                                bg={theme.colors.orange[1]}
                                radius="xs"
                                p={10}
                                fz={20}
                                fw={800}
                            >
                                Sign up for NutriCloud
                            </Button>
                        </Col>
                    </Grid>
                </div>

                {/*companies we work with*/}
                <div className={classes.titleContainer}>
                    <ScrollRevealGradientLine colorStart="rgba(251, 177, 73)"
                                              colorMiddle={theme.colors.orange[3]}
                                              colorEnd={ServicesColor}
                                              leftContent={
                                                  <div style={{height: "15vw"}}></div>
                                              }
                    />

                </div>
            </div>


            {/*services provided*/}
            <div className={classes.glowHeader}>
                <ScrollRevealIcon
                    bloomColor="#a249b6"
                    IconComponent={Package}
                    string={"Services"}
                />
                {/*<Text className={classes.glowTitle}>Services</Text>*/}
            </div>
            <div className={classes.titleContainer}
                 style={{
                     // height: 275,
                     // marginBottom: -300
                 }}
            >
                <ScrollRevealGradientLine colorStart={ServicesColor}
                                          colorMiddle={ServicesColor}
                                          colorEnd="rgba(251, 177, 73, 0)"
                                          leftContent={
                                              <div>
                                                  <Text
                                                      className={classes.glowTitleText}
                                                      style={{color: ServicesColor}}>Supercharge
                                                      your app</Text>
                                                  <Text mb={100}
                                                        className={classes.glowTitleText}>with
                                                      AI solutions that unlock new user
                                                      experiences and insights</Text>
                                              </div>
                                          }
                />
            </div>
            <div className={classes.titleContainer}
                 style={{position: 'relative'}}>
                {largeScreen && (
                <img src={`${process.env.PUBLIC_URL}/landingPage/kiwi-outline.png`}
                     style={{
                         position: 'absolute',
                         right: '50px', // Adjust for overflow effect
                         top: '100%',
                         transform: 'translateY(-50%)',
                         width: '600px', // Adjust as needed
                         zIndex: -1, // Send to back
                     }} alt="Background"/>
                )}
                {/* Your content here */}
            </div>

            <div style={{
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
            }}>
                <ServiceTabs></ServiceTabs>
            </div>
            {/*AI tech picker*/}
            <div className={classes.titleContainer}>
                <ScrollRevealGradientLine colorStart="rgb(162, 73, 182, 0)"
                                          colorMiddle="#a249b6"
                                          colorEnd="#D32122"
                                          leftContent={
                                              <ServicePickerTop></ServicePickerTop>
                                          }
                />
            </div>

            {/*services provided*/}
            <div className={classes.glowHeader}>
                <ScrollRevealIcon
                    bloomColor="#D32122"
                    IconComponent={Codesandbox}
                    string={"SDKs"}
                />
                {/*<Text className={classes.glowTitle}>Services</Text>*/}
            </div>
            {/*SDKs*/}

            <div className={classes.titleContainer}>
                <ScrollRevealGradientLine colorStart="#D32122"
                                          colorMiddle="#D32122"
                                          colorEnd="rgb(211, 33, 34, 0)"
                                          leftContent={
                                              <div>
                                                  <Text
                                                      className={classes.glowTitleText}
                                                      style={{color: "#D32122"}}>Streamline
                                                      Development</Text>
                                                  <Text mb={100}
                                                        className={classes.glowTitleText}>With
                                                      NutriCloud, you can develop in
                                                      days
                                                      not months</Text>
                                              </div>
                                          }
                />
            </div>
            <div className={classes.titleContainer}
                 style={{position: 'relative'}}>
                {largeScreen && (
                <img src={`${process.env.PUBLIC_URL}/landingPage/lettuce-outline.png`}
                     style={{
                         position: 'absolute',
                         right: '50px', // Adjust for overflow effect
                         top: '80%',
                         transform: 'translateY(-50%)',
                         width: '600px', // Adjust as needed
                         zIndex: -1, // Send to back
                     }} alt="Background"/>
                )}
                {/* Your content here */}
            </div>

            <div>
                <SDKSection></SDKSection>
            </div>


            {/*fine tuned models*/}
            <div className={classes.glowHeader}>
                <ScrollRevealIcon
                    bloomColor="#3fb950"
                    IconComponent={Cpu}
                    string={"Models"}
                />
                {/*<Text className={classes.glowTitle}>Services</Text>*/}
            </div>
            <div className={classes.titleContainer}>
                <ScrollRevealGradientLine colorStart="#3fb950"
                                          colorMiddle="#3fb950"
                                          colorEnd="rgb(63, 185, 80, 0)"
                                          leftContent={
                                              <div>
                                                  <Text
                                                      className={classes.glowTitleText}
                                                      style={{color: "#3fb950"}}>Fine-Tuned
                                                      Models</Text>
                                                  <Text mb={100}
                                                        className={classes.glowTitleText}>for
                                                      Cutting-Edge Applications</Text>
                                              </div>
                                          }
                />
            </div>
            <div className={classes.titleContainer}
                 style={{position: 'relative'}}>
                {largeScreen && (
                <img src={`${process.env.PUBLIC_URL}/landingPage/bean-outline.png`}
                     style={{
                         position: 'absolute',
                         right: '50px', // Adjust for overflow effect
                         top: '100%',
                         transform: 'translateY(-50%)',
                         width: '600px', // Adjust as needed
                         zIndex: -1, // Send to back
                     }} alt="Background"/>
                )}
                {/* Your content here */}
            </div>
            <div style={{height: "auto"}}>
                <EmbeddingsService></EmbeddingsService>
            </div>
            <div className={classes.titleContainer}>
                <ScrollRevealGradientLine colorStart="rgb(63, 185, 80, 0)"
                                          colorMiddle="#3fb950"
                                          colorEnd="#28AFB0"
                                          leftContent={
                                              <div className={classes.shortLinearGradient}>
                                              </div>
                                          }
                />
            </div>
            <div className={classes.titleContainer}
                 style={{position: 'relative'}}>
    {largeScreen && (
      <img
        src={`${process.env.PUBLIC_URL}/landingPage/lemon-outline.png`}
        style={{
          position: 'absolute',
          right: '50px',
          top: '30%',
          transform: 'translateY(0%)',
          width: '600px',
          zIndex: -1,
        }}
        alt="Background"
      />
    )}

            </div>

            {/* developement speed */}

            <div className={classes.glowHeader}>
                <ScrollRevealIcon
                    bloomColor="#28AFB0"
                    IconComponent={Cpu}
                    string={"Development"}
                />
            </div>
            <div className={classes.titleContainer}>
                <ScrollRevealGradientLine colorStart="#28AFB0"
                                          colorMiddle="#28AFB0"
                                          colorEnd="rgb(63, 185, 80, 0)"
                                          leftContent={
                                              <div>
                                                  <Text
                                                      className={classes.glowTitleText}
                                                      style={{color: "#28AFB0"}}>Simplify
                                                      food app development</Text>
                                                  <Text mb={100}
                                                        className={classes.glowTitleText}> and
                                                      reach the market faster</Text>
                                              </div>
                                          }
                />
            </div>

            <div style={{height: "auto"}}>
                <DevelopementSection></DevelopementSection>
            </div>

            {/*Final call to action*/}
            <div className={classes.glowHeader} style={{maxHeight: 50}}>
                <ScrollRevealIcon
                    bloomColor="#FFFFFF"
                    IconComponent={Code}
                    string={""}
                />
                <Grid ml={-50} pr={20}>
                    <Col span={12} sm={8}>
                        <TextInput
                            type="email"
                            placeholder="Email Address"
                            value={email}
                            onChange={handleEmailChange}
                            className={classes.emailInput}
                            required
                            radius="xs"
                            size="lg"
                            onKeyDown={(event) => {
                                if (event.key === 'Enter') {
                                    handleButtonClick(); // Call the same function as the button click
                                }
                            }}
                        />
                    </Col>
                    <Col span={12} sm={4}>
                        <Button
                            variant="filled"
                            style={{
                                color: theme.colors.gray[1],
                                border: 'none',
                            }}
                            className={classes.emailSubmit}
                            size="lg"
                            onClick={handleButtonClick}
                            bg={"#28AFB0"}
                            radius="xs"
                            p={10}
                            fz={20}
                            fw={800}
                        >
                            Sign up for NutriCloud
                        </Button>
                    </Col>
                </Grid>
            </div>

        </Container>
    );
}

export default LandingPageNew;
