import React, {useEffect, useState} from 'react';
import {
    Paper,
    Progress,
    Text,
    Badge,
    Box,
    Grid,
    Divider,
    useMantineTheme, createStyles,
    Tooltip as MantineTooltip, Group, Stack, Button, Loader, Center,
} from '@mantine/core';
import {
    Bar,
    BarChart,
    CartesianGrid,
    ResponsiveContainer,
    Tooltip, TooltipProps,
    XAxis,
    YAxis
} from "recharts";
import {useUsage} from "../usageContext";

const useStyles = createStyles((theme) => ({
    title: {
        fontSize: '12px',
        color: theme.colors.gray[1],
        fontWeight: 500,
    },
    data: {
        fontSize: '16px',
        color: theme.colors.gray[4],
        fontWeight: 300,
    },
    toolTip: {
        transition: 'all 0.3s ease',
        color: theme.colors.gray[1],
        border: `3px solid ${theme.colors.gray[1]}`,
        zIndex: 1000,
        fontSize: '10px',
    },

    inLineStyle: {
         display: 'inline-block'
    },
    toolTipText: {
        borderBottom: `2px dotted ${theme.colors.gray[7]}`,
    },
        graphToolTip: {
        backgroundColor: theme.colors.gray[9],
        color: theme.colors.gray[0],
        padding: '20px',
        borderRadius: '10px',
        width: '225px',
        // border: `1px solid ${theme.colors.gray[5]}`,
        fontSize: '12px',
        lineHeight: '.5',
        // marginTop: '0px',
        // marginBottom: '0px',
    },
}));

interface MonthlyUsageDataItem {
  day: number;
  [key: string]: number;
}
interface MonthlySpendGraphProps{
}

export const MonthlySpendGraphs: React.FC<MonthlySpendGraphProps> = () => {
    const theme = useMantineTheme();
    const { classes } = useStyles();

    const {
        monthlyUsageData,
        costDataKeys,
        totalSpend,
        shortMonth,
      } = useUsage();

    // chart stuff
    const hexToRGBA = (hex: string, alpha = 1) => {
      const [r, g, b] = hex.match(/\w\w/g)!.map((x) => parseInt(x, 16));
      return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    };
    const chartLineColor = theme.colorScheme === 'dark' ? theme.colors.gray[5] : theme.colors.gray[7];
    const toolTipHighlightColor = hexToRGBA(theme.colors.orange[0], 0.2);
    const CustomTooltip: React.FC<TooltipProps<number, string>> = ({ active, payload, label }) => {
      if (active && payload && payload.length) {
        const entry = payload[0];
    const costKeys = Object.keys(entry.payload).filter(key =>
      key.endsWith('cost') &&
      entry.payload[key] > 0 &&
      key !== "total-cost" // Exclude "total-cost" from being displayed
    );

        return (
          <div className={classes.graphToolTip}>
            <p>{`${shortMonth} ${label}`}</p>
            {costKeys.map((key) => {
              let color = '#8884d8'; // Default color
                if (key === "food-info-cost") color = '#82CA9D';
                if (key === "food-chat-cost") color = '#FFC658';
                if (key === "food-suggest-cost") color = '#FF7300';
                if (key === "food-image-cost") color = '#E25822';
                if (key === "foods-search-cost") color = '#F7977A';
                if (key === "food-classify-cost") color = '#C26DBC';

                if (key === "recipes-chat-cost") color = '#7030A0';
                if (key === "recipes-classify-cost") color = '#0078D7';
                if (key === "recipes-generate-cost") color = '#00B0F0';
                if (key === "recipes-image-cost") color = '#00B2EE';
                if (key === "recipes-info-cost") color = '#009688';
                if (key === "recipes-suggest-cost") color = '#40514E';

              const displayName = key.replace('-cost', '').replace(/-/g, ' ');
              return (
                <div key={key} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{
                      width: '10px',
                      height: '10px',
                      backgroundColor: color,
                      marginRight: '5px',
                    }}></div>
                    <span style={{ marginRight: 'auto'}}>{`${displayName.charAt(0).toUpperCase() + displayName.slice(1)}`}</span>
                  </div>
                  <span>{`$${entry.payload[key]}`}</span>
                </div>
              );
            })}
          </div>
        );
      }

      return null;
    };
    const CustomXAxisTick = (props: any) => {
      const { x, y, stroke, payload } = props;

      return (
        <g transform={`translate(${x},${y})`}>
          <text x={20} y={0} dy={10} textAnchor="end" fill={chartLineColor} fontSize={'12px'}>
            {payload.value} {shortMonth}
          </text>
        </g>
      );
    };
    const SimpleToolTip: React.FC<TooltipProps<number, string>> = ({ active, payload, label }) => {
      if (active && payload && payload.length) {
        return (
            <div className={classes.graphToolTip} style={{ width: 200}}>
                <p style={{margin: 0, marginBottom: 15}}>{`${shortMonth} ${label}`}</p>

                {payload.map((entry: any, index: number) => (
                    <div key={index} style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <div style={{
                                width: '10px',
                                height: '10px',
                                backgroundColor: entry.color,
                                marginRight: '5px',
                            }}></div>
                            <span>{formatKey(entry.name)}</span>
                        </div>
                        <span>${entry.value}</span>
                    </div>
                ))}
            </div>
        );
      }

        return null;
    };
    const formatKey = (key: string): string => {
      // Remove '-cost' and then split by '-' to deal with remaining parts
      const parts = key.replace('-cost', '').split('-');

      // Capitalize the first letter of each part and join them back with spaces
      const capitalizedParts = parts.map(part => part.charAt(0).toUpperCase() + part.slice(1));

      return capitalizedParts.join(' ');
    };
    const keyColors: Record<string, string> =   {
        "food-info-cost": "#ff6f4b",
        "food-info-req": "#ff6f4b",
        "food-info-tok": "#ff6f4b",

        "food-chat-cost": "#fd4c55",
        "food-chat-req": "#fd4c55",
        "food-chat-tok": "#fd4c55",

        "food-suggest-cost": "#e13661",
        "food-suggest-req": "#e13661",
        "food-suggest-tok": "#e13661",

        "food-image-cost": "#c1246b",
        "food-image-req": "#c1246b",
        "food-image-tok": "#c1246b",

        "foods-search-cost": "#a11477",
        "foods-search-req": "#a11477",
        "foods-search-tok": "#a11477",

        "foods-generate-cost": "#624185",
        "foods-generate-req": "#624185",
        "foods-generate-tok": "#624185",

        "food-classify-cost": "#ed5784",
        "food-classify-req": "#ed5784",
        "food-classify-tok": "#ed5784",

        "recipes-chat-cost": "#fd4c55",
        "recipes-chat-req": "#fd4c55",
        "recipes-chat-tok": "#fd4c55",

        "recipes-search-cost": "#a11477",
        "recipes-search-req": "#a11477",
        "recipes-search-tok": "#a11477",

        "recipes-classify-cost": "#ed5784",
        "recipes-classify-req": "#ed5784",
        "recipes-classify-tok": "#ed5784",

        "recipes-generate-cost": "#624185",
        "recipes-generate-req": "#624185",
        "recipes-generate-tok": "#624185",

        "recipes-image-cost": "#c1246b",
        "recipes-image-req": "#c1246b",
        "recipes-image-tok": "#c1246b",

        "recipes-info-cost": "#ff6f4b",
        "recipes-info-req": "#ff6f4b",
        "recipes-info-tok": "#ff6f4b",

        "recipes-suggest-cost": "#e13661",
        "recipes-suggest-req": "#e13661",
        "recipes-suggest-tok": "#e13661"

      };

  return (
      <Box mr={20}>
          <Stack spacing="xs">
              {/*TOTAL USAGE*/}
              <div style={{marginTop: '10px'}}></div>
                  <div>
                      <div style={{
                          marginLeft: '20px',
                          marginBottom: '10px',
                          fontSize: '18px'
                      }}>
                          <p style={{
                              display: 'inline',
                              marginRight: '0px',
                              color: theme.colors.gray[1],
                              fontWeight: '700',
                          }}>Monthly Spend </p>
                          <p style={{
                              display: 'inline',
                              color: theme.colors.gray[4],
                          }}>${totalSpend}</p>
                      </div>
                      <ResponsiveContainer width="100%" height={300}>
                          <BarChart data={monthlyUsageData} style={{marginLeft: -20}}>
                              <CartesianGrid strokeDasharray=""
                                             vertical={false}
                                             stroke={chartLineColor}/>
                              <XAxis
                                  fontSize={'12px'}
                                  dataKey="day"
                                  interval={Math.floor(monthlyUsageData.length / 5) - 1}
                                  tick={<CustomXAxisTick/>}
                                  stroke={chartLineColor}
                              />
                              <YAxis
                                  tickFormatter={(value) => `$${value}`}
                                  tick={{
                                      fontSize: '12px',
                                      fill: chartLineColor
                                  }}
                                  axisLine={false}
                                  stroke={chartLineColor}
                              />
                              <Tooltip content={<CustomTooltip/>}
                                       cursor={{fill: toolTipHighlightColor}}/>
                              <Bar dataKey="total-cost" stackId="a"
                                   fill={theme.colors.orange[1]}
                                   radius={[5, 5, 0, 0]}/>
                          </BarChart>
                      </ResponsiveContainer>
                      {/*smaller tables*/}
                      <div style={{display: 'flex', flexWrap: 'wrap'}}>
                          {costDataKeys.map(([key, value], index) => (
                              <div key={index} style={{
                                  marginBottom: '20px',
                                  width: '50%',
                                  padding: '10px'
                              }}>
                                  <div style={{
                                      marginLeft: '20px',
                                      marginBottom: '10px',
                                      fontSize: '16px'
                                  }}>
                                      <p style={{
                                          display: 'inline',
                                          marginRight: '0px',
                                          color: theme.colors.gray[1],
                                          fontWeight: '700',
                                      }}>{formatKey(key)}</p> {/* Apply formatting to the key */}

                                      <p style={{
                                          display: 'inline',
                                          color: theme.colors.gray[4],
                                      }}>${value.toFixed(2)}</p> {/* Display the value directly */}
                                  </div>
                                  <ResponsiveContainer width="100%"
                                                       height={200}>
                                      <BarChart data={monthlyUsageData}>
                                          <CartesianGrid strokeDasharray=""
                                                         vertical={false}
                                                         stroke={chartLineColor}/>
                                          <XAxis
                                              fontSize={'12px'}
                                              dataKey="day"
                                              interval={Math.floor(monthlyUsageData.length / 5) - 1}
                                              tick={<CustomXAxisTick/>}
                                              stroke={chartLineColor}
                                          />
                                          <YAxis
                                              tickFormatter={(value) => `$${value}`}
                                              tick={{
                                                  fontSize: '12px',
                                                  fill: chartLineColor
                                              }}
                                              axisLine={false}
                                              stroke={chartLineColor}
                                          />
                                          <Tooltip content={<SimpleToolTip/>}
                                                   cursor={{fill: toolTipHighlightColor}}/>
                                          <Bar dataKey={key} stackId="a"
                                               fill={keyColors[key] || theme.colors.orange[1]}
                                               radius={[5, 5, 0, 0]}/>
                                      </BarChart>
                                  </ResponsiveContainer>
                              </div>
                          ))}
                      </div>

                  </div>
          </Stack>
      </Box>
  );
};

export default MonthlySpendGraphs;
