import {Box, createStyles, Text, useMantineTheme} from '@mantine/core';
import React, {ReactNode, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {ChevronRight, Icon} from "react-feather";

const useStyles = createStyles((theme, { isHovered }: { isHovered: boolean }) => ({
  container: {
  },
  animatedLink: {
      marginBottom: theme.spacing.sm,
    display: 'inline-flex',
    alignItems: 'center',
    cursor: 'pointer',
    position: 'relative',
    textDecoration: 'none',
    color: theme.colorScheme === 'dark' ? theme.white : theme.black,

    '&::after': {
      content: '""',
      position: 'absolute',
      width: isHovered ? '100%' : 0,
      height: '2px',
      bottom: 0,
      left: 0,
      backgroundColor: theme.colorScheme === 'dark' ? theme.white : theme.black,
      transition: 'width 0.3s ease',
    },
  },
  icon: {
    marginLeft: theme.spacing.sm,
  },
    unstyledLink: {
    textDecoration: "none",
    color: "inherit",

  },
    coloredBox: {
      flex: 1,
              border: `0.5px solid ${theme.colors.gray[8]}`,
      backgroundColor: theme.colors.gray[9],
    },
    bottomIcon: {
    position: 'absolute',
    bottom: '0px',
    right: '32px',
    color: theme.colors.gray[0], // Adjust color as needed
    opacity: 0.7, // Optional: adds a subtle effect
  },
}));


interface GradientCardProps {
  children: ReactNode;
  backgroundColor: string;
  title: string;
  path: string;
  width?: string | number;
  height?: string | number;
  icon?: Icon; // Add this line - Icon is the type from react-feather
}

export const GradientCard = ({
  children,
  backgroundColor,
  title,
  path,
  width = '100%',
  height = 'auto',
  icon: Icon, // Add this line
}: GradientCardProps) => {
  const [isHovered, setIsHovered] = useState(false);
  const { classes } = useStyles({ isHovered });
  const navigate = useNavigate();
  const theme = useMantineTheme();

  return (
    <a href={path} className={classes.unstyledLink}>
      <Box
        sx={{
          position: 'relative', // Add this to help position the icon
          border: '1px solid',
          borderColor: theme.colors.gray[8],
          borderRadius: '8px',
          padding: '16px',
          width,
          height,
          background: `linear-gradient(225deg, ${backgroundColor} 0%, ${backgroundColor}85 100%)`,
        }}
        className={classes.container}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <div className={classes.animatedLink}>
          <Text>{title}</Text>
          <ChevronRight className={classes.icon}/>
        </div>
        {children}
        {Icon && (
          <div className={classes.bottomIcon}>
            <Icon size={120} />
          </div>
        )}
      </Box>
    </a>
  );
};
