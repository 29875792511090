import { createStyles, Paper, Text } from '@mantine/core';
import TwoColumn from "../TwoColumns";
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import {rem} from "@mantine/core";
import WhiteWireFrameButton from "../../../componenets/buttons/whiteWireFrameButton";

const useStyles = createStyles((theme) => ({
    pageTitle: {
        textAlign: 'left',
        marginTop: 80,
        marginBottom: 40,
        fontSize: '2.5rem',
        marginLeft: theme.spacing.md,
        color: theme.colors.gray[0],
        '@media (min-width: 600px)': {
            marginTop: 100,
            marginBottom: 60,
            fontSize: '3.5rem',
        },

        '@media (min-width: 1280px)': {
            marginTop: 150,
            marginBottom: 80,
            fontSize: '4.5625rem',
        },
    },

    header: {
        textAlign: 'left',
        fontSize: theme.fontSizes.lg,
        color: theme.colors.gray[3],
        fontWeight: 400,
        '@media (min-width: 600px)': {
            fontSize: theme.fontSizes.xl
        },

        '@media (min-width: 1280px)': {
            fontSize: theme.fontSizes.xxl,
        },
    },

    pageLine: {
        width: '97%',
        height: '2px',
        backgroundColor: theme.colors.gray[7],
        marginBottom: theme.spacing.md,
        marginLeft: 'auto', // Add this line
        marginRight: 'auto', // Add this line
    },

    paragraphText: {
        fontSize: '18px',
        fontWeight: 200,
        color: theme.colors.gray[0],
    },

    rightDiv: {
        marginLeft: '25px',
        '@media (min-width: 1280px)': {
            marginLeft: 0,
        },
    },

    leftDiv: {
        marginBottom: '40px',
    },

    separatingDiv: {
        height: '20px',
        '@media (min-width: 1280px)': {
            height: '30px',
        },
    },
    largeSeparatingDiv: {
        height: '60px',
        '@media (min-width: 1280px)': {
            height: '80px',
        },
    },

    paragraphBoldText: {
        fontWeight: 'bold',
    },

    table: {
        width: '100%',
        borderCollapse: 'collapse',
    },
    cell: {
        padding: theme.spacing.md,
        textAlign: 'left',
        borderBottom: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.gray[0] : theme.colors.gray[0]}`,
    },
    tableHeader: {
        borderBottomWidth: '2px',  // make the header line bolder
        fontWeight: 'bold',
    },
    lastRow: {
        borderBottom: 'none',  // remove the bottom border from the last row
    }
}));

export function SharingAndPublicationPolicy() {
    const { classes } = useStyles();
    return (

        <div>
            <Text size="xxl" className={classes.pageTitle}>Sharing & publication policy</Text>
            <div className={classes.pageLine}></div>
            {/* info*/}
            <TwoColumn
                left={
                    <div className={classes.leftDiv}>
                        <Text style={{fontWeight: 'bold'}} className={classes.paragraphText}>Updated</Text>
                        <Text className={classes.paragraphText}>August 1, 2023</Text>
                    </div>
                }
                right={
                    <div className={classes.rightDiv}>
                        <div className={classes.separatingDiv}></div>
                        <div className={classes.largeSeparatingDiv}></div>

                        <Text className={classes.header}>Social media, livestreaming, and demonstrations</Text>
                        <p className={classes.paragraphText}>To mitigate the possible risks of AI-generated content, we have set the following policy on permitted sharing.</p>
                        <p className={classes.paragraphText}>Posting your own prompts or completions to social media is generally permissible, as is livestreaming your usage or demonstrating our products to groups of people. Please adhere to the following:</p>
                        <ul>
                            <li className={classes.paragraphText}>Manually review each generation before sharing or while streaming.
                            </li>
                            <li className={classes.paragraphText}>Attribute the content to your name or your company.
                            </li>
                            <li className={classes.paragraphText}>Indicate that the content is AI-generated in a way no user could reasonably miss or misunderstand.
                            </li>
                            <li className={classes.paragraphText}>Do not share content that violates our Content Policy or that may offend others.
                            </li>
                            <li className={classes.paragraphText}>If taking audience requests for prompts, use good judgment; do not input prompts that might result in violations of our Content Policy.
                            </li>
                        </ul>

                        <p className={classes.paragraphText}>If you would like to ensure the Nutricloud team is aware of a particular completion, you may email us or use the reporting tools within Playground.

                        </p>

                        <ul>
                            <li className={classes.paragraphText}>Recall that you are interacting with the raw model, which means we do not filter out biased or negative responses. (Also, you can read more about implementing our free Moderation endpoint here.)</li>
                        </ul>
                        <div className={classes.separatingDiv}></div>
                        <div className={classes.largeSeparatingDiv}></div>


                        <Text className={classes.header}>Content co-authored with the Nutricloud API</Text>
                        <p  className={classes.paragraphText}>Creators who wish to publish their first-party written content (e.g., a book, compendium of short stories) created in part with the Nutricloud API are permitted to do so under the following conditions:</p>

                        <ul>
                            <li className={classes.paragraphText}>The published content is attributed to your name or company.
                            </li>
                            <li className={classes.paragraphText}>The role of AI in formulating the content is clearly disclosed in a way that no reader could possibly miss, and that a typical reader would find sufficiently easy to understand.
                            </li>
                            <li className={classes.paragraphText}>Topics of the content do not violate Nutricloud’s Content Policy or Terms of Use, e.g., are not related to adult content, spam, hateful content, content that incites violence, or other uses that may cause social harm.
                            </li>
                            <li className={classes.paragraphText}>We kindly ask that you refrain from sharing outputs that may offend others.
                            </li>
                        </ul>

                        <p className={classes.paragraphText}>For instance, one must detail in a Foreword or Introduction (or some place similar) the relative roles of drafting, editing, etc. People should not represent API-generated content as being wholly generated by a human or wholly generated by an AI, and it is a human who must take ultimate responsibility for the content being published.</p>
                        <div className={classes.separatingDiv}></div>
                        <div className={classes.largeSeparatingDiv}></div>

                        <Text className={classes.header}>Research</Text>
                        <p className={classes.paragraphText}>We believe it is important for the broader world to be able to evaluate our research and products, especially to understand and improve potential weaknesses and safety or bias problems in our API's. Accordingly, we welcome research publications related to the Nutricloud API.</p>
                        <p className={classes.paragraphText}>If you have any questions about research publications based on API access or would like to give us advanced notice of a publication (though not required), please email us at papers@Nutricloud.com.</p>
                        <ul>
                            <li className={classes.paragraphText}>In some cases, we may want to highlight your work internally and/or externally.
                            </li>
                            <li className={classes.paragraphText}>In others, such as publications that pertain to security or misuse of the API, we may want to take appropriate actions to protect our users.
                            </li>
                            <li className={classes.paragraphText}>If you notice any safety or security issues with the API in the course of your research, we ask that you please submit these immediately through our Coordinated Vulnerability Disclosure Program.</li>
                        </ul>
                        <div className={classes.separatingDiv}></div>
                        <div className={classes.largeSeparatingDiv}></div>

                        <Text className={classes.header}>Researcher Access Program</Text>
                        <p className={classes.paragraphText}>There are a number of research directions we are excited to explore with the Nutricloud API. If you are interested in the opportunity for subsidized access, please provide us with details about your research use case on the Researcher Access Program application.</p>
                        <p className={classes.paragraphText}>In particular, we consider the following to be especially important directions, though you are free to craft your own direction:</p>
                        <ul>
                            <li className={classes.paragraphText}><span className={classes.paragraphBoldText}>Alignment:</span> How can we understand what objective, if any, a APIis best understood as pursuing? How do we increase the extent to which that objective is aligned with human preferences, such as via prompt design or fine-tuning?
                            </li>
                            <li className={classes.paragraphText}><span className={classes.paragraphBoldText}>Fairness and representation:</span> How should performance criteria be established for fairness and representation in language API's? How can language API's be improved in order to effectively support the goals of fairness and representation in specific, deployed contexts?
                            </li>
                            <li className={classes.paragraphText}><span className={classes.paragraphBoldText}>Interdisciplinary research:</span> How can AI development draw on insights from other disciplines such as philosophy, cognitive science, and sociolinguistics?
                            </li>
                            <li className={classes.paragraphText}><span className={classes.paragraphBoldText}>Interpretability and transparency:</span> How do these API's work, mechanistically? Can we identify what concepts they’re using, or extract latent knowledge from the model, make inferences about the training procedure, or predict surprising future behavior?
                            </li>
                            <li className={classes.paragraphText}><span className={classes.paragraphBoldText}>Misuse potential:</span> How can systems like the API be misused? What sorts of “red teaming” approaches can we develop to help us and other AI developers think about responsibly deploying technologies like this?
                            </li>
                            <li className={classes.paragraphText}><span className={classes.paragraphBoldText}>APIexploration:</span> API's like those served by the API have a variety of capabilities which we have yet to explore. We’re excited by investigations in many areas including APIlimitations, linguistic properties, commonsense reasoning, and potential uses for many other problems.
                            </li>
                            <li className={classes.paragraphText}><span className={classes.paragraphBoldText}>Robustness:</span> Generative API's have uneven capability surfaces, with the potential for surprisingly strong and surprisingly weak areas of capability. How robust are large generative API's to “natural” perturbations in the prompt, such as phrasing the same idea in different ways or with or without typos? Can we predict the kinds of domains and tasks for which large generative API's are more likely to be robust (or not robust), and how does this relate to the training data? Are there techniques we can use to predict and mitigate worst-case behavior? How can robustness be measured in the context of few-shot learning (e.g., across variations in prompts)? Can we train API's so that they satisfy safety properties with a very high level of reliability, even under adversarial inputs?
                            </li>
                        </ul>

                        <p className={classes.paragraphText}>Please note that due to a high volume of requests, it takes time for us to review these applications and not all research will be prioritized for subsidy. We will only be in touch if your application is selected for subsidy.</p>
                        <div className={classes.separatingDiv}></div>
                        <div className={classes.largeSeparatingDiv}></div>
                    </div>
                }
            />
        </div>
    );
}
