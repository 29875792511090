import React, {useState} from 'react';
import {
    createStyles,
    rem,
    Slider,
    TextInput,
    Text,
    SegmentedControl,
    Button,
    useMantineTheme, MultiSelect
} from '@mantine/core';
import { Select, SelectItem } from '@mantine/core';
import QueryBuilder from "../QueryBuilder";

const useStyles = createStyles((theme) => ({
    inputMargin: {
        marginBottom: '10px',
    },
    titleSize: {
        fontSize: '24x',
        '@media (max-height: 1000px)': {
            fontSize: '16px'
        },
    }
}));

const selectorStyles = createStyles((theme) => ({
    root: {
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.white,
        boxShadow: theme.shadows.md,
        border: `${rem(1)} solid ${
            theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[1]
        }`,
    },

    indicator: {
        backgroundImage: theme.fn.gradient({ from: 'pink', to: 'orange' }),
    },

    control: {
        border: '0 !important',
    },

    label: {
        '&, &:hover': {
            '&[data-active]': {
                color: theme.white,
            },
        },
    },
}));



interface RelationalSearchParametersProps {
    onParamChange: (param: string, value: any) => void;
}

const SearchParameters: React.FC<RelationalSearchParametersProps> = ({ onParamChange }) => {
    const theme = useMantineTheme();
    const { classes } = useStyles();

      const data_quality_variables: SelectItem[] = [
        { value: 'verified', label: 'Verified' },
        { value: 'unverified', label: 'Unverified' },
        { value: 'all', label: 'All Data Sources' },
      ];

      const nutrient_variables: SelectItem[] = [
        { value: 'macronutrients', label: 'Macronutrients' },
        { value: 'micronutrients_simplified', label: 'Simplified micronutrients' },
        { value: 'micronutrients_full', label: 'Full micronutrients' },
      ];

    const orange = "rgb(255, 140, 0)";

        React.useEffect(() => {
        // Set default values for all parameters
        onParamChange('queries', []);
        onParamChange('limit', 2);
        onParamChange('threshold', 0.5);
        onParamChange('data_source', 'verified');
        onParamChange('nutrients', 'micronutrients_simplified');
    }, []);

    const [values, setValues] = useState<{ value: string; label: string }[]>([]);

    return (
        <div>
            {/*searchTerm*/}
            <Text className={classes.titleSize}>Queries</Text>

            <QueryBuilder
          onQueriesChange={(queries) => {
            onParamChange('queries', queries);
          }}
        />


            {/*limit*/}
            <div>
                <Text className={classes.titleSize}>Limit</Text>
                <Slider
                    defaultValue={2}
                    min={1}
                    max={10}
                    label={(value) => value.toFixed(0)}
                    onChangeEnd={(value) => onParamChange('limit', value)}
                    className={classes.inputMargin}
                />
            </div>

            {/*minSimilarity*/}
            <div>
                <Text className={classes.titleSize}>Threshold</Text>
                <Slider
                    defaultValue={0.5}
                    min={0}
                    max={1}
                    step={0.01}
                    label={(value) => value.toFixed(2)}
                    onChangeEnd={(value) => onParamChange('threshold', value)}
                    className={classes.inputMargin}
                />
            </div>

            {/*    data quality*/}
            <div>
                <Text className={classes.titleSize}>Data Quality</Text>
                <Select
                    data={data_quality_variables}
                    placeholder="Verified"
                    // defaultValue={'unverified'}
                    radius="sm"
                    size="sm"
                    onChange={(value) => onParamChange('data_source', value)}
                    className={classes.inputMargin}
                />
            </div>

            {/* nutrients */}
            <div>
                <Text className={classes.titleSize}>Nutrients</Text>
                <Select
                    data={nutrient_variables}
                    placeholder="Simplified micronutrients"
                    radius="sm"
                    size="sm"
                    onChange={(value) => onParamChange('nutrients', value)}
                    className={classes.inputMargin}
                />
            </div>
        </div>
    );
};

export default SearchParameters;
