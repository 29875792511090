import React, { useEffect, useRef, useState } from 'react';
import { createStyles, Text } from '@mantine/core';
import { Icon } from 'react-feather';

// Define the types for the component props
interface ScrollRevealIconProps {
  bloomColor: string;
  IconComponent: Icon;
  string: string;
}

// Define the styles with a dynamic bloom color
const useStyles = createStyles((theme, { bloomColor }: { bloomColor: string }) => ({
  iconWithBloom: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative', // Set position to relative to contain the absolute positioned bloom effect
transition: "opacity 0.5s ease, visibility 0.5s ease",
  },
bloomEffect: {
  position: 'absolute', // Keep the bloom effect behind the icon
  backgroundColor: bloomColor,
  borderRadius: '50%',
  width: '0px',
  height: '0px',
  boxShadow: `0 0 30px 15px ${bloomColor}`, // Increase blur radius and spread for a more gradual effect
  opacity: 0.6, // Lower the opacity for a lighter effect
},

  icon: {
      color: theme.colors.gray[1],
    zIndex: 1, // Ensure the icon sits above the bloom effect
  },
  lineTitle: {
    fontSize: theme.fontSizes.md,
    marginLeft: 0, // Adjust spacing between icon and text
  },
  visible: {
    visibility: 'visible',
    opacity: 1,
  },
    glowTitle: {
      color: theme.colors.gray[1],
        fontSize: '1.75rem',
        fontWeight: 500,
    },
        glowHeader: {
    display: 'flex', // Enables flexbox
    flexDirection: 'row', // Aligns children in a row
    alignItems: 'center', // Centers items vertically in the container
    justifyContent: 'left', // Centers items horizontally in the container
    gap: theme.spacing.lg, // Adds some space between items
  },
}));

const ScrollRevealIcon: React.FC<ScrollRevealIconProps> = ({ bloomColor, IconComponent, string }) => {
  const { classes } = useStyles({ bloomColor });
  const iconRef = useRef<HTMLDivElement>(null);
  const [isVisible, setIsVisible] = useState(false);
const dynamicStyle = {
  opacity: isVisible ? 1 : 0, // Fade in when visible, fade out when not
  transition: 'opacity 0.5s ease', // Smooth transition for the opacity change
  // You can also add visibility: isVisible ? 'visible' : 'hidden' if needed
};

  useEffect(() => {
    const onScroll = () => {
      if (!iconRef.current) return;

      const iconRect = iconRef.current.getBoundingClientRect();
      const triggerHeight = window.innerHeight * 0.2;

      setIsVisible(iconRect.top < window.innerHeight - triggerHeight);
    };

    onScroll(); // Call onScroll to check if the component is in view

    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  return (
      <div
          className={classes.visible} // your existing classes
          style={dynamicStyle} // Apply dynamic styles here
          ref={iconRef}
      >
          <div className={classes.glowHeader}>
              <div className={classes.iconWithBloom}>
                  <div className={classes.bloomEffect}></div>
                  <IconComponent className={classes.icon}/>
              </div>
              <Text className={classes.glowTitle}>{string}</Text>
          </div>
      </div>
  );
};

export default ScrollRevealIcon;
