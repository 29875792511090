import React, {FC, useState} from 'react';
import {BrowserRouter as Router, Navigate, Route, Routes} from 'react-router-dom';
// general components



// main pages
import NewPricing from './pages/pricing/newPricing';
import LandingPageNew from './pages/LandingPage/LandingPage';
import PricingPage from "./pages/pricing/oldPricing/Pricing";
import AboutPage from "./pages/About/About";

// API's
import {Login} from "./pages/Authentication/login/Login";
import {Signup} from "./pages/Authentication/signup/Signup";
import Page from "./componenets/Page";
import PageJustHeader from "./componenets/PageJustHeader";

// API Reference

// Documentation
import {DocsPage} from "./pages/Documentation/DocsPage";

// Legal
import {TermsAndPoliciesPage} from "./pages/TermAndPolicies/Policies";
import {TermsOfUse} from "./pages/TermAndPolicies/Legal/TermsOfUse";
import {PrivacyPolicy} from "./pages/TermAndPolicies/Legal/PrivacyPolicy";
import {ServiceTerms} from "./pages/TermAndPolicies/Legal/ServiceTerms";
import {DataProcessingAddendum} from "./pages/TermAndPolicies/Legal/DataProcessingAddendum";
import {UsagePolicies} from "./pages/TermAndPolicies/Policies/UsagePolicies";
import {SharingAndPublicationPolicy} from "./pages/TermAndPolicies/Policies/SharingAndPublicationPolicy";
import {CoordinatedVulnerabilityDisclosurePolicy} from "./pages/TermAndPolicies/Policies/CoordinatedVulnerabilityPolicy";

// playground
import PlayGround from "./pages/PlayGround/PlayGround";

import {SearchPage} from "./pages/search/search";
import {AuthProvider, useAuth} from "./AuthContext";
import ProtectedElement from "./ProtectedElement";
import PageJustSidebar from "./componenets/PageJustSideBar";
import APIKeysPage from "./pages/ApiKeys/ApiKeys";
import UsagePage from "./pages/Usage/usage";
import {Onboarding} from "./pages/Onboarding/onboarding";
import {VerifyEmail} from "./pages/Onboarding/VerifyEmail";
import Profile from "./pages/Settings/profile";
import Billing from "./pages/Settings/billing/billing";
import Limits from "./pages/Settings/limits";
import Organization from "./pages/Settings/organization";
import {VerifyPhoneNumber} from "./pages/Onboarding/VerifyPhoneNumber";

//API Keys

// stripe
import {loadStripe} from '@stripe/stripe-js';
import {Elements} from '@stripe/react-stripe-js';
import CheckoutForm from "./pages/payment/checkoutForm";
import CheckoutPage from "./pages/payment/checkoutPage";
import {BillingHistory} from "./pages/Settings/billing/billingHistory";
import BillingPreferences from "./pages/Settings/billing/billingPreferences";
import DataPage from "./pages/data/dataPage";
import ApiReferencePage from "./pages/apiReference/ApiReferencePage";
import DocsIntroductionPage from "./pages/Documentation/GetStarted/introduction";
import RateLimitsGuide from "./pages/Documentation/Guides/RateLimits";
import RateLimitsGuidePage from "./pages/Documentation/Guides/RateLimits";
import {ForgotPassword} from "./pages/Authentication/ForgotPassword/ForgotPassword";
import {UsageProvider} from "./pages/Usage/usageContext";
import ErrorCodesGuidePage from "./pages/Documentation/Guides/ErrorCodes";
import {
    ClassifyApiReference
} from "./pages/apiReference/pages/classifyApiReference";
import DocsClassificationPage from "./pages/Documentation/capabilities/classification";
import DocsSearchPage from "./pages/Documentation/capabilities/food_search";
import {SearchAPIReference} from "./pages/apiReference/pages/searchApiReference";
import AuthenticationAPIReferencePage from "./pages/apiReference/pages/authentication";
import MakingRequestsAPIReferencePage from "./pages/apiReference/pages/making_requests";
import DebuggingRequestsAPIReferencePage
    from "./pages/apiReference/pages/debuggingRequests";
import OverviewDocsPage from "./pages/Documentation/GetStarted/overview";
import QuickStartDocsPage from "./pages/Documentation/GetStarted/quickstart";
import APIsDocsPage from "./pages/Documentation/GetStarted/apis";
import AutoCompleteDocsPage from "./pages/Documentation/capabilities/auto_complete";
import ChatDocsPage from "./pages/Documentation/capabilities/chat";
import RecognitionDocsPage from "./pages/Documentation/capabilities/recognition";
import EmbeddingsDocsPage from "./pages/Documentation/capabilities/embeddings";
import RecommendationDocsPage from "./pages/Documentation/capabilities/recomendation";
import UsersDocsPage from "./pages/Documentation/capabilities/users";
import AppsDocsPage from "./pages/Documentation/capabilities/apps";
import RecipeSearchDocsPage from "./pages/Documentation/capabilities/recipe_search";
import ProductionBestPracticesDocsPage
    from "./pages/Documentation/Guides/ProductionBestPractices";


const stripePromise = loadStripe('pk_live_51MUDAdLxrSaYVGQ0b2trZzFaMBAzuDHIH9zWsHcKv3VlFHA8lfoVMIxShCAN8cj4O1gfpyOp82xNowrz6kx1Bxq200vJvG8Ju7');


const App: FC = () => {
    const [documentationTOCActive, setDocumentationTOCActive] = useState('');

    const documentationiTOCLinks = [
        { label: 'Relational Search', link: 'relational-search', order: 1 },
        { label: 'Auto Complete', link: 'auto-complete', order: 1 },
        { label: 'Full Nutrient Profile', link: 'full-nutrient-profile', order: 1 },
        { label: 'Database Search', link: 'database-search', order: 1 },
    ];


    return (
        <div className="app" style={{
            maxWidth: '100%',
            overflowX: 'hidden',
        }}>
            <Router>
                <AuthProvider>
                    <Routes>
                        {/*payment*/}
                        <Route path="/checkout" element={<CheckoutPage/>} />

                        {/**/}
                        {/**/}
                        {/* LANDING-WEBSITE */}
                        {/**/}
                        {/**/}

                        {/*basics*/}
                        <Route path="/" element={<Page><LandingPageNew /></Page>} />
                        <Route path="/home" element={<Page><LandingPageNew /></Page>} />
                        <Route path="/pricing" element={<Page><NewPricing /></Page>} />
                        <Route path="/about" element={<Page><AboutPage /></Page>} />

                        {/*legal*/}
                        <Route path="/policies" element={<Page><TermsAndPoliciesPage /> </Page>} />
                        <Route path="/policies/terms-of-use" element={<Page><TermsOfUse /></Page>} />
                        <Route path="/policies/service-terms" element={<Page><ServiceTerms /></Page>} />
                        <Route path="/policies/privacy-policy" element={<Page><PrivacyPolicy /></Page>}/>
                        <Route path="/policies/data-processing-addendum" element={<Page><DataProcessingAddendum /></Page>} />
                        <Route path="/policies/usage-policies" element={<Page><UsagePolicies /></Page>} />
                        <Route path="/policies/sharing-&-publication-policy" element={<Page><SharingAndPublicationPolicy /></Page>} />
                        <Route path="/policies/coordinated-vulnerability-disclosure-policy" element={<Page><CoordinatedVulnerabilityDisclosurePolicy /></Page>} />

                        {/*Search*/}
                        <Route path="/search" element={<PageJustHeader><SearchPage /></PageJustHeader>} />

                        {/*authentication*/}
                        <Route path="/login" element={<Login />} />
                        <Route path="/signup" element={<Signup />} />
                        <Route path="/forgot-password" element={<ForgotPassword />} />

                        {/*onboarding*/}
                        <Route path="/onboarding" element={<Onboarding />} />
                        <Route path="/verify/email" element={<VerifyEmail />} />
                        <Route path="/verify/phone-number" element={<ProtectedElement component={VerifyPhoneNumber} />} />




                        {/**/}
                        {/**/}
                        {/* USER-PAGES */}
                        {/**/}
                        {/**/}
                        {/*legacy_data page*/}
                        <Route path="/data" element={ <ProtectedElement component={DataPage} /> } />


                        {/*playground*/}
                        <Route path="/playground" element={ <ProtectedElement component={PlayGround} /> } />

                        {/*api reference*/}
                        <Route path="/docs/api-reference/classify" element={ <ClassifyApiReference/>} />
                        <Route path="/docs/api-reference/search" element={ <SearchAPIReference/>} />
                        <Route path="/docs/api-reference/authentication" element={ <AuthenticationAPIReferencePage/>} />
                        <Route path="/docs/api-reference/making-requests" element={ <MakingRequestsAPIReferencePage/>} />
                        <Route path="/docs/api-reference/debugging-requests" element={ <DebuggingRequestsAPIReferencePage/>} />
                        <Route path="/docs/api-reference" element={ <ApiReferencePage />} />

                        {/*Documentation*/}
                        <Route path="/docs/overview" element={<OverviewDocsPage/>} />
                        <Route path="/docs/quickstart" element={<QuickStartDocsPage/>} />
                        <Route path="/docs/apis" element={<APIsDocsPage/>} />
                        <Route path="/docs/search" element={<DocsSearchPage/>} />
                        <Route path="/docs/search/food" element={<DocsSearchPage/>} />
                        <Route path="/docs/search/recipe" element={<RecipeSearchDocsPage/>} />
                        <Route path="/docs/classify" element={<DocsClassificationPage/>} />
                        <Route path="/docs/autocomplete" element={<AutoCompleteDocsPage/>} />
                        <Route path="/docs/chat" element={<ChatDocsPage/>} />
                        <Route path="/docs/recognition" element={<RecognitionDocsPage/>} />
                        <Route path="/docs/embeddings" element={<EmbeddingsDocsPage/>} />
                        <Route path="/docs/recommendation" element={<RecommendationDocsPage/>} />
                        <Route path="/docs/users" element={<UsersDocsPage/>} />
                        <Route path="/docs/apps" element={<AppsDocsPage/>} />


                        {/*<Route path="/api-reference/introduction" element={ <ProtectedElement component={ApiReferenceIntroduction} />} />*/}
                        <Route path="/docs/introduction" element={ <DocsIntroductionPage/>} />
                        <Route path="/docs/guides/rate-limits" element={ <RateLimitsGuidePage />} />
                        <Route path="/docs/guides/error-codes" element={ <ErrorCodesGuidePage />} />
                        <Route path="/docs/guides/production-best-practices" element={ <ProductionBestPracticesDocsPage />} />


                        {/*Usage*/}
                        <Route path="/usage" element={<ProtectedElement component={
                            () => {
                                return (
                                    <UsageProvider>
                                        <UsagePage />
                                    </UsageProvider>
                                );
                            }
                        } />} />

                        {/*Settings*/}
                        <Route path="/account/profile" element={<ProtectedElement component={Profile} />} />
                        <Route path="/account/billing/overview" element={<ProtectedElement component={Billing} />} />
                        <Route path="/account/billing/history" element={<ProtectedElement component={BillingHistory} />} />
                        <Route path="/account/billing/preferences" element={<ProtectedElement component={BillingPreferences} />} />
                        <Route path="/account/limits" element={<ProtectedElement component={Limits} />} />
                        <Route path="/account/organization" element={<ProtectedElement component={Organization} />} />



                        {/*API Keys*/}
                        <Route path="/api-keys" element={<ProtectedElement component={APIKeysPage} />} />


                    </Routes>
                </AuthProvider>
            </Router>
        </div>
    );
}

export default App;
