import {
    Button,
    createStyles,
    Paper,
    Select,
    Text, Tooltip,
    useMantineTheme
} from '@mantine/core';
import { Link } from 'react-router-dom';
import {rem} from "@mantine/core";
import Sidebar from "../../componenets/sidebar/sidebar";
import React from "react";
import ApiKeysTable from "./ApiKeysTable";
import { notifications } from '@mantine/notifications';


const useStyles = createStyles((theme) => ({
    pageTitle: {
        textAlign: 'left',
        marginTop: 15,
        marginBottom: 10,
        fontSize: '1.5rem',
        fontWeight: 700,
        marginLeft: theme.spacing.md,
        color: theme.colors.gray[0],
        borderBottom: `1px dotted ${theme.colors.gray[7]}`,
        lineHeight: 'normal', // Adjust this line
    },

        toolTip: {
        transition: 'all 0.3s ease',
        backgroundColor: theme.colors.gray[0],
        color: theme.colors.gray[9],
        border: `1px solid ${theme.colors.gray[5]}`,
        zIndex: 1000,
        fontSize: '10px',
    },

    inLineStyle: {
         display: 'inline-block'
    },

    header: {
        textAlign: 'left',
        fontSize: "24px",
        color: theme.colors.gray[3],
        fontWeight: 600,
        marginLeft: '25px',
    },

    finePrint: {
        fontSize: '12px',
        color: theme.colors.gray[3],
        marginLeft: '25px',
        marginRight: '25px',
    },

    pageLine: {
        width: '200%',
        height: '1px',
        backgroundColor: theme.colors.gray[7],
        marginBottom: theme.spacing.md,
        marginLeft: 'auto', // Add this line
        marginRight: 'auto', // Add this line
    },

    paragraphText: {
        fontSize: '16px',
        fontWeight: 300,
        color: theme.colors.gray[0],
        marginLeft: '25px',
        marginRight: '25px',
    },

    rightDiv: {
        maxWidth: '1000px',
        marginLeft: '25px',
        '@media (min-width: 1280px)': {
            marginLeft: 0,
        },
    },

    leftDiv: {
        marginBottom: '40px',
    },

    separatingDiv: {
        height: '10px',
        '@media (min-width: 1280px)': {
            height: '15px',
        },
    },
    largeSeparatingDiv: {
        height: '60px',
        '@media (min-width: 1280px)': {
            height: '80px',
        },
    },

    paragraphBoldText: {
        fontWeight: 'bold',
    },

    highlightedLink: {
        color: theme.colors.orange[0],
        textDecoration: 'none',
    },

    table: {
        width: '100%',
        borderCollapse: 'collapse',
        marginLeft: '20px',
    },

    cell: {
        padding: '0px',
        textAlign: 'left',
        borderBottom: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.gray[0] : theme.colors.gray[0]}`,
        minWidth: '100px',
        marginLeft: '0px',
        marginRight: '0px',

        fontSize: '14px',
        color: theme.colors.gray[3],

    },
    bigCell: {
        padding: '0px',
        textAlign: 'left',
        borderBottom: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.gray[0] : theme.colors.gray[0]}`,
        marginLeft: '0px',
        marginRight: '0px',
        fontSize: '14px',
        color: theme.colors.gray[3],
        minWidth: '100px',
        '@media (min-width: 1000px)': {
            minWidth: '200px',
        },
    },
    tableHeader: {
        borderBottomWidth: '2px',  // make the header line bolder
        fontWeight: 'bold',
    },
    lastRow: {
        borderBottom: 'none',  // remove the bottom border from the last row
    },

    buttonColor: {
        backgroundColor: theme.colors.orange[1],
        fontSize: '14px',
        height: '35px',
        color: theme.colors.gray[9],
    },

    sideBarShrink: {
        marginLeft: '60px',
        '@media (min-width: 1000px)': {
            marginLeft: '200px',
        },
    }
}));

export function APIKeysPage() {
    const theme = useMantineTheme();
    const { classes } = useStyles();
    return (
        <div>
            <Sidebar defaultExpanded={true} activeItem="api-keys" shrinkSize={1000}/>
            <div className={classes.sideBarShrink}>
                {/*title*/}
                {/*title*/}
                <Tooltip
                  label="Securely manage your API keys here. Create, view, and delete keys to control access to our services efficiently."
                  className={classes.toolTip}
                  position={'bottom'}
                  transitionProps={{ transition: 'scale', duration: 200 }}
                  multiline
                  w={300}
                  radius={"xs"}
                  p={20}
                  fw={500}
                  fz={'14px'}
                  ml={15}
                  offset={-5}
                  bg={theme.colors.gray[9]}
                  color={theme.colors.gray[8]}
                >
                  <div className={classes.inLineStyle}>
                    <Text size="xs" className={classes.pageTitle}>API keys</Text>
                  </div>
                </Tooltip>
                <div className={classes.pageLine}></div>
                {/* info*/}
                <div className={classes.rightDiv}>
                    {/*description*/}
                    <Text className={classes.paragraphText}>Your secret API keys are
                        listed
                        below. Please note that we do not display your secret API keys
                        again
                        after you generate them.</Text>
                    <div className={classes.separatingDiv}></div>
                    <Text className={classes.paragraphText}>Do not share your API key
                        with
                        others, or expose it in the browser or other client-side code.
                        In
                        order to protect the security of your account, OpenAI may also
                        automatically disable any API key that we've found has leaked
                        publicly.</Text>
                    <div className={classes.separatingDiv}></div>
                    <Text className={classes.paragraphText}>Enable tracking to see usage
                        per
                        API key on the <Link to="/usage"
                                             className={classes.highlightedLink}>Usage
                            page.</Link></Text>
                    <div className={classes.separatingDiv}></div>
                    <div className={classes.separatingDiv}></div>

                    {/*api key table*/}
                    <ApiKeysTable/>

                    {/*Organization*/}
                    <Text className={classes.header}>Default organization</Text>
                    <div className={classes.separatingDiv}></div>
                    <Text className={classes.paragraphText}>If you belong to multiple
                        organizations, this setting controls which organization is used
                        by default when making requests with the API keys above.</Text>
                    <div className={classes.separatingDiv}></div>
                    <Select
                        data={['Personal']}
                        defaultValue={'Personal'}
                        style={{width: '200px', marginLeft: '25px'}}
                    />
                    <div className={classes.separatingDiv}></div>
                    <Text className={classes.finePrint}>Note: For more information on authentication with the API refer to the <span
                            style={{color: theme.colors.orange[1]}}>Authentication</span> page to
                        learn more.</Text>


                </div>
            </div>
        </div>
    );
}

export default APIKeysPage;
