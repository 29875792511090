import React, {useState} from 'react';
import {
    Button, Col,
    createStyles, Grid,
    Input, List,
    Text,
    TextInput,
    useMantineTheme
} from '@mantine/core';

import {Prism} from "@mantine/prism";
import {ChevronRight} from "react-feather";
import ThreeDContainer from "../LandingPage/components/threeDContainer";
import ScrollRevealGradientLine from "../LandingPage/components/coloredLine";
import AnimatedLink from "../LandingPage/components/animatedLink";
import {PythonIcon} from "../../componenets/icons/Python";
import {JavaIcon} from "../../componenets/icons/Java";
import {TsIcon} from "../../componenets/icons/TypeScript";

// Create custom styles
const useStyles = createStyles((theme, { bloomColor }: { bloomColor: string }) => ({
    overallSection: {
        display: "flex",
        flexDirection: "column",
    },
    smallSection: {
        display: "flex",
        justifyContent: "left",
        alignItems: "center",
        gap: '80px',
        flexDirection: "row",
        // paddingBottom: '10vh',
        // marginTop: "18vh",
        padding: 20,

    },
        textInput: {
        width: "100%",
        color: theme.colors.gray[1]

    },
        glowTitleText: {
        fontSize: '2rem',
        fontWeight: 500,
        maxWidth: 800,
        lineHeight: '1.1',
            textAlign: "left",
    },

    description: {
        fontSize: '1rem',
        marginTop: '20px',
        color: theme.colors.gray[5]
    },
    pipInstall: {
        marginLeft: "auto",
        marginTop: "-20vh",
        marginRight: "-10vw",
        width: '23vw'

    },

    bottomSection: {
        display: "flex",
        justifyContent: "left",
        flexDirection: "row",
        marginTop: "-15vh",
        height: "auto",
        // marginLeft: 40,
    },
    bloomEffect: {
      position: 'absolute', // Keep the bloom effect behind the icon
      backgroundColor: bloomColor,
      borderRadius: '50%',
        // marginLeft: "11vw",
        marginTop: "27vh",
      boxShadow: `0 0 190px 200px ${bloomColor}`, // Increase blur radius and spread for a more gradual effect
      opacity: 0.6, // Lower the opacity for a lighter effect
        zIndex: -1,
    },
          paragraphText: {
        fontSize: theme.fontSizes.sm,

        [theme.fn.largerThan('sm')]: {
          fontSize: theme.fontSizes.md,
        },

        [theme.fn.largerThan('md')]: {
          fontSize: theme.fontSizes.md,
        },
      },
}));

function UsageDashboard() {
    const bloomColor = '#D32122';
  const { classes } = useStyles({ bloomColor });
      const theme = useMantineTheme();

      const serviceColor = "#D32122"
    return (
        <div>
            {/*<ThreeDContainer bloomColor={'radial-gradient(circle, rgba(211,33,34,0.2) 0%, rgba(211,33,34,0) 70%)'}>*/}
                <img style={{width: "auto", height: "70vh", borderRadius: 20, border: "1px solid #fff"}} src={`${process.env.PUBLIC_URL}/pricingPage/activityUsageDash.png`}/>
                <img style={{width: "auto", height: "65vh", borderRadius: 20, border: "1px solid #fff", marginTop: "-50vh", marginLeft: "15vw"}} src={`${process.env.PUBLIC_URL}/pricingPage/costUsageDash.png`}/>

            <div className={classes.bottomSection} style={{marginLeft: 10}}>
                <ScrollRevealGradientLine colorStart="rgb(211, 33, 34, 0)"
                                          colorMiddle="#D32122"
                                          colorEnd="#994fb2"
                                          leftContent={
                    <div className={classes.overallSection}>
                        <Grid p={20}  mt={"18vh"}>
                            {/*text*/}
                            <Col span={12} md={6}>
                                <Text style={{fontWeight: 800, fontSize: "26px"}}>Real-Time
                                    Metrics</Text>
                                <Text className={classes.paragraphText} mb={20}>
                                    Our usage dashboard provides real-time metrics on
                                    your token usage, so you can monitor your
                                    consumption as it happens. You can:
                                </Text>
                                <List>
                                    <List.Item className={classes.paragraphText}>View your current token balance and usage
                                        rate</List.Item>
                                    <List.Item className={classes.paragraphText}>Break down your usage by endpoint,
                                        application, or time period</List.Item>
                                    <List.Item className={classes.paragraphText}>Identify usage trends and patterns to
                                        optimize your integration</List.Item>
                                </List>
                            </Col>
                            <Col span={12} md={6}>
                                <Text style={{fontWeight: 800, fontSize: "26px"}}>Detailed
                                    Reports</Text>
                                <Text className={classes.paragraphText} mb={20}>
                                    In addition to real-time metrics, our dashboard
                                    provides detailed usage reports that you can
                                    generate on-demand. These reports include: </Text>
                                <List>
                                    <List.Item className={classes.paragraphText}>Historical usage data by endpoint,
                                        application, and time period</List.Item>
                                    <List.Item className={classes.paragraphText}>Aggregated usage statistics and
                                        visualizations</List.Item>
                                    <List.Item className={classes.paragraphText}>Downloadable data for further analysis or
                                        integration with your own billing
                                        systems</List.Item>
                                </List>
                                <div style={{marginTop: 40,}}>
                                </div>
                            </Col>
                                <div style={{marginTop: 40, marginLeft: 20, marginBottom: 50}}>
                                    <AnimatedLink text={"Explore documentation"}
                                                  path={"/docs/overview.tsx"}/>
                                </div>
                        </Grid>

                    </div>
                                          }
                />
            </div>

        </div>
    );
}

export default UsageDashboard;
