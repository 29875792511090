import React from 'react';
import {
    Paper,
    Text,
    Flex,
    Container,
    createStyles,
    Col,
    Grid,
    Center, useMantineTheme, ScrollArea, Badge, Select, SegmentedControl, Code
} from '@mantine/core';
import { FaHome, FaUser, FaEnvelope, FaPhone, FaSearch, FaHeart, FaComment, FaBars } from 'react-icons/fa';
import {Link, useNavigate} from 'react-router-dom';
import { FaInfoCircle } from 'react-icons/fa';
import Demo from "../Components/Feedback";
import DocsPage from "../DocsPage";
import FeedbackBanner from "../Components/Feedback";
import {OverviewContentCard} from "../Components/leftRightCard";
import {Prism} from "@mantine/prism";
import AnimatedCardLink, {
} from "../Components/AnimatedBoxCardSimple";
import AnimatedLink from "../../LandingPage/components/animatedLink";
import {GradientCard} from "../Components/GradientCard";
import {useViewportSize} from "@mantine/hooks";
import {
    Activity, AlertCircle,
    BarChart2,
    ChevronRight,
    Eye,
    Layers,
    Lock,
    MessageCircle,
    Search, Terminal
} from "react-feather";


const useStyles = createStyles((theme) => ({
    iconBox: {
        display: 'flex',
        justifyContent: 'flex-start', // Align items to the start (left side)
        alignItems: 'center',
        marginBottom: theme.spacing.md,
        '& svg': {
            transition: 'transform 0.3s ease',
        },
        '&:hover svg': {
            transform: 'scale(1.3)',
            color: theme.colors.gray[0],
        },

    },
    contentBox: {
        marginLeft: theme.spacing.md, // Add some space between the icon and the text
    },
      animatedLink: {
          display: 'inline-flex',
          fontSize: '18px',
          alignItems: 'center',
          cursor: 'pointer',
          position: 'relative',
          textDecoration: 'none',
          color: theme.colorScheme === 'dark' ? theme.white : theme.black,
      },

    // headers
    pageTitle: {
        textAlign: "left",
        fontSize: theme.fontSizes.xxl,
        fontWeight:600,
        color: theme.colors.gray[3],
        marginBottom: "0px",
        marginTop: "100px",
    },
    mainHeader: {
        fontWeight: 500,
        fontSize: theme.fontSizes.xl,
        marginTop: "0px",
        marginBottom: "10px",
    },
    subHeader: {
        fontWeight: 500,
        fontSize: theme.fontSizes.lg,
        color: theme.colors.gray[5],
        // marginTop: "30px",
        marginBottom: "0px",
    },

    // bullet list
    link: {
        color: theme.colors.orange[0],
        textDecoration: 'none',
        '&:hover': {
            color: theme.colors.orange[9],  // Change this to the color you want on hover
        },
    },
    bulletList: {
        listStyleType: 'disc',
    },

    // text
    paragraphText: {
        fontSize: '16px',
        marginBottom: "10px",
        color: theme.colors.gray[3],
    },

    customText: {
        fontSize: '16px',
        color: theme.colors.gray[6]
    },

    // info boxes
    infoBox: {
        backgroundColor: theme.colors.gray[8],
        padding: theme.spacing.md,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        width: '100%',
        marginTop: '10px',
        marginBottom: '20px',
    },
    infoText: {
        marginLeft: theme.spacing.sm,
        fontSize: '16px',
    },
    infoIcon: {
        fontSize: '1em',
        flexShrink: 0,
    },
coloredBox: {
    border: `0.5px solid ${theme.colors.gray[8]}`,
    backgroundColor: theme.colors.gray[9],
        '&:hover': {
      backgroundColor: theme.colors.dark[5],
    },
    borderRadius: '8px',
    padding: 20,
    flex: '1 1 30%',  // Add this line to make boxes take equal space
    // minWidth: '250px', // Add this to prevent boxes from getting too narrow
},

    meetTheAPICardContainer: {
        display: 'flex',
        gap: '20px',
        flexDirection: 'row',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        [`@media (max-width: 1000px)`]: {
            flexDirection: 'column',
        },
    },
}));


const unixCodeExamplesCurl: Record<string, string> = {
    Classify: `curl -X 'POST' \\
  'https://api.nutricloud.ai/api/classify/food/' \\
  -H 'accept: application/json' \\
  -H 'Authorization: api-key $NUTRICLOUD_API_KEY' \\
  -H 'Content-Type: application/json' \\
  -d '{
  "query": "Chicken Fettucini alfredo with broccoli and capers",
  "limit": 10,
  "classification_types": [
    "allergy",
             "texture",
             "flavor_profile",
             "cuisine"
  ]
}'`,
    Search: `curl -X 'POST' \\
  'https://api.nutricloud.ai/api/search/food/' \\
  -H 'accept: application/json' \\
  -H "Authorization: api-key $NUTRICLOUD_API_KEY" \\
  -H 'Content-Type: application/json' \\
  -d '{
  "queries": [
    "Salmon Filet",
    "Chicken Breast"
  ],
  "limit": 1,
  "threshold": 0,
  "data_source": "verified",
  "nutrients": "macronutrients"
}'`
};

const windowsCodeExamplesCurl: Record<string, string> = {
    Classify: `curl -X POST ^
  "https://api.nutricloud.ai/api/classify/food/" ^
  -H "accept: application/json" ^
  -H "Authorization: api-key %NUTRICLOUD_API_KEY%" ^
  -H "Content-Type: application/json" ^
  -d "{
  \\"query\\": \\"Chicken Fettucini alfredo with broccoli and capers\\",
  \\"limit\\": 10,
  \\"classification_types\\": [
    \\"allergy\\",
    \\"texture\\",
    \\"flavor_profile\\",
    \\"cuisine\\"
  ]
}"`,
    Search: `curl -X POST ^
  "https://api.nutricloud.ai/api/search/food/" ^
  -H "accept: application/json" ^
  -H "Authorization: api-key %NUTRICLOUD_API_KEY%" ^
  -H "Content-Type: application/json" ^
  -d "{
  \\"queries\\": [
    \\"Salmon Filet\\",
    \\"Chicken Breast\\"
  ],
  \\"limit\\": 1,
  \\"threshold\\": 0,
  \\"data_source\\": \\"verified\\",
  \\"nutrients\\": \\"macronutrients\\"
}"`
};


const codeExamplesPython: Record<string, string> = {
    Classify: `import os
import requests

api_key = os.getenv('NUTRICLOUD_API_KEY')
if not api_key:
    raise ValueError("NUTRICLOUD_API_KEY environment variable is not set")

def classify_food():
    url = 'https://api.nutricloud.ai/api/classify/food/'

    headers = {
        'accept': 'application/json',
        'Authorization': f'api-key {api_key}',
        'Content-Type': 'application/json'
    }

    data = {
        "query": "Chicken Fettucini alfredo with broccoli and capers",
        "limit": 10,
        "classification_types": [
            "allergy",
            "texture",
            "flavor_profile",
            "cuisine"
        ]
    }

    try:
        response = requests.post(url, headers=headers, json=data)
        response.raise_for_status()
        return response.json()
    except requests.exceptions.RequestException as e:
        print(f"Error: {e}")
        return None

if __name__ == "__main__":
    result = classify_food()
    print("Classification results:", result)`,
    Search: `import os
import requests

api_key = os.getenv('NUTRICLOUD_API_KEY')
if not api_key:
    raise ValueError("NUTRICLOUD_API_KEY environment variable is not set")

def search_food():
    url = 'https://api.nutricloud.ai/api/search/food/'

    headers = {
        'accept': 'application/json',
        'Authorization': f'api-key {api_key}',
        'Content-Type': 'application/json'
    }

    data = {
        "queries": [
            "Salmon Filet",
            "Chicken Breast"
        ],
        "limit": 1,
        "threshold": 0,
        "data_source": "verified",
        "nutrients": "macronutrients"
    }

    try:
        response = requests.post(url, headers=headers, json=data)
        response.raise_for_status()
        return response.json()
    except requests.exceptions.RequestException as e:
        print(f"Error: {e}")
        return None

if __name__ == "__main__":
    result = search_food()
    print("Search results:", result)`
};

const codeExamples: Record<string, string> = {
  Classify: `const apiKey = process.env.NUTRICLOUD_API_KEY;

if (!apiKey) {
  console.error('Error: NUTRICLOUD_API_KEY environment variable is not set');
  process.exit(1);
}

const requestData = {
  query: "Chicken Fettucini alfredo with broccoli and capers",
  limit: 10,
  classification_types: [
    "allergy",
    "texture",
    "flavor_profile",
    "cuisine"
  ]
};

async function classifyFood() {
  try {
    const response = await fetch('https://api.nutricloud.ai/api/classify/food/', {
      method: 'POST',
      headers: {
        'accept': 'application/json',
        'Authorization': \`api-key \${apiKey}\`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(requestData)
    });

    if (!response.ok) {
      throw new Error(\`HTTP error! status: \${response.status}\`);
    }

    const data = await response.json();
    console.log('Classification results:', data);
    return data;
  } catch (error) {
    console.error('Error:', error);
  }
}

classifyFood();`,

  Search: `const apiKey = process.env.NUTRICLOUD_API_KEY;

if (!apiKey) {
 console.error('Error: NUTRICLOUD_API_KEY environment variable is not set');
 process.exit(1);
}

const requestData = {
 queries: [
   "Salmon Filet",
   "Chicken Breast"
 ],
 limit: 1,
 threshold: 0,
 data_source: "verified",
 nutrients: "macronutrients"
};

async function searchFood() {
 try {
   const response = await fetch('https://api.nutricloud.ai/api/search/food/', {
     method: 'POST',
     headers: {
       'accept': 'application/json',
       'Authorization': \`api-key \${apiKey}\`,
       'Content-Type': 'application/json'
     },
     body: JSON.stringify(requestData)
   });

   if (!response.ok) {
     throw new Error(\`HTTP error! status: \${response.status}\`);
   }

   const data = await response.json();
   console.log('Search results:', data);
   return data;
 } catch (error) {
   console.error('Error:', error);
 }
}

searchFood();`
};


const nextStepBoxData = [
    { colors: ['#FF073A', '#FF7F56'], title: 'Food search', description: 'Search for foods and get nutrients', logo: <Search size="1.1em" />, to: '/docs/search/food' },
    { colors: ['#FF073A', '#FF7F56'], title: 'Recipe search', description: 'Search for recipes', logo: <Search size="1.1em" />, to: '/docs/search/recipe' },
    { colors: ['#0047AB', '#418FDE'], title: 'Classification', description: 'Get in-depth understandings of foods', logo: <Layers size="1.1em" /> , to: '/docs/classify'},
    { colors: ['#008000', '#32CD32'], title: 'Chat', description: 'Learn about generating text responses to natural language prompts', logo: <MessageCircle size="1.1em" /> , to: '/docs/chat'},
    { colors: ['#FF00FF', '#FF77FF'], title: 'Recognition', description: 'Generate nutrients with just a photo', logo: <Eye size="1.1em" /> , to: '/docs/recognition'},
    { colors: ['#FF4500', '#FFA07A'], title: 'Authentication', description: 'Guarantee secure access and best practices to our APIs', logo: <Lock size="1.1em" /> , to: '/docs/api-reference/authentication'},
    { colors: ['#D2691E', '#FFA500'], title: 'Rate limits', description: 'Learn how rate limiting is handled and find your limits', logo: <Activity size="1.1em" />, to: '/docs/guides/rate-limits' },
    { colors: ['#FF073A', '#FF7F56'], title: 'Error codes', description: 'Examine detailed guidance for error codes on the platform', logo: <AlertCircle size="1.1em" />, to: '/docs/guides/error-codes' },
    { colors: ['#0047AB', '#418FDE'], title: 'Debugging requests', description: 'Troubleshoot common problems and understand error responses', logo: <Terminal size="1.1em" /> , to: '/docs/api-reference/debugging-requests'},
];


function QuickStartDocs() {
    const {classes} = useStyles();
    const theme = useMantineTheme();
    const navigate = useNavigate();

    const [osType, setOsType] = React.useState<string>('macOS/Linux');
    const [selectedHTTPClient, setSelectedHTTPClient] = React.useState<string>('JavaScript');
    const [selectedExample, setSelectedExample] = React.useState<string>('Classify');

        const gradientBoxes = nextStepBoxData.map((box, index) => (
        <Link to={box.to} key={index} style={{ textDecoration: 'none', color: 'inherit' }}>
        <Flex className={classes.iconBox} key={index}>
            <Paper
                style={{
                    background: `linear-gradient(to right, ${theme.colors.gray[7]}, ${theme.colors.dark[3]})`,
                    padding: '.5em',
                    borderRadius: '6px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '2.4em',
                    height: '2.4em',
                    boxSizing: 'border-box',
                }}
            >
                {box.logo}
            </Paper>
            <Flex direction="column" className={classes.contentBox}>
                <div className={classes.animatedLink}>
                    <Text>{box.title}</Text>
                    <ChevronRight style={{marginLeft: theme.spacing.sm}}/>
                </div>

                <Text size="xs">{box.description}</Text>
            </Flex>
        </Flex>
        </Link>
        ));

    return (
        <Container>
            {/*title*/}
            <Text className={classes.pageTitle}>Developer quickstart</Text>
            <Text className={classes.subHeader}>Learn how to make your first API request.</Text>

            {/*intro*/}
            <Text mt={30} className={classes.paragraphText}>
                The NutriCloud API provides a simple interface to state-of-the-art AI models
                for food and nutrition analysis. Follow this guide to learn how to
                <span
                    onClick={() => navigate('/docs/search/food')}
                    style={{cursor: 'pointer', color: theme.colors.orange[0]}}
                > analyze nutritional content</span> from text descriptions or food images,
                <span
                    onClick={() => navigate('/docs/classify')}
                    style={{cursor: 'pointer', color: theme.colors.orange[0]}}
                >perform ingredient classification</span>, and get detailed nutrient breakdowns
                through our real-time AI-powered systems. Our <span
                    onClick={() => navigate('/docs/chat')}
                    style={{cursor: 'pointer', color: theme.colors.orange[0]}}
            >streaming LLM capabilities</span>
                are seamlessly integrated with our nutrition APIs, enabling intelligent,
                context-aware food and dietary analysis. Discover this and more with NutriClouds APIs.
            </Text>

            {/*create and export API Key*/}
            <Text mt={30} className={classes.mainHeader}>Create and export an API key</Text>
            <Text mt={30} className={classes.paragraphText}><span
                    onClick={() => navigate('/api-keys')}
                    style={{cursor: 'pointer', color: theme.colors.orange[0]}}
                >Get started by generating
                your API key in the dashboard. </span>
                Keep this key somewhere secure on your computer - you might store
                it in your local <Badge>.zshrc file</Badge> or another text document. Then set it up as
                an environment variable in your terminal session.</Text>

            {/*export api key*/}
            <SegmentedControl
                styles={(theme) => ({
                    label: {
                      '&[data-active]': {
                        color: theme.colors.orange[0], // or any color you want
                      },
                    },
                  })}
                mt={20}
                mb={20}
              data={['macOS/Linux', 'Windows']}
              value={osType}
              onChange={(value: string | null) => setOsType(value || 'macOS/Linux')}
            />

            <Prism language={"bash"}>
                {osType === 'macOS/Linux' ? `export NUTRICLOUD_API_KEY="your_api_key_here"` :
                    `setx NUTRICLOUD_API_KEY="your_api_key_here"\``}
            </Prism>

            {/*make first api request*/}
            <Text mt={30} className={classes.mainHeader}>Make your first API request</Text>
            <Text mt={30} className={classes.paragraphText}>With your NutriCloud API key exported as an environment variable, you're ready to make your first API request. You are now ready to use the <span
                    onClick={() => navigate('/docs/api-reference/authentication')}
                    style={{cursor: 'pointer', color: theme.colors.orange[0]}}
            >REST API</span> directly with the HTTP client of your choice.</Text>

            <SegmentedControl
                styles={(theme) => ({
                    label: {
                      '&[data-active]': {
                        color: theme.colors.orange[0], // or any color you want
                      },
                    },
                  })}
                mt={20}
                mb={20}
              data={['JavaScript', 'Python', 'cURL']}
              value={selectedHTTPClient}
              onChange={(value: string | null) => setSelectedHTTPClient(value || 'JavaScript')}
            />

            {selectedHTTPClient === 'JavaScript' && (
                <div>
                    <Text mt={30} className={classes.paragraphText}>To use the NutriCloud API in server-side JavaScript environments
                        like <Badge color={"green"}>Node.js</Badge>, <Badge color={"green"}>Deno</Badge>, or <Badge color={"green"}>Bun</Badge>, you can use your favorite HTTP library such
                        as <Badge>http/https</Badge>, <Badge>axios</Badge>, or <Badge>node-fetch</Badge>.</Text>

                    <Text mt={30} className={classes.paragraphText}>With your runtime setup, create a file called <Code px={10}>example.mjs</Code> and copy one of the following examples into it:</Text>

                {/*    examples*/}
                    <SegmentedControl
                        styles={(theme) => ({
                            label: {
                              '&[data-active]': {
                                color: theme.colors.orange[0], // or any color you want
                              },
                            },
                          })}
                        mt={20}
                        mb={20}
                      data={['Classify', 'Search']}
                      value={selectedExample}
                      onChange={(value: string | null) => setSelectedExample(value || 'Classify')}
                    />

                    <Prism language={"javascript"}>
                        {codeExamples[selectedExample]}
                    </Prism>

                    <Text mt={30} className={classes.paragraphText}>Execute the code with <Code px={10}>node example.mjs</Code> (or the equivalent command for Deno or Bun). In a few moments, you should see the output of your API request!</Text>

                </div>

            )}
            {selectedHTTPClient === 'Python' && (
                <div>
                    <Text mb={30} mt={30} className={classes.paragraphText}>To use the NutriCloud API in Python, you can use popular HTTP libraries such
                    as <Badge>requests</Badge>, <Badge>httpx</Badge>, or Python's built-in <Badge>urllib</Badge>.</Text>

                    <Prism language={"python"}>
                        {`pip install requests`}
                    </Prism>

                    <Text mt={30} className={classes.paragraphText}>With Python installed, create a file called <Code px={10}>example.py</Code> and copy one of the following examples into it:</Text>

                    <SegmentedControl
                        styles={(theme) => ({
                            label: {
                              '&[data-active]': {
                                color: theme.colors.orange[0], // or any color you want
                              },
                            },
                          })}
                        mt={20}
                        mb={20}
                      data={['Classify', 'Search']}
                      value={selectedExample}
                      onChange={(value: string | null) => setSelectedExample(value || 'Classify')}
                    />

                    <Prism language={"python"}>
                        {codeExamplesPython[selectedExample]}
                    </Prism>

                    <Text mt={30} className={classes.paragraphText}>Execute the code with <Code px={10}>python example.py</Code> In a few moments, you should see the output of your API request!</Text>

                </div>
            )}
            {selectedHTTPClient === 'cURL' && (
                <div>
                    <Text mb={30} mt={30} className={classes.paragraphText}>To use the
                        NutriCloud API with cURL, you can send requests directly from
                        your terminal
                        using <Badge>curl</Badge>. cURL comes pre-installed on most
                        Unix-based systems and Windows 10+.</Text>
                    <Prism language={"bash"}>
                        {`# Check if curl is installed
curl --version`}
                    </Prism>
                    <Text mt={30} className={classes.paragraphText}>Copy one of the
                        following cURL commands into your terminal:</Text>
                    <SegmentedControl
                        styles={(theme) => ({
                            label: {
                                '&[data-active]': {
                                    color: theme.colors.orange[0],
                                },
                            },
                        })}
                        mt={20}
                        mb={20}
                        data={['Classify', 'Search']}
                        value={selectedExample}
                        onChange={(value: string | null) => setSelectedExample(value || 'Classify')}
                    />

                    {osType === 'macOS/Linux' ? (
                        <Prism language={"bash"}>
                            {unixCodeExamplesCurl[selectedExample]}
                        </Prism>
                    ) : (
                        <Prism language={"bash"}>
                            {windowsCodeExamplesCurl[selectedExample]}
                        </Prism>
                    )}

                    <Text mt={30} className={classes.paragraphText}>Execute the curl commands above in your terminal. In a few moments, you should see the output of your API request!</Text>

                </div>
            )}

            {/*next steps*/}
            <Text mt={30} className={classes.mainHeader}>Next steps</Text>
            <Text mb={30} mt={0} className={classes.paragraphText}>Now that you've made your first NutriCloud API request, you can explore the following resources:</Text>

            <Flex direction="column">
                <Flex direction="column">{gradientBoxes}</Flex>
            </Flex>


            <FeedbackBanner/>
        </Container>
    );
}

export default function QuickStartDocsPage() {
    return (
        <div>
            <DocsPage children={<QuickStartDocs/>} activeItem={"quickstart"}/>
        </div>
    );
}
