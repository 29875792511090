import React, {useState} from 'react';
import {
    Box,
    Button, Col,
    createStyles, Grid,
    Group,
    Text,
    TextInput,
    useMantineTheme
} from '@mantine/core';
import {Link} from "react-router-dom";
import AnimatedLink from "../animatedLink";

// Create custom styles
const useStyles = createStyles((theme) => ({
    content: {
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
                marginTop: "10vh",

        paddingLeft: 50,
        [`@media (max-width: 767px)`]: {
          paddingLeft: 30,
    },
    },
    title: {
        fontSize: 32,
        fontWeight: 700,
        textAlign: "center",
    },
        textInput: {
        width: "100%",
        color: theme.colors.gray[1],
            marginTop: 40,
            marginBottom: "20vh",
    },
    emailInput: {
      [`@media (min-width: 767px)`]: {
          minWidth: 350,
    },
        [`@media (max-width: 767px)`]: {
          marginTop: 70
    },
      },
    emailSubmit: {
      marginLeft: -30,
      [`@media (max-width: 767px)`]: {
          marginLeft: 0,
      width:  "100%",
    },
    }
}));

interface APIDescription {
  [key: string]: string;
}

const apiDescriptions: APIDescription = {
    Search: "Empower your users to quickly find the food items and recipes they're looking for with our search endpoints. Our high-performance search APIs provide lightning-fast results for both general queries and specific criteria like ingredients, cuisines, and dietary restrictions. In addition, our intelligent autocomplete endpoints offer real-time suggestions as users type, helping them find what they need with minimal effort.",
  Generative: "Revolutionize your food and recipe content creation with our powerful generation endpoints. Our state-of-the-art APIs can create full, detailed recipes from a variety of inputs, including ingredient lists, cuisine styles, dietary preferences, and more. Each generated recipe includes enticing images, helpful preparation tips, step-by-step instructions, providing everything your users need to cook with confidence.",
    Suggest: "Unlock the full potential of your food and recipe applications with our suggest endpoints. These powerful APIs provide intelligent recommendations and suggestions to enhance your users' culinary experiences. From suggesting ingredient substitutions and recommending recipes based on available items, to identifying key components in a dish and proposing complementary recipes, our suggest endpoints offer a range of features to inspire and assist users in their cooking endeavors. Integrate our suggest APIs to add a new level of interactivity and personalization to your application.",
  Chat: "Our chat endpoints empower your application with a state-of-the-art language model that has full access to our entire suite of food and recipe APIs. This enables users to engage in rich, context-aware conversations about all aspects of the culinary world. Leveraging the advanced natural language capabilities of leading providers like OpenAI and Anthropic, our chat endpoints provide intelligent and informed responses to user queries, seamlessly drawing upon the wealth of information available through our APIs.\n",
    Info: "Get insights into the required equipment and techniques to successfully execute a recipe. And discover the essential ingredients that form the backbone of your favorite dishes. Our food info endpoints offer a range of useful details. Explore the seasonality of ingredients to know when they are at their peak freshness and flavor.",
Image: "Our image APIs offer a range of powerful features to enhance your food and recipe applications. With our image recognition endpoints, you can easily identify foods and dishes from photos, unlocking detailed nutritional information and enabling users to make informed choices about what they eat. Beyond recognition, our image APIs also allow you to generate realistic or stylized images of food items and recipes, providing engaging visuals to accompany your content. For recipe applications, our endpoints can create entire recipes based on a single image.",
  Classify: "Our classify endpoints provide powerful and flexible solutions for analyzing and categorizing food items and recipes. Whether you need to identify allergens, determine dietary suitability, or classify recipes by cuisine, meal type, or course, our APIs have you covered.",
};

const apiDocsLinks = {
    Search: "/docs/search",
    Generative: "/docs/generative",
    Suggest: "/docs/suggest",
    Chat: "/docs/chat",
    Info: "/docs/info",
    Image: "/docs/image",
    Classify: "/docs/classify",
}


function ServicePickerTop() {
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const color = "#a249b6"
      const highlightcolor = "#a249b6"

    // input
    const [searchValue, setSearchValue] = useState('');
  const handleSearchValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };


  // search results
  const [displayableAPIs, setDisplayableAPIs] = useState<{ name: string; description: string; link: string; }[]>([]);
  const [display, setDisplay] = useState(false);

  const handleSearch = () => {
    const apis = getSearchResults();
    const descriptions = apis.map(api => ({ name: api, description: apiDescriptions[api as keyof APIDescription], link: apiDocsLinks[api as keyof typeof apiDocsLinks]  }));
    setDisplayableAPIs(descriptions);
    setDisplay(true);
  };

  const getSearchResults = (): string[] => {
    return ["Search", "Generative", "Chat", "Classify"];
  };

    return (
        <div className={classes.content}>
<Grid w={"100%"} justify="center" align="center" mb={100}>
    <Col span={12}>
        <Text className={classes.title} style={{color: theme.colors.gray[6], textAlign: 'center'}}>
            Don't see what you're looking for?
        </Text>
    </Col>
    <Col span={12}>
        <Text className={classes.title} style={{marginTop: '2rem', textAlign: 'center'}}>
            Describe your project and let us <span style={{color: color}}>find the right API</span> for you
        </Text>
    </Col>
    <Col span={12} sm={8}>
        <TextInput
            type="email"
            placeholder="Email Address"
            value={searchValue}
            onChange={handleSearchValueChange}
            className={classes.emailInput}
            required
            radius="xs"
            size="lg"
            style={{ width: '100%' }} // Ensure the input spans the whole column
        />
    </Col>
    <Col span={12} sm={4}>
        <Button
            variant="filled"
            style={{
                color: theme.colors.gray[1],
                border: 'none',
                width: '100%' // Ensure the button spans the whole column
            }}
            className={classes.emailSubmit}
            size="lg"
            onClick={handleSearch}
            bg={color}
            radius="xs"
            p={10}
            fz={20}
            fw={800}
        >
            Find the right API
        </Button>
    </Col>
</Grid>

{display && (
    <Grid p={40} style={{ display: 'flex', flexWrap: 'wrap' }}>
        {displayableAPIs.map((api, index) => (
            <Col key={api.name} span={12} sm={6} style={{ marginBottom: 20, display: 'flex' }}> {/* Use flex to ensure boxes can grow */}
                <div style={{
                    textDecoration: 'none',
                    width: '100%', // Ensure link takes full width of column
                    display: 'flex',
                    flexDirection: 'column', // Needed to align children
                }}>
                    <div style={{
                        backgroundColor: theme.colors.dark[5],
                        borderRadius: 10,
                        padding: 30,
                        color: theme.colors.gray[6],
                        flex: 1, // Makes the div grow to fill the column height
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between' // Distributes space between items and pushes the link to the bottom
                    }}>
                        <div>
                            <Text fz={24} fw={500} mb={10} c={theme.colors.gray[4]}>
                                {api.name} API
                            </Text>
                            <Text fz={16} c={theme.colors.gray[5]}>
                                {api.description}
                            </Text>
                        </div>
                        <div style={{marginTop: 20}}>
                            <AnimatedLink text={`explore ${api.name} docs`} path={api.link} />
                        </div>
                    </div>
                </div>
            </Col>
        ))}
    </Grid>
)}

            <Box>
    </Box>
        </div>
    );
}

export default ServicePickerTop;
